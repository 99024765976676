import React from 'react';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	withStyles,
	TextField,
	Paper,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Grid,
	InputAdornment,
	Slide,
	AppBar,
	Toolbar,
	IconButton,
	Tab,
	Tabs,
	Typography,
	Box,
	List,
	ListItem,
	ListItemText,
  TableFooter,
  TableRow,
  TableCell
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from 'mui-datatables';

import writeToActivity from '../functions/WriteToActivity';
import LoadingButton from '../components/LoadingButton';
import PdfTableDialog from '../components/pdf/PdfTableDialog';
import PrintIcon from '@material-ui/icons/Print';
import VerifyAccess from '../functions/VerifyAccess';
import { responsive } from '../utils/constants/muidatatablesConsts';
import { AntTabsVert, AntTab } from '../components/common/Tabs';
import LoadingCircle from '../components/common/LoadingCircle';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { customTableSort, formatter } from '../utils/tableCustoms';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		alignItems: 'left',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	tabsRoot: {
		borderBottom: '1px solid #e8e8e8',
	},
	tabsIndicator: {
		backgroundColor: '#E86613',
	},
	selected: {},
	tabRoot: {
		textTransform: 'initial',
		minWidth: 72,
		'&:hover': {
			color: '#E86613',
			opacity: 1,
		},
		'&$selected': {
			color: '#E86613',
		},
		'&:focus': {
			color: '#E86613',
			outline: 'none',
		},
		'&:active': {
			outline: 'none',
		},
	},
	primaryVLButton: {
		color: 'white',
		backgroundColor: '#E86613',
		marginLeft: 15,
		width: 'min-content',
		'&:hover': {
			backgroundColor: 'rgba(232, 102, 19, .9)',
		},
	},
});

class Reports extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentReport: null,
			currentReportDropdowns: {},
			reports: [],
			isLoading: true,
			paramOpen: false,
			isSubmitting: false,
			tableCol: null,
			tableData: null,
			tabs: [],
			tabValue: 0,
			openPrintDialog: false,
			access: {},
      reportData: []
		};
		this.runReport = this.runReport.bind(this);
	}
	verify(roles) {
		VerifyAccess(
			(data) => {
				this.setState({ access: data.access });
			},
			roles,
			this.props.fetchInitialData.credentials.user.jsonWebTok
		);
	}
	componentDidMount() {
		fetch(`${process.env.REACT_APP_API}/reports`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
			},
			body: JSON.stringify({
				jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
			}),
		})
			.then((resp) => resp.json())
			.then((info) => {
				if (info.error) {
					this.createSnack(info.error, 'error', 3500);
					return null;
				}
				if (info.success) {
					const roles = info.data.roles.map((role) => role.action);
					this.verify(roles);

					const data = info.data;
					let tabs = [];
					data.reports.forEach((report) => {
						if (tabs.indexOf(report.category) === -1) {
							tabs.push(report.category);
						}
					});

					this.setState({
						reports: data.reports,
						isLoading: false,
						tabs: tabs.sort(),
					});
				} else {
					this.setState({ isLoading: false }, () => {
						this.createSnack('There was a error grabbing the reports', 'error', 3500);
					});
				}
			});
	}

	createSnack(message, type, duration) {
		this.props.enqueueSnackbar(message, {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			variant: type,
			autoHideDuration: duration,
		});
	}

	handleClose = () => {
		this.setState({
			currentReport: null,
			currentReportDropdowns: {},
			paramOpen: false,
		});
	};
	handleRedirect = (href) => {
		window.open(href, '_blank');
	};
	getOptions = (queries, report) => {
		fetch(`${process.env.REACT_APP_API}/basic-query`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
			},
			body: JSON.stringify({
				queries,
			}),
		})
			.then((resp) => resp.json())
			.then((info) => {
				if (info.success) {
					const data = info.data;
					this.setState({
						dropdownData: data.myResults,
						currentReport: report,
						paramOpen: true,
					});
				}
			});
	};
	handleCurrentReport = (report) => {
		let myQueries = JSON.parse(report.parameters).reduce((acc, field) => {
			if (field.fieldType === 'dynamicList') {
				return [...acc, { id: field.name, query: field.query }];
			} else return acc;
		}, []);
		if (myQueries.length > 0) {
			this.getOptions(myQueries, report);
		} else {
			this.setState({ currentReport: report, paramOpen: true });
		}
	};

	runReport(event) {
		event.preventDefault();
		const { currentReport } = this.state;
		const { user } = this.props.fetchInitialData.credentials;

		this.setState({ isSubmitting: true });
		let parameters = JSON.parse(this.state.currentReport.parameters);
		let paramsConditions = [];
		let params = parameters.map((p) => {
			if (p.fieldType === 'dynamicList') {
				if (
					p.name === 'vendor_id' ||
					p.name === 'user_login_id' ||
					p.name === 'document_type' ||
					p.name === 'role_id'
				) {
					paramsConditions.push({
						name: p.displayName,
						value: this.state[p.name].value === '-1' ? 'All' : this.state[p.name].display,
					});
				} else {
					paramsConditions.push({
						name: p.displayName,
						value: this.state[p.name].value === '-1' ? 'All' : this.state[p.name].value,
					});
				}
				return this.state[p.name].value;
			} else {
				paramsConditions.push({
					name: p.displayName,
					value: !event.target[p.name].value ? 'All' : event.target[p.name].value,
				});
				return event.target[p.name].value;
			}
		});
		let storedProc = parameters.reduce((acc, param, index) => {
			return (acc += index + 1 === params.length ? `?)` : `?,`);
		}, `call dba.${this.state.currentReport.storedproc}(?,?,?,?,`);

		let fetchData = {
			jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
			report_name: this.state.currentReport.report_name,
			storedProc,
			params: [user.clientId, user.uuid, currentReport.id, ...params],
			storedProcName: this.state.currentReport.storedproc,
		};
		fetch(`${process.env.REACT_APP_API}/run-report`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
			},
			body: JSON.stringify(fetchData),
		})
			.then((resp) => resp.json())
			.then((info) => {
				let fieldDataType = JSON.parse(this.state.currentReport.fields_returned).reduce((acc, field) => {
					acc[field.name] = field.dataType;
					return acc;
				}, {});

				if (info.success) {
					const data = info.data.data;
					let tableCol = JSON.parse(this.state.currentReport.fields_returned).map((field) => {
						return {
							name: field.name,
							label: field.displayName,
							dataType: field.dataType,
              total: field.total ? true : false,
							options: {
								filter: true,
								sort: true,
								customBodyRender: (value, tableMeta, updateValue) => {
									return (
										<Typography
											component={'span'}
											style={{ fontSize: '0.8rem', overflowX: 'scroll' }}
											noWrap={true}
										>
											{field.dataType === 'currency' ? formatter.format(value) : value}
										</Typography>
									);
								},
							},
						};
					});
					let tableData = data.map((data) => {
						let rowData = [];
						for (let item in data) {
							if (fieldDataType[item] === 'date') {
								rowData.push(
									isNaN(new Date(data[item]).getDate())
										? ''
										: moment(new Date(data[item])).format('MM/DD/YYYY')
								);
							} else if (fieldDataType[item] === 'decimal') {
								if (parseInt(data[item]) < 0) {
									rowData.push(
										<a href={'google.com'} className="editLink" style={{ color: 'red' }}>
											{data[item]}
										</a>
									);
								} else {
									rowData.push(data[item]);
								}
							} else if (item === 'href') {
								rowData.push(
									<div
										onClick={() => this.handleRedirect(data[item])}
										style={{ color: 'blue' }}
										className="editLink"
									>
										view
									</div>
								);
							} else {
								rowData.push(data[item]);
							}
						}
						return rowData;
					});

					const activity = {
						CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
						OBJ_TYPE: 'REPORT',
						OBJ_ID: this.state.currentReport.id,
						ACTIVITY_TYPE: 'REPORT',
						ACTIVITY_STRING: `${this.state.currentReport.report_name} ${paramsConditions.reduce(
							(str, item, index) => {
								return (str +=
									paramsConditions.length - 1 === index
										? `${item.name} ${item.value})`
										: `${item.name} ${item.value}, `);
							},
							'('
						)}`,
						USER_ID: this.props.fetchInitialData.credentials.user.uuid,
					};

					writeToActivity({ activity }, () => {
						this.setState({
							isSubmitting: false,
							tableCol,
							tableData,
							paramOpen: false,
							paramsConditions,
              reportData: data
						});
					});
				} else {
					this.setState({ isSubmitting: false });
					this.props.fetchInitialData.createSnack('There was a error running the report', 'error', 3000);
				}
			});
	}

	onTagsChange = (event, values, name) => {
		this.setState({ [name]: values });
	};
	getMuiTheme = () =>
		createMuiTheme({
			overrides: {
				MuiTableCell: {
					root: {
						backgroundColor: '#FFF',
					},
				},
			},
		});
	render() {
		const { reports, isLoading, currentReport, paramOpen } = this.state;
		if (isLoading) {
			return <LoadingCircle />;
		}
		const { classes } = this.props;
		const { access } = this.state;
		let tabs = [];
		if (this.state.reports) {
			tabs = this.state.reports.forEach((report) => {
				if (tabs.indexOf(report.category) === -1) {
					tabs.push(reports.category);
				}
			});
		}

		return (
			<div>
				{this.state.tableData && (
					<Dialog
						fullScreen
						open={!!this.state.tableData}
						onClose={() => this.setState({ tableData: null, tableCol: null })}
						TransitionComponent={Transition}
					>
						<AppBar style={{ backgroundColor: '#545454', position: 'relative' }}>
							<Toolbar>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => this.setState({ tableData: null, tableCol: null })}
									aria-label="close"
								>
									<CloseIcon />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div style={{ margin: '1rem' }}>
							<div style={{ margin: 'auto', maxWidth: '1500px' }}>
								<MuiThemeProvider theme={this.getMuiTheme()}>
									<MUIDataTable
										title={`${currentReport.report_name} ${this.state.paramsConditions.reduce(
											(str, item, index) => {
												return (str +=
													this.state.paramsConditions.length - 1 === index
														? `${item.name} ${item.value})`
														: `${item.name} ${item.value}, `);
											},
											'('
										)}`}
										data={this.state.tableData}
										columns={this.state.tableCol}
										options={{
											customSort: calculateSort(this.state.tableCol),
											downloadOptions: {
												filename: `${moment().format()}_${
													currentReport.report_name
												}__${this.state.paramsConditions.reduce((str, item, index) => {
													return (str +=
														this.state.paramsConditions.length - 1 === index
															? `${item.name} ${item.value})`
															: `${item.name} ${item.value}, `);
												}, '(')}.csv`,
												separator: ',',
											},
											rowsPerPage: 100,
											selectableRows: false,
											filterType: 'dropdown',
											responsive: 'scrollMaxHeight',
											print: false,
                  
                      customTableBodyFooterRender: () => customFooter(this.state.tableCol, this.state.tableData)
										}}
									/>
								</MuiThemeProvider>
							</div>
						</div>
						<PdfTableDialog
							isLarge
							open={this.state.openPrintDialog}
							fileName={'report'}
							title={`${currentReport.report_name} ${this.state.paramsConditions.reduce(
								(str, item, index) => {
									return (str +=
										this.state.paramsConditions.length - 1 === index
											? `${item.name} ${item.value})`
											: `${item.name} ${item.value}, `);
								},
								'('
							)}`}
							data={this.state.tableData}
							columns={this.state.tableCol}
							onClose={() => this.setState({ openPrintDialog: false })}
						/>
						<Button
							className="primaryVLButton"
							style={{
								margin: '0 0 15px 15px',
								width: 'min-content',
							}}
							onClick={() => this.setState({ openPrintDialog: true })}
						>
							PDF
							<PrintIcon style={{ marginLeft: 10 }} />
						</Button>
					</Dialog>
				)}

				{paramOpen && (
					<Dialog
						fullWidth
						open={paramOpen}
						onClose={this.handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<form onSubmit={this.runReport}>
							<DialogTitle>{currentReport.report_name}</DialogTitle>
							<DialogContent>
								<Grid container justify="flex-start" spacing={2}>
									{JSON.parse(currentReport.parameters).map((p, index) => {
										if (p.fieldType === 'dynamicList') {
											return (
												<Grid key={index} item xs={12} sm={12} md={12}>
													<Autocomplete
														required
														getOptionLabel={(option) => option.display}
														onChange={(event, values) =>
															this.onTagsChange(event, values, p.name)
														}
														options={
															this.state.dropdownData[p.name]
																? this.state.dropdownData[p.name]
																: []
														}
														renderInput={(params) => (
															<TextField
																required
																{...params}
																label={p.displayName}
																variant="filled"
															/>
														)}
													/>
												</Grid>
											);
										} else if (p.fieldType === 'finiteList') {
											return (
												<Grid key={index} item xs={12} sm={12} md={12}>
													<Autocomplete
														required
														getOptionLabel={(option) => option}
														id={p.name}
														options={p.list ? p.list.split(',') : []}
														renderInput={(params) => (
															<TextField
																required
																{...params}
																label={p.displayName}
																variant="filled"
															/>
														)}
													/>
												</Grid>
											);
										} else {
											return (
												<Grid key={index} item xs={12} sm={12} md={12}>
													<TextField
														id={p.name}
														type={p.fieldType}
														required={p.isRequired === 'True'}
														label={p.displayName}
														variant="filled"
														fullWidth
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<span />
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											);
										}
									})}
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose}>Close</Button>

								<LoadingButton
									label="Confirm"
									isLoading={this.state.isSubmitting}
									color="primaryVLButton"
									buttonType="submit"
								/>
							</DialogActions>
						</form>
					</Dialog>
				)}
				<div className="paymentForm">
					<Paper style={{ margin: 'auto', maxWidth: '900px' }}>
						<div className={classes.root}>
							<AntTabsVert
								color={this.props.fetchInitialData.credentials.primaryAppColor}
								orientation="vertical"
								variant="scrollable"
								value={this.state.tabValue}
								onChange={(event, newValue) => this.setState({ tabValue: newValue })}
								aria-label="Vertical tabs example"
								style={{ width: '120px' }}
							>
								{this.state.tabs.map((tab, index) => {
									return (
										<AntTab
											color={this.props.fetchInitialData.credentials.primaryAppColor}
											key={index}
											label={tab}
											value={index}
										/>
									);
								})}
							</AntTabsVert>
							{this.state.tabs.map((tab, index) => {
								return (
									<TabPanel
										key={index}
										style={{ paddingTop: 0, paddingLeft: 0 }}
										value={this.state.tabValue}
										index={index}
									>
										<List
											style={{ width: '100%' }}
											component="nav"
											aria-label="main mailbox folders"
										>
											{reports
												.filter((report) => report.category === tab)
												.map((row, index) => {
													return (
														<ListItem
															button
															disabled={!access[row.storedproc]}
															onClick={() => this.handleCurrentReport(row)}
															key={index}
														>
															<ListItemText
																primary={row.report_name}
																secondary={row.description}
															/>
														</ListItem>
													);
												})}
										</List>
									</TabPanel>
								);
							})}
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(withSnackbar(Reports));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box style={{ paddingLeft: '1rem' }}>{children}</Box>}
		</div>
	);
}

function calculateSort(cols) {
	return (data, colIndex, order) => {
		const dataType = cols[colIndex].dataType;
		if (dataType === 'currency' || dataType === 'number') {
			return data.sort((a, b) => {
				return (
					(parseFloat(a.data[colIndex]) < parseFloat(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1)
				);
			});
		}
		if (dataType === 'date') {
			return data.sort((a, b) => {
				return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1);
			});
		}
		return data.sort((a, b) => {
			return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
		});
	};
}


function customFooter(cols, tableData) {

    let showFooter = false
    let indexOfFirstTotal = 0

    const totalRow = cols.map((c,index) => {
      if(c.total) {
        indexOfFirstTotal = indexOfFirstTotal === 0 ? index : indexOfFirstTotal
        showFooter = true
        const total = tableData.reduce((tot,cur) => {
          tot += parseFloat(cur[index] ? cur[index] : 0)
          return tot
        },0)
        return <TableCell key={index} style={{color: 'black', fontSize: '0.85rem'}}>{formatter.format(total)}</TableCell>
      } else {
        return <TableCell key={index}></TableCell>
      }
    })
	
    totalRow[indexOfFirstTotal-1] = <TableCell key="total" style={{color: 'black', fontSize: '0.85rem'}}>Total:</TableCell>

    if(showFooter) {
      return (
        <TableFooter>
          <TableRow>
          {totalRow}
          </TableRow>
        </TableFooter>
      );
    }

  };
