import React from "react";
import { Grid, Tab, Tabs, withStyles, withWidth } from "@material-ui/core";

import AdminUserQueue from "./AdminUserQueue";
import AdminQueue from "./AdminQueue";
import { AntTab, AntTabsVert } from "../components/common/Tabs";

const styles = (theme) => ({
  tabsRoot: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    },
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$selected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
});

class AdminApprovalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  viewHash = () => {
    switch (window.location.hash) {
      case '#userQueue':
      default:
        return 0;
      case '#queue':
        return 1;
      case '#other':
        return 2;
    }
  };

  componentDidMount() {
    this.setState({ tabValue: this.viewHash() });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      switch (newValue) {
        case 1:
          window.location.hash = 'queue';
          break;
        case 2:
          window.location.hash = 'userQueue';
          break;
        case 0:
        default:
          window.location.hash = 'userQueue';
          break;
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {clientInfo} = this.props.fetchInitialData.credentials
  
    return (
        <Grid style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: `${(this.props.width === 'sm' || this.props.width === 'xs') ? 'column' : 'row'}`,
          height: 224,
        }}>

        <AntTabsVert
          color={this.props.fetchInitialData.credentials.primaryAppColor}
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          orientation={(this.props.width === 'sm' || this.props.width === 'xs') ? 'horizontal' : 'vertical'}
        >
          
            <AntTab
              key={0}
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              label="User Queue"
              value={0}
            />
        {(clientInfo.inovice_license === 1 || clientInfo.po_license === 1) && (
          <AntTab
              key={1}
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              label="Queue"
              value={1}
            />
        )}
        

        </AntTabsVert>
          {this.state.tabValue === 0 && (
              <AdminUserQueue
                  access={this.props.access}
                  fetchInitialData={this.props.fetchInitialData}/>
          )}
          {this.state.tabValue === 1 && (
              <AdminQueue
                  access={this.props.access}
                  fetchInitialData={this.props.fetchInitialData}/>
          )}
        </Grid>
    )
  }
}

export default withStyles(styles)(withWidth()(AdminApprovalSettings));
