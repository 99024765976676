import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html, fileName, landscape) => {
    savePDF(html, {
      paperSize: 'A4',
      fileName,
      margin: 5,
      landscape,
    })
  }
}

const Doc = new DocService();
export default Doc;
