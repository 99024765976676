import React from "react";
import { withStyles, Button, CircularProgress, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';

import SignUpProgressBar from "../components/SignUpProgressBar";
import { formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

class Vendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isProfileCompleted: false,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API}/vendor-landing-page`, {
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
      .then((resp) => resp.json())
      .then((info) => {
        const { data } = info;
        this.setState({
          content: data.content,
          noOfUnpaidInvoices: data.content.count,
          amountOfUnpaidInvoices: data.content.sum,
          approval_status: data.content.approval_status,
          vendorProgress: data.content.vendorProgress,
          isProfileCompleted: this.isProfileCompleted(
            Object.values(data.content.vendorProgress)
          ),
        });
      })
      .catch((error) => console.log(error));
  }

  isProfileCompleted = (progress) =>
    progress[2] === 0 && progress[3] === 2 && progress[4] !== null;

  render() {
    const { classes } = this.props;
    if (!this.state.content) {
      return (
        <LoadingCircle/>
      );
    }

    return (
      <div className={classes.vendorHomeContainer}>
        <div className={classes.vendorLandingContainer}>
          <SignUpProgressBar mask={Object.values(this.state.vendorProgress)} />
          {!this.state.isProfileCompleted && (
            <Alert severity="warning">
  <AlertTitle>Warning</AlertTitle>
  **Your account is not yet fully approved and processed. Payment
                requests and other changes will not get processed until the
                account processing is complete.You will be notified when your
                account is ready.
</Alert>

          )}
          {this.state.approval_status === 5 && (
            <div>


            <Alert
                  severity="error"
                  style={{ margin: "1rem 0" }}
                  action={
                    <Button
                      onClick={() => this.props.history.push("/vendordetails")}
                      variant="contained"
                      disableElevation
                      style={{ marginLeft: "0.5rem" }}
                      color="secondary"
                      size="small"
                    >
                      Resubmit
                    </Button>
                  }
                >
                  Your account has been rejected. You may resubmit it here.
                </Alert>
            </div>
          )}
          <div style={{ padding: "1rem" }}>
            <div className={classes.welcomeMessage}>
              Welcome back, {this.props.fetchInitialData.credentials.user.name}
            </div>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  Pending Payment Requests:
                  <span className={classes.unpaid}>
                    {this.state.noOfUnpaidInvoices}
                  </span>
                </div>
                <div>
                  Total Outstanding:
                  <span className={classes.unpaid}>
                    {formatter.format(this.state.amountOfUnpaidInvoices)}
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const styles = () => ({
  vendorHomeContainer: {
    margin: "0 auto",
    maxWidth: 900,
  },
  vendorLandingContainer: {
    margin: "0 auto",
    padding: "1rem",
    "@media (max-width: 700px)": {
      padding: "0.1rem",
    },
  },
  nonActive: {
    border: "solid #E86613 2px",
    padding: "1rem",
    borderRadius: 10,
    backgroundColor: "#FF9F61",
    margin: "1rem 1rem 0 1rem",
  },
  welcomeMessage: {
    fontSize: "1.3rem",
    marginBottom: "1rem",
  },
  unpaid: {
    marginLeft: "0.6rem",
    fontSize: "1.2rem",
  },
});

export default withStyles(styles)(Vendor);
