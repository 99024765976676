import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary } from '@material-ui/core';
import LambdaFetch from '../functions/FetchFromLambda';
import CommentTextArea from './CommentTextArea';
import './Comments.css';

var moment = require('moment-timezone');

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      users: [],
      isLoading: false,
      isLoadingComment: false,
      makeInternal: false,
      currentComment: { value: '', plain: '', mentions: null },
    };
    this.handleComment = this.handleComment.bind(this);
  }
  componentDidMount() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 50) {
      }
    });
    this.getData();
  }
  getData = async () => {
    let form = {
      get: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      objectId: this.props.objectId,
      objectType: this.props.objectType,
      vendorId: this.props.vendorId,
      isVendor: !!this.props.isVendor,
    };

    const info = await LambdaFetch(
      'comments',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(form)
    );
    const data = info.data;
    let users = [];
    let comments = [];

    if (data.comments) {
      comments = data.comments.reverse();
    }
    if (data.users) {
      users = data.users.map((u) => Object.assign({}, u));
    }
    this.setState({ comments, users });
  };
  handleComment = async (event) => {
    event.preventDefault();
    if (!event.target.comment.value) {
      return null;
    }
    this.setState({ isLoadingComment: true });

    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      objectType: this.props.objectType,
      objectId: this.props.objectId,
      commentString: event.target.comment.value,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      type: this.state.makeInternal ? 'internal' : 'public',
      isVendor: !!this.props.isVendor,
    };
    event.target.reset();

    const info = await LambdaFetch(
      'comments',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData)
    );

  
    if (info.success) {
      this.setState({
        comments: info.data.comments.reverse(),
        isLoadingComment: false,
      });
    } else {
      this.setState({
        isLoadingComment: false,
      });
    }
  };
  submitComment = async (isInternal, text, mentions) => {
    if (!text) {
      return null;
    }
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      objectType: this.props.objectType,
      objectId: this.props.objectId,
      commentString: text,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      type: isInternal ? 'internal' : 'public',
      isVendor: !!this.props.isVendor,
      mentions: mentions,
    };

    const info = await LambdaFetch(
      'comments',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData)
    );
    if (info.success) {
      this.setState({
        comments: info.data.comments.reverse(),
        isLoadingComment: false,
      });
    } else {
      this.setState({
        isLoadingComment: false,
      });
    }
    return 200;
  };
  render() {
    return (
      <div style={{ margin: '1rem auto' }}>
        <Accordion elevation={1}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {`Comments (${this.state.comments.length})`}
          </AccordionSummary>

          <div style={{ padding: '0 5% 1rem 5%' }}>
            <div style={{ display: 'block', margin: '1rem 0.5rem' }}>
              <CommentTextArea
                fetchInitialData={this.props.fetchInitialData}
                users={this.state.users.map((u) =>
                  Object.assign(
                    {},
                    {
                      id: u.uuid,
                      display: u.name,
                      username: u.username,
                    }
                  )
                )}
                submit={(a, b, c) => this.submitComment(a, b, c)}
                isVendor={this.props.fetchInitialData.credentials.isVendor}
              />
            </div>

            {this.state.comments.map((comment) => {
              const name =
                comment.USER_ID ===
                this.props.fetchInitialData.credentials.user.uuid
                  ? 'Me'
                  : comment.name;
              const diffDate = timeSince(
                moment(
                  moment(new Date(comment.TIMESTAMP)).utcOffset(
                    this.props.fetchInitialData.credentials.user.timezone
                  )._d
                ).format('lll')
              );
              return (
                <div
                  style={{
                    display: 'block',
                    margin: '0.5rem',
                    minHeight: '40px',
                    width: '95%',
                  }}
                  key={comment.COMMENT_ID}
                >
                  <Avatar
                    style={{
                      float: 'left',
                      marginRight: '12px',
                      backgroundColor: this.props.fetchInitialData.credentials.primaryAppColor,
                      fontSize: '0.8rem',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    {comment.avatar_content}
                  </Avatar>

                  <Typography
                    style={{
                      display: 'inline',
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: '1em',
                    }}
                  >
                    {name}
                  </Typography>
                  <span style={{ fontSize: '11px', margin: '0 0.2rem' }}>
                    •
                  </span>
                  <Typography
                    style={{
                      display: 'inline',
                      color: '#656565',
                      fontWeight: 'normal',
                      fontSize: '11px',
                    }}
                  >
                    {diffDate}
                  </Typography>
                  {comment.COMMENT_TYPE === 'internal' && (
                    <span
                      style={{
                        fontSize: '0.85rem',
                        color: '#545454',
                        marginLeft: '0.25rem',
                      }}
                    >
                      <i>(Internal Only)</i>
                    </span>
                  )}
                  <div style={{ margin: '1rem' }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: '#656565',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                      }}
                    >
                      <p className="commentString" dangerouslySetInnerHTML={{__html: comment.COMMENT_STRING}}/>
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>
    );
  }
}

function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' years ago';
  }
  if (interval > 0 && interval <= 1) {
    return '1 year ago';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months ago';
  }
  if (interval > 0 && interval <= 1) {
    return '1 month ago';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days ago';
  }
  if (interval > 0 && interval <= 1) {
    return '1 day ago';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours ago';
  }
  if (interval > 0 && interval <= 1) {
    return '1 hour ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes ago';
  }
  if (interval > 0 && interval <= 1) {
    return '1 minute ago';
  }
  return 'just now';
}

const styles = (theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '800px',
  },
  tableCell: {
    padding: '10px 20px',
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: 'rgb(232,102,19)',
    '&:hover': {
      backgroundColor: 'rgb(220,92,10)',
      color: 'white',
    },
  },
  outline: {
    fontSize: '1',
    border: 'solid black 1.5px',
    borderRadius: '10px',
  },
  resize: {
    fontSize: '0.8rem',
  },
  noPadding: {
    padding: 0,
  },
});

Comments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Comments);
