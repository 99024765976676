import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import { Grid, makeStyles, TextField } from "@material-ui/core";

const GooglePlacesAutocompleteComponent = ({
                                             id, stateName, label, types, setter, initialValue,
                                             iconMargin, clearAddr, isEdit
                                           }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialValue);
  const [defaultValue, setDefaultValue] = React.useState(initialValue);
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const clearInput = () => {
    if (isEdit) {
      setShowAutocomplete(true);
      setDefaultValue('');
      return;
    }
    setValue('');
    setDefaultValue('');
    clearAddr();
  }
  const setAddress = (newValue) => {
    setValue(newValue.label);
    setter(stateName, newValue.value)
    setDefaultValue(newValue.value.structured_formatting.main_text)
  }

  const closeIcon = (top) => (
      <CloseIcon
          style={{ top }}
          className={classes.closeIcon}
          onClick={clearInput}
      />
  )

  const autocompletionRequest = {
    types,
    componentRestrictions: {
      country: ['us'],
    }
  };

  const autocompleteStyles = {
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 15,
    }),
    "valueContainer": (provided) => ({
      ...provided,
      background: 'rgba(0, 0, 0, 0.09)',
      color: 'black',
      borderRadius: '4px 4px 0 0',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.13)'
      }
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '4px 4px 0 0',
      border: 'none',
      borderColor: 'none',
      borderBottom: '1px solid grey',
      cursor: 'text',
      '&:focus': {
        border: 'none',
        borderColor: 'none',
        borderBottom: '2px solid #3f50b5',
        boxShadow: 'none',
      },
      '&:hover': {
        border: 'none',
        borderColor: 'none',
        borderBottom: '1px solid black',
        boxShadow: 'none',
      },
    }),
    container: (provided) => ({
      ...provided,
      borderRadius: '4px 4px 0 0',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingTop: 10,
      color: 'rgba(0, 0, 0, 0.54)',
    }),
    input: (provided) => ({
      ...provided,
      paddingBottom: 0,
      paddingTop: 7,
    })
  };

  const selectProps = {
    placeholder: label,
    value,
    onChange: setAddress,
    styles: autocompleteStyles,
    "noOptionsMessage": ({ inputValue }) =>
        inputValue ? (
            <div className="editLink" onClick={() => setDefaultValue(inputValue)}>
              Select this location
            </div>
        ) : 'Start typing...',
  };

  const onChangeHandler = (event) => {
    if (event.target.value === '') clearInput();
    setValue(event.target.value)
    setDefaultValue(event.target.value);
  };

  return (
      <div>
        {(defaultValue || (isEdit && !showAutocomplete)) ? (
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                  id={id}
                  fullWidth
                  label="Addr1"
                  variant="filled"
                  margin="dense"
                  value={defaultValue}
                  onChange={onChangeHandler}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                      root: classes.textFieldRoot,
                    },
                  }}
              />
              {closeIcon(30)}
            </Grid>
        ) : (
            <>
              {closeIcon(iconMargin)}
              <GooglePlacesAutocomplete
                  className={classes.autocomplete}
                  autocompletionRequest={autocompletionRequest}
                  selectProps={selectProps}
              />
            </>
        )}
      </div>
  )
}

const useStyles = makeStyles({
  autocomplete: {
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    zIndex: 10,
    right: 15,
    cursor: 'pointer',
    border: '1px solid gray',
    borderRadius: '10px',
  }
})

export default GooglePlacesAutocompleteComponent;
