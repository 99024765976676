import React from "react";
import { Fab, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import { formatter } from "../../utils/tableCustoms";

const styles = (theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  editLink: {
    color: 'rgb(0, 104, 178)',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#E86613',
    '&:hover': {
      backgroundColor: '#E86613',
      color: 'white',
    },
  },
})

class Distributions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    const {classes} = this.props
    return (
        <>
          <h4>Distributions</h4>
          <Paper className={classes.paper}>
            <form onSubmit={this.onSubmit}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Line</TableCell>
                    {this.props.clientSegments.map((segment) => (
                        <TableCell key={segment} align="center">
                          {segment}
                        </TableCell>
                    ))}
                    <TableCell align="center">Account Category</TableCell>
                    <TableCell align="center">Total</TableCell>
                    {!!this.props.access.invoice_distribution && (
                        <TableCell/>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.currentGroupOfCodes.map((line, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        {this.props.clientSegments.map((seg, index) => (
                            <TableCell key={index} align="center">
                              {line['segment' + (index + 1)]}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                          {this.props.accountCatDic[line['accountCategory']]}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(line['total'])}
                        </TableCell>
                        {!!this.props.access.invoice_distribution && (
                            <TableCell
                                onClick={(event) => this.props.onEdit(event, index)}
                                align="center"
                            >
                              <Typography>
                                <span className={classes.editLink}>edit </span>
                              </Typography>
                            </TableCell>
                        )}
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!!this.props.access.invoice_distribution && (
                  <div style={{ marginLeft: '0.7rem' }}>
                    <Fab
                        size="small"
                        aria-label="Add"
                        style={{margin: '0.5rem'}}
                        className="primaryVLButton"
                        onClick={this.props.onAdd}
                    >
                      +
                    </Fab>
                  </div>
              )}
            </form>
          </Paper>
          </>
    )
  }
}

export default withStyles(styles)(Distributions);
