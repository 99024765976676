import React from 'react';
import {
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Dialog,
  ListItemText,
  List,
  Paper,
  CircularProgress,
  TextField,
  ListItem,
  Checkbox,
  FormControlLabel,
  Button,
  withStyles,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import LoadingButton from '../components/LoadingButton';
import ConfirmationDialog from './common/ConfirmationDialog';

const styles = () => ({
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 20,
    marginTop: -12,
    marginLeft: -12,
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, 0.9)',
    },
  },
  editLink: {
    color: 'rgba(0,0,255, 0.8)',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(0,0,255, 1)',
      textDecoration: 'underline',
    },
  },
});

class SettingsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddOpen: false,
      isLoading: false,
      isDeleting: false,
      editValue: null,
      addType: null,
      showConfirmation: false,
      addCheck: false,
      editCheck: false,
    };
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  addNew = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let value = event.target.newValue.value;

    this.props.addNew(
        (statusCode) => {
          if (statusCode === 200) {
            this.createSnack(`Successfully added ${value}`, 'success', 2000);
            this.setState({ isAddOpen: false, addRequired: false });
          } else if (statusCode === 402) {
            this.createSnack('Cannot add duplicate value', 'error', 2000);
          } else {
            this.createSnack('There was a error', 'error', 2000);
          }
          this.setState({ isLoading: false });
        },
        this.props.type,
        event.target.newValue.value,
        this.props.hasRequired && this.state.addRequired ? 1 : 0
    );
  };

  edit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let value = event.target.editValue.value;
    this.props.edit(
        (statusCode) => {
          if (statusCode === 200) {
            this.createSnack(`Successfully updated to ${value}`, 'success', 2000);
            this.setState({ isEditOpen: false });
          } else if (statusCode === 402) {
            this.createSnack('Cannot add duplicate value', 'error', 2000);
          } else {
            this.createSnack('There was a error', 'error', 2000);
          }
          this.setState({ isLoading: false });
        },
        this.props.type,
        this.state.editValue[this.props.idPropertyName],
        value,
        this.props.hasRequired && this.state.editRequired ? 1 : 0
    );
  };

  handleDelete = (id, type) => {
    this.setState({ isDeleting: true });
    this.props.delete(
        (status) => {
          if (status === 200) {
            this.createSnack('Successfully deleted value', 'success', 2000);
            this.setState({ isEditOpen: false });
          } else if (status === 402) {
            this.setState({ isDeleting: false });
          } else {
            this.createSnack('There was a error', 'error', 2000);
          }
          this.setState({ isDeleting: false });
        },
        id,
        type
    );
  };

  handleClose = () => {
    this.setState({
      isAddOpen: false,
      isEditOpen: false,
      showConfirmation: false,
    });
  };

  render() {
    const { classes } = this.props;

    const list = this.props.hasRequired
        ? this.props.list.sort((a, b) => b.required_flag - a.required_flag)
        : this.props.list;
    return (
        <div>
          <Dialog
              open={this.state.isAddOpen}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              fullWidth
              className="noOverflowX"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.addNew}>
              <DialogTitle id="alert-dialog-title">
                {`Add ${this.props.title}`}
              </DialogTitle>
              <DialogContent>
                <TextField
                    style={{ marginRight: '16px' }}
                    fullWidth
                    id="newValue"
                    label={this.props.title}
                    autoComplete="off"
                />
                {this.props.hasRequired && (
                    <FormControlLabel
                        style={{ marginTop: '0.5rem' }}
                        label="Required"
                        labelPlacement="end"
                        control={
                          <Checkbox
                              id="addRequired"
                              style={{ color: this.props.fetchInitialData.credentials.primaryAppColor}}
                              checked={this.state.addRequired}
                              onChange={(event) =>
                                  this.setState({
                                    addRequired: event.target.checked,
                                  })
                              }
                          />
                        }
                    />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Cancel</Button>
                <LoadingButton
                    buttonType="submit"
                    label="Submit"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                />
              </DialogActions>
            </form>
          </Dialog>

          <ConfirmationDialog
              // classes={classes}
              handleClose={this.handleClose}
              handleOpen={this.state.showConfirmation}
              dialogTitle={'Are you sure you want to delete this item?'}
              isDeleting={this.state.isDeleting}
              handleDelete={() => {
                this.handleDelete(
                    this.props.type,
                    this.state.editValue[this.props.idPropertyName]
                );
                this.setState({ showConfirmation: false });
              }}
          />
          {this.state.editValue && (
              <Dialog
                  open={this.state.isEditOpen}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  className="noOverflowX"
                  fullWidth
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.edit}>
                  <DialogTitle id="alert-dialog-title">
                    {`Edit ${this.props.title}`}
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                        style={{ marginRight: '16px' }}
                        fullWidth
                        id="editValue"
                        defaultValue={this.state.editValue[this.props.lookupProperty]}
                        label={this.props.title}
                        autoComplete="off"
                    />
                    {this.props.hasRequired && (
                        <FormControlLabel
                            style={{ marginTop: '0.5rem' }}
                            label="Required"
                            labelPlacement="end"
                            control={
                              <Checkbox
                                  id="editRequired"
                                  style={{ color: this.props.fetchInitialData.credentials.primaryAppColor }}
                                  checked={this.state.editRequired}
                                  onChange={(event) =>
                                      this.setState({
                                        editRequired: event.target.checked,
                                      })
                                  }
                              />
                            }
                        />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    {!!this.props.access.delete && (
                        <Button
                            style={{
                              marginRight: '0.5rem',
                              color: this.state.isDeleting ? '' : 'red',
                            }}
                            disabled={this.state.isDeleting}
                            onClick={() => {
                              this.setState({
                                showConfirmation: true,
                                isEditOpen: false,
                              });
                            }}
                        >
                          {this.state.isDeleting ? (
                              <Box>
                                <CircularProgress
                                    style={{ color: 'rgb(232,102,19)' }}
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                                Delete
                              </Box>
                          ) : (
                              'Delete'
                          )}
                        </Button>
                    )}
                    {!!this.props.access.edit && (
                        <LoadingButton
                            buttonType="submit"
                            label="Submit"
                            isLoading={this.state.isLoading}
                            color="primaryVLButton"
                        />
                    )}
                  </DialogActions>
                </form>
              </Dialog>
          )}

          <div>
            <h5 style={{ textAlign: 'center', marginBottom: '1rem' }}>
              {this.props.title}
            </h5>
            <Paper elevation={2}>
              <div style={{ padding: '16px', display: 'flex' }}>
                {!!this.props.access.add && (
                    <Button
                        variant="contained"
                        className="primaryVLButton"
                        onClick={() => this.setState({ isAddOpen: true })}
                    >
                      Add New
                    </Button>
                )}
              </div>
              <List
                  style={{ maxHeight: 300, overflow: 'auto' }}
                  aria-label="main mailbox folders"
              >
                {list.map((item, index) => {
                  return (
                      <ListItem dense key={index}>
                        <ListItemText
                            primary={item[this.props.lookupProperty]}
                            secondary={
                              this.props.hasRequired && (
                                  <i style={{ fontSize: '0.75rem' }}>
                                    {item.required_flag === 1 ? '(required)' : ''}
                                  </i>
                              )
                            }
                        />
                        <span
                            style={{ fontSize: '0.75rem' }}
                            className={classes.editLink}
                            onClick={() =>
                                this.setState({
                                  isEditOpen: true,
                                  editValue: item,
                                  editRequired:
                                      !!(this.props.hasRequired && item.required_flag === 1),
                                })
                            }
                        >
                      {this.props.access.edit ? 'edit' : 'view'}
                    </span>
                      </ListItem>
                  );
                })}
              </List>
            </Paper>
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(SettingsList));
