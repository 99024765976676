export const countriesList = ["USA", "CAD", "MEX"];

export const countriesOptions = [
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "CAD",
    label: "CAD",
  },
  {
    value: "MEX",
    label: "MEX",
  }
]

export const USAoptions = [
  {
    value: "AK",
    label: "AK",
  },
  {
    value: "AL",
    label: "AL",
  },
  {
    value: "AR",
    label: "AR",
  },
  {
    value: "AS",
    label: "AS",
  },
  {
    value: "AZ",
    label: "AZ",
  },
  {
    value: "CA",
    label: "CA",
  },
  {
    value: "CO",
    label: "CO",
  },
  {
    value: "CT",
    label: "CT",
  },
  {
    value: "DC",
    label: "DC",
  },
  {
    value: "DE",
    label: "DE",
  },
  {
    value: "FL",
    label: "FL",
  },
  {
    value: "GA",
    label: "GA",
  },
  {
    value: "GU",
    label: "GU",
  },
  {
    value: "HI",
    label: "HI",
  },
  {
    value: "IA",
    label: "IA",
  },
  {
    value: "ID",
    label: "ID",
  },
  {
    value: "IL",
    label: "IL",
  },
  {
    value: "IN",
    label: "IN",
  },
  {
    value: "KS",
    label: "KS",
  },
  {
    value: "KY",
    label: "KY",
  },
  {
    value: "LA",
    label: "LA",
  },
  {
    value: "MA",
    label: "MA",
  },
  {
    value: "MD",
    label: "MD",
  },
  {
    value: "ME",
    label: "ME",
  },
  {
    value: "MI",
    label: "MI",
  },
  {
    value: "MN",
    label: "MN",
  },
  {
    value: "MO",
    label: "MO",
  },
  {
    value: "MS",
    label: "MS",
  },
  {
    value: "MT",
    label: "MT",
  },
  {
    value: "NC",
    label: "NC",
  },
  {
    value: "ND",
    label: "ND",
  },
  {
    value: "NE",
    label: "NE",
  },
  {
    value: "NH",
    label: "NH",
  },
  {
    value: "NJ",
    label: "NJ",
  },
  {
    value: "NM",
    label: "NM",
  },
  {
    value: "NV",
    label: "NV",
  },
  {
    value: "NY",
    label: "NY",
  },
  {
    value: "OH",
    label: "OH",
  },
  {
    value: "OK",
    label: "OK",
  },
  {
    value: "OR",
    label: "OR",
  },
  {
    value: "PA",
    label: "PA",
  },
  {
    value: "PR",
    label: "PR",
  },
  {
    value: "RI",
    label: "RI",
  },
  {
    value: "SC",
    label: "SC",
  },
  {
    value: "SD",
    label: "SD",
  },
  {
    value: "TN",
    label: "TN",
  },
  {
    value: "TX",
    label: "TX",
  },
  {
    value: "UT",
    label: "UT",
  },
  {
    value: "VA",
    label: "VA",
  },
  {
    value: "VI",
    label: "VI",
  },
  {
    value: "VT",
    label: "VT",
  },
  {
    value: "WA",
    label: "WA",
  },
  {
    value: "WI",
    label: "WI",
  },
  {
    value: "WV",
    label: "WV",
  },
  {
    value: "WY",
    label: "WY",
  },
];

export const USAstates = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const MEXstates = [
  "AG",
  "BC",
  "BS",
  "CH",
  "CL",
  "CM",
  "CO",
  "CS",
  "DF",
  "DG",
  "GR",
  "GT",
  "HG",
  "JA",
  "ME",
  "MI",
  "MO",
  "NA",
  "NL",
  "OA",
  "PB",
  "QE",
  "QR",
  "SI",
  "SL",
  "SO",
  "TB",
  "TL",
  "TM",
  "VE",
  "YU",
  "ZA",
];
export const MEXoptions = [
  {
    "value": "AG",
    "label": "AG"
  },
  {
    "value": "BC",
    "label": "BC"
  },
  {
    "value": "BS",
    "label": "BS"
  },
  {
    "value": "CH",
    "label": "CH"
  },
  {
    "value": "CL",
    "label": "CL"
  },
  {
    "value": "CM",
    "label": "CM"
  },
  {
    "value": "CO",
    "label": "CO"
  },
  {
    "value": "CS",
    "label": "CS"
  },
  {
    "value": "DF",
    "label": "DF"
  },
  {
    "value": "DG",
    "label": "DG"
  },
  {
    "value": "GR",
    "label": "GR"
  },
  {
    "value": "GT",
    "label": "GT"
  },
  {
    "value": "HG",
    "label": "HG"
  },
  {
    "value": "JA",
    "label": "JA"
  },
  {
    "value": "ME",
    "label": "ME"
  },
  {
    "value": "MI",
    "label": "MI"
  },
  {
    "value": "MO",
    "label": "MO"
  },
  {
    "value": "NA",
    "label": "NA"
  },
  {
    "value": "NL",
    "label": "NL"
  },
  {
    "value": "OA",
    "label": "OA"
  },
  {
    "value": "PB",
    "label": "PB"
  },
  {
    "value": "QE",
    "label": "QE"
  },
  {
    "value": "QR",
    "label": "QR"
  },
  {
    "value": "SI",
    "label": "SI"
  },
  {
    "value": "SL",
    "label": "SL"
  },
  {
    "value": "SO",
    "label": "SO"
  },
  {
    "value": "TB",
    "label": "TB"
  },
  {
    "value": "TL",
    "label": "TL"
  },
  {
    "value": "TM",
    "label": "TM"
  },
  {
    "value": "VE",
    "label": "VE"
  },
  {
    "value": "YU",
    "label": "YU"
  },
  {
    "value": "ZA",
    "label": "ZA"
  }
]
export const CADoptions = [
  {
    "value": "AB",
    "label": "AB"
  },
  {
    "value": "BC",
    "label": "BC"
  },
  {
    "value": "MB",
    "label": "MB"
  },
  {
    "value": "NB",
    "label": "NB"
  },
  {
    "value": "NL",
    "label": "NL"
  },
  {
    "value": "NT",
    "label": "NT"
  },
  {
    "value": "NS",
    "label": "NS"
  },
  {
    "value": "NU",
    "label": "NU"
  },
  {
    "value": "ON",
    "label": "ON"
  },
  {
    "value": "PE",
    "label": "PE"
  },
  {
    "value": "QC",
    "label": "QC"
  },
  {
    "value": "SK",
    "label": "SK"
  },
  {
    "value": "YT",
    "label": "YT"
  }
]
export const CADstates = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];
