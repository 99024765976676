import React from 'react';
import '../containers/Details.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import '../containers/VendorForm.css';
import DropDown from '../components/DropDown';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Comments from '../components/Comments';

import moment from 'moment-timezone';

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
};

class VendorFormDetails extends React.Component {
  constructor(props) {
    super(props);
    this.masterPHONE_NUM0 = React.createRef();
    this.masterFAX_NUM0 = React.createRef();
    this.contactPHONE_NMBR0 = React.createRef();
    this.contactMOBILE_NUM0 = React.createRef();
    this.contactFAX_NMBR0 = React.createRef();
    this.contactPHONE_NMBR1 = React.createRef();
    this.contactMOBILE_NUM1 = React.createRef();
    this.contactFAX_NMBR1 = React.createRef();
    this.state = {
      formButton: null,
      isOpen: false,
      isAccept: null,
      content: null,
      updatedFields: null,
      dropdowns: null,
      termCode: null,
      invCurrency: null,
      languageCode: null,
      vendorGroup: null,
      venClass: null,
      taxCode: null,
      holdCode: null,
      distributionCode: null,
      accrualCode: null,
      paymentCode: null,
      cashCode: null,
      reason: '',
      dropdownReason: null,
      noNumberError: '',
      errors: {
        masterPHONE_NUM0: '',
        masterFAX_NUM0: '',
        contactPHONE_NMBR0: '',
        contactMOBILE_NUM0: '',
        contactFAX_NMBR0: '',
        contactPHONE_NMBR1: '',
        contactMOBILE_NUM1: '',
        contactFAX_NMBR1: '',
      },
      fileLoad: '',
      isLoading: false,
    };
    this.dropDownChange = this.dropDownChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.vendorId !== prevProps.vendorId) {
      this.setState({ isLoading: true });
      this.getData();
    }
  }
  getData() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      vendorId: this.props.vendorId,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonVendorId = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-vendor-forms`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: jsonVendorId,
    })
      .then((resp) => resp.json())
      .then((data) => {
        const termCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'termCode'
        );
        const invCurrencyDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'invCurrency'
        );
        const languageCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'languageCode'
        );
        const vendorGroupDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'vendorGroup'
        );
        const venClassDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'venClass'
        );
        const taxCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'taxCode'
        );
        const holdCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'holdCode'
        );
        const distributionCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'distributionCode'
        );
        const accrualCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'accrualCode'
        );
        const paymentCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'paymentCode'
        );
        const cashCodeDropdown = data.dropdowns.filter(
          (option) => option.R_KEY === 'cashCode'
        );
        let rejectReason = data.rejectReasons;
        rejectReason.push({ R_VALUE: 'other', DESCRIPTION: 'other' });
        const dropdownMenus = {
          vendorGroupDropdown: vendorGroupDropdown,
          venClassDropdown: venClassDropdown,
          taxCodeDropdown: taxCodeDropdown,
          holdCodeDropdown: holdCodeDropdown,
          distributionCodeDropdown: distributionCodeDropdown,
          accrualCodeDropdown: accrualCodeDropdown,
          paymentCodeDropdown: paymentCodeDropdown,
          cashCodeDropdown: cashCodeDropdown,
          termCodeDropdown: termCodeDropdown,
          invCurrencyDropdown: invCurrencyDropdown,
          languageCodeDropdown: languageCodeDropdown,
          rejectDropdown: rejectReason,
        };
        let reject;
        if (data.rejectReasons.length !== 0) {
          reject = data.rejectReasons[0].DESCRIPTION;
        } else {
          reject = 'other';
        }
        data.activity = data.activity.reverse();
        this.setState({
          content: data,
          recievedInfo: true,
          dropdowns: dropdownMenus,
          taxClassification: data.master[0].TAX_CLASS,
          termCode: data.master[0].TERM_CODE,
          invCurrency: data.master[0].INV_CURRENCY,
          languageCode: data.master[0].LANGUAGE_CODE,
          dropdownReason: reject,
          vendorGroup: data.master[0].VENDOR_GROUP,
          venClass: data.master[0].VEN_CLASS,
          taxCode: data.master[0].TAX_CODE,
          holdCode: data.master[0].HLD_CODE,
          distributionCode: data.master[0].DIST_CODE,
          accrualCode: data.master[0].ACCR_CODE,
          paymentCode: data.master[0].BANK_INST_CODE,
          cashCode: data.master[0].CASH_CODE,
          isLoading: false,
        });
      })
      .catch((error) => console.log(error));
  }
  dropDownChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  validate(validateFields) {
    const { errors } = this.state;
    let isError = false;

    if (this.state.content.contact.length > 1) {
      if (validateFields.contactFAX_NMBR1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactFAX_NMBR1'].value)
        ) {
          isError = true;
          errors.contactFAX_NMBR1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactFAX_NMBR1.current) {
            this.contactFAX_NMBR1.current.focus();
          }
        } else {
          errors.contactFAX_NMBR1 = '';
        }
      } else {
        errors.contactFAX_NMBR1 = '';
      }

      if (validateFields.contactMOBILE_NUM1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactMOBILE_NUM1'].value)
        ) {
          isError = true;
          errors.contactMOBILE_NUM1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactMOBILE_NUM1.current) {
            this.contactMOBILE_NUM1.current.focus();
          }
        } else {
          errors.contactMOBILE_NUM1 = '';
        }
      } else {
        errors.contactMOBILE_NUM1 = '';
      }

      if (validateFields.contactPHONE_NMBR1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactPHONE_NMBR1'].value)
        ) {
          isError = true;
          errors.contactPHONE_NMBR1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactPHONE_NMBR1.current) {
            this.contactPHONE_NMBR1.current.focus();
          }
        } else {
          errors.contactPHONE_NMBR1 = '';
        }
      } else {
        errors.contactPHONE_NMBR1 = '';
      }
    }

    const masterPHONE_NUM0 = this.validatePhoneNumber(
        validateFields['masterPHONE_NUM0'].value
    );
    const contactPHONE_NMBR0 = this.validatePhoneNumber(
        validateFields['contactPHONE_NMBR0'].value
    );

    const contactMOBILE_NUM0 = this.validatePhoneNumber(
        validateFields['contactMOBILE_NUM0'].value
    );

    if (validateFields.contactFAX_NMBR0.value !== '') {
      if (!this.validatePhoneNumber(validateFields['contactFAX_NMBR0'].value)) {
        isError = true;
        errors.contactFAX_NMBR0 = 'Must be of format xxx-xxx-xxxx';
        if (this.contactFAX_NMBR0.current) {
          this.contactFAX_NMBR0.current.focus();
        }
      } else {
        errors.contactFAX_NMBR0 = '';
      }
    } else {
      errors.contactFAX_NMBR0 = '';
    }

    if (!contactMOBILE_NUM0) {
      isError = true;
      errors.contactMOBILE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.contactMOBILE_NUM0.current) {
        this.contactMOBILE_NUM0.current.focus();
      }
    } else {
      errors.contactMOBILE_NUM0 = '';
    }

    if (!contactPHONE_NMBR0) {
      isError = true;
      errors.contactPHONE_NMBR0 = 'Must be of format xxx-xxx-xxxx';
      if (this.contactPHONE_NMBR0.current) {
        this.contactPHONE_NMBR0.current.focus();
      }
    } else {
      errors.contactPHONE_NMBR0 = '';
    }

    if (validateFields.masterFAX_NUM0.value !== '') {
      if (!this.validatePhoneNumber(validateFields['masterFAX_NUM0'].value)) {
        isError = true;
        errors.masterFAX_NUM0 = 'Must be of format xxx-xxx-xxxx';
        if (this.masterFAX_NUM0.current) {
          this.masterFAX_NUM0.current.focus();
        }
      } else {
        errors.masterFAX_NUM0 = '';
      }
    } else {
      errors.masterFAX_NUM0 = '';
    }

    if (!masterPHONE_NUM0) {
      isError = true;
      errors.masterPHONE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.masterPHONE_NUM0.current) {
        this.masterPHONE_NUM0.current.focus();
      }
    } else {
      errors.masterPHONE_NUM0 = '';
    }

    if (!masterPHONE_NUM0) {
      isError = true;
      errors.masterPHONE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.masterPHONE_NUM0.current) {
        this.masterPHONE_NUM0.current.focus();
      }
    } else {
      errors.masterPHONE_NUM0 = '';
    }

    if (isError) {
      this.setState({
        errors: errors,
      });
    }
    return isError;
  }

  validatePhoneNumber(number) {
    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return numberPattern.test(number);
  }

  validateVNum(vendorNumber) {
    let isError = false;
    if (this.state.formButton === 'accept') {
      if (
        this.props.fetchInitialData.credentials.clientInfo
          .erp_vendor_auto_numbering === 0 &&
        vendorNumber === ''
      ) {
        isError = true;
        this.setState({
          noNumberError: 'Must fill in Vendor Number',
        });
      }
    }
    return isError;
  }

  handleForm(event) {
    event.preventDefault();
    const form = event.target;
    const masterPairs = {};
    const addressPairs = [];
    const contactPairs = [];

    const err = this.validateVNum(form['autoVendorNumber'].value);

    if (this.state.formButton === 'accept') {
      const validateErrors = this.validate(form);
      if (this.state.formButton === 'accept' && validateErrors === true) {
        return null;
      }
    }
    if (!err) {
      masterPairs['VENDOR_ID'] = this.state.content.master[0].VENDOR_ID;

      for (let i = 0; i < apcVenMast.length; i++) {
        const masterId = apcVenMast[i]['fieldId'];
        masterPairs[masterId] = form['master' + masterId + 0].value;
      }
      masterPairs.TAX_CLASS = this.state.taxClassification;
      masterPairs.TERM_CODE = form['termCode'].value;
      masterPairs.INV_CURRENCY = form['invCurrency'].value;
      masterPairs.LANGUAGE_CODE = form['languageCode'].value;
      masterPairs.VENDOR_GROUP = form['vendorGroup'].value;
      masterPairs.VEN_CLASS = form['venClass'].value;
      masterPairs.TAX_CODE = form['taxCode'].value;
      masterPairs.HLD_CODE = form['holdCode'].value;
      masterPairs.DIST_CODE = form['distributionCode'].value;
      masterPairs.ACCR_CODE = form['accrualCode'].value;
      masterPairs.BANK_INST_CODE = form['paymentCode'].value;
      masterPairs.CASH_CODE = form['cashCode'].value;
      masterPairs.AUTO_VENDOR_NUMBER = form['autoVendorNumber'].value;
      masterPairs.FILTER_VALUE = form['clientFilter'].value;

      for (let i = 0; i < this.state.content.address.length; i++) {
        const addressObj = { VENDOR_ID: this.state.content.master[0].VENDOR_ID };
        for (let j = 0; j < apcVenAddr.length; j++) {
          const addressId = apcVenAddr[j]['fieldId'];
          const addressTextId = 'address' + addressId + i;
          addressObj[addressId] = form[addressTextId].value;
        }
        addressObj.ADDR_ID = this.state.content.address[i]['ADDR_ID'];
        addressPairs.push(addressObj);
      }

      for (let i = 0; i < this.state.content.contact.length; i++) {
        const contactObj = { VENDOR_ID: this.state.content.master[0].VENDOR_ID };
        for (let j = 0; j < apcContact.length; j++) {
          const contactId = apcContact[j]['fieldId'];
          const contactTextId = 'contact' + contactId + i;
          contactObj[contactId] = form[contactTextId].value;
        }
        contactObj.CONTACT_ID = this.state.content.contact[i]['CONTACT_ID'];
        contactPairs.push(contactObj);
      }

      const updatedFields = {
        master: masterPairs,
        address: addressPairs,
        contact: contactPairs,
      };
      this.setState({
        updatedFields: updatedFields,
        isOpen: true,
      });
    }
  }
  handleTaxChange(event) {
    this.setState({ taxClassification: event.target.value });
  }
  handleClickOpen() {
    this.setState({ isOpen: false });
  }
  handleChange(event) {
    this.setState({ reason: event.target.value });
  }
  handleClose() {
    this.setState({ isOpen: false });
  }
  handleSave(saveFields) {
    this.setState({ isLoading: true });
    if (this.state.formButton === 'save') {
      saveFields = this.state.updatedFields;
    }
    saveFields[
      'jsonWebTok'
    ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
    saveFields['activity'] = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.state.content.master[0].VENDOR_ID,
      ACTIVITY_TYPE: 'SAVE',
      ACTIVITY_STRING: ' Saved updates',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    const stringUpdate = JSON.stringify(saveFields);
    fetch(`${process.env.REACT_APP_API}/save-apc`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: stringUpdate,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (this.state.formButton === 'save' && data.statusCode === 200) {
          this.getData();
          window.alert('Successfully saved changes');
        }
      })
      .catch((error) => {
        window.alert('Error: ', error);
      });
  }
  handleAccept(save) {
    this.setState({
      isOpen: false,
      isLoading: true,
    });
    if (this.state.formButton === 'save') {
      this.handleSave();
      return null;
    } else {
      const update = Object.assign(this.state.updatedFields);

      if (save) {
        this.handleSave(update);
      }
      let fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        objectType: 'VENDOR',
        objectId: this.state.content.master[0].VENDOR_ID,
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/vendor`,
      };
      if (this.state.formButton === 'accept') {
        fetchData['action'] = 'approve';
        fetchData[
          'currentLevel'
        ] = this.state.content.master[0].APPROVAL_STATUS_LEVEL;
      } else if (this.state.formButton === 'deny') {
        fetchData['action'] = 'deny';
        if (this.state.dropdownReason === 'other') {
          fetchData['reason'] = this.state.reason;
        } else {
          fetchData['reason'] = this.state.dropdownReason;
        }
      }
      const fetchString = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/approve-deny-apc`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: this.props.fetchInitialData.credentials.user
            .jsonWebTok,
        },
        body: fetchString,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if (this.state.formButton === 'accept') {
              window.alert('Successfully Approved Vendor');
            }
            if (this.state.formButton === 'deny') {
              window.alert('Successfully Denied Vendor');
            }
            this.setState({ isLoading: false });
            this.props.history.push('/approver');
          } else if (
            data.errorMessage ===
            'ER_SIGNAL_EXCEPTION: You are not supposed to be here'
          ) {
            window.alert('You are not supppose to be here');
            this.setState({ isLoading: false });
          } else {
            this.setState({ isLoading: false });
          }
          this.props.fetchInitialData.getNotificationCount(
            this.props.fetchInitialData.credentials.user.clientId,
            this.props.fetchInitialData.credentials.user.jsonWebTok
          );
          this.getData();
        })
        .catch((error) => {
          window.alert(error);
        });
    }
  }

  getFile(key, originalName) {
    this.setState({ fileLoad: key });
    const getObject = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      key: `${this.props.fetchInitialData.credentials.clientInfo.client_id}/${key}`,
      activity: {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: 'DOWNLOAD',
        ACTIVITY_STRING: 'downloaded ' + originalName,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      },
    };
    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 402) {
          window.alert('You do not have credentials to see this file');
        }
        if (data.errorMessage) {
          window.alert(data.errorMessage);
        } else {
          const parsed = JSON.parse(data.body);
          const read = Buffer.from(parsed.Body.data).toString('base64');
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = `data:application/pdf;base64,${read}`;
          a.download = originalName;
          a.click();
          this.getData();
          this.setState({ fileLoad: false });
        }
      })
      .catch((err) => console.log(err));
  }

  approverTextField(input, index, fieldGroup, readOnly) {
    const { classes } = this.props;

    if (
      input.fieldId === 'PHONE_NMBR' ||
      input.fieldId === 'PHONE_NUM' ||
      input.fieldId === 'MOBILE_NUM' ||
      input.fieldId === 'FAX_NMBR' ||
      input.fieldId === 'FAX_NUM'
    ) {
      return (
        <InputMask
          key={input.fieldId}
          mask="999-999-9999"
          defaultValue={input.defaultValue}
        >
          {() => (
            <TextField
              inputRef={this[fieldGroup + input.fieldId + index]}
              className="textfield"
              key={fieldGroup + input.fieldId + index}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              fullWidth
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <span />
                  </InputAdornment>
                ),
              }}
              error={
                this.state.errors[fieldGroup + input.fieldId + index] !== ''
              }
              helperText={this.state.errors[fieldGroup + input.fieldId + index]}
            />
          )}
        </InputMask>
      );
    }
    if (readOnly) {
      return (
        <TextField
          className="textfield"
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          disabled
          variant="filled"
          margin="dense"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.textFieldRoot,
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      );
    }
    if (index === null) index = '';
    if (input.half) {
      return (
        <span key={input.fieldId} style={{ marginRight: '5px' }}>
          <TextField
            className="textfield"
            key={input.fieldId}
            id={fieldGroup + input.fieldId + index}
            label={input.fieldName}
            defaultValue={input.defaultValue}
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              ),
            }}
          />
        </span>
      );
    } else {
      return (
        <TextField
          className="textfield"
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          variant="filled"
          margin="dense"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.textFieldRoot,
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
          }}
        />
      );
    }
  }
  render() {
    const { classes } = this.props;
    if (this.state.isLoading) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    } else if (!this.state.content) {
      return null;
    } else {
      const master = meta.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy['defaultValue'] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });
      const contacts = this.state.content.contact.map((contact, index) => {
        return contactInputs.map((input) => {
          const inputCopy = Object.assign({}, input);
          inputCopy['defaultValue'] = this.state.content.contact[index][input.fieldId];
          return inputCopy;
        });
      });
      const addresses = this.state.content.address.map((address, index) => {
        return addressInputs.map((input) => {
          const inputCopy = Object.assign({}, input);
          inputCopy['defaultValue'] = this.state.content.address[index][input.fieldId];
          return inputCopy;
        });
      });
      const banking = bankingInputs.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy['defaultValue'] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });

      const preparer = preparerInfo.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy['defaultValue'] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });
      const noAttach =
        this.state.content.attachment.length < 1
          ? { display: 'visible' }
          : { display: 'none' };
      const dropdownDefaults = {
        vendorGroupDropdown: '',
        venClassDropdown: '',
        taxCodeDropdown: '',
        holdCodeDropdown: '',
        distributionCodeDropdown: '',
        accrualCodeDropdown: '',
        paymentCodeDropdown: '',
        cashCodeDropdown: '',
      };
      for (let property in dropdownDefaults) {
        if (dropdownDefaults.hasOwnProperty(property)) {
          if (
            !this.state.dropdowns[property] ||
            this.state.dropdowns[property].length === 0
          ) {
            dropdownDefaults[property] = '';
          } else {
            dropdownDefaults[property] = this.state.dropdowns[
              property
            ][0].R_VALUE;
          }
        }
      }

      if (this.state.vendorGroup !== null) {
        dropdownDefaults.vendorGroupDropdown = this.state.vendorGroup;
      }
      if (this.state.venClass !== null) {
        dropdownDefaults.venClassDropdown = this.state.venClass;
      }
      if (this.state.taxCode !== null) {
        dropdownDefaults.taxCodeDropdown = this.state.taxCode;
      }
      if (this.state.holdCode !== null) {
        dropdownDefaults.holdCodeDropdown = this.state.holdCode;
      }
      if (this.state.distributionCode !== null) {
        dropdownDefaults.distributionCodeDropdown = this.state.distributionCode;
      }
      if (this.state.accrualCode !== null) {
        dropdownDefaults.accrualCodeDropdown = this.state.accrualCode;
      }
      if (this.state.paymentCode !== null) {
        dropdownDefaults.paymentCodeDropdown = this.state.paymentCode;
      }
      if (this.state.cashCode !== null) {
        dropdownDefaults.cashCodeDropdown = this.state.cashCode;
      }
      let message,
        saveAnd = 'Okay',
        action = '';

      if (this.state.formButton === 'save') {
        message = 'You are about to save the changes you have made';
      } else if (this.state.formButton === 'deny') {
        action = 'Deny';
        saveAnd = 'Save and Deny';
        message =
          "You are about to deny this vendor's application. Please give the reason why:";
      } else {
        action = 'Approve';
        saveAnd = 'Save and Approve';
        message = 'You are about to accept this vendor into your ERP system';
      }

      const dropdownReason = !(this.state.formButton === 'deny') ? (
        ''
      ) : (
        <DropDown
          id="dropdownReason"
          showDescription
          default={this.state.dropdownReason}
          options={this.state.dropdowns.rejectDropdown}
          label="Rejection Reason"
          passUp={this.dropDownChange}
        />
      );
      const reasonMessage =
        this.state.dropdownReason === 'other' &&
        this.state.formButton !== 'save' ? (
          <div>
            <TextField
              id="reason"
              multiline
              required
              rows="3"
              label="Please provide a reason"
              fullWidth
              onBlur={this.handleChange}
              margin="normal"
              variant="filled"
            />
          </div>
        ) : (
          ''
        );

      const showAutoNumbering =
        this.props.fetchInitialData.credentials.clientInfo
          .erp_vendor_auto_numbering === 0
          ? {}
          : { display: 'none' };

      const load = this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <CircularProgress
            style={{ marginLeft: '10px', color: '#E86613' }}
            size="20px"
          />
        </Fade>
      ) : (
        ''
      );
      return (
        <div>
          <div>
            <Dialog
              open={this.state.isOpen}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
                {dropdownReason}
                {reasonMessage}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleClose}
                >
                  Back
                </Button>
                {(this.state.formButton === 'accept' ||
                  this.state.formButton === 'deny') && (
                  <Button
                    onClick={() => this.handleAccept(false)}
                    color="primary"
                    variant="contained"
                    style={{ backgroundColor: '#545454', color: '#FFFFFF' }}
                  >
                    {action}
                  </Button>
                )}
                <Button
                  onClick={() => this.handleAccept(true)}
                  autoFocus
                  variant="contained"
                  style={{ backgroundColor: '#E86613', color: '#FFFFFF' }}
                >
                  {saveAnd}
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div className="formVendor">
            {/* <div>
              <h2>{this.state.content.master[0]["VENDOR"]}</h2>
              <div style={{ fontSize: "10px" }}>
                Submission Date: {submissionDate}
              </div>
            </div>
            <hr /> */}
            <form onSubmit={this.handleForm}>
              <Grid container justify="flex-start" spacing={24}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6">
                    {
                      this.props.fetchInitialData.credentials.clientInfo
                        .filter_value_label
                    }
                  </Typography>
                  <TextField
                    id="clientFilter"
                    className="textfield"
                    variant="filled"
                    margin="dense"
                    defaultValue={this.state.content.master[0].FILTER_VALUE}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <h5>Vendor Information</h5>
                  {master.map((input) => {
                    return this.approverTextField(
                      input,
                      0,
                      'master',
                      input.readOnly
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="h6" style={{ paddingTop: '2%' }}>
                    Tax Classification
                  </Typography>

                  <FormControl margin="dense" component="fieldset">
                    <FormLabel component="legend" />
                    <RadioGroup
                      aria-label="taxClassification"
                      name="taxClassification"
                      value={this.state.taxClassification}
                      onChange={this.handleTaxChange}
                    >
                      <FormControlLabel
                        value="Sole Proprietor"
                        control={
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Sole Proprietor"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Corporation"
                        control={
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Corporation"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="S-Corp"
                        control={
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="S-Corp"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Partnership"
                        control={
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Partnership"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {addresses.map((inputGroup, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <h5>Address #{index + 1}</h5>
                      <div>
                        {inputGroup.map((input) => {
                          return this.approverTextField(
                            input,
                            index,
                            'address'
                          );
                        })}
                      </div>
                    </Grid>
                  );
                })}
                <div style={{ width: '100%' }} />
                {contacts.map((inputGroup, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <h5>Contact #{index + 1}</h5>
                      <div>
                        {inputGroup.map((input) => {
                          return this.approverTextField(
                            input,
                            index,
                            'contact'
                          );
                        })}
                      </div>
                    </Grid>
                  );
                })}
                <div style={{ width: '100%' }} />

                <Grid item xs={12} sm={6} md={6}>
                  <h5>Banking Information</h5>
                  {banking.map((input) => {
                    return this.approverTextField(
                      input,
                      0,
                      'master',
                      input.readOnly
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <h5>Payment Information</h5>
                  <DropDown
                    id="termCode"
                    options={this.state.dropdowns.termCodeDropdown}
                    default={this.state.termCode}
                    label="Payment Terms"
                    passUp={this.dropDownChange}
                  />
                  <DropDown
                    id="invCurrency"
                    options={this.state.dropdowns.invCurrencyDropdown}
                    default={this.state.invCurrency}
                    label="Invoice Currency"
                    passUp={this.dropDownChange}
                  />
                  <DropDown
                    id="languageCode"
                    options={this.state.dropdowns.languageCodeDropdown}
                    default={this.state.languageCode}
                    label="Language"
                    passUp={this.dropDownChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    className="textfield"
                    id="masterCOMMENT0"
                    label="Describe your service/products"
                    fullWidth
                    defaultValue={this.state.content.master[0]['COMMENT']}
                    multiline
                    rows="2"
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <h5>Prepaper's Information</h5>
                  {preparer.map((input) => {
                    return this.approverTextField(
                      input,
                      0,
                      'master',
                      input.readOnly
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <h5>Attachments</h5>
                  <span style={noAttach}>
                    Therer were no attachments submitted with this application
                  </span>
                  {this.state.content.attachment.map((file) => {
                    return (
                      <div key={file.ATTACHMENT_ID}>
                        {this.state.fileLoad === file.S3_FILE_NAME && (
                          <CircularProgress
                            style={{ color: 'rgb(232,102,19)' }}
                            variant="indeterminate"
                            size={16}
                            thickness={2}
                          />
                        )}
                        <span
                          className="downloadable"
                          download={file.ORIGINAL_NAME}
                          onClick={() =>
                            this.getFile(file.ATTACHMENT_ID, file.ORIGINAL_NAME)
                          }
                        >
                          {file.ORIGINAL_NAME}
                        </span>
                        {file.ATTACHMENT_TYPE && (
                          <span> - {file.ATTACHMENT_TYPE}</span>
                        )}
                        {this.state.fileLoad === file.ATTACHMENT_ID && (
                          <CircularProgress
                            style={{
                              marginLeft: '0.5rem',
                              color: 'rgb(232,102,19)',
                            }}
                            variant="indeterminate"
                            size={16}
                            thickness={2}
                          />
                        )}
                      </div>
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <h5>For Approver Only</h5>
                  <div>
                    <div className="row">
                      <div className="col">
                        <DropDown
                          id="vendorGroup"
                          showWithDescription
                          options={this.state.dropdowns.vendorGroupDropdown}
                          default={dropdownDefaults.vendorGroupDropdown}
                          label="Vendor Group"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="venClass"
                          showWithDescription
                          options={this.state.dropdowns.venClassDropdown}
                          default={dropdownDefaults.venClassDropdown}
                          label="Vendor Class"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="taxCode"
                          options={this.state.dropdowns.taxCodeDropdown}
                          default={dropdownDefaults.taxCodeDropdown}
                          label="Tax Code"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="holdCode"
                          showWithDescription
                          options={this.state.dropdowns.holdCodeDropdown}
                          default={dropdownDefaults.holdCodeDropdown}
                          label="Hold Code"
                          passUp={this.dropDownChange}
                        />
                      </div>

                      <div className="col">
                        <DropDown
                          id="distributionCode"
                          options={
                            this.state.dropdowns.distributionCodeDropdown
                          }
                          default={dropdownDefaults.distributionCodeDropdown}
                          label="Distribution Code"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="accrualCode"
                          options={this.state.dropdowns.accrualCodeDropdown}
                          default={dropdownDefaults.accrualCodeDropdown}
                          label="Accrual Code"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="paymentCode"
                          showWithDescription
                          options={this.state.dropdowns.paymentCodeDropdown}
                          default={dropdownDefaults.paymentCodeDropdown}
                          label="Payment Code"
                          passUp={this.dropDownChange}
                        />
                        <DropDown
                          id="cashCode"
                          options={this.state.dropdowns.cashCodeDropdown}
                          default={dropdownDefaults.cashCodeDropdown}
                          label="Cash Code"
                          passUp={this.dropDownChange}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid style={showAutoNumbering} item xs={6} sm={6} md={6}>
                  <TextField
                    id="autoVendorNumber"
                    label="ERP Vendor Number"
                    fullWidth
                    defaultValue={
                      this.state.content.master[0].LAWSON_VENDOR_NUMBER
                    }
                    margin="dense"
                    variant="filled"
                    error={this.state.noNumberError !== ''}
                    helperText={this.state.noNumberError}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <span>
                    <Button
                      variant="contained"
                      id="save"
                      type="submit"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          formButton: 'save',
                          noNumberError: '',
                        });
                      }}
                      style={{
                        color: 'white',
                      }}
                    >
                      Save
                    </Button>
                  </span>
                  <span>
                    <Button
                      variant="contained"
                      id="deny"
                      type="submit"
                      onClick={() => {
                        this.setState({
                          formButton: 'deny',
                          noNumberError: '',
                        });
                      }}
                      style={{
                        backgroundColor: '#656565',
                        color: 'white',
                        margin: '0.3rem',
                      }}
                    >
                      Deny
                    </Button>
                  </span>
                  <span>
                    <Button
                      variant="contained"
                      id="accept"
                      type="submit"
                      onClick={() => {
                        this.setState({
                          formButton: 'accept',
                          noNumberError: '',
                        });
                      }}
                      style={{
                        backgroundColor: '#E86613',
                        color: 'white',
                      }}
                    >
                      Accept
                    </Button>
                  </span>
                  {load}
                </Grid>
              </Grid>
            </form>
            <Comments
              fetchInitialData={this.props.fetchInitialData}
              objectId={window.location.href.split('details/')[1]}
              objectType="VENDOR"
            />
            {this.state.content.activity.length > 0 && (
              <div style={{ margin: '1rem auto' }}>
                <h5>Vendor Request History</h5>
                <div style={{ padding: '0.5rem 0', marginTop: '1rem' }}>
                  <Paper className={classes.paper}>
                    {this.props.fetchInitialData.credentials.appWidth > 599 ? (
                      <Table style={{ overflowX: 'auto' }} padding="dense">
                        <TableBody>
                          {this.state.content.activity.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.ACTIVITY_TYPE}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="left">
                                {row.ACTIVITY_STRING}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                {moment(
                                  moment(new Date(row.TIMESTAMP)).utcOffset(
                                    this.props.fetchInitialData.credentials.user
                                      .timezone
                                  )._d
                                ).format('lll')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <div>
                        {this.state.content.activity.map((row, index) => {
                          return (
                            <Card
                              style={{ border: '0.5px solid #545454' }}
                              key={index}
                            >
                              <CardContent>
                                <div>
                                  <span style={{ fontSize: '1.2rem' }}>
                                    {row.ACTIVITY_TYPE}
                                  </span>
                                  <span
                                    style={{
                                      float: 'right',
                                      fontSize: '0.8rem',
                                    }}
                                  >
                                    {moment(
                                      moment(new Date(row.TIMESTAMP)).utcOffset(
                                        this.props.fetchInitialData.credentials
                                          .user.timezone
                                      )._d
                                    ).format('lll')}
                                  </span>
                                  <hr style={{ margin: '0.1rem' }} />
                                  <p style={{ margin: '0.8rem' }}>
                                    {row.ACTIVITY_STRING}
                                  </p>
                                  <div
                                    style={{
                                      marginTop: '0.8rem',
                                      fontSize: '0.8rem',
                                    }}
                                  >
                                    by: {row.name}
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          );
                        })}
                      </div>
                    )}
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

VendorFormDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorFormDetails);

const meta = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID (read only)',
    readOnly: true,
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  {
    fieldId: 'TAX_ID',
    fieldName: 'EIN / SSN (read only)',
    readOnly: true,
  },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
];

const addressInputs = [
  {
    fieldId: 'ADDR1',
    fieldName: 'Address 1',
  },
  {
    fieldId: 'ADDR2',
    fieldName: 'Address 2',
  },
  {
    fieldId: 'CITY_ADDR5',
    fieldName: 'City',
  },
  {
    fieldId: 'STATE_PROV',
    fieldName: 'State',
    half: true,
  },
  {
    fieldId: 'POSTAL_CODE',
    fieldName: 'Zip',
    half: true,
  },
];

const contactInputs = [
  {
    fieldId: 'NAME',
    fieldName: 'Name',
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Email',
  },
  {
    fieldId: 'PHONE_NMBR',
    fieldName: 'Phone',
  },
  {
    fieldId: 'MOBILE_NUM',
    fieldName: 'Mobile',
  },
  {
    fieldId: 'FAX_NMBR',
    fieldName: 'Fax',
  },
  {
    fieldId: 'JOB_TITLE',
    fieldName: 'Job Title',
  },
];

const bankingInputs = [
  {
    fieldId: 'BANK_ENTITY',
    fieldName: 'Routing Number (read only)',
    readOnly: true,
  },
  {
    fieldId: 'VBANK_ACCT_NO',
    fieldName: 'Bank Account Number (read only)',
    readOnly: true,
  },
  {
    fieldId: 'VBANK_ACCT_TP',
    fieldName: 'Account Type (read only)',
    readOnly: true,
  },
];

const preparerInfo = [
  {
    fieldId: 'PREPARER_NAME',
    fieldName: "Preparer's Name (read only)",
    readOnly: true,
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
    readOnly: true,
  },
];
const apcVenMast = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID',
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  {
    fieldId: 'TAX_ID',
    fieldName: 'EIN / SSN',
  },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
  {
    fieldId: 'BANK_ENTITY',
    fieldName: 'Routing Number',
  },
  {
    fieldId: 'VBANK_ACCT_NO',
    fieldName: 'Bank Account Number',
  },
  {
    fieldId: 'VBANK_ACCT_TP',
    fieldName: 'Account Type',
  },

  {
    fieldId: 'COMMENT',
    fieldName: 'Comment',
  },

  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
  },
];

const apcContact = [
  {
    fieldId: 'NAME',
    fieldName: 'Name',
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Email',
  },
  {
    fieldId: 'PHONE_NMBR',
    fieldName: 'Phone',
  },
  {
    fieldId: 'MOBILE_NUM',
    fieldName: 'Mobile',
  },
  {
    fieldId: 'FAX_NMBR',
    fieldName: 'Fax',
  },
  {
    fieldId: 'JOB_TITLE',
    fieldName: 'Job Title',
  },
];

const apcVenAddr = [
  {
    fieldId: 'ADDR1',
    fieldName: 'Address 1',
  },
  {
    fieldId: 'ADDR2',
    fieldName: 'Address 2',
  },
  {
    fieldId: 'CITY_ADDR5',
    fieldName: 'City',
  },
  {
    fieldId: 'STATE_PROV',
    fieldName: 'State',
  },
  {
    fieldId: 'POSTAL_CODE',
    fieldName: 'Zip',
  },
];
