import React from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Grid, withStyles, Button } from "@material-ui/core";
import moment from "moment-timezone";
import { vendorInvoices } from "../utils/constants/invoiceColumns";
import VendorStatus from "../functions/VendorStatus";
import { responsive } from "../utils/constants/muidatatablesConsts";
import { customTableSort, formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  invoicesContainer: {
    margin: "0 auto",
    maxWidth: 900,
    padding: "2rem",
    "@media (max-width: 700px)": {
      padding: "0.1rem",
    },
  },
});

class VendorInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: null,
      tableData: null,
    };
  }

  getInvoices() {
    fetch(`${process.env.REACT_APP_API}/vendor-landing-page`, {
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
      .then((resp) => resp.json())
      .then((info) => {
        const { data } = info;
        this.setState({
          tableData: this.makeTableData(data.content.invoices),
          invoices: data.content.invoices,
        });
      })
      .catch((error) => console.log(error));
  }

  makeTableData = (data) =>
    data.map((invoice) => [
      VendorStatus(invoice.INVOICE_STATUS),
      <div
        className="editLink"
        onClick={() =>
          this.props.history.push(`/invoice-details/${invoice.INVOICE_ID}`)
        }
      >
        {invoice.REFERENCE_NUM}
      </div>,
      new Date(invoice.INVOICE_DATE).getTime()
        ? moment(new Date(invoice.INVOICE_DATE)).format("MM/DD/YYYY")
        : "N/A",
      invoice.DUE_DATE
        ? moment(
            new Date(
              invoice.DUE_DATE.split("-")[1].concat(
                "/",
                invoice.DUE_DATE.split("-")[2],
                "/",
                invoice.DUE_DATE.split("-")[0]
              )
            )
          ).format("MM/DD/YYYY")
        : "N/A",
      formatter.format(invoice.TRAN_INV_AMOUNT),
    ]);

  componentDidMount() {
    this.getInvoices();
  }

  render() {
    const { classes } = this.props;
    return this.state.invoices ? (
      <Grid item xs={12} sm={12} md={12} className={classes.invoicesContainer}>
        <div
          style={{ position: "relative", height: "36px", marginBottom: "1rem" }}
        >
          <div style={{ position: "relative", float: "right", height: "30px" }}>
            <Button
              variant="contained"
              className="primaryVLButton"
              onClick={() => this.props.history.push("/vendorpaymentform")}
            >
              Create Invoice
            </Button>
          </div>
        </div>
        <MUIDataTable
          title={"Your Invoices"}
          data={this.state.tableData}
          columns={vendorInvoices}
          options={{
            selectableRows: false,
            filterType: "dropdown",
            responsive,
            print: false,
            customSort: (data, colIndex, order) =>
              customTableSort(data, colIndex, order, 4),
            customSearch: () => true,
            onSearchChange: (query) => {
              this.setState({
                tableData: this.makeTableData(
                  this.state.invoices.filter((invoice) => {
                    if (query) {
                      return (
                        VendorStatus(invoice.INVOICE_STATUS)
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        invoice.REFERENCE_NUM.toLowerCase().includes(
                          query.toLowerCase().trim()
                        ) ||
                        (new Date(invoice.INVOICE_DATE).getTime()
                          ? moment(new Date(invoice.INVOICE_DATE)).format(
                              "MM/DD/YYYY"
                            )
                          : "N/A"
                        )
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        (invoice.DUE_DATE
                          ? moment(
                              new Date(
                                invoice.DUE_DATE.split("-")[1].concat(
                                  "/",
                                  invoice.DUE_DATE.split("-")[2],
                                  "/",
                                  invoice.DUE_DATE.split("-")[0]
                                )
                              )
                            ).format("MM/DD/YYYY")
                          : "N/A"
                        )
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        formatter
                          .format(invoice.TRAN_INV_AMOUNT)
                          .toString()
                          .includes(query.toLowerCase().trim()) ||
                        invoice.TRAN_INV_AMOUNT.toString().includes(
                          query.toLowerCase().trim()
                        )
                      );
                    }
                    return invoice;
                  })
                ),
              });
            },
            onSearchClose: () => {
              this.setState({
                tableData: this.makeTableData(this.state.invoices),
              });
            },
          }}
        />
      </Grid>
    ) : (
      <LoadingCircle/>
    );
  }
}

export default withStyles(styles)(VendorInvoices);
