import React from 'react';
import {
  TextField,
  Grid,
  CircularProgress,
  InputAdornment,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ColorPicker from 'material-ui-color-picker'
import './Admin.css';
import DropDown from '../components/DropDown';
import LoadingButton from '../components/LoadingButton';
import LoadingCircle from "../components/common/LoadingCircle"

const styles = (theme) => ({
  colorSwitchBase: {
    color: 'rgb(232,102,19)',
    '&$colorChecked': {
      color: 'rgb(232,102,19)',
      '& + $colorBar': {
        backgroundColor: 'rgb(232,102,19)',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
});

class AdminGeneralSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      clientInfo: null,
      autoNumbering: false,
      vlGenerate: false,
      numSegments: 0,
      segments: [],
      linked_doc_behavior: null,
      erpSystem: 'Lawson',
      customFilterOpen: false,
      filterLevel: null,
      hasCustomAccountingFilter: false,
      currentColor: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this);
  }

  componentDidMount() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonClientInfo = JSON.stringify(form);

    fetch(`${process.env.REACT_APP_API}/get-or-update-client-info`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
      .then((resp) => resp.json())
      .then((info) => {

        
        let data = info.data.clientData;
        let bool = false;
        let vlGenBool = false;
        if (data[0].erp_vendor_auto_numbering !== 0) {
          bool = true;
        }
        if (data[0].vl_vendor_number_generation !== 0) {
          vlGenBool = true;
        }
        JSON.parse(data[0].segments);
        this.setState({
          currentColor: data[0].app_color,
          autoNumbering: bool,
          vlGenerate: vlGenBool,
          clientInfo: data[0],
          linked_doc_behavior: data[0].linked_document_behavior,
          numSegments: JSON.parse(data[0].segments).length,
          segments: JSON.parse(data[0].segments),
          filterLevel: data[0].filter_value_accounting_segment,
          hasCustomAccountingFilter:
            info.data.hasCustomAccountingLevels.count > 0,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  dropDownChange(name, value) {
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let segmentArray = [];
    for (let i = 0; i < this.state.numSegments; i++) {
      segmentArray.push(event.target[`segment${i + 1}`].value);
    }
    let copyOfClientInfo = Object.assign({}, this.state.clientInfo);
    copyOfClientInfo['update'] = true;
    copyOfClientInfo[
      'jsonWebTok'
    ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
    copyOfClientInfo.company_name = event.target.companyName.value;
    copyOfClientInfo.erp = this.state.erpSystem;
    copyOfClientInfo.erp_version = event.target.erpVersion.value;
    copyOfClientInfo.filter_value_accounting_segment = this.state.filterLevel;
    copyOfClientInfo.filter_value_label = event.target.filterValueLabel.value;
    copyOfClientInfo.linked_document_behavior = this.state.linked_doc_behavior;
    copyOfClientInfo.app_color = this.state.currentColor;
    copyOfClientInfo.segments = JSON.stringify(segmentArray);
    copyOfClientInfo['activity'] = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'Setting',
      ACTIVITY_TYPE: 'Update',
      ACTIVITY_STRING:
        this.props.fetchInitialData.credentials.user.name +
        ' edited General Settings',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    const copyOfClientInfoString = JSON.stringify(copyOfClientInfo);

    fetch(`${process.env.REACT_APP_API}/get-or-update-client-info`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: copyOfClientInfoString,
    })
      .then((resp) => resp.json())
      .then((info) => {
        const data = info.data.clientData;
        let bool = false;
        let vlGenBool = false;
        if (data[0].erp_vendor_auto_numbering !== 0) {
          bool = true;
        }
        if (data[0].vl_vendor_number_generation !== 0) {
          vlGenBool = true;
        }

        this.setState({
          autoNumbering: bool,
          vlGenerate: vlGenBool,
          clientInfo: data[0],
          currentColor: data[0].app_color,
          isLoading: false,
        });
        this.props.fetchInitialData.changeClientInfo(data[0]);
        this.props.fetchInitialData.createSnack(
          'Changes have been saved',
          'success',
          3000
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderAccountLevels() {
    if (this.state.numSegments < 1) {
      return null;
    } else {
      let accountFields = [];
      for (let i = 0; i < this.state.numSegments; i++) {
        accountFields.push(i);
      }
      return (
        <div>
          {accountFields.map((index) => {
            return (
              <Grid key={`segment${index + 1}`} item xs={12} sm={12} md={12}>
                <TextField
                  disabled={!this.props.access.super_admin}
                  id={`segment${index + 1}`}
                  label={`Account Level ${index + 1}`}
                  margin="dense"
                  defaultValue={this.state.segments[index]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>
            );
          })}
        </div>
      );
    }
  }

  render() {
    if (!this.state.clientInfo) {
      return (
        <LoadingCircle/>
      );
    }

    const {
      company_name,
      erp_version,
      account_status,
      filter_value_label,
    } = this.state.clientInfo;
    const status = account_status === 1 ? 'Active' : 'Inactive';
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="settingsContainer">
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="account_status"
                  label="Account Status"
                  defaultValue={status}
                  margin="dense"
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  autoComplete="off"
                />
<div style={{margin: '0.5rem 0'}}>
                <ColorPicker
                label="App Color"
                defaultValue="Click to change"
  name='color'
  value={this.state.currentColor}
  onChange={color => this.setState({currentColor: color})}
 
/>
</div>
                <TextField
                  id="companyName"
                  label="Company Name"
                  defaultValue={company_name}
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />

                <DropDown
                  underlined={true}
                  id="erpSystem"
                  options={[{ R_VALUE: 'Lawson' }]}
                  default={'Lawson'}
                  label="ERP System"
                  passUp={this.dropDownChange}
                />

                <TextField
                  id="erpVersion"
                  label="ERP Version"
                  defaultValue={erp_version}
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ marginTop: '8px' }}>
                  <InputLabel htmlFor="numSegments">
                    Linked Document Behavior
                  </InputLabel>
                  <Select
                    style={{ width: '350px' }}
                    value={this.state.linked_doc_behavior}
                    onChange={(event) =>
                      this.setState({ linked_doc_behavior: event.target.value })
                    }
                    inputProps={{
                      name: 'Linked Document Behavior',
                      id: 'linked_doc_behavior',
                    }}
                    autoComplete="off"
                  >
                    {linkedBehavior.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {!!this.props.access.super_admin && (
                  <FormControl style={{ marginTop: '8px' }}>
                    <InputLabel htmlFor="numSegments">
                      No. Account Levels
                    </InputLabel>
                    <Select
                      style={{ width: '200px' }}
                      value={this.state.numSegments}
                      onChange={(event) =>
                        this.setState({ numSegments: event.target.value })
                      }
                      inputProps={{
                        name: 'No. Account Levels',
                        id: 'numSegments',
                      }}
                      autoComplete="off"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => {
                        return (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                {this.renderAccountLevels()}
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  id="filterValueLabel"
                  defaultValue={filter_value_label}
                  label="Filter Value"
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={9}>
                <div
                  style={{
                    position: 'relative',
                    disply: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FormControl style={{ marginTop: '6px' }}>
                    <InputLabel htmlFor="filterLevel">Filter Level</InputLabel>
                    <Select
                      style={{ width: '200px' }}
                      value={this.state.filterLevel}
                      onChange={(event) =>
                        this.setState({ filterLevel: event.target.value })
                      }
                      inputProps={{
                        name: 'Filter Level',
                        id: 'filterLevel',
                      }}
                      autoComplete="off"
                    >
                      {this.state.segments.map((level, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {`${index + 1} - ${level}`}
                          </MenuItem>
                        );
                      })}
                      {!!this.state.hasCustomAccountingFilter && (
                        <MenuItem key={11} value={11}>
                          Custom
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {!!this.props.access.settings_general_edit && (
                  <div style={{ marginTop: '1rem' }}>
                    <LoadingButton
                      label="Save Changes"
                      isLoading={this.state.isLoading}
                      color="primaryVLButton"
                      buttonType="submit"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    );
  }
}

AdminGeneralSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminGeneralSettings);

const linkedBehavior = [
  {
    value: 'C',
    description: 'Client Sees the Option Only',
  },
  {
    value: 'D',
    description: 'Client Sees the Option Only but as default choice',
  },
  {
    value: 'B',
    description: 'Client and Vendor both see the Option (not as default)',
  }, 
  {
    value: 'E',
    description: 'Client and Vendor both see the Option as default choice',
  },
];
