import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField,
  InputAdornment, MenuItem, CardContent, Card, Checkbox, FormControlLabel
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import PrintIcon from '@material-ui/icons/Print';
import LoadingButton from '../components/LoadingButton';
import writeToActivity from '../functions/WriteToActivity';
import { locationColumns } from "../utils/constants/locationColumns";
import AddressForm from "./AddressForm";
import PdfTableDialog from "./pdf/PdfTableDialog";
import { responsive } from '../utils/constants/muidatatablesConsts';
import FieldChangeActivity from '../functions/FieldChangeActivity';
import { checkComplexEntry } from "../utils/tableCustoms";

const styles = {
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
  editLink: {
    color: 'blue',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
};

class ClientLocationMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addingNewLoc: false,
      isEditLoc: false,
      editLoc: null,
      locationType: '',
      isSaving: false,
      country: 'USA',
      state: '',
      zip: '',
      showInactive: false,
      openPrintDialog: false,
      locations: this.makeTableData(this.props.address, false),
      viewNum: this.props.address.length - this.makeTableData(this.props.address, false).length
    };
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  handleClose = () => {
    this.setState({ addingNewLoc: false, isEditLoc: false });
  };
  handleChange = (event) => {
    this.setState({ locationType: event.target.value });
  };
  handleNew = (event) => {
    event.preventDefault();
    if (
        this.props.address
            .map((a) => (a.LOCATION_CODE ? a.LOCATION_CODE.toUpperCase() : ''))
            .indexOf(event.target.locationCode.value.toUpperCase()) !== -1
    ) {
      this.createSnack(
          `Location ${event.target.locationCode.value} is already being used.`,
          'error',
          3500
      );
      return null;
    }
    this.setState({ isSaving: true });
    let newLocation = {
      locationName: event.target.locationName.value,
      locationCode: event.target.locationCode.value,
      locationType: this.state.locationType,
      addr1: event.target.addr1 ? event.target.addr1.value : '',
      addr2: event.target.addr2.value,
      city: event.target.city.value,
      country: event.target.country.value,
      state: event.target.state.value,
      zip: event.target.zip.value,
      primary_addr: event.target.isPrimaryLoc.checked ? 1 : 0,
    };
    let locName = event.target.locationName.value;

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'add',
      vendorId: this.props.vendorId,
      newLocation,
    };
    fetch(`${process.env.REACT_APP_API}/add-update-location`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `beaer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.createSnack(info.error, 'error', 3000);
            return null;
          } else if (info.success) {
            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
              OBJ_TYPE: 'VENDOR',
              OBJ_ID: this.props.vendorId,
              ACTIVITY_TYPE: 'CREATE',
              ACTIVITY_STRING: `Created location ${locName ? `: ${locName}` : ``}`,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            };
            writeToActivity({ activity }, () => {
              this.setState(
                  { isSaving: false, addingNewLoc: false, state: 'USA' },
                  () => {
                    this.props.getData();
                    this.createSnack(
                        'Successfully created new location',
                        'success',
                        3000
                    );
                  }
              );
            });
          } else {
            this.setState({ isSaving: false }, () => {
              this.createSnack(
                  'There was an error adding the location',
                  'error',
                  3000
              );
            });
          }
        });
  };
  handleEdit = (event, id) => {
    event.preventDefault();
    if (
        this.props.address
            .filter((a) => a.ADDR_ID !== id)
            .map((a) => (a.LOCATION_CODE ? a.LOCATION_CODE.toUpperCase() : ''))
            .indexOf(event.target.editLocationCode.value.toUpperCase()) !== -1
    ) {
      this.createSnack(
          `Location ${event.target.editLocationCode.value} is already being used.`,
          'error',
          3500
      );
      return null;
    }
    this.setState({ isSaving: true });

    let editLocation = {
      locationName: event.target.editLocationName.value,
      locationCode: event.target.editLocationCode.value,
      locationType: this.state.locationType,
      addr1: !event.target.editAddr1 ? '' : event.target.editAddr1.value,
      addr2: event.target.editAddr2.value,
      city: event.target.editCity.value,
      country: event.target.editCountry.value,
      state: event.target.editState.value,
      zip: event.target.editZip.value,
      primary_addr: event.target.isPrimaryLoc.checked ? 1 : 0,
      inactive_flag: (event.target.isPrimaryLoc.checked && this.state.editLoc.INACTIVE_FLAG === 1)
          ? 0 : this.state.editLoc.INACTIVE_FLAG,
    };

    let locName = this.state.editLoc.NAME;

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'edit',
      vendorId: this.props.vendorId,
      addrId: this.state.editLoc.ADDR_ID,
      editLocation,
    };
    fetch(`${process.env.REACT_APP_API}/add-update-location`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.createSnack(info.error, 'error', 3000);
            return null;
          } else if (info.success) {

            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
              OBJ_TYPE: 'VENDOR',
              OBJ_ID: this.props.vendorId,
              ACTIVITY_TYPE: 'UPDATE',
              ACTIVITY_STRING: `Updated location ${locName ? `: ${locName}` : ``}`,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
              field_changes: info.data.changes.field_changes,
              type: 'location'
            };

            FieldChangeActivity({ activity }, () => {
              this.setState(
                  {
                    isSaving: false,
                    isEditLoc: false,
                    state: 'USA',
                    editLoc: null,
                  },
                  () => {
                    this.props.getData();
                    this.createSnack(
                        'Successfully updated location',
                        'success',
                        3000
                    );
                  }
              );
            });
          } else {
            this.setState({ isSaving: false }, () => {
              this.createSnack(
                  'There was an error updating the location',
                  'error',
                  3000
              );
            });
          }
        });
  };
  handleActivate = () => {
    this.setState({ isActivating: true });
    if (
        this.state.editLoc.PRIMARY_ADDR === 1 &&
        this.state.editLoc.INACTIVE_FLAG === 0
    ) {
      this.createSnack(
          'You cannot inactivate a primary address',
          'warning',
          3000
      );
      this.setState({ isActivating: false });
      return null;
    }

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'activate',
      vendorId: this.props.vendorId,
      addrId: this.state.editLoc.ADDR_ID,
      inactiveFlag: this.state.editLoc.INACTIVE_FLAG === 0 ? 1 : 0,
    };

    let locName = this.state.editLoc.NAME;
    let locAction = this.state.editLoc.INACTIVE_FLAG === 0 ? 'Inactived' : 'Reactivated';

    fetch(`${process.env.REACT_APP_API}/add-update-location`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `beaer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.createSnack(info.error, 'error', 3000);
            return null;
          } else if (info.success) {
            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
              OBJ_TYPE: 'VENDOR',
              OBJ_ID: this.props.vendorId,
              ACTIVITY_TYPE: locAction.toUpperCase(),
              ACTIVITY_STRING: `${locAction} location ${locName ? `: ${locName}` : ``}`,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            };
            writeToActivity({ activity }, () => {
              this.setState(
                  {
                    isActivating: false,
                    isEditLoc: false,
                    state: 'USA',
                    editLoc: null,
                  },
                  () => {
                    this.props.getData();
                    this.createSnack(
                        'Successfully updated location',
                        'success',
                        3000
                    );
                  }
              );
            });
          } else {
            this.setState({ isActivating: false }, () => {
              this.createSnack(
                  'There was an error updating the location',
                  'error',
                  3000
              );
            });
          }
        });
  };

  makeTableData = (data, showInactive) => (
      data
          .filter((l) => l.INACTIVE_FLAG === (showInactive ? 1 : 0))
          .map((loc) => {
            return [
              <Fragment>
                <div>{loc.NAME}</div>
                {loc.PRIMARY_ADDR === 1 && (
                    <div
                        style={{
                          fontSize: '0.5rem',
                          color: 'grey',
                          position: 'absolute',
                        }}
                    >
                      Primary Address
                    </div>
                )}
              </Fragment>,
              loc.CITY_ADDR5,
              loc.STATE_PROV,
              loc.LOCATION_TYPE,
              <div
                  className={this.props.classes.editLink}
                  onClick={() =>
                      this.setState({
                        isEditLoc: true,
                        editLoc: loc,
                        country: loc.COUNTRY_CODE,
                        state: loc.STATE_PROV,
                        zip: loc.POSTAL_CODE,
                        locationType: loc.LOCATION_TYPE,
                      })
                  }
              >
                {' '}
                {!!this.props.access.vendor_location_edit ? 'edit' : 'view'}
              </div>,
            ];
          }))

  componentDidMount() {
    this.setState({
      locations: this.makeTableData(this.props.address, this.state.showInactive),
      viewNum: this.props.address.length - this.makeTableData(this.props.address, this.state.showInactive).length
    })
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.address !== prevProps.address)) {
      this.setState({
        locations: this.makeTableData(this.props.address, this.state.showInactive),
        viewNum: this.props.address.length - this.makeTableData(this.props.address, this.state.showInactive).length
      })
    }
  }


  render() {
    if (!this.state.locations) return null;
    const { classes } = this.props;
    return (
        <div>
          {this.state.addingNewLoc && (
              <Dialog
                  open={this.state.addingNewLoc}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.handleNew}>
                  <DialogTitle id="alert-dialog-title">Add Location</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            value="primaryAddress"
                            control={
                              <Checkbox
                                  id="isPrimaryLoc"
                                  style={{ color: this.props.fetchInitialData.credentials.primaryAppColor}}
                              />
                            }
                            label="Primary Address"
                            labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <TextField
                            id="locationName"
                            fullWidth
                            required
                            label="Location Name"
                            variant="filled"
                            margin="dense"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id="locationCode"
                            fullWidth
                            required
                            label="Location Code"
                            variant="filled"
                            margin="dense"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            select
                            id="locationType"
                            label="Location Type"
                            value={this.state.locationType}
                            onChange={this.handleChange}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.root,
                              },
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <span/>
                                  </InputAdornment>
                              ),
                            }}
                        >
                          {['Purchase', 'Remit', 'Both'].map((option, index) => (
                              <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <AddressForm isEdit={false}/>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <LoadingButton
                        label="Add"
                        isLoading={this.state.isSaving}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Dialog>
          )}
          {this.state.isEditLoc && (
              <Dialog
                  open={this.state.isEditLoc}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={(e) => this.handleEdit(e, this.state.editLoc.ADDR_ID)}>
                  <DialogTitle id="alert-dialog-title">Edit Location</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            value="primaryAddress"
                            control={
                              <Checkbox
                                  id="isPrimaryLoc"
                                  defaultChecked={this.state.editLoc.PRIMARY_ADDR !== 0}
                                  disabled={this.state.editLoc.PRIMARY_ADDR !== 0}
                                  style={{ color: this.state.editLoc.PRIMARY_ADDR === 0 ? this.props.fetchInitialData.credentials.primaryAppColor : 'grey', }}
                              />
                            }
                            label="Primary Address"
                            labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <TextField
                            id="editLocationName"
                            fullWidth
                            required
                            label="Location Name"
                            variant="filled"
                            defaultValue={this.state.editLoc.NAME}
                            margin="dense"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id="editLocationCode"
                            fullWidth
                            required
                            label="Location Code"
                            variant="filled"
                            defaultValue={this.state.editLoc.LOCATION_CODE}
                            margin="dense"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            select
                            id="editLocationType"
                            label="Location Type"
                            value={this.state.locationType}
                            onChange={this.handleChange}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.root,
                              },
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <span/>
                                  </InputAdornment>
                              ),
                            }}
                        >
                          {['Purchase', 'Remit', 'Both'].map((option, index) => (
                              <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <AddressForm isEdit editValues={this.state.editLoc}/>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    {!!this.props.access.vendor_location_delete && (
                        <LoadingButton
                            label={this.state.editLoc.INACTIVE_FLAG === 0 ? 'Inactivate' : 'Reactivate'}
                            isLoading={this.state.isActivating}
                            fontColor={this.state.editLoc.INACTIVE_FLAG === 0 ? 'red' : 'green'}
                            variant="text"
                            fn={this.handleActivate}
                        />
                    )}
                    {!!this.props.access.vendor_location_edit && (
                        <LoadingButton
                            label="Update"
                            isLoading={this.state.isSaving}
                            color="primaryVLButton"
                            buttonType="submit"
                        />
                    )}
                  </DialogActions>
                </form>
              </Dialog>
          )}
          <Card className={classes.root}>
            <PdfTableDialog
                isLarge={false}
                open={this.state.openPrintDialog}
                fileName={'locationMaintenance'}
                columns={locationColumns.slice(0, -1)}
                data={this.makeTableData(this.props.address, this.state.showInactive).map((row) => row.slice(0, -1))}
                onClose={() => this.setState({ openPrintDialog: false })}
                title={'Location Maintenance'}
            />
            <CardContent>
              <MUIDataTable
                  title={'Location Maintenance'}
                  data={this.state.locations}
                  columns={locationColumns}
                  options={{
                    customSort: (data, colIndex, order) =>
                        data.sort((a, b) => {
                          const firstData = checkComplexEntry(a.data[colIndex]);
                          const secondData = checkComplexEntry(b.data[colIndex]);
                          return (firstData === secondData ? 0 : ((firstData < secondData) ? -1 : 1)) * (order === 'asc' ? 1 : -1)
                        }),
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        locations: this.makeTableData(this.props.address.filter((location) => {
                          if (query) {
                            return (
                                location.NAME
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                location.CITY_ADDR5
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                location.STATE_PROV
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                location.LOCATION_TYPE
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim())
                            );
                          }
                          return location;
                        }), this.state.showInactive)
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        locations: this.makeTableData(this.props.address, this.state.showInactive)
                      });
                    },
                    search: true,
                    print: false,
                    filter: true,
                    viewColumns: true,
                    download: false,
                    displaySelectToolbar: false,
                    elevation: 0,
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                  }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                {!!this.props.access.vendor_location_add && (
                    <Button
                        className="primaryVLButton"
                        onClick={() =>
                            this.setState({
                              addingNewLoc: true,
                              state: '',
                              zip: '',
                              country: 'USA',
                              locationType: '',
                            })
                        }
                    >
                      Add New Location
                    </Button>
                )}
                <Button
                    className="primaryVLButton"
                    onClick={() => this.setState({ openPrintDialog: true })}>
                  PDF
                  <PrintIcon style={{ marginLeft: 10 }}/>
                </Button>
                <div style={{ float: 'right' }}>
                  <Button
                      onClick={() => this.setState({
                        showInactive: !this.state.showInactive,
                        locations: this.makeTableData(this.props.address, !this.state.showInactive),
                        viewNum: this.props.address.length - this.makeTableData(this.props.address, !this.state.showInactive).length
                      })}
                      variant="outlined"
                      className="primaryTextVLButton"
                  >
                    {this.state.showInactive
                        ? `View Active Locations (${this.state.viewNum})`
                        : `View Inactive Locations (${this.state.viewNum})`}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
    );
  }
}

ClientLocationMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(ClientLocationMaintenance));
