import React from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, withStyles } from "@material-ui/core";

const styles = () => ({
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 20,
    marginTop: -12,
    marginLeft: -12,
  },
});

class ConfirmationDialog extends React.Component {
  render() {
    const { classes, handleClose, handleOpen, dialogTitle, handleDelete, isDeleting } = this.props;
    return (
        <Dialog
            onClose={handleClose}
            open={handleOpen}
            fullWidth>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
                style={{ marginRight: "0.5rem", color: isDeleting ? "" : "red" }}
                disabled={isDeleting}
                onClick={handleDelete}
            >
              {isDeleting ? (
                  <Box>
                    <CircularProgress
                        style={{ color: "rgb(232,102,19)" }}
                        size={24}
                        className={classes.buttonProgress}
                    />
                    Delete
                  </Box>
              ) : (
                  "Delete"
              )}
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmationDialog);
