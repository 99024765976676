import {tableCellOverflow, tableCellNoWrap} from "../tableCellOverflow";

export const contractsColumns = [
	{
		name: 'vendor',
		label: 'Vendor',
		options: {
			filter: true,
			sort: true,
      customBodyRender: tableCellOverflow
		},
		wordBreak: 'normal',
	},
	{
		name: 'contractNumber',
		label: 'Contract Number',
		options: {
			filter: false,
			sort: true,
            customBodyRender: tableCellNoWrap
		},
		wordBreak: 'keep-all',
	},
	{
		name: 'title',
		label: 'Title',
		options: {
			filter: false,
			sort: true,
			customBodyRender: tableCellOverflow
		},
		wordBreak: 'normal',
	},
	{
		name: 'classificationValue',
		label: 'Classification Value',
		options: {
			filter: false,
			sort: true,
		},
		wordBreak: 'normal',
	},
	{
		name: 'startDate',
		label: 'Start Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'endDate',
		label: 'End Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'daysLeft',
		label: 'Days Left',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'contractAmount',
		label: 'Contract Amount',
		options: {
			filter: false,
			sort: true,
		},
		wordBreak: 'break-all',
	},
];

export const attributeColumns = [
	{
		name: 'vendor',
		label: 'Vendor',
		options: {
			filter: true,
			sort: true,
      customBodyRender: tableCellOverflow
		},
	},
	{
		name: 'title',
		label: 'Contract / Document Title',
		options: {
			filter: true,
			sort: true,
      customBodyRender: tableCellOverflow
		},
	},
	{
		name: 'type',
		label: 'Type',
		options: {
			filter: true,
			sort: true,
		},
		width: 75,
	},
	{
		name: 'attribute',
		label: 'Attribute',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'Value',
		label: 'Value',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'status',
		label: 'Status',
		options: {
			filter: true,
			sort: true,
			filterList: ['Active'],
		},
		width: 40,
	},
];

export const notificationColumns = [
	{
		name: 'vendor',
		label: 'Vendor',
		options: {
			filter: true,
			sort: true,
      customBodyRender: tableCellOverflow
		},
	},
	{
		name: 'title',
		label: 'Contract / Document Title',
		options: {
			filter: true,
			sort: true,
      customBodyRender: tableCellOverflow
		},
	},
	{
		name: 'type',
		label: 'Type',
		options: {
			filter: true,
			sort: true,
		},
		width: 75,
	},
	{
		name: 'date',
		label: 'Date',
		options: {
			filter: false,
			sort: true,
		},
		width: 70,
	},
	{
		name: 'taskTitle',
		label: 'Task Title',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'status',
		label: 'Status',
		options: {
			filter: true,
			sort: true,
			filterList: ['Active'],
		},
		width: 40,
	},
];
