export const taskColumns = [
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'UsersToNotify',
    label: '# Users to Notify',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'action',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
    },
  },
];
