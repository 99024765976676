import React from "react";
import {
  withStyles,
  CircularProgress,
  Tabs,
  Tab,
  Button,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import AllContracts from "../components/AllContracts";
import ContractSearch from "../components/ContractSearch";
import CreateContract from "../components/CreateContract";
import VerifyAccess from "../functions/VerifyAccess";
import { AntTabs, AntTab } from "../components/common/Tabs";
import LoadingCircle from "../components/common/LoadingCircle";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paymentForm: {
    margin: "0 auto",
    padding: "2rem",
    maxWidth: "1300px",
    "@media (max-width: 700px)": {
      padding: "0.5rem",
    },
  },
});

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      createNewContract: false,
      isLoading: false,
      contractTypes: null,
      allVendors: null,
      classification1Header: null,
      classification1Values: null,
      access: null,
    };
  }

  viewHash = () => {
    const hash = window.location.hash;
    if (hash === "#all") {
      this.setState({ tabValue: 0 });
    } else if (hash === "#documents") {
      this.setState({ tabValue: 1 });
    } else if (hash === "#search") {
      this.setState({ tabValue: 2 });
    }
  };

  componentDidMount() {
    VerifyAccess(
      ({ access }) => this.setState({ access }),
      ["contract_add"],
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    window.addEventListener("hashchange", this.viewHash);
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_API}/get-contracts`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      }),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.success) {
          const {
            contractTypes,
            vendors,
            classification1Header,
            classification1Values,
          } = info.data;
          this.setState({
            isLoading: false,
            contractTypes,
            allVendors: vendors,
            classification1Header,
            classification1Values,
          });
        } else {
          this.setState({ isLoading: false }, () =>
            window.alert("There was an error loading your contracts")
          );
        }
      });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      switch (newValue) {
        case 1:
          window.location.hash = "documents";
          break;
        case 2:
          window.location.hash = "search";
          break;
        case 0:
        default:
          window.location.hash = "all";
          break;
      }
    });
  };
  closeCreate = () => {
    this.setState({ createNewContract: false }, () => {
      this.getData();
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingCircle />;
    } else {
      const { access } = this.state;
      const { classes } = this.props;
      return (
        <div>
          {this.state.createNewContract && (
            <Dialog
              fullScreen
              open={this.state.createNewContract}
              onClose={() => this.setState({ createNewContract: false })}
              TransitionComponent={Transition}
            >
              <AppBar
                style={{ backgroundColor: "#545454", position: "relative" }}
              >
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ createNewContract: false })}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6">Create New Contract</Typography>
                </Toolbar>
              </AppBar>
              <CreateContract
                props={this.props}
                history={this.props.history}
                closeCreate={this.closeCreate}
                allVendors={this.state.allVendors}
                contractTypes={this.state.contractTypes}
                classification1Header={this.state.classification1Header}
                classification1Values={this.state.classification1Values}
              />
            </Dialog>
          )}
          <div style={{ backgroundColor: "white", width: "100%", zIndex: 200 }}>
            <AntTabs
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="ant example"
            >
              {[
                { label: "All Contracts", value: 0 },
                { label: "Attribute Search", value: 1 },
                { label: "Task Search", value: 2 },
              ].map((el) => (
                <AntTab
                  key={el.value}
                  color={
                    this.props.fetchInitialData.credentials.primaryAppColor
                  }
                  label={el.label}
                  value={el.value}
                />
              ))}
            </AntTabs>
          </div>
          {access && !!access.contract_add && (
            <div
              style={{
                position: "relative",
                height: "36px",
                marginTop: "1rem",
              }}
            >
              <div
                style={{ position: "relative", float: "right", height: "30px" }}
              >
                <Button
                  className="primaryVLButton"
                  onClick={() => this.setState({ createNewContract: true })}
                >
                  Create New Contract
                </Button>
              </div>
            </div>
          )}
          <div className={classes.paymentForm}>
            {this.state.tabValue === 0 && <AllContracts props={this.props} />}
            {this.state.tabValue === 1 && (
              <ContractSearch props={this.props} show="attributes" />
            )}
            {this.state.tabValue === 2 && (
              <ContractSearch props={this.props} show="notifications" />
            )}
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(Contracts);
