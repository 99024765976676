import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import {responsive} from '../utils/constants/muidatatablesConsts';
import { formatter } from "../utils/tableCustoms";
import VendorStatus from '../functions/VendorStatus'

class PurchaseRequestVendorHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null
    };
  }
  componentDidMount() {
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id
    });
    fetch(
      `${process.env.REACT_APP_API}/vendor-landing-page`,
      {
        method: "post",
        headers: { "content-type": "application/json",
        'Authorization': this.props.fetchInitialData.credentials.user.jsonWebTok },
        body: jsonVendor
      }
    )
      .then(resp => resp.json())
      .then(data => {
        let purchaseRequests = [];
        if (data.content.purchasing.length > 0) {
          purchaseRequests = data.content.purchasing.map((po, index) => {
            let status;
            let prArray = [];
            switch (po.PO_STATUS) {
              case 2:
                status = "Ready for process";
                break;
              case 7:
                status = "Viewed";
                break;
              case 8:
                status = "Fulfilled";
                break;
              default:
                status = "Rejected";
            }
            let deliveryDate, creationDate = '';
            if (po.DELIVERY_DATE) {
              let date = po.DELIVERY_DATE.split("T00")[0]
              deliveryDate = date.split('-')[1].concat('/',date.split('-')[2],'/',date.split('-')[0])
            }
            if (po.CREATE_DATE) {
              let date = po.CREATE_DATE.split("T00")[0]
              creationDate = date.split('-')[1].concat('/',date.split('-')[2],'/',date.split('-')[0])
            }

            prArray.push(VendorStatus(status));
            prArray.push(po.PO_NUMBER);
            prArray.push(deliveryDate);
            prArray.push(creationDate);
            prArray.push(po.LOCATION_ID);
            prArray.push(formatter.format(po.EXT_AMOUNT));

            return prArray;
          });
        }
        this.setState({
          purchaseRows: purchaseRequests,
          content: data.content
        });
      })
      .catch(error => console.log(error));
  }
  render() {
    if (!this.state.content) {
      return (
        <div style={{ height: "100vh" }}>
          <div style={{ position: "relative", left: "50%", top: "40%" }}>
            <CircularProgress
              style={{ color: "rgb(232,102,19)" }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    return (
      <div className="vendorLandingContainer">
        {this.state.purchaseRows.length === 0 && (
          <div style={{ margin: "3rem auto" }}>
            <h4 style={{ textAlign: "center" }}>
              There are no purchase requests
            </h4>
          </div>
        )}
        <Grid container justify="flex-start" spacing={24}>
          {this.state.purchaseRows.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <MUIDataTable
                title={"Your Purchase Requests"}
                data={this.state.purchaseRows}
                columns={[
                  {
                    name: "status",
                    label: "Status",
                    options: {
                      filter: true,
                      sort: true
                    }
                  },
                  {
                    name: "prNumber",
                    label: "PR Number",
                    options: {
                      filter: true,
                      sort: true
                    }
                  },
                  {
                    name: "deliveryDate",
                    label: "Delivery Date",
                    options: {
                      filter: false,
                      sort: true
                    }
                  },
                  {
                    name: "createDate",
                    label: "Create Date",
                    options: {
                      filter: false,
                      sort: true
                    }
                  },
                  {
                    name: "location",
                    label: "Location",
                    options: {
                      filter: true,
                      sort: true
                    }
                  },
                  {
                    name: "extAmount",
                    label: "Ext Amount",
                    options: {
                      filter: false,
                      sort: true
                    }
                  }
                ]}
                options={{
                  selectableRows: false,
                  filterType: "dropdown",
                  responsive,
                  print: false,
                  onRowClick: (rowData, rowState) => {
                    this.props.history.push(
                      `/po-vendor-details/${
                        this.state.content.purchasing[rowState.rowIndex].PO_ID
                      }`
                    );
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default PurchaseRequestVendorHome;
