import React, { useState } from 'react';
import {
  Grid, TableHead, TableCell, TableBody, Table, TableRow, Button, TextField, Fab, DialogActions,
  DialogContent, DialogTitle, Dialog, Paper, InputAdornment, Container,
} from '@material-ui/core';
import SuggestionSql from './SuggestionSql';
import LoadingButton from './LoadingButton';

import uuidv1 from 'uuid/v1';
import uuid from 'uuid';
import moment from 'moment';

import { formatter } from "../utils/tableCustoms";
import AddLineDialog from "./clientCreateInvoice/AddLineDialog";
import { Select } from "./common/Select";
import EditLineDialog from "./clientCreateInvoice/EditLineDialog";

const inputProps = {
  startAdornment: (
      <InputAdornment position="start">
        <div/>
      </InputAdornment>
  ),
};

function ClientCreateInvoice(props) {
  const [state, setState] = useState({
    vendorId: '',
    vendorVname: '',
    poNum: '',
    contractNum: '',
    currency: '',
    uom: '',
    poList: [],
    contractList: [],
    currencyList: [],
    uomList: null,
  });
  const [isAddingLine, setAddingLine] = useState(false);
  const [newLine, setNewLine] = useState({
    LINE_UNIT_COST: '',
    LINE_QTY: '',
  });
  const [rows, setRows] = useState([]);
  const [isEditOpen, setEditOpen] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [invoiceData, setInvoiceData] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const handleVendorSelect = (id, name) => {
    fetch(`${process.env.REACT_APP_API}/get-vendor-forms`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        vendorId: id,
        userId: props.fetchInitialData.credentials.user.uuid,
        isVendor: true,
        clientId: props.fetchInitialData.credentials.clientInfo.client_id,
      }),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            const lists = data.data;
            setState({
              ...state,
              vendorId: id,
              vendorVname: name,
              poList: lists.invoicePoList,
              contractList: lists.invoiceContractList,
              currencyList: lists.invoiceCurrencyCodeList,
              uomList: lists.invoiceUomList,
            });
          }
        });
  };

  const updateAddLine = (id, val) => {
    setNewLine({ ...newLine, [id]: val });
  };


  const addToInvoice = (event) => {
    setLoading(true);
    event.preventDefault();
    const newRow = {
      LINE_NUM: rows.length,
      LINE_DESC: event.target.description.value,
      LINE_UOM: state.uom,
      LINE_UNIT_COST: newLine.LINE_UNIT_COST,
      LINE_QTY: newLine.LINE_QTY,
      LINE_TRAN_AMOUNT: newLine.LINE_UNIT_COST * newLine.LINE_QTY,
    };
    let updated = rows;
    updated.push(newRow);

    setRows(updated);
    setAddingLine(false);
    setState({ ...state, uom: '' });
    setLoading(false);
    setNewLine({ LINE_UNIT_COST: '', LINE_QTY: '' });
  };

  const editInvoiceRow = (event) => {
    setLoading(true);
    event.preventDefault();
    let copyOfEditRow = editRow;
    copyOfEditRow['LINE_DESC'] = event.target.description.value;
    copyOfEditRow.LINE_UOM = state.uom;
    copyOfEditRow.LINE_TRAN_AMOUNT = editRow.LINE_UNIT_COST * editRow.LINE_QTY;

    const updatedRows = rows.map((row) => {
      if (row.LINE_NUM === copyOfEditRow.LINE_NUM) return copyOfEditRow;
      else return row;
    });
    setRows(updatedRows);
    setState({ ...state, uom: '' });
    setEditOpen(false);
    setEditRow({});
    setLoading(false);
  };
  const handleDeleteRow = () => {
    const updatedRows = rows.filter((row) => row.LINE_NUM !== editRow.LINE_NUM);
    setRows(updatedRows);
    setState({ ...state, uom: '' });
    setEditOpen(false);
    setEditRow({});
    setLoading(false);
  };

  const inputFileChange = (e) => {
    e.preventDefault();

    if (e.target.files[0] === undefined) {
      setFiles({
        uploadFiles: [],
      });
    } else {
      setFiles([]);
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
            reader = new FileReader();
        reader.onload = function (r) {
          const updatedUpload = files.map((file) => Object.assign({}, file));
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size,
          });
          setFiles(updatedUpload);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (rows.length < 1) {
      props.fetchInitialData.createSnack(
          'Must have at least 1 line',
          'warning',
          3500
      );
      return null;
    }

    if (props.checkDuplicateIds(props.fetchInitialData.credentials.clientInfo.client_id, state.vendorId, event.target.ref.value)) {
      props.fetchInitialData.createSnack(
          `Invoice ${event.target.ref.value} already exists`,
          'warning',
          3500
      );
      return null;
    }

    const copyOfRows = rows.map((row) => {
      return Object.assign({}, row, { UUID: uuid() });
    });
    const invoiceId = uuid();
    const fetchData = {
      url: `https://${props.fetchInitialData.credentials.clientInfo.subdomain}.${process.env.REACT_APP_SITE_URL}/invoice-details/${invoiceId}`,
      activity: {
        CLIENT_ID: props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'INVOICE',
        OBJ_ID: invoiceId,
        ACTIVITY_TYPE: 'CREATED',
        ACTIVITY_STRING: 'Invoice created',
        USER_ID: props.fetchInitialData.credentials.user.uuid,
      },
      apcinvoice: {
        INVOICE_ID: invoiceId,
        CLIENT_ID: props.fetchInitialData.credentials.clientInfo.client_id,
        VENDOR_ID: state.vendorId,
        REFERENCE_NUM: event.target.ref.value,
        CURRENCY: state.currency,
        INVOICE_DATE: moment().format(),
        PO_NUMBER: state.poNum,
        CONTRACT_NUM: state.contractNum,
        DESCRIPTION: event.target.description.value,
        TRAN_INV_AMOUNT: rows.reduce((tot, cur) => (tot += cur.LINE_TRAN_AMOUNT), 0),
        DUE_DATE: event.target.dueDate.value,
        INVOICE_STATUS: 1,
        APPROVAL_STATUS_LEVEL: 1,
        USER_ID: props.fetchInitialData.credentials.user.uuid,
        CODING_COMPLETE: 0,
      },
      apcinvdtl: copyOfRows,
    };

    setInvoiceData(fetchData);
    setConfirm(true);
  };
  const submitInvoice = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(invoiceData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            let invoiceId = data.data.invoice_id;
            for (let i = 0; i < files.length; i++) {
              uploadFile(invoiceId, files[i]);
            }
            props.fetchInitialData.createSnack(
                'Successfully created Invoice',
                'success',
                3500
            );
            props.closeDialog();
          } else {
            props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
          }
        });
    setLoading(false);
  };
  const uploadFile = (invoiceId, fileData) => {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      window.alert(fileData.name + ' is too big to upload');
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: 'INVOICE',
        obj_id: invoiceId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: props.fetchInitialData.credentials.user.uuid,
        activity: {
          CLIENT_ID: props.fetchInitialData.credentials.clientInfo.client_id,
          OBJ_TYPE: 'INVOICE',
          OBJ_ID: invoiceId,
          ACTIVITY_TYPE: 'UPLOAD',
          ACTIVITY_STRING:
              props.fetchInitialData.credentials.user.name +
              ' uploaded an attachment to invoice',
          USER_ID: props.fetchInitialData.credentials.user.uuid,
        },
      };
      const stringData = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: stringData,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.errorMessage) {
              window.alert(data.errorMessage);
            }
            return data;
          })
          .catch((err) => console.log(err));
    }
  };
  return (
      <div
          style={{
            padding: '2rem',
            margin: '0 auto',
          }}
      >
        {confirm && (
            <Dialog
                open={confirm}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm Invoice
              </DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={0}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div>
                      <span>Invoice Date: </span>
                      <span>
                    {moment(invoiceData.apcinvoice.INVOICE_DATE).format('l')}
                  </span>
                    </div>
                    <div>
                      <span>Payable To: </span>
                      <span>{state.vendorVname}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <span>PO Number: </span>
                      <span>{invoiceData.apcinvoice.PO_NUMBER}</span>
                    </div>
                    <div>
                      <span>Contract: </span>
                      <span>{invoiceData.apcinvoice.CONTRACT_NUM}</span>
                    </div>
                    <div>
                      <span>Description: </span>
                      <span>{invoiceData.apcinvoice.DESCRIPTION}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <span>Vendor Invoice Number: </span>
                      <span>{invoiceData.apcinvoice.REFERENCE_NUM}</span>
                    </div>
                    <div>
                      <span>Invoice Currency: </span>
                      <span>{invoiceData.apcinvoice.CURRENCY}</span>
                    </div>
                    <div>
                      <span>Due Date: </span>
                      <span>
                    {moment(invoiceData.apcinvoice.DUE_DATE).format('l')}
                  </span>
                    </div>
                  </Grid>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Line</TableCell>
                      <TableCell align="center">Notes</TableCell>
                      <TableCell align="center">Unit</TableCell>
                      <TableCell align="center">Rate</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.apcinvdtl.map((row, index) => (
                        <TableRow key={index + 1}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.LINE_DESC}</TableCell>
                          <TableCell align="center">{row.LINE_UOM}</TableCell>
                          <TableCell align="center">
                            {formatter.format(row.LINE_UNIT_COST)}
                          </TableCell>
                          <TableCell align="center">{row.LINE_QTY}</TableCell>
                          <TableCell align="center">
                            {formatter.format(row.LINE_TRAN_AMOUNT)}
                          </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}/>
                      <TableCell align="center" colSpan={1}>
                        <strong>Subtotal:</strong>
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        <strong>
                          {formatter.format(
                              invoiceData.apcinvdtl.reduce(
                                  (tot, cur) => (tot += cur.LINE_TRAN_AMOUNT),
                                  0
                              )
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={() => setConfirm(false)}
                    style={{
                      backgroundColor: '#545454',
                      color: 'white',
                    }}
                    variant="contained"
                >
                  Cancel
                </Button>

                <LoadingButton
                    label="Submit"
                    isLoading={isLoading}
                    color="primaryVLButton"
                    variant="contained"
                    fn={() => submitInvoice()}
                />

                {/* <LoadingButton
              label="Submit"
              isLoading={false}
              color="primaryVLButton"
              buttonType="submit"
            /> */}
              </DialogActions>
            </Dialog>
        )}

        <AddLineDialog
            open={isAddingLine}
            onClose={() => setAddingLine(false)}
            onSubmit={(event) => addToInvoice(event)}
            addToInvoice={addToInvoice} setAddingLine={setAddingLine}
            isLoading={isLoading} uom={state.uom} uomList={state.uomList}
            updateAddLine={updateAddLine}
            newLine={newLine} setNewLine={setNewLine} vendorId={state.vendorId} state={state} setState={setState}
        />
        <EditLineDialog
            editRow={editRow}
            onClose={() => {
              setEditOpen(false);
              setEditRow({});
            }}
            setEditRow={setEditRow}
            isLoading={isLoading} uom={state.uom} uomList={state.uomList}
            handleDeleteRow={handleDeleteRow}
            open={isEditOpen} editInvoiceRow={editInvoiceRow}
            inputProps={inputProps}
            vendorId={state.vendorId}
        />
        <Container>
          <form onSubmit={handleSubmit}>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <SuggestionSql
                    id="vendor"
                    jsonWebTok={props.fetchInitialData.credentials.user.jsonWebTok}
                    handleChange={(id, newVendorName, isSelected, name) => {
                      if (isSelected) {
                        handleVendorSelect(id, name);
                      } else {
                        setState({ ...state, vendorId: null });
                      }
                    }}
                    placeholder=""
                    label="Vendor"
                    required
                    clientId={props.fetchInitialData.credentials.user.clientId}
                />

                {Select('poNum', 'PO Number', state.poNum, state.poList, state.vendorId, state, setState)}
                {Select(
                    'contractNum',
                    'Contract',
                    state.contractNum,
                    state.contractList,
                    state.vendorId, state, setState
                )}

                <TextField
                    id="ref"
                    style={{ width: '25ch', marginTop: '0.5rem' }}
                    label="Vendor Invoice Number"
                    required
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <span/>
                          </InputAdornment>
                      ),
                    }}
                />

                <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    style={{ marginTop: '0.5rem' }}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <span/>
                          </InputAdornment>
                      ),
                    }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                {Select(
                    'currency',
                    'Invoice Currency',
                    state.currency,
                    state.currencyList,
                    state.vendorId, state, setState
                )}

                <TextField
                    id="dueDate"
                    style={{ width: '25ch', marginTop: '0.5rem' }}
                    label="Due Date"
                    fullWidth
                    type="date"
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <span/>
                          </InputAdornment>
                      ),
                    }}
                />
              </Grid>

              <Grid item xl={12} sm={12} md={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Line</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Unit</TableCell>
                        <TableCell align="center">Rate</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Total</TableCell>
                        {rows.length > 0 && (
                            <TableCell align="center">Edit</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell style={styles.tc} align="center">
                              {index + 1}
                            </TableCell>
                            <TableCell style={styles.tc} align="center">
                              {row.LINE_DESC}
                            </TableCell>
                            <TableCell style={styles.tc} align="center">
                              {row.LINE_UOM}
                            </TableCell>
                            <TableCell style={styles.tc} align="center">
                              {formatter.format(row.LINE_UNIT_COST)}
                            </TableCell>
                            <TableCell style={styles.tc} align="center">
                              {row.LINE_QTY}
                            </TableCell>
                            <TableCell style={styles.tc} align="center">
                              {formatter.format(row.LINE_TRAN_AMOUNT)}
                            </TableCell>
                            <TableCell
                                onClick={() => {
                                  setEditRow(row);
                                  setEditOpen(true);
                                  setState({ ...state, uom: row.LINE_UOM });
                                }}
                                align="center"
                                style={styles.tc}
                                className="editLink"
                            >
                              edit
                            </TableCell>
                          </TableRow>
                      ))}

                      <TableRow>
                        <TableCell style={styles.tc} align="center" colSpan={1}>
                          <Fab
                              onClick={() => setAddingLine(true)}
                              style={{
                                color: 'white',
                                backgroundColor: '#545454',
                              }}
                              size="small"
                              aria-label="Add"
                          >
                            +
                          </Fab>
                        </TableCell>
                        <TableCell style={styles.tc} colSpan={3}/>
                        <TableCell style={styles.tc} align="center" colSpan={1}>
                          <strong>Subtotal:</strong>
                        </TableCell>
                        <TableCell style={styles.tc} align="center" colSpan={1}>
                          {rows.length > 0 && (
                              <strong>
                                {formatter.format(
                                    rows.reduce((tot, cur) => (tot += cur.LINE_TRAN_AMOUNT), 0)
                                )}
                              </strong>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <input
                    style={{ margin: '1rem 0' }}
                    type="file"
                    multiple="multiple"
                    onChange={inputFileChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <LoadingButton
                    label="Submit"
                    isLoading={false}
                    color="primaryVLButton"
                    buttonType="submit"
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
  );
}

const styles = {
  tc: {
    padding: '10px 20px',
  },
};

export default ClientCreateInvoice;
