import React, { Fragment } from "react";
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  ListItem,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Fade,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import moment from "moment-timezone";
import Comments from "../components/Comments";
import Activity from "../components/Activity";
import LoadingButton from "../components/LoadingButton";
import writeToActivity from "../functions/WriteToActivity";
import ConfirmDialog from "../components/ConfirmDialog";
import AttachAttribTask from "../components/AttachAttribTask";
import VerifyAccess from "../functions/VerifyAccess";
import LambdaFetch from "../functions/FetchFromLambda";
import FieldChangeActivity from "../functions/FieldChangeActivity";
import DocumentsPagination from "../components/common/DocumentsPagination";
import ObjectNotifications from "../components/ObjectNotifications";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  editLink: {
    color: "rgb(0, 104, 178)",
    margin: "0 auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  paymentForm: {
    margin: "0 auto",
    padding: "2rem",
    maxWidth: "1300px",
    "@media (max-width: 700px)": {
      padding: "0.5rem",
    },
  },
  nonActive: {
    border: "solid #E86613 2px",
    padding: "1rem",
    borderRadius: 10,
    backgroundColor: "#FF9F61",
    margin: "1rem 1rem 0 1rem",
  },
});

class DocumentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingContent: true,
      document: null,
      documentAttributes: [],
      documentNotifications: [],
      documentTypes: [],
      activity: [],
      tabValue: 0,
      editDocument: false,
      editDocumentType: null,
      editDate: null,
      editUploadType: null,
      editUploadLink: null,
      isSaving: false,
      notificationUsers: [],
      isArchive: false,
      editDoesNotExpire: false,
      contractUsers: [],
      isDownloading: false,
      access: {},
      isVerified: false,
      whiteList: null,
    };
  }

  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      [
        "document_verify",
        "document_edit",
        "document_view",
        "document_download",
        "vendor_document_attribute",
        "attribute_edit",
        "attribute_view",
        "attribute_add",
        "comment_add",
        "history_view",
        "history_add",
        "document_archive",
        "vendor_document",
        "task_edit",
        "task_view",
        "task_add",
        "task_complete",
      ],
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    this.getData();
  }

  viewHash = () => {
    const hash = window.location.hash;
    if (hash === "#attributes") {
      this.setState({ tabValue: 0 });
    } else if (hash === "#notifications") {
      this.setState({ tabValue: 1 });
    }
  };
  createSnack = (message, type, duration) => {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      variant: type,
      autoHideDuration: duration,
    });
  };
  getData = async () => {
    try {
    const data = await Promise.all([
      LambdaFetch(
        "get-document-details",
        "post",
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          document_id: window.location.pathname.split("document-details/")[1],
        })
      ),
      LambdaFetch(
        "get-activity",
        "post",
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          objectType: "DOCUMENT",
          objectId: window.location.pathname.split("document-details/")[1],
        })
      ),
      LambdaFetch(
        "get-all-users",
        "post",
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        null
      ),
      LambdaFetch(
        "contract-settings",
        "post",
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: "vendor",
        })
      ),
    ]);

    const documentData = data[0];
    const activityData = data[1];
    const users = data[2].data;
    const filteredUsers = users.users.filter((user) => {
      return !user.vendor_id;
    });
    const notificationUsers = filteredUsers.map((u, index) => {
      return { title: u.username, index: index, uuid: u.uuid };
    });
    this.setState({
      isLoadingContent: false,
      notificationTypes: documentData.data.notifications_types,
      document: documentData.data.document[0],
      documentAttributes: documentData.data.attributes,
      documentNotifications: documentData.data.notifications,
      documentTypes: documentData.data.document_types,
      activity: activityData.data.activity,
      notificationUsers,
      contractUsers: filteredUsers,
      isVerified: documentData.data.document[0].verified === 1,
      whiteList: data[3].data.whiteList,
    });
  } catch(e) {
    console.log(e)
  }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  editDocument = async (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });

    let updatedDoc = Object.assign({}, this.state.document);

    updatedDoc.title = event.target.editDocTitle.value;
    updatedDoc.expiration_date = null;
    updatedDoc.description = event.target.editDescription.value;
    updatedDoc.document_type_id = this.state.editDocumentType;
    updatedDoc.document_type_other = this.state.editOtherDocType;

    if (this.state.document.reference_type === "l") {
      updatedDoc.document_reference = event.target.editUploadLink.value;
    }

    if (!this.state.editDoesNotExpire) {
      updatedDoc.expiration_date = this.state.editDate;
      if (new Date(this.state.editDate) > new Date("3000-01-01")) {
        this.createSnack("Enter a valid date", "warning", 3000);
        this.setState({ isSaving: false });
        return null;
      }
    }
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      document: updatedDoc,
    };

    const info = await LambdaFetch(
      "edit-document",
      "post",
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData),
      "Successfully updated document"
    );
    if (!info) {
      this.setState({ isSaving: false });
      return null;
    }

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: "DOCUMENT",
      OBJ_ID: this.state.document.document_id,
      ACTIVITY_TYPE: "UPDATE",
      ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} updated document information`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      field_changes: info.data.changes.field_changes,
    };

    FieldChangeActivity({ activity }, () => {
      this.setState(
        {
          document: info.data.document,
          isSaving: false,
          editDocument: false,
        },
        () => {
          this.getData();
        }
      );
    });
  };

  getFile(key, originalName) {
    this.setState({ isDownloading: true, fileLoad: key });
    const getObject = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      key: `${this.props.fetchInitialData.credentials.clientInfo.client_id}/${key}`,
    };
    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.createSnack(info.error, "error", 3000);
          return null;
        } else if (info.success) {
          const parsed = info.data.data;
          const read = Buffer.from(parsed.Body.data).toString("base64");
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = `data:${info.data.data.ContentType};base64,${read}`;
          a.download = originalName;
          a.click();
          this.setState({ isDownloading: false });

          const activityDoc = {
            CLIENT_ID: this.props.fetchInitialData.credentials.user.clientId,
            OBJ_TYPE: "DOCUMENT",
            OBJ_ID: this.state.document.document_id,
            ACTIVITY_TYPE: "DOWNLOAD",
            ACTIVITY_STRING: `Downloaded ${originalName}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.user.clientId,
            OBJ_TYPE: this.state.document.parent_type.toUpperCase(),
            OBJ_ID: this.state.document.parent_id,
            ACTIVITY_TYPE: "DOWNLOAD",
            ACTIVITY_STRING: `Downloaded ${originalName}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          writeToActivity({ activity: activityDoc }, () => {
            writeToActivity({ activity }, () => {
              this.getData();
            });
          });
        }
      })
      .catch((err) => console.log(err));
  }

  archive = (callback, type, id, name, isComplete, reactive) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      reactive: reactive ? reactive : false,
      isComplete: isComplete ? isComplete : false,
      id: id,
    };
    fetch(`${process.env.REACT_APP_API}/update-status`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: "DOCUMENT",
            OBJ_ID: this.state.document.document_id,
            ACTIVITY_TYPE: isComplete ? "COMPLETED" : "ARCHIVED",
            ACTIVITY_STRING: `Archived ${type}: ${name}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          if (type === "notification") {
            activity.ACTIVITY_STRING = `Completed Task: ${name}`;
          }

          writeToActivity({ activity }, (status) => {
            if (status === 200) {
              if (type === "document") {
                const activity = {
                  CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                    .client_id,
                  OBJ_TYPE: "CONTRACT",
                  OBJ_ID: this.state.document.parent_id,
                  ACTIVITY_TYPE: "ARCHIVED",
                  ACTIVITY_STRING: `Archived Document: ${this.state.document.title}`,
                  USER_ID: this.props.fetchInitialData.credentials.user.uuid,
                };
                writeToActivity({ activity }, (status) => {
                  if (status === 200) {
                    this.setState({ isEditting: false }, () => {
                      this.props.fetchInitialData.createSnack(
                        `Successfully ${
                          reactive ? "reactivated" : "archived"
                        } document`,
                        "success",
                        2000
                      );
                      this.getData();
                    });
                    callback(200);
                  } else {
                    this.props.fetchInitialData.createSnack(
                      `There was a error trying to ${
                        reactive ? "reactivate" : "archive"
                      } document`,
                      "error",
                      2000
                    );
                    callback(400);
                  }
                });
              } else {
                this.setState({ isEditting: false }, () => this.getData());
                callback(200);
              }
            } else {
              callback(400);
            }
          });
        }
      });
  };

  verifyDoc = async () => {
    await LambdaFetch(
      "edit-document",
      "post",
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({
        verify: true,
        document_id: this.state.document.document_id,
        verified: this.state.isVerified ? 0 : 1,
      }),
      `Successfully ${
        this.state.isVerified ? "unverified document" : "verified document"
      }`
    );
    this.getData();
  };

  render() {
    const { classes } = this.props;
    if (this.state.isLoadingContent) {
      return (
        <LoadingCircle/>
      );
    }

    const load = this.state.isDownloading ? (
      <Fade in={this.state.isDownloading} unmountOnExit>
        <CircularProgress
          style={{ marginLeft: "10px", color: "#E86613" }}
          size="15px"
        />
      </Fade>
    ) : (
      ""
    );
    const viewDoc = !!this.state.access.document_edit && (
      <Fragment>
        {this.state.document.reference_type === "l" ? (
          <a
            href={this.state.document.document_reference}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: "1rem" }}
            className={classes.editLink}
          >
            view/download
          </a>
        ) : (
          <Fragment>
            <span
              onClick={() => {
                if (this.state.isDownloading) return null;
                this.getFile(
                  this.state.document.document_reference,
                  this.state.document.title
                );
              }}
              className={classes.editLink}
              style={{ marginTop: "1rem" }}
            >
              download
            </span>
            {load}
          </Fragment>
        )}
      </Fragment>
    );

    const { document } = this.state;

    return (
      <div className={classes.paymentForm}>
        <div style={{ marginBottom: "1rem" }}>
          {this.props.fetchInitialData.credentials.isVendor ? (
            <Fragment>
              {document.parent_type === "vendor" ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href={"/vendordetails#attachments"}>
                    Profile{" "}
                  </Link>

                  <Typography color="textPrimary">{document.title}</Typography>
                </Breadcrumbs>
              ) : (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href={"/vcontracts"}>
                    Contracts
                  </Link>
                  <Typography color="textPrimary">{document.title}</Typography>
                </Breadcrumbs>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {document.parent_type === "vendor" ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    href={`/viewvendordetails/${document.parent_id}`}
                  >
                    {document.parent_description}
                  </Link>

                  <Link
                    color="inherit"
                    href={`/viewvendordetails/${document.parent_id}#attachments`}
                  >
                    Documents
                  </Link>
                  <Typography color="textPrimary">{document.title}</Typography>
                </Breadcrumbs>
              ) : (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href={"/contracts#all"}>
                    Contracts
                  </Link>
                  <Link
                    color="inherit"
                    href={`/contract-details/${document.parent_id}`}
                  >
                    {document.parent_title}
                  </Link>
                  <Link
                    color="inherit"
                    href={`/contract-details/${document.parent_id}#attachments`}
                  >
                    Documents
                  </Link>
                  <Typography color="textPrimary">{document.title}</Typography>
                </Breadcrumbs>
              )}
            </Fragment>
          )}
        </div>
        <DocumentsPagination
          setLoading={this.setLoading}
          jsonWebTok={this.props.fetchInitialData.credentials.user.jsonWebTok}
          parentId={document.parent_id}
          parentType={document.parent_type}
        />
        <Grid container spacing={2}>
          {!this.state.editDocument ? (
            <Grid item xs={12} sm={12} md={12}>
              <Card style={{ position: "relative" }} elevation={2}>
                {parseInt(this.state.document.status) === 2 ? (
                  <div className={classes.nonActive}>
                    <div>**This document is archived and can't be edited.</div>
                  </div>
                ) : (
                  <Fragment>
                    {!!this.state.access.document_edit && (
                      <Button
                        onClick={() => {
                          this.setState({
                            editDocument: true,
                            editDocumentType: document.document_type_id,
                            editDate: document.expiration_date
                              ? document.expiration_date.split("T")[0]
                              : "",
                            editUploadType: this.state.document.reference_type,
                            editDoesNotExpire: !this.state.document
                              .expiration_date,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "10px",
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Fragment>
                )}

                <CardHeader
                  title={
                    <div>
                      {!(
                        this.props.fetchInitialData.credentials.isVendor ||
                        document.parent_type === "vendor"
                      ) && (
                        <Typography variant="h6" component="p">
                          Vendor:{" "}
                          {
                            <span
                              className={classes.editLink}
                              onClick={() =>
                                this.props.history.push(
                                  `/viewvendordetails/${document.VENDOR_ID}`
                                )
                              }
                            >
                              {document.VENDOR_VNAME}
                            </span>
                          }
                        </Typography>
                      )}
                      <Typography variant="h6" component="p">
                        {document.title}
                      </Typography>
                    </div>
                  }
                  subheader={
                    new Date(document.expiration_date).getTime()
                      ? moment
                          .utc(new Date(document.expiration_date))
                          .format("MM/DD/YYYY")
                      : "N/A"
                  }
                />

                <CardContent style={{ paddingTop: 0 }}>
                  <Typography variant="body2" component="p">
                    {document.description}
                  </Typography>
                  {viewDoc}
                </CardContent>
                <FormControlLabel
                  style={{ position: "absolute", right: "15px", bottom: 0 }}
                  control={
                    <Checkbox
                      disabled={
                        this.props.fetchInitialData.credentials.isVendor ||
                        !this.state.access.document_verify
                      }
                      style={{
                        color:
                          this.props.fetchInitialData.credentials.isVendor ||
                          !this.state.access.document_verify
                            ? ""
                            : this.props.fetchInitialData.credentials.primaryAppColor,
                      }}
                      checked={this.state.isVerified}
                      onChange={() => this.verifyDoc()}
                    />
                  }
                  label="Verified"
                  labelPlacement="start"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Card style={{ position: "relative" }} elevation={2}>
                <CardContent>
                  <h5 style={{ margin: "1rem" }}>Edit Document</h5>
                  <Fragment>
                    <form onSubmit={(event) => this.editDocument(event)}>
                      <ListItem dense>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Title"
                              margin="dense"
                              id="editDocTitle"
                              variant="outlined"
                              defaultValue={document.title}
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                              style={{ margin: "0", verticalAlign: "middle" }}
                              control={
                                <Checkbox
                                  checked={this.state.editDoesNotExpire}
                                  onChange={() =>
                                    this.setState({
                                      editDoesNotExpire: !this.state
                                        .editDoesNotExpire,
                                    })
                                  }
                                  name="editDoesNotExpire"
                                  style={{
                                    color: this.props.fetchInitialData.credentials.primaryAppColor,
                                    display: "inline",
                                  }}
                                />
                              }
                              label="Document does not expire"
                            />
                          </Grid>
                          {!this.state.editDoesNotExpire && (
                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                id="editDocDate"
                                required
                                label="Expiration Date"
                                type="date"
                                size="small"
                                variant="outlined"
                                value={this.state.editDate}
                                onChange={(event) =>
                                  this.setState({
                                    editDate: event.target.value,
                                  })
                                }
                                InputLabelProps={{ shrink: true }}
                                autoComplete="off"
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              fullWidth
                              multiline
                              label="Description"
                              margin="dense"
                              id="editDescription"
                              variant="outlined"
                              defaultValue={document.description}
                              autoComplete="off"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              id="editDocType"
                              select
                              fullWidth
                              margin="dense"
                              label="Document Type"
                              value={this.state.editDocumentType}
                              onChange={(event) =>
                                this.setState({
                                  editDocumentType: event.target.value,
                                })
                              }
                              variant="outlined"
                              autoComplete="off"
                            >
                              {this.state.documentTypes.map((option) => (
                                <MenuItem
                                  key={option.document_type_id}
                                  value={option.document_type_id}
                                >
                                  {option.description}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              style={{
                                display:
                                  this.state.editDocumentType !== "o"
                                    ? "none"
                                    : "",
                              }}
                              id="editOtherDocType"
                              fullWidth
                              margin="dense"
                              label="Enter a Document Type"
                              variant="outlined"
                              defaultValue={
                                this.state.document.documentTypeOther
                              }
                              autoComplete="off"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              style={{
                                display:
                                  this.state.editUploadType !== "l"
                                    ? "none"
                                    : "",
                              }}
                              id="editUploadLink"
                              fullWidth
                              margin="dense"
                              label="Document Link"
                              variant="outlined"
                              defaultValue={
                                this.state.document.reference_type === "c"
                                  ? ""
                                  : this.state.document.document_reference
                              }
                              autoComplete="off"
                            />
                          </Grid>
                        </Grid>
                      </ListItem>

                      <div style={{ float: "right" }}>
                        <div style={{ margin: "1rem" }}>
                          {!!this.state.access.document_archive && (
                            <ConfirmDialog
                              handleConfirm={() =>
                                this.archive(
                                  (status) => {
                                    if (status === 200) {
                                      this.setState({ editDocument: false });
                                    }
                                  },
                                  "document",
                                  this.state.document.document_id,
                                  this.state.document.title
                                )
                              }
                              type="document"
                              title="Archive Document"
                              dialogTitle="Confirm Archive"
                              dialogText="Are you sure you want to archive this Document?"
                              color="red"
                            />
                          )}
                          <Button
                            style={{ marginRight: "0.5rem" }}
                            onClick={() =>
                              this.setState({
                                editDocument: false,
                                editDocumentType: null,
                                editDate: null,
                              })
                            }
                          >
                            Cancel
                          </Button>
                          {!!this.state.access.document_edit && (
                            <LoadingButton
                              label="Save"
                              isLoading={this.state.isSaving}
                              color="primaryVLButton"
                              buttonType="submit"
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </Fragment>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <AttachAttribTask
            tab={4}
              props={this.props}
              access={this.state.access}
              getData={this.getData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              currentUser={this.props.fetchInitialData.credentials.user.uuid}
              parentType="document"
              vendorId={this.state.document.VENDOR_ID}
              parentId={this.state.document.document_id}
              whiteList={this.state.whiteList}
            />


            <AttachAttribTask
            tab={5}
              props={this.props}
              access={this.state.access}
              getData={this.getData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              currentUser={this.props.fetchInitialData.credentials.user.uuid}
              parentType="document"
              vendorId={this.state.document.VENDOR_ID}
              parentId={this.state.document.document_id}
              whiteList={this.state.whiteList}
            />
            {!!this.state.access.comment_add && (
              <>
           <div style={{height: '0.5rem'}}></div>
                <Comments
                  fetchInitialData={this.props.fetchInitialData}
                  objectId={
                    window.location.pathname.split("document-details/")[1]
                  }
                  objectType="DOCUMENT"
                  vendorId={this.state.document.VENDOR_ID}
                  isVendor={this.props.fetchInitialData.credentials.isVendor}
                />
                </>
         
            )}
            {!!this.state.access.history_view && (
              <Activity
                title="Document History"
                activity={this.state.activity}
                timezone={this.props.fetchInitialData.credentials.user.timezone}
                appWidth={this.props.fetchInitialData.credentials.appWidth}
              />
            )}

            <ObjectNotifications
              createSnack={this.props.fetchInitialData.createSnack}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              parentId={this.state.document.document_id}
              parentType="document"
              isVendor={!!this.props.fetchInitialData.credentials.isVendor}
              vendorId={this.state.document.VENDOR_ID}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(DocumentDetails));
