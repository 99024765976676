import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import { responsive } from '../utils/constants/muidatatablesConsts';
import { vendorContractsUpdatedColumns, vendorContractsUpdatedColumnsWithClassification } from "../utils/constants/vendorsColumns";
import { customTableSort, formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

class VendorContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: Date.now(),
      endDate: Date.now(),
      tableData: [],
      isLoading: true,
      createNewContract: false,
      currentContracts: [],
      contracts: null,
      archived: false,
      allVendors: [],
      classification1Header: '',
      classification1Values: [],
      helperTextId: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_API}/get-contracts`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.props.fetchInitialData.credentials.user.jsonWebTok,
        vendorId: this.props.vendorId ? this.props.vendorId : null,
      }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          const data = info.data
          if (info.success) {
            const activeContracts = data.contracts.filter(
                (contract) => parseInt(contract.status) === 1
            );

            if (data.classification1Header.length !== 0) {
              vendorContractsUpdatedColumnsWithClassification[2].label = data.classification1Header[0].classification_type_name
            }

            const tableData = this.makeTableData(
                this.props.vendorId ? activeContracts
                    .filter((c) => c.vendor_id === this.props.vendorId) : activeContracts,
                data.classification1Header);

            this.setState({
              tableData,
              currentContracts: activeContracts,
              contracts: data.contracts,
              isLoading: false,
              contractTypes: data.contractTypes,
              allVendors: data.vendors,
              classification1Header: data.classification1Header,
              classification1Values: data.classification1Values,
            });
          } else {
            this.setState({ isLoading: false, },
                () => window.alert('There was an error loading your contracts')
            );
          }
        });
  };

  getRemainingDays = (endDate) => {
    if (!new Date(endDate).getTime()) {
      return 'N/A';
    }
    const daysLeft = Math.floor((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    return daysLeft > 0 ? daysLeft : 0
  }

  makeTableData = (data, classification) =>
      data.map((contract) => {
        const row = [
          <span
              className="editLink"
              onClick={() => this.props.props.history.push(`/contract-details/${contract.contract_id}`)}>
            {contract.contract_number}
          </span>,
          contract.title,
          contract.classification_value,
          moment(new Date(contract.start_date)).format('MM/DD/YYYY'),
          moment(new Date(contract.end_date)).format('MM/DD/YYYY'),
          this.getRemainingDays(contract.end_date),
          formatter.format(contract.contract_amount),
        ];

        if (classification.length === 0) {
          row.splice(2, 1);
        }
        return row;
      });

  viewArchived = () => {
    const currentContracts = this.state.contracts.filter(
        (doc) => parseInt(doc.status) === (this.state.archived ? 1 : 2)
    );
    const tableData = this.makeTableData(
        this.props.vendorId ? currentContracts
            .filter((c) => c.vendor_id === this.props.vendorId) : currentContracts,
        this.state.classification1Header
    );
    this.setState({
      currentContracts,
      tableData,
      archived: !this.state.archived,
      isLoading: false,
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
          <LoadingCircle/>
      );
    } else {

      const viewNum = this.state.contracts.length - this.state.tableData.length;
      return (
          <div>
            <div className="tableCard">
              <MUIDataTable
                  title={this.state.archived ? 'Archived Contracts' : 'Active Contracts'}
                  data={this.state.tableData}
                  columns={this.state.classification1Header.length !== 0 ?vendorContractsUpdatedColumnsWithClassification : vendorContractsUpdatedColumns}
                  options={{
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                    print: false,
                    elevation: this.props.embedded ? 0 : 4,
                    customSort: (data, colIndex, order) => customTableSort(data, colIndex, order, 4),
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        tableData: this.makeTableData(this.state.currentContracts
                            .filter((c) => c.vendor_id === this.props.vendorId).filter((contract) => {
                              if (query) {
                                return (
                                    contract.contract_number
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    contract.title
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    contract.classification_value
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    this.getRemainingDays(contract.end_date)
                                        .toString().toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    formatter.format(contract.contract_amount)
                                        .toString()
                                        .includes(query.toLowerCase().trim()) ||
                                    contract.contract_amount.toString()
                                        .includes(query.toLowerCase().trim())
                                );
                              }
                              return contract;
                            }), this.state.classification1Header)
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        tableData: this.makeTableData(
                            this.state.currentContracts
                                .filter((c) => c.vendor_id === this.props.vendorId),
                            this.state.classification1Header)
                      });
                    },
                  }}
              />
            </div>
            <div style={{ margin: '1rem 0', position: 'relative' }}>
              <Button
                  color={this.state.archived ? 'primary' : 'secondary'}
                  variant="outlined"
                  onClick={() => this.viewArchived()}
              >
                {this.state.archived
                    ? `View Active (${viewNum})`
                    : `View Archived (${viewNum})`}
              </Button>
            </div>
          </div>
      );
    }
  }
}

export default VendorContracts;
