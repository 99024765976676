import React from "react";
import { CircularProgress, Grid, withStyles , Link} from "@material-ui/core";
import { MemoryRouter, Route } from "react-router";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const styles = ({
  link: {
    color: 'black',
    fontSize: 20,
    '&:hover': {
      color: 'black',
    }
  },
  disabledLink: {
    color: 'grey',
    pointerEvents: 'none',
    fontSize: 20,
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
    }
  },
  icon: {
    position: 'relative',
    top: -2,
    fill: 'black',
  },
  disabledIcon: {
    position: 'relative',
    top: -2,
    fill: 'grey',
  },
  spinner: {
    width: 30,
    height: 30,
    margin: '0 auto',
    color: 'grey',
  }
})

class DocumentsPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
    }
  }

  getAllDocuments() {
    fetch(`${process.env.REACT_APP_API}/read-document`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.jsonWebTok,
        type: this.props.parentType,
        parent_id: this.props.parentId,
      }),
    })
    .then(res => res.json())
    .then(({ data }) => {
        this.setState({ documents: data.documents.filter(el => el.status === 1) })
    }).catch((error) => {
      console.log(error)
    })
  }

  componentDidMount() {
    this.getAllDocuments();
  }

  render() {
    const { documents } = this.state;
    const { classes } = this.props;
    return (
        <MemoryRouter initialEntries={[window.location.pathname]}>
          <Route>
            {() => {
              const currentDoc = parseInt(window.location.pathname.split('document-details/')[1])
              const currentPage = documents.indexOf(documents.find((el) => el.document_id === currentDoc));
              return (
                  <Grid container justify={'space-between'} style={{ height: 40 }}>
                    {!!documents.length ? (
                        <>
                          <Grid item>
                            <Link
                                className={documents[currentPage - 1] ? classes.link : classes.disabledLink}
                                href={`/document-details/${
                                    documents[currentPage - 1]
                                        ? documents[currentPage - 1].document_id :
                                        documents[0].document_id
                                }`}>
                              <DoubleArrowIcon
                                  className={documents[currentPage - 1] ? classes.icon : classes.disabledIcon}
                                  style={{ transform: "rotate(-180deg)" }}
                              />Previous</Link>
                          </Grid>
                          <Grid item>
                            <Link
                                className={documents[currentPage + 1] ? classes.link : classes.disabledLink}
                                href={`/document-details/${
                                    documents[currentPage + 1] ?
                                        documents[currentPage + 1].document_id :
                                        documents[documents.length - 1].document_id
                                }`}>Next
                              <DoubleArrowIcon
                                  className={documents[currentPage + 1] ? classes.icon : classes.disabledIcon}
                              />
                            </Link>
                          </Grid>
                        </>
                    ) : (
                        <CircularProgress className={classes.spinner}/>
                    )}
                  </Grid>
              );
            }}
          </Route>
        </MemoryRouter>

    );
  }
}

export default withStyles(styles)(DocumentsPagination);
