import React from 'react';
import {
	withStyles,
	Grid,
	Tabs,
	Tab,
	Card,
	Typography,
	Divider,
	List,
	IconButton,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	MuiThemeProvider,
	createMuiTheme,
  Tooltip
} from '@material-ui/core';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';

import Calendar from '../components/Calendar';
import MenuDropdown from '../components/MenuDropdown';
import CreateContract from '../components/CreateContract';
import ClientCreateVendor from '../components/ClientCreateVendor';
import VerifyAccess from '../functions/VerifyAccess';
import { responsive } from '../utils/constants/muidatatablesConsts';
import { AntTabs, AntTab } from '../components/common/Tabs';
import LoadingCircle from '../components/common/LoadingCircle';

const CustomTooltip = withStyles({
	tooltip: {
		padding: '0.5rem',
		color: 'white',
		backgroundColor: '#545454',
	},
})(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const tableCellOverflow = (value) => {
	if (value.length <= 24) {
		return value;
	} else {
		return (
			<CustomTooltip arrow title={<span style={{ fontSize: '14px' }}>{value}</span>}>
				<Typography style={{ textOverflow: 'ellipsis', fontSize: '14px' }} noWrap={true}>{`${value.slice(0, 24)}...`}</Typography>
			</CustomTooltip>
		);
	}
};

const styles = () => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	tabsRoot: {
		borderBottom: '1px solid #e8e8e8',
	},
	tabsIndicator: {
		backgroundColor: '#E86613',
	},
	selected: {},
	tabRoot: {
		textTransform: 'initial',
		minWidth: 72,
		'&:hover': {
			color: '#E86613',
			opacity: 1,
		},
		'&$selected': {
			color: '#E86613',
		},
		'&:focus': {
			color: '#E86613',
			outline: 'none',
		},
		'&:active': {
			outline: 'none',
		},
	},
});

class ClientLandingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingContent: true,
			expiringDates: [],
			notificationDates: [],
			selectedDate: new Date(),
			currentUsersDocs: [],
			showOnlyUserDocs: false,
			days90TabValue: 0,
			newOpen: null,
			contractTypes: [],
			allVendors: [],
			classification1Header: [],
			classification1Values: [],
			access: null,
		};
	}

	getMuiTheme = () =>
		createMuiTheme({
			overrides: {
				MuiTableFooter: {
					root: {
						'& td': {
							'& > div': {
								padding: '0!important',
							},
							'& button': {
								padding: '0!important',
							},
						},
					},
				},
			},
		});

	createSnack(message, type, duration) {
		this.props.enqueueSnackbar(message, {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			variant: type,
			autoHideDuration: duration,
		});
	}

	componentDidMount() {
		this.getData();
		VerifyAccess(
			(data) => {
				this.setState({ access: data.access });
			},
			['vendor_add', 'contract_add'],
			this.props.fetchInitialData.credentials.user.jsonWebTok
		);
	}

	getData = (date) => {
		let lsd, led;

		if (date) {
			lsd = startOfMonth(new Date(date));
			led = endOfMonth(new Date(date));
		} else {
			lsd = startOfMonth(new Date(this.state.selectedDate));
			led = endOfMonth(new Date(this.state.selectedDate));
		}

		const fetchData = JSON.stringify({
			jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
			lookup_start_date: format(lsd, 'yyyy-MM-dd'),
			lookup_end_date: format(led, 'yyyy-MM-dd'),
		});
		const requests = [
			{
				url: `${process.env.REACT_APP_API}/get-risk-exposure`,
				body: fetchData,
			},
			{
				url: `${process.env.REACT_APP_API}/notification-risk`,
				body: fetchData,
			},
			{
				url: `${process.env.REACT_APP_API}/calendar-90-days`,
				body: fetchData,
			},
			{
				url: `${process.env.REACT_APP_API}/get-contracts`,
				body: fetchData,
			},
		];

		Promise.all(
			requests.map((request) => {
				return fetch(request.url, {
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
					},
					body: request.body,
				})
					.then((resp) => resp.json())
					.catch(() => {
						this.createSnack('There was an error fetch the data', 'error', 2000);
					});
			})
		).then((data) => {
			if (!data[2]) return;
			const riskData = data[0];
			const notificationRisk = data[1];
			const notificationDates = data[2];
			const createContractData = data[3];

			let next90DayTasks = [];
			let delinquentTasks = [];

			if (notificationDates.success) {
				next90DayTasks = this.makeTableData(notificationDates.data.next90, 'next90');
				delinquentTasks = this.makeTableData(
					notificationDates.data.pastDue.filter((item) => !isNaN(new Date(item.expiration_date).getTime())),
					'delinquent'
				);
			}

			this.setState({
				isLoadingContent: false,
				expiringDates: riskData.data.riskData,
				notificationDates: notificationRisk.data.notification_risk_data,
				next90DayTasks,
				delinquentTasks,
				next90DayTaskData: notificationDates.data.next90.sort((a, b) => {
					return new Date(a.expiration_date) - new Date(b.expiration_date);
				}),
				delinquentTaskData: notificationDates.data.pastDue.sort((a, b) => {
					return new Date(a.expiration_date) - new Date(b.expiration_date);
				}),
				contractTypes: createContractData.data.contractTypes,
				allVendors: createContractData.data.vendors,
				classification1Header: createContractData.data.classification1Header,
				classification1Values: createContractData.data.classification1Values,
			});
		});
	};

	updateExpiringDateCount = (docs) => {
		let expiringDatesCount = {};
		docs.forEach((doc) => {
			const date = new Date(doc.expiration_date);
			const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;

			if (expiringDatesCount[dateString]) {
				expiringDatesCount[dateString]++;
			} else {
				expiringDatesCount[dateString] = 1;
			}
		});
		return expiringDatesCount;
	};

	updateNotificationDateCount = (notifications) => {
		let notificationDatesCount = {};
		notifications.forEach((n) => {
			const nDate = new Date(n.notify_date);

			const nDateString = `${nDate.getUTCFullYear()}-${nDate.getUTCMonth()}-${nDate.getUTCDate()}`;
			if (notificationDatesCount[nDateString]) {
				if (n.days_out === 0) {
					notificationDatesCount[nDateString]['dayOf']++;
				} else {
					notificationDatesCount[nDateString]['upcoming']++;
				}
			} else {
				if (n.days_out === 0) {
					notificationDatesCount[nDateString] = { dayOf: 1, upcoming: 0 };
				} else {
					notificationDatesCount[nDateString] = { dayOf: 0, upcoming: 1 };
				}
			}
		});
		return notificationDatesCount;
	};
	updateListDate = (date) => {
		this.setState({ selectedDate: date });
	};
	updateMonthsData = (date) => {
		this.getData(date);
	};

  customRender = (value, tableMeta, updateValue) => {
    const textColor = this.props.fetchInitialData.credentials.primaryAppColor;
  
      const [desc,name,type] = value.split("___")
      const description = type === 'task' ? desc : `${desc} - Expires`
    return (
      <div>
        <div>{tableCellOverflow(description)}
        </div>
        <div style={{ fontSize: '0.85rem', color: textColor }}>{tableCellOverflow(name)}</div>
      </div>
      )
  };

	makeTableData = (data, chartType) => {
		const textColor = this.props.fetchInitialData.credentials.primaryAppColor;
		let sortedData = data.sort((a, b) => {
			return new Date(a.expiration_date) - new Date(b.expiration_date);
		});

		if (chartType === 'next90') {
			return sortedData.map((task) => {
				let daysLeft = Math.ceil(
					(new Date(task.expiration_date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
				);

				let formattedDate = moment.utc(task.expiration_date).format('MM/DD/YYYY');

				if (isNaN(new Date(task.expiration_date).getTime())) {
					formattedDate = 'N/A';
					daysLeft = 'N/A';
				}

				return [
          `${task.description}___${task.vendor_vname}___${task.type}`,
					// <div>
					// 	<div>{`${task.description} ${task.type === 'task' ? '' : ' - Expires'}`}</div>
					// 	<div style={{ fontSize: '0.85rem', color: textColor }}>{task.vendor_vname}</div>
					// </div>,
					<div>
						<div>{formattedDate}</div>
						<div style={{ fontSize: '0.75rem', color: textColor }}>
							<i>{`${daysLeft} days`}</i>
						</div>
					</div>,
				];
			});
		} else {
			return sortedData.map((task) => {
				let daysLeft = Math.ceil(
					(new Date(task.expiration_date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
				);

				let formattedDate = moment.utc(task.expiration_date).format('MM/DD/YYYY');

				if (isNaN(new Date(task.expiration_date).getTime())) {
					formattedDate = 'N/A';
					daysLeft = 'N/A';
				}

				return [
					`${task.description}___${task.vendor_vname}___${task.type}`,

					<div>
						<div>{formattedDate}</div>
						<div style={{ fontSize: '0.75rem', color: textColor }}>
							<i>{`${Math.abs(daysLeft)} days overdue`}</i>
						</div>
					</div>,
				];
			});
		}
	};
	handle90dayTabChange = (event, newValue) => {
		this.setState({ days90TabValue: newValue });
	};
	closeCreate = () => {
		this.setState({ newOpen: null });
	};

	render() {

		if (this.state.isLoadingContent) {
			return <LoadingCircle />;
		}
		let currentExpiringDocs, currentNotifications;
		if (this.state.showOnlyUserDocs) {
			currentExpiringDocs = this.state.expiringDates.filter((e) => {
				const lookup = `${e.record_type.toLowerCase()}-${e.id}`;
				return this.state.currentUsersDocs.indexOf(lookup) !== -1;
			});

			currentNotifications = this.state.notificationDates.filter((e) => {
				const lookup = this.props.fetchInitialData.credentials.user.uuid;
				return JSON.parse(e.users_to_notify).indexOf(lookup) !== -1;
			});
		} else {
			currentExpiringDocs = this.state.expiringDates;
			currentNotifications = this.state.notificationDates;
		}
		const calendarExpiringDocs = this.updateExpiringDateCount(currentExpiringDocs);
		const calendarNotifications = this.updateNotificationDateCount(currentNotifications);
		const selectedExpiringDocs = currentExpiringDocs.filter((e) => {
			const date = new Date(e.expiration_date);
			const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;
			const selectedDate = new Date(this.state.selectedDate);
			const selectedDateString = `${selectedDate.getUTCFullYear()}-${selectedDate.getUTCMonth()}-${selectedDate.getUTCDate()}`;
			return dateString === selectedDateString;
		});

		const selectedNotifications = currentNotifications.filter((e) => {
			const date = new Date(e.notify_date);
			const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;
			const selectedDate = new Date(this.state.selectedDate);
			const selectedDateString = `${selectedDate.getUTCFullYear()}-${selectedDate.getUTCMonth()}-${selectedDate.getUTCDate()}`;
			return dateString === selectedDateString;
		});



let next90Columns = [
	{
		name: 'description',
		label: 'Description',
		options: {
      customBodyRender: this.customRender,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'date',
		label: 'Date',
		options: {
			filter: false,
			sort: true,
		},
	},
];

let pastDueColumns = [
	{
		name: 'task',
		label: 'Task',
		options: {
      customBodyRender: this.customRender,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'date',
		label: 'Date',
		options: {
			filter: false,
			sort: true,
		},
	},
];
		return (
			<div style={{ margin: '1rem' }}>
				<div style={{ margin: '1rem auto', maxWidth: '1800px' }}>
					{this.state.newOpen === 'contract' &&
						this.props.fetchInitialData.credentials.clientInfo.contract_license === 1 && (
							<Dialog
								fullScreen
								open={this.state.newOpen === 'contract'}
								onClose={() => this.setState({ newOpen: null })}
								TransitionComponent={Transition}
							>
								<AppBar style={{ backgroundColor: '#545454', position: 'relative' }}>
									<Toolbar>
										<IconButton
											edge="start"
											color="inherit"
											onClick={() => this.setState({ newOpen: null })}
											aria-label="close"
										>
											<CloseIcon />
										</IconButton>
										<Typography variant="h6">Create New Contract</Typography>
									</Toolbar>
								</AppBar>
								<CreateContract
									props={this.props}
									pushhistory={this.props.history}
									closeCreate={this.closeCreate}
									allVendors={this.state.allVendors}
									contractTypes={this.state.contractTypes}
									classification1Header={this.state.classification1Header}
									classification1Values={this.state.classification1Values}
								/>
							</Dialog>
						)}

					{this.state.newOpen === 'vendor' && (
						<Dialog
							fullScreen
							open={this.state.newOpen === 'vendor'}
							onClose={() => this.setState({ newOpen: null })}
							TransitionComponent={Transition}
						>
							<AppBar style={{ backgroundColor: '#545454', position: 'relative' }}>
								<Toolbar>
									<IconButton
										edge="start"
										color="inherit"
										onClick={() => this.setState({ newOpen: null })}
										aria-label="close"
									>
										<CloseIcon />
									</IconButton>
									<Typography variant="h6">Create New Vendor</Typography>
								</Toolbar>
							</AppBar>
							<ClientCreateVendor props={this.props} fetchInitialData={this.props.fetchInitialData} />
						</Dialog>
					)}

					<div style={{ position: 'relative', height: '36px', marginTop: '1rem' }}>
						<div style={{ position: 'relative', float: 'right', height: '30px' }}>
							<MenuDropdown
								access={this.state.access}
								clientInfo={this.props.fetchInitialData.credentials.clientInfo}
								handleNewOpen={(value) => this.setState({ newOpen: value })}
							/>
						</div>
					</div>

					<Grid container justify="flex-start" spacing={2}>
						<Grid item xs={12} sm={8} md={8}>
							<div style={{ margin: '1rem auto' }}>
								<Calendar
									fetchInitialData={this.props.fetchInitialData}
									notificationDatesCount={calendarNotifications}
									expiringDatesCount={calendarExpiringDocs}
									updateListDate={this.updateListDate}
									getNewMonthData={this.updateMonthsData}
								/>
							</div>

							<div style={{ margin: '1rem auto', maxWidth: '1200px' }}>
								<div style={{ textAlign: 'center', margin: '1rem' }}>
									<Typography variant="h5" component="h2">
										{format(this.state.selectedDate, 'MMMM dd yyyy')}
									</Typography>
								</div>
								<Typography style={{ margin: '0.5rem 0' }} variant="h6" component="h6">
									Expiring Items
								</Typography>
								<Card style={{ padding: '1rem' }}>
									{selectedExpiringDocs.length === 0 ? (
										<div>There are no expiring items on this date</div>
									) : (
										<List style={{ padding: 0 }}>
											{selectedExpiringDocs.map((e, index) => {
												let clickOn = '';
												if (e.record_type === 'DOCUMENT') {
													clickOn = `/document-details/${e.id}`;
												} else if (e.record_type === 'CONTRACT') {
													clickOn = `/contract-details/${e.id}`;
												}
												return (
													<div
														style={{
															padding: '.25rem 0',
															position: 'relative',
														}}
														key={index}
													>
														<div
															style={{
																position: 'absolute',
																top: '-5px',
																right: '-5px',
															}}
															className="editLink"
															onClick={() => this.props.history.push(clickOn)}
														>
															view/edit
														</div>
														<Typography
															style={{ marginRight: '35ßpx' }}
															variant="h6"
															gutterBottom
														>
															{e.title}
														</Typography>
														<Typography
															style={{ color: '#666666' }}
															variant="subtitle2"
															gutterBottom
														>
															{`Vendor: ${e.vendor}`}
														</Typography>
														<Typography
															style={{ color: '#999999' }}
															variant="body2"
															gutterBottom
														>
															{`Type: ${e.record_type}`}
														</Typography>
														{!(index === selectedExpiringDocs.length - 1) && (
															<Divider style={{ margin: '1rem 0' }} />
														)}
													</div>
												);
											})}
										</List>
									)}
								</Card>

								<Typography style={{ margin: '0.5rem 0' }} variant="h6" component="h6">
									Upcoming Tasks
								</Typography>
								<Card style={{ padding: '1rem' }}>
									{selectedNotifications.length === 0 ? (
										<div>There are no upcoming tasks on this date</div>
									) : (
										<List style={{ padding: 0 }}>
											{selectedNotifications.map((e, index) => {
												let clickOn = '';
												let reminderText = '';
												if (e.days_out === 90) {
													reminderText = '90 day reminder';
												} else if (e.days_out === 60) {
													reminderText = '60 day reminder';
												} else if (e.days_out === 30) {
													reminderText = '30 day reminder';
												} else if (e.days_out === 7) {
													reminderText = '7 day reminder';
												} else if (e.days_out === 0) {
													reminderText = 'Day of reminder';
												}

												if (e.parent_type.toUpperCase() === 'DOCUMENT') {
													clickOn = `/document-details/${e.parent_id}#notifications`;
												} else if (e.parent_type.toUpperCase() === 'CONTRACT') {
													clickOn = `/contract-details/${e.parent_id}#notifications`;
												} else if (e.parent_type.toUpperCase() === 'VENDOR') {
													clickOn = `/viewvendordetails/${e.parent_id}#notifications`;
												}
												return (
													<div
														style={{
															padding: '.25rem 0',
															position: 'relative',
														}}
														key={index}
													>
														<div
															style={{
																position: 'absolute',
																top: '-5px',
																right: '-5px',
															}}
															className="editLink"
															onClick={() => this.props.history.push(clickOn)}
														>
															view/edit
														</div>
														<Typography
															style={{ marginRight: '35px' }}
															variant="h6"
															gutterBottom
														>
															{`${e.title} - ${reminderText}`}
														</Typography>
														<Typography
															style={{ color: '#666666' }}
															variant="subtitle2"
															gutterBottom
														>
															{`Vendor: ${e.vendor}`}
														</Typography>
														<Typography
															style={{ color: '#999999' }}
															variant="body2"
															gutterBottom
														>
															{`Type: ${e.parent_type.toUpperCase()}`}
														</Typography>
														<Typography
															style={{ color: '#666666' }}
															variant="subtitle2"
															gutterBottom
														>
															{e.description}
														</Typography>
														{!(index === selectedNotifications.length - 1) && (
															<Divider style={{ margin: '1rem 0' }} />
														)}
													</div>
												);
											})}
										</List>
									)}
								</Card>
							</div>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<div style={{ margin: '1rem auto', maxWidth: '1800px' }}>
								<Card elevation={2}>
									<AntTabs
										color={this.props.fetchInitialData.credentials.primaryAppColor}
										value={this.state.days90TabValue}
										onChange={this.handle90dayTabChange}
										variant="scrollable"
										scrollButtons="auto"
									>
										{[
											{ label: 'Next 90-days', value: 0 },
											{ label: 'Past Due', value: 1 },
										].map((el) => (
											<AntTab
												key={el.value}
												color={this.props.fetchInitialData.credentials.primaryAppColor}
												label={el.label}
												value={el.value}
											/>
										))}
									</AntTabs>
									{this.state.days90TabValue === 0 && (
										<MuiThemeProvider theme={this.getMuiTheme()}>
											<MUIDataTable
												data={this.state.next90DayTasks}
												columns={next90Columns}
												options={{
													customSearch: () => true,
													onSearchChange: (query) => {
														this.setState({
															next90DayTasks: this.makeTableData(
																this.state.next90DayTaskData.filter((task) => {
																	if (query) {
																		return (
																			task.vendor_vname
																				.toLowerCase()
																				.includes(query.toLowerCase().trim()) ||
																			task.description
																				.toLowerCase()
																				.includes(query.toLowerCase().trim())
																		);
																	}
																	return task;
																}),
																'next90'
															),
														});
													},
													onSearchClose: () => {
														this.setState({
															next90DayTasks: this.makeTableData(
																this.state.next90DayTaskData,
																'next90'
															),
														});
													},
													customSort: (data, colIndex, order) => {
														let dateCols = next90Columns.reduce(
															(dateCols, currCol, index) => {
																if (currCol.name.toLowerCase().indexOf('date') !== -1) {
																	return [...dateCols, index];
																} else return dateCols;
															},
															[]
														);
														let amountCols = next90Columns.reduce(
															(amountCols, currCol, index) => {
																if (
																	currCol.name.toLowerCase().indexOf('amount') !== -1
																) {
																	return [...amountCols, index];
																} else return amountCols;
															},
															[]
														);

														return data.sort((a, b) => {
															if (dateCols.indexOf(colIndex) !== -1) {
																return (
																	(new Date(a.data[colIndex]) <
																	new Date(b.data[colIndex])
																		? -1
																		: 1) * (order === 'desc' ? 1 : -1)
																);
															} else if (amountCols.indexOf(colIndex) !== -1) {
																return (
																	(parseFloat(
																		a.data[colIndex].replaceAll('[$,]', '')
																	) <
																	parseFloat(b.data[colIndex].replaceAll('[$,]', ''))
																		? -1
																		: 1) * (order === 'desc' ? 1 : -1)
																);
															} else {
																return (
																	(a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
																	(order === 'desc' ? 1 : -1)
																);
															}
														});
													},
													selectableRows: false,
													disableToolbarSelect: true,
													print: false,
													responsive,
													onRowClick: (rowData, rowState) => {
														let path = '';
														let parentId = this.state.next90DayTaskData[rowState.dataIndex]
															.parent_id;
														let type = this.state.next90DayTaskData[rowState.dataIndex]
															.type;

														if (
															this.state.next90DayTaskData[rowState.dataIndex]
																.parent_type === 'document'
														) {
															path = `/document-details/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														} else if (
															this.state.next90DayTaskData[rowState.dataIndex]
																.parent_type === 'contract'
														) {
															path = `/contract-details/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														} else if (
															this.state.next90DayTaskData[rowState.dataIndex]
																.parent_type === 'vendor'
														) {
															path = `/viewvendordetails/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														}
														this.props.history.push(path);
													},
												}}
											/>
										</MuiThemeProvider>
									)}
									{this.state.days90TabValue === 1 && (
										<MuiThemeProvider theme={this.getMuiTheme()}>
											<MUIDataTable
												data={this.state.delinquentTasks}
												columns={pastDueColumns}
												options={{
													customSearch: () => true,
													onSearchChange: (query) => {
														this.setState({
															delinquentTasks: this.makeTableData(
																this.state.delinquentTaskData.filter((task) => {
																	if (query) {
																		return (
																			task.vendor_vname
																				.toLowerCase()
																				.includes(query.toLowerCase().trim()) ||
																			task.description
																				.toLowerCase()
																				.includes(query.toLowerCase().trim())
																		);
																	}
																	return task;
																}),
																'next90'
															),
														});
													},
													onSearchClose: () => {
														this.setState({
															delinquentTasks: this.makeTableData(
																this.state.delinquentTaskData,
																'delinquent'
															),
														});
													},
													customSort: (data, colIndex, order) => {
														let dateCols = pastDueColumns.reduce(
															(dateCols, currCol, index) => {
																if (currCol.name.toLowerCase().indexOf('date') !== -1) {
																	return [...dateCols, index];
																} else return dateCols;
															},
															[]
														);
														return data.sort((a, b) => {
															if (dateCols.indexOf(colIndex) !== -1) {
																return (
																	(new Date(a.data[colIndex]) <
																	new Date(b.data[colIndex])
																		? -1
																		: 1) * (order === 'desc' ? 1 : -1)
																);
															} else {
																return (
																	(a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
																	(order === 'desc' ? 1 : -1)
																);
															}
														});
													},
													selectableRows: false,
													filterType: 'dropdown',
													responsive,
													print: false,
													onRowClick: (rowData, rowState) => {
														let path = '';
														let parentId = this.state.delinquentTaskData[rowState.dataIndex]
															.parent_id;
														let type = this.state.delinquentTaskData[rowState.dataIndex]
															.type;
														if (
															this.state.delinquentTaskData[rowState.dataIndex]
																.parent_type === 'document'
														) {
															path = `/document-details/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														} else if (
															this.state.delinquentTaskData[rowState.dataIndex]
																.parent_type === 'contract'
														) {
															path = `/contract-details/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														} else if (
															this.state.delinquentTaskData[rowState.dataIndex]
																.parent_type === 'vendor'
														) {
															path = `/viewvendordetails/${parentId}${
																type === 'task' ? '#notifications' : ''
															}`;
														}
														this.props.history.push(path);
													},
												}}
											/>
										</MuiThemeProvider>
									)}
								</Card>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(withSnackbar(ClientLandingPage));
