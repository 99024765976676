import React from "react";
import MUIDataTable from "mui-datatables";
import "./Admin.css";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../components/LoadingButton";
import Popover from "../components/Popover";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { responsive } from "../utils/constants/muidatatablesConsts";
import LoadingCircle from "../components/common/LoadingCircle";

class AdminUserQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyOfData: null,
      isOpen: false,
      currentEntry: null,
      confirmDelete: false,
      isActive: true,
      isEdit: false,
      editCheck: false,
      queueType: "VENDOR",
      isLoading: false,
      names: [],
      currentName: "",
      queueUsers: null,
      filterValues: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.getQueueData = this.getQueueData.bind(this);
    this.makeTableData = this.makeTableData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.addQueue = this.addQueue.bind(this);
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  componentDidMount() {
    this.getQueueData();
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  getQueueData() {
    let form = {
      get: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-or-update-user-queue`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
      .then((resp) => resp.json())
      .then((info) => {
        const data = info.data;
        const names = data.names.filter((user) => !user.vendor_id);

        let fV =
          data.selectedFilterValueAccountingSegment
            .selectedAccountingSegment !== null
            ? data.accountingLevels
                .filter(
                  (lvl) =>
                    lvl.level ===
                    data.selectedFilterValueAccountingSegment
                      .selectedAccountingSegment
                )
                .map((item) => {
                  return { value: item.value, description: item.description };
                })
            : [];
        this.setState({
          copyOfData: data.queueUser,
          queueUsers: data.queueUser,
          names: names,
          currentEntry: null,
          confirmDelete: false,
          isEdit: false,
          isOpen: false,
          isLoading: false,
          filterValues: fV,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  makeTableData(unfilteredRows, access) {
    return unfilteredRows.map((option, index) => {
      let reducedObj = [index + 1];
      let checked = false;
      if (option.FILTER_ON === "NO") {
        checked = true;
      }
      reducedObj.push(option.QUEUE_TYPE);
      reducedObj.push(option.LEVEL);
      reducedObj.push(option.username);
      reducedObj.push(option.FILTER_VALUE);
      reducedObj.push(option.FILTER_ON);
      reducedObj.push(
        <p
          onClick={() => {
            this.setState({
              isEdit: true,
              editCheck: !checked,
              currentEntry: option,
            });
          }}
          className="editLink"
        >
          {access.edit ? "edit" : "view"}
        </p>
      );
      return reducedObj;
    });
  }

  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  addQueue(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let filter = "YES";
    if (!this.state.isActive) {
      filter = "NO";
    }
    const newQueue = {
      type: this.state.queueType,
      username: this.state.currentName,
      level: event.target.level.value,
      filter: event.target.filter.value,
      filterOn: filter,
    };
    const fetchData = {
      new: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queueUser: newQueue,
    };
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/add-queue-user`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
      .then((resp) => resp.json())
      .then(() => {
        this.createSnack("Successfully created queue user", "success", 2000);
        this.getQueueData();
      })
      .catch(() => {
        this.createSnack("There was an error", "error", 2000);
      });
  }

  handleDelete(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const fetchData = {
      delete: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queueUser: this.state.currentEntry,
    };
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/get-or-update-user-queue`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
      .then((resp) => resp.json())
      .then(() => {
        this.createSnack("Successfully deleted queue user", "success", 2000);
        this.getQueueData();
      })
      .catch(() => {
        this.createSnack("There was an error", "error", 2000);
      });
  }

  handleEdit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let filterOn = "NO";
    if (this.state.editCheck) {
      filterOn = "YES";
    }
    const fetchData = {
      edit: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queueUser: this.state.currentEntry,
    };
    fetchData.queueUser.FILTER_ON = filterOn;
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/get-or-update-user-queue`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
      .then((resp) => resp.json())
      .then((info) => {
        this.createSnack("Successfully updated queue user", "success", 2000);
        this.setState({
          currentEntry: null,
          confirmDelete: false,
          isEdit: false,
          isLoading: false,
        });
        this.getQueueData();
      })
      .catch(() => {
        this.createSnack("There was an error", "error", 2000);
      });
  }

  modalTextField(id, label) {
    const { classes } = this.props;
    return (
      <TextField
        id={id}
        label={label}
        fullWidth
        margin="dense"
        InputProps={{
          classes: {
            input: classes.resize,
            root: classes.textFieldRoot,
          },
          startAdornment: (
            <InputAdornment position="start">
              <span />
            </InputAdornment>
          ),
        }}
        autoComplete="off"
      />
    );
  }

  checkAccess = () => {
    const { access } = this.props;
    return {
      view: access.settings_userqueue_view,
      edit: access.settings_userqueue_edit,
      add: access.settings_userqueue_add,
      delete: access.settings_userqueue_delete,
    };
  };

  render() {
    if (!this.state.queueUsers) {
      return (
        <div style={{ height: "100vh", width: "100%" }}>
          <LoadingCircle />
        </div>
      );
    }
    const { classes } = this.props;
    const { clientInfo } = this.props.fetchInitialData.credentials;
    const access = this.checkAccess();
    const tableData = this.makeTableData(this.state.queueUsers, access);
    return (
      <div style={{ width: "100%" }}>
        <div className="approvalSettings">
          {this.state.isNewEntry && (
            <Dialog
              open={this.state.isOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <form onSubmit={this.addQueue}>
                <DialogTitle id="alert-dialog-title">
                  Add Queue User
                </DialogTitle>
                <DialogContent>
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        select
                        id="type"
                        label="Type"
                        value={this.state.queueType}
                        onChange={(event) =>
                          this.setState({ queueType: event.target.value })
                        }
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        margin="dense"
                        autoComplete="off"
                      >
                        <MenuItem key="VENDOR" value="VENDOR">
                          VENDOR
                        </MenuItem>
                        {clientInfo.invoice_license === 1 && (
                          <MenuItem key="INVOICE" value="INVOICE">
                            INVOICE
                          </MenuItem>
                        )}

                        {clientInfo.invoice_license === 1 && (
                          <MenuItem key="CODING" value="CODING">
                            CODING
                          </MenuItem>
                        )}
                        {clientInfo.invoice_license === 1 && (
                          <MenuItem key="PURCHASE" value="PURCHASE">
                            PURCHASE
                          </MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        select
                        id="user"
                        label="User"
                        value={this.state.currentName}
                        onChange={(event) => {
                          this.setState({ currentName: event.target.value });
                        }}
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <span />
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="off"
                        margin="dense"
                      >
                        {this.state.names.map((user) => {
                          return (
                            <MenuItem key={user.username} value={user.uuid}>
                              {user.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {this.modalTextField("level", "Level")}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl>
                        <InputLabel htmlFor="filterLevel">
                          {`Filter ${this.props.fetchInitialData.credentials.clientInfo.filter_value_label}`}
                        </InputLabel>
                        <Select
                          id="filter"
                          style={{ width: "200px" }}
                          inputProps={{
                            name: "Filter Level",
                            id: "filter",
                          }}
                          autoComplete="off"
                        >
                          {this.state.filterValues.map((value) => {
                            return (
                              <MenuItem key={value.value} value={value.value}>
                                {`${value.value} - ${value.description}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div>
                        Filter On:
                        <Checkbox
                          style={{
                            color: this.props.fetchInitialData.credentials
                              .primaryAppColor,
                          }}
                          checked={this.state.isActive}
                          onChange={this.handleToggleChange("isActive")}
                          value="isActive"
                        />
                        <Typography
                          style={{ fontSize: "0.75rem", color: "grey" }}
                          display="block"
                          gutterBottom
                        >
                          Filter On lets you limit the user to see values based
                          on the above filter values in their approval queue
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({ isOpen: false, currentEntry: null })
                    }
                  >
                    {" "}
                    Cancel
                  </Button>

                  <LoadingButton
                    label="Add"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                </DialogActions>
              </form>
            </Dialog>
          )}
          {this.state.isEdit && (
            <Dialog
              open={this.state.isEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <form onSubmit={this.handleEdit}>
                <DialogTitle id="alert-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                  <div style={{ margin: "0.4rem" }}>
                    <span>Type: </span>
                    <span>{this.state.currentEntry.QUEUE_TYPE}</span>
                  </div>
                  <div style={{ margin: "0.4rem" }}>
                    <span>Level: </span>
                    <span>{this.state.currentEntry.LEVEL}</span>
                  </div>
                  <div style={{ margin: "0.4rem" }}>
                    <span>User: </span>
                    <span>{this.state.currentEntry.username}</span>
                  </div>
                  <div style={{ marginLeft: "0.4rem", marginTop: "0.4rem" }}>
                    <span>
                      {`Filter ${this.props.fetchInitialData.credentials.clientInfo.filter_value_label}`}
                      :{" "}
                    </span>
                    <span>{this.state.currentEntry.FILTER_VALUE}</span>
                  </div>

                  <div style={{ marginLeft: "0.4rem", position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        left: "-15px",
                        top: "8px",
                      }}
                    >
                      <Popover
                        content="?"
                        hoverContent="Should Vendor Approvals be limited to the Filter Value"
                      />
                    </span>
                    Filter On:
                    <Checkbox
                      style={{
                        color: this.props.fetchInitialData.credentials
                          .primaryAppColor,
                      }}
                      checked={this.state.editCheck}
                      onChange={this.handleToggleChange("editCheck")}
                      value="editCheck"
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  {!!access.delete && (
                    <Button
                      onClick={() => this.setState({ confirmDelete: true })}
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      this.setState({
                        isEdit: false,
                        currentEntry: null,
                      })
                    }
                  >
                    {" "}
                    Cancel
                  </Button>
                  {!!access.edit && (
                    <LoadingButton
                      label="Submit"
                      isLoading={this.state.isLoading}
                      color="primaryVLButton"
                      buttonType="submit"
                    />
                  )}
                </DialogActions>
              </form>
            </Dialog>
          )}
          {this.state.confirmDelete && (
            <Dialog
              open={this.state.confirmDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this queue user?
              </DialogContent>
              <DialogActions>
                <form onSubmit={this.handleDelete}>
                  <Button
                    onClick={() => this.setState({ confirmDelete: false })}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <LoadingButton
                    label="Confirm"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                </form>
              </DialogActions>
            </Dialog>
          )}

          <div className="rejectContainer" style={{ paddingBottom: 50 }}>
            <MUIDataTable
              title={"Users Queue"}
              data={tableData}
              columns={[
                {
                  name: "#",
                  label: "#",
                  options: {
                    filter: false,
                    sort: true,
                  },
                },
                {
                  name: "type",
                  label: "Type",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "level",
                  label: "Level",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "user",
                  label: "User",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "filterValue",
                  label: this.props.fetchInitialData.credentials.clientInfo
                    .filter_value_label,
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "filterOn",
                  label: "Filter On",
                  options: {
                    filter: true,
                    sort: true,
                  },
                },
                {
                  name: "edit",
                  label: "Edit",
                  options: {
                    filter: false,
                    sort: false,
                  },
                },
              ]}
              options={{
                selectableRows: false,
                filterType: "dropdown",
                responsive,
                print: false,
              }}
            />
            <div>
              {!!access.add && (
                <Button
                  style={{
                    marginTop: "1rem",
                  }}
                  className="primaryVLButton"
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      isNewEntry: true,
                      currentEntry: {
                        ACTIVE: 1,
                        CLIENT_ID: this.props.fetchInitialData.credentials
                          .clientInfo.client_id,
                      },
                    })
                  }
                >
                  + Add a Queued User
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: "0 solid #e8e8e8",
  },
  tabsIndicator: {
    color: "rgb(232,102,19)",
    backgroundColor: "rgb(232,102,19)",
  },
  tabRoot: {
    textTransform: "initial",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      color: "rgb(232,102,19)",
      opacity: 1,
    },
  },
});

AdminUserQueue.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(AdminUserQueue));
