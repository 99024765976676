import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import writeToActivity from '../functions/WriteToActivity';
import LambdaFetch from '../functions/FetchFromLambda';
import FieldChangeActivity from '../functions/FieldChangeActivity';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

class ViewVendorLegal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      taxId: '',
      vbankAcctNo: '',
      vbankIdent: '',
      vbankAcctTp: '',
      bankEntity: '',
      editTaxId: '',
      editVbankAcctNo: '',
      editVbankIdent: '',
      editVbankAcctTp: '',
      editBankEntity: '',
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    const body = JSON.stringify({
      jsonWebTok: this.props.jsonWebTok,
      vendorId: this.props.vendorId,
    });
    const info = await LambdaFetch(
      'vendor-legal',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      body
    );
    if(info.success) {
      let data = info.data.legal[0];
      this.setState({
        taxId: data.TAX_ID ? data.TAX_ID : '',
        vbankAcctNo: data.VBANK_ACCT_NO ? data.VBANK_ACCT_NO : '',
        vbankIdent: data.VBANK_IDENT ? data.VBANK_IDENT : '',
        vbankAcctTp: data.VBANK_ACCT_TP ? data.VBANK_ACCT_TP : '',
        bankEntity: data.BANK_ENTITY ? data.BANK_ENTITY : '',
        isLoading: false,
      });
    }
    
  };
  dropDownChange(name, value) {
    this.setState({
      [name]: value,
    });
  }
  handleSubmitLegal = async (type) => {
    this.setState({ isSaving: true });
    const { taxId, vbankAcctNo, vbankAcctTp, bankEntity } = this.state;
    let fetchData = {
      update: true,
      jsonWebTok: this.props.jsonWebTok,
      vendorId: this.props.vendorId,
      taxId,
      vbankAcctNo,
      vbankAcctTp,
      bankEntity,
    };
    let editValue = '';
    if (type === 'taxId') {
      if(this.state.editTaxId.length < 9) {
        this.props.fetchInitialData.createSnack('Tax id length must be at least 9 characters', 'warning', 3000);
        this.setState({ isSaving: false});
        return null;
      }

      fetchData.taxId = this.state.editTaxId;
      editValue = this.state.editTaxId;
    } else if (type === 'vbankAcctNo') {
      fetchData.vbankAcctNo = this.state.editVbankAcctNo;
      editValue = this.state.editVbankAcctNo;
    } else if (type === 'vBankAcctTp') {
      fetchData.vbankAcctTp = this.state.editVbankAcctTp;
      editValue = this.state.editVbankAcctTp;
    } else if (type === 'bankEntity') {
      fetchData.bankEntity = this.state.editBankEntity;
      editValue = this.state.editBankEntity;
    }

    const info = await LambdaFetch(
      'vendor-legal',
      'post',
      this.props.jsonWebTok,
      JSON.stringify(fetchData),
      'Changes have been saved'
    );

    if (info.success) {

      if(info.data.changes) {


      const activity = {
        CLIENT_ID: this.props.clientId,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.props.vendorId,
        ACTIVITY_TYPE: 'UPDATE',
        ACTIVITY_STRING: `${this.props.user.name} updated legal information`,
        USER_ID: this.props.user.uuid,
        field_changes: info.data.changes.field_changes
      };

      FieldChangeActivity({ activity }, () => {
        this.setState({ isSaving: false, editItem: null, [type]: editValue });
      });

    } else {
      const activity = {
        CLIENT_ID: this.props.clientId,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.props.vendorId,
        ACTIVITY_TYPE: 'UPDATE',
        ACTIVITY_STRING: `${this.props.user.name} updated legal information`,
        USER_ID: this.props.user.uuid,
      };
      writeToActivity({ activity }, () => {
        this.setState({ isSaving: false, editItem: null, [type]: editValue });
      });
    }


    }
  };
  saveButton = (type) => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        id="save"
        disabled={this.state.isSaving}
        className={this.state.isSaving ? '' : 'primaryVLButton'}
        onClick={() => this.handleSubmitLegal(type)}
        color="primary"
        style={{
          marginRight: '0.5rem',
        }}
      >
        {this.state.isSaving ? (
          <div>
            <CircularProgress size={24} className={classes.buttonProgress} />
            Save{' '}
          </div>
        ) : (
          'Save'
        )}
      </Button>
    );
  };
  handleChange = (type, event) => {
    this.setState({ [type]: event.target.value });
  };
  render() {
    if (this.state.isLoading) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          fullScreen
          open={true}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar style={{ backgroundColor: '#545454', position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.props.handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Legal Information</Typography>
            </Toolbar>
          </AppBar>
          <div className="formVendor">
            <form onSubmit={this.submit}>
              <Grid
                container
                justify="flex-start"
                style={{
                  minWidth: '300px',
                  maxWidth: '600px',
                  paddingTop: '2rem',
                }}
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h5>Banking Information</h5>

                  {this.state.editItem === 'bankEntity' ? (
                    <div>
                      <TextField
                        id="bankEntity"
                        style={{ flexGrow: 4 }}
                        fullWidth
                        className="textfield"
                        variant="filled"
                        margin="dense"
                        label="Routing Number"
                        value={this.state.editBankEntity}
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        onChange={(e) => this.handleChange('editBankEntity', e)}
                        autoComplete="off"
                      />
                      <div>
                        <Button
                          style={{ marginRight: '0.5rem' }}
                          onClick={() => this.setState({ editItem: null })}
                        >
                          Cancel
                        </Button>
                        {this.saveButton('bankEntity')}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        id="bankEntity"
                        style={{ flexGrow: 4 }}
                        fullWidth
                        disabled
                        className="textfield"
                        variant="filled"
                        margin="dense"
                        label="Routing Number"
                        value={this.state.bankEntity.replace(/.(?=.{4})/g, 'x')}
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        autoComplete="off"
                      />
                      {!!this.props.access.vendor_legal_edit && (
                        <div
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() =>
                            this.setState({
                              editItem: 'bankEntity',
                              editBankEntity: this.state.bankEntity,
                            })
                          }
                          className="editLink"
                        >
                          edit
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.editItem === 'vbankAcctNo' ? (
                    <div>
                      <TextField
                        id="vbankAcctNo"
                        fullWidth
                        className="textfield"
                        variant="filled"
                        label="Bank Account Number"
                        value={this.state.editVbankAcctNo}
                        margin="dense"
                        onChange={(e) =>
                          this.handleChange('editVbankAcctNo', e)
                        }
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        autoComplete="off"
                      />
                      <div>
                        <Button
                          style={{ marginRight: '0.5rem' }}
                          onClick={() => this.setState({ editItem: null })}
                        >
                          Cancel
                        </Button>
                        {this.saveButton('vbankAcctNo')}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        id="vbankAcctNo"
                        fullWidth
                        disabled
                        className="textfield"
                        variant="filled"
                        label="Bank Account Number"
                        value={this.state.vbankAcctNo.replace(
                          /.(?=.{4})/g,
                          'x'
                        )}
                        margin="dense"
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        autoComplete="off"
                      />
                      {!!this.props.access.vendor_legal_edit && (
                        <div
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() =>
                            this.setState({
                              editItem: 'vbankAcctNo',
                              editVbankAcctNo: this.state.vbankAcctNo,
                            })
                          }
                          className="editLink"
                        >
                          edit
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.editItem === 'vBankAcctTp' ? (
                    <div>
                      <TextField
                        select
                        className="textfield"
                        id="editvBankAcctTp"
                        label="Account Type"
                        value={this.state.editVbankAcctTp}
                        onChange={(event) =>
                          this.setState({ editVbankAcctTp: event.target.value })
                        }
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        margin="dense"
                        variant="filled"
                      >
                        <MenuItem key="N/A" value="N/A">
                          N/A
                        </MenuItem>
                        <MenuItem key="Checking" value="Checking">
                          Checking
                        </MenuItem>
                        <MenuItem key="Savings" value="Savings">
                          Savings
                        </MenuItem>
                        <MenuItem key="MoneyMarket" value="MoneyMarket">
                          Money Market
                        </MenuItem>
                      </TextField>
                      <div>
                        <Button
                          style={{ marginRight: '0.5rem' }}
                          onClick={() => this.setState({ editItem: null })}
                        >
                          Cancel
                        </Button>
                        {this.saveButton('vBankAcctTp')}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        select
                        disabled
                        className="textfield"
                        id="vBankAcctTp"
                        label="Account Type"
                        defaultValue={this.state.vbankAcctTp}
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                        margin="dense"
                        variant="filled"
                      >
                        <MenuItem key="N/A" value="N/A">
                          N/A
                        </MenuItem>
                        <MenuItem key="Checking" value="Checking">
                          Checking
                        </MenuItem>
                        <MenuItem key="Savings" value="Savings">
                          Savings
                        </MenuItem>
                        <MenuItem key="MoneyMarket" value="MoneyMarket">
                          Money Market
                        </MenuItem>
                      </TextField>
                      {!!this.props.access.vendor_legal_edit && (
                        <div
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() =>
                            this.setState({
                              editItem: 'vBankAcctTp',
                              editVbankAcctTp: this.state.vbankAcctTp,
                            })
                          }
                          className="editLink"
                        >
                          edit
                        </div>
                      )}
                    </div>
                  )}

                  <Grid
                    style={{ marginTop: '1rem' }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <h5>Tax Information</h5>

                    {this.state.editItem === 'taxId' ? (
                      <div>
                        <TextField
                          id="taxId"
                          className="textfield"
                          fullWidth
                          variant="filled"
                          margin="dense"
                          value={this.state.editTaxId}
                          label="EIN / SSN"
                          onChange={(e) => this.handleChange('editTaxId', e)}
                          InputProps={{
                            classes: {
                              input: classes.resize,
                              root: classes.textFieldRoot,
                            },
                          }}
                          inputProps={{ maxLength : 11, minLength: 9 }}
                          autoComplete="off"
                        />
                        <div>
                          <Button
                            style={{ marginRight: '0.5rem' }}
                            onClick={() => this.setState({ editItem: null })}
                          >
                            Cancel
                          </Button>
                          {this.saveButton('taxId')}
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          id="taxId"
                          className="textfield"
                          fullWidth
                          disabled
                          variant="filled"
                          margin="dense"
                          value={this.state.taxId.replace(/.(?=.{4})/g, 'x')}
                          label="EIN / SSN"
                          InputProps={{
                            classes: {
                              input: classes.resize,
                              root: classes.textFieldRoot,
                            },
                          }}
                          inputProps={{ maxLength : 11, minLength: 9 }}
                          autoComplete="off"
                        />
                        {!!this.props.access.vendor_legal_edit && (
                          <div
                            style={{ marginLeft: '0.5rem' }}
                            onClick={() =>
                              this.setState({
                                editItem: 'taxId',
                                editTaxId: this.state.taxId,
                              })
                            }
                            className="editLink"
                          >
                            edit
                          </div>
                        )}
                      </div>
                    )}
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    id="save"
                    disabled={this.state.isSaving}
                    className={this.state.isSaving ? "" : "primaryVLButton"}
                    onClick={() => this.handleSubmitLegal()}
                    color="primary"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  >
                    {this.state.isSaving ? (
                      <div>
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                        Save{" "}
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Grid> */}
                </Grid>
              </Grid>
            </form>
          </div>
        </Dialog>
      </div>
    );
  }
}

ViewVendorLegal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewVendorLegal);
