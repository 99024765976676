import NumberFormat from "react-number-format";
import React from "react";

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                value: values.value,
              },
            });
          }}
          thousandSeparator
          prefix="$"
          decimalScale={2}
      />
  );
}
