export const tabHashes = [
  '#general',
  '#codes',
  '#users',
  '#roles',
  '#userqueue',
  '#queue',
  '#vendor',
  '#contract'
];

export const settingsActions = [
  'settings_general_tab',
  'settings_codes_tab',
  'settings_user_tab',
  'settings_userqueue_tab',
  'settings_vendor_tab',
  'settings_role_tab',
  'settings_queue_tab',
  'settings_contract_tab',
  'settings_general_edit',
  'settings_general_view',
  'settings_codes_add',
  'settings_codes_view',
  'settings_codes_edit',
  'settings_codes_delete',
  'settings_user_add',
  'settings_user_edit',
  'settings_user_view',
  'settings_user_delete',
  'settings_user_roleadmin',
  'settings_vendoruser_edit',
  'settings_vendoruser_view',
  'settings_vendoruser_view',
  'settings_vendoruser_add',
  'settings_vendoruser_delete',
  'settings_vendoruser_roleadmin',
  'settings_vendoruser_admin',
  'settings_userqueue_edit',
  'settings_userqueue_view',
  'settings_userqueue_add',
  'settings_userqueue_delete',
  'settings_vendor_edit',
  'settings_vendor_view',
  'settings_vendor_edit',
  'settings_vendor_add',
  'settings_vendor_delete',
  'settings_queue_edit',
  'settings_queue_view',
  'settings_queue_add',
  'settings_queue_delete',
  'settings_contract_edit',
  'settings_contract_view',
  'settings_contract_add',
  'settings_contract_delete',
];

export const settingsAccess = [
  { label: 'General Settings', access: 'settings_general_tab' },
  { label: 'Codes Settings', access: 'settings_codes_tab' },
  { label: 'User Maintenance', access: 'settings_user_tab' },
  { label: 'Role Maintenance', access: 'settings_role_tab' },
  { label: 'User Queue', access: 'settings_userqueue_tab' },
  { label: 'Queue', access: 'settings_queue_tab' },
  { label: 'Vendor Settings', access: 'settings_vendor_tab' },
  { label: 'Contract Settings', access: 'settings_contract_tab' }
];

export const settingsTabs = [
  { label: 'General Settings', access: ['settings_general_tab'] },
  { label: 'Codes Settings', access: ['settings_codes_tab'] },
  { label: 'User and Roles', access: ['settings_user_tab','settings_role_tab' ] },
  { label: 'Approval Settings', access: ['settings_userqueue_tab', 'settings_queue_tab'] },
  { label: 'Invoice Settings', access: ['settings_general_tab'], license: 'invoice_license' },
  { label: 'PO Settings', access: ['settings_general_tab'], license: 'po_license' },
  { label: 'Vendor Settings', access: ['settings_vendor_tab'] },
  { label: 'Contract Settings', access: ['settings_contract_tab'], license: 'contract_license' },
  { label: 'Accounting Settings', access: ['settings_accounting_tab'] },
]

export const adminSettingsTabHashes = [
  {value: 0, hash: '#general'},
  {value: 1, hash: '#codes'},
  {value: 2, hash: '#users'},
  {value: 2, hash: '#userFilters'},
  {value: 2, hash: '#vendorUsers'},
  {value: 2, hash: '#roles'},
  {value: 2, hash: '#loginSettings'},
  {value: 3, hash: '#userQueue'},
  {value: 3, hash: '#queue'},
  {value: 3, hash: '#other'},
  {value: 4, hash: '#invoice'},
  {value: 5, hash: '#pos'},
  {value: 6, hash: '#vendor'},
  {value: 7, hash: '#contract'},
  {value: 8, hash: '#accounting'},
];

export const adminSettingsAccessActions = [
  'super_admin',
  'settings_general_tab',
  'settings_codes_tab',
  'settings_user_tab',
  'settings_userqueue_tab',
  'settings_vendor_tab',
  'settings_role_tab',
  'settings_queue_tab',
  'settings_contract_tab',
  'settings_accounting_tab',
  'settings_general_edit',
  'settings_general_view',
  'settings_codes_add',
  'settings_codes_view',
  'settings_codes_edit',
  'settings_codes_delete',
  'settings_user_add',
  'settings_user_edit',
  'settings_user_view',
  'settings_user_delete',
  'settings_user_roleadmin',
  'settings_vendoruser_edit',
  'settings_vendoruser_view',
  'settings_vendoruser_view',
  'settings_vendoruser_add',
  'settings_vendoruser_delete',
  'settings_vendoruser_roleadmin',
  'settings_vendoruser_admin',
  'settings_userqueue_edit',
  'settings_userqueue_view',
  'settings_userqueue_add',
  'settings_userqueue_delete',
  'settings_vendor_edit',
  'settings_vendor_view',
  'settings_vendor_edit',
  'settings_vendor_add',
  'settings_vendor_delete',
  'settings_queue_edit',
  'settings_queue_view',
  'settings_queue_add',
  'settings_queue_delete',
  'settings_contract_edit',
  'settings_contract_view',
  'settings_contract_add',
  'settings_contract_delete',
  'task_swap',
  'settings_users_contract_classifications_edit'
]
