import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import './VendorPayment.css';
import { formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

var uuidv1 = require('uuid/v1');
var uuid = require('uuid');

class ClientPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isLoading: false,
      date: '',
      newUnit: 'hourly',
      poNumber: null,
      contract: null,
      department: null,
      description: null,
      ref: null,
      invoiceCurrency: null,
      dueDate: '',
      notes: '',
      newRate: '',
      newQuantity: '',
      newTotal: '',
      invoiceSubTotal: '',
      confirm: false,
      fetchString: null,
      rows: [],
      uploadFiles: [],
      filterValue: '',
      editOpen: false,
      editNum: null,
      editUnit: 'hourly',
    };
    this.addToInvoice = this.addToInvoice.bind(this);
    this.submitInvoice = this.submitInvoice.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.inputFileChange = this.inputFileChange.bind(this);
    this.updateRows = this.updateRows.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  //   componentDidMount() {
  //     const jsonVendor = JSON.stringify({
  //       jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
  //       vendorId: this.props.fetchInitialData.credentials.user.vendorId,
  //       userId: this.props.fetchInitialData.credentials.user.uuid,
  //       isVendor: true
  //     });
  //     fetch(
  //       "https://dntndq8p6e.execute-api.us-west-2.amazonaws.com/dev/get-vendor-forms",
  //       {
  //         method: "post",
  //         headers: { "content-type": "application/json" },
  //         body: jsonVendor
  //       }
  //     )
  //       .then(resp => resp.json())
  //       .then(data => {
  //         var today = new Date();
  //         var dd = today.getDate();
  //         var mm = today.getMonth() + 1;
  //         var yyyy = today.getFullYear();

  //         if (dd < 10) {
  //           dd = "0" + dd;
  //         }

  //         if (mm < 10) {
  //           mm = "0" + mm;
  //         }
  //         today = mm + "/" + dd + "/" + yyyy;

  //         this.setState({
  //           content: data,
  //           date: today
  //         });
  //       })
  //       .catch(error => console.log(error));
  //   }
  addToInvoice(event) {
    event.preventDefault();
    if (event.target.rate.value === '' || event.target.quantity.value === '') {
      window.alert('must add a rate and quantity');
      return null;
    }
    const newRow = {
      LINE_NUM: this.state.rows.length + 1,
      LINE_DESC: event.target.notes.value,
      LINE_UOM: event.target.unit.value,
      LINE_UNIT_COST: event.target.rate.value,
      LINE_QTY: event.target.quantity.value,
      LINE_TRAN_AMOUNT: event.target.quantity.value * event.target.rate.value,
    };
    var invoiceSub = 0;
    for (var i = 0; i < this.state.rows.length; i++) {
      invoiceSub += this.state.rows[i].LINE_TRAN_AMOUNT;
    }
    invoiceSub += event.target.quantity.value * event.target.rate.value;
    var newTableRows = this.state.rows.map((row) => {
      return Object.assign({}, row);
    });
    newTableRows.push(newRow);
    this.setState({
      rows: newTableRows,
      newRate: '',
      newQuantity: '',
      newTotal: '',
      notes: '',
      invoiceSubTotal: invoiceSub,
    });
  }
  updateFetchData(event) {
    if (this.state.rows.length === 0) {
      window.alert('Form must contain atleast 1 line');
      return null;
    } else {
      var copyOfRows = this.state.rows.map((row) => {
        return Object.assign({}, row, { UUID: uuid() });
      });
      let invoiceId = uuid();
      var fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/invoice-details/${invoiceId}`,
        activity: {
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          OBJ_TYPE: 'INVOICE',
          OBJ_ID: invoiceId,
          ACTIVITY_TYPE: 'CREATED',
          ACTIVITY_STRING: 'Invoice created',
          USER_ID: this.props.fetchInitialData.credentials.user.uuid,
        },
        apcinvoice: {
          INVOICE_ID: invoiceId,
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          VENDOR_ID: this.state.content.master[0].VENDOR_ID,
          REFERENCE_NUM: this.state.ref,
          CURRENCY: this.state.invoiceCurrency,
          INVOICE_DATE: this.state.date,
          PO_NUMBER: this.state.poNumber,
          DEPARTMENT: this.state.department,
          CONTRACT_NUM: this.state.contract,
          DESCRIPTION: this.state.description,
          TRAN_INV_AMOUNT: this.state.invoiceSubTotal,
          DUE_DATE: this.state.dueDate,
          INVOICE_STATUS: 1,
          APPROVAL_STATUS_LEVEL: 1,
          USER_ID: null,
          FILTER_VALUE: this.state.filterValue,
          CODING_COMPLETE: 0,
        },
        apcinvdtl: copyOfRows,
      };
      this.setState({
        fetchString: JSON.stringify(fetchData),
        confirm: true,
      });
    }
  }
  submitInvoice() {
    this.setState({ isLoading: true });
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: this.state.fetchString,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 200) {
          let invoiceId = data.invoice_id;
          for (var i = 0; i < this.state.uploadFiles.length; i++) {
            this.uploadFile(invoiceId, this.state.uploadFiles[i]);
          }
          window.alert('Invoice created');
          this.setState({
            newRate: '',
            newQuantity: '',
            newTotal: '',
            notes: '',
            invoiceSubTotal: '',
            fetchData: null,
            confirm: false,
            rows: [],
            uploadFile: [],
          });
          this.setState({ isLoading: false });
          this.props.history.push('/vendor');
        } else if (data.statusCode === 402) {
          this.setState({ isLoading: false });
          window.alert('Unauthorized user');
        } else {
          this.setState({ isLoading: false });
          window.alert('There was an error');
        }
      });
  }
  uploadFile(invoiceId, fileData) {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      window.alert(fileData.name + ' is too big to upload');
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: 'INVOICE',
        obj_id: invoiceId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        activity: {
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          OBJ_TYPE: 'INVOICE',
          OBJ_ID: invoiceId,
          ACTIVITY_TYPE: 'UPLOAD',
          ACTIVITY_STRING:
            this.props.fetchInitialData.credentials.user.name +
            ' uploaded an attachment to invoice',
          USER_ID: this.props.fetchInitialData.credentials.user.uuid,
        },
      };
      const stringData = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: this.props.fetchInitialData.credentials.user
            .jsonWebTok,
        },
        body: stringData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.errorMessage) {
            window.alert(data.errorMessage);
          }
          return data;
        });
    }
  }
  inputFileChange(e) {
    if (e.target.files[0] === undefined) {
      this.setState({
        uploadFiles: [],
      });
    } else {
      this.setState({ uploadFiles: [] });
      for (var i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
          reader = new FileReader(),
          self = this;
        reader.onload = function(r) {
          var updatedUpload = self.state.uploadFiles.map((file) =>
            Object.assign({}, file)
          );
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size,
          });
          self.setState({
            uploadFiles: updatedUpload,
          });
        };
        reader.readAsDataURL(file);
        this.setState({ value: reader });
      }
    }
  }
  updateRows(event) {
    event.preventDefault();
    const form = event.target;
    const updatedRows = this.state.rows.map((row, index) => {
      if (this.state.editNum === index) {
        return {
          LINE_DESC: form.editDesc.value,
          LINE_NUM: index + 1,
          LINE_QTY: form.editQty.value,
          LINE_UNIT_COST: form.editRate.value,
          LINE_UOM: form.editUnit.value,
          LINE_TRAN_AMOUNT: this.state.editQty * this.state.editRate,
        };
      } else return row;
    });
    var invoiceSub = 0;
    for (var i = 0; i < this.state.rows.length; i++) {
      invoiceSub += updatedRows[i].LINE_TRAN_AMOUNT;
    }

    this.setState({
      rows: updatedRows,
      editOpen: false,
      invoiceSubTotal: invoiceSub,
    });
  }
  handleDelete() {
    const updatedRows = this.state.rows.filter(
      (row, index) => index !== this.state.editNum
    );
    var invoiceSub = 0;
    for (var i = 0; i < updatedRows.length; i++) {
      invoiceSub += updatedRows[i].LINE_TRAN_AMOUNT;
    }

    this.setState({
      rows: updatedRows,
      editOpen: false,
      invoiceSubTotal: invoiceSub,
    });
  }
  handleClose() {
    this.setState({ confirm: false, editOpen: false });
  }

  render() {
    const { classes } = this.props;
    const load = this.state.isLoading ? (
      <Fade in={this.state.isLoading} unmountOnExit>
        <LoadingCircle/>
      </Fade>
    ) : (
      ''
    );
    let total = 0;
    if (this.state.editOpen) {
      total = this.state.editQty * this.state.editRate;
    }
    return (
      <div className="paymentForm">
        <Dialog
          open={this.state.confirm}
          fullWidth={true}
          maxWidth={'lg'}
          TransitionComponent={this.Transition}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Payment Form
          </DialogTitle>
          <DialogContent>
            <Grid container justify="flex-start" spacing={24}>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <span>Invoice Date: </span>
                  <span>{this.state.date}</span>
                </div>
                <div>
                  <span>Payable To: </span>
                  <span></span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <span>PO Number: </span>
                  <span>{this.state.poNumber}</span>
                </div>
                <div>
                  <span>Contract: </span>
                  <span>{this.state.contract}</span>
                </div>
                <div>
                  <span>Department: </span>
                  <span>{this.state.department}</span>
                </div>
                <div>
                  <span>Description: </span>
                  <span>{this.state.description}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <span>Ref#: </span>
                  <span>{this.state.ref}</span>
                </div>
                <div>
                  <span>Invoice Currency: </span>
                  <span>{this.state.invoiceCurrency}</span>
                </div>
                <div>
                  <span>Due Date: </span>
                  <span>{this.state.dueDate}</span>
                </div>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Line</TableCell>
                  <TableCell align="center">Notes</TableCell>
                  <TableCell align="center">Unit</TableCell>
                  <TableCell align="center">Rate</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map((row) => (
                  <TableRow key={row.LINE_NUM}>
                    <TableCell align="center">{row.LINE_NUM}</TableCell>
                    <TableCell align="center">{row.LINE_DESC}</TableCell>
                    <TableCell align="center">{row.LINE_UOM}</TableCell>
                    <TableCell align="center">
                      {formatter.format(row.LINE_UNIT_COST)}
                    </TableCell>
                    <TableCell align="center">{row.LINE_QTY}</TableCell>
                    <TableCell align="center">
                      {formatter.format(row.LINE_TRAN_AMOUNT)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell align="center" colSpan={1}>
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <strong>
                      {formatter.format(this.state.invoiceSubTotal)}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                onClick={this.submitInvoice}
                style={{
                  margin: '0.5rem',
                }}
                className="primaryVLButton"
                variant="contained"
              >
                Confirm
                {load}
              </Button>

              <Button
                onClick={this.handleClose}
                style={{
                  margin: '0.5rem',
                  backgroundColor: '#545454',
                  color: 'white',
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>

        {this.state.editOpen && (
          <Dialog
            open={this.state.editOpen}
            fullWidth={true}
            maxWidth={'lg'}
            TransitionComponent={this.Transition}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.updateRows}>
              <DialogTitle id="alert-dialog-title">Edit</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={24}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="editDesc"
                      label="Description"
                      multiline
                      fullWidth
                      rowsMax="4"
                      defaultValue={
                        this.state.rows[this.state.editNum].LINE_DESC
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      select
                      style={{ marginBottom: '0.2rem' }}
                      label="Unit"
                      id="editUnit"
                      fullWidth
                      value={this.state.editUnit}
                      onChange={(event) =>
                        this.setState({ editUnit: event.target.value })
                      }
                      margin="dense"
                      variant="standard"
                    >
                      <MenuItem key="hourly" value="hourly">
                        hourly
                      </MenuItem>
                      <MenuItem key="daily" value="daily">
                        daily
                      </MenuItem>
                      <MenuItem key="unit" value="unit">
                        unit
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editRate"
                      label="Rate"
                      defaultValue={
                        this.state.rows[this.state.editNum].LINE_UNIT_COST
                      }
                      onChange={(event) =>
                        this.setState({ editRate: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editQty"
                      label="Quantity"
                      defaultValue={
                        this.state.rows[this.state.editNum].LINE_QTY
                      }
                      onChange={(event) =>
                        this.setState({ editQty: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editTotal"
                      label="Total"
                      value={formatter.format(total)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{
                    backgroundColor: '#545454',
                    color: 'white',
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleDelete}
                  style={{
                    backgroundColor: '#545454',
                    color: 'white',
                  }}
                  variant="contained"
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  className="primaryVLButton"
                  variant="contained"
                >
                  Confirm
                  {load}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}

        <h3>Payment Request Form</h3>
        <Grid container justify="flex-start" spacing={24}>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <span>Invoice Date: </span>
              <span>{this.state.date}</span>
            </div>
            <div>
              <span>Payable To: </span>
              <span></span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                PO Number:{' '}
              </span>
              <TextField
                id="poNumber"
                onBlur={(event) =>
                  this.setState({ poNumber: event.target.value })
                }
              />
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                Contract:{' '}
              </span>
              <TextField
                id="contract"
                onBlur={(event) =>
                  this.setState({ contract: event.target.value })
                }
              />
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                Description:{' '}
              </span>
              <TextField
                id="description"
                onBlur={(event) =>
                  this.setState({ description: event.target.value })
                }
              />
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                Ref#:{' '}
              </span>
              <TextField
                id="ref"
                onBlur={(event) => this.setState({ ref: event.target.value })}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                Invoice Currency:{' '}
              </span>
              <TextField
                id="invoiceCurrency"
                onBlur={(event) =>
                  this.setState({ invoiceCurrency: event.target.value })
                }
              />
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                Due Date:{' '}
              </span>
              <TextField
                id="dueDate"
                type="date"
                onChange={(event) =>
                  this.setState({ dueDate: event.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '10px',
                  paddingRight: '0.6rem',
                }}
              >
                {
                  this.props.fetchInitialData.credentials.clientInfo
                    .filter_value_label
                }
                :{' '}
              </span>
              <TextField
                id="filterValue"
                onBlur={(event) =>
                  this.setState({ filterValue: event.target.value })
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={this.addToInvoice}>
              <div style={{ padding: '0.5rem 0' }}>
                <Table
                  style={{ tableLayout: 'auto' }}
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Line</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Unit</TableCell>
                      <TableCell align="center">Rate </TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Total</TableCell>
                      {this.state.rows.length > 0 && (
                        <TableCell align="center">edit</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map((row, index) => (
                      <TableRow key={row.LINE_NUM}>
                        <TableCell align="center" component="th" scope="row">
                          {row.LINE_NUM}
                        </TableCell>
                        <TableCell align="left">{row.LINE_DESC}</TableCell>
                        <TableCell align="center">{row.LINE_UOM}</TableCell>
                        <TableCell align="center">
                          ${row.LINE_UNIT_COST}
                        </TableCell>
                        <TableCell align="center">{row.LINE_QTY}</TableCell>
                        <TableCell align="center">
                          {formatter.format(row.LINE_TRAN_AMOUNT)}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            this.setState({
                              editOpen: true,
                              editNum: index,
                              editUnit: row.LINE_UOM,
                              editRate: row.LINE_UNIT_COST,
                              editQty: row.LINE_QTY,
                            })
                          }
                          align="center"
                          style={{ color: 'blue' }}
                          className="editLink"
                        >
                          edit
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="addInput">
                      <TableCell align="center">
                        <Fab
                          type="submit"
                          className="primaryVLButton"
                          size="small"
                          aria-label="Add"
                        >
                          +
                        </Fab>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          multiline
                          style={{ width: '250px' }}
                          rows="2"
                          id="notes"
                          value={this.state.notes}
                          onChange={(event) =>
                            this.setState({ notes: event.target.value })
                          }
                        />
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          select
                          id="unit"
                          value={this.state.newUnit}
                          onChange={(event) =>
                            this.setState({ newUnit: event.target.value })
                          }
                          fullWidth
                          margin="dense"
                          variant="standard"
                        >
                          <MenuItem key="hourly" value="hourly">
                            hourly
                          </MenuItem>
                          <MenuItem key="daily" value="daily">
                            daily
                          </MenuItem>
                          <MenuItem key="unit" value="unit">
                            unit
                          </MenuItem>
                        </TextField>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          style={{ width: '75px' }}
                          id="rate"
                          value={this.state.newRate}
                          onChange={(event) =>
                            this.setState({ newRate: event.target.value })
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          style={{ width: '75px' }}
                          id="quantity"
                          value={this.state.newQuantity}
                          onChange={(event) =>
                            this.setState({ newQuantity: event.target.value })
                          }
                        />
                      </TableCell>
                      <TableCell colSpan={2}>
                        {formatter.format(
                          this.state.newQuantity * this.state.newRate
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div style={{ float: 'right', padding: '2rem' }}>
                <div>
                  <span>Total: </span>
                  <span>{formatter.format(this.state.invoiceSubTotal)}</span>
                </div>
              </div>
            </form>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Button
              onClick={(event) => this.updateFetchData()}
              style={{
                margin: '0.5rem',
              }}
              className="primaryVLButton"
              variant="contained"
            >
              Submit
              {load}
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <input
              type="file"
              multiple="multiple"
              onChange={this.inputFileChange}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

ClientPaymentForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientPaymentForm);
