import React from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import LoadingButton from "../LoadingButton";

class ApproveDialog extends React.Component {
  render() {
    return (
        <Dialog
            open={this.props.open}
            onClose={this.props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}
        >
          <form onSubmit={this.props.onSubmit}>
            <DialogContent>
              {`Are you sure you want to ${this.props.action} this invoice?`}
              <div style={{ marginTop: '0.5rem' }}>
                {this.props.action === 'deny' && (
                    <TextField
                        id="reason"
                        required
                        label="please provide a reason"
                        fullWidth
                    />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                  variant="outlined"
                  onClick={this.props.onClose}
              >
                Back
              </Button>
              <LoadingButton
                  label="Confirm"
                  isLoading={this.props.isApproveDenying}
                  color="primaryVLButton"
                  variant="contained"
                  buttonType="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
    )
  }
}

export default ApproveDialog;
