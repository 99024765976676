import React, { Fragment } from 'react';
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Link,
  TextField,
  ListItem,
  InputAdornment,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Tab,
  Tabs,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import Comments from '../components/Comments';
import Activity from '../components/Activity';
import LoadingButton from '../components/LoadingButton';
import ConfirmDialog from '../components/ConfirmDialog';
import SuggestionSql from '../components/SuggestionSql';
import AttachAttribTask from '../components/AttachAttribTask';
import VerifyAccess from '../functions/VerifyAccess';
import LambdaFetch from '../functions/FetchFromLambda';
import formatDate from '../functions/FormatDate';
import writeToActivity from '../functions/WriteToActivity';
import { contractDetailsTabs } from '../utils/constants/contactColums';
import ObjectNotifications from '../components/ObjectNotifications';
import { AntTabs, AntTab } from "../components/common/Tabs";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$selected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  editLink: {
    color: 'rgb(0, 104, 178)',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  nonActive: {
    border: 'solid #E86613 2px',
    padding: '1rem',
    borderRadius: 10,
    backgroundColor: '#FF9F61',
    margin: '1rem 1rem 0 1rem',
  },
});

class ContractDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingContent: true,
      isSaving: false,
      contract: null,
      contractAttributes: [],
      contractNotifications: [],
      documents: [],
      activity: [],
      documentTypes: [],
      isEditting: false,
      editContractAmount: null,
      editStartDate: null,
      editEndDate: null,
      tabValue: 0,
      notificationUsers: [],
      contractUsers: [],
      contractType: null,
      contractTypes: [],
      classification1Header: null,
      classification1Values: [],
      editClassification1Value: null,
      location: null,
      locations: [],
      hasInactiveLocation: false,
      updatedContract: null,
      vendorSelect: null,
      access: {},
      whiteList: null,
      allNotificationUsers: [],
    };
  }

  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      [
        'contract_archive',
        'contract_edit',
        'comment_add',
        'comment_view',
        'history_view',
        'view_vendor',
        'vendor_edit',
      ],
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    this.getData();
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  viewHash = () => {
    switch (window.location.hash) {
      case '#attachments':
        this.setState({ tabValue: 1 });
        break;
      case '#attributes':
        this.setState({ tabValue: 4 });
        break;
      case '#notifications':
        this.setState({ tabValue: 5 });
        break;
      default:
        this.setState({ tabValue: 0 });
        break;
    }
  };
  getData = async () => {
    try {
    const data = await Promise.all([
      LambdaFetch(
        'get-contract-details',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          contract_id: window.location.pathname.split('contract-details/')[1],
          parentType: 'contract',
        })
      ),
      LambdaFetch(
        'get-activity',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          objectType: 'CONTRACT',
          objectId: window.location.pathname.split('contract-details/')[1],
        })
      ),
      LambdaFetch(
        'get-all-users',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        null
      ),
      LambdaFetch(
        'contract-settings',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: 'vendor',
        })
      ),
    ]);

    if(data.includes(false)) {
      throw new Error()
    }
    const contractData = data[0];
    const activityData = data[1].data ? data[1].data : [];
    const users = data[2].data;
    const filteredUsers = users.users.filter((user) => {
      return !user.vendor_id;
    });

    const defaultContractType = contractData.data.contractTypes.filter(
      (type) =>
        type.contract_type_id === contractData.data.contract[0].contract_type_id
    );

    const notificationUsers = filteredUsers.map((u, index) => {
      return { title: u.username, name: u.name, index: index, uuid: u.uuid };
    });

    const allNotificationUsers = users.users.map((u, index) => {
      return {
        title: u.username,
        name: u.name,
        index: index,
        uuid: u.uuid,
        isVendor: !!u.vendor_id,
      };
    });

    this.setState({
      allNotificationUsers,
      isLoadingContent: false,
      notificationTypes: contractData.data.notificationTypes,
      contract: contractData.data.contract[0],
      contractDocuments: contractData.data.documents,
      contractAttributes: contractData.data.attributes,
      contractNotifications: contractData.data.notifications,
      documentTypes: contractData.data.documentTypes,
      activity: activityData.activity ? activityData.activity : [],
      notificationUsers,
      contractUsers: filteredUsers,
      contractType:
        defaultContractType.length > 0 ? defaultContractType[0] : null,
      contractTypes: contractData.data.contractTypes,
      classification1Header:
        contractData.data.classificationType.length > 0
          ? contractData.data.classificationType[0].classification_type_name
          : null,
      classification1Values:
        contractData.data.classification1Values.length > 0
          ? contractData.data.classification1Values
          : [],
      editClassification1Value: contractData.data.contract[0].classification1,
      whiteList: data[3].data.whiteList,
    });
  } catch(e) {
    console.log(e)
  }
  };
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      switch (newValue) {
        case 1:
          window.location.hash = 'attachments';
          break;
        case 4:
          window.location.hash = 'attributes';
          break;
        case 5:
          window.location.hash = 'notifications';
          break;
        case 0:
        default:
          window.location.hash = '';
          break;
      }
    });
  };
  editContract = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });

    const {
      contract,
      editStartDate,
      editEndDate,
      editContractAmount,
      editContractAmountUsed,
      contractType,
      location,
    } = this.state;

    let sDate = new Date(editStartDate);
    let eDate = new Date(editEndDate);

    if (sDate > new Date('3000-01-01')) {
      this.createSnack('Enter a valid start date', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }

    if (eDate.getTime() && eDate > new Date('3000-01-01')) {
      this.createSnack('Enter a valid end date', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }
    if (eDate.getTime() && sDate > eDate) {
      this.createSnack('End date must be after start date', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }
    if (!this.state.vendorSelect) {
      this.createSnack('You must choose a valid vendor', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }
    if (!location) {
      this.createSnack('You must choose a location', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }
    // if (!contractType) {
    //   this.createSnack('You must choose a contract type', 'warning', 3000);
    //   this.setState({ isSaving: false });
    //   return null;
    // }

    const updatedContract = {
      contract_id: contract.contract_id,
      title: event.target.editContractTitle.value,
      contract_number: event.target.editContractNumber.value,
      description: event.target.editDescription.value,
      start_date: editStartDate,
      end_date: editEndDate,
      contract_type_id: contractType ? contractType.contract_type_id : null,
      classification1: this.state.editClassification1Value,
      status: 1,
      contract_amount: editContractAmount,
      amount_used: editContractAmountUsed,
      location_id: location.ADDR_ID,
      vendor_id: this.state.vendorSelect,
    };
    if (this.state.location) {
      if (this.state.location.INACTIVE_FLAG === 1) {
        this.setState({
          hasInactiveLocation: true,
          updatedContract,
          isSaving: false,
        });
        return null;
      } else {
        this.handleEdit(updatedContract);
      }
    } else {
      this.handleEdit(updatedContract);
    }
  };
  handleEdit = async (updatedContract) => {
    this.setState({ isConfirmInactive: true });
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      contract: updatedContract,
    };

    const info = await LambdaFetch(
      'edit-contract',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData),
      'Successfully updated contract'
    );

    let activityString;
    if (info.data.changes) {
      let jsonChanges = JSON.parse(info.data.changes.field_changes);
      activityString = jsonChanges.reduce((string, change, index) => {
        return (
          string +
          `${change.field} from: ${change.before} to:${change.after}${
            index + 1 === jsonChanges.length ? '' : ', '
          }`
        );
      }, `${this.props.fetchInitialData.credentials.user.name} updated contract information `);
    } else {
      activityString = `${this.props.fetchInitialData.credentials.user.name} updated contract information`;
    }

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'CONTRACT',
      OBJ_ID: this.state.contract.contract_id,
      ACTIVITY_TYPE: 'UPDATE',
      ACTIVITY_STRING: activityString,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    writeToActivity({ activity }, () => {
      this.setState(
        {
          contract: info.data.contract,
          isSaving: false,
          isEditting: false,
          editContractAmount: null,
          editStartDate: null,
          editEndDate: null,
          isConfirmInactive: false,
          hasInactiveLocation: false,
        },
        () => this.getData()
      );
    });
  };

  archive = (callback, type, id, name, isComplete, reactive) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type,
      reactive: reactive ? reactive : false,
      isComplete: isComplete ? isComplete : false,
      id,
      unarchive: !!reactive,
    };
    fetch(`${process.env.REACT_APP_API}/update-status`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.createSnack(info.error, 'error', 3000);
          return null;
        } else if (info.success) {
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: 'CONTRACT',
            OBJ_ID: this.state.contract.contract_id,
            ACTIVITY_TYPE: 'ARCHIVED',
            ACTIVITY_STRING: `Archived ${type.charAt(0).toUpperCase() +
              type.slice(1)} ${name ? `:${name}` : ''}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          if (type === 'notification') {
            activity.ACTIVITY_STRING = `Completed Task: ${name}`;
          }
          writeToActivity({ activity }, (status) => {
            if (status === 200) {
              this.setState({ isEditting: false }, () => {
                this.props.fetchInitialData.createSnack(
                  `Successfully ${
                    reactive ? 'reactivated' : 'archived'
                  } contract`,
                  'success',
                  2000
                );
                this.getData();
              });
              callback(200);
            } else {
              this.props.fetchInitialData.createSnack(
                `There was a error trying to ${
                  reactive ? 'reactivate' : 'archive'
                } contract`,
                'error',
                2000
              );
              callback(400);
            }
          });
        }
      });
  };

  getLocations(id) {
    fetch(`${process.env.REACT_APP_API}/get-locations`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ vendorId: id }),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.createSnack(info.error, 'error', 3000);
          return null;
        } else if (info.success) {
          let currLoc = null;
          let locations = info.data.locations.map((l) => {
            if (l.ADDR_ID === this.state.contract.location_id) {
              currLoc = Object.assign(
                {},
                l,
                l.INACTIVE_FLAG === 1
                  ? { NAME: `${l.NAME} (Inactive)`, ONLY_NAME: l.NAME }
                  : { ONLY_NAME: l.NAME },
                l.PRIMARY_ADDR === 1
                  ? { NAME: `${l.NAME} (Primary)`, ONLY_NAME: l.NAME }
                  : { ONLY_NAME: l.NAME }
              );
            }
            return Object.assign(
              {},
              l,
              l.INACTIVE_FLAG === 1
                ? { NAME: `${l.NAME} (Inactive)`, ONLY_NAME: l.NAME }
                : { ONLY_NAME: l.NAME },
              l.PRIMARY_ADDR === 1
                ? { NAME: `${l.NAME} (Primary)`, ONLY_NAME: l.NAME }
                : { ONLY_NAME: l.NAME }
            );
          });
          this.setState({ locations, location: currLoc });
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error getting vendor locations',
            'error',
            3000
          );
        }
      });
  }

  render() {
    if (this.state.isLoadingContent) {
      return (
        <LoadingCircle/>
      );
    }
    const { contract } = this.state;

    let editDaysLeft = Math.floor(
      (new Date(contract.end_date).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
    );
    if (!new Date(this.state.editEndDate).getTime()) {
      editDaysLeft = 'N/A';
    }

    let eD = contract.end_date;
    let daysLeft = Math.floor(
      (new Date(contract.end_date).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
    );
    if (!new Date(eD).getTime()) {
      eD = 'N/A';
      daysLeft = 'N/A';
    } else {
      eD = formatDate(eD, 'yyyy-MM-dd');
    }

    const remainingDays =
      daysLeft !== 'N/A' ? (daysLeft > 0 ? daysLeft : 0) : 'N/A';

    let currentClassifcation = this.state.classification1Values.find(
      (c) => c.classification_id === contract.classification1
    );
    const { credentials } = this.props.fetchInitialData;
    const { access } = this.state;
    const { classes } = this.props;
    return (
      <>
        <div style={{ backgroundColor: 'white', width: '100%', zIndex: 200 }}>



        <AntTabs
            color={this.props.fetchInitialData.credentials.primaryAppColor}
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {contractDetailsTabs.map((el) => (
              <AntTab
                key={el.value}
                color={this.props.fetchInitialData.credentials.primaryAppColor}
                label={el.label}
                value={el.value}
              />
            ))}
          </AntTabs>
        </div>
        <div className={classes.paymentForm}>
          <div style={{ marginBottom: '1rem' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                href={credentials.isVendor ? '/vcontracts' : '/contracts#all'}
              >
                Contracts
              </Link>
              <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '300px'}}> 
              <Typography noWrap color="textPrimary">{contract.title}</Typography>
              </div>
            </Breadcrumbs>
          </div>

          {this.state.hasInactiveLocation && (
            <Dialog
              open={this.state.hasInactiveLocation}
              fullWidth={true}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Location Inactive
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`Location ${this.state.location.ONLY_NAME} is currently inactive, are you sure you want to select it?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ hasInactiveLocation: false })}
                  style={{ margin: '0.3rem' }}
                >
                  No
                </Button>
                <LoadingButton
                  label="Yes"
                  color="primaryVLButton"
                  isLoading={this.state.isConfirmInactive}
                  fn={() => this.handleEdit(this.state.updatedContract)}
                />
              </DialogActions>
            </Dialog>
          )}

          <Grid container spacing={2}>
            {this.state.tabValue === 0 && (
              <>
                {!this.state.isEditting ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Card style={{ position: 'relative' }} elevation={2}>
                      <div style={{ backgroundColor: 'lightgrey', brightness: "100%" }}>
                        <div style={{ padding: '8px 16px 0px 16px' }}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography variant="subtitle2">{`Contract Number: ${contract.contract_number}`}</Typography>
                          </div>
                          <Grid container spacing={1}>
                            <Grid />
                            <Grid item xs={9} sm={9} md={9} lg={10}>
                              <Typography variant="h6" component="p">
                                Vendor:{' '}
                                {!!(
                                  access.vendor_view || access.vendor_edit
                                ) ? (
                                  <span
                                    className={classes.editLink}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/viewvendordetails/${this.state.contract.vendor_id}`
                                      )
                                    }
                                  >
                                    {contract.vendor_name}
                                  </span>
                                ) : (
                                  contract.vendor_name
                                )}
                              </Typography>

                              <Typography variant="h6" component="p">
                                {contract.title}
                              </Typography>
                            </Grid>

                            <Grid item xs={3} sm={3} md={3} lg={2}>
                              <div style={{ float: 'right' }}>
                                <Typography variant="subtitle1">
                                  {formatDate(
                                    contract.start_date.split('T')[0]
                                  )}{' '}
                                  - {eD}
                                </Typography>
                                <Typography variant="subtitle2" component="p">
                                  Days Remaining: {remainingDays}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <div style={{ padding: '16px', position: 'relative' }}>
                        {parseInt(this.state.contract.status) === 2 ? (
                          <div
                            style={{ margin: '2rem auto' }}
                            className={classes.nonActive}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              **This contract is archived.
                              <Button
                                onClick={() =>
                                  this.archive(
                                    (status) => {
                                      if (status === 200) {
                                        this.setState({ isEditting: false });
                                      }
                                    },
                                    'contract',
                                    this.state.contract.contract_id,
                                    null,
                                    null,
                                    true
                                  )
                                }
                                variant="contained"
                                color="secondary"
                              >
                                Unarchive
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {credentials.isVendor ? null : (
                              <Button
                                style={{
                                  position: 'absolute',
                                  top: '10px',
                                  right: '10px',
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      isEditting: true,
                                      editContractAmount:
                                        contract.contract_amount,
                                      editContractAmountUsed:
                                        contract.amount_used,
                                      editStartDate: contract.start_date.split(
                                        'T'
                                      )[0],
                                      editEndDate: contract.end_date.split(
                                        'T'
                                      )[0],
                                      location: {
                                        NAME: contract.location_name,
                                        ADDR_ID: contract.ADDR_ID,
                                      },
                                      vendorSelect: contract.vendor_id,
                                    },
                                    () => this.getLocations(contract.vendor_id)
                                  )
                                }
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        )}

                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h6" component="p">
                              {`Type: ${
                                this.state.contract.contract_type
                                  ? this.state.contract.contract_type
                                  : ' N/A'
                              }`}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h6" component="p">
                              {`Location: ${
                                this.state.contract.location_name
                                  ? this.state.contract.location_name
                                  : ' N/A'
                              } ${
                                this.state.contract.inactive_flag === 1
                                  ? '(Inactive)'
                                  : ''
                              }
                                  ${
                                    this.state.contract.primary_addr === 1
                                      ? '(Primary)'
                                      : ''
                                  }`}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={12} lg={12}>
                            {contract.classification1 ? (
                              <Typography variant="h6" component="p">
                                {`${this.state.classification1Header}: ${
                                  currentClassifcation
                                    ? currentClassifcation.classification_value
                                    : 'N/A'
                                }`}
                              </Typography>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={5} lg={4}>
                            <div style={{ margin: 'auto' }}>
                              <Typography variant="h6" component="p">
                                {'Contract Amount: '}

                                <NumberFormat
                                  value={contract.contract_amount}
                                  displayType={'text'}
                                  thousandSeparator
                                  prefix="$"
                                />
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3} lg={2}>
                            <div style={{ marginTop: '0.2rem' }}>
                              <Typography variant="subtitle1" component="p">
                                {'Used: '}

                                <NumberFormat
                                  value={
                                    contract.amount_used
                                      ? contract.amount_used
                                      : 0
                                  }
                                  displayType={'text'}
                                  thousandSeparator
                                  prefix="$"
                                />
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3} lg={2}>
                            <div style={{ marginTop: '0.2rem' }}>
                              <Typography variant="subtitle1" component="p">
                                {'Remaining: '}

                                <NumberFormat
                                  value={
                                    Math.round(
                                      (contract.contract_amount -
                                        contract.amount_used) *
                                        100
                                    ) / 100
                                  }
                                  displayType={'text'}
                                  thousandSeparator
                                  prefix="$"
                                />
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>

                        <Typography variant="body2" component="p">
                          {contract.description}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                ) : (
                  this.state.isEditting && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Card style={{ position: 'relative' }}>
                        <CardContent>
                          <h5 style={{ margin: '1rem' }}>Edit Contract</h5>
                          <Fragment>
                            <form
                              onSubmit={(event) => this.editContract(event)}
                            >
                              <ListItem dense>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <SuggestionSql
                                      id="vendor"
                                      handleChange={(
                                        id,
                                        newVendorName,
                                        isSelected
                                      ) => {
                                        if (isSelected) {
                                          this.getLocations(id);
                                          this.setState({ vendorSelect: id });
                                        } else {
                                          this.setState({ vendorSelect: null });
                                        }
                                      }}
                                      placeholder=""
                                      label="Vendor"
                                      required
                                      defaultValue={contract.vendor_name}
                                      variant="outlined"
                                      fullWidth
                                      clientId={
                                        this.props.fetchInitialData.credentials
                                          .user.clientId
                                      }
                                      jsonWebTok={
                                        this.props.fetchInitialData.credentials
                                          .user.jsonWebTok
                                      }
                                    />

                                    <TextField
                                      fullWidth
                                      multiline
                                      label="Title"
                                      required
                                      margin="dense"
                                      id="editContractTitle"
                                      variant="outlined"
                                      defaultValue={contract.title}
                                    />
                                    <TextField
                                      fullWidth
                                      multiline
                                      required
                                      label="Contract Number"
                                      margin="dense"
                                      id="editContractNumber"
                                      variant="outlined"
                                      defaultValue={contract.contract_number}
                                    />

                                    <Autocomplete
                                      value={this.state.contractType}
                                      onChange={(event, newValue) => {
                                        this.setState({
                                          contractType: newValue,
                                        });
                                      }}
                                      id="location"
                                      required
                                      options={this.state.contractTypes}
                                      getOptionLabel={(option) =>
                                        option.description
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          id="textLocations"
                                          {...params}
                                          margin="dense"
                                          label="Type"
                                          variant="outlined"
                                        />
                                      )}
                                    />

                                    <Autocomplete
                                      value={this.state.location}
                                      onChange={(event, newValue) => {
                                        this.setState({ location: newValue });
                                      }}
                                      id="location"
                                      required
                                      options={this.state.locations}
                                      getOptionLabel={(option) =>
                                        option.NAME
                                          ? option.NAME
                                          : 'Name not available'
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          id="textLocations"
                                          {...params}
                                          margin="dense"
                                          label="Location"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      style={{ marginRight: '10px' }}
                                      type="date"
                                      label="Start Date"
                                      margin="dense"
                                      id="editStartDate"
                                      variant="outlined"
                                      value={this.state.editStartDate}
                                      onChange={this.handleChange(
                                        'editStartDate'
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                    <TextField
                                      type="date"
                                      label="End Date"
                                      margin="dense"
                                      id="editEndDate"
                                      variant="outlined"
                                      value={this.state.editEndDate}
                                      onChange={this.handleChange(
                                        'editEndDate'
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    {this.state.classification1Header && (
                                      <Autocomplete
                                        size="small"
                                        options={
                                          this.state.classification1Values
                                        }
                                        onChange={(event, values) =>
                                          this.setState({
                                            editClassification1Value: values
                                              ? values.classification_id
                                              : '',
                                          })
                                        }
                                        defaultValue={
                                          this.state.classification1Values.filter(
                                            (c) =>
                                              c.classification_id ===
                                              contract.classification1
                                          )[0]
                                        }
                                        getOptionLabel={(option) =>
                                          option.classification_value
                                        }
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            id="classificationType"
                                            onKeyPress={(e) => {
                                              if (e.key === 'Enter')
                                                e.preventDefault();
                                            }}
                                            label={
                                              this.state.classification1Header
                                            }
                                            fullWidth
                                            autoComplete="off"
                                          />
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                      style={{ marginRight: '0.5rem' }}
                                      label="Contract Amount"
                                      variant="outlined"
                                      margin="dense"
                                      value={
                                        this.state.editContractAmount
                                          ? Math.round(
                                              this.state.editContractAmount *
                                                100
                                            ) / 100
                                          : ''
                                      }
                                      onChange={this.handleChange(
                                        'editContractAmount'
                                      )}
                                      id="editContractAmount"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <span />
                                          </InputAdornment>
                                        ),
                                      }}
                                      autoComplete="off"
                                    />

                                    <TextField
                                      style={{ marginRight: '0.5rem' }}
                                      label="Amount Used"
                                      variant="outlined"
                                      margin="dense"
                                      value={
                                        this.state.editContractAmountUsed
                                          ? Math.round(
                                              this.state
                                                .editContractAmountUsed * 100
                                            ) / 100
                                          : ''
                                      }
                                      onChange={this.handleChange(
                                        'editContractAmountUsed'
                                      )}
                                      id="editContractAmountUsed"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <span />
                                          </InputAdornment>
                                        ),
                                      }}
                                      autoComplete="off"
                                    />

                                    <TextField
                                      style={{ marginRight: '0.5rem' }}
                                      label="Remaining"
                                      variant="outlined"
                                      margin="dense"
                                      disabled
                                      id="remaining"
                                      value={
                                        Math.round(
                                          (this.state.editContractAmount -
                                            this.state.editContractAmountUsed) *
                                            100
                                        ) / 100
                                      }
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <span />
                                          </InputAdornment>
                                        ),
                                      }}
                                      autoComplete="off"
                                    />

                                    <TextField
                                      style={{ marginRight: '0.5rem' }}
                                      label="Days Remaining"
                                      variant="outlined"
                                      margin="dense"
                                      disabled
                                      id="daysRemaining"
                                      value={editDaysLeft}
                                      autoComplete="off"
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                      label="Description"
                                      fullWidth
                                      multiline
                                      margin="dense"
                                      id="editDescription"
                                      variant="outlined"
                                      defaultValue={contract.description}
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>

                              <div style={{ float: 'right' }}>
                                <div style={{ margin: '1rem' }}>
                                  {!!access.contract_archive && (
                                    <ConfirmDialog
                                      handleConfirm={() =>
                                        this.archive(
                                          (status) => {
                                            if (status === 200) {
                                              this.setState({
                                                isEditting: false,
                                              });
                                            }
                                          },
                                          'contract',
                                          this.state.contract.contract_id
                                        )
                                      }
                                      type="contract"
                                      title="Archive Contract"
                                      dialogTitle="Confirm Archive"
                                      dialogText="Are you sure you want to archive this Contract?"
                                      color="red"
                                    />
                                  )}
                                  <Button
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={() =>
                                      this.setState({
                                        isEditting: false,
                                        editContractAmount: null,
                                        editStartDate: null,
                                        editEndDate: null,
                                      })
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  {!!access.contract_edit && (
                                    <LoadingButton
                                      label="Save"
                                      isLoading={this.state.isSaving}
                                      color="primaryVLButton"
                                      buttonType="submit"
                                    />
                                  )}
                                </div>
                              </div>
                            </form>
                          </Fragment>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                )}
              </>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <AttachAttribTask
                props={this.props}
                tab={this.state.tabValue}
                getData={this.getData}
                jsonWebTok={
                  this.props.fetchInitialData.credentials.user.jsonWebTok
                }
                clientId={
                  this.props.fetchInitialData.credentials.clientInfo.client_id
                }
                currentUser={this.props.fetchInitialData.credentials.user.uuid}
                parentType="contract"
                vendorId={this.state.contract.vendor_id}
                parentId={this.state.contract.contract_id}
                whiteList={this.state.whiteList}
              />

              {this.state.tabValue === 0 && (
                <>
                {!!(access.comment_add || access.comment_view) && (
                <Comments
                  fetchInitialData={this.props.fetchInitialData}
                  objectId={
                    window.location.pathname.split('contract-details/')[1]
                  }
                  objectType="CONTRACT"
                  vendorId={this.state.contract.vendor_id}
                  isVendor={this.props.fetchInitialData.credentials.isVendor}
                />
              )}
              {!!access.history_view && (
                <Activity
                  title="Contract History"
                  activity={this.state.activity}
                  timezone={
                    this.props.fetchInitialData.credentials.user.timezone
                  }
                  appWidth={this.props.fetchInitialData.credentials.appWidth}
                />
              )}
              <ObjectNotifications
                createSnack={this.props.fetchInitialData.createSnack}
                jsonWebTok={
                  this.props.fetchInitialData.credentials.user.jsonWebTok
                }
                parentId={
                  window.location.pathname.split('contract-details/')[1]
                }
                parentType="contract"
                isVendor={
                  !!this.props.fetchInitialData.credentials.isVendor
                }
                vendorId={this.state.contract.vendor_id}
              />
                </>
              )}
              
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbar(ContractDetails));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}
