export const documentColumns = [
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'expirationDate',
    label: 'Expiration Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'uploadedBy',
    label: 'Uploaded By',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'uploadedOn',
    label: 'Uploaded On',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'action',
    label: 'Action',
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const uploadTypes = [
  {
    value: 'c',
    label: 'Local Computer',
  },
  {
    value: 'l',
    label: 'Link',
  },
];
