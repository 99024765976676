import React from "react";
import MUIDataTable from "mui-datatables";
import { Grid, CircularProgress, withStyles, Button } from "@material-ui/core";
import moment from "moment-timezone";
import PrintIcon from "@material-ui/icons/Print";

import PdfTableDialog from "./pdf/PdfTableDialog";
import {responsive} from '../utils/constants/muidatatablesConsts';
import { notificationColumns, attributeColumns } from "../utils/constants/contractsColumns";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = ({
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginLeft: 10,
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
});

class ContractSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      attributes: [],
      notifications: [],
      attributeTableData: [],
      notificationTableData: [],
      openPrintDialog: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(
        `${process.env.REACT_APP_API}/contract-search`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            'Authorization': `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`
          },
          body: JSON.stringify({ jsonWebTok: this.props.props.fetchInitialData.credentials.user.jsonWebTok }),
        }
    )
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            const data = info.data


            this.setState({
              isLoading: false,
              attributes: data.attributes,
              notifications: data.notifications,
              attributeTableData: this.makeContractSearchData(data.attributes),
              notificationTableData: this.makeContractSearchData(data.notifications),
            });
          }
        }).catch(err => {
      this.props.props.fetchInitialData.createSnack(`'Error: ${err}`, 'error', 3000)
    })
  };
  onTagsChange = (event, values, type) => {
    if (type === "currentAttributeHeader") {
      let currentHeader = "";
      if (values) {
        currentHeader = values.title;
      }
      const attributeValuesAuto = this.state.attributes.reduce((filtered, a) => {
        const currentAttributeValue = filtered.find(
            (item) => item.title === a.attribute_value
        );
        if (
            !currentAttributeValue &&
            (!currentHeader || currentHeader === a.attribute_header)
        ) {
          return filtered.concat([{ title: a.attribute_value }]);
        } else {
          return filtered;
        }
      }, []);
      this.setState(
          {
            currentAttributeHeader: currentHeader,
            currentAttributeValue: "",
            attributeValuesAuto,
          },
          () => {
            this.makeTableData("attributes");
          }
      );
    } else if (type === "currentAttributeValue") {
      let currentValue = "";
      if (values) {
        currentValue = values.title;
      }
      this.setState({ currentAttributeValue: currentValue }, () => {
        this.makeTableData("attributes");
      });
    }
  };

  makeContractSearchData = (data) => {
    if(this.props.show === 'attributes') {
     return data.map((a) => {
        const link = (`/${a.parent_type === 'vendor' ? 'viewvendor' : a.parent_type + '-'}details/${a.parent_id}#${this.props.show}`);
        return [
          a.vendor,
          a.parent_name ? a.parent_name : '',
          a.parent_type.toUpperCase(),
          <div
              className="editLink"
              onClick={() => this.props.props.history.push(link)}
          >
            {a.attribute_header}
          </div>,
          a.data_type === "user" ? a.username : a.attribute_value,
          a.delete_flag === 0 ? 'Active' : 'Archived'
        ];
      });
    } else {
      return data.map((n) => {
        const link = (`/${n.parent_type === 'vendor' ? 'viewvendor' : n.parent_type + '-'}details/${n.parent_id}#${this.props.show}`);
        return [
          n.vendor,
          n.parent_description || '',
          n.parent_type.toUpperCase(),
          isNaN(new Date(n.date).getDate())
              ? ""
              : moment(new Date(n.date)).format("MM/DD/YYYY"),
          <div
              className="editLink"
              onClick={() => this.props.props.history.push(link)}
          >
            {n.title}
          </div>,
          n.is_complete === 1 ? 'Completed' : 'Active'
        ];
      });
    }



  }

  makeTableData = (type) => {
    const { currentAttributeHeader, currentAttributeValue } = this.state;
    if (type === "attributes") {
      const attributeTableData = this.state.attributes.reduce((filtered, a) => {
        if (
            (!currentAttributeHeader ||
                currentAttributeHeader === a.attribute_header) &&
            (!currentAttributeValue ||
                currentAttributeValue === a.attribute_value)
        ) {
          return filtered.concat([a]);
        } else {
          return filtered;
        }
      }, []);
      this.setState({ attributeTableData });
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.isLoading) {
      return (
          <LoadingCircle/>
      );
    } else {
      return (
          <Grid
              container
              justify="flex-start"
              spacing={2}
              style={{ margin: "0 auto", width: "95%" }}
          >
            {this.props.show === "attributes" && (
                <Grid item xs={12} md={12} lg={12}>
                  <MUIDataTable
                      title="Attributes"
                      data={this.state.attributeTableData}
                      columns={attributeColumns}
                      options={{
                        selectableRows: false,
                        filterType: "dropdown",
                        responsive,
                        print: false,
                        customSort: (data, colIndex, order) =>
                            data.sort((a, b) => {
                              const firstData = (typeof a.data[colIndex] === 'object'
                                  ? a.data[colIndex].props.children.toLowerCase() : a.data[colIndex].toString().toLowerCase());
                              const secondData = (typeof b.data[colIndex] === 'object'
                                  ? b.data[colIndex].props.children.toLowerCase() : b.data[colIndex].toString().toLowerCase());
                              return (firstData === secondData ? 0 : ((firstData < secondData) ? -1 : 1)) * (order === 'asc' ? 1 : -1)
                            }),
                        customSearch: () => true,
                        onSearchChange: (query) => {
                          this.setState({
                            attributeTableData: this.makeContractSearchData(this.state.attributes.filter((attribute) => {
                              if (query) {
                                return (
                                    attribute.vendor
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    (attribute.parent_name && attribute.parent_name
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim())) ||
                                    attribute.parent_type
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    attribute.attribute_header
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    (attribute.attribute_value && attribute.attribute_value
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim())) ||
                                    (attribute.username && attribute.username
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()))  ||
                                    (attribute.delete_flag === 0 ? 'Active' : 'Archived')
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim())
                                );
                              }
                              return attribute;
                            }))
                          });
                        },
                        onSearchClose: () => {
                          this.setState({
                            attributeTableData: this.makeContractSearchData(this.state.attributes)
                          });
                        },
                      }}
                  />
                </Grid>
            )}
            <PdfTableDialog
                isLarge
                open={this.state.openPrintDialog}
                fileName={this.props.show === "attributes" ? "attributes" : "notifications"}
                columns={this.props.show === "attributes" ? attributeColumns : notificationColumns}
                data={this.props.show === "attributes" ? this.state.attributeTableData : this.state.notificationTableData}
                onClose={() => this.setState({ openPrintDialog: false })}
                title={this.props.show === "attributes" ? "Attributes" : "Tasks"}/>

            {this.props.show === "notifications" && (
                <Grid item xs={12} md={12} lg={12}>
                  <MUIDataTable
                      title="Tasks"
                      data={this.state.notificationTableData}
                      columns={notificationColumns}
                      options={{
                        customSort: (data, colIndex, order) => {
                          let dateCols = notificationColumns.reduce((dateCols, currCol, index) => {
                            if (currCol.name.toLowerCase().indexOf('date') !== -1) {
                              return [...dateCols, index]
                            } else return dateCols
                          }, [])
                          return data.sort((a, b) => {
                            if (dateCols.indexOf(colIndex) !== -1) {
                              return (
                                  (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                                      ? -1
                                      : 1) * (order === "desc" ? 1 : -1)
                              );
                            } else {
                              return (
                                  (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
                                  (order === "desc" ? 1 : -1)
                              );
                            }
                          });
                        },
                        customSearch: () => true,
                        onSearchChange: (query) => {
                          this.setState({
                            notificationTableData: this.makeContractSearchData(this.state.notifications.filter((notification) => {
                              if (query) {
                                return (
                                    notification.vendor
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                   ( notification.parent_description && notification.parent_description
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim())) ||
                                    notification.parent_type
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    notification.title
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim()) ||
                                    (notification.is_complete === 1 ? 'Completed' : 'Active')
                                        .toLowerCase()
                                        .includes(query.toLowerCase().trim())
                                );
                              }
                              return notification;
                            }))
                          });
                        },
                        onSearchClose: () => {
                          this.setState({
                            notificationTableData: this.makeContractSearchData(this.state.notifications)
                          });
                        },
                        selectableRows: false,
                        filterType: "dropdown",
                        responsive,
                        print: false,
                      }}
                  />
                </Grid>
            )}
            <Button
                className="primaryVLButton"
                style={{
                  marginLeft: 10,
                  marginTop: 10
                }}
                onClick={() => this.setState({ openPrintDialog: true })}>
              PDF
              <PrintIcon style={{ marginLeft: 10 }}/>
            </Button>
          </Grid>
      );
    }
  }
}

export default withStyles(styles)(ContractSearch);
