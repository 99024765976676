import React from 'react';
import {
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import VerifyAccess from '../functions/VerifyAccess';
import { responsive } from '../utils/constants/muidatatablesConsts';
import LoadingButton from '../components/LoadingButton';

import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingCircle from "../components/common/LoadingCircle"

class ChangeResponsibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingContent: true,
      isLoading: false,
      access: null,
      users: [],
      tasks: [],
      currentUser: null,
      toUser: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_API}/change-responsibility`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'get-users' }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ users: data.data.users, isLoadingContent: false });
      });
  };
  handleUserChange = (user) => {
    if (!user) {
      this.setState({ tasks: [], currentUser: null });
      return null;
    }
    fetch(`${process.env.REACT_APP_API}/change-responsibility`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'get-tasks', userId: user.uuid }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ tasks: data.data.tasks, currentUser: user });
      });
  };
  handleToUserChange = (user) => {
    this.setState({ toUser: user });
  };
  makeTableData = (data) => {
    return data.map((row) => {
      return [
        row.notification_id,
        row.vendor,
        moment(row.date).format('MM/DD/YYYY'),
        row.title,
        row.description,
      ];
    });
  };
  handleTransfer = () => {
    this.setState({ isLoading: true });
    const fromUserId = this.state.currentUser.uuid;
    const toUserId = this.state.toUser.uuid;

    const updatedTasks = this.state.tasks.map((task) => {
      const notifiees = JSON.parse(task.users_to_notify);
      const updatedNotifiees = notifiees.map((user) => {
        if (user === fromUserId) {
          return toUserId;
        } else {
          return user;
        }
      });
      return Object.assign(
        {},
        { notification_id: task.notification_id },
        { users_to_notify: JSON.stringify(updatedNotifiees) }
      );
    });
    fetch(`${process.env.REACT_APP_API}/change-responsibility`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'swap', tasks: updatedTasks }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if(data.success) {
            this.props.fetchInitialData.createSnack('Successfully transfered tasks', 'success', 3500)
            this.setState({ toUser: null });
            this.handleUserChange(this.state.currentUser)
        } else {
            this.props.fetchInitialData.createSnack('There was an error transfering tasks', 'error', 3500)
        }
      })
      .catch((err) => console.log(err));

    this.setState({ isLoading: false });
  };
  render() {
    if (this.state.isLoadingContent) {
      return (
        <LoadingCircle/>
      );
    }
const cols = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'vendor',
      label: 'Vendor',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      },
    },
  ]
    const tableData = this.makeTableData(this.state.tasks);
    return (
      <div style={{ margin: '1rem', width: '100%' }}>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              size="small"
              required
              options={this.state.users}
              onChange={(event, values) => this.handleUserChange(values)}
              getOptionLabel={(option) => `${option.name} (${option.username})`}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  style={{ marginTop: '16px' }}
                  id="users"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  label="Select From User"
                  autoComplete="off"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {this.state.currentUser && (
              <Autocomplete
                size="small"
                required
                options={this.state.users}
                onChange={(event, values) => this.handleToUserChange(values)}
                getOptionLabel={(option) =>
                  `${option.name} (${option.username})`
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    style={{ marginTop: '16px' }}
                    id="users"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    label="Select To User"
                    autoComplete="off"
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            {this.state.currentUser && (
              <MUIDataTable
                title={'Tasks'}
                data={tableData}
                columns={cols}
                options={{
                  selectableRows: false,
                  filterType: 'dropdown',
                  responsive,
                  print: false,
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            {(this.state.toUser && this.state.currentUser) && (
              <div>
                <LoadingButton
                  label="Transfer"
                  color="primaryVLButton"
                  isLoading={this.state.isLoading}
                  fn={() => this.handleTransfer()}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ChangeResponsibility;
