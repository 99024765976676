import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import PrintIcon from "@material-ui/icons/Print";
import { Button, withStyles } from "@material-ui/core";
import moment from 'moment-timezone';
import LoadingCircle from "../components/common/LoadingCircle"

import { invoiceApproval } from '../utils/constants/invoiceColumns';
import PdfTableDialog from "../components/pdf/PdfTableDialog";
import { customTableSort, formatter } from '../utils/tableCustoms';
import VendorStatus from '../functions/VendorStatus'
import { responsive } from '../utils/constants/muidatatablesConsts';


class InvoiceApprovalHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
      rows: null,
      openPrintDialog: false,
      muiTableData: this.makeTableData(this.props.content),
    };
  }

  componentDidMount() {
    this.setState({
      content: this.props.content,
      muiTableData: this.makeTableData(this.props.content),
    })
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.content !== prevProps.content)) {
      this.setState({
        content: this.props.content,
        muiTableData: this.makeTableData(this.props.content),
      })
    }
  }

  makeTableData = (data) => {
    return data.map((invoice) => {
      return [
        <div className="editLink" onClick={() => this.props.history.push(`/invoice-details/${invoice.INVOICE_ID
        }`)}>{invoice.REFERENCE_NUM}</div>,
        invoice.VENDOR_VNAME,
        new Date(invoice.INVOICE_DATE).getTime() ? moment(new Date(invoice.INVOICE_DATE)).format('MM/DD/YYYY') : 'N/A',
        new Date(invoice.DUE_DATE).getTime() ? moment(new Date(invoice.DUE_DATE)).format('MM/DD/YYYY') : 'N/A',
        formatter.format(invoice.TRAN_INV_AMOUNT),
        VendorStatus(invoice.INVOICE_STATUS),
      ]
    });
  }

  render() {
    const { classes } = this.props;
    return (
        <div className={classes.paymentForm}>
          <MUIDataTable
              title={'Invoice Approvals'}
              data={this.state.muiTableData}
              columns={invoiceApproval}
              options={{
                selectableRows: false,
                filterType: 'dropdown',
                responsive,
                print: false,
                customSort: (data, colIndex, order) => customTableSort(data, colIndex, order, 4),
                customSearch: () => true,
                onSearchChange: (query) => {
                  this.setState({
                    muiTableData: this.makeTableData(this.state.content.filter((invoice) => {
                      if (query) {
                        return (
                            invoice.INVOICE_NUMBER
                                .toString()
                                .includes(query.trim()) ||
                            invoice.VENDOR_VNAME
                                .toLowerCase()
                                .includes(query.toLowerCase().trim()) ||
                            (new Date(invoice.INVOICE_DATE).getTime() ?
                                moment(new Date(invoice.INVOICE_DATE)).format('MM/DD/YYYY') : 'N/A')
                                .toString().toLowerCase()
                                .includes(query.trim())
                            ||
                            (new Date(invoice.DUE_DATE).getTime() ?
                                moment(new Date(invoice.DUE_DATE)).format('MM/DD/YYYY') : 'N/A')
                                .toString().toLowerCase()
                                .includes(query.trim()) ||
                            formatter.format(invoice.TRAN_INV_AMOUNT)
                                .toString()
                                .includes(query.trim()) ||
                            invoice.TRAN_INV_AMOUNT.toString()
                                .includes(query.trim()) ||
                            VendorStatus(invoice.INVOICE_STATUS)
                                .toLowerCase()
                                .includes(query.toLowerCase().trim())
                        );
                      }
                      return invoice;
                    }))
                  });
                },
                onSearchClose: () => {
                  this.setState({
                    muiTableData: this.makeTableData(this.state.content),
                  });
                },
              }}
          />
          <PdfTableDialog
              isLarge={false}
              open={this.state.openPrintDialog}
              fileName={'invoiceApprovals'}
              data={this.state.muiTableData}
              columns={invoiceApproval}
              onClose={() => this.setState({ openPrintDialog: false })}
              title={'Invoice Approvals'}
          />
          <Button
              style={{marginTop: 10}}
              className="primaryVLButton"
              onClick={() => this.setState({ openPrintDialog: true })}>
            PDF
            <PrintIcon style={{ marginLeft: 10 }}/>
          </Button>
        </div>
    );
  }
}

const styles = () => ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
});

InvoiceApprovalHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InvoiceApprovalHome);
