import React from "react"
import '../containers/Admin.css'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'


class AdminTabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            appBarWidth: 1000
        }
    }
    componentDidMount() {
        this.calcSize()
        window.addEventListener("resize", this.calcSize.bind(this))
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.calcSize.bind(this));
    }
    calcSize() {
        this.setState({ appBarWidth: window.innerWidth })
    }
    render() {
        const { classes } = this.props
        const variant = this.state.appBarWidth > 1100 ? "fullWidth" : "scrollable"
        const centerTrue = this.state.appBarWidth > 1100 ? true : false
        return (
            <div style={{height: '55px'}}>
              
                <div style={{borderBottom: '1px solid rgba(0,0,0,.3)', backgroundColor: 'white', width: "100%", zIndex: 200}}>

                        <Tabs
                            style={{padding: 0}}
                            value={this.props.tabNumber}
                            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                            scrollButtons="auto"
                            variant={variant}
                            centered={centerTrue}
                        >
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="General Settings"
                                onClick={() => this.props.history.push('/admingeneralsettings')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="Codes Settings"
                                onClick={() => this.props.history.push('/admincodessettings')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="User Maintenance"
                                onClick={() => this.props.history.push('/adminusermaintenance')} />
                                 <Tab
                                classes={{ root: classes.tabRoot }}
                                label="Role Maintenance"
                                onClick={() => this.props.history.push('/adminrolemaintenance')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="User Queue"
                                onClick={() => this.props.history.push('/adminuserqueue')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="Vendor Settings"
                                onClick={() => this.props.history.push('/adminvendorsettings')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="Queue"
                                onClick={() => this.props.history.push('/adminqueue')} />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                label="Contract Settings"
                                onClick={() => this.props.history.push('/admincontracts')} />
                        </Tabs>
                    </div>
                
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
      },
      tabsRoot: {
        borderBottom: "1px solid #e8e8e8",
      },
      tabsIndicator: {
        backgroundColor: "#E86613",
      },
    
      tabRoot: {
        textTransform: "initial",
        minWidth: 72,
        "&:hover": {
          color: "#E86613",
          opacity: 1,
        },
        "&$tabSelected": {
          color: "#E86613",
        },
        "&:focus": {
          color: "#E86613",
          outline: "none",
        },
        "&:active": {
          outline: "none",
        }
    }
})

AdminTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminTabs);