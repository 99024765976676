import React from "react";
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField, withStyles
} from "@material-ui/core";
import { NumberFormatCustom } from "../../utils/numberFormatUtils";

const styles = ({
  resize: {},
  textFieldRoot: {
    height: '30px',
  },
})

class EditDistCodeDialog extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <Dialog
            onClose={this.props.onClose}
            open={this.props.open}
            maxWidth="lg"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.props.onSubmit}>
            <DialogTitle id="alert-dialog-title">Edit Code</DialogTitle>
            <DialogContent>
              <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', padding: '15px 0' }}>
                <Grid
                    spacing={3}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                  {this.props.accountingLevels &&
                  this.props.accountingLevels.map((segment, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <TextField
                            select
                            id={'updatedCodeSegment' + index}
                            label={segment.accounting_level_name}
                            defaultValue={this.props.currentCodeEntry['segment' + (index + 1)]}
                            style={{ margin: '0 0.25rem' }}
                            fullWidth
                            margin="dense"
                            InputProps={{
                              id: 'updatedCodeSegment' + index,
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <span/>
                                  </InputAdornment>
                              ),
                            }}
                        >
                          {segment.accounting_level_values &&
                          segment.accounting_level_values.map((el, index) => (
                              <MenuItem key={index} value={el.value}>
                                {el.description} ({el.value})
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                  ))}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        select
                        margin="dense"
                        id="accountCategory"
                        style={{ margin: '0 0.25rem' }}
                        fullWidth
                        InputProps={{ id: 'accountCategory' }}
                        label="Account Category"
                        autoComplete="off"
                        defaultValue={this.props.currentCodeEntry['accountCategory']}
                    >
                      {this.props.purchaseAccountCategory &&
                      this.props.purchaseAccountCategory.map((level, index) => (
                          <MenuItem key={index} value={level.value}>
                            {level.description}
                          </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        label="Total"
                        margin="dense"
                        style={{ margin: '0 0.25rem' }}
                        fullWidth
                        defaultValue={this.props.currentCodeEntry['total']}
                        id="updatedCodeSegmentTotal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                          startAdornment: (
                              <InputAdornment position="start">
                                <span/>
                              </InputAdornment>
                          ),
                        }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                  onClick={this.props.onClose}
                  variant="text"
              >
                Back
              </Button>
              <Button
                  onClick={this.props.handleDelete}
                  color="secondary"
                  variant="text"
              >
                Delete
              </Button>
              <Button
                  type="submit"
                  className="primaryVLButton"
              >
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
    )
  }
}

export default withStyles(styles)(EditDistCodeDialog);
