import { responsive } from './muidatatablesConsts';

export const invoiceDist = [
  {
    name: 'vendorInvoiceNumber',
    label: 'Vendor Invoice Number',
    options: {
      filter: true,
      sort: true,
    },
    wordBreak: 'normal'
  },
  {
    name: 'vendor',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true,
    },
    wordBreak: 'normal'
  },
  {
    name: 'invoiceDate',
    label: 'Invoice Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    options: {
      filter: true,
      sort: true,
    },
    wordBreak: 'normal'
  },
  {
    name: 'invoice Amount',
    label: 'Invoice Amount',
    options: {
      filter: true,
      sort: true,
    },
    wordBreak: 'normal'
  },
  {
    name: 'codingComplete',
    label: 'Coding Complete',
    options: {
      filter: true,
      sort: true,
      filterList: ['No']
    },
    wordBreak: 'normal'
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const invoiceApproval = [
  {
    name: 'vendorInvoiceNumber',
    label: 'Vendor Invoice Number',
    options: {
      filter: true,
      sort: true,
    },
    width: 80,
    wordBreak: 'normal'
  },
  {
    name: 'vendor',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'invoiceDate',
    label: 'Invoice Date',
    options: {
      filter: true,
      sort: true,
    },
    width: 85,
    wordBreak: 'normal',
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    options: {
      filter: true,
      sort: true,
    },
    width: 85,
    wordBreak: 'normal'
  },
  {
    name: 'invoice Amount',
    label: 'Invoice Amount',
    options: {
      filter: true,
      sort: true,
    },
    wordBreak: 'normal'
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
    width: 82,
  },
];

export const vendorInvoices = [
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'vendorInvoiceNumber',
    label: 'Vendor Invoice Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'invoiceDate',
    label: 'Invoice Date',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'invoiceAmount',
    label: 'Invoice Amount',
    options: {
      filter: true,
      sort: true,
    },
    width: 82,
  },
];

export const invoiceAccountingColumns = {
  cols: [
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: true,
        sort: true,
        filterList: ['Active'],
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
      },
    },
  ],
  options: {
    selectableRows: false,
    filterType: 'dropdown',
    responsive,
    print: false,
  }
};

export const invoiceDetailsFields = [
  { label: 'PO Number', id: 'poNumber', state: 'poNumber', array: 'invoicePoList', select: true, type: 'text' },
  { label: 'Contract', id: 'contract', state: 'contract', array: 'invoiceContractList', select: true, type: 'text' },
  { label: 'Description', id: 'description', state: 'description', array: null, select: false, type: 'text' },
  {
    label: 'Invoice Currency',
    id: 'invoiceCurrency',
    state: 'invoiceCurrency',
    array: 'invoiceCurrencyCodeList',
    select: true,
    type: 'text'
  },
  { label: 'Due Date', id: 'dueDate', state: 'dueDate', array: null, select: false, type: 'date' },
];

