import React from 'react';

import LambdaFetch from '../functions/FetchFromLambda';
import Documents from '../components/Documents';
import Attributes from '../components/Attributes';
import Notifications from '../components/Notifications';
import writeToActivity from '../functions/WriteToActivity';
import FieldChangeActivity from '../functions/FieldChangeActivity';
import VerifyAccess from '../functions/VerifyAccess';

const uuidv1 = require('uuid/v1');

class AttachAttribTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: this.props.parentType !== 'document' ? 0 : 1,
      documents: [],
      attributes: [],
      tasks: [],
      contractUsers: [],
      notificationUsers: [],
      isInitalLoad: true,
      attributeTypes: [],
      access: {},
      missingDocuments: []
    };
  }

  componentDidMount() {
    VerifyAccess(
        (data) => {
          this.setState({ access: data.access });
        },
        [
          'vendor_attribute',
          'vendor_document',
          'contract_document',
          'document_edit',
          'document_view',
          'document_add',
          'attribute_edit',
          'attribute_view',
          'attribute_add',
          'task_view',
          'task_edit',
          'task_add',
          'task_complete',
          'contract_task',
          'contract_attribute',
        ],
        this.props.jsonWebTok
    );

    this.getData();
    this.viewHash();
  }

  viewHash = () => {
    const hash = window.location.hash;
    if (hash === '#attachments') {
      if (this.props.parentType === 'document') {
        this.setState({ tabValue: 1 });
      } else {
        this.setState({ tabValue: 0 });
      }
    } else if (hash === '#attributes') {
      this.setState({ tabValue: 1 });
    } else if (hash === '#notifications') {
      this.setState({ tabValue: 2 });
      this.scrollToLocation();
    }
  };
  scrollToLocation = () => {
    const { hash } = window.location;
    if (hash !== '') {
      let retries = 0;
      const id = hash.replace('#', '');
      const scroll = () => {
        retries += 0;
        if (retries > 50) return;
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 0);
        } else {
          setTimeout(scroll, 100);
        }
      };
      scroll();
    }
  };
  getData = async () => {
    const info = await Promise.all([
      LambdaFetch(
          'read-attach-attrib-task',
          'post',
          this.props.jsonWebTok,
          JSON.stringify({
            jsonWebTok: this.props.jsonWebTok,
            parentId: this.props.parentId,
            parentType: this.props.parentType,
          })
      ),
      LambdaFetch(
          'get-all-users',
          'post',
          this.props.jsonWebTok,
          JSON.stringify({
            jsonWebTok: this.props.jsonWebTok,
          })
      ),
    ]);
    let data = info[0].data;
    let users = info[1].data;

    const filteredUsers = users.users.filter((user) => {
      return (!user.vendor_id ) ||
          user.vendor_id === this.props.vendorId
    });
    const attribUsers = users.users.filter((user) => {
      return (
          (!user.vendor_id) ||
          user.vendor_id === this.props.vendorId
      );
    });
    const notificationUsers = filteredUsers.map((u, index) => {
      return { title: u.username, name: u.name, index: index, uuid: u.uuid };
    });
    this.setState({
      isInitalLoad: false,
      attributes: data.attributes,
      contractUsers: attribUsers,
      notifications: data.notifications,
      notificationUsers: notificationUsers,
      notificationTypes: data.notification_types,
      documents: data.documents,
      documentTypes: data.document_types,
      attributeTypes: data.attribute_types,
      missingDocuments: data.missing_documents
    });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  addAttribute = async (newAttribute, callback) => {
    newAttribute['parent_id'] = this.props.parentId;
    newAttribute['parent_type'] = this.props.parentType;

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      attribute: newAttribute,
    };

    const action = await LambdaFetch(
        'add-attribute',
        'post',
        this.props.jsonWebTok,
        JSON.stringify(fetchData),
        'Successfully added attribute'
    );
    if (!action) {
      callback(400);
      return null;
    }
    const activity = {
      CLIENT_ID: this.props.clientId,
      OBJ_TYPE: this.props.parentType.toUpperCase(),
      OBJ_ID: this.props.parentId,
      ACTIVITY_TYPE: 'CREATE',
      ACTIVITY_STRING: newAttribute.activity_string,
      USER_ID: this.props.currentUser,
    };
    writeToActivity({ activity }, () => {
      this.props.getData();
      this.getData();
      callback(200);
    });
  };
  updateAttribute = async (updatedAttribute, callback) => {
    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      attribute: updatedAttribute,
    };

    const action = await LambdaFetch(
        'update-attribute',
        'post',
        this.props.jsonWebTok,
        JSON.stringify(fetchData),
        'Successfully updated attribute'
    );

    if (!action) {
      callback(400);
      return null;
    }

    const activity = {
      CLIENT_ID: this.props.clientId,
      OBJ_TYPE: this.props.parentType.toUpperCase(),
      OBJ_ID: this.props.parentId,
      ACTIVITY_TYPE: 'UPDATE',
      ACTIVITY_STRING: updatedAttribute.activity_string,
      USER_ID: this.props.currentUser,
      field_changes: action.data.changes.field_changes,
      type: 'attribute'
    };

    FieldChangeActivity({ activity }, () => {
      this.getData();
      this.props.getData();
      callback(200);
    });

  };
  addNotification = async (newNotification, callback) => {
    newNotification['parent_type'] = this.props.parentType;
    newNotification['parent_id'] = this.props.parentId;
    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      notification: newNotification,
    };

    const action = await LambdaFetch(
        'insert-notification',
        'post',
        this.props.jsonWebTok,
        JSON.stringify(fetchData),
        'Successfully created task'
    );

    if (!action) {
      callback(400);
      return null;
    }

    const activity = {
      CLIENT_ID: this.props.clientId,
      OBJ_TYPE: this.props.parentType.toUpperCase(),
      OBJ_ID: this.props.parentId,
      ACTIVITY_TYPE: 'CREATE',
      ACTIVITY_STRING: `Created Task: ${newNotification.title}`,
      USER_ID: this.props.currentUser,
    };
    writeToActivity({ activity }, () => {
      callback(200);
      this.props.getData();
      this.getData();
    });
  };
  updateNotification = async (updatedNotification, callback) => {
    let fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      notification: updatedNotification,
    };
    const action = await LambdaFetch(
        'update-notification',
        'post',
        this.props.jsonWebTok,
        JSON.stringify(fetchData),
        'Successfully updated task'
    );

    if (!action) {
      callback(400);
      return null;
    }
    const activity = {
      CLIENT_ID: this.props.clientId,
      OBJ_TYPE: this.props.parentType.toUpperCase(),
      OBJ_ID: this.props.parentId,
      ACTIVITY_TYPE: 'UPDATE',
      ACTIVITY_STRING: `Updated Task: ${updatedNotification.title}`,
      USER_ID: this.props.currentUser,
      field_changes: action.data.changes.field_changes,
      type: 'notification'
    };
    FieldChangeActivity({ activity }, () => {
      callback(200);
      this.props.getData();
      this.getData();
    });
  };
  archive = async (callback, type, id, name, isComplete, reactivate) => {
    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      type: type,
      isComplete: isComplete ? isComplete : false,
      reactivate: reactivate ? reactivate : false,
      id: id,
    };

    const action = await LambdaFetch(
        'update-status',
        'post',
        this.props.jsonWebTok,
        JSON.stringify(fetchData),
        `Successfully ${
            reactivate ? 'reactivated' : isComplete ? 'completed' : 'archived'
        } ${type === 'notification' ? 'task' : type}`
    );

    if (!action) {
      callback(400);
      return null;
    }

    const activity = {
      CLIENT_ID: this.props.clientId,
      OBJ_TYPE: this.props.parentType.toUpperCase(),
      OBJ_ID: this.props.parentId,
      ACTIVITY_TYPE: reactivate ? 'REACTIVATED' : 'ARCHIVED',
      ACTIVITY_STRING: `Archived ${type.charAt(0).toUpperCase() + type.slice(1)}: ${name}`,
      USER_ID: this.props.currentUser,
    };
    if (type === 'notification') {
      if (reactivate) {
        activity.ACTIVITY_STRING = `Reactivated Task: ${name}`;
      } else activity.ACTIVITY_STRING = `Completed Task: ${name}`;
    }
    writeToActivity({ activity }, (status) => {
      if (status === 200) {
        this.setState({ isEditting: false }, () => {
          this.props.getData();
          this.getData();
          callback(200);
        });
      } else {
        callback(400);
      }
    });
  };
  addNewDocument = (newDoc, callback) => {
    newDoc['parent_id'] = this.props.parentId;
    newDoc['parent_type'] = this.props.parentType;
    newDoc['created_by'] = this.props.currentUser;

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      document: newDoc,
    };

    fetch(`${process.env.REACT_APP_API}/insert-document`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => {
          return resp.json();
        })
        .then((info) => {
          if (!info.success) {
            callback({ statusCode: 400, error: info.data.error });
            return null;
          } else if (info.success) {

            const activityDoc = {
              CLIENT_ID: this.props.clientId,
              OBJ_TYPE: 'DOCUMENT',
              OBJ_ID: info.data.document_id,
              ACTIVITY_TYPE: 'CREATED',
              ACTIVITY_STRING: `${this.props.props.fetchInitialData.credentials.user.name} uploaded document`,
              USER_ID: this.props.currentUser,
            };
            const activity = {
              CLIENT_ID: this.props.clientId,
              OBJ_TYPE: this.props.parentType.toUpperCase(),
              OBJ_ID: this.props.parentId,
              ACTIVITY_TYPE: 'UPLOAD',
              ACTIVITY_STRING: `${this.props.props.fetchInitialData.credentials.user.name} uploaded document: ${newDoc.title}`,
              USER_ID: this.props.currentUser,
            };

            writeToActivity( {activity: activityDoc} , () => {
              writeToActivity({ activity }, () => {
                this.props.getData();
                this.getData();
              })
            callback({ statusCode: 200 });
            })


          }
        });
  };
  uploadFile = (fileData, callback) => {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      window.alert(fileData.name + ' is too big to upload');
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.props.fetchInitialData.credentials.user.clientId,
        obj_type: this.props.parentType.toUpperCase(),
        obj_id: this.props.parentId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: this.props.props.fetchInitialData.credentials.user.uuid,
      };
      const stringData = JSON.stringify(fetchData);

      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.jsonWebTok}`,
        },
        body: stringData,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.success) {
              callback(200, fetchData);
            } else {
              callback(400, fetchData);
            }
          });
    }
  };
  checkAccess = () => {
    const { access } = this.state;
    let renderAccess;
    if (this.props.parentType === 'vendor') {
      renderAccess = {
        showAttach: access.vendor_document && (access.document_edit || access.document_view),
        showAttrib: access.vendor_attribute && (access.attribute_edit || access.attribute_view),
        showTask: access.vendor_task || access.task_edit || access.task_view,
      };
    } else if (this.props.parentType === 'contract') {
      renderAccess = {
        showAttach: access.contract_document && (access.document_edit || access.document_view),
        showAttrib: access.contract_attribute && (access.attribute_edit || access.attribute_view),
        showTask: access.contract_task || access.task_edit || access.task_view,
      };
    } else {
      renderAccess = {
        showAttach: 0,
        showAttrib: (access.vendor_document || access.contract_document) && (access.attribute_edit || access.attribute_view),
        showTask: (access.vendor_document || access.contract_document) && (access.task_edit || access.task_view),
      };
    }
    renderAccess['viewDoc'] = access.document_view;
    renderAccess['editDoc'] = access.document_edit;
    renderAccess['addDoc'] = access.document_add;
    renderAccess['viewAttrib'] = access.attribute_view;
    renderAccess['editAttrib'] = access.attribute_edit;
    renderAccess['addAttrib'] = access.attribute_add;
    renderAccess['viewTask'] = access.task_view;
    renderAccess['editTask'] = access.task_edit;
    renderAccess['addTask'] = access.task_add;
    renderAccess['completeTask'] = access.task_complete;
    return renderAccess;
  };

  render() {
    const { tab } = this.props;
    if (
        this.props.props.fetchInitialData.credentials.isVendor &&
        this.props.parentType === 'document'
    ) {
      return null;
    }
    const access = this.checkAccess();
    return (
        (tab === 1 || tab === 5 || tab === 4 || tab === undefined) && (
            <div id={'notifications'}>
              {(!this.state.isInitalLoad && !!access.showAttach && (tab === 1 || tab === undefined)) && (
                  <Documents
                      access={access}
                      contractId={this.props.parentId}
                      parentType={this.props.parentType}
                      documents={this.state.documents}
                      documentTypes={this.state.documentTypes}
                      props={this.props.props}
                      addNewDocument={this.addNewDocument}
                      uploadFile={this.uploadFile}
                      addNotification={this.addNotification}
                      missingDocuments={this.state.missingDocuments}
                      whiteList={this.props.whiteList}
                  />
              )}
              {(!!access.showAttrib && (tab === 4 || tab === undefined)) && (
                  <Attributes
                      access={access}
                      props={this.props.props}
                      parentId={this.props.parentId}
                      parentType={this.props.parentType}
                      attributes={this.state.attributes}
                      attributeTypes={this.state.attributeTypes}
                      addAttribute={this.addAttribute}
                      updateAttribute={this.updateAttribute}
                      archive={this.archive}
                      contractUsers={this.state.contractUsers}
                  />
              )}
              {
                (!!access.showTask && this.state.notifications && (tab === 5 || tab === undefined)) && (
                    <Notifications
                        access={access}
                        props={this.props.props}
                        type={this.props.parentType}
                        objectId={this.props.parentId}
                        notificationTypes={this.state.notificationTypes}
                        notifications={this.state.notifications}
                        addNotification={this.addNotification}
                        updateNotification={this.updateNotification}
                        notificationUsers={this.state.notificationUsers}
                        archive={this.archive}
                    />
                )}
            </div>
        ));
  }
}

export default AttachAttribTask;


