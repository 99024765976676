export const adminQueueColumns = [
  {
    name: '#',
    label: '#',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'queuetype',
    label: 'Queue Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'level',
    label: 'Level',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'dollarLimit',
    label: 'Dollar Limit',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];
