import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import UserMaintenance from '../components/UserMaintenance';
import VerifyAccess from '../functions/VerifyAccess';

class VendorAdminUserMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      isOpen: false,
      currentEntry: null,
      confirmDelete: false,
      isActive: true,
      isLoading: false,
      currentRole: null,

      vendorUsers: [],
      showArchivedVendorUsers: false,
      isActivating: false,
      access: {},
    };
  }

  componentDidMount() {
    VerifyAccess(
        (data) => {
          this.setState({ access: data.access });
        },
        [
          'settings_vendoruser_edit',
          'settings_vendoruser_view',
          'settings_vendoruser_view',
          'settings_vendoruser_add',
          'settings_vendoruser_delete',
          'settings_vendoruser_roleadmin',
          'settings_vendoruser_admin',
        ],
        this.props.fetchInitialData.credentials.user.jsonWebTok
    );
  }

  render() {
    const loggedAsVendor = localStorage.getItem('isVendor');
    const {classes} = this.props;
    return (
        <div>
          <div className={classes.rejectContainer}>
            <UserMaintenance
                loggedAsVendor={loggedAsVendor}
                access={this.state.access}
                vendorId={this.props.fetchInitialData.credentials.user.vendorId}
                jsonWebTok={this.props.fetchInitialData.credentials.user.jsonWebTok}
                userType="Vendor"
                client_id={this.props.fetchInitialData.credentials.user.clientId}
                props={this.props}
            />
          </div>
        </div>
    );
  }
}

const styles = () => ({
  rejectContainer: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: 1300,
    '@media (max-width: 800px)': {
      padding: '0.5rem 0.1rem',
    },
  },
});

VendorAdminUserMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(VendorAdminUserMaintenance));
