import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { formatter } from "../../utils/tableCustoms";
import BasicField from "../common/BasicField";
import {Select} from "../common/Select";

const inputProps = {
  startAdornment: (
      <InputAdornment position="start">
        <div/>
      </InputAdornment>
  ),
};

class AddLineDialog extends React.Component {
  render() {
    return (
        <Dialog open={this.props.open} fullWidth maxWidth={'md'}
                onClose={this.props.onClose}>
          <form onSubmit={this.props.onSubmit}>
            <DialogTitle id="alert-dialog-title">Add Item</DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                      fullWidth
                      label="Description"
                      margin="dense"
                      id="description"
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                              <div/>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  {Select(
                      'uom',
                      'Unit of Measure',
                      this.props.uom,
                      this.props.uomList,
                      '15ch',
                      this.props.vendorId, this.props.setState, this.props.state
                  )}
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <BasicField onChange={this.props.updateAddLine}
                      id={'LINE_UNIT_COST'} label={'Rate'} type={'decimal'} val={this.props.newLine.LINE_UNIT_COST}
                   inputProps={{
                     startAdornment: (
                     <InputAdornment position="start">
                     <div/>
                     </InputAdornment>
                     ),
                   }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                      id="LINE_QTY"
                      label="Qty"
                      margin="dense"
                      type="number"
                      value={this.props.newLine.LINE_QTY}
                      onChange={(e) =>
                          this.props.setNewLine({ ...this.props.newLine, LINE_QTY: e.target.value })
                      }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                      id="LINE_TRAN_AMOUNT"
                      label="Total"
                      margin="dense"
                      value={
                        this.props.newLine.LINE_QTY && this.props.newLine.LINE_UNIT_COST
                            ? formatter.format(
                            this.props.newLine.LINE_QTY * this.props.newLine.LINE_UNIT_COST
                            )
                            : 0
                      }
                      InputProps={inputProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                  onClick={this.props.onClose}
                  variant="text"
              >
                Cancel
              </Button>
              <Button
                  type="submit"
                  disabled={this.props.isLoading}
                  style={{
                    backgroundColor: 'rgb(232,102,19)',
                    color: 'white',
                  }}
                  variant="contained"
              >
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>

    )
  }
}

export default AddLineDialog;
