import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  CircularProgress,
  Fab,
  Fade,
  Card,
  InputAdornment,
  Grid,
  Slide,
  TextField,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
  Button,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import LoadingButton from '../components/LoadingButton';
import writeToActivity from '../functions/WriteToActivity';
import FieldChangeActivity from '../functions/FieldChangeActivity';
import Comments from '../components/Comments';
import Attachments from '../components/Attachments';
import Activity from '../components/Activity';
import './PurchasingRequest.css';
import './Home.css';
import { purchasingRequestDetailsColumns } from '../utils/constants/purchaseRequestColumns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VendorStatus from '../functions/VendorStatus'
import ObjectNotifications from '../components/ObjectNotifications';

import LambdaFetch from '../functions/FetchFromLambda';
import { formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

class PurchasingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
      currentEdit: null,
      currentUom: '',
      company: '',
      locations: [],
      location: '',
      hasInactiveLocation: false,
      vendor: '',
      filterValue: '',
      poNumber: '',
      deliveryDate: '',
      currentQty: 0,
      currentCost: 0,
      currentEntryExtCost: 0,
      currentAccountCategory: '',
      header: null,
      rows: [],
      isLoading: false,
      isLoadingDelete: false,
      comments: [],
      isLoadingComment: false,
      editHeaders: false,
      isLoadingUpdate: false,
      isDateEdited: false,
      purchaseAccountcategory: [],
      contracts: null,
      contractId: '',
    };
    this.updateHeaders = this.updateHeaders.bind(this);
    this.createRows = this.createRows.bind(this);
    this.addRow = this.addRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  componentDidMount() {
    this.getData();
    this.getDropdownData();
    this.getAccountingLevels();
    this.setState({ isDateEdited: false });
  }

  getData = async () => {
    const body = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: window.location.href.split('purchasing-request-details/')[1],
    };
    const data = await LambdaFetch(
        'get-pos',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify(body)
    );
    this.createRows(data.data);
    this.getLocations(data.data.poHeader[0].VENDOR_ID);
    this.getContracts(data.data.poHeader[0].VENDOR_ID);
  };
  updateHeaders = async (event) => {
    event.preventDefault();
    if (
        this.state.isDateEdited &&
        moment(event.target['editHeaderDeliveryDate'].value).isBefore(
            moment(new Date()).add(-1, 'days')
        )
    ) {
      this.props.fetchInitialData.createSnack(
          `Date cannot be in the past`,
          'error',
          4000
      );
      return;
    } else if (
        this.state.isDateEdited &&
        moment(event.target['editHeaderDeliveryDate'].value).isAfter(
            moment(new Date()).year(moment(new Date()).year() + 100)
        )
    ) {
      this.props.fetchInitialData.createSnack(`Invalid date`, 'error', 4000);
      return;
    } else if (!this.state.location) {
      this.props.fetchInitialData.createSnack(
          `Please select the location`,
          'warning',
          4000
      );
      return;
    }

    let fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: this.state.header.PO_ID,
      company: event.target['editHeaderCompany']
          ? event.target['editHeaderCompany'].value
          : this.state.company,
      department: event.target['editHeaderDepartment']
          ? event.target['editHeaderDepartment'].value
          : this.state.department,
      location: event.target['editHeaderLocation']
          ? event.target['editHeaderLocation'].value
          : this.state.location,
      contractId: this.state.contractId,
      filterValue: event.target['editHeaderFilterValue']
          ? event.target['editHeaderFilterValue'].value
          : this.state.filterValue,
      deliveryDate: event.target['editHeaderDeliveryDate'].value,
    };

    this.state.accountingLevels.forEach((lvl, index) => {
      fetchData[`seg${index + 1}`] = this.state[`updatedSEG${index + 1}`]
          ? this.state[`updatedSEG${index + 1}`]
          : this.state.content.poHeader[0][`SEG${index + 1}`];
    });

    this.setState({ isLoadingUpdate: true });
    const info = await LambdaFetch(
        'update-po-headers',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify(fetchData),
        'Successfully updated headers'
    );

    const data = info.data.po;
    this.setState({
      editHeaders: false,
      isLoadingUpdate: false,
    });
    this.getData();
  };

  getContracts = (vendorId) => {
    fetch(`${process.env.REACT_APP_API}/get-contracts`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        vendorId,
      }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          const data = info.data
          if (info.success) {
            const activeContracts = data.contracts.filter(
                (contract) => parseInt(contract.status) === 1
            );
            this.setState({ contracts: activeContracts })
          }
        });

  }

  getLocations = async (id) => {
    this.setState({ loadingLocations: true });

    const info = await LambdaFetch(
        'get-locations',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({ vendorId: id })
    );

    const data = info.data;
    let locations = data.locations.map((l) => {
      return Object.assign(
          {},
          l,
          l.INACTIVE_FLAG === 1
              ? { NAME: `${l.NAME} (Inactive)`, ONLY_NAME: l.NAME }
              : { ONLY_NAME: l.NAME },
          l.PRIMARY_ADDR === 1
              ? { NAME: `${l.NAME} (Primary)`, ONLY_NAME: l.NAME }
              : { ONLY_NAME: l.NAME },
      );
    });
    this.setState({
      locations,
      loadingLocations: false,
    });
  };
  getDropdownData = () => {
    fetch(`${process.env.REACT_APP_API}/get-po-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((data) => {
          this.setState({
            purchaseAccountcategory: data.data.purchaseAccountcategory,
            filterList: data.data.purchaseFilterlist,
            uomDropdown: data.data.purchaseUomlist,
            accountCategories: data.data.purchaseAccountcategory,
          });
        });
  };

  getAccountingLevels = () => {
    this.setState({ isLoadingLevels: true });
    fetch(`${process.env.REACT_APP_API}/get-accounting-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((info) => {
          let accountingLevels = info.data.accountDropdowns.map((drop) => {
            return JSON.parse(drop.client_accounting_levels)[0];
          });
          this.setState({ accountingLevels });
          this.setState({ isLoadingLevels: false });
        });
  };

  createRows(rows) {
    const detailsNoDeletes = rows.poDetail.map((line) => {
      if (line.DELETED !== 1) {
        return {
          num: line.LINE_NUM,
          itemNum: line.LINE_ITEM,
          description: line.LINE_DESC,
          qty: line.LINE_QTY,
          filled: line.FULFILLED,
          uom: line.LINE_UOM,
          cost: line.LINE_COST,
          extCost: line.LINE_EXT_COST,
          deliveryDate: line.LINE_DELIVERY_DATE.split('T0')[0],
          shipTo: line.LINE_SHIP_TO,
          accountCategory: line.ACCOUNT_CATEGORY,
          edit: (
              <span
                  className="editLink"
                  onClick={() => {
                    this.setState({
                      isEdit: true,
                      currentEdit: {
                        num: line.LINE_NUM,
                        itemNum: line.LINE_NUM,
                        description: line.LINE_DESC,
                        qty: line.LINE_QTY,
                        filled: line.FULFILLED,
                        uom: line.LINE_UOM,
                        cost: line.LINE_COST,
                        extCost: line.LINE_EXT_COST,
                        deliveryDate: line.LINE_DELIVERY_DATE.split('T0')[0],
                        shipTo: line.LINE_SHIP_TO,
                        accountCategory: line.ACCOUNT_CATEGORY,
                        seg1: line.SEG1,
                        seg2: line.SEG2,
                        seg3: line.SEG3,
                        seg4: line.SEG4,
                        seg5: line.SEG5,
                        seg6: line.SEG6,
                        seg7: line.SEG7,
                        seg8: line.SEG8,
                        seg9: line.SEG9,
                        seg10: line.SEG10,
                      },
                      currentCost: line.LINE_COST,
                      currentQty: line.LINE_QTY,
                      currentEntryExtCost: line.LINE_EXT_COST,
                    });
                  }}
              >
              edit
            </span>
          ),
        };
      } else return null;
    });
    const filtered = detailsNoDeletes.filter((row) => row !== null);
    const header = rows.poHeader[0];
    this.setState({
      content: rows,
      isEdit: false,
      isNewEntryOpen: false,
      header: header,
      rows: filtered,
      company: header.COMPANY,
      location: header.LOCATION_ID,
      vendor: header.VENDOR_VNAME,
      filterValue: header.FILTER_VALUE,
      poNumber: header.PO_NUMBER,
      contractId: header.CONTRACT_ID,
      deliveryDate: header.DELIVERY_DATE.split('T0')[0],
      isLoading: false,
    });
  }

  isDateValid = (e) => {
    if (
        this.state.isDateEdited &&
        moment(e.deliveryDate.value).isBefore(moment(new Date()).add(-1, 'days'))
    ) {
      this.props.fetchInitialData.createSnack(
          `Date cannot be in the past`,
          'error',
          4000
      );
      return false;
    } else if (
        this.state.isDateEdited &&
        moment(e.deliveryDate.value).isAfter(
            moment(new Date()).year(moment(new Date()).year() + 100)
        )
    ) {
      this.props.fetchInitialData.createSnack(`Invalid date`, 'error', 4000);
      return false;
    }
    return true;
  }

  addRow = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const e = event.target;
    if (!this.isDateValid(e)) {
      this.setState({ isLoading: false });
      return;
    }
    const extAmt = this.state.currentEntryExtCost;
    const newRow = {
      edit: true,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      vendorId: this.state.content.poHeader[0].VENDOR_ID,
      poId: this.state.header.PO_ID,
      lineNum: this.state.content.poDetail.length
          ? this.state.content.poDetail[this.state.content.poDetail.length - 1].LINE_NUM + 1 : 1,
      lineItemNum: e.itemNum.value,
      lineDescription: e.description.value,
      lineQty: parseFloat(e.qty.value),
      lineUom: this.state.currentUom || '',
      lineCost: parseFloat(e.cost.value),
      lineExtCost: extAmt,
      lineDeliveryDate: e.deliveryDate.value,
      lineShipTo: e.shipTo.value,
      deleteFlag: 0,
      accountCategory: this.state.currentAccountCategory,
    };

    this.state.accountingLevels.forEach((lvl, index) => {
      newRow[`seg${index + 1}`] = this.state[`currentSEG${index + 1}`]
          ? this.state[`currentSEG${index + 1}`]
          : this.state.content.poHeader[0][`SEG${index + 1}`];
    });
    const fetchString = JSON.stringify({ add: true, ...newRow });

    await LambdaFetch(
        'update-po',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        fetchString,
        'Successfully added row'
    );
    this.setState({ isLoading: true, currentUom: '' });
    this.getData();
  };

  editRow = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const e = event.target;
    const extAmt = this.state.currentEntryExtCost;
    if (
        !
            this.isDateValid(e)) {
      this.setState({ isLoading: false });
      return;
    }
    const fetchData = {
      edit: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      vendorId: this.state.vendor,
      poId: this.state.header.PO_ID,
      lineNum: this.state.currentEdit.num,
      lineItemNum: e.itemNum.value,
      lineDescription: e.description.value,
      lineQty: e.qty.value,
      lineUom: this.state.currentEdit.uom ? this.state.currentEdit.uom : this.state.currentUom,
      lineCost: e.cost.value,
      lineExtCost: extAmt,
      lineDeliveryDate: e.deliveryDate.value.split('T00')[0],
      lineShipTo: e.shipTo.value,
      deleteFlag: 0,
      ...this.state.currentEdit,
    };

    const fetchString = JSON.stringify(fetchData);

    const action = await LambdaFetch(
        'update-po',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        fetchString,
        'Successfully edited row'
    );

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'PURCHASE',
      OBJ_ID: this.state.header.PO_ID,
      ACTIVITY_TYPE: 'DELETE',
      ACTIVITY_STRING: `Deleted line item ${fetchData.lineNum}`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      field_changes: action.data.changes.field_changes,
    };

    FieldChangeActivity({ activity }, () => {
      this.getData()
    })

    this.setState({ isLoading: false });
  };

  deleteRow = async () => {
    this.setState({ isLoadingDelete: true });
    const fetchData = {
      delete: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: this.state.header.PO_ID,
      lineNum: this.state.currentEdit.num,
      deleteFlag: 1,
    };
    const fetchString = JSON.stringify(fetchData);

    await LambdaFetch(
        'update-po',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        fetchString,
        'Successfully deleted row'
    );

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'PURCHASE',
      OBJ_ID: this.state.header.PO_ID,
      ACTIVITY_TYPE: 'DELETE',
      ACTIVITY_STRING: `Deleted line item ${fetchData.lineNum}`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid
    };

    writeToActivity({ activity }, () => {
      this.getData()
    })

    this.setState({ isLoading: false });
  };

  handleClose() {
    this.setState({
      isNewEntryOpen: false,
      isEdit: false,
      isDateEdited: false,
      currentSeg1: '',
      currentSeg2: '',
      currentSeg3: '',
      currentSeg4: '',
      currentSeg5: '',
      currentSeg6: '',
      currentSeg7: '',
      currentSeg8: '',
      currentSeg9: '',
      currentSeg10: '',
    });
  }

  render() {
    if (!this.state.content) {
      return (
          <LoadingCircle/>
      );
    }

    const loadDelete = this.state.isLoadingDelete ? (
        <Fade in={this.state.isLoadingDelete} unmountOnExit>
          <CircularProgress
              style={{ margin: '5px', color: '#FFFFFF' }}
              size="15px"
          />
        </Fade>
    ) : (
        ''
    );
    let sum = this.state.rows.reduce((acc, val) => {
      return acc + val.extCost;
    }, 0);
    const { classes } = this.props;
    let editItemNum,
        editDescription,
        editQty,
        editUom,
        editCost,
        editDeliveryDate,
        editShipTo = '';
    if (this.state.currentEdit !== null) {
      const ce = this.state.currentEdit;
      editItemNum = ce.itemNum;
      editDescription = ce.description;
      editQty = ce.qty;
      editUom = ce.uom;
      editCost = ce.cost;
      editDeliveryDate = ce.deliveryDate;
      editShipTo = ce.shipTo;
    }
    return (
        <div>
          {this.state.currentEdit && (
              <Dialog
                  open={this.state.isEdit}
                  onClose={this.handleClose}
                  fullWidth={true}
                  maxWidth={'lg'}
                  TransitionComponent={this.Transition}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.editRow}>
                  <DialogTitle id="alert-dialog-title">Edit Request</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={2}>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            id="deliveryDate"
                            type="date"
                            label="Delivery Date"
                            defaultValue={editDeliveryDate}
                            onChange={() => {
                              this.setState({ isDateEdited: true });
                            }}
                            InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <div/>
                                  </InputAdornment>
                              ),
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            id="shipTo"
                            label="Ship To"
                            fullWidth
                            defaultValue={editShipTo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            id="itemNum"
                            label="Item Number"
                            defaultValue={editItemNum}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            id="description"
                            fullWidth
                            label="Description"
                            defaultValue={editDescription}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                            id="qty"
                            type={'number'}
                            InputProps={{ inputProps: { min: 1 } }}
                            label="Quantity"
                            defaultValue={editQty}
                            onChange={(event) =>
                                this.setState({
                                  currentQty: event.target.value,
                                  currentEntryExtCost:
                                      event.target.value * this.state.currentCost,
                                })
                            }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                            id="uom"
                            select
                            label="Unit Of Measure"
                            className={classes.uomSelect}
                            defaultValue={editUom}
                            onChange={(event) =>
                                this.setState({
                                  currentUom: event.target.value,
                                })
                            }
                        >
                          {this.state.uomDropdown &&
                          this.state.uomDropdown.map((uom, index) => (
                              <MenuItem key={index} value={uom.value}>
                                {uom.value}
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                            id="cost"
                            label="Cost"
                            defaultValue={editCost}
                            type={'number'}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) =>
                                this.setState({
                                  currentCost: event.target.value,
                                  currentEntryExtCost:
                                      event.target.value * this.state.currentQty,
                                })
                            }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                            id="extCost"
                            label="Extended Cost"
                            value={formatter.format(this.state.currentEntryExtCost)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', padding: '15px 0' }}>
                      <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                        {this.state.accountingLevels &&
                        this.state.accountingLevels.map((el, index) => (
                            <Grid style={{ marginRight: 20 }} item xs={12} sm={4} md={3} lg={2} key={index}>
                              <TextField
                                  className={classes.customSelect}
                                  id={el.accounting_level_name.toString().toLowerCase()}
                                  label={el.accounting_level_name.toString()}
                                  defaultValue={
                                    this.state.currentEdit[`seg${index + 1}`]
                                  }
                                  onChange={(event) => {
                                    const updatedEdit = {
                                      ...this.state.currentEdit,
                                      [`seg${index + 1}`]: event.target.value,
                                    };
                                    this.setState({ currentEdit: updatedEdit });
                                  }}
                                  autoComplete="off"
                                  select
                              >
                                {el.accounting_level_values && el.accounting_level_values.map((level, index) => (
                                    <MenuItem key={index} value={level.value}>
                                      {level.description} ({level.value})
                                    </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                          <TextField
                              className={classes.customSelect}
                              id={'account_category'}
                              label={'Account Category'}
                              autoComplete="off"
                              defaultValue={this.state.currentEdit.accountCategory}
                              onChange={(event) => {
                                const updatedEdit = {
                                  ...this.state.currentEdit,
                                  accountCategory: event.target.value,
                                };
                                this.setState({ currentEdit: updatedEdit });
                              }}
                              select
                          >
                            {this.state.accountCategories &&
                            this.state.accountCategories.map((category, index) => (
                                <MenuItem key={index} value={category.value}>
                                  {category.description} ({category.value})
                                </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                        onClick={this.deleteRow}
                        style={{
                          color: 'red',
                        }}
                    >
                      Delete
                      {loadDelete}
                    </Button>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <LoadingButton
                        label="Save"
                        isLoading={this.state.isLoading}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Dialog>
          )}
          <Dialog
              open={this.state.isNewEntryOpen}
              onClose={this.handleClose}
              fullWidth={true}
              maxWidth={'lg'}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.addRow}>
              <DialogTitle id="alert-dialog-title">Add Request</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                        id="deliveryDate"
                        type="date"
                        label="Delivery Date"
                        defaultValue={this.state.deliveryDate}
                        onChange={() => {
                          this.setState({ isDateEdited: true });
                        }}
                        InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                <div/>
                              </InputAdornment>
                          ),
                        }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="shipTo"
                        label="Ship To"
                        fullWidth
                        defaultValue={this.state.location}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField id="itemNum" label="Item Number"/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField id="description" fullWidth label="Description"/>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="qty"
                        label="Quantity"
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) =>
                            this.setState({
                              currentQty: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentCost,
                            })
                        }
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="uom"
                        label="Unit Of Measure"
                        select
                        className={classes.uomSelect}
                        onChange={(event) =>
                            this.setState({
                              currentUom: event.target.value,
                            })
                        }
                    >
                      {this.state.uomDropdown &&
                      this.state.uomDropdown.map((uom, index) => (
                          <MenuItem key={index} value={uom.value}>
                            {uom.value}
                          </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="cost"
                        label="Cost"
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(event) =>
                            this.setState({
                              currentCost: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentQty,
                            })
                        }
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="extCost"
                        label="Extended Cost"
                        value={formatter.format(this.state.currentEntryExtCost)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', padding: '15px 0' }}>
                  <Grid spacing={2} container direction="row" justify="flex-start" alignItems="center">
                    {this.state.accountingLevels &&
                    this.state.accountingLevels.map((el, index) => (
                        <Grid item xs={12} sm={4} md={3} lg={2} key={index} style={{ marginRight: 20 }}>
                          <TextField
                              className={classes.customSelect}
                              id={el.accounting_level_name.toString().toLowerCase()}
                              label={el.accounting_level_name.toString()}
                              defaultValue={this.state.content.poHeader[0][`SEG${index + 1}`]}
                              onChange={(event) =>
                                  this.setState({
                                    [`currentSEG${index + 1}`]: event.target.value,
                                  })
                              }
                              autoComplete="off"
                              select
                          >
                            {el.accounting_level_values && el.accounting_level_values.map((level, index) => (
                                <MenuItem key={index} value={level.value}>
                                  {level.description} ({level.value})
                                </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <TextField
                          className={classes.customSelect}
                          id={'account_category'}
                          label={'Account Category'}
                          autoComplete="off"
                          onChange={(event) =>
                              this.setState({ currentAccountCategory: event.target.value })
                          }
                          select
                      >
                        {this.state.purchaseAccountcategory &&
                        this.state.purchaseAccountcategory.map(
                            (category, index) => (
                                <MenuItem key={index} value={category.value}>
                                  {category.description} ({category.value})
                                </MenuItem>
                            )
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Cancel</Button>

                <LoadingButton
                    label="Save"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                    buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>
          <div className="purchasingForm">
          <div
            style={{
              position: 'absolute',
              right: 0,

              fontSize: '12px',
            }}
          >
            <div>Status: {VendorStatus(this.state.content.poHeader[0].PO_STATUS)}</div>
            <div>Approval Level: {this.state.content.poHeader[0].APPROVAL_STATUS_LEVEL}</div>
          </div>
            <h3>Purchasing Request</h3>
            <Grid container justify="flex-start" spacing={2}>
              <Card
                  style={{ position: 'relative', padding: '1rem', margin: '1rem' }}
                  className={classes.paper}
              >
                {this.state.editHeaders && (
                    <Fragment>
                      <form onSubmit={this.updateHeaders}>
                        <Grid container justify="flex-start" spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            {this.state.accountingLevels &&
                            this.state.accountingLevels.map((el, index) => (
                                <div
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      maxWidth: 300,
                                      marginTop: 5,
                                    }}
                                >
                              <span className={classes.headerMarginTop}>
                                {el.accounting_level_name}:{' '}
                              </span>
                                  <TextField
                                      className={classes.customSelect}
                                      select
                                      id={el.accounting_level_name
                                          .toString()
                                          .toLowerCase()}
                                      autoComplete="off"
                                      defaultValue={
                                        this.state.content.poHeader[0][
                                            `SEG${index + 1}`
                                            ] || ''
                                      }
                                      onChange={(event) => {
                                        this.setState({
                                          [`updatedSEG${index + 1}`]: event.target
                                              .value,
                                        });
                                      }}
                                  >
                                    {el.accounting_level_values && el.accounting_level_values.map(
                                        (level, index) => (
                                            <MenuItem key={index} value={level.value}>
                                              {level.description} ({level.value})
                                            </MenuItem>
                                        )
                                    )}
                                  </TextField>
                                </div>
                            ))}
                            <div>
                          <span className={classes.headerMarginTop} style={{ top: 12 }}>
                            Location:{' '}
                          </span>
                              <Autocomplete
                                  style={{ display: 'inline-block', minWidth: 260 }}
                                  defaultValue={this.state.location}
                                  value={this.state.location}
                                  disabled={!this.state.locations.length}
                                  onChange={(event, newValue) => {
                                    this.setState({ location: newValue ? newValue.NAME : '' });
                                  }}
                                  id="location"
                                  options={this.state.locations}
                                  getOptionLabel={(option) => option.NAME || this.state.location}
                                  renderInput={(params) => (
                                      <TextField
                                          style={{ maxWidth: 200, cursor: 'pointer' }}
                                          id="textLocations"
                                          {...params}
                                          margin="dense"
                                      />
                                  )}
                              />
                              {this.state.loadingLocations && (
                                  <CircularProgress
                                      className={classes.spinner}
                                      size="20px"
                                  />
                              )}
                            </div>
                            <div style={{ marginTop: -2, marginBottom: 5 }}>
                              <span className={classes.headerMarginTop} style={{ top: 12 }}>
                                Contract:{' '}
                              </span>
                              <TextField
                                  id="contractId"
                                  disabled={
                                    !this.state.contracts || !this.state.contracts.length
                                  }
                                  getOptionLabel={(option) => option.NAME ? option.NAME : 'No available contracts'}
                                  onChange={(event) =>
                                      this.setState({ contractId: event.target.value })
                                  }
                                  defaultValue={this.state.contractId}
                                  style={{ marginTop: 10, minWidth: 200}}
                                  autoComplete="off"
                                  select
                              >
                                {this.state.contracts && this.state.contracts.map((contract, index) => (
                                    <MenuItem key={index}
                                              value={contract.contract_id}>{contract.contract_number}</MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div>
                          <span className={classes.headerMarginTop}>
                            {this.props.fetchInitialData.credentials.clientInfo.filter_value_label}:{' '}
                          </span>
                              <TextField
                                  id="editHeaderFilterValue"
                                  select
                                  defaultValue={this.state.filterValue}
                                  onChange={(event) =>
                                      this.setState({ filterValue: event.target.value })
                                  }
                              >
                                {this.state.filterList &&
                                this.state.filterList.map((el, index) => (
                                    <MenuItem key={index} value={el.FILTER_VALUE}>
                                      {el.FILTER_VALUE}
                                    </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div style={{ marginTop: 5 }}>
                          <span className={classes.headerMarginTop}>
                            Delivery Date:{' '}
                          </span>
                              <TextField
                                  id="editHeaderDeliveryDate"
                                  type="date"
                                  defaultValue={this.state.deliveryDate}
                                  onChange={() => {
                                    this.setState({ isDateEdited: true });
                                  }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <div>
                              <span className={classes.headers}>Vendor: </span>
                              <span>{this.state.vendor}</span>
                            </div>
                            <div>
                              <span className={classes.headers}>PO Number: </span>
                              <span>{this.state.poNumber}</span>
                            </div>
                          </Grid>
                          <div style={{ marginTop: '0.5rem' }}>
                            <Button
                                onClick={() =>
                                    this.setState({
                                      editHeaders: false,
                                      isDateEdited: false,
                                    })
                                }
                            >
                              Cancel
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                className="primaryVLButton"
                                style={{
                    
                                  marginLeft: '0.5rem',
                                }}
                            >
                              Submit
                            </Button>
                            {this.state.isLoadingUpdate && (
                                <CircularProgress
                                    className={classes.spinner}
                                    size="20px"
                                    style={{ right: 0, bottom: -5 }}
                                />
                            )}
                          </div>
                        </Grid>
                      </form>
                    </Fragment>
                )}
                {!this.state.editHeaders && (
                    <Fragment>
                      <Button
                          style={{ position: 'absolute', right: '12px', top: '12px' }}
                          className="material-icons editHeaders"
                          onClick={() => {
                            this.setState({ editHeaders: true });
                          }}
                      >
                        Edit
                      </Button>
                      <Grid container justify="flex-start" spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          {this.state.isLoadingLevels && (
                              <CircularProgress
                                  size="20px"
                                  style={{
                                    position: 'relative',
                                    top: 10,
                                    left: '30%',
                                    color: '#545454',
                                  }}
                              />
                          )}
                          {this.state.accountingLevels &&
                          this.state.accountingLevels.map((el, index) => (
                              <div key={index}>
                            <span className={classes.headers}>
                              {el.accounting_level_name}:{' '}
                            </span>
                                <span>
                              {this.state.content.poHeader[0][`SEG${index + 1}`]}
                            </span>
                              </div>
                          ))}
                          <div>
                            <span className={classes.headers}>Location: </span>
                            <span>{this.state.location}</span>
                          </div>
                          <div>
                            <span className={classes.headers}>Contract: </span>
                            <span>{this.state.content.poHeader[0].contract_number}</span>
                          </div>
                          <div>
                        <span className={classes.headers}>
                          {this.props.fetchInitialData.credentials.clientInfo.filter_value_label}:{' '}
                        </span>
                            <span>{this.state.filterValue}</span>
                          </div>
                          <div>
                            <span className={classes.headers}>Delivery Date: </span>
                            <span>{this.state.deliveryDate}</span>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div>
                            <span className={classes.headers}>Vendor: </span>
                            <span>{this.state.vendor}</span>
                          </div>
                          <div>
                            <span className={classes.headers}>PO Number: </span>
                            <span>{this.state.poNumber}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Fragment>
                )}
              </Card>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <span>Total: </span>
                  <span style={{ fontSize: '1.6rem' }}>
                  <strong>{formatter.format(sum)}</strong>
                </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <form onSubmit={this.addToInvoice}>
                    <Table className={classes.table}>
                      <colgroup>
                        {purchasingRequestDetailsColumns.map((el, index) => (
                            <col key={index} style={{ width: el.width }}/>
                        ))}
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          {purchasingRequestDetailsColumns.map((el, index) => (
                              <TableCell
                                  key={index}
                                  className={classes.tableCell}
                                  align="center"
                              >
                                {el.label}
                              </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rows.map((row) => (
                            <TableRow key={row.num}>
                              <TableCell align="center" component="th" scope="row">
                                {row.num}
                              </TableCell>
                              {[
                                row.itemNum,
                                row.description,
                                row.qty,
                                row.filled,
                                row.uom,
                                formatter.format(row.cost),
                                formatter.format(row.extCost),
                                row.deliveryDate,
                                row.shipTo,
                                row.edit,
                              ].map((el, index) => (
                                  <TableCell
                                      key={index}
                                      className={classes.tableCell}
                                      align="center"
                                  >
                                    {el}
                                  </TableCell>
                              ))}
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div style={{ marginLeft: '0.7rem' }}>
                      <Fab
                          onClick={() => {
                            this.setState({
                              isNewEntryOpen: true,
                              currentCost: 0,
                              currentQty: 0,
                              currentEntryExtCost: 0,
                            });
                          }}
                          size="small"
                          aria-label="Add"
                          style={{margin: '0.5rem'}}
                        className="primaryVLButton"
                      >
                        +
                      </Fab>
                    </div>
                  </form>
                </Paper>
              </Grid>
            </Grid>

            <Attachments
                fetchInitialData={this.props.fetchInitialData}
                objectId={
                  window.location.href.split('purchasing-request-details/')[1]
                }
                objectType="PURCHASE"
            />
            <Comments
                fetchInitialData={this.props.fetchInitialData}
                objectId={
                  window.location.href.split('purchasing-request-details/')[1]
                }
                objectType="PURCHASE"
                vendorId={this.state.content.poHeader[0].VENDOR_ID}
                isVendor={this.props.fetchInitialData.credentials.isVendor}

            />
            <Activity
                title="Purchase Request History"
                activity={this.state.content.activity}
                timezone={this.props.fetchInitialData.credentials.user.timezone}
                appWidth={this.props.fetchInitialData.credentials.appWidth}
            />
            <ObjectNotifications
              createSnack={this.props.fetchInitialData.createSnack}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              parentId={this.state.content.poHeader[0].PO_ID}
              parentType="purchase"
              isVendor={!!this.props.fetchInitialData.credentials.isVendor}
              vendorId={this.state.content.poHeader[0].VENDOR_ID}
            />
          </div>
        </div>
    );
  }
}

const styles = () => ({
  headers: {
    fontSize: '1.2rem',
    position: 'relative',
  },
  headerMarginTop: {
    fontSize: '1.2rem',
    position: 'relative',
    marginRight: '0.5rem',
    top: '5px',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: '1000px',
  },
  tableCell: {
    padding: '10px 20px',
  },
  fab: {
    margin: '0.25rem',
    color: 'white',
    backgroundColor: 'rgb(232,102,19)',
    '&:hover': {
      backgroundColor: 'rgb(220,92,10)',
      color: 'white',
    },
  },
  spinner: {
    marginLeft: '10px',
    color: '#545454',
    position: 'relative',
    right: 60,
    bottom: -15,
  },
  customSelect: {
    minWidth: '100%',
    '& label': {
      width: 'max-content',
    },
    '& > div': {
      '& > div': {
        paddingRight: '50px !important',
      },
    },
  },
  uomSelect: {
    '& label': {
      width: 'max-content',
    },
    '& > div': {
      '& > div': {
        paddingRight: '150px !important',
      },
    },
  },
});

PurchasingRequest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PurchasingRequest);
