import React from 'react';
import './Admin.css';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminTabs from '../components/AdminTabs';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

class AdminNotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: null,
      newVendorSelect: 'Instantly',
      errorSelect: 'Instantly',
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    var jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-or-update-vl-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: jsonClientInfo,
    })
      .then((resp) => resp.json())
      .then((data) => {
        const newVendorEmails = data.notifications.filter(
          (notification) => notification.key === 'newVendor'
        );
        const errorEmails = data.notifications.filter(
          (notification) => notification.key === 'error'
        );
        const generalMaintenanceEmails = data.notifications.filter(
          (notification) => notification.key === 'generalMaintenance'
        );
        const billingEmails = data.notifications.filter(
          (notification) => notification.key === 'billing'
        );

        let newVendorTime = 'Instantly';
        let updateVendorTime = 'Instantly';

        if (newVendorEmails.length > 0) {
          newVendorTime = newVendorEmails[0].time;
        }
        if (errorEmails.length > 0) {
          updateVendorTime = errorEmails[0].time;
        }
        this.setState({
          notifications: {
            newVendorEmails: newVendorEmails,
            errorEmails: errorEmails,
            generalMaintenanceEmails: generalMaintenanceEmails,
            billingEmails: billingEmails,
          },
          newVendorSelect: newVendorTime,
          errorSelect: updateVendorTime,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleNewVendorChange(value) {
    this.setState({ newVendorSelect: value });
  }
  handleerrorChange(value) {
    this.setState({ errorSelect: value });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let fetchObject = {
      update: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      newVendor: {
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        key: 'newVendor',
        email1: event.target['newVendorNotification_1'].value,
        email2: event.target['newVendorNotification_2'].value,
        email3: event.target['newVendorNotification_3'].value,
        email4: event.target['newVendorNotification_4'].value,
        time: this.state.newVendorSelect,
      },
      error: {
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        key: 'error',
        email1: event.target['errorNotification_1'].value,
        email2: event.target['errorNotification_2'].value,
        email3: event.target['errorNotification_3'].value,
        email4: event.target['errorNotification_4'].value,
        time: this.state.errorSelect,
      },
      generalMaintenance: {
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        key: 'generalMaintenance',
        email1: event.target['systemNotification_1'].value,
        email2: event.target['systemNotification_2'].value,
        email3: event.target['systemNotification_3'].value,
        email4: event.target['systemNotification_4'].value,
      },
      billing: {
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        key: 'billing',
        email1: event.target['billingNotification_1'].value,
        email2: event.target['billingNotification_2'].value,
        email3: event.target['billingNotification_3'].value,
        email4: event.target['billingNotification_4'].value,
      },
    };
    const jsonNotificationString = JSON.stringify(fetchObject);
    fetch(`${process.env.REACT_APP_API}/get-or-update-vl-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: jsonNotificationString,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.errorMessage) {
          if (data.errorMessage === 1061 || data.errorMessage === 1062) {
            window.alert('Error: This value already exists');
          } else {
            window.alert('There was an error');
          }
        } else {
          const newVendorEmails = data.notifications.filter(
            (notification) => notification.key === 'newVendor'
          );
          const errorEmails = data.notifications.filter(
            (notification) => notification.key === 'error'
          );
          const generalMaintenanceEmails = data.notifications.filter(
            (notification) => notification.key === 'generalMaintenance'
          );
          const billingEmails = data.notifications.filter(
            (notification) => notification.key === 'billing'
          );

          let newVendorTime = 'Instantly';
          let updateVendorTime = 'Instantly';

          if (newVendorEmails.length > 0) {
            newVendorTime = newVendorEmails[0].time;
          }
          if (errorEmails.length > 0) {
            updateVendorTime = errorEmails[0].time;
          }
          this.setState({
            notifications: {
              newVendorEmails: newVendorEmails,
              errorEmails: errorEmails,
              generalMaintenanceEmails: generalMaintenanceEmails,
              billingEmails: billingEmails,
            },
            newVendorSelect: newVendorTime,
            errorSelect: updateVendorTime,
            isLoading: false,
          });
          window.alert('Changes have been saved');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    if (!this.state.notifications) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    const { notifications } = this.state;

    let newVendorEmailDefaults;
    let errorEmailDefaults;
    let generalMaintenanceEmailDefaults;
    let billingEmailDefaults;

    if (notifications.newVendorEmails.length !== 0) {
      let newVen = notifications.newVendorEmails[0];
      newVendorEmailDefaults = {
        email1: newVen.email1,
        email2: newVen.email2,
        email3: newVen.email3,
        email4: newVen.email4,
      };
    } else {
      newVendorEmailDefaults = {
        email1: '',
        email2: '',
        email3: '',
        email4: '',
      };
    }
    if (notifications.errorEmails.length !== 0) {
      let err = notifications.errorEmails[0];
      errorEmailDefaults = {
        email1: err.email1,
        email2: err.email2,
        email3: err.email3,
        email4: err.email4,
      };
    } else {
      errorEmailDefaults = {
        email1: '',
        email2: '',
        email3: '',
        email4: '',
      };
    }
    if (notifications.generalMaintenanceEmails.length !== 0) {
      let genMain = notifications.generalMaintenanceEmails[0];
      generalMaintenanceEmailDefaults = {
        email1: genMain.email1,
        email2: genMain.email2,
        email3: genMain.email3,
        email4: genMain.email4,
      };
    } else {
      generalMaintenanceEmailDefaults = {
        email1: '',
        email2: '',
        email3: '',
        email4: '',
      };
    }
    if (notifications.billingEmails.length !== 0) {
      let bill = notifications.billingEmails[0];
      billingEmailDefaults = {
        email1: bill.email1,
        email2: bill.email2,
        email3: bill.email3,
        email4: bill.email4,
      };
    } else {
      billingEmailDefaults = {
        email1: '',
        email2: '',
        email3: '',
        email4: '',
      };
    }
    const load = this.state.isLoading ? (
      <Fade in={this.state.isLoading} unmountOnExit>
        <CircularProgress color="inherit" size="20px" />
      </Fade>
    ) : (
      <span></span>
    );
    const submitting = this.state.isLoading ? (
      <span style={{ paddingRight: '0.3rem' }}>Saving Changes</span>
    ) : (
      'Save Changes'
    );
    return (
      <div>
        <AdminTabs history={this.props.history} tabNumber={4} />

        <div className="rejectContainer">
          <form onSubmit={this.handleSubmit}>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h5>New Vendor</h5>
                  {/* <div>
                                        <Radio
                                            checked={this.state.newVendorSelect === 'Weekly'}
                                            onChange={() => this.handleNewVendorChange('Weekly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Weekly</span>
                                        <Radio
                                            checked={this.state.newVendorSelect === 'Daily'}
                                            onChange={() => this.handleNewVendorChange('Daily')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Daily</span>
                                        <Radio
                                            checked={this.state.newVendorSelect === 'Hourly'}
                                            onChange={() => this.handleNewVendorChange('Hourly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Hourly</span>
                                        <Radio
                                            checked={this.state.newVendorSelect === 'Instantly'}
                                            onChange={() => this.handleNewVendorChange('Instantly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Instantly</span>
                                    </div> */}
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="newVendorNotification_1"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={newVendorEmailDefaults.email1}
                      />
                      <TextField
                        id="newVendorNotification_2"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={newVendorEmailDefaults.email2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="newVendorNotification_3"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={newVendorEmailDefaults.email3}
                      />
                      <TextField
                        id="newVendorNotification_4"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={newVendorEmailDefaults.email4}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h5>Error Updates</h5>
                  {/* <div>
                                        <Radio
                                            checked={this.state.errorSelect === 'Weekly'}
                                            onChange={() => this.handleerrorChange('Weekly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Weekly</span>
                                        <Radio
                                            checked={this.state.errorSelect === 'Daily'}
                                            onChange={() => this.handleerrorChange('Daily')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Daily</span>
                                        <Radio
                                            checked={this.state.errorSelect === 'Hourly'}
                                            onChange={() => this.handleerrorChange('Hourly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Hourly</span>
                                        <Radio
                                            checked={this.state.errorSelect === 'Instantly'}
                                            onChange={() => this.handleerrorChange('Instantly')}
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                            }}
                                        />
                                        <span style={{ fontSize: '0.8rem' }}>Instantly</span>
                                    </div> */}
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="errorNotification_1"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={errorEmailDefaults.email1}
                      />
                      <TextField
                        id="errorNotification_2"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={errorEmailDefaults.email2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="errorNotification_3"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={errorEmailDefaults.email3}
                      />
                      <TextField
                        id="errorNotification_4"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={errorEmailDefaults.email4}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h5>System Notifications and Outage Warnings</h5>
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="systemNotification_1"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={generalMaintenanceEmailDefaults.email1}
                      />
                      <TextField
                        id="systemNotification_2"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={generalMaintenanceEmailDefaults.email2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="systemNotification_3"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={generalMaintenanceEmailDefaults.email3}
                      />
                      <TextField
                        id="systemNotification_4"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={generalMaintenanceEmailDefaults.email4}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h5>Billing and Account Messages</h5>
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="billingNotification_1"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={billingEmailDefaults.email1}
                      />
                      <TextField
                        id="billingNotification_2"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={billingEmailDefaults.email2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="billingNotification_3"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={billingEmailDefaults.email3}
                      />
                      <TextField
                        id="billingNotification_4"
                        label="Email"
                        fullWidth
                        // type='email'
                        variant="filled"
                        margin="dense"
                        defaultValue={billingEmailDefaults.email4}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  style={{ backgroundColor: 'rgb(232,84,10)' }}
                  variant="contained"
                  id="accept"
                  type="submit"
                  color="primary"
                >
                  {submitting}
                  {load}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    color: 'rgb(117, 117, 117)',
    '&$checked': {
      color: 'rgb(232,102,19)',
    },
  },
  checked: {},
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
});

AdminNotificationSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminNotificationSettings);
