import React, { Fragment } from 'react';
import {
  Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
  FormControlLabel, Grid, InputLabel, List, ListItem, MenuItem, Select, TextField, withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import { withSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';

import ConfirmDialog from '../components/ConfirmDialog';
import LoadingButton from './LoadingButton';
import { taskColumns } from "../utils/constants/notificationsTasksColumns";
import {responsive} from '../utils/constants/muidatatablesConsts';

const styles = () => ({
  button: {
    color: 'rgba(1,1,1,0.8)',
  },
  '&:focus': {
    outline: 'none !important',
  },
  '&:active': {
    outline: 'none !important',
  },
});

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewNotification: false,
      addingNotification: false,
      newNotificationType: '',
      newNotifies: [],
      notifications: this.props.notifications,
      editIndex: null,
      editNotification: null,
      editNotifies: [],
      editAddingNotification: null,
      isSaving: false,
      viewType: 'list',
      showCompleted: false,
    };
    this.handleArchive = this.handleArchive.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  addNewNotification = (e) => {
    e.preventDefault();
    const {
      new90Check,
      new60Check,
      new30Check,
      new7Check,
      new0Check,
    } = e.target;
    this.setState({ isSaving: true });

    if (new Date(e.target.newDate.value) > new Date('3000-01-01')) {
      this.createSnack('Enter a valid date', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }

    if (
        this.state.addingNotification &&
        !new90Check.checked &&
        !new60Check.checked &&
        !new30Check.checked &&
        !new7Check.checked &&
        !new0Check.checked
    ) {
      this.createSnack(
          'You must choose at least 1 notify in dates',
          'warning',
          3000
      );
      this.setState({ isSaving: false });
      return null;
    }

    if (this.state.newNotifies.length < 1 && this.state.addingNotification) {
      this.createSnack(
          'You must add at least 1 user to notify',
          'warning',
          3000
      );
      this.setState({ isSaving: false });
      return null;
    }

    const users = this.state.newNotifies.map((user) => user.uuid);
    const newNotification = {
      parent_type: this.props.type,
      parent_id: this.props.objectId,
      date: e.target.newDate.value,
      title: e.target.newTitle.value,
      description: e.target.newDescription.value,
      notification_type_id: this.props.notificationTypes
          .find((n) => n.id === this.state.newNotificationType).notification_type_id,
      to_notify: this.state.addingNotification ? 1 : 0,
      days_90: 0,
      days_60: 0,
      days_30: 0,
      days_7: 0,
      days_0: 0,
      users_to_notify: users,
    };
    if (newNotification.to_notify === 1) {
      newNotification.days_90 = e.target.new90Check.checked ? 1 : 0;
      newNotification.days_60 = e.target.new60Check.checked ? 1 : 0;
      newNotification.days_30 = e.target.new30Check.checked ? 1 : 0;
      newNotification.days_7 = e.target.new7Check.checked ? 1 : 0;
      newNotification.days_0 = e.target.new0Check.checked ? 1 : 0;
    }

    this.props.addNotification(newNotification, () => {
      this.setState({
        addNewNotification: false,
        isSaving: false,
        newNotifies: [],
      });
    });
  };
  editNotification = (type, value) => {
    const editNotificationUpdated = Object.assign({}, this.state.editNotification);
    editNotificationUpdated[type] = value;
    this.setState({ editNotification: editNotificationUpdated });
  };
  editFullNotification = (event) => {
    event.preventDefault();
    const { editNotification } = this.state;
    const { days_0, days_7, days_30, days_60, days_90 } = editNotification;

    this.setState({ isSaving: true });

    if (new Date(this.state.editNotification.date) > new Date('3000-01-01')) {
      this.createSnack('Enter a valid date', 'warning', 3000);
      this.setState({ isSaving: false });
      return null;
    }

    if (
        editNotification.to_notify === 1 &&
        !days_0 && !days_7 && !days_30 && !days_60 && !days_90
    ) {
      this.createSnack(
          'You must choose at least 1 notify in dates',
          'warning',
          3000
      );
      this.setState({ isSaving: false });
      return null;
    }

    if (
        this.state.editNotifies.length < 1 &&
        this.state.editNotification.to_notify === 1
    ) {
      this.createSnack(
          'You must add at least 1 user to notify',
          'warning',
          3000
      );
      this.setState({ isSaving: false });
      return null;
    }

    const updatedFullNotification = Object.assign({}, this.state.editNotification);
    updatedFullNotification.users_to_notify = this.state.editNotifies.map((user) => user.uuid);

    this.props.updateNotification(updatedFullNotification, () => {
      this.setState({
        editIndex: null,
        editNotification: null,
        editNotifies: [],
        isSaving: false,
      });
    });
  };
  onTagsChange = (event, values, type) => {
    this.setState({ [type]: values });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleArchive(id, callback) {
    this.props.archive(
        () => {
          this.setState({ editIndex: null, editNotification: null }, () => {
            callback(200);
          });
        },
        'notification',
        id,
        this.state.editNotification.title,
        false
    );
  }

  handleComplete(id, callback) {
    this.props.archive(
        () => {
          this.setState({ editIndex: null, editNotification: null }, () => {
            callback(200);
          });
        },
        'notification',
        id,
        this.state.editNotification.title,
        true
    );
  }

  handleReactivate = (id, callback) => {
    this.props.archive(
        () => {
          this.setState({ editIndex: null, editNotification: null }, () => {
            callback(200);
          });
        },
        'notification',
        id,
        this.state.editNotification.title,
        true,
        true
    );
  };

  render() {
    const { editNotification } = this.state;
    const { notifications } = this.props;
    const reveredNotifications = notifications.slice().reverse();

    let allUserObj = this.props.notificationUsers.reduce((acc, user, index) => {
      let userObj = { index: index, title: user.title };
      return { ...acc, [user.uuid]: userObj };
    }, {});

    let u, defaultUsers;

    if (editNotification) {
      u = JSON.parse(editNotification.users_to_notify).map((user) => {
        if (allUserObj[user] != null) {
          return this.props.notificationUsers[allUserObj[user].index];
        } else return null;
      });

      defaultUsers = u.filter((user) => user);
    }

    let sortedNotifications;
    if (this.state.showCompleted) {
      sortedNotifications = reveredNotifications
          .filter((n) => n.is_complete === 1)
          .slice()
          .sort((a, b) => {
            const dateB = new Date(b.date).getTime();
            const dateA = new Date(a.date).getTime();
            return dateA > dateB ? 1 : -1;
          })
          .map((n, index) => {
            let taskRow = [
              n.notifiction_type_description,
              n.title,
              n.to_notify === 0
                  ? 'No Notification'
                  : JSON.parse(n.users_to_notify).length,
              isNaN(new Date(n.date).getTime())
                  ? ''
                  : format(addDays(n.date, 1), 'MM/dd/yyyy'),
            ];
            if (this.props.access.editTask) {
              taskRow.push(
                  <div
                      className="editLink"
                      onClick={() => {
                        let u = JSON.parse(n.users_to_notify)
                            .map((user) => {
                              if (allUserObj[user] != null) {
                                return this.props.notificationUsers[
                                    allUserObj[user].index
                                    ];
                              } else return null;
                            })
                            .filter((user) => user);

                        this.setState({
                          editIndex: index,
                          editNotification: n,
                          editNotifies: u,
                          editAddingNotification: n.to_notify,
                        });
                      }}
                  >
                    {this.props.access.editTask ? 'edit' : 'view'}
                  </div>
              );
            }
            return taskRow;
          });
    } else {
      sortedNotifications = reveredNotifications
          .filter((n) => n.is_complete === 0)
          .slice()
          .sort((a, b) => {
            const dateB = new Date(b.date).getTime();
            const dateA = new Date(a.date).getTime();
            return dateA > dateB ? 1 : -1;
          })
          .map((n, index) => {
            let taskRow = [
              n.notifiction_type_description,
              n.title,
              n.to_notify === 0
                  ? 'No Notification'
                  : JSON.parse(n.users_to_notify).length,
              isNaN(new Date(n.date).getTime())
                  ? ''
                  : format(addDays(n.date, 1), 'MM/dd/yyyy'),
            ];
            if (this.props.access.editTask) {
              taskRow.push(
                  <div
                      className="editLink"
                      onClick={() => {
                        let u = JSON.parse(n.users_to_notify)
                            .map((user) => {
                              if (allUserObj[user] != null) {
                                return this.props.notificationUsers[
                                    allUserObj[user].index
                                    ];
                              } else return null;
                            })
                            .filter((user) => user);

                        this.setState({
                          editIndex: index,
                          editNotification: n,
                          editNotifies: u,
                          editAddingNotification: n.to_notify,
                        });
                      }}
                  >
                    edit
                  </div>
              );
            }
            return taskRow;
          });
    }
    let viewNum = reveredNotifications.length - sortedNotifications.length;

    return (
        <Grid item xs={12} sm={12} md={12} style={{ position: 'relative', top: 15 }}>
          <Card>
            <CardContent>
              <div>
                <List component="nav">
                  {this.state.addNewNotification && (
                      <Dialog
                          open={this.state.addNewNotification}
                          onClose={this.handleClose}
                          maxWidth="md"
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                        <form onSubmit={(event) => this.addNewNotification(event)}>
                          <DialogTitle id="alert-dialog-title">
                            Add Task
                          </DialogTitle>

                          <DialogContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="newDate"
                                    label="Date"
                                    required
                                    type="date"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                />

                                <FormControl
                                    margin="dense"
                                    fullWidth
                                    variant="outlined"
                                >
                                  <InputLabel>Notification Type</InputLabel>
                                  <Select
                                      id="editNotifcationType"
                                      value={this.state.newNotificationType}
                                      onChange={(event) =>
                                          this.setState({
                                            newNotificationType: event.target.value,
                                          })
                                      }
                                      labelWidth={125}
                                      required
                                  >
                                    {this.props.notificationTypes.map((type) => {
                                      return (
                                          <MenuItem
                                              key={`type_${type.id}`}
                                              value={type.id}
                                          >
                                            {type.description}
                                          </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    multiline
                                    label="Title"
                                    margin="dense"
                                    id="newTitle"
                                    variant="outlined"
                                    autoComplete="off"
                                    required
                                />

                                <TextField
                                    fullWidth
                                    multiline
                                    label="Description"
                                    margin="dense"
                                    id="newDescription"
                                    variant="outlined"
                                    autoComplete="off"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <div style={{ margin: '0.5rem' }}>
                                  <FormControlLabel
                                      style={{ margin: '0 0.25rem' }}
                                      label="Add a Notification"
                                      labelPlacement="end"
                                      control={
                                        <Checkbox
                                            id="addingNotification"
                                            style={{ color: this.props.props.fetchInitialData.credentials.primaryAppColor }}
                                            checked={this.state.addingNotification}
                                            onChange={(event) =>
                                                this.setState({
                                                  addingNotification:
                                                  event.target.checked,
                                                })
                                            }
                                        />
                                      }
                                  />
                                </div>
                              </Grid>

                              {this.state.addingNotification && (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <div style={{ margin: '0.5rem 0 0.5rem 1rem' }}>
                                      Notify in:
                                    </div>
                                    {[{
                                      type: 'new90Check',
                                      label: '90 days'
                                    },
                                    {
                                      type: 'new60Check',
                                      label: '60 days'
                                    },
                                     {
                                      type: 'new30Check',
                                      label: '30 days'
                                    },
                                     {
                                      type: 'new7Check',
                                      label: '7 days'
                                    },
                                     {
                                      type: 'new0Check',
                                      label: 'Day of'
                                    }].map(cur => {
                                      return (
                                        <>
                                        {notificationCheckbox(
                                        cur.type,
                                        null,
                                        null,
                                        cur.label,
                                        null,
                                        null,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                    </>
                                      )
                                    })}
                                  </Grid>
                              )}
                            </Grid>
                            {this.state.addingNotification && (
                                <Autocomplete
                                    style={{ width: '100%' }}
                                    multiple
                                    disableCloseOnSelect
                                    options={this.props.notificationUsers}
                                    onChange={(event, values) =>
                                        this.onTagsChange(event, values, 'newNotifies')
                                    }
                                    getOptionLabel={(option) => `${option.name}  (${option.title})`}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="newNotifies"
                                            variant="outlined"
                                            label="User(s) to Notify"
                                            fullWidth
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            )}
                          </DialogContent>
                          <DialogActions>
                            <Button
                                style={{ marginRight: '0.5rem' }}
                                onClick={() => this.setState({ addNewNotification: false })}
                            >
                              Cancel
                            </Button>

                            <LoadingButton
                                label="Save"
                                isLoading={this.state.isSaving}
                                color="primaryVLButton"
                                buttonType="submit"
                            />
                          </DialogActions>
                        </form>
                      </Dialog>
                  )}

                  {editNotification && (
                      <Dialog
                          open={!!editNotification}
                          onClose={this.handleClose}
                          maxWidth="md"
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                        <form onSubmit={(event) => this.editFullNotification(event)}>
                          <DialogTitle id="alert-dialog-title">
                            Edit Task
                          </DialogTitle>
                          <DialogContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id={`${editNotification.notification_id}_date`}
                                    label="Date"
                                    type="date"
                                    size="small"
                                    onChange={(event) =>
                                        this.editNotification(
                                            'date',
                                            event.target.value
                                        )
                                    }
                                    value={editNotification.date.split('T')[0]}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    autoComplete="off"
                                />

                                <FormControl
                                    margin="dense"
                                    fullWidth
                                    variant="outlined"
                                >
                                  <InputLabel>Notification Type</InputLabel>
                                  <Select
                                      id="editNotifcationType"
                                      value={editNotification.notification_type_id}
                                      onChange={(event) => {
                                        this.editNotification(
                                            'notification_type_id',
                                            event.target.value
                                        );
                                      }}
                                      labelWidth={125}
                                  >
                                    {this.props.notificationTypes.map((type) => {
                                      return (
                                          <MenuItem
                                              key={`type_${type.notification_type_id}`}
                                              value={type.notification_type_id}
                                          >
                                            {type.description}
                                          </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    label="Title"
                                    margin="dense"
                                    multiline
                                    id={`${editNotification.notification_id}_title`}
                                    value={editNotification.title}
                                    onChange={(event) =>
                                        this.editNotification(
                                            'title',
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    autoComplete="off"
                                />

                                <TextField
                                    fullWidth
                                    label="Description"
                                    margin="dense"
                                    multiline
                                    id={`${editNotification.notification_id}_description`}
                                    value={editNotification.description}
                                    onChange={(event) =>
                                        this.editNotification(
                                            'description',
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    autoComplete="off"
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <div style={{ margin: '0.5rem' }}>
                                  <FormControlLabel
                                      style={{ margin: '0 0.25rem' }}
                                      label="Add a Notification"
                                      labelPlacement="end"
                                      control={
                                        <Checkbox
                                            id="addingNotification"
                                            style={{ color: this.props.props.fetchInitialData.credentials.primaryAppColor }}
                                            checked={
                                              editNotification.to_notify === 1
                                            }
                                            onChange={(event) => {
                                              this.editNotification(
                                                  'to_notify',
                                                  event.target.checked ? 1 : 0
                                              );
                                            }}
                                        />
                                      }
                                  />
                                </div>
                              </Grid>

                              {editNotification.to_notify === 1 && (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <div
                                        style={{
                                          margin: '0.5rem 0 0.5rem 1rem',
                                        }}
                                    >
                                      Notify in:
                                    </div>
                                    {notificationCheckbox(
                                        `editday90`,
                                        editNotification.days_90 === 1,
                                        false,
                                        '90 Days',
                                        'days_90',
                                        this.editNotification,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                    {notificationCheckbox(
                                        `editday60`,
                                        editNotification.days_60 === 1,
                                        false,
                                        '60 Days',
                                        'days_60',
                                        this.editNotification,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                    {notificationCheckbox(
                                        `editday30`,
                                        editNotification.days_30 === 1,
                                        false,
                                        '30 Days',
                                        'days_30',
                                        this.editNotification,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                    {notificationCheckbox(
                                        `editday7`,
                                        editNotification.days_7 === 1,
                                        false,
                                        '7 Days',
                                        'days_7',
                                        this.editNotification,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                    {notificationCheckbox(
                                        `editday0`,
                                        editNotification.days_0 === 1,
                                        false,
                                        'Day of',
                                        'days_0',
                                        this.editNotification,
                                        this.props.props.fetchInitialData.credentials.primaryAppColor
                                    )}
                                  </Grid>
                              )}
                            </Grid>
                            {editNotification.to_notify === 1 && (
                                <ListItem>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        multiple
                                        disableCloseOnSelect
                                        defaultValue={defaultUsers}
                                        options={this.props.notificationUsers}
                                        getOptionLabel={(option) => `${option.name}  (${option.title})`}                                        filterSelectedOptions
                                        onChange={(event, values) =>
                                            this.onTagsChange(
                                                event,
                                                values,
                                                'editNotifies'
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="editNotifies"
                                                variant="outlined"
                                                label="User(s) to Notify"
                                                fullWidth
                                            />
                                        )}
                                    />
                                  </Grid>
                                </ListItem>
                            )}
                          </DialogContent>
                          <DialogActions>
                          <Button
                                
                                onClick={() =>
                                    this.setState({
                                      editIndex: null,
                                      editNotification: null,
                                      editNotifies: [],
                                      editAddingNotification: null,
                                    })
                                }
                            >
                              Cancel
                            </Button>
                            {this.state.showCompleted ? (
                                <Fragment>
                                  {!!this.props.access.completeTask && (
                                      <ConfirmDialog
                                          handleConfirm={this.handleReactivate}
                                          type="notification"
                                          title="Reactivate Task"
                                          color="green"
                                          reactivate
                                          dialogTitle="Are you sure you want to reactivate this task?"
                                          dialogText="By reactivating this task you will receive notifications pertaining to this task"
                                          id={editNotification.notification_id}
                                      />
                                  )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                  {!!this.props.access.completeTask && (
                                      <ConfirmDialog
                                          handleConfirm={this.handleComplete}
                                          type="notification"
                                          title="Task Complete"
                                          color="green"
                                          dialogTitle="Are you sure you want to mark this task as complete?"
                                          dialogText="By marking this task as complete, you will no longer receive notifications pertaining to this task"
                                          id={editNotification.notification_id}
                                      />
                                  )}
                                </Fragment>
                            )}

              
                            {!!this.props.access.editTask && (
                                <LoadingButton
                                    label="Save"
                                    isLoading={this.state.isSaving}
                                    color="primaryVLButton"
                                    buttonType="submit"
                                />
                            )}
                          </DialogActions>
                        </form>
                      </Dialog>
                  )}
                  {this.state.viewType === 'list' && (
                      <MUIDataTable
                          title={'Tasks'}
                          data={sortedNotifications}
                          columns={
                            this.props.access.editTask ? taskColumns
                                : taskColumns.slice(0, -1)
                          }
                          options={{
                            customSort: (data, colIndex, order) => {
                              let dateCols = taskColumns.reduce(
                                  (dateCols, currCol, index) => {
                                    if (currCol.name.toLowerCase().indexOf('date') !== -1 ||
                                        currCol.name.toLowerCase().indexOf('upload') !== -1
                                    ) {
                                      return [...dateCols, index];
                                    } else return dateCols;
                                  },
                                  []
                              );
                              return data.sort((a, b) => {
                                if (dateCols.indexOf(colIndex) !== -1) {
                                  return (
                                      (new Date(a.data[colIndex]) <
                                      new Date(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1)
                                  );
                                } else {
                                  return (
                                      (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
                                      (order === 'desc' ? 1 : -1)
                                  );
                                }
                              });
                            },
                            search: false,
                            print: false,
                            filter: false,
                            viewColumns: false,
                            download: false,

                            displaySelectToolbar: false,
                            elevation: 0,
                            selectableRows: false,
                            filterType: 'dropdown',
                            responsive,
                          }}
                      />
                  )}
                </List>
              </div>

              {!!this.props.access.addTask && (
                  <div>
                    {!this.state.addNewNotification && (
                        <div style={{ float: 'left', margin: '1rem 0' }}>
                          <Button
                              aria-label="add"
                              className="primaryVLButton"
                              onClick={() =>
                                  this.setState({ addNewNotification: true })
                              }
                          >{`Add ${this.props.type.slice(0, 1).toUpperCase() +
                          this.props.type.slice(1)} Task`}</Button>
                        </div>
                    )}
                  </div>
              )}

              <div style={{ float: 'right', margin: '1rem 0' }}>
                <Button
                    onClick={() => {
                      if (!this.state.showCompleted) {
                        this.setState({
                          showCompleted: !this.state.showCompleted,
                          viewType: 'list',
                        });
                      } else {
                        this.setState({ showCompleted: !this.state.showCompleted });
                      }
                    }}
                    variant="outlined"
                      className="primaryTextVLButton"
                >
                  {this.state.showCompleted
                      ? `View Active Tasks (${viewNum})`
                      : `View Completed Tasks (${viewNum})`}
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Notifications));

function notificationCheckbox(id, isChecked, isDisabled, label, name, myMethod, boxColor) {
  if (isDisabled) {
    return (
        <FormControlLabel
            id={name}
            style={{ margin: '0 0.25rem' }}
            label={label}
            labelPlacement="end"
            control={<Checkbox id={id} checked={isChecked} disabled={isDisabled}/>}
        />
    );
  } else if (!myMethod) {
    return (
        <FormControlLabel
            style={{ margin: '0 0.25rem' }}
            label={label}
            labelPlacement="end"
            control={<Checkbox id={id} style={{ color: boxColor }}/>}
        />
    );
  } else {
    return (
        <FormControlLabel
            style={{ margin: '0 0.25rem' }}
            label={label}
            labelPlacement="end"
            control={
              <Checkbox
                  id={id}
                  style={{ color: boxColor }}
                  checked={isChecked}
                  onChange={(event) => myMethod(name, event.target.checked ? 1 : 0)}
              />
            }
        />
    );
  }
}

function addDays(inputDate, days) {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + days);
  return date;
}
