import React, {useEffect} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './containers/Home';
import { NotFound, AccessDenied, Submission } from './containers/NotFound';
import VerifyEmail from './pages/VerifyEmail';
import NoClient from './containers/NoClient';
import VendorForm1 from './containers/VendorForm.1';
import VendorConfirmation from './containers/VendorConfirmation';
import Approver from './containers/Approver';
import Details from './containers/Details';
import AdminCodesSettings from './containers/AdminCodesSettings';
import AdminUserMaintenance from './containers/AdminUsersAndRolesMaintenance';
import AdminNotificationSettings from './containers/AdminNotificationSettings';
import AdminGeneralSettings from './containers/AdminGeneralSettings';
import NotAuth from './containers/NotAuth';
import VendorPaymentForm from './containers/VendorPaymentForm';
import VendorLandingPage from './containers/VendorLandingPage';
import InvoiceDistHome from './containers/InvoiceDistHome';
import InvoiceDetails from './containers/InvoiceDetails';
import InvoiceApprovalHome from './containers/InvoiceApprovalHome';
import AdminUserQueue from './containers/AdminUserQueue';
import PurchasingRequest from './containers/PurchaseRequest';
import PurchasingRequestHome from './containers/PurchasingRequestHome';
import PurchasingRequestDetails from './containers/PurchasingRequestDetails';
import PurchaseRequestApprovalHome from './containers/PurchaseRequestApprovalHome';
import PurchaseRequestApprovalDetails from './containers/PurchaseRequestApprovalDetails';
import ForgotPassword from './containers/ForgotPassword';
import InvoiceVendorDetails from './containers/InvoiceVendorDetails';
import ClientProfile from './containers/ClientProfile';
import PoVendorDetails from './containers/PoVendorDetails';
import VendorAdminUserMaintenance from './containers/VendorAdminUserMaintenance';
import EmployeeReimbursementRequest from './containers/EmployeeReimbursementRequest';
import ClientPaymentForm from './containers/ClientPaymentForm';
import PurchaseRequestVendorHome from './containers/PurchaseRequestVendorHome';
import VendorDetails from './containers/VendorDetails';
import AdminQueue from './containers/AdminQueue';
import ClientVendors from './containers/ClientVendors';
import Contracts from './containers/Contracts';
import ContractDetails from './containers/ContractDetails';
import DocumentDetails from './containers/DocumentDetails';
import AdminContractSettings from './containers/AdminContractSettings';
import ViewVendorDetails from './containers/ViewVendorDetails';
import ClientLandingPage from './containers/ClientLandingPage';
import AllandPendingVendors from './containers/AllandPendingVendors';
import VendorAllContracts from './containers/VendorAllContracts';
import ClientPurchaseRequestMod from './containers/ClientPurchaseRequestMod';
import ClientInvoiceMod from './containers/ClientInvoiceMod';
import Reports from './containers/Reports';
import AdminSettings from './containers/AdminSettings';
import VendorInvoices from './containers/VendorInvoices';
import VendorPos from './containers/VendorPos';
import AdminVendorSettings from './containers/AdminVendorSettings';
import ChangeResponsibility from './containers/ChangeResponsibility';
import { useHistory } from "react-router-dom";

function Routes(props) {
const history = useHistory();
useEffect(() => {
window.history.replaceState({}, '')
}, [])
	const auth = props;
	return (
		<Switch>
			<Route path="/" exact render={(props) => <Home {...props} fetchInitialData={auth} />} />
			{paths.map((route) => {
				if (!route.license || auth.credentials.clientInfo[route.license] == 1) {
					const TagName = route.component;
					return (
						<Route
							key={route.path}
							path={route.path}
							render={(props) =>
								auth.credentials.user.jsonWebTok ? (
									<TagName {...props} fetchInitialData={auth} />
								) : (
									<Redirect
										to={{
											pathname: '/',
											state: { path: `${window.location.pathname}${window.location.hash}`},
										}}
									/>
								)
							}
						/>
					);
				}
			})}
			<Route path="*/confirmation" component={VendorConfirmation} />
			{<Route path="/forgotpassword" render={(props) => <ForgotPassword {...props} fetchInitialData={auth} />} />}
			{<Route path="*/vendorform" render={(props) => <VendorForm1 {...props} fetchInitialData={auth} />} />}
			<Route path="/login" component={Home} />

			<Route path="/clientnotfound" exact component={NoClient} />
			<Route path="/accessdenied" exact component={AccessDenied} />
			<Route component={NotFound} />
		</Switch>
	);
}

export default Routes;

const paths = [
	{
		path: '/profile',
		component: ClientProfile,
	},
	{
		path: '/po-vendor-details',
		component: PoVendorDetails,
		license: 'po_license',
	},
	{
		path: '/vendordetails',
		component: VendorDetails,
	},
	{
		path: '/purchasing-request-approval-details',
		component: PurchaseRequestApprovalDetails,
		license: 'po_license',
	},
	{
		path: '/vendor-user-maintenance',
		component: VendorAdminUserMaintenance,
	},
	{
		path: '/purchasing-request-approval',
		component: PurchaseRequestApprovalHome,
		license: 'po_license',
	},
	{
		path: '/purchasing-request-details',
		component: PurchasingRequestDetails,
		license: 'po_license',
	},
	{
		path: '/purchasingrequesthome',
		component: PurchasingRequestHome,
		license: 'po_license',
	},
	{
		path: '/purchaserequests',
		component: ClientPurchaseRequestMod,
		license: 'po_license',
	},
	{
		path: '/invoices',
		component: ClientInvoiceMod,
		license: 'invoice_license',
	},
	{
		path: '/purchasingrequest',
		component: PurchasingRequest,
		license: 'po_license',
	},
	{
		path: '/adminuserqueue',
		component: AdminUserQueue,
	},
	{
		path: '/invoices-approval',
		component: InvoiceApprovalHome,
		license: 'invoice_license',
	},
	{
		path: '/invoice-details',
		component: InvoiceDetails,
		license: 'invoice_license',
	},
	{
		path: '/vendorpaymentform',
		component: VendorPaymentForm,
	},
	{
		path: '/invoice-vendor-details',
		component: InvoiceVendorDetails,
		license: 'invoice_license',
	},
	{
		path: '/invoices-dist',
		component: InvoiceDistHome,
		license: 'invoice_license',
	},
	{
		path: '/approver',
		component: Approver,
	},
	{
		path: '/allvendors',
		component: AllandPendingVendors,
	},
	{
		path: '/viewvendordetails',
		component: ViewVendorDetails,
	},
	{
		path: '/adminnotificationsettings',
		component: AdminNotificationSettings,
	},
	{
		path: '/admingeneralsettings',
		component: AdminGeneralSettings,
	},
	{
		path: '/adminusermaintenance',
		component: AdminUserMaintenance,
	},
	{
		path: '/admincodessettings',
		component: AdminCodesSettings,
	},
	{
		path: '/adminqueue',
		component: AdminQueue,
	},
	{
		path: '/admincontracts',
		component: AdminContractSettings,
	},
	{
		path: '/yourvendors',
		component: ClientVendors,
	},
	{
		path: '/adminvendorsettings',
		component: AdminVendorSettings,
	},
	{
		path: '/details',
		component: Details,
	},
	{
		path: '/vendor',
		component: VendorLandingPage,
	},
	{
		path: '/vcontracts',
		component: VendorAllContracts,
		license: 'contract_license',
	},
	{
		path: '/vinvoices',
		component: VendorInvoices,
		license: 'invoice_license',
	},
	{
		path: '/purchaserequestvendorhome',
		component: PurchaseRequestVendorHome,
		license: 'po_license',
	},
	{
		path: '/reimbursementrequest',
		component: EmployeeReimbursementRequest,
	},
	{
		path: '/client-payment-details',
		component: ClientPaymentForm,
	},
	{
		path: '/contracts',
		component: Contracts,
		license: 'contract_license',
	},
	{
		path: '/contract-details',
		component: ContractDetails,
		license: 'contract_license',
	},
	{
		path: '/document-details',
		component: DocumentDetails,
	},
	{
		path: '/home',
		component: ClientLandingPage,
	},
	{
		path: '/reports',
		component: Reports,
	},
	{
		path: '/adminsettings',
		component: AdminSettings,
	},
	{
		path: '/vpos',
		component: VendorPos,
		license: 'po_license',
	},
	{
		path: '/swap-tasks',
		component: ChangeResponsibility,
	},
];
{
	/* {
          <Route
            path="/po-vendor-details"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PoVendorDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/vendordetails"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/purchasing-request-approval-details"
            render={(props) =>
              // this.props.credentials.isPrApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchaseRequestApprovalDetails
                  {...props}
                  fetchInitialData={auth}
                />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/vendor-user-maintenance"
            render={(props) =>
              // this.props.credentials.isVendorAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorAdminUserMaintenance
                  {...props}
                  fetchInitialData={auth}
                />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/purchasing-request-approval"
            render={(props) =>
              // this.props.credentials.isPrApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchaseRequestApprovalHome
                  {...props}
                  fetchInitialData={auth}
                />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/purchasing-request-details"
            render={(props) =>
              // (this.props.credentials.isBuyer ||
              //   this.props.credentials.isPrApprover) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchasingRequestDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/purchasingrequesthome"
            render={(props) =>
              // this.props.credentials.isBuyer &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchasingRequestHome {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/purchaserequests"
            render={(props) =>
              // this.props.credentials.isBuyer &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ClientPurchaseRequestMod {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/invoices"
            render={(props) =>
              // this.props.credentials.isInvoiceApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ClientInvoiceMod {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/purchasingrequest"
            render={(props) =>
              // this.props.credentials.isBuyer &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchasingRequest {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/adminuserqueue"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminUserQueue {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {/* {
          <Route
            path="/invoice-distribution"
            render={(props) =>
              // this.props.credentials.isCoder &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <InvoiceDistDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/invoices-approval"
            render={(props) =>
              // this.props.credentials.isInvoiceApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <InvoiceApprovalHome {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/invoice-details"
            render={(props) =>
              // this.props.credentials.isInvoiceApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <InvoiceDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/vendorpaymentform"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorPaymentForm {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/invoice-vendor-details"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <InvoiceVendorDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/invoices-dist"
            render={(props) =>
              // this.props.credentials.isCoder &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <InvoiceDistHome {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/approver"
            render={(props) =>
              // this.props.credentials.isApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <Approver {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/allvendors"
            render={(props) =>
              // (this.props.credentials.isViewVendor ||
              //   this.props.credentials.isEditVendor ||
              //   this.props.credentials.isApprover) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AllandPendingVendors {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/viewvendordetails"
            render={(props) =>
              // (this.props.credentials.isViewVendor ||
              //   this.props.credentials.isEditVendor) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ViewVendorDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/adminnotificationsettings"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminNotificationSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/admingeneralsettings"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminGeneralSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/adminusermaintenance"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminUserMaintenance {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/admincodessettings"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminCodesSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/adminqueue"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminQueue {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/admincontracts"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminContractSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/yourvendors"
            render={(props) =>
              this.props.credentials.clientId ===
              this.props.credentials.clientInfo.clientId ? (
                <ClientVendors {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/adminvendorsettings"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminVendorSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
       

        {
          <Route
            path="/details"
            render={(props) =>
              // this.props.credentials.isApprover &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <Details {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/vendor"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorLandingPage {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/vcontracts"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorAllContracts {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
              path="/vinvoices"
              render={(props) =>
                  // this.props.credentials.isVendor &&
                  this.props.credentials.clientId ===
                  this.props.credentials.clientInfo.clientId ? (
                      <VendorInvoices {...props} fetchInitialData={auth} />
                  ) : (
                      <Redirect to="/" {...props} />
                  )
              }
          />
        }
        {
          <Route
            path="/purchaserequestvendorhome"
            render={(props) =>
              // this.props.credentials.isVendor &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <PurchaseRequestVendorHome {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/reimbursementrequest"
            render={(props) =>
              // this.props.credentials.isAdmin &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <EmployeeReimbursementRequest
                  {...props}
                  fetchInitialData={auth}
                />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/client-payment-details"
            render={(props) =>
              // this.props.credentials.isBuyer &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ClientPaymentForm {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/contracts"
            render={(props) =>
              // (this.props.credentials.isContractGeneral ||
              //   this.props.credentialsisContractAdmin) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <Contracts {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/contract-details"
            render={(props) =>
              // (this.props.credentials.isContractGeneral ||
              //   this.props.credentials.isContractAdmin ||
              //   this.props.credentials.isVendor ||
              //   this.props.credentials.isVendorAdmin) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ContractDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/document-details"
            render={(props) =>
              // (this.props.credentials.isContractGeneral ||
              //   this.props.credentials.isContractAdmin ||
              //   this.props.credentials.isVendor) &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <DocumentDetails {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }

        {
          <Route
            path="/home"
            render={(props) =>
              // this.props.credentials.isClient &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ClientLandingPage {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }

        <Route path="/notauth" component={NotAuth} />
        <Route path="/submitted" component={Submission} />
        <Route path="/verify" component={VerifyEmail} />
        {
          <Route
            path="/reports"
            render={(props) =>
              // this.props.credentials.isClient &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <Reports {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/adminsettings"
            render={(props) =>
              // this.props.credentials.isClient &&
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <AdminSettings {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/vpos"
            render={(props) =>
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <VendorPos {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/" {...props} />
              )
            }
          />
        }
        {
          <Route
            path="/swap-tasks"
            render={props =>
              this.props.credentials.clientId ===
                this.props.credentials.clientInfo.clientId ? (
                <ChangeResponsibility {...props} fetchInitialData={auth} />
              ) : (
                <Redirect to="/notauth" {...props} />
              )
            }
          />
        }
 */
}
