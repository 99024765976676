import { InputAdornment, MenuItem, TextField } from "@material-ui/core";
import React from "react";

export const Select = (id, label, val, items, width, vendorId, setState, state) => {
  return (
      <div>
        <TextField
            id={id}
            select
            style={{
              width: width ? width : '25ch',
              marginTop: '0.5rem',
              marginBottom: '2px',
            }}
            label={label}
            fullWidth
            disabled={!vendorId}
            value={val}
            onChange={(e) => setState({ ...state, [id]: e.target.value })}
            InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                    <span/>
                  </InputAdornment>
              ),
            }}
        >
          {items && items.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.description}
              </MenuItem>
          ))}
        </TextField>
      </div>
  );
};
