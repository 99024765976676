import React from 'react';
import './Details.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './VendorForm.css';
import DropDown from '../components/DropDown';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import Fade from '@material-ui/core/Fade';
import Comments from '../components/Comments';
import Activity from '../components/Activity';
import writeToActivity from '../functions/WriteToActivity';
import ViewVendorLegal from '../components/ViewVendorLegal';
import AttachAttribTask from '../components/AttachAttribTask';
import ClientContactMaintenance from '../components/ClientContactMaintenance';
import ClientLocationMaintenance from '../components/ClientLocationMaintenance';
import VendorStatus from '../functions/VendorStatus'
import VerifyAccess from '../functions/VerifyAccess';
import LambdaFetch from '../functions/FetchFromLambda';

import moment from 'moment-timezone';

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },

  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.masterPHONE_NUM0 = React.createRef();
    this.masterFAX_NUM0 = React.createRef();
    this.contactPHONE_NMBR0 = React.createRef();
    this.contactMOBILE_NUM0 = React.createRef();
    this.contactFAX_NMBR0 = React.createRef();
    this.contactPHONE_NMBR1 = React.createRef();
    this.contactMOBILE_NUM1 = React.createRef();
    this.contactFAX_NMBR1 = React.createRef();
    this.state = {
      formButton: null,
      isOpen: false,
      isAccept: null,
      content: {},
      updatedFields: null,
      dropdowns: null,
      termCode: null,
      invCurrency: null,
      languageCode: '',
      vendorGroup: null,
      venClass: null,
      taxCode: null,
      holdCode: null,
      distributionCode: null,
      accrualCode: null,
      paymentCode: null,
      cashCode: null,
      reason: '',
      dropdownReason: null,
      noNumberError: '',
      address1country: '',
      address1state: '',
      address1zip: '',
      address2country: '',
      address2state: '',
      address2zip: '',
      errors: {
        masterPHONE_NUM0: '',
        masterFAX_NUM0: '',
        contactPHONE_NMBR0: '',
        contactMOBILE_NUM0: '',
        contactFAX_NMBR0: '',
        contactPHONE_NMBR1: '',
        contactMOBILE_NUM1: '',
        contactFAX_NMBR1: '',
      },
      fileLoad: '',
      isLoading: false,
      tabValue: 0,
      viewLegal: false,
      isLoadingLegal: false,
      access: {},
      vendorName: '',
      whiteList: null,
    };
    this.dropDownChange = this.dropDownChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    VerifyAccess(
        (data) => {
          this.setState({ access: data.access });
        },
        [
          'vendor_location_edit',
          'vendor_location_view',
          'vendor_location_add',
          'vendor_location_delete',
          'vendor_approve',
          'vendor_edit',
          'vendor_deny',
          'vendor_legal_edit',
          'vendor_leagal_view',
          'vendor_contact_view',
          'vendor_contact_edit',
          'vendor_contact_add',
          'vendor_contact_delete',
          'comment_add',
          'history_view'
        ],
        this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.getData();
    this.getAllVendors();
  }

  getData = async() => {


    const info = await Promise.all([
      LambdaFetch(
        'get-vendor-forms',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          vendorId: window.location.pathname.split('details/')[1],
          clientId: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
        })
      ),
      LambdaFetch(
        'get-all-users',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        null
      ),
      LambdaFetch(
          'contract-settings',
          'post',
          this.props.fetchInitialData.credentials.user.jsonWebTok,
          JSON.stringify({
            jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
            parentType: 'vendor',
          }),
      )
    ]);

    let data = info[0].data;
    let users = info[1].data;
    const filteredUsers = users.users.filter((user) => {
      let userRole = JSON.parse(user.role);
      if (!userRole) return false
      return userRole.indexOf(2) === -1 && userRole.indexOf(3) === -1;
    });
    const notificationUsers = filteredUsers.map((u, index) => {
      return { title: u.username, index: index, uuid: u.uuid };
    });
    const termCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'termCode'
    );
    const invCurrencyDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'invCurrency'
    );
    const languageCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'languageCode'
    );
    const vendorGroupDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'vendorGroup'
    );
    const venClassDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'venClass'
    );
    const taxCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'taxCode'
    );
    const holdCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'holdCode'
    );
    const distributionCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'distributionCode'
    );
    const accrualCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'accrualCode'
    );
    const paymentCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'paymentCode'
    );
    const cashCodeDropdown = data.dropdowns.filter(
        (option) => option.R_KEY === 'cashCode'
    );
    let rejectReason = data.rejectReasons;
    rejectReason.push({ R_VALUE: 'other', DESCRIPTION: 'other' });
    const dropdownMenus = {
      vendorGroupDropdown: vendorGroupDropdown,
      venClassDropdown: venClassDropdown,
      taxCodeDropdown: taxCodeDropdown,
      holdCodeDropdown: holdCodeDropdown,
      distributionCodeDropdown: distributionCodeDropdown,
      accrualCodeDropdown: accrualCodeDropdown,
      paymentCodeDropdown: paymentCodeDropdown,
      cashCodeDropdown: cashCodeDropdown,
      termCodeDropdown: termCodeDropdown,
      invCurrencyDropdown: invCurrencyDropdown,
      languageCodeDropdown: languageCodeDropdown,
      rejectDropdown: rejectReason,
      whiteList: info[2].data.whiteList,
    };
    let reject;
    if (data.rejectReasons.length !== 0) {
      reject = data.rejectReasons[0].DESCRIPTION;
    } else {
      reject = 'other';
    }

    let address1country =
        data.address.length > 0 ? data.address[0].COUNTRY_CODE : '';
    let address1state =
        data.address.length > 0 ? data.address[0].STATE_PROV : '';
    let address1zip =
        data.address.length > 0 ? data.address[0].POSTAL_CODE : '';
    let address2country =
        data.address.length > 1 ? data.address[1].COUNTRY_CODE : '';
    let address2state =
        data.address.length > 1 ? data.address[1].STATE_PROV : '';
    let address2zip =
        data.address.length > 1 ? data.address[1].POSTAL_CODE : '';

    this.setState({
      content: data,
      recievedInfo: true,
      dropdowns: dropdownMenus,
      taxClassification: data.master[0].TAX_CLASS,
      termCode: data.master[0].TERM_CODE,
      invCurrency: data.master[0].INV_CURRENCY,
      languageCode: data.master[0].LANGUAGE_CODE,
      dropdownReason: reject,
      vendorGroup: data.master[0].VENDOR_GROUP,
      venClass: data.master[0].VEN_CLASS,
      taxCode: data.master[0].TAX_CODE,
      holdCode: data.master[0].HLD_CODE,
      distributionCode: data.master[0].DIST_CODE,
      accrualCode: data.master[0].ACCR_CODE,
      paymentCode: data.master[0].BANK_INST_CODE,
      cashCode: data.master[0].CASH_CODE,
      isLoading: false,
      attributes: data.attributes,
      contractUsers: users.users,
      notifications: data.notifications,
      notificationUsers: notificationUsers,
      notificationTypes: data.notification_types,
      documentTypes: data.document_types,
      isSaving: false,
      isOpen: false,
      address1country,
      address1state,
      address1zip,
      address2country,
      address2state,
      address2zip,
    });
   };

  dropDownChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  validate() {
    const { errors } = this.state;
    let isError = false;

    if (isError) {
      this.setState({
        errors: errors,
      });
    }
    return isError;
  }

  validatePhoneNumber(number) {
    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return numberPattern.test(number);
  }

  validateVNum(vendorNumber) {
    let isError = false;
    if (this.state.formButton === 'accept') {
      if (
          this.props.fetchInitialData.credentials.clientInfo
              .erp_vendor_auto_numbering === 0 &&
          vendorNumber === ''
      ) {
        isError = true;
        this.setState({
          noNumberError: 'Must fill in Vendor Number',
        });
      }
    }
    return isError;
  }

  getAllVendors = () => {
    fetch(`${process.env.REACT_APP_API}/get-all-vendor-forms`, {
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          if (data.success) {
            this.setState({
              allVendors: data.data.vendors,
            });
          } else {
            window.alert('There was an error fetching all your vendors');
          }
        })
        .catch((error) => console.log(error));
  }

  isVendorNameDuplicated = () => !!this.state.allVendors.filter(vendor => vendor.VENDOR_VNAME === this.state.vendorName).length;

  handleForm(event) {
    event.preventDefault();
    const form = event.target;
    const masterPairs = {};
    const addressPairs = [];
    const contactPairs = [];

    const err = this.validateVNum(form['autoVendorNumber'].value);

    if (this.state.formButton === 'accept') {
      const validateErrors = this.validate();
      if (this.state.formButton === 'accept' && validateErrors === true) {
        return null;
      }
    }
    if (!err) {
      masterPairs['VENDOR_ID'] = this.state.content.master[0].VENDOR_ID;

      for (let i = 0; i < apcVenMast.length; i++) {
        const masterId = apcVenMast[i]['fieldId'];
        masterPairs[masterId] = form['master' + masterId + 0].value;
      }

      masterPairs.FILTER_VALUE = event.target.clientFilter.value;
      masterPairs.TAX_CLASS = this.state.taxClassification;
      masterPairs.TERM_CODE = this.state.termCode;
      masterPairs.INV_CURRENCY = this.state.invCurrency;
      masterPairs.LANGUAGE_CODE = this.state.languageCode;
      masterPairs.VENDOR_GROUP = this.state.vendorGroup;
      masterPairs.VEN_CLASS = this.state.venClass;
      masterPairs.TAX_CODE = this.state.taxCode;
      masterPairs.HLD_CODE = this.state.holdCode;
      masterPairs.DIST_CODE = this.state.distributionCode;
      masterPairs.ACCR_CODE = this.state.accrualCode;
      masterPairs.BANK_INST_CODE = this.state.paymentCode;
      masterPairs.CASH_CODE = this.state.cashCode;
      masterPairs.AUTO_VENDOR_NUMBER = event.target.autoVendorNumber.value;

      const updatedFields = {
        master: masterPairs,
        address: addressPairs,
        contact: contactPairs,
      };

      this.setState({
        updatedFields: updatedFields,
        isOpen: true,
      });
    }
  }

  handleTaxChange(event) {
    this.setState({ taxClassification: event.target.value });
  }

  handleClickOpen() {
    this.setState({ isOpen: false });
  }

  handleChange(event) {
    this.setState({ reason: event.target.value });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleSave = async(saveFields) => {
    this.setState({ isLoading: true });
    if (this.state.formButton === 'save') {
      saveFields = this.state.updatedFields;
    }
    saveFields[
        'jsonWebTok'
        ] = this.props.fetchInitialData.credentials.user.jsonWebTok;

    const stringUpdate = JSON.stringify(saveFields);


    const action = await LambdaFetch(
      'save-apc',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      stringUpdate,
      'Successfully saved changes'
    )

    if(!action) return null

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
          .client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.state.content.master[0].VENDOR_ID,
      ACTIVITY_TYPE: 'SAVE',
      ACTIVITY_STRING: ' Saved updates',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    writeToActivity({ activity }, () => {
      this.getData();
      this.setState({ vendorNameError: false })
    });
  }

  handleAccept = async(save) => {
    if (this.isVendorNameDuplicated()) {
      this.setState({ vendorNameError: true })
      this.props.fetchInitialData.createSnack('Vendor name is duplicated!', 'warning', 2000);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    this.setState({
      isOpen: false,
      isLoading: true,
    });
    if (this.state.formButton === 'save') {
      this.handleSave();
      return null;
    } else {
      const update = Object.assign(this.state.updatedFields);

      if (save) {
        this.handleSave(update);
      }
      let fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        objectType: 'VENDOR',
        objectId: this.state.content.master[0].VENDOR_ID,
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.${process.env.REACT_APP_SITE_URL}/vendor`,
      };
      if (this.state.formButton === 'accept') {
        fetchData['action'] = 'approve';
        fetchData[
            'currentLevel'
            ] = this.state.content.master[0].APPROVAL_STATUS_LEVEL;
      } else if (this.state.formButton === 'deny') {
        fetchData['action'] = 'deny';
        if (this.state.dropdownReason === 'other') {
          fetchData['reason'] = this.state.reason;
        } else {
          fetchData['reason'] = this.state.dropdownReason;
        }
      }
      const fetchString = JSON.stringify(fetchData);

      const actionString = `Successfully ${this.state.formButton === 'accept' ? 'Approved' : 'Denied'} Vendor`

      const action = await LambdaFetch(
        'approve-deny-apc',
        'post',
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        fetchString,
        actionString
      )

      if(!action) {
        this.setState({ isLoading: true }, () => null )
      }
      this.setState({ isLoading: false });
      this.props.history.push('/allvendors#pending');
    }
  }

  approverTextField(input, index, fieldGroup, readOnly) {
    const { classes } = this.props;
    if (
        input.fieldId === 'PHONE_NMBR' ||
        input.fieldId === 'PHONE_NUM' ||
        input.fieldId === 'MOBILE_NUM' ||
        input.fieldId === 'FAX_NMBR' ||
        input.fieldId === 'FAX_NUM'
    ) {
      return (
          <InputMask
              key={input.fieldId}
              mask="999-999-9999"
              defaultValue={input.defaultValue}
          >
            {() => (
                <TextField
                    inputRef={this[fieldGroup + input.fieldId + index]}
                    key={fieldGroup + input.fieldId + index}
                    id={fieldGroup + input.fieldId + index}
                    label={input.fieldName}
                    fullWidth
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      startAdornment: (
                          <InputAdornment position="start">
                            <span/>
                          </InputAdornment>
                      ),
                    }}
                    error={
                      this.state.errors[fieldGroup + input.fieldId + index] !== ''
                    }
                    helperText={this.state.errors[fieldGroup + input.fieldId + index]}
                />
            )}
          </InputMask>
      );
    }
    if (readOnly) {
      return (
          <TextField
              key={input.fieldId}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              defaultValue={input.defaultValue}
              fullWidth
              disabled
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                    <InputAdornment position="start">
                      <span/>
                    </InputAdornment>
                ),
                readOnly: true,
              }}
          />
      );
    }
    if (index === null) index = '';
    if (input.half) {
      return (
          <span key={input.fieldId} style={{ marginRight: '5px' }}>
          <TextField
              key={input.fieldId}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              defaultValue={input.defaultValue}
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                    <InputAdornment position="start">
                      <span/>
                    </InputAdornment>
                ),
              }}
          />
        </span>
      );
    } else {
      return (
          <TextField
              key={input.fieldId}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              defaultValue={input.defaultValue}
              fullWidth
              variant="filled"
              onChange={(event) => {
                if (input.fieldId === 'VENDOR') {
                  this.setState({ vendorName: event.target.value })
                }
              }}
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                    <InputAdornment position="start">
                      <span/>
                    </InputAdornment>
                ),
              }}
          />
      );
    }
  }

  handleCountryStateZipChange = (type, value) => {
    this.setState({ [type]: value });
  };
  archive = (callback, type, id, name, isComplete) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      isComplete: isComplete ? isComplete : false,
      id: id,
    };
    fetch(`${process.env.REACT_APP_API}/update-status`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                  .client_id,
              OBJ_TYPE: 'VENDOR',
              OBJ_ID: this.state.content.master[0].VENDOR_ID,
              ACTIVITY_TYPE: 'ARCHIVED',
              ACTIVITY_STRING: `Archived ${type.charAt(0).toUpperCase() +
              type.slice(1)}: ${name}`,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            };
            if (type === 'notification') {
              activity.ACTIVITY_STRING = `Completed Task: ${name}`;
            }
            writeToActivity({ activity }, (status) => {
              if (status === 200) {
                this.setState({ isEditting: false }, () => this.getData());
                callback(200);
              } else {
                callback(400);
              }
            });
          }
        });
  };
  handleDeleteAttachment = (callback, attachment) => {
    fetch(`${process.env.REACT_APP_API}/delete-attachment`, {
      method: 'post',
      header: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        attachmentId: attachment.ATTACHMENT_ID,
      }),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                  .client_id,
              OBJ_TYPE: 'VENDOR',
              OBJ_ID: this.state.content.master[0].VENDOR_ID,
              ACTIVITY_TYPE: 'DELETE',
              ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} deleted ${attachment.ORIGINAL_NAME}`,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            };
            writeToActivity({ activity }, () => {
              this.getData();
              callback(200);
            });
          } else {
            this.getData();
            callback(400);
          }
        });
  };
  viewLegal = () => {
    this.setState({ isLoadingLegal: true });
    if (
        this.props.fetchInitialData.credentials.isViewVendor ||
        this.props.fetchInitialData.credentials.isEditVendor
    ) {
      const activity = {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: 'VIEWED',
        ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} viewed legal information`,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      };
      writeToActivity({ activity }, () => {
        this.setState({ viewLegal: true, isLoadingLegal: false });
      });
    } else {
      this.setState({ isLoadingLegal: true }, () => {
        this.props.fetchInitialData.createSnack(
            'You do not have credentials to view legal information',
            'warning',
            2000
        );
      });
    }
  };

  render() {
    const { classes } = this.props;
    if (!this.state.content.master) {
      return (
          <div style={{ height: '100vh' }}>
            <div style={{ position: 'relative', left: '50%', top: '40%' }}>
              <CircularProgress
                  style={{ color: 'rgb(232,102,19)' }}
                  disableShrink
              />
            </div>
          </div>
      );
    } else {
      const master = meta.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy['defaultValue'] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });
      const preparer = preparerInfo.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy['defaultValue'] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });
      let submissionDate = '';
      if (this.state.content.master[0]['SUBMISSION_DATE']) {
        submissionDate = moment(new Date(this.state.content.master[0]['SUBMISSION_DATE'])).format("MMMM Do YYYY, h:mm:ss a")
      }
      const dropdownDefaults = {
        vendorGroupDropdown: '',
        venClassDropdown: '',
        taxCodeDropdown: '',
        holdCodeDropdown: '',
        distributionCodeDropdown: '',
        accrualCodeDropdown: '',
        paymentCodeDropdown: '',
        cashCodeDropdown: '',
      };
      for (let property in dropdownDefaults) {
        if (dropdownDefaults.hasOwnProperty(property)) {
          if (
              !this.state.dropdowns[property] ||
              this.state.dropdowns[property].length === 0
          ) {
            dropdownDefaults[property] = '';
          }
        }
      }

      if (this.state.vendorGroup !== null) {
        dropdownDefaults.vendorGroupDropdown = this.state.vendorGroup;
      }
      if (this.state.venClass !== null) {
        dropdownDefaults.venClassDropdown = this.state.venClass;
      }
      if (this.state.taxCode !== null) {
        dropdownDefaults.taxCodeDropdown = this.state.taxCode;
      }
      if (this.state.holdCode !== null) {
        dropdownDefaults.holdCodeDropdown = this.state.holdCode;
      }
      if (this.state.distributionCode !== null) {
        dropdownDefaults.distributionCodeDropdown = this.state.distributionCode;
      }
      if (this.state.accrualCode !== null) {
        dropdownDefaults.accrualCodeDropdown = this.state.accrualCode;
      }
      if (this.state.paymentCode !== null) {
        dropdownDefaults.paymentCodeDropdown = this.state.paymentCode;
      }
      if (this.state.cashCode !== null) {
        dropdownDefaults.cashCodeDropdown = this.state.cashCode;
      }
      let message, saveAnd = 'Okay';

      if (this.state.formButton === 'save') {
        message = 'You are about to save the changes you have made';
      } else if (this.state.formButton === 'deny') {
        saveAnd = 'Save and Deny';
        message =
            "You are about to deny this vendor's application. Please give the reason why:";
      } else {
        saveAnd = 'Save and Approve';
        message =
            this.props.fetchInitialData.credentials.clientInfo
                .no_approval_levels ===
            this.state.content.master[0].APPROVAL_STATUS_LEVEL
                ? 'You are about to accept this vendor into your ERP system'
                : `You are about the accept this vendor to level ${this.state.content.master[0].APPROVAL_STATUS_LEVEL}`;
      }

      const dropdownReason = !(this.state.formButton === 'deny') ? (
          ''
      ) : (
          <DropDown
              id="dropdownReason"
              showWithDescription
              required
              default={this.state.dropdownReason}
              options={this.state.dropdowns.rejectDropdown}
              label="Rejection Reason"
              passUp={this.dropDownChange}
          />
      );
      const reasonMessage =
          this.state.dropdownReason === 'other' &&
          this.state.formButton !== 'save' ? (
              <div>
                <TextField
                    id="reason"
                    multiline
                    required
                    rows="3"
                    label="Please provide a reason"
                    fullWidth
                    onBlur={this.handleChange}
                    margin="normal"
                    variant="filled"
                />
              </div>
          ) : (
              ''
          );

      const showAutoNumbering =
          this.props.fetchInitialData.credentials.clientInfo
              .erp_vendor_auto_numbering === 0
              ? {}
              : { display: 'none' };

      const load = this.state.isLoading ? (
          <Fade in={this.state.isLoading} unmountOnExit>
            <CircularProgress
                style={{ marginLeft: '10px', color: '#E86613' }}
                size="20px"
            />
          </Fade>
      ) : (
          ''
      );
      return (
          <div>
            {this.state.viewLegal && (
                <ViewVendorLegal
                    access={this.state.access}
                    fetchInitialData={this.props.fetchInitialData}
                    jsonWebTok={
                      this.props.fetchInitialData.credentials.user.jsonWebTok
                    }
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    clientId={
                      this.props.fetchInitialData.credentials.clientInfo.client_id
                    }
                    user={this.props.fetchInitialData.credentials.user}
                    handleClose={() => {
                      this.setState({ viewLegal: false }, () => this.getData());
                    }}
                />
            )}
            <div>
              <Dialog
                  open={this.state.isOpen}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {message}
                  </DialogContentText>
                  {dropdownReason}
                  {reasonMessage}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose}>Back</Button>
                  <Button
                      onClick={() => this.handleAccept(true)}
                      autoFocus
                      variant="contained"
                      style={{ backgroundColor: '#E86613', color: '#FFFFFF' }}
                  >
                    {saveAnd}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div className="formVendor">
              <div style={{ position: 'relative' }}>
                <h2>{this.state.content.master[0]['VENDOR']}</h2>
                <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: '0px',
                      fontSize: '12px',
                    }}
                >
                  <div>
                    Status: {VendorStatus(this.state.content.master[0].VL_STATUS)}
                  </div>
                  <div>
                    Approval Level:{' '}
                    {this.state.content.master[0].APPROVAL_STATUS_LEVEL - 1}
                  </div>
                </div>
                <div style={{ fontSize: '10px' }}>
                  Submission Date: {submissionDate}
                </div>
              </div>
              <hr/>
              <form onSubmit={this.handleForm}>
                {/*{this.state.vendorNameError && (*/}
                {/*    <Alert severity="error">*/}
                {/*      Status: Rejected*/}
                {/*    </Alert>*/}
                {/*)}*/}
                <Grid container justify="flex-start" spacing={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">
                      {
                        this.props.fetchInitialData.credentials.clientInfo
                            .filter_value_label
                      }
                    </Typography>
                    <TextField
                        id="clientFilter"
                        variant="filled"
                        margin="dense"
                        defaultValue={this.state.content.master[0].FILTER_VALUE}
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <h5>Vendor Information</h5>
                    {master.map((input) => {
                      return this.approverTextField(
                          input,
                          0,
                          'master',
                          input.readOnly
                      );
                    })}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" style={{ paddingTop: '2%' }}>
                      Tax Classification
                    </Typography>

                    <FormControl margin="dense" component="fieldset">
                      <FormLabel component="legend"/>
                      <RadioGroup
                          aria-label="taxClassification"
                          name="taxClassification"
                          value={this.state.taxClassification}
                          onChange={this.handleTaxChange}
                      >
                        <FormControlLabel
                            value="Sole Proprietor"
                            control={
                              <Radio
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                  }}
                              />
                            }
                            label="Sole Proprietor"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Corporation"
                            control={
                              <Radio
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                  }}
                              />
                            }
                            label="Corporation"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="S-Corp"
                            control={
                              <Radio
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                  }}
                              />
                            }
                            label="S-Corp"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Partnership"
                            control={
                              <Radio
                                  classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                  }}
                              />
                            }
                            label="Partnership"
                            labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} sm={6} md={6}>
                    <h5>Invoice Information</h5>
                    <DropDown
                        id="termCode"
                        options={this.state.dropdowns.termCodeDropdown}
                        default={this.state.termCode}
                        label="Payment Terms"
                        passUp={this.dropDownChange}
                    />
                    <DropDown
                        id="invCurrency"
                        options={this.state.dropdowns.invCurrencyDropdown}
                        default={this.state.invCurrency}
                        label="Invoice Currency"
                        passUp={this.dropDownChange}
                    />
                    <DropDown
                        id="languageCode"
                        options={this.state.dropdowns.languageCodeDropdown}
                        default={this.state.languageCode}
                        label="Language"
                        passUp={this.dropDownChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="masterCOMMENT0"
                        label="Describe your service/products"
                        fullWidth
                        defaultValue={this.state.content.master[0]['COMMENT']}
                        multiline
                        rows="2"
                        variant="filled"
                        margin="dense"
                        InputProps={{
                          classes: {
                            input: classes.resize,
                          },
                        }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <h5>Prepaper's Information</h5>
                    {preparer.map((input) => {
                      return this.approverTextField(
                          input,
                          0,
                          'master',
                          input.readOnly
                      );
                    })}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <h5>For Approver Only</h5>
                    <div>
                      <div className="row">
                        <div className="col">
                          <DropDown
                              id="vendorGroup"
                              disabled={!this.state.access.vendor_approve}
                              showWithDescription
                              options={this.state.dropdowns.vendorGroupDropdown}
                              default={dropdownDefaults.vendorGroupDropdown}
                              label="Vendor Group"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="venClass"
                              disabled={!this.state.access.vendor_approve}
                              showWithDescription
                              options={this.state.dropdowns.venClassDropdown}
                              default={dropdownDefaults.venClassDropdown}
                              label="Vendor Class"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="taxCode"
                              disabled={!this.state.access.vendor_approve}
                              options={this.state.dropdowns.taxCodeDropdown}
                              default={dropdownDefaults.taxCodeDropdown}
                              label="Tax Code"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="holdCode"
                              disabled={!this.state.access.vendor_approve}
                              showWithDescription
                              options={this.state.dropdowns.holdCodeDropdown}
                              default={dropdownDefaults.holdCodeDropdown}
                              label="Hold Code"
                              passUp={this.dropDownChange}
                          />
                        </div>

                        <div className="col">
                          <DropDown
                              id="distributionCode"
                              disabled={!this.state.access.vendor_approve}
                              showWithDescription
                              options={
                                this.state.dropdowns.distributionCodeDropdown
                              }
                              default={dropdownDefaults.distributionCodeDropdown}
                              label="Distribution Code"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="accrualCode"
                              disabled={!this.state.access.vendor_approve}
                              options={this.state.dropdowns.accrualCodeDropdown}
                              default={dropdownDefaults.accrualCodeDropdown}
                              label="Accrual Code"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="paymentCode"
                              disabled={!this.state.access.vendor_approve}
                              showWithDescription
                              options={this.state.dropdowns.paymentCodeDropdown}
                              default={dropdownDefaults.paymentCodeDropdown}
                              label="Payment Code"
                              passUp={this.dropDownChange}
                          />
                          <DropDown
                              id="cashCode"
                              disabled={!this.state.access.vendor_approve}
                              options={this.state.dropdowns.cashCodeDropdown}
                              default={dropdownDefaults.cashCodeDropdown}
                              label="Cash Code"
                              passUp={this.dropDownChange}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid style={showAutoNumbering} item xs={6} sm={6} md={6}>
                    <TextField
                        id="autoVendorNumber"
                        label="ERP Vendor Number"
                        fullWidth
                        defaultValue={
                          this.state.content.master[0].ERP_VENDOR_NUMBER
                        }
                        margin="dense"
                        variant="filled"
                        error={this.state.noNumberError !== ''}
                        helperText={this.state.noNumberError}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                  <span>
                  {!!this.state.access.vendor_edit && (
                      <Button
                          variant="contained"
                          id="save"
                          type="submit"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              formButton: 'save',
                              noNumberError: '',
                            });
                          }}
                          style={{
                            color: 'white',
                            marginRight: '0.5rem',
                          }}
                      >
                        Save
                      </Button>
                  )}
                  </span>
                    <span>
                  {!!this.state.access.vendor_deny && (
                      <Button
                          variant="contained"
                          id="deny"
                          type="submit"
                          onClick={() => {
                            this.setState({
                              formButton: 'deny',
                              noNumberError: '',
                            });
                          }}
                          style={{
                            backgroundColor: '#656565',
                            color: 'white',
                            marginRight: '0.5rem',
                          }}
                      >
                        Deny
                      </Button>
                  )}
                  </span>
                    <span>
                  {!!this.state.access.vendor_approve && (
                      <Button
                          variant="contained"
                          id="accept"
                          type="submit"
                          onClick={() => {
                            this.setState({
                              formButton: 'accept',
                              noNumberError: '',
                            });
                          }}
                          style={{
                            backgroundColor: '#E86613',
                            color: 'white',
                            marginRight: '0.5rem',
                          }}
                      >
                        Accept
                      </Button>
                  )}
                      {!!(this.state.access.vendor_legal_edit ||
                          this.state.vendor_legal_view) && (
                          <Button
                              variant="outlined"
                              disabled={this.state.isLoadingLegal}
                              onClick={() => this.viewLegal()}
                          >
                            {this.state.isLoadingLegal ? (
                                <div>
                                  <CircularProgress
                                      size={24}
                                      className={classes.buttonProgress}
                                  />
                                  View/Edit Legal Information
                                </div>
                            ) : (
                                'View/Edit Legal Information'
                            )}
                          </Button>
                      )}
                  </span>
                    {load}
                  </Grid>
                </Grid>
              </form>

              <div style={{ marginTop: '1rem' }}>
                <ClientLocationMaintenance
                    getData={this.getData}
                    access={this.state.access}
                    address={this.state.content.address}
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    jsonWebTok={
                      this.props.fetchInitialData.credentials.user.jsonWebTok
                    }
                    fetchInitialData={this.props.fetchInitialData}
                />
              </div>

              <div style={{ marginTop: '1rem' }}>
                <ClientContactMaintenance
                    getData={this.getData}
                    access={this.state.access}
                    contacts={this.state.content.contact}
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    jsonWebTok={
                      this.props.fetchInitialData.credentials.user.jsonWebTok
                    }
                    fetchInitialData={this.props.fetchInitialData}
                />
              </div>

              <AttachAttribTask
                  props={this.props}
                  getData={this.getData}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  clientId={
                    this.props.fetchInitialData.credentials.clientInfo.client_id
                  }
                  currentUser={this.props.fetchInitialData.credentials.user.uuid}
                  parentType="vendor"
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  parentId={this.state.content.master[0].VENDOR_ID}
                  whiteList={this.state.whiteList}
              />
              {!!this.state.access.comment_add && (
                  <Comments
                      fetchInitialData={this.props.fetchInitialData}
                      objectId={window.location.pathname.split('details/')[1]}
                      objectType="VENDOR"
                  />
              )}
              {!!this.state.access.history_view && (
                  <Activity
                      title="Vendor Account History"
                      activity={this.state.content.activity}
                      timezone={this.props.fetchInitialData.credentials.user.timezone}
                      appWidth={this.props.fetchInitialData.credentials.appWidth}
                  />
              )}
            </div>
          </div>
      );
    }
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Details);

const meta = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID (read only)',
    readOnly: true,
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  // {
  //   fieldId: "TAX_ID",
  //   fieldName: "EIN / SSN (read only)",
  //   readOnly: true,
  // },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
];
const preparerInfo = [
  {
    fieldId: 'PREPARER_NAME',
    fieldName: "Preparer's Name (read only)",
    readOnly: true,
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
    readOnly: true,
  },
];
const apcVenMast = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID',
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  // {
  //   fieldId: "TAX_ID",
  //   fieldName: "EIN / SSN",
  // },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
  // {
  //   fieldId: "BANK_ENTITY",
  //   fieldName: "Routing Number",
  // },
  // {
  //   fieldId: "VBANK_ACCT_NO",
  //   fieldName: "Bank Account Number",
  // },
  // {
  //   fieldId: "VBANK_ACCT_TP",
  //   fieldName: "Account Type",
  // },

  {
    fieldId: 'COMMENT',
    fieldName: 'Comment',
  },

  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
  },
];
