import React from "react";
import { Grid, TextField, withStyles, CircularProgress, MenuItem } from "@material-ui/core";

import GooglePlacesAutocompleteComponent from "./common/GooglePlacesAutocomplete";
import { USAoptions } from "../utils/constants/addressOptions";

const styles = () => ({
  resize: {
    fontSize: 14,
  },
  textFieldRoot: {
    height: '40px',
  },
  spinner: {
    marginLeft: '10px',
    color: '#545454',
    position: 'relative',
  }
});

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: '',
      state: '',
      zip: '',
      addr1: '',
      addr2: '',
      city: '',
      loading: '',
      addressWithoutZip: false,
      isEdit: this.props.isEdit,
      isRenewal: false,
    };
  }

  getZipCode = (value) => {
    this.setState({ loading: true });
    fetch(
        `${process.env.REACT_APP_API}/google-places-detail`,
        {
          method: "POST",
          headers: { "content-type": "application/json", },
          body: JSON.stringify({ placeId: value.place_id }),
        }
    )
        .then((resp) => resp.json())
        .then((res) => {
          const zip = res.data.parsed.zip ||
              value.structured_formatting.secondary_text.split(',')[1].trim().split(' ')[1] || '';
          const city = res.data.parsed.city || value.structured_formatting.secondary_text.split(',')[0].trim();
          const state = res.data.parsed.state || value.structured_formatting.secondary_text.split(',')[1].slice(0, 3).trim();
          const addr1 = value.structured_formatting.main_text;
          const country = value.terms[value.terms.length - 1].value;
          const updatedValue = {
            addr1, country, city, state, zip
          }
          this.setState({
            ...updatedValue,
            loading: false,
            addressWithoutZip: !res.data.parsed.zip,
          })
          if (this.props.isCreateVendor) {
            this.setState({}, () => this.props.updateAddress(this.props.type, updatedValue));
          }
        });
  };

  clearStates = () => {
    this.setState({
      zip: '', city: '', state: '', country: ''
    })
  }

  clearAddr = () => {
    this.setState({ addr1: '' })
  }


  setAddressInputValue = (stateName, newValue) => {
    this.setState({ [stateName]: newValue.description })
    this.getZipCode(newValue);
    if (this.state.isEdit) {
      this.setState({ isRenewal: true })
    }
  }

  render() {
    const { classes, editValues } = this.props;
    return (
        <>
          <Grid item xs={12} sm={12} md={12} style={{ position: 'relative' }}>
            <GooglePlacesAutocompleteComponent
                isEdit={this.state.isEdit}
                clearStates={this.clearStates}
                clearAddr={this.clearAddr}
                iconMargin={17}
                initialValue={this.state.isEdit ? editValues.ADDR1 : this.state.addr1}
                id={`${(this.props.isCreateVendor ? (this.props.type + '1') : (this.state.isEdit ? 'editAddr1' : 'addr1'))}`}
                stateName={'addr1'}
                setter={this.setAddressInputValue}
                types={['address']}
                label={'Addr1'}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: 'center' }}>
            {this.state.loading && (
                <CircularProgress size="20px" color={'inherit'} className={classes.spinner}/>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
                id={`${(this.props.isCreateVendor ? (this.props.type + '2') : (this.state.isEdit ? 'editAddr2' : 'addr2'))}`}
                fullWidth
                label="Addr2"
                defaultValue={this.state.isEdit ? editValues.ADDR2 : ''}
                variant="filled"
                margin="dense"
                InputProps={{
                  classes: {
                    input: classes.resize,
                    root: classes.textFieldRoot,
                  },
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
                id={`${(this.props.isCreateVendor ? (this.props.type + 'City') : (this.state.isEdit ? 'editCity' : 'city'))}`}
                fullWidth
                label="City"
                variant="filled"
                value={(!this.state.isEdit || this.state.isRenewal) ? this.state.city : undefined}
                defaultValue={this.state.isEdit ? editValues.CITY_ADDR5 : undefined}
                autoComplete={'off'}
                margin="dense"
                InputProps={{
                  classes: {
                    input: classes.resize,
                    root: classes.textFieldRoot,
                  },
                }}
                onChange={(event) =>
                    this.setState({ city: event.target.value })}

            />
          </Grid>
          <Grid container spacing={3}
                style={{ marginTop: 0 }}
          >
            <Grid item xs>
              <TextField
                  id={`${(this.props.isCreateVendor ? (this.props.type + 'Zip') : (this.state.isEdit ? 'editZip' : 'zip'))}`}
                  label="Zip"
                  style={{ marginTop: 0 }}
                  value={(!this.state.isEdit || this.state.isRenewal) ? this.state.zip : undefined}
                  defaultValue={this.state.isEdit ? editValues.POSTAL_CODE : undefined}
                  margin="dense"
                  variant="filled"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                      root: classes.textFieldRoot,
                    },
                  }}
                  onChange={(event) =>
                      this.setState({ zip: event.target.value })}
              />
            </Grid>
            <Grid item xs>
              <TextField
                  id={`${(this.props.isCreateVendor ? (this.props.type + 'State') : (this.state.isEdit ? 'editState' : 'state'))}`}
                  label="State"
                  style={{ marginTop: 0, width: '100%' }}
                  value={(!this.state.isEdit || this.state.isRenewal) ? this.state.state : undefined}
                  defaultValue={this.state.isEdit ? editValues.STATE_PROV : undefined}
                  margin="dense"
                  variant="filled"
                  autoComplete="off"
                  InputProps={{
                    id: `${(this.props.isCreateVendor ? (this.props.type + 'State') : (this.state.isEdit ? 'editState' : 'state'))}`,
                    classes: {
                      input: classes.resize,
                      root: classes.textFieldRoot,
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true
                  }}
                  select={this.state.isEdit && !this.state.isRenewal}
                  onChange={(event) =>
                      this.setState({ state: event.target.value })}
              >
                {USAoptions.map((state, index) => (
                    <MenuItem key={index} value={state.value}>{state.value}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                  id={`${(this.props.isCreateVendor ? (this.props.type + 'Country') : (this.state.isEdit ? 'editCountry' : 'country'))}`}
                  label="Country"
                  style={{ marginTop: 0 }}
                  value={(!this.state.isEdit || this.state.isRenewal) ? this.state.country : undefined}
                  defaultValue={this.state.isEdit ? editValues.COUNTRY_CODE : undefined}
                  margin="dense"
                  variant="filled"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                      root: classes.textFieldRoot,
                    },
                  }}
                  onChange={(event) =>
                      this.setState({ country: event.target.value })}
              />
            </Grid>
          </Grid>
        </>
    );
  }
}

export default withStyles(styles)(AddressForm);
