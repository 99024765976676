import React from 'react'
import {CircularProgress} from '@material-ui/core';

export default function LoadingCircle(){
    return (
    <div style={{ height: '100vh' }}>
            <div style={{ position: 'relative', left: '50%', top: '40%', width: '60px' }}>
              <CircularProgress
                  className="vl-primary-text-color"
                  disableShrink
              />
            </div>
          </div>
    )
}