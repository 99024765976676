import React, { Component } from 'react';
import './Home.css';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { withStyles, Button, TextField, Fade, CircularProgress, InputAdornment } from '@material-ui/core';
import LoadingButton from '../components/LoadingButton';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			isLoading: false,
			passwordLengthError: '',
		};
		this.submitEmail = this.submitEmail.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
	}

	componentDidMount() {
		const id = window.location.href.split('ID=')[1];
		if (id) {
			const fetchData = {
				check: true,
				id: id,
			};
			fetch(`${process.env.REACT_APP_API}/forgot-password`, {
				method: 'post',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify(fetchData),
			})
				.then((resp) => resp.json())
				.then((data) => {
					if (data.data.statusCode === 200) {
						this.setState({ id: id });
					} else {
					}
				})
				.catch((err) => console.log(err));
		}
	}

	submitEmail(event) {
		event.preventDefault();
		this.setState({ isLoading: true });
		const fetchData = {
			email: event.target.email.value,
			subdomain: this.props.fetchInitialData.credentials.clientInfo.subdomain,
			clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
		};
		fetch(`${process.env.REACT_APP_API}/forgot-password`, {
			method: 'post',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(fetchData),
		})
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({ isLoading: false });
				if (data.error) {
					this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
				} else if (data.success) {
					this.props.fetchInitialData.createSnack('Please check your email address', 'success', 3000);
				} else {
					this.props.fetchInitialData.createSnack('There was an error', 'error', 3000);
				}
			})
			.catch((err) => console.log(err));
	}

	updatePassword(event) {
		event.preventDefault();
		this.setState({ isLoading: true });
		var updatedPassword = event.target.password.value;
		if (event.target.password.value.length < 6) {
			const passwordLengthError = 'Password must be atleast 6 characters';
			this.setState({
				passwordLengthError,
				isLoading: false,
			});
			return null;
		} else {
			const fetchData = {
				update: true,
				id: this.state.id,
				password: updatedPassword.toString(),
				clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
			};
			fetch(`${process.env.REACT_APP_API}/forgot-password`, {
				method: 'post',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify(fetchData),
			})
				.then((resp) => resp.json())
				.then((data) => {
					this.setState({ isLoading: false, passwordLengthError: '' });
					if (data.error) {
						this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
					} else if (data.success) {
						this.props.fetchInitialData.createSnack('Successfully changed password', 'success', 3000);
						this.props.history.push('/');
					} else {
						this.props.fetchInitialData.createSnack('There was an error', 'error', 3000);
					}
				})
				.catch((err) => console.log(err));
		}
	}

	render() {
		const { classes } = this.props;
		const { id, passwordLengthError } = this.state;
		if (id) {
			return (
				<div className="homeCard">
					<div className="forgotBox">
						<h3 style={{ textAlign: 'center' }}>Reset Password From</h3>
						<hr />
						<form onSubmit={this.updatePassword}>
							<div style={{ textAlign: 'center' }}>Please enter an updated password</div>
							<TextField
								className="passwordfield"
								id="password"
								required
								type="password"
								label="Password"
								margin="normal"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Lock />
										</InputAdornment>
									),
									classes: { notchedOutline: classes.outline },
								}}
								error={passwordLengthError !== ''}
								helperText={passwordLengthError}
							/>

							<LoadingButton
								label="Update Password"
								className={classes.loginButton}
								isLoading={this.state.isLoading}
								color="primaryVLButton"
								buttonType="submit"
							/>
						</form>
					</div>
				</div>
			);
		} else {
			return (
				<div className="homeCard">
					<div className="forgotBox">
						<h3 style={{ textAlign: 'center' }}>Reset Password From</h3>
						<hr />

						<div style={{ textAlign: 'center' }}>
							Please enter your email address. You will recieve a link to create a new password via email.
						</div>
						<form onSubmit={this.submitEmail}>
							<TextField
								className="loginInput"
								id="email"
								fullWidth
								label="Email"
								required
								margin="normal"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccountCircle />
										</InputAdornment>
									),
									classes: { notchedOutline: classes.outline },
								}}
							/>
							<div style={{ height: '8px' }} />

							<LoadingButton
								label="Submit"
								className={classes.loginButton}
								isLoading={this.state.isLoading}
								color="primaryVLButton"
								buttonType="submit"
							/>
						</form>
					</div>
				</div>
			);
		}
	}
}

const styles = {
	outline: {
		fontSize: '1rem',
		border: 'solid black 1px',
		borderRadius: '100px',
	},
	label: {
		textTransform: 'capitalize',
	},
	loginButton: {
		height: 50,
		width: '100%',
		borderRadius: '100px !important',
		marginTop: 8,
		color: 'white !important',
	},
	buttonLabel: {
		textDecoration: 'none',
	},
};

ForgotPassword.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPassword);
