import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';

import { contractsColumns } from '../utils/constants/contractsColumns';
import PrintIcon from '@material-ui/icons/Print';
import PdfTableDialog from './pdf/PdfTableDialog';
import { responsive } from '../utils/constants/muidatatablesConsts';
import { customTableSort, formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = {
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    },
  },
};

class AllContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: Date.now(),
      endDate: Date.now(),
      tableData: null,
      isLoading: true,
      createNewContract: false,
      currentContracts: null,
      contracts: null,
      archived: false,
      allVendors: null,
      classification1Header: '',
      classification1Values: null,
      helperTextId: null,
      openPrintDialog: false,
    };
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_API}/get-contracts`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            const { contracts, classification1Header, classification1Values, contractTypes, vendors } = info.data;
            const activeContracts = contracts.filter(
                (contract) => parseInt(contract.status) === 1
            );
            if (classification1Header.length !== 0) {
              contractsColumns[3].label = classification1Header[0].classification_type_name
            }
            const tableData = this.makeTableData(activeContracts, classification1Header);
            this.setState({
              tableData,
              currentContracts: activeContracts,
              contracts,
              isLoading: false,
              contractTypes,
              allVendors: vendors,
              classification1Header,
              classification1Values,
            });
          } else {
            this.setState({ isLoading: false },
                () => this.createSnack(
                    'There was an error loading your contracts',
                    'error',
                    2000
                ));
          }
        });
  };

  getRemainingDays = (endDate) => {
    if (!new Date(endDate).getTime()) {
      return 'N/A';
    }
    const daysLeft = Math.floor((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    return daysLeft > 0 ? daysLeft : 0
  }
  makeTableData = (data, classification) => {
    return data.map((contract) => {

      const row = [
        contract.VENDOR_VNAME,
        <span
            className="editLink"
            onClick={() => this.props.props.history.push(`/contract-details/${contract.contract_id}`)}>
          {contract.contract_number}
        </span>,
        contract.title,
        contract.classification_value,
        moment(contract.start_date).format('MM/DD/YYYY'),
        (!!new Date(contract.end_date).getTime() ? moment(contract.end_date).format('MM/DD/YYYY') : 'N/A'),
        this.getRemainingDays(contract.end_date),
        formatter.format(contract.contract_amount),
      ];

      if (classification.length === 0) {
        row.splice(2, 1);
      }
      return row;
    });
  };

  viewArchived = () => {
    const currentContracts = this.state.contracts
        .filter((doc) => parseInt(doc.status) === (this.state.archived ? 1 : 2));
    const tableData = this.makeTableData(currentContracts, this.state.classification1Header);
    this.setState({
      currentContracts,
      tableData,
      archived: !this.state.archived,
      isLoading: false,
    });
  };

  render() {
    const { classes } = this.props;
    if (this.state.isLoading) {
      return (
          <LoadingCircle/>
      );
    } else {
      let viewNum = this.state.contracts.length - this.state.tableData.length;
      return (
          <div>
            <div className="tableCard">
              <MUIDataTable
                  title={this.state.archived ? 'Archived Contracts' : 'Active Contracts'}
                  data={this.state.tableData}
                  columns={contractsColumns}
                  options={{
                    customSort: (data, colIndex, order) => customTableSort(data, colIndex, order, 7),
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                    print: false,
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        tableData: this.makeTableData(this.state.currentContracts.filter((contract) => {
                          if (query) {
                            return (
                                contract.contract_number
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                contract.VENDOR_VNAME
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                contract.title
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                contract.classification_value
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                formatter.format(contract.contract_amount)
                                    .toString()
                                    .includes(query.toLowerCase().trim()) ||
                                contract.contract_amount.toString()
                                    .includes(query.toLowerCase().trim()) ||
                                moment(contract.start_date).format('MM/DD/YYYY')
                                    .toString()
                                    .includes(query.toLowerCase().trim()) ||
                                (!!new Date(contract.end_date).getTime() ? moment(contract.end_date).format('MM/DD/YYYY') : 'N/A')
                                    .toString().toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                this.getRemainingDays(contract.end_date)
                                    .toString().toLowerCase()
                                    .includes(query.toLowerCase().trim())
                            );
                          }
                          return contract;
                        }), this.state.classification1Header)
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        tableData: this.makeTableData(this.state.currentContracts, this.state.classification1Header)
                      });
                    },
                  }}
              />
            </div>
            <PdfTableDialog
                isLarge
                open={this.state.openPrintDialog}
                fileName={this.state.archived ? 'archivedContracts' : 'activeContracts'}
                columns={contractsColumns}
                data={this.state.tableData}
                onClose={() => this.setState({ openPrintDialog: false })}
                title={this.state.archived ? 'Archived Contracts' : 'Active Contracts'}
            />
            <div style={{ margin: '1rem 0', position: 'relative' }}>
              <Button
                  color={this.state.archived ? 'primary' : 'secondary'}
                  variant="outlined"
                  onClick={() => this.viewArchived()}
              >
                {this.state.archived ? `View Active (${viewNum})` : `View Archived (${viewNum})`}
              </Button>
              <Button
                  style={{marginLeft: 10}}
                  className="primaryVLButton"
                  onClick={() => this.setState({ openPrintDialog: true })}
              >
                PDF
                <PrintIcon style={{ marginLeft: 10 }}/>
              </Button>
            </div>
          </div>
      );
    }
  }
}

export default withStyles(styles)(withSnackbar(AllContracts));
