import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationUsers from './NotificationUsers';

export default function ObjectNotifications({
  jsonWebTok,
  parentId,
  parentType,
  createSnack,
  isVendor,
  vendorId,
}) {
  let types = ['client', 'vendor'];
  if (isVendor) {
    types = ['vendor'];
  }
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Subscribers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {types.map((type) => (
              <Grid key={type} item xs={12} sm={12} md={12}>
                <NotificationUsers
                  userType={type}
                  createSnack={createSnack}
                  jsonWebTok={jsonWebTok}
                  parentId={parentId}
                  parentType={parentType}
                  vendorId={vendorId}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
