import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment, MenuItem,
  TextField
} from "@material-ui/core";
import { formatter } from "../../utils/tableCustoms";
import { NumberFormatCustom } from "../../utils/numberFormatUtils";

const inputProps = {
  startAdornment: (
      <InputAdornment position="start">
        <div/>
      </InputAdornment>
  ),
};

class EditInvoiceDetailsLineDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uom: this.props.editLine.LINE_UOM,
      qty: this.props.editLine.LINE_QTY,
      total: this.props.editLine.LINE_TRAN_AMOUNT,
      unitCost: this.props.editLine.LINE_UNIT_COST,
      description: this.props.editLine.LINE_DESC,
    }
  }

  closeDialog = () => {
    this.props.onClose();
    this.setState({
      uom: '',
      qty: '',
      total: '',
      unitCost: '',
      description: '',
    })
  }

  render() {
    return (
        <Dialog open={this.props.open} fullWidth maxWidth={'md'}
                onClose={this.closeDialog}>
          <DialogTitle id="alert-dialog-title">Edit Item</DialogTitle>
          <DialogContent>
            <Grid container justify="flex-start" spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    id="lineDescription"
                    value={this.state.description}
                    onChange={(e) => this.setState({ description: e.target.value })}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <div/>
                          </InputAdornment>
                      ),
                    }}
                />
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    id={'uom'}
                    select
                    style={{
                      width: '15ch',
                      marginTop: '0.5rem',
                      marginBottom: '2px',
                    }}
                    label={'Unit of Measure'}
                    fullWidth
                    value={this.state.uom}
                    onChange={(e) => this.setState({ uom: e.target.value })}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <span/>
                          </InputAdornment>
                      ),
                    }}
                >
                  {this.props.uomList && this.props.uomList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.description}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    id={'LINE_UNIT_COST'}
                    label={'Rate'}
                    margin="dense"
                    value={this.state.unitCost}
                    onChange={(e) => this.setState({ unitCost: e.target.value })}
                    InputProps={{ ...inputProps, inputComponent: NumberFormatCustom }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    id="LINE_QTY"
                    label="Qty"
                    margin="dense"
                    type="number"
                    value={this.state.qty}
                    onChange={(e) =>
                        this.setState({ qty: e.target.value })
                    }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    id="LINE_TRAN_AMOUNT"
                    label="Total"
                    margin="dense"
                    value={ this.state.qty && this.state.unitCost
                        ? formatter.format(
                            this.state.qty * this.state.unitCost
                        )
                        : 0}
                    InputProps={inputProps}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={this.closeDialog}
                style={{
                  color: '#545454',
                }}
                variant="text"
            >
              Cancel
            </Button>
            <Button
                onClick={this.props.handleDelete}
                color="secondary"
                variant="text"
            >
              Delete
            </Button>
            <Button
                type="button"
                className="primaryVLButton"
                variant="contained"
                onClick={() => {
                  this.props.onSubmit(this.state.description, this.state.uom, this.state.unitCost, this.state.qty);
                  this.closeDialog()
                }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

    )
  }
}

export default EditInvoiceDetailsLineDialog;
