import React  from "react";
import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { invoiceDetailsColumns } from "../../utils/constants/invoiceDistDetailsConstants";
import { formatter } from "../../utils/tableCustoms";
import AddInvoiceDetailsLineDialog from "./AddInvoiceDetailsLineDialog";
import EditInvoiceDetailsLineDialog from "./EditInvoiceDetailsLineDialog";

const styles = (theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#E86613',
    '&:hover': {
      backgroundColor: '#E86613',
      color: 'white',
    },
  },
  editLink: {
    color: 'rgb(0, 104, 178)',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
})

class InvoiceDetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddLine: false,
      isEditLine: false,
      rows: this.props.apcinvdtl,
      isLoading: false,
      editLine: null,
      subTotal: this.props.apcinvdtl.reduce((acc, curr) => acc + curr.LINE_TRAN_AMOUNT, 0)
    }
  }

  addToInvoice = (description, uom, unitCost, qty) => {
    this.setState({ isLoading: true });
    const rows = this.state.rows;
    const subTotal = this.state.subTotal + unitCost * qty;
    const newRow = {
      LINE_NUM: rows.length + 1,
      LINE_DESC: description,
      LINE_UOM: uom,
      LINE_UNIT_COST: unitCost,
      LINE_QTY: qty,
      LINE_TRAN_AMOUNT: unitCost * qty,
    };
    rows.push(newRow);
    this.setState({ rows, isAddLine: false, isLoading: false, subTotal });
  };

  editInvoiceRow = (description, uom, unitCost, qty) => {
    this.setState({ isLoading: true });
    const subTotal = this.state.rows.reduce((acc, curr) => acc + curr.LINE_TRAN_AMOUNT, 0) - this.state.editLine.LINE_TRAN_AMOUNT + unitCost * qty;

    const copyOfEditRow = this.state.editLine;
    copyOfEditRow.LINE_DESC = description;
    copyOfEditRow.LINE_UOM = uom;
    copyOfEditRow.LINE_UNIT_COST = unitCost;
    copyOfEditRow.LINE_QTY = qty;
    copyOfEditRow.LINE_TRAN_AMOUNT = unitCost * qty;

    const updatedRows = this.state.rows.map((row) => (row.LINE_NUM === copyOfEditRow.LINE_NUM) ? copyOfEditRow : row);
    this.setState({ rows: updatedRows, isEditLine: false, isLoading: false, subTotal, editLine: null });
  };

  handleDeleteRow = () => {
    const updatedRows = this.state.rows.filter((row) => row.LINE_NUM !== this.state.editLine.LINE_NUM);
    const subTotal = this.state.subTotal - this.state.editLine.LINE_TRAN_AMOUNT
    this.setState({
      rows: updatedRows, isEditLine: false, editLine: null, isLoading: false, subTotal
    });
  };

  render() {
    const { classes } = this.props;
    return (
        <div style={{ margin: '1rem 0rem' }}>
          <AddInvoiceDetailsLineDialog
              open={this.state.isAddLine}
              onClose={() => this.setState({ isAddLine: false })}
              onSubmit={this.addToInvoice}
              uomList={this.props.uomList}/>
          {this.state.editLine && (
              <EditInvoiceDetailsLineDialog
                  open={this.state.isEditLine} editLine={this.state.editLine}
                  onClose={() => this.setState({ isEditLine: false, editLine: null })}
                  onSubmit={this.editInvoiceRow} handleDelete={this.handleDeleteRow}
                  uomList={this.props.uomList}/>
          )}

          <h4>Invoice Details</h4>
          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {invoiceDetailsColumns.map((col, index) => (
                      <TableCell key={index} align="center">
                        {col.label}
                      </TableCell>
                  ))}
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{row.LINE_DESC}</TableCell>
                      <TableCell align="center">{row.LINE_UOM}</TableCell>
                      <TableCell align="center">
                        {formatter.format(row.LINE_UNIT_COST)}
                      </TableCell>
                      <TableCell align="center">{row.LINE_QTY}</TableCell>
                      <TableCell align="center">
                        {formatter.format(row.LINE_TRAN_AMOUNT)}
                      </TableCell>
                      <TableCell
                          onClick={() => {
                            this.setState({ isEditLine: true, editLine: row })
                          }}
                          align="center"
                      >
                        <Typography>
                          <span className={classes.editLink}>edit </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={5}/>
                  <TableCell colSpan={1} alight="center" style={{ textAlign: 'center' }}>
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{formatter.format(this.state.subTotal)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div style={{ marginLeft: '0.7rem' }}>
              <Fab
                  size="small"
                  aria-label="Add"
                  style={{margin: '0.5rem'}}
                  className="primaryVLButton"
                  onClick={() => this.setState({ isAddLine: true })}
              >
                +
              </Fab>
            </div>
          </Paper>
        </div>
    )
  }
}

export default withStyles(styles)(InvoiceDetailsTable)
