import React, { Component, Fragment } from 'react';
import './App.css';
import Routes from './Routes';
import './containers/NotFound.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MainTabs from './components/MainTabs';
import FooterLinks from './components/FooterLinks';
import VendorTabs from './components/VendorTabs';
import { withSnackbar } from 'notistack';

const styles = () => ({
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  badge: {
    backgroundColor: '#E86613',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryAppColor: "#0E92AB",
      appWidth: 1000,
      clientInfo: '',
      right: false,
      vendorFormNotifications: null,
      vendorInvoiceNotifications: null,
      vendorCodeNotifications: null,
      vendorPurchaseNotifications: null,
      vendorReadyPrs: null,
      user: {
        uuid: '' || localStorage.uuid,
        username: '' || localStorage.username,
        role: '' || localStorage.role,
        clientId: '' || localStorage.clientId,
        name: '' || localStorage.name,
        vendorId: null || localStorage.vendorId,
        jsonWebTok: '' || localStorage.jsonWebTok,
        approver_level: null || localStorage.approver_level,
        timezone: null || localStorage.timezone,
        avatarContent: '' || localStorage.avatarContent,
        avatarPrimaryColor: '' || localStorage.avatarPrimaryColor,
        contractClassifications: null || localStorage.contractClassifications
      },
      isVendor: false || localStorage.isVendor ? JSON.parse(localStorage.isVendor) : false,
      isClient: false || localStorage.isClient ? JSON.parse(localStorage.isClient) : false,
      previousPage: '',
    };
    this.signout = this.signout.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.changeClientInfo = this.changeClientInfo.bind(this);
    this.changeUserInfo = this.changeUserInfo.bind(this);
    this.setRoles = this.setRoles.bind(this);
    this.getNotificationCount = this.getNotificationCount.bind(this);
  }
  createSnack = (message, type, duration) => {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  };
  setAppColor = (color) => {
    const root = document.documentElement;
    const appCol = color ? color : '#E86613'
    root.style.setProperty('--primary-color', appCol);
    this.setState({primaryAppColor:appCol})
  }
  componentDidMount() {

    var pathSnippet = window.location.host.split('.')[0];
    const client = {
      subdomain: pathSnippet,
    };
    const jsonClient = JSON.stringify(client);
    fetch(`${process.env.REACT_APP_API}/get-client`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
      },
      body: jsonClient,
    })
      .then((resp) => resp.json())
      .then((info) => {
        const data = info.data;
        var clientData = data.client[0];
        clientData['subdomain'] = window.location.host.split('.')[0];
        this.setAppColor(clientData.app_color)
        this.setState({
          clientInfo: clientData,
          previousPage: window.location.href,
        });
        if (this.state.user.jsonWebTok) {
          this.getNotificationCount(
            data.client[0].client_id,
            this.state.user.jsonWebTok
          );
        } 
      })
      .catch((error) => {
        console.log(error);
      });
    this.calcSize();
    window.addEventListener('resize', this.calcSize.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calcSize.bind(this));
  }
  calcSize() {
    this.setState({ appWidth: window.innerWidth });
  }
  getNotificationCount(client, webToken) {
    var jsonNotification = JSON.stringify({
      jsonWebTok: webToken,
      clientId: client,
      userId: this.state.user.uuid,
    });

    fetch(`${process.env.REACT_APP_API}/notification-count`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${webToken}`,
      },
      body: jsonNotification,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.success) {
          const data = info.data;
          if (!data.client) {
            this.setState({
              vendorCodeNotifications: 0,
              vendorFormNotifications: 0,
              vendorInvoiceNotifications: 0,
              vendorPurchaseNotifications: 0,
              vendorReadyPrs: data.notifications[0].VENDORPRS,
            });
          } else {
            const { CODE, VENDOR, INVOICE, PURCHASE } = data.notifications[0];

            this.setState({
              vendorCodeNotifications: CODE,
              vendorFormNotifications: VENDOR,
              vendorInvoiceNotifications: INVOICE,
              vendorPurchaseNotifications: PURCHASE,
              vendorReadyPrs: 0,
            });
          }
        } else {
          this.setState({
            vendorCodeNotifications: 0,
            vendorFormNotifications: 0,
            vendorInvoiceNotifications: 0,
            vendorPurchaseNotifications: 0,
            vendorReadyPrs: 0,
          });
        }
      });
  }
  authenticate(userInfo) {
    this.setState({
      user: {
        uuid: userInfo.info.uuid,
        jsonWebTok: userInfo.jsonWebTok,
        username: userInfo.info.username,
        clientId: userInfo.info.client_id,
        name: userInfo.info.name,
        vendorId: userInfo.info.vendor_id,
        approver_level: parseInt(userInfo.info.approver_level, 10),
        timezone: userInfo.info.timezone,
        avatarContent: userInfo.info.avatar_content,
        avatarPrimaryColor: userInfo.info.avatar_primary_color,
        contractClassifications: userInfo.info.contract_classification
      },
    });

    this.getNotificationCount(userInfo.info.client_id, userInfo.jsonWebTok);

    localStorage.setItem('vendorId', userInfo.info.vendor_id);
    localStorage.setItem('uuid', userInfo.info.uuid);
    localStorage.setItem('name', userInfo.info.name);
    localStorage.setItem('jsonWebTok', userInfo.jsonWebTok);
    localStorage.setItem('username', userInfo.info.username);
    localStorage.setItem('clientId', userInfo.info.client_id);
    localStorage.setItem('timezone', userInfo.info.timezone);
    localStorage.setItem('avatarContent', userInfo.info.avatar_content);
    localStorage.setItem(
      'avatarPrimaryColor',
      userInfo.info.avatar_primary_color
    );
    localStorage.setItem('contractClassifications', userInfo.info.contract_classification);

    this.setRoles(userInfo.info.vendor_id);
  }
  setRoles(vendId) {
    localStorage.removeItem('isVendor');
    localStorage.removeItem('isClient');
    this.setState({
      isVendor: vendId ? true : false,
      isClient: vendId ? false : true,
    });
    localStorage.setItem('isVendor', vendId ? true : false,);
    localStorage.setItem('isClient', vendId ? false : true);
  }
  signout(callback) {
    localStorage.removeItem('uuid');
    localStorage.removeItem('name');
    localStorage.removeItem('jsonWebTok');
    localStorage.removeItem('vendorId');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('clientId');
    localStorage.removeItem('isVendor');
    localStorage.removeItem('isClient');

    this.setState(
      {
        user: {},
        isClient: false,
        isVendor: false
      },
      () => callback(200)
    );
  }
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  changeClientInfo(info) {
    this.setAppColor(info.app_color )
    this.setState({ clientInfo: info });
  }
  changeUserInfo(info) {
    this.setState({ user: info });
    this.getNotificationCount(info.clientId, info.jsonWebTok);
    localStorage.setItem('name', info.name);
    localStorage.setItem('username', info.username);
    localStorage.setItem('timezone', info.timezone);
    localStorage.setItem('avatarContent', info.avatarContent);
    localStorage.setItem('avatarPrimaryColor', info.avatarPrimaryColor);
  }
  createAppLink(name, linkTo) {
    return (
      <div key={linkTo}>
        <Link className="dropdown-item" to={linkTo}>
          {name}
        </Link>
      </div>
    );
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    if (this.state.clientInfo === '') {
      return null;
    } else if (!this.state.clientInfo) {
      return (
        <div className="App">
          <div className="NotFound">
            <h3>Sorry, client subdomain not found!</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <div style={{ maxWidth: '1300px', margin: '0 auto' }}>
              <div
                style={{
                  margin: this.state.appWidth < 600 ? '0 0.25rem' : '0 1.5rem',
                }}
              >
                {this.state.user.uuid && (
                  <Fragment>
                    {this.state.isVendor ? (
                      <VendorTabs
                        appState={this.state}
                        logo={this.state.clientInfo.logo_img}
                        logout={this.signout}
                      />
                    ) : (
                      <MainTabs
                        appState={this.state}
                        logo={this.state.clientInfo.logo_img}
                        logout={this.signout}
                      />
                    )}
                  </Fragment>
                )}

                <Routes
                  createSnack={this.createSnack}
                  credentials={this.state}
                  auth={this.authenticate}
                  changeClientInfo={this.changeClientInfo}
                  changeUserInfo={this.changeUserInfo}
                  getNotificationCount={this.getNotificationCount}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="footerBox" />
            <div className="footerContainer">
              {this.state.clientInfo && (
                <div className="footerContent">
                  <FooterLinks
                    hre="https://vendor-launch-app-client.s3-us-west-2.amazonaws.com/Sample+PDF.pdf"
                    link="Privacy"
                  />
                  <span className="vr" />
                  <FooterLinks hre={this.state.clientInfo.legal} link="Legal" />
                  <span className="vr" />
                  <FooterLinks
                    hre={this.state.clientInfo.terms_and_conditions}
                    link="Terms"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(App));
