import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import './PurchasingRequest.css';
import Comments from '../components/Comments';
import Activity from '../components/Activity';
import { formatter } from "../utils/tableCustoms";
import VendorStatus from '../functions/VendorStatus';
import ObjectNotifications from '../components/ObjectNotifications';
import LoadingCircle from "../components/common/LoadingCircle"

class PoVendorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
      currentEdit: null,
      company: '',
      location: '',
      vendor: '',
      vendorVname: '',
      filterValue: '',
      poNumber: '',
      deliveryDate: '',
      currentQty: 0,
      currentCost: 0,
      header: null,
      rows: [],
      isLoading: false,
    };
    this.update = this.update.bind(this);
    this.createRows = this.createRows.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: window.location.href.split('po-vendor-details/')[1],
    };
    const jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-pos`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          this.createRows(data.data);
        }
      })
      .catch((error) => console.log(error));
  }
  createRows(rows) {
    const detailsNoDeletes = rows.poDetail.map((line) => {
      if (line.DELETED !== 1) {
        return {
          num: line.LINE_NUM,
          itemNum: line.LINE_ITEM,
          description: line.LINE_DESC,
          qty: line.LINE_QTY,
          uom: line.LINE_UOM,
          cost: line.LINE_COST,
          extCost: line.LINE_EXT_COST,
          deliveryDate: line.LINE_DELIVERY_DATE.split('T00')[0],
          shipTo: line.LINE_SHIP_TO,
        };
      } else return null;
    });
    const filtered = detailsNoDeletes.filter((row) => row !== null);
    rows.activity = rows.activity.reverse();
    const header = rows.poHeader[0];
    this.setState({
      content: rows,
      isEdit: false,
      isNewEntryOpen: false,
      header: header,
      rows: filtered,
      location: header.LOCATION_ID,
      vendor: header.VENDOR_ID,
      vendorVname: header.VENDOR_VNAME,
      filterValue: header.FILTER_VALUE,
      poNumber: header.PO_NUMBER,
      deliveryDate: header.DELIVERY_DATE,
      isLoading: false,
    });
  }
  update(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const updateFilled = this.state.rows.map((line, index) => {
      const id = `${index}_fulfilled`;
      return {
        lineNum: line.num,
        fulfilled: parseInt(event.target[id].value, 10),
      };
    });
    const fetchData = {
      vendorUpdate: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.state.content.poHeader[0].CLIENT_ID,
      vendorId: this.state.content.poHeader[0].VENDOR_ID,
      poId: this.state.content.poHeader[0].PO_ID,
      jsonArray: JSON.stringify(updateFilled),
      url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/purchasing-request-details/${this.state.content.poHeader[0].PO_ID}`,
    };
    fetch(`${process.env.REACT_APP_API}/update-po`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((data) => {

        if(data.error) {
          this.props.fetchInitialData.createSnack(data.error, 'error', 3500)
        } else if (data.success) {
          this.getData();
          this.props.fetchInitialData.createSnack('Fulfilled quantities updated!', 'success', 3500)
        } else {
          this.props.fetchInitialData.createSnack('Error', 'error', 3500)
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => console.log(error));
  }
  handleClose() {
    this.setState({
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
    });
  }
  render() {
    if (!this.state.content) {
      return (
        <LoadingCircle/>
      );
    }
    const { classes } = this.props;
    let sum = this.state.rows.reduce((acc, val) => {
      return parseFloat(acc, 10) + parseFloat(val.extCost, 10);
    }, 0);
    const load = this.state.isLoading ? (
      <Fade in={this.state.isLoading} unmountOnExit>
        <CircularProgress
          style={{ marginLeft: '10px', color: '#545454' }}
          size="20px"
        />
      </Fade>
    ) : (
      ''
    );
    return (
      <div>
        <div className="purchasingForm">
          <h3>Purchasing Request</h3>
          <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              right: 0,

              fontSize: '12px',
            }}
          >
            <div>Status: {VendorStatus(this.state.content.poHeader[0].PO_STATUS)}</div>
            <div>Approval Level: {this.state.content.poHeader[0].APPROVAL_STATUS_LEVEL}</div>
          </div>
        </div>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>Company: </span>
                {this.state.company}
              </div>
              <div>
                <span>Location: </span>
                {this.state.location}
              </div>
              <div>
                <span>Vendor: </span>
                {this.state.vendorVname}
              </div>
              <div>
                <span>
                  {
                    this.props.fetchInitialData.credentials.clientInfo
                      .filter_value_label
                  }
                  :{' '}
                </span>
                {this.state.filterValue}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>PO Number: </span>
                {this.state.poNumber}
              </div>
              <div>
                <span>Delivery Date: </span>
                {this.state.deliveryDate.split('T0')[0]}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>Total: </span>
                <span style={{ fontSize: '1.6rem' }}>
                  <strong>{formatter.format(sum)}</strong>
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <form onSubmit={this.update}>
                <Paper className={classes.paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="center">
                          Items
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Item #
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Description
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Qty
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Qty Fulfilled
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          UOM
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Cost
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Ext Cost
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Delivery Date
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Ship To
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row, index) => (
                        <TableRow key={row.num}>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {row.num}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.itemNum}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.description}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.qty}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <TextField
                              id={`${index}_fulfilled`}
                              style={{ maxWidth: '50px' }}
                              defaultValue={
                                this.state.content.poDetail[index].FULFILLED
                              }
                              type="number"
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.uom}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            ${row.cost}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            ${row.extCost}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.deliveryDate}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {row.shipTo}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>

                <div style={{ marginTop: '1rem' }}>
                  <Button
                    type="submit"
className="primaryVLButton"
                    variant="contained"
                  >
                    Update
                  </Button>
                  {load}
                </div>
              </form>
            </Grid>
          </Grid>
          <Comments
            fetchInitialData={this.props.fetchInitialData}
            objectId={window.location.href.split('po-vendor-details/')[1]}
            objectType="PURCHASE"
            vendorId={this.state.content.poHeader[0].VENDOR_ID}
            isVendor={this.props.fetchInitialData.credentials.isVendor}
          />

          <Activity
            title="Purchase Request History"
            activity={this.state.content.activity}
            timezone={this.props.fetchInitialData.credentials.user.timezone}
            appWidth={this.props.fetchInitialData.credentials.appWidth}
          />

<ObjectNotifications
                  createSnack={this.props.fetchInitialData.createSnack}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  parentId={this.state.content.poHeader[0].PO_ID}
                  parentType="vendor"
                  isVendor={!!this.props.fetchInitialData.credentials.isVendor}
                  vendorId={this.state.content.poHeader[0].VENDOR_ID}
                />
          {/*
          {this.state.content.activity.length > 0 && (
            <div style={{ margin: "1rem auto" }}>
              <h5>Purchase Request History</h5>
              <div>
                <Paper className={classes.paper}>
                  {this.props.fetchInitialData.credentials.appWidth > 599 ? (
                    <Table style={{ overflowX: "auto" }} padding="dense">
                      <TableBody>
                        {this.state.content.activity.map((row, index) => (
                          <TableRow key={row.ACTIVITY_ID}>
                            <TableCell align="left">
                              {row.ACTIVITY_TYPE}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.ACTIVITY_STRING}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {moment(
                                moment(new Date(row.TIMESTAMP)).utcOffset(
                                  this.props.fetchInitialData.credentials.user
                                    .timezone
                                )._d
                              ).format("lll")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div>
                      {this.state.content.activity.map((row, index) => {
                        return (
                          <Card
                            style={{ border: "0.5px solid #545454" }}
                            key={row.ACTIVITY_ID}
                          >
                            <CardContent>
                              <div>
                                <span style={{ fontSize: "1.2rem" }}>
                                  {row.ACTIVITY_TYPE}
                                </span>
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  {moment(
                                    moment(new Date(row.TIMESTAMP)).utcOffset(
                                      this.props.fetchInitialData.credentials
                                        .user.timezone
                                    )._d
                                  ).format("lll")}
                                </span>
                                <hr style={{ margin: "0.1rem" }} />
                                <p style={{ margin: "0.8rem" }}>
                                  {row.ACTIVITY_STRING}
                                </p>
                                <div
                                  style={{
                                    marginTop: "0.8rem",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  by: {row.name}
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: 'rgb(232,102,19)',
    '&:hover': {
      backgroundColor: 'rgb(220,92,10)',
      color: 'white',
    },
  },
  tableCell: {
    padding: '10px 20px',
  },
});

PoVendorDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PoVendorDetails);
