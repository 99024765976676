import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress, Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';

import { invoiceDist } from '../utils/constants/invoiceColumns';
import PrintIcon from "@material-ui/icons/Print";
import PdfTableDialog from "../components/pdf/PdfTableDialog";
import { customTableSort, formatter } from '../utils/tableCustoms';
import VendorStatus from '../functions/VendorStatus'
import { responsive } from '../utils/constants/muidatatablesConsts';
import LoadingCircle from "../components/common/LoadingCircle"


class ClientInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      rows: null,
      openPrintDialog: false,
    };
  }

  componentDidMount() {
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      get: true,
    });
    fetch(`${process.env.REACT_APP_API}/get-ready-distrib-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonVendor,
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            const { distribInvoices } = info.data;
            this.setState({
              content: distribInvoices,
              muiTableData: this.makeTableData(distribInvoices),
            });
          }
        })
        .catch((error) => console.log(error));
  }

  makeTableData = (data) =>
      data.map((invoice) => [
        <div className="editLink" onClick={() => this.props.history.push(`/invoice-details/${invoice.INVOICE_ID
        }`)}>{invoice.REFERENCE_NUM}</div>,
        invoice.VENDOR_VNAME,
        new Date(invoice.INVOICE_DATE).getTime() ? moment(new Date(invoice.INVOICE_DATE)).format('MM/DD/YYYY') : 'N/A',
        new Date(invoice.DUE_DATE).getTime() ? moment(new Date(invoice.DUE_DATE)).format('MM/DD/YYYY') : 'N/A',
        formatter.format(invoice.TRAN_INV_AMOUNT),
        invoice.CODING_COMPLETE === 1 ? 'Yes' : 'No',
        VendorStatus(invoice.INVOICE_STATUS),
      ])

  render() {
    const { classes } = this.props;
    if (!this.state.content) {
      return (
          <LoadingCircle/>
      );
    } else {
      return (
          <div className={classes.paymentForm}>
            <MUIDataTable
                title={'Distributions'}
                data={this.state.muiTableData}
                columns={invoiceDist}
                options={{
                  selectableRows: false,
                  filterType: 'dropdown',
                  responsive,
                  print: false,
                  customSort: (data, colIndex, order) => customTableSort(data, colIndex, order, 4),
                  customSearch: () => true,
                  onSearchChange: (query) => {
                    this.setState({
                      muiTableData: this.makeTableData(this.state.content.filter((invoice) => {
                        if (query) {
                          return (
                              invoice.INVOICE_NUMBER
                                  .toString()
                                  .includes(query.trim()) ||
                              invoice.VENDOR_VNAME
                                  .toLowerCase()
                                  .includes(query.toLowerCase().trim()) ||
                              (new Date(invoice.INVOICE_DATE).getTime() ?
                                  moment(new Date(invoice.INVOICE_DATE)).format('MM/DD/YYYY') : 'N/A')
                                  .toString().toLowerCase()
                                  .includes(query.trim()) ||
                              (new Date(invoice.DUE_DATE).getTime() ?
                                  moment(new Date(invoice.DUE_DATE)).format('MM/DD/YYYY') : 'N/A')
                                  .toString().toLowerCase()
                                  .includes(query.trim()) ||
                              formatter.format(invoice.TRAN_INV_AMOUNT)
                                  .toString()
                                  .includes(query.trim()) ||
                              invoice.TRAN_INV_AMOUNT.toString()
                                  .includes(query.trim()) ||
                              VendorStatus(invoice.INVOICE_STATUS)
                                  .toLowerCase()
                                  .includes(query.toLowerCase().trim()) ||
                              (invoice.CODING_COMPLETE === 1 ? 'Yes' : 'No')
                                  .toLowerCase()
                                  .includes(query.toLowerCase().trim())
                          );
                        }
                        return invoice;
                      }))
                    });
                  },
                  onSearchClose: () => {
                    this.setState({
                      muiTableData: this.makeTableData(this.state.content)
                    });
                  },
                }}
            />
            <PdfTableDialog
                isLarge={false}
                open={this.state.openPrintDialog}
                fileName={'distributions'}
                title={'Distributions'}
                data={this.state.muiTableData}
                columns={invoiceDist}
                onClose={() => this.setState({ openPrintDialog: false })}
            />
            <Button
                className="primaryVLButton"
                style={{marginTop: 10}}
                onClick={() => this.setState({ openPrintDialog: true })}>
              PDF
              <PrintIcon style={{ marginLeft: 10 }}/>
            </Button>
          </div>
      );
    }
  }
}

const styles = () => ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
});

ClientInvoices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientInvoices);
