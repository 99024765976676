import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
  TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, InputAdornment,
  withStyles, CircularProgress, MenuItem, Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import LoadingButton from '../components/LoadingButton';
import { adminQueueColumns } from "../utils/constants/adminQueueColumns";
import {responsive} from '../utils/constants/muidatatablesConsts';
import { formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

class AdminQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queue: null,
      copyOfData: null,
      isOpen: false,
      currentEntry: null,
      confirmDelete: false,
      isActive: true,
      isEdit: false,
      editCheck: false,
      queueType: '',
      isLoading: false,
      dollarLimitAmount: '',
      editDollarLimitAmount: '',
      tableData: null,
    };
  }

  componentDidMount() {
    this.getQueueData();
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  getQueueData = () => {
    let form = {
      get: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-or-update-queue`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
        .then((resp) => resp.json())
        .then((info) => {
          const data = info.data;
          const access = this.checkAccess()
          this.setState({
            queue: data.queue,
            copyOfData: data.queueUser,
            tableData: this.makeTableData(data.queue, access),
          });
        })
        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
        });
  }

  makeTableData = (unfilteredRows, access) => {
    return unfilteredRows.map((option, index) => {
      let reducedObj = [index + 1];
      let checked = false;
      if (option.FILTER_ON === 'NO') {
        checked = true;
      }
      reducedObj.push(option.QUEUE_TYPE);
      reducedObj.push(option.LEVEL);
      reducedObj.push(formatter.format(option.DOLLAR_LIMIT));
      reducedObj.push(
          <p
              onClick={() => {
                this.setState({
                  isEdit: true,
                  editCheck: !checked,
                  currentEntry: option,
                  queueType: option.QUEUE_TYPE,
                  editDollarLimitAmount: option.DOLLAR_LIMIT,
                });
              }}
              className="editLink"
          >
            {(access && access.edit) ? 'edit' : 'view'}
          </p>
    );
      return reducedObj;
    });
  }

  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  addQueue = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const newQueue = {
      type: this.state.queueType,
      level: event.target.level.value,
      dollarLimit: this.state.dollarLimitAmount,
    };
    const fetchData = {
      new: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queue: newQueue,
    };
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/get-or-update-queue`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          } else if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully added queue',
                'success',
                3000
            );

            const tableData = this.makeTableData(info.data.queue);
            this.setState({
              tableData,
              copyOfData: info.data.queue,
              isOpen: false,
              currentEntry: null,
              isLoading: false,
              queueType: '',
              dollarLimitAmount: '',
            });
          }
        })
        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
          console.log(error);
          this.setState({
            isOpen: false,
            currentEntry: null,
            isLoading: false,
            queueType: '',
            dollarLimitAmount: '',
          });
        });
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const fetchData = {
      delete: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queue: this.state.currentEntry,
    };
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/get-or-update-queue`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          } else if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully deleted queue',
                'success',
                3000
            );

            const tableData = this.makeTableData(info.data.queue);
            this.setState({
              tableData,
              copyOfData: info.data.queue,
              currentEntry: null,
              confirmDelete: false,
              isEdit: false,
              isLoading: false,
              queueType: '',
              dollarLimitAmount: '',
            });
          }
        })
        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
          this.setState({
            currentEntry: null,
            confirmDelete: false,
            isEdit: false,
            isLoading: false,
            queueType: '',
            dollarLimitAmount: '',
          });
        });
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const fetchData = {
      edit: true,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      user_id: this.props.fetchInitialData.credentials.user.uuid,
      queueId: this.state.currentEntry.QUEUE_ID,
      queueType: this.state.queueType,
      queueLevel: event.target.editQueueLevel.value,
      queueDollarLimit: this.state.editDollarLimitAmount,
    };
    const fetchString = JSON.stringify(fetchData);
    fetch(`${process.env.REACT_APP_API}/get-or-update-queue`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchString,
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.error) {
            this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          } else if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully updated queue',
                'success',
                3000
            );
            const tableData = this.makeTableData(info.data.queue);
            this.setState({
              tableData,
              copyOfData: info.data.queue,
              currentEntry: null,
              confirmDelete: false,
              isEdit: false,
              isLoading: false,
              editDollarLimitAmount: '',
              queueType: '',
            });
          }
        })
        .catch((error) => {
              this.props.fetchInitialData.createSnack(
                  `Error: ${error}`,
                  'error',
                  3000
              );
              this.setState({
                currentEntry: null,
                confirmDelete: false,
                isEdit: false,
                isLoading: false,
                editDollarLimitAmount: '',
                queueType: '',
              });
            }
        );
  }

  modalTextField = (id, label) => {
    const { classes } = this.props;
    return (
        <TextField
            id={id}
            label={label}
            fullWidth
            margin="dense"
            InputProps={{
              type: 'number',
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
              startAdornment: <InputAdornment position="start"><span/></InputAdornment>,
            }}
            autoComplete="off"
        />
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  checkAccess = () => {
    const { access } = this.props
    return {
      view: access.settings_queue_view,
      edit: access.settings_queue_edit,
      add: access.settings_queue_add,
      delete: access.settings_queue_delete,
    }
  }

  render() {
    if (!this.state.queue) {
      return (
          <div style={{ height: '100vh', width: '100%' }}>
            <LoadingCircle/>
          </div>
      );
    }
    const { clientInfo } = this.props.fetchInitialData.credentials
    const { classes } = this.props;
    const access = this.checkAccess();
    return (
        <Grid container>
          {this.state.isNewEntry && (
              <Dialog
                  open={this.state.isOpen}
                  onClose={() => this.setState({ isOpen: false, currentEntry: null })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.addQueue}>
                  <DialogTitle id="alert-dialog-title">Add Queue</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            select
                            id="type"
                            label="Type"
                            value={this.state.queueType}
                            onChange={(event) =>
                                this.setState({ queueType: event.target.value })
                            }
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                            margin="dense"
                            autoComplete="off"
                        >
                          {clientInfo.invoice_license === 1 && (
                            <MenuItem key="INVOICE" value="INVOICE">
                            INVOICE
                          </MenuItem>
                          )}
                          {clientInfo.po_license === 1 && (
                             <MenuItem key="PURCHASE" value="PURCHASE">
                             PURCHASE
                           </MenuItem>
                            )}
                          
                         
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {this.modalTextField('level', 'Level')}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            label="Dollar Limit"
                            margin="dense"
                            value={
                              this.state.dollarLimitAmount
                                  ? Math.round(this.state.dollarLimitAmount * 100) /
                                  100
                                  : ''
                            }
                            onChange={this.handleChange('dollarLimitAmount')}
                            id="dollarLimitAmount"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              startAdornment: <InputAdornment position="start"><span/></InputAdornment>,
                            }}
                            autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({ isOpen: false, currentEntry: null })}>
                      Back
                    </Button>
                    <LoadingButton
                        label="Add"
                        isLoading={this.state.isLoading}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Dialog>
          )}
          {this.state.isEdit && (
              <Dialog
                  onClose={() => this.setState({ isEdit: false, currentEntry: null, queueType: '' })}
                  open={this.state.isEdit}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.handleEdit}>
                  <DialogTitle id="alert-dialog-title">Edit Queue</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            select
                            id="editQueueType"
                            label="Type"
                            value={this.state.queueType}
                            onChange={(event) =>
                                this.setState({ queueType: event.target.value })
                            }
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                            }}
                            margin="dense"
                        >
                          {clientInfo.invoice_license === 1 && (
                          <MenuItem key="INVOICE" value="INVOICE">
                            INVOICE
                          </MenuItem>
                          )}
                          {clientInfo.po_license === 1 && (
                          <MenuItem key="PURCHASE" value="PURCHASE">
                            PURCHASE
                          </MenuItem>
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            id="editQueueLevel"
                            label="Level"
                            fullWidth
                            defaultValue={this.state.currentEntry.LEVEL}
                            margin="dense"
                            InputProps={{
                              type: 'number',
                              classes: {
                                input: classes.resize,
                                root: classes.textFieldRoot,
                              },
                              startAdornment: <InputAdornment position="start"><span/></InputAdornment>,
                            }}
                            autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            label="Dollar Limit"
                            margin="dense"
                            value={
                              this.state.editDollarLimitAmount
                                  ? Math.round(
                                  this.state.editDollarLimitAmount * 100
                              ) / 100
                                  : ''
                            }
                            onChange={this.handleChange('editDollarLimitAmount')}
                            id="editDollarLimitAmount"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              startAdornment: <InputAdornment position="start"><span/></InputAdornment>,
                            }}
                            autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {!!access.delete && (
                        <Button
                            onClick={() =>
                                this.setState({ confirmDelete: true, isEdit: false })}
                            style={{ color: 'red' }}
                        >
                          Delete
                        </Button>
                    )}
                    <Button onClick={() => this.setState({ isEdit: false, currentEntry: null, queueType: '' })}>
                      Cancel
                    </Button>
                    {access && !!access.edit && (
                        <LoadingButton
                            label="Submit"
                            isLoading={this.state.isLoading}
                            color="primaryVLButton"
                            buttonType="submit"
                        />
                    )}
                  </DialogActions>
                </form>
              </Dialog>
          )}
          {this.state.confirmDelete && (
              <Dialog
                  open={this.state.confirmDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  onClose={() => this.setState({ confirmDelete: false })}
              >
                <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this queue?
                </DialogContent>
                <DialogActions>
                  <form onSubmit={this.handleDelete}>
                    <Button onClick={() => this.setState({ confirmDelete: false })}>
                      Cancel
                    </Button>
                    <LoadingButton
                        label="Confirm"
                        isLoading={this.state.isLoading}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </form>
                </DialogActions>
              </Dialog>
          )}

          {this.state.queue && (
              <Grid item xs={12} sm={12} md={12} className={classes.rejectContainer}>
                <MUIDataTable
                    title={'Queue'}
                    data={this.state.tableData}
                    columns={adminQueueColumns}
                    options={{
                      selectableRows: false,
                      filterType: 'dropdown',
                      responsive,
                      print: false,
                    }}
                />
                <Grid item xs={12} sm={12} md={12}>
                  {!!access.add && (
                      <Button
                          style={{
        
                            marginTop: '1rem',
                          }}
                          className="primaryVLButton"
                          onClick={() =>
                              this.setState({
                                isOpen: true,
                                isNewEntry: true,
                                currentEntry: {
                                  ACTIVE: 1,
                                  CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
                                },
                              })
                          }
                      >
                        + Add to Queue
                      </Button>
                  )}
                </Grid>
              </Grid>
          )}
        </Grid>
    );
  }
}

const styles = ({
  rejectContainer: {
    margin: '0 auto',
    paddingBottom: '50px',
    padding: '2rem',
    maxWidth: 1300,
    '@media (max-width: 800px)': {
      padding: '0.5rem 0.1rem',
    },
  },
});

AdminQueue.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminQueue);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                value: values.value,
              },
            });
          }}
          thousandSeparator
          prefix="$"
      />
  );
}
