import React, { useEffect } from 'react';
import { TableContainer } from "@material-ui/core";

export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);

  useEffect(() => {
    props.onClose();
    createPdf();
  }, []);

  return (
      <section ref={bodyRef}>
        <TableContainer style={{borderCollapse:'collapse'}}>
          {props.children}
        </TableContainer>
      </section>
  )
}
