import React, { useReducer, useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import LoadingButton from '../components/LoadingButton';
import LambdaFetch from '../functions/FetchFromLambda';

export default function NotificationUsers({
  userType,
  jsonWebTok,
  parentType,
  parentId,
  createSnack,
  vendorId,
}) {
  const [isSaving, setSaving] = useState(false);
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'EDIT_USERS':
          return { ...state, selectedUsers: action.values };
        case 'ADD_ALL_USERS':
          const filterUsers = action.allUsers
            .filter((user) =>
              userType === 'client'
                ? !user.vendor_id
                : user.vendor_id === vendorId
            )
            .map((u, index) => {
              return {
                index,
                title: u.username,
                name: u.name,
                uuid: u.uuid,
              };
            });
          const defaults = action.defaultUsers.map((user) => user.user_id);

          const defaultUsers = filterUsers.filter((user) => {
            return defaults.includes(user.uuid);
          });
          return {
            ...state,
            allUsers: filterUsers,
            selectedUsers: defaultUsers,
          };
        default:
          return state;
      }
    },
    {
      allUsers: [],
      selectedUsers: [],
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await LambdaFetch(
        'notification-users',
        'post',
        jsonWebTok,
        JSON.stringify({
          action: 'get',
          parentId,
          parentType,
          userType,
        })
      );
      dispatch({
        type: 'ADD_ALL_USERS',
        allUsers: data.data.users,
        defaultUsers: data.data.defaultUsers,
      });
    };
    fetchData();
  }, []);

  const updateNotifiees = async (e) => {
    e.preventDefault();
    setSaving(true);
    const updatedNotifiees = await LambdaFetch(
      'notification-users',
      'post',
      jsonWebTok,
      JSON.stringify({
        action: 'edit',
        parentId,
        parentType,
        userType,
        users: JSON.stringify(state.selectedUsers.map((u) => u.uuid)),
      })
    );

    if (updatedNotifiees.success) {
      createSnack(
        `Successfully updated ${userType} subscribers`,
        'success',
        3500
      );
    } else {
      createSnack(`Error updating ${userType} subscribers`, 'error', 3500);
    }
    setSaving(false);
  };

  return (
    <div>
      <form onSubmit={(e) => updateNotifiees(e)}>
        <Autocomplete
          style={{ width: '100%' }}
          multiple
          fullWidth
          value={state.selectedUsers}
          disableCloseOnSelect
          options={state.allUsers}
          onChange={(event, values) =>
            dispatch({
              type: 'EDIT_USERS',
              event,
              values,
            })
          }
          getOptionLabel={(option) => `${option.name}  (${option.title})`}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              id="notifiee"
              variant="outlined"
              margin="dense"
              label={`Subscribed ${userType.slice(0, 1).toUpperCase() +
                userType.slice(1)} User(s)`}
              fullWidth
              autoComplete="off"
            />
          )}
        />
        <div style={{marginTop: '0.25rem'}}>
        <LoadingButton
          label="Save"
          isLoading={isSaving}
          color="primaryVLButton"
          buttonType="submit"
        />
        </div>
      </form>
    </div>
  );
}
