import React from "react";
import {
  withStyles, Grid, Card, CardContent, Button, TextField, List, InputAdornment, InputLabel, FormControl,
  Select, MenuItem, DialogActions, Dialog, DialogContent, DialogTitle
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MUIDataTable from "mui-datatables";
import NumberFormat from "react-number-format";
import { withSnackbar } from "notistack";

import LoadingButton from "./LoadingButton";
import ConfirmDialog from "../components/ConfirmDialog";
import { attributeColumns } from "../utils/constants/attributesColumns";
import {responsive} from '../utils/constants/muidatatablesConsts';


const styles = () => ({
  button: {
    color: "rgba(1,1,1,0.8)",
  },
  "&:focus": {
    outline: "none !important",
  },
  "&:active": {
    outline: "none !important",
  },
});

class Attributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingNewAttribute: false,
      editIndex: null,
      addAttribute: null,
      attributes: this.props.attributes,
      attributeType: 0,
      isSaving: false,

      attributeUser: null,
      editAttributeUser: null,
      attributeAmount: "",
      showCompleted: false
    };
    this.handleArchive = this.handleArchive.bind(this);
  }

  addNewAttribute = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    let value;
    let header = this.props.attributeTypes
        .find((a) => a.attribute_id === this.state.attributeType).attribute_header;

    const newAttribute = {
      client_id: this.props.props.fetchInitialData.credentials.user.clientId,
      attribute_id: this.state.attributeType,
      attribute_value: null,
    };

    if (event.target.newValue) {
      value = event.target.newValue.value;
      newAttribute.attribute_value = event.target.newValue.value;
    } else if (this.state.attributeUser) {
      value = this.state.attributeUser.username;
      newAttribute.attribute_value = this.state.attributeUser.uuid;
    }
    newAttribute["activity_string"] = `Created attribute: ${header} to ${value}`;

    this.props.addAttribute(newAttribute, (statusCode) => {
      if (statusCode === 200) {
        this.setState({
          addingNewAttribute: false,
          addAttribute: null,
          isSaving: false,
        });
      } else {
        this.setState({
          isSaving: false,
        });
      }
    });
  };
  editAttribute = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });

    let updatedAttribute = {
      attribute_value_id: this.state.editAttribute.attribute_value_id,
      attribute_value: this.state.editAttributeValue
    }

    if (this.state.editAttributeUser) {
      updatedAttribute.attribute_value = this.state.editAttributeUser.uuid
      updatedAttribute['activity_string'] = `Updated attribute: ${this.state.editAttribute.attribute_header} to ${this.state.editAttributeUser.title}`
    } else {
      updatedAttribute['activity_string'] = `Updated attribute: ${this.state.editAttribute.attribute_header} to ${updatedAttribute.attribute_value}`
    }


    this.props.updateAttribute(updatedAttribute, () => {
      this.setState({
        editAttribute: null,
        isSaving: false,
        editAttributeType: null,
        editAttributeUser: null,
      })
    });
  };
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleArchive(id, callback) {

    this.props.archive(
        () => {
          this.setState({ editIndex: null, editAttribute: null }, () => {
            callback(200);
          });
        },
        "attribute",
        id,
        this.state.editAttribute.attribute_header
    );
  }

  handleReactivate = (id, callback) => {
    this.props.archive(
        () => {
          this.setState({ editIndex: null, editAttribute: null }, () => {
            callback(200);
          });
        },
        "attribute",
        id,
        this.state.editAttribute.attribute_header,
        true,
        true
    );
  };
  onTagsChange = (event, values, type) => {
    this.setState({
      [type]: values
    });
  };

  render() {
    let filteredAttributes
    if (this.state.showCompleted) {
      filteredAttributes = this.props.attributes.filter(a => a.delete_flag === 1)
    } else {
      filteredAttributes = this.props.attributes.filter(a => a.delete_flag === 0)
    }

    const reveredAttributes = filteredAttributes.slice().reverse().map((a) => {
      let attribRow = [
        a.attribute_header,
        a.username ? a.username : a.attribute_value
      ]
      if (this.props.access.editAttrib) {
        attribRow.push(
            <div
                className="editLink"
                onClick={() =>
                    this.setState({
                      editAttribute: a,
                      attributeType: a.attribute_id,
                      editAttributeValue: a.attribute_value,
                      editAttributeUser: a.data_type === 'user' ? {
                            uuid: a.attribute_value,
                            title: a.username
                          }
                          : null,
                    })
                }
            >
              {this.props.access.editAttrib ? 'edit' : 'view'}
            </div>)
      }
      return attribRow
    })

    let viewNum = this.props.attributes.length - filteredAttributes.length;


    let contractUsers = this.props.contractUsers.map((u) => {
      return { uuid: u.uuid, title: u.username, name: u.name, username: u.username };
    });
    let defaultUser;
    if (this.state.editAttribute) {
      defaultUser = contractUsers.filter(
          (c) => c.uuid === this.state.editAttribute.attribute_value
      )[0];
    }

    let attribType;
    if (this.state.attributeType) {
      let dataType = this.props.attributeTypes.find(
          (a) => a.attribute_id === this.state.attributeType
      ).data_type;
      if (dataType === "text") {
        attribType = 0;
      } else if (dataType === "number") {
        attribType = 1;
      } else if (dataType === "user") {
        attribType = 2;
      } else if (dataType === "date") {
        attribType = 3;
      }

    }
    return (
        <Grid item xs={12} sm={12} md={12} style={{ position: 'relative', top: 10 }}>
          <Card style={{ position: "relative" }}>
            <CardContent>
              <div>
                <List component="nav" aria-label="main mailbox folders">
                  {this.state.addingNewAttribute && (
                      <Dialog
                          open={this.state.addingNewAttribute}
                          onClose={this.handleClose}
                          fullWidth
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          Add Attribute
                        </DialogTitle>

                        <form
                            style={{ width: "100%" }}
                            onSubmit={this.addNewAttribute}
                        >
                          <DialogContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControl
                                    variant="filled"
                                    margin="dense"
                                    fullWidth
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Attribute Type
                                  </InputLabel>
                                  <Select
                                      labelId="demo-simple-select-label"
                                      labelWidth={100}
                                      id="demo-simple-select"
                                      value={this.state.attributeType}
                                      onChange={(e) =>
                                          this.handleChange(
                                              "attributeType",
                                              e.target.value
                                          )
                                      }
                                  >
                                    {this.props.attributeTypes.map((type) => {
                                      return (
                                          <MenuItem
                                              key={type.attribute_id}
                                              value={type.attribute_id}
                                          >
                                            {type.attribute_header}
                                          </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                {attribType === 2 ? (
                                    <Autocomplete
                                        style={{
                                          marginTop: "8px",
                                          width: "100%",
                                        }}
                                        size="small"
                                        required
                                        options={this.props.contractUsers}
                                        onChange={(event, values) =>
                                            this.onTagsChange(
                                                event,
                                                values,
                                                "attributeUser"
                                            )
                                        }
                                        getOptionLabel={(option) => `${option.name} - (${option.username})`}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                id="attributeUser"
                                                onKeyPress={(e) => {
                                                  if (e.key === "Enter") e.preventDefault();
                                                }}
                                                variant="filled"
                                                label="User"
                                                fullWidth
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                ) : attribType === 1 ? (
                                    <TextField
                                        label="Value"
                                        fullWidth
                                        required
                                        margin="dense"
                                        variant="filled"
                                        value={
                                          this.state.attributeAmount
                                              ? this.state.attributeAmount
                                              : ""
                                        }
                                        onChange={(event) => {
                                          this.setState({
                                            attributeAmount: event.target.value,
                                          });
                                        }}
                                        id="newValue"
                                        InputProps={{
                                          inputComponent: NumberFormatCustom,
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : attribType === 0 ? (
                                    <TextField
                                        label="Value"
                                        fullWidth
                                        required
                                        margin="dense"
                                        id="newValue"
                                        variant="filled"
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") e.preventDefault();
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : attribType === 3 ? (
                                    <TextField
                                        label="Value"
                                        fullWidth
                                        required
                                        type="date"
                                        margin="dense"
                                        id="newValue"
                                        variant="filled"
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") e.preventDefault();
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : (
                                    <div/>
                                )}
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                                className="secondaryVLButton"
                                onClick={() =>
                                    this.setState({
                                      addingNewAttribute: false,
                                    })
                                }
                            >
                              Cancel
                            </Button>

                            <LoadingButton
                                label="Save"
                                isLoading={this.state.isSaving}
                                color="primaryVLButton"
                                buttonType="submit"
                            />
                          </DialogActions>
                        </form>
                      </Dialog>
                  )}
                  {this.state.editAttribute && (
                      <Dialog
                          open={!!this.state.editAttribute}
                          onClose={this.handleClose}
                          fullWidth
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                        <form
                            key={this.state.editAttribute.attribute_id}
                            style={{ width: "100%" }}
                            onSubmit={(event) => this.editAttribute(event)}
                        >
                          <DialogTitle id="alert-dialog-title">
                            Edit Attribute
                          </DialogTitle>
                          <DialogContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControl
                                    variant="filled"
                                    margin="dense"
                                    fullWidth
                                    disabled
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Attribute
                                  </InputLabel>
                                  <Select
                                      labelId="demo-simple-select-label"
                                      labelWidth={100}
                                      id="demo-simple-select"
                                      value={this.state.attributeType}
                                      onChange={(e) =>
                                          this.handleChange(
                                              "attributeType",
                                              e.target.value
                                          )
                                      }
                                  >
                                    {this.props.attributeTypes.map((type) => {
                                      return (
                                          <MenuItem
                                              key={type.attribute_id}
                                              value={type.attribute_id}
                                          >
                                            {type.attribute_header}
                                          </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={6} lg={6}>
                                {attribType === 2 ? (
                                    <Autocomplete
                                        style={{
                                          marginTop: "8px",
                                          width: "100%",
                                        }}
                                        size="small"
                                        required
                                        options={contractUsers}
                                        defaultValue={defaultUser}
                                        onChange={(event, values) =>
                                            this.onTagsChange(
                                                event,
                                                values,
                                                "editAttributeUser"
                                            )
                                        }
                                        getOptionLabel={(option) => `${option.name} - (${option.username})`}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                id="editAttributeUser"
                                                onKeyPress={(e) => {
                                                  if (e.key === "Enter") e.preventDefault();
                                                }}
                                                variant="filled"
                                                label="User"
                                                fullWidth
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                ) : attribType === 1 ? (
                                    <TextField
                                        label="Value (number)"
                                        fullWidth
                                        required
                                        margin="dense"
                                        variant="filled"
                                        value={this.state.editAttributeValue}
                                        onChange={e => this.setState({ editAttributeValue: e.target.value })}
                                        id="editValuenumber"
                                        InputProps={{
                                          inputComponent: NumberFormatCustom,
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : attribType === 0 ? (
                                    <TextField
                                        label="Value (text)"
                                        fullWidth
                                        required
                                        margin="dense"
                                        id="editValuestring"
                                        variant="filled"
                                        value={this.state.editAttributeValue}
                                        onChange={e => this.setState({ editAttributeValue: e.target.value })}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") e.preventDefault();
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : attribType === 3 ? (
                                    <TextField
                                        label="Value (date)"
                                        fullWidth
                                        required
                                        type="date"
                                        margin="dense"
                                        id="editValuedate"
                                        variant="filled"
                                        value={this.state.editAttributeValue}
                                        onChange={e => this.setState({ editAttributeValue: e.target.value })}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") e.preventDefault();
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <span/>
                                              </InputAdornment>
                                          ),
                                        }}
                                        autoComplete="off"
                                    />
                                ) : (
                                    <div/>
                                )}
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                                onClick={() =>
                                    this.setState({
                                      editIndex: null,
                                      editAttribute: null,
                                    })
                                }
                            >
                              Cancel
                            </Button>

                            {this.state.showCompleted ? (
                                <ConfirmDialog
                                    handleConfirm={this.handleReactivate}
                                    type="attribute"
                                    title="Reactivate Attribute"
                                    color="green"
                                    reactivate
                                    dialogText="Are you sure you want to reactivate this attribute?"
                                    id={this.state.editAttribute.attribute_value_id}
                                />
                            ) : (
                                <ConfirmDialog
                                    handleConfirm={this.handleArchive}
                                    title="Archive Attribute"
                                    dialogText="Are you sure you want to archive this attribute?"
                                    color="red"
                                    type="attribute"
                                    id={this.state.editAttribute.attribute_value_id}
                                />
                            )}
                            <LoadingButton
                                label="Save"
                                isLoading={this.state.isSaving}
                                color="primaryVLButton"
                                buttonType="submit"
                            />
                          </DialogActions>
                        </form>
                      </Dialog>
                  )}

                  <MUIDataTable
                      title={'Attributes'}
                      data={reveredAttributes}
                      columns={this.props.access.editAttrib ? attributeColumns : attributeColumns.slice(0, -1)}
                      options={{
                        search: false,
                        print: false,
                        filter: false,
                        viewColumns: false,
                        download: false,

                        displaySelectToolbar: false,
                        elevation: 0,
                        selectableRows: false,
                        filterType: "dropdown",
                        responsive,
                      }}
                  />
                </List>

                {this.props.access.addAttrib && (
                    <div style={{ float: "left", margin: "1rem 0" }}>
                      <Button
                          className="primaryVLButton"
                          aria-label="add"
                          onClick={() => this.setState({ addingNewAttribute: true })}
                      >
                        Add Attribute
                      </Button>
                    </div>
                )}
              </div>
              <div style={{ float: "right", margin: "1rem 0" }}>
                <Button
                    onClick={() => {
                      if (!this.state.showCompleted) {
                        this.setState({
                          showCompleted: !this.state.showCompleted,
                          viewType: "list",
                        });
                      } else {
                        this.setState({ showCompleted: !this.state.showCompleted });
                      }
                    }}
                    variant="outlined"
                    className="primaryTextVLButton"
                >
                  {this.state.showCompleted
                      ? `View Active Attributes (${viewNum})`
                      : `View Archived Attributes (${viewNum})`}
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Attributes));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                value: values.value,
              },
            });
          }}
      />
  );
}
