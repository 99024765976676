import React from "react";

class AdminPOSettings extends React.Component {
  render() {
    return (
        <div tyle={{position: 'relative'}}>
          <h5 style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}>Coming soon...</h5>

        </div>
    );
  }
}

export default AdminPOSettings;
