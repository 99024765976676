import React from "react";
import { TextField, MenuItem } from "@material-ui/core";

export default class FormTextField extends React.Component {
  render() {
    const { label, value, onChange, type, select, menuItems } = this.props;
    return (
        <div>
          <span style={{ position: 'relative', top: '10px', paddingRight: '0.6rem' }}>
            {label}:{' '}
          </span>
          <TextField
              select={select}
              value={value}
              onChange={onChange}
              type={type}
          >
            {select && (
                menuItems.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.value}
                    </MenuItem>
                )))}
          </TextField>
        </div>
    );
  }
}
