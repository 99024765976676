import React, { useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const mentionStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: 83,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const merge = (target, ...sources) => {
  return sources.reduce((t, s) => {
    return mergeDeep(t, s);
  }, target);
};

const mergeDeep = (target, source) => {
  let output = Object.assign({}, target);
  if (isPlainObject(target) && isPlainObject(source)) {
    keys(source).forEach((key) => {
      if (isPlainObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

const keys = (obj) => {
  return obj === Object(obj) ? Object.keys(obj) : [];
};

const isPlainObject = (obj) =>
  !(obj instanceof Date) && obj === Object(obj) && !Array.isArray(obj);

function CommentTextArea(props) {
  let style = merge({}, mentionStyle, {
    input: {
      overflow: 'auto',
      height: 90,
      borderRadius: '5px',
    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 90,
    },
  });

  const [value, setValue] = useState({ value: '', plain: '', mentions: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeInternal, setMakeInternal] = useState(false);
  const onChange = (ev, newValue, newPlainTextValue, mentions) => {
    setValue({ value: newValue, plain: newPlainTextValue, mentions });
  };
  const onAdd = (...args) => {
  };

  const handleSubmit = async () => {

if(!value.value) return null
    let newComment = value.value;
    newComment = newComment.split("@@@__")

    let splitSplit = newComment.map(string => {
      let withoutId = string.split("^^__")
      return withoutId.length > 0 ? withoutId[1] : ""
    }).join(`<span className=mentionText>`)

    newComment = splitSplit.split("@@@^^^").join(`</span>`);

    setIsSubmitting(true);
    const comment = await props.submit(
      makeInternal,
      newComment === '' ? value.value : newComment,
      value.mentions
    );

    if (comment === 200) {
      setValue({ value: '', plain: '', mentions: null });
    }
    setIsSubmitting(false);
  };
  const submitButtonText = isSubmitting ? 'Submitting...' : 'Submit';

  return (
    <div className="scrollable">
      <MentionsInput
        value={value.value}
        onChange={onChange}
        style={style}
        placeholder={'Join the conversation... '}
      >
        <Mention
          markup="@@@____id__^^____display__@@@^^^"
          trigger="@"
          data={props.users}
          renderSuggestion={(suggestion, search, highlightedDisplay) => {
            return (
              <div className="user">{`${suggestion.display} (${suggestion.username})`}</div>
            );
          }}
          onAdd={onAdd}
          style={{
            backgroundColor: '#cee4e5',
          }}
        />
      </MentionsInput>

      <div style={{ margin: '0.5rem 0' }}>
        <Button
          variant="contained"
          className="primaryVLButton"
          onClick={handleSubmit}
        >
          {submitButtonText}
          <span style={{ margin: '0.3rem' }} />
          <SendIcon />
        </Button>
        {!props.isVendor && (
          <FormControlLabel
            style={{ margin: '0 0.25rem' }}
            label="Make Internal Only"
            labelPlacement="end"
            control={
              <Checkbox
                id="makeInternal"
                style={{ color: props.fetchInitialData.credentials.primaryAppColor }}
                checked={makeInternal}
                onChange={(event) => setMakeInternal(event.target.checked)}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

export default CommentTextArea;
