import React from "react";
import { withStyles, Grid, Tabs, Button, Tab, Badge } from "@material-ui/core";
import PurchasingRequestHome from "../containers/PurchasingRequestHome";
import PurchaseRequestApprovalHome from "../containers/PurchaseRequestApprovalHome";
import { AntTabs, AntTab, MyBadge } from "../components/common/Tabs";

class ClientPurchaseRequestMod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorTabValue: 0,
    };
  }
  componentDidMount() {
    this.viewHash();
    this.props.fetchInitialData.getNotificationCount(
      this.props.fetchInitialData.credentials.user.clientId,
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
  }
  viewHash = () => {
    this.setState({
      vendorTabValue: window.location.hash === "#pending" ? 1 : 0,
    });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ vendorTabValue: newValue }, () => {
      window.location.hash = newValue === 0 ? "all" : "pending";
    });
  };
  render() {
    const pendingPurchaseCount =
      this.props.fetchInitialData.credentials.vendorPurchaseNotifications >
      0 ? (
        <MyBadge
          bgcolor={this.props.fetchInitialData.credentials.primaryAppColor}
          badgeContent={
            this.props.fetchInitialData.credentials.vendorPurchaseNotifications
          }
        >
          Pending Purchase Requests
          <span style={{ width: "5px" }} />
        </MyBadge>
      ) : (
        "Pending Purchase Requests"
      );
    const tabs = [
      { label: "All Purchase Requests", value: 0 },
      { label: pendingPurchaseCount, value: 1 },
    ];
    return (
      <Grid item xs={12} sm={12} md={12}>
        <AntTabs
          color={this.props.fetchInitialData.credentials.primaryAppColor}
          value={this.state.vendorTabValue}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((el) => (
            <AntTab
              key={el.value}
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              label={el.label}
              value={el.value}
            />
          ))}
        </AntTabs>
        <div
          style={{ position: "relative", height: "36px", marginTop: "1rem" }}
        >
          <div style={{ position: "relative", float: "right", height: "30px" }}>
            <Button
              className="primaryVLButton"
              onClick={() => this.props.history.push("/purchasingrequest")}
            >
              Create Purchase Request
            </Button>
          </div>
        </div>

        {this.state.vendorTabValue === 0 && (
          <PurchasingRequestHome
            history={this.props.history}
            fetchInitialData={this.props.fetchInitialData}
          />
        )}
        {this.state.vendorTabValue === 1 && (
          <PurchaseRequestApprovalHome
            history={this.props.history}
            fetchInitialData={this.props.fetchInitialData}
          />
        )}
      </Grid>
    );
  }
}

export default (ClientPurchaseRequestMod);
