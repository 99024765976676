import React from 'react';
import './Approver.css';
import 'react-table/react-table.css';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core';
import { checkStatus, purchaseRequests, tableData2 } from "../utils/constants/purchaseRequestColumns";
import PdfTableDialog from "../components/pdf/PdfTableDialog";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { responsive } from '../utils/constants/muidatatablesConsts';
import { customTableSort, formatter } from "../utils/tableCustoms";
import moment from "moment-timezone";
import VendorStatus from '../functions/VendorStatus'
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
  tableCard: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 1400
  }
});

class PurchaseRequestApprovalHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '',
      pos: null,
      tableData: null,
      openPrintDialog: false,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const cId = JSON.stringify({
      approval: true,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
    });
    fetch(`${process.env.REACT_APP_API}/get-pos`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: cId,
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            this.setState({
              content: data.data.pos,
              muiTableData: this.makeTableData(data.data.pos),
            });
          } else if (data.status === 400) {
            this.setState({
              pos: [],
              tableData: tableData2,
            });
          }
        })
        .catch((error) => console.log(error));
  }

  makeTableData = (data) => {
    return data.map((po) => {
      let vendorObj = [];
      let deliveryDate,
          createDate = '';
      if (po.DELIVERY_DATE) {
        const date = po.DELIVERY_DATE.split('T')[0].split('-');
        deliveryDate = date[1].concat('/', date[2], '/', date[0]);
      }
      if (po.CREATE_DATE) {
        const date = po.CREATE_DATE.split('T')[0].split('-');
        createDate = date[1].concat('/', date[2], '/', date[0]);
      }
      vendorObj.push(<div
          className="editLink"
          onClick={() => this.props.history.push(`/purchasing-request-approval-details/${po.PO_ID}`)}>
        {po.PO_NUMBER}
      </div>);
      vendorObj.push(po.VENDOR_VNAME);
      vendorObj.push(deliveryDate);
      vendorObj.push(createDate);
      vendorObj.push(po.LOCATION_ID);
      vendorObj.push(formatter.format(po.EXT_AMOUNT));
      vendorObj.push(po.APPROVAL_STATUS_LEVEL);
      vendorObj.push(VendorStatus(po.PO_STATUS));
      return vendorObj;
    });
  }

  render() {
    const { content } = this.state;
    const { classes } = this.props;
    if (!content) {
      return (
          <LoadingCircle/>
      );
    } else {
      return (
          <div className={classes.paymentForm}>
            <div className={classes.tableCard}>
              <MUIDataTable
                  title={'Purchasing Request Approval'}
                  data={this.state.muiTableData}
                  columns={purchaseRequests}
                  options={{
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                    print: false,
                    customSort: (data, colIndex, order) => customTableSort(data, colIndex, order, 5),
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.content.filter((po) => {
                          if (query) {
                            return (
                                po.PO_NUMBER
                                    .toString()
                                    .includes(query.trim()) ||
                                po.VENDOR_VNAME
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                po.LOCATION_ID
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                formatter.format(po.EXT_AMOUNT)
                                    .toString()
                                    .includes(query.trim()) ||
                                po.EXT_AMOUNT.toString()
                                    .includes(query.trim()) ||
                                po.APPROVAL_STATUS_LEVEL
                                    .toString()
                                    .includes(query.trim()) ||
                                checkStatus(po.PO_STATUS)
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                (new Date(po.DELIVERY_DATE).getTime() ?
                                    moment(new Date(po.DELIVERY_DATE)).format('MM/DD/YYYY') : 'N/A')
                                    .toString().toLowerCase()
                                    .includes(query.trim())
                                ||
                                (new Date(po.CREATE_DATE).getTime() ?
                                    moment(new Date(po.CREATE_DATE)).format('MM/DD/YYYY') : 'N/A')
                                    .toString().toLowerCase()
                                    .includes(query.trim())

                            );
                          }
                          return po;
                        }))
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.content)
                      });
                    },
                  }}
              />
            </div>
            <PdfTableDialog
                isLarge
                open={this.state.openPrintDialog}
                fileName={'purchasingRequestsApproval'}
                title={"Purchasing Requests Approval"}
                data={this.state.muiTableData}
                columns={purchaseRequests}
                onClose={() => this.setState({ openPrintDialog: false })}
            />
            <Button
                className="primaryVLButton"
                style={{marginTop: 10}}
                onClick={() => this.setState({ openPrintDialog: true })}>
              PDF
              <PrintIcon style={{ marginLeft: 10 }}/>
            </Button>
          </div>
      );
    }
  }
}

export default withStyles(styles)(PurchaseRequestApprovalHome);
