import React from 'react';
import {Tooltip, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles({
	tooltip: {
		padding: '0.5rem',
		color: 'white',
		backgroundColor: '#545454',
	},
})(Tooltip);

const ellipsisLength = 27;

export const tableCellOverflow = (value, tableMeta, updateValue) => {
	if (value.length <= ellipsisLength) {
		return value;
	} else {
		return (
			<CustomTooltip arrow title={<span style={{ fontSize: '14px' }}>{value}</span>}>
				<Typography style={{ textOverflow: 'ellipsis', fontSize: '14px' }} noWrap={true}>{`${value.slice(0, ellipsisLength)}...`}</Typography>
			</CustomTooltip>
		);
	}
};


export const tableCellNoWrap = (value, tableMeta, updateValue) => {
    return  <Typography style={{ fontSize: '14px' }} noWrap={true}>{value}</Typography>

};