import React from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { responsive } from '../utils/constants/muidatatablesConsts';

const customStyles = () => ({
  red: {
    '& td': { color: 'red' },
  },
});

class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      denseTable: false,
      vertical: true,
    };
  }

  getMuiTheme = () =>
      createMuiTheme({
        overrides: {
          MUIDataTable: {
            root: {
              backgroundColor: '#AAF',
            },
          },
        },
      });


  render() {
    const options = {
      filter: true,
      responsive,
      fixedHeader: false,
      rowHover: true,
      selectableRows: false,
      displaySelectToolbar: false,
      elevation: 0,
      setRowProps: (row) => ({
        className: clsx({
          [this.props.classes.red]: new Date(row[2]) < new Date(),
        })
      }),
      setTableProps: () => {
        return {
          padding: 'default',
          size: 'medium',
        };
      },
      customSort: (data, colIndex, order) => {
        let dateCols = this.props.cols.reduce(
            (dateCols, currCol, index) => {
              if (
                  currCol.name.toLowerCase().indexOf('date') !== -1 ||
                  currCol.name.toLowerCase().indexOf('upload') !== -1
              ) {
                return [...dateCols, index];
              } else return dateCols;
            },
            []
        );

        return data.sort((a, b) => {
          if (dateCols.indexOf(colIndex) !== -1) {
            return a.data[colIndex] === 'N/A'
                ? -1
                : (new Date(a.data[colIndex]) <
            new Date(b.data[colIndex])
                ? -1
                : 1) * (order === 'desc' ? 1 : -1);
          } else {
            if (typeof a.data[colIndex] === 'object') {
              return (
                  (a.data[colIndex].props.children[0] < b.data[colIndex].props.children[0] ? -1 : 1) *
                  (order === 'desc' ? 1 : -1)
              )
            }
            return (
                (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
                (order === 'desc' ? 1 : -1)
            );
          }
        });
      },
    };

    return (
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
              title={this.props.title}
              data={this.props.rows}
              columns={this.props.cols}
              options={options}/>
        </MuiThemeProvider>
    );
  }
}

export default withStyles(customStyles, { name: 'table' })(CustomTable);
