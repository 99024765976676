import React from "react";
import Grid from "@material-ui/core/Grid";
import InvoiceAccounting from "../components/InvoiceAccounting";

class AdminInvoiceSettings extends React.Component {
  render() {
    return (
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <InvoiceAccounting props={this.props}/>
          </Grid>
        </Grid>
    )
  }
}

export default AdminInvoiceSettings;
