export default function FieldChangeActivity(activityObj, callback) {
  if (activityObj.activity["field_changes"]) {
    let jsonChanges = JSON.parse(activityObj.activity["field_changes"]);
    activityObj.activity["ACTIVITY_STRING"] = jsonChanges.reduce(
      (string, change, index) => {
        return (
          string +
          `${change.field} from: ${change.before} to: ${change.after}${
            index + 1 === jsonChanges.length ? "" : ", "
          }`
        );
      },
      activityObj.activity.type
        ? `Updated ${activityObj.activity.type} information: `
        : "Updated information: "
    );
  }
  fetch(`${process.env.REACT_APP_API}/write-to-apc-activity`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(activityObj),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.success) {
        callback(200);
      } else {
        callback(400);
      }
    });
}
