import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, withStyles,
} from '@material-ui/core';

import LoadingButton from '../components/LoadingButton';
import Comments from '../components/Comments';
import Attachments from '../components/Attachments';
import Activity from '../components/Activity';
import VerifyAccess from '../functions/VerifyAccess';
import InvoiceDetailsDist from "../components/invoiceDetails/Distributions";
import InvoiceDetailsAddCodeLineDialog from "../components/invoiceDetails/AddDistCodeLineDialog";
import InvoiceDetailsEditCodeDialog from "../components/invoiceDetails/EditDistCodeDialog";
import ApproveDialog from "../components/invoiceDetails/ApproveDialog";
import InvoiceEditForm from "../components/invoiceDetails/InvoiceEditForm";
import ObjectNotifications from '../components/ObjectNotifications';
import LoadingCircle from "../components/common/LoadingCircle"

class InvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      poNumber: null,
      contract: null,
      refNum: null,
      department: null,
      description: null,
      dueDate: null,
      company: '',
      processLevel: '',
      clientSegments: JSON.parse(
          this.props.fetchInitialData.credentials.clientInfo.segments
      ),
      isOpen: false,
      currentGroupOfCodes: [],
      currentCodeTotal: 0,
      invoiceTranAmount: 0,
      currentCodeEntry: null,
      isLoading: false,
      isNewEntryOpen: false,
      purchaseAccountCategory: null,
      accountingLevels: null,
      accountCatDic: {},
      access: {},
      action: null,
      isSaving: false,
      isApproveDenying: false,
      showAcceptDeny: false,
      isApproveDenyOpen: false,
      uomList: null,
    };
  }

  componentDidMount() {
    VerifyAccess(
        ({ access }) => {
          this.setState({ access });
        },
        ['invoice_approve', 'invoice_deny', 'invoice_edit', 'invoice_distribution'],
        this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.getInvoiceDetails();
    this.getDropdownData();
    this.getAccountingLevels();
  }

  getDropdownData = () => {
    fetch(`${process.env.REACT_APP_API}/get-po-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((data) => {
          const dictionary = data.data.purchaseAccountcategory.reduce(
              (acc, cur) => ({ ...acc, [cur.value]: cur.storeVal }), {}
          );

          this.setState({
            purchaseAccountCategory: data.data.purchaseAccountcategory,
            accountCatDic: dictionary,
          });
        });
  };

  getAccountingLevels = () => {
    this.setState({ isLoadingLevels: true });
    fetch(`${process.env.REACT_APP_API}/get-accounting-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((info) => {
          const accountingLevels = info.data.accountDropdowns.map(
              (drop) => JSON.parse(drop.client_accounting_levels)[0]
          );
          this.setState({ accountingLevels });
        });
  };

  getInvoiceDetails() {
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      invoice_id: window.location.href.split('invoice-details/')[1],
    });
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonVendor,
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.props.fetchInitialData.callSnack(data.error, 'error', 3000);
            return null;
          } else if (data.success) {
            let currentCodeTotal = data.data.apcinvoice.TRAN_INV_AMOUNT;
            let currentCodes = [];
            const invoiceTotal = data.data.apcinvdtl.reduce((acc, line) => acc + line.LINE_TRAN_AMOUNT, 0);
            if (data.data.distrib.length > 0) {
              currentCodes = data.data.distrib.map((distrib) => {
                currentCodeTotal -= distrib.DIST_TRAN_AMOUNT;
                const codeRow = {
                  line: distrib.DIST_LINE_NUM,
                };
                for (let i = 0; i < this.state.clientSegments.length; i++) {
                  codeRow['segment' + (i + 1)] =
                      distrib['SEG' + (i + 1)] === 'null'
                          ? '-'
                          : distrib['SEG' + (i + 1)];
                }
                codeRow['accountCategory'] = distrib.ACCOUNT_CATEGORY;
                codeRow['total'] = distrib.DIST_TRAN_AMOUNT;
                return codeRow;
              });
            }

            let company = '';
            let processLvl = '';
            if (data.data.apcinvoice.COMPANY) {
              company = data.data.apcinvoice.COMPANY;
            }
            if (data.data.apcinvoice.PROCESS_LEVEL) {
              processLvl = data.data.apcinvoice.PROCESS_LEVEL;
            }
            this.setState({
              currentGroupOfCodes: currentCodes,
              content: data.data,
              poNumber: data.data.apcinvoice.PO_NUMBER,
              contract: data.data.apcinvoice.CONTRACT_NUM,
              department: data.data.apcinvoice.DEPARTMENT,
              description: data.data.apcinvoice.DESCRIPTION,
              dueDate: data.data.apcinvoice.DUE_DATE,
              invoiceCurrency: data.data.apcinvoice.CURRENCY,
              refNum: data.data.apcinvoice.REFERENCE_NUM,
              currentCodeTotal,
              invoiceTranAmount: invoiceTotal,
              company,
              processLevel: processLvl,
              isSaving: false,
              isApproveDenying: false,
              showAcceptDeny: data.data.invoiceQueueCheck.flag >= 1,
              uomList: data.data.invoiceUomList
            });
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                4000
            );
          }
        })
        .catch((error) => console.log(error));
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false, isNewEntryOpen: false });
  }

  handleDelete = () => {
    const filteredCodes = this.state.currentGroupOfCodes.filter(
        (seg) => seg.line !== this.state.currentCodeEntry.line
    );
    const codesUpdatedLineNums = filteredCodes.map((code, index) => {
      let updatedCode = Object.assign({}, code);
      updatedCode.line = index + 1;
      return updatedCode;
    });
    let currentTotal = parseFloat(this.state.invoiceTranAmount);
    for (let j = 0; j < filteredCodes.length; j++) {
      currentTotal -= parseFloat(filteredCodes[j].total);
    }
    this.setState({
      currentGroupOfCodes: codesUpdatedLineNums,
      isOpen: false,
      currentCodeTotal: currentTotal.toFixed(2),
    });
  }

  handleUpdate = (event) => {
    event.preventDefault();
    const updatedCodeLine = { line: this.state.currentCodeEntry.line };
    for (let i = 0; i < this.state.clientSegments.length; i++) {
      let property = 'updatedCodeSegment' + i;
      updatedCodeLine['segment' + (i + 1)] = event.target[property].value;
    }
    updatedCodeLine['accountCategory'] = event.target['accountCategory'].value;
    updatedCodeLine['total'] = parseFloat(
        event.target['updatedCodeSegmentTotal'].value.replace(/[^0-9.-]+/g, '')
    );

    let sumOfTotals = 0;
    const updatedGroupOFCodes = this.state.currentGroupOfCodes.map((code) => {
      if (code.line === updatedCodeLine.line) {
        sumOfTotals += parseFloat(updatedCodeLine.total);
        return updatedCodeLine;
      } else {
        sumOfTotals += parseFloat(code.total);
        return Object.assign({}, code);
      }
    });
    this.setState({
      currentGroupOfCodes: updatedGroupOFCodes,
      currentCodeTotal: (this.state.invoiceTranAmount - sumOfTotals).toFixed(2),
      isOpen: false,
    });
  }

  addCodeToGroup = (event) => {
    event.preventDefault();
    let codeRow = { line: this.state.currentGroupOfCodes.length + 1 };
    for (let i = 1; i < this.state.clientSegments.length + 1; i++) {
      let lookup = 'segment' + i;
      codeRow[lookup] = event.target[lookup].value;
    }

    codeRow['accountCategory'] = event.target.accountCategory.value;
    codeRow['total'] = Number(
        event.target.currentCodeLineTotal.value.replace(/[^0-9.-]+/g, '')
    );
    const copyOfCodeGroup = this.state.currentGroupOfCodes.map((line) =>
        Object.assign({}, line)
    );
    copyOfCodeGroup.push(codeRow);

    let currentTotal = parseFloat(this.state.invoiceTranAmount) - codeRow.total;
    for (let j = 0; j < this.state.currentGroupOfCodes.length; j++) {
      currentTotal -= parseFloat(this.state.currentGroupOfCodes[j].total);
    }

    this.setState({
      currentGroupOfCodes: copyOfCodeGroup,
      currentCodeTotal: currentTotal.toFixed(2),
      isNewEntryOpen: false,
    });
  }

  submitCodes = () => {
    this.setState({ isLoading: true });
    const sumOfGroup = this.state.currentGroupOfCodes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total, 0
    );
    const canSubmit =
        parseFloat(this.state.invoiceTranAmount) - 0.01 < sumOfGroup &&
        parseFloat(this.state.invoiceTranAmount) + 0.01 > sumOfGroup;
    if (!canSubmit) {
      this.setState({ isLoading: false });
      this.props.fetchInitialData.createSnack(
          'Code Total must equal invoice subtotal',
          'error',
          4000
      );
    } else {
      const fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        vendorId: this.state.content.apcinvoice.VENDOR_ID,
        invoiceId: this.state.content.apcinvoice.INVOICE_ID,
        jsonArray: this.state.currentGroupOfCodes,
      };

      const fetchString = JSON.stringify(fetchData);

      fetch(`${process.env.REACT_APP_API}/add-invoice-codes`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: fetchString,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.error) {
              this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
              return null;
            } else if (data.success) {
              this.props.fetchInitialData.getNotificationCount(
                  this.props.fetchInitialData.credentials.user.clientId,
                  this.props.fetchInitialData.credentials.user.jsonWebTok
              );
              this.getInvoiceDetails();
              this.props.fetchInitialData.createSnack(
                  'You have successfully saved your changes',
                  'success',
                  4000
              );
            } else {
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => console.log(error));
    }
  }

  handleForm = (event) => {
    event.preventDefault();
    this.setState({ isApproveDenying: true });
    if (this.state.action === 'approve') {
      const invoiceId = this.state.content.apcinvoice.INVOICE_ID;
      let fetchData = {
        action: 'approve',
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        invoiceId: invoiceId,
        currentLevel: this.state.content.apcinvoice.APPROVAL_STATUS_LEVEL,
        invoiceTranAmount: parseInt(
            this.state.content.apcinvoice.TRAN_INV_AMOUNT,
            10
        ),
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/invoice-vendor-details/${invoiceId}`,
      };
      const fetchString = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/approve-deny-invoice`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: fetchString,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.error) {
              this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
              return null;
            } else if (data.success) {
              this.getInvoiceDetails();
              this.props.fetchInitialData.createSnack(
                  'You have successfully approved this invoice',
                  'success',
                  4000
              );
              this.setState({ isApproveDenyOpen: false });
            } else {
              this.setState({ isLoading: false });
              this.props.fetchInitialData.createSnack(
                  'There was an error',
                  'error',
                  4000
              );
            }
          })
          .catch((error) => {
            console.log(error);
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                4000
            );
          });
    } else {
      const invoiceId = this.state.content.apcinvoice.INVOICE_ID;

      let fetchDataDeny = {
        action: 'deny',
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        invoiceId: invoiceId,
        reason: event.target.reason.value,
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/invoice-vendor-details/${invoiceId}`,
      };
      const fetchString = JSON.stringify(fetchDataDeny);
      fetch(`${process.env.REACT_APP_API}/approve-deny-invoice`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: fetchString,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.error) {
              this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
              return null;
            } else if (data.success) {
              this.props.fetchInitialData.getNotificationCount(
                  this.props.fetchInitialData.credentials.user.clientId,
                  this.props.fetchInitialData.credentials.user.jsonWebTok
              );
              this.getInvoiceDetails();
              this.props.fetchInitialData.createSnack(
                  'You have successfully denied this invoice',
                  'success',
                  4000
              );
              this.setState({ isApproveDenyOpen: false });
            } else if (
                data.errorMessage ===
                'ER_SIGNAL_EXCEPTION: You are not supposed to be here'
            ) {
              this.getInvoiceDetails();
              this.props.fetchInitialData.createSnack(
                  'You do not have the credentials to deny this vendor at this time',
                  'error',
                  4000
              );
            }
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                4000
            );
          });
    }
  };
  handleSave = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true })
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      update: true,
      invoiceId: this.state.content.apcinvoice.INVOICE_ID,
      filterValue: this.state.filterValue,
      poNumber: event.target.poNumber.value,
      contract: event.target.contract.value,
      description: event.target.description.value,
      invoiceCurrency: event.target.invoiceCurrency.value,
      refNum: this.state.refNum,
      company: this.state.company,
      processLevel: this.state.processLevel,
      dueDate: event.target.dueDate.value,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.user.clientId,
      invoiceHeaders: !!this.props.fetchInitialData.credentials.isVendor ? null : JSON.stringify(this.state.content.invoiceHeaderFields.reduce((acc, val) => [...acc, {
        field_id: val.field_id,
        value: event.target[val.name + 'Header'].value,
      }], []))
    };
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.props.fetchInitialData.createSnack(data.error, 'error', 3000);
            return null;
          } else if (data.success) {
            if (this.state.action !== 'approve') {
              this.getInvoiceDetails();
              this.props.fetchInitialData.createSnack(
                  'Updates Saved',
                  'success',
                  3000
              );
            }
          } else {
            this.setState({ isSaving: false });
            this.props.fetchInitialData.createSnack(
                'Something went wrong',
                'error',
                3000
            );
          }
        });
  };

  onEditInvoiceDetailsDist = (event, index) => {
    this.setState({
      isOpen: true,
      currentCodeEntry: this.state.currentGroupOfCodes[index],
    })
  }

  onAddInvoiceDetailsDist = () => {
    let total = this.state.invoiceTranAmount;
    this.state.currentGroupOfCodes.forEach((group) => {
      total -= group.total;
    });
    this.setState({
      isNewEntryOpen: true,
      currentCodeTotal: total,
    });
  }

  render() {
    if (!this.state.content) {
      return (
          <LoadingCircle/>
      );
    } else {
      const { classes } = this.props;
      const { apcinvoice, apcinvdtl } = this.state.content;

      return (
          <div className={classes.paymentForm}>
            <ApproveDialog
                onClose={() => this.setState({ isApproveDenyOpen: false })}
                open={this.state.isApproveDenyOpen}
                onSubmit={this.handleForm}
                action={this.state.action}
                fetchInitialData={this.props.fetchInitialData}
                isApproveDenying={this.state.isApproveDenying}
            />
            {this.state.currentCodeEntry && (
                <InvoiceDetailsEditCodeDialog
                    onClose={() => this.setState({ isOpen: false })}
                    open={this.state.isOpen}
                    onSubmit={this.handleUpdate}
                    accountingLevels={this.state.accountingLevels}
                    purchaseAccountCategory={this.state.purchaseAccountCategory}
                    handleDelete={this.handleDelete}
                    fetchInitialData={this.props.fetchInitialData}
                    currentCodeEntry={this.state.currentCodeEntry}
                />
            )}
            <InvoiceDetailsAddCodeLineDialog
                onClose={() => this.setState({ isNewEntryOpen: false })}
                open={this.state.isNewEntryOpen}
                onSubmit={this.addCodeToGroup}
                accountingLevels={this.state.accountingLevels}
                transition={this.Transition}
                fetchInitialData={this.props.fetchInitialData}
                purchaseAccountCategory={this.state.purchaseAccountCategory}
                currentCodeTotal={this.state.currentCodeTotal}
                handleChange={this.handleChange}
            />
            <InvoiceEditForm
                apcinvdtl={apcinvdtl}
                isSaving={this.state.isSaving}
                access={this.state.access} showAcceptDeny={this.state.showAcceptDeny}
                refNum={this.state.refNum} invoiceHeaderValues={this.state.content.invoiceHeaderValues}
                onSubmit={this.handleSave} fetchInitialData={this.props.fetchInitialData}
                apcinvoice={apcinvoice} invoiceHeaderFields={this.state.content.invoiceHeaderFields}
                onDeny={() => this.setState({ action: 'deny', isApproveDenyOpen: true })}
                onApprove={() => this.setState({ action: 'approve', isApproveDenyOpen: true })}
                INVOICE_HEADER_CODES={this.state.content.apcinvoice.INVOICE_HEADER_CODES}
                state={this.state}
                uomList={this.state.uomList}
                handleChange={this.handleChange}
            />
            <br/>
            {this.props.fetchInitialData.credentials.isClient && (
                <InvoiceDetailsDist
                    onSubmit={this.addCodeToGroup} clientSegments={this.state.clientSegments}
                    access={this.state.access} currentGroupOfCodes={this.state.currentGroupOfCodes}
                    fetchInitialData={this.props.fetchInitialData}
                    accountCatDic={this.state.accountCatDic}
                    onEdit={this.onEditInvoiceDetailsDist}
                    onAdd={this.onAddInvoiceDetailsDist}
                />
            )}
            {!!this.state.access.invoice_distribution && (
                <div style={{ marginTop: '1rem' }}>
                  <LoadingButton
                      label="Submit Codes"
                      isLoading={this.state.isLoading}
                      color="primaryVLButton"
                      fn={() => this.submitCodes()}
                  />
                </div>
            )}
            <Attachments
                fetchInitialData={this.props.fetchInitialData}
                objectId={window.location.href.split('invoice-details/')[1]}
                objectType="INVOICE"
            />
            <Comments
                fetchInitialData={this.props.fetchInitialData}
                objectId={window.location.href.split('invoice-details/')[1]}
                objectType="INVOICE"
                vendorId={this.state.content.apcinvoice.VENDOR_ID}
                isVendor={this.props.fetchInitialData.credentials.isVendor}
            />
            <Activity
                title="Invoice History"
                activity={this.state.content.activity}
                timezone={this.props.fetchInitialData.credentials.user.timezone}
                appWidth={this.props.fetchInitialData.credentials.appWidth}
            />

<ObjectNotifications
              createSnack={this.props.fetchInitialData.createSnack}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              parentId={this.state.content.apcinvoice.INVOICE_ID}
              parentType="invoice"
              isVendor={!!this.props.fetchInitialData.credentials.isVendor}
              vendorId={this.state.content.apcinvoice.VENDOR_ID}
            />
          </div>
      );
    }
  }
}

const styles = ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
});

InvoiceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InvoiceDetails);
