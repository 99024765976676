import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
};

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      fileLoad: '',
    };
  }
  componentDidMount() {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      objectId: this.props.objectId,
    };
    fetch(`${process.env.REACT_APP_API}/attachments`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success && data.data.attachments) {
          this.setState({ attachments: data.data.attachments });
        }
      });
  }
  getFile(key, originalName) {
    this.setState({ fileLoad: key });
    var getObject = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      key:
        this.props.fetchInitialData.credentials.clientInfo.client_id +
        '/' +
        key,
      activity: {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: this.props.objectType,
        OBJ_ID: this.props.objectId,
        ACTIVITY_TYPE: 'DOWNLOAD',
        ACTIVITY_STRING: 'downloaded ' + originalName,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      },
    };
    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          window.alert('There was an error');
        }
        if (info.success) {
          const data = info.data.data
          var read = Buffer.from(data.Body.data).toString('base64');
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          var url = `data:${data.ContentType};base64,${read}`;
          a.href = url;
          a.download = originalName;
          a.click();
        } else {
          window.alert('There was an error downloading file')
        }
        this.setState({ fileLoad: '' });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <div>
        {this.state.attachments.length > 0 && (
          <div style={{ margin: '1rem 0' }}>
            <h4>Attachments</h4>
            {this.state.attachments.map((file) => {
              return (
                <div key={file.ATTACHMENT_ID}>
                  <span
                    className="downloadable"
                    download={file.ORIGINAL_NAME}
                    onClick={() => {
                      this.setState({ fileLoad: file.ATTACHMENT_ID });
                      this.getFile(file.ATTACHMENT_ID, file.ORIGINAL_NAME);
                    }}
                  >
                    {file.ORIGINAL_NAME}
                    {this.state.fileLoad === file.ATTACHMENT_ID && (
                      <CircularProgress
                        style={{
                          marginLeft: '0.5rem',
                          color: 'rgb(232,102,19)',
                        }}
                        variant="indeterminate"
                        size={16}
                        thickness={2}
                      />
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

Comments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Comments);
