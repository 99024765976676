import React from "react";
import "./NotFound.css";
import Button from "@material-ui/core/Button";


export function NotFound(props) {
  return (
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
      <Button onClick={() => props.history.push('/')} className="primaryVLButton">Go Home</Button>
    </div>
  )
}

export function AccessDenied(props) {
  return (
    <div className="NotFound">
      <h3>Access Denied!</h3>
      <Button onClick={() => props.history.push('/')} className="primaryVLButton">Go Home</Button>
    </div>
  )
}

export function Submission(props) {
  return (
    <div className="NotFound">
      <h3>Thank you for submitting your application on Vendor Launch.</h3>
        <h5> Please check your email to verify your account.</h5>
      <Button onClick={() => props.history.push('/')} className="primaryVLButton">Go Home</Button>
    </div>
  )
}