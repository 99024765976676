import React from 'react';
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
} from 'date-fns';
import './Calendar.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CircularProgress, Button } from '@material-ui/core';
import LoadingCircle from "../components/common/LoadingCircle"

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      currentMonth: new Date(),
      selectedDate: new Date(),
      isLoading: false,
      rows: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ isLoading: false });
  }

  renderHeader() {
    const dateFormat = 'MMMM yyyy';

    return (
      <div className="header row flex-middle">
        <div className="col col-start hover" onClick={this.prevMonth}>
          <ArrowBackIosIcon />
        </div>
        <div className="col col-center">
          <span>
            <div>{format(this.state.currentMonth, dateFormat)}</div>
            {!isSameDay(new Date(),this.state.selectedDate) ? (
            <div>
              <Button
                onClick={() => this.setToday()}
                variant="outlined"
                className="primaryTextVLButton"
              >
                Go to Today
              </Button>
            </div>
            ) : (
              <div style={{ height: '36px' }}/>
            )}
          </span>
        </div>
        <div className="col col-end hover" onClick={this.nextMonth}>
          <ArrowForwardIosIcon />
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = 'EEE';
    const days = [];

    let startDate = startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div style={{ fontSize: '0.65rem' }} className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells = () => {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = 'dd';
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const count = this.props.expiringDatesCount[
          `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
        ]
          ? this.props.expiringDatesCount[
              `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
            ]
          : '';
        const nCountToday = this.props.notificationDatesCount[
          `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
        ]
          ? this.props.notificationDatesCount[
              `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
            ]['dayOf']
          : '';

        const nCountUpcoming = this.props.notificationDatesCount[
          `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
        ]
          ? this.props.notificationDatesCount[
              `${cloneDay.getUTCFullYear()}-${cloneDay.getMonth()}-${cloneDay.getUTCDate()}`
            ]['upcoming']
          : '';
        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart)
                ? 'disabled'
                : isSameDay(day, selectedDate)
                ? 'selected'
                : ''
            }`}
            key={day}
            onClick={() => this.onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            <div className={'countsContainer'}>
              {parseInt(+ count + nCountToday) !== 0 ? (
                <div>
                  <span className="countToday">
                    {parseInt(+ count + nCountToday) === 0 ? '' : `${+ count + nCountToday}`}
                  </span>
                  <span style={{ fontSize: '0.75rem' }}>: Today</span>
                </div>
              ) : (
                <div style={{ height: '20px' }} />
              )}
              {(+ nCountUpcoming) !== 0 && (
                <div>
                  <span className="countUpcoming">
                    {(+ nCountUpcoming) === 0 ? '' : `${+ nCountUpcoming}`}
                  </span>
                  <span style={{ fontSize: '0.75rem' }}>: Reminder(s)</span>
                </div>
              )}
            </div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({ selectedDate: day }, () => this.props.updateListDate(day));
  };

  setToday = () => {
    this.setState({ isLoading: true });
    const currentMonth = new Date()
    this.props.getNewMonthData(currentMonth);
    this.setState({
      currentMonth: currentMonth,
      selectedDate: currentMonth,
    });
  }

  nextMonth = () => {
    this.setState({ isLoading: true });
    const nextMonth = addMonths(this.state.currentMonth, 1);
    this.props.getNewMonthData(nextMonth);
    this.setState({
      currentMonth: nextMonth,
    });
  };

  prevMonth = () => {
    this.setState({ isLoading: true });
    const prevMonth = subMonths(this.state.currentMonth, 1);
    this.props.getNewMonthData(prevMonth);
    this.setState({
      currentMonth: prevMonth,
    });
  };

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.state.isLoading ? (
          <div style={{ height: '35vh' }}>
            <div style={{ position: 'relative', left: '50%', top: '40%' }}>
              <CircularProgress
                style={{ color: this.props.fetchInitialData.credentials.primaryAppColor }}
                disableShrink
              />
            </div>
          </div>
        ) : (
          this.renderCells()
        )}
      </div>
    );
  }
}

export default Calendar;
