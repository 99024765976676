import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import moment from "moment-timezone";
import MUIDataTable from "mui-datatables";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  withStyles,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from 'clsx';
import LoadingButton from "../components/LoadingButton";
import { userDepartmentCols } from "../utils/constants/userDepartmentCols";
import { responsive } from "../utils/constants/muidatatablesConsts";
import writeToActivity from "../functions/WriteToActivity";
import FieldChangeActivity from "../functions/FieldChangeActivity";
import LoadingCircle from "../components/common/LoadingCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import LambdaFetch from "../functions/FetchFromLambda";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function MyDialog(props) {
  return (
    <Dialog
      fullWidth
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={props.close}
    >
      {props.children}
    </Dialog>
  );
}
function MySelect(props) {
  return (
    <FormControl style={{ width: "100%" }} ccvariant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">
        {props.title}
      </InputLabel>
      <Select
        value={props.value}
        onChange={(e) => props.change(e.target.value)}
        label={props.title}
      >
        {props.options.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function UserFilters(props) {
  const [isOpen, setOpen] = useState(false);
  const [state, setState] = useState({
    mounted: false,
    isLoading: false,
    users: [],
    classifications: [],
    currentUser: "",
    currentFilters: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await LambdaFetch(
        "user-departments",
        "POST",
        props.fetchInitialData.credentials.user.jsonWebTok,
        JSON.stringify({
          action: "read",
          client_id: props.fetchInitialData.credentials.user.clientId,
        }),
        null
      );

      const { data } = result;

      setState({
        ...state,
        users: data.users,
        classifications: data.classifications,
        mounted: true
      });
    };
    fetchData();
  }, []);

  const makeTableData = (arr) => {

    return arr.filter(u => !u.vendor_id).map((el) => [
      el.username,
      el.contract_classification
        ? el.contract_classification.split(",").length
        : 0,
      <p
        onClick={() => {
          setOpen(true);
          setState({ ...state, currentUser: el });
        }}
        className="editLink"
      >
        {!!props.access.settings_users_contract_classifications_edit ? 'edit' : 'view'}
      </p>,
    ]);
  };
  const changeCurrentFilters = (e, values) => {
    e.preventDefault();
    setState({ ...state, currentFilters: values });
  };

  const editClassifications = async (e) => {
    e.preventDefault();
    setState({ ...state, isLoading: true });

    const fetchData = {
      action: "update",
      client_id: props.fetchInitialData.credentials.user.clientId,
      user_id: state.currentUser.uuid,
      classifications: state.currentFilters
        .reduce((acc, cur) => acc.concat(cur.classification_id, ","), "")
        .slice(0, -1),
    };
    const result = await LambdaFetch(
      "user-departments",
      "POST",
      props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData),
      null
    );
    const { data } = result;
    setState({
      ...state,
      users: data.users,
      classifications: data.classifications,
      currentUser: "",
      isLoading: false,
    });
    setOpen(false);
  };

  const options = props.classifications.map((el) => {
    return {
      value: el.classification_id,
      label: el.classification_value,
    };
  });

  let defaultUsers = [];
  if (state.currentUser) {
    defaultUsers = props.classifications.filter((classification) => {
      const classifications = state.currentUser.contract_classification;
      if (!classifications) return false;
      return (
        classifications
          .split(",")
          .indexOf(classification.classification_id.toString()) !== -1
      );
    });
  }

if(!state.mounted){
  return (
  <div style={{ width: "100%" }}>
  <LoadingCircle/>
  </div>
  )
}
  return (
    <div style={{ width: "100%"}}>
      <MyDialog open={isOpen}>
        <form onSubmit={editClassifications}>
          <DialogTitle id="alert-dialog-title">{`Edit User's ${props.classificationType}`}</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              fullWidth
              id="filterValues"
              defaultValue={defaultUsers}
              options={props.classifications}
              disableCloseOnSelect
              onChange={changeCurrentFilters}
              getOptionLabel={(option) => option.classification_value}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{
                      marginRight: 8,
                      color: props.fetchInitialData.credentials.primaryAppColor,
                    }}
                    checked={selected}
                  />
                  {option.classification_value}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  {...params}
                  variant="outlined"
                  style={{ width: "100%" }}
                  label={props.classificationType}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            {!!props.access.settings_users_contract_classifications_edit && (
<LoadingButton
              label="Save"
              isLoading={state.isLoading}
              color="primaryVLButton"
              buttonType="submit"
            />
            ) }
            
          </DialogActions>
        </form>
      </MyDialog>

      <div className="rejectContainer">
        <MUIDataTable
          title={`Users Contract ${props.classificationType}`}
          data={makeTableData(state.users)}
          columns={userDepartmentCols}
          options={{
            filter: false,
            selectableRows: false,
            filterType: "dropdown",
            responsive: "scrollMaxHeight",
            print: false,
          }}
        />
      </div>
      <div style={{height: '1rem' , marginBottom: '1rem'}}></div>
    </div>
  );
}

export default UserFilters;
