import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import {IconButton} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default {
  success: function (msg) {
    this.toast(msg, 'success');
  },
  toast: function (msg, variant) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { variant });
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <SnackbarProvider  maxSnack={3} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint)
  },
  persist: function (msg, variant) {
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    }

    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { 
          variant,     
          persist: true,
      });
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <SnackbarProvider  maxSnack={3} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
      ref={notistackRef}
      action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
              <Close style={{color: 'white'}}/>
          </IconButton>
      )}>
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint)
  }
}