import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, withStyles } from '@material-ui/core';
import PrintIcon from "@material-ui/icons/Print";
import moment from 'moment-timezone';
import VendorStatus from '../functions/VendorStatus'
import 'react-table/react-table.css';
import './Approver.css';
import { vendorsColumns } from "../utils/constants/vendorsColumns";
import PdfTableDialog from "../components/pdf/PdfTableDialog";
import { responsive } from '../utils/constants/muidatatablesConsts';
import LoadingCircle from "../components/common/LoadingCircle"

const styles = ({
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  tableCard: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 1400
  }
})

class AllVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '',
      data: null,
      muiTableData: null,
      currentVendors: [],
      option: 'No Filter',
      archived: false,
      openPrintDialog: false,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API}/get-all-vendor-forms`, {
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          if (data.success) {
            this.setState({
              currentVendors: data.data.vendors.filter((ven) => ven.INACTIVE_FLAG === 0),
              muiTableData: this.makeTableData(data.data.vendors, 0),
              data: data.data.vendors,
            });
          } else {
            window.alert('There was an error fetching all your vendors');
          }
        })
        .catch((error) => console.log(error));
  }

  makeTableData = (data, filter) => {
    return data
        .filter((vendor) => vendor.INACTIVE_FLAG === filter)
        .map((vendor) => {
          const row = [
            vendor.VL_ID,
            <div
                className="editLink"
                onClick={() => this.props.history.push(`/viewvendordetails/${vendor.VENDOR_ID}`)}>
              {vendor.VENDOR_VNAME}
            </div>,
            vendor.CITY_ADDR5,
            vendor.STATE_PROV,
            vendor.SUBMISSION_DATE ? moment(vendor.SUBMISSION_DATE).format('MM/DD/YYYY') : '',
            vendor.ATTACH_COUNT,
            VendorStatus(vendor.VL_STATUS),
          ];

          if (this.props.fetchInitialData.credentials.clientInfo.vendor_numbering_scheme === 'ERP') {
            row.splice(1, 0, vendor.ERP_VENDOR_NUMBER)
          }
          return row
        });
  };
  viewArchived = () => {
    let muiTableData = this.makeTableData(
        this.state.data,
        this.state.archived ? 0 : 1
    );
    this.setState({
      currentVendors: this.state.data.filter(
          (ven) => ven.INACTIVE_FLAG === (this.state.archived ? 0 : 1)
      ),
      muiTableData,
      archived: !this.state.archived,
    });
  };

  render() {
    const { data } = this.state;
    const { classes } = this.props;
    if (!data) {
      return (
          <LoadingCircle/>
      );
    } else {
      let viewNum = this.state.data.length - this.state.muiTableData.length;
      let venCol = vendorsColumns.slice();
      if (this.props.fetchInitialData.credentials.clientInfo.vendor_numbering_scheme === 'ERP') {
        venCol.splice(1, 0, {
          name: 'erp',
          label: 'Erp Number',
          options: {
            filter: false,
            sort: true,
          },
          width: 70,
        },)
      }
      return (
          <div className={classes.paymentForm}>
            <div className={classes.tableCard}>
              <MUIDataTable
                  title={'All Vendors'}
                  data={this.state.muiTableData}
                  columns={venCol}
                  options={{
                    customSort: (data, colIndex, order) => {
                      let dateCols = vendorsColumns.reduce((dateCols, currCol, index) => {
                        if (currCol.name.toLowerCase().indexOf('date') !== -1) {
                          return [...dateCols, index];
                        } else return dateCols;
                      }, []);
                      return data.sort((a, b) => {
                        if (dateCols.indexOf(colIndex) !== -1) {
                          return (
                              (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                                  ? -1
                                  : 1) * (order === 'desc' ? 1 : -1)
                          );
                        } else {
                          return (
                              ((typeof a.data[colIndex] === 'object' ? a.data[colIndex].props.children : a.data[colIndex])
                              < (typeof b.data[colIndex] === 'object' ? b.data[colIndex].props.children : b.data[colIndex])
                                  ? -1 : 1) * (order === 'desc' ? 1 : -1)
                          );
                        }
                      });
                    },
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.data.filter((vendor) => {
                          if (query) {
                            return (
                                vendor.VL_ID
                                    .toString()
                                    .includes(query.trim()) ||
                                vendor.VENDOR_VNAME
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                vendor.STATE_PROV
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                vendor.CITY_ADDR5
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                vendor.ATTACH_COUNT
                                    .toString()
                                    .includes(query.trim()) ||
                                VendorStatus(vendor.VL_STATUS)
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                moment(vendor.SUBMISSION_DATE).format('MM/DD/YYYY')
                                    .toString()
                                    .includes(query.trim())
                            );
                          }
                          return vendor;
                        }), 0)
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.data, 0)
                      });
                    },
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                    print: false,
                  }}
              />
            </div>
            <PdfTableDialog isLarge
                            open={this.state.openPrintDialog}
                            fileName={'allVendors'}
                            columns={venCol}
                            data={this.state.muiTableData}
                            onClose={() => this.setState({ openPrintDialog: false })}
                            title={'All Vendors'}
            />
            <div style={{ margin: '1rem 0', position: 'realtive' }}>
              <Button
                  color={this.state.archived ? 'primary' : 'secondary'}
                  variant="outlined"
                  onClick={() => this.viewArchived()}
              >
                {this.state.archived
                    ? `View Active (${viewNum})`
                    : `View Archived (${viewNum})`}
              </Button>
              <Button
                  className="primaryVLButton"
                  style={{marginLeft: 10}}
                  onClick={() => this.setState({ openPrintDialog: true })}>
                PDF
                <PrintIcon style={{ marginLeft: 10 }}/>
              </Button>
            </div>
          </div>
      );
    }
  }
}

export default withStyles(styles)(AllVendors);
