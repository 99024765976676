import React from "react";

export default function footerLinks(props) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href = {props.hre}
    >
      {props.link}
    </a>
  );
}
