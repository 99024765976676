import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import InputMask from "react-input-mask";
import {
  Typography,
  Grid,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  InputAdornment,
  withStyles,
  Button,
  TextField,
  Paper,
  Tabs,
  Tab,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FieldChangeActivity from "../functions/FieldChangeActivity";
import DropDown from "../components/DropDown";
import Comments from "../components/Comments";
import LoadingButton from "../components/LoadingButton";
import AttachAttribTask from "../components/AttachAttribTask";
import Activity from "../components/Activity";
import ViewVendorLegal from "../components/ViewVendorLegal";
import VendorContracts from "../components/VendorContracts";
import ClientLocationMaintenance from "../components/ClientLocationMaintenance";
import ClientContactMaintenance from "../components/ClientContactMaintenance";
import ConfirmDialog from "../components/ConfirmDialog";
import writeToActivity from "../functions/WriteToActivity";
import VendorStatus from "../functions/VendorStatus";
import VerifyAccess from "../functions/VerifyAccess";
import LambdaFetch from "../functions/FetchFromLambda";
import ObjectNotifications from "../components/ObjectNotifications";
import {
  apcVenMast,
  dropdownsNames,
  forApproverOnlyDropdowns,
  invoiceInformation,
  meta,
  preparerInfo,
  taxClassification,
  vendorDetailsActions,
  vendorDetailsTabs,
} from "../utils/constants/vendorDetailsConstants";
import { AntTabs, AntTab } from "../components/common/Tabs";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = {
  root: {
    color: "rgb(117,117,117)",
    "&$checked": {
      color: "#E86613",
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: "40px",
  },
  button: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:hover": {
      outline: "none",
    },
  },
  buttonProgress: {
    color: "#545454",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formVendor: {
    margin: "0 auto",
    maxWidth: 1000,
    padding: "1rem 3rem 1rem 3rem",
    height: "inherit",
    overflow: "visible",
    "@media (max-width: 500px)": {
      padding: "3%",
    },
    "@media (max-width: 700px)": {
      padding: "3%",
    },
  },
  nonActive: {
    border: "solid #E86613 2px",
    padding: "1rem",
    borderRadius: 10,
    backgroundColor: "#FF9F61",
    margin: "1rem 1rem 0 1rem",
  },
  primaryVLButton: {
    color: "white",
    backgroundColor: "#E86613",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "rgba(232, 102, 19, .9)",
    },
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    "&:hover": {
      color: "#E86613",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#E86613",
    },
    "&:focus": {
      color: "#E86613",
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  tabSelected: {},
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#E86613",
  },
};

class ViewVendorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.masterPHONE_NUM0 = React.createRef();
    this.masterFAX_NUM0 = React.createRef();
    this.contactPHONE_NMBR0 = React.createRef();
    this.contactMOBILE_NUM0 = React.createRef();
    this.contactFAX_NMBR0 = React.createRef();
    this.contactPHONE_NMBR1 = React.createRef();
    this.contactMOBILE_NUM1 = React.createRef();
    this.contactFAX_NMBR1 = React.createRef();
    this.aat = React.createRef();

    this.state = {
      formButton: null,
      isOpen: false,
      isAccept: null,
      content: {},
      updatedFields: null,
      dropdowns: null,
      termCode: null,
      invCurrency: null,
      languageCode: null,
      vendorGroup: null,
      venClass: null,
      taxCode: null,
      holdCode: null,
      distributionCode: null,
      accrualCode: null,
      paymentCode: null,
      cashCode: null,
      reason: "",
      dropdownReason: null,
      noNumberError: "",
      address1country: "",
      address1state: "",
      address1zip: "",
      address2country: "",
      address2state: "",
      address2zip: "",
      errors: {
        masterPHONE_NUM0: "",
        masterFAX_NUM0: "",
        contactPHONE_NMBR0: "",
        contactMOBILE_NUM0: "",
        contactFAX_NMBR0: "",
        contactPHONE_NMBR1: "",
        contactMOBILE_NUM1: "",
        contactFAX_NMBR1: "",
      },
      fileLoad: "",
      isLoading: false,
      isReopenOpen: false,
      isReopening: false,
      attributes: [],
      contractUsers: [],
      notifications: [],
      notificationUsers: [],
      documents: [],
      documentTypes: [],
      isSaving: false,
      viewLegal: false,
      isLoadingLegal: false,
      access: {},
      mainTabValue: 0,
      showAcceptDeny: false,
      allVendors: [],
      whiteList: null,
    };
    this.getData = this.getData.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      vendorDetailsActions,
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    this.getAllVendors();
    this.getData();
  }

  getAllVendors = () => {
    fetch(`${process.env.REACT_APP_API}/get-all-vendor-forms`, {
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        if (data.success) {
          this.setState({
            allVendors: data.data.vendors,
          });
        } else {
          window.alert("There was an error fetching all your vendors");
        }
      })
      .catch((error) => console.log(error));
  };
  viewHash = () => {
    switch (window.location.hash) {
      case "#profile":
        this.setState({ mainTabValue: 0 });
        break;
      case "#attachments":
        this.setState({ mainTabValue: 1 });
        break;
      case "#locations":
        this.setState({ mainTabValue: 2 });
        break;
      case "#people":
        this.setState({ mainTabValue: 3 });
        break;
      case "#attributes":
        this.setState({ mainTabValue: 4 });
        break;
      case "#notifications":
        this.setState({ mainTabValue: 5 });
        break;
      case "#contracts":
        this.setState({ mainTabValue: 6 });
        break;
      default:
        this.setState({ mainTabValue: 0 });
        break;
    }
  };

  getData() {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/get-vendor-forms`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          vendorId: window.location.pathname.split("viewvendordetails/")[1],
          clientId: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          parentType: "vendor",
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/get-all-users`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/read-document`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          type: "vendor",
          parent_id: window.location.pathname.split("viewvendordetails/")[1],
          parentType: "vendor",
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/contract-settings`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: "contract",
        }),
      },
    ];

    Promise.all(
      requests.map((request) =>
        fetch(request.url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
          },
          body: request.body,
        })
          .then((resp) => resp.json())
          .catch(() => {
            this.props.fetchInitialData.createSnack(
              "There was an error fetch the data",
              "error",
              3000
            );
          })
      )
    ).then((info) => {
      if (info[0].statusCode === 410) {
        this.props.history.push("/noauth");
        return null;
      }
      let data = info[0].data;
      let users = info[1].data;
      let docs = info[2];

      const filteredUsers = users.users.filter((user) => {
        return !user.vendor_id;
      });
      const notificationUsers = filteredUsers.map((u, index) => {
        return { title: u.username, index: index, uuid: u.uuid };
      });
      const dropdownMenus = {};
      dropdownsNames.forEach((el) => {
        dropdownMenus[`${el}Dropdown`] = data.dropdowns.filter(
          (option) => option.R_KEY === el
        );
      });
      let rejectReason = data.rejectReasons;
      rejectReason.push({ R_VALUE: "other", DESCRIPTION: "other" });
      dropdownMenus.rejectDropdown = rejectReason;

      let reject;
      if (data.rejectReasons.length !== 0) {
        reject = data.rejectReasons[0].DESCRIPTION;
      } else {
        reject = "other";
      }

      let address1country =
        data.address.length > 0 ? data.address[0].COUNTRY_CODE : "";
      let address1state =
        data.address.length > 0 ? data.address[0].STATE_PROV : "";
      let address1zip =
        data.address.length > 0 ? data.address[0].POSTAL_CODE : "";
      let address2country =
        data.address.length > 1 ? data.address[1].COUNTRY_CODE : "";
      let address2state =
        data.address.length > 1 ? data.address[1].STATE_PROV : "";
      let address2zip =
        data.address.length > 1 ? data.address[1].POSTAL_CODE : "";
      this.setState({
        content: data,
        recievedInfo: true,
        dropdowns: dropdownMenus,
        taxClassification: data.master[0].TAX_CLASS,
        termCode: data.master[0].TERM_CODE,
        invCurrency: data.master[0].INV_CURRENCY,
        languageCode: data.master[0].LANGUAGE_CODE,
        dropdownReason: reject,
        vendorGroup: data.master[0].VENDOR_GROUP,
        venClass: data.master[0].VEN_CLASS,
        taxCode: data.master[0].TAX_CODE,
        holdCode: data.master[0].HLD_CODE,
        distributionCode: data.master[0].DIST_CODE,
        accrualCode: data.master[0].ACCR_CODE,
        paymentCode: data.master[0].BANK_INST_CODE,
        cashCode: data.master[0].CASH_CODE,
        isLoading: false,
        attributes: data.attributes,
        contractUsers: users.users,
        notifications: data.notifications,
        notificationUsers: notificationUsers,
        notificationTypes: data.notification_types,
        documentTypes: data.document_types,
        documents: docs.data.documents,
        isSaving: false,
        isOpen: false,
        address1country,
        address1state,
        address1zip,
        address2country,
        address2state,
        address2zip,
        showAcceptDeny: data.vendor_queue_check.flag >= 1,
        whiteList: info[3].data.whiteList,
      });
    });
  }

  dropDownChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  validate(validateFields) {
    const { errors } = this.state;
    let isError = false;

    if (this.state.content.contact.length > 1) {
      ["masterFAX_NUM0"].forEach((el) => {
        if (validateFields[el].value !== "") {
          if (!this.validatePhoneNumber(validateFields[el].value)) {
            isError = true;
            errors[el] = "Must be of format xxx-xxx-xxxx";
            if (this[el].current) {
              this[el].current.focus();
            }
          } else {
            errors[el] = "";
          }
        } else {
          errors[el] = "";
        }
      });
    }

    if (isError) {
      this.setState({
        errors: errors,
      });
    }
    return isError;
  }

  validatePhoneNumber(number) {
    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return numberPattern.test(number);
  }

  validateVNum(vendorNumber) {
    let isError = false;
    if (this.state.formButton === "accept") {
      if (
        this.props.fetchInitialData.credentials.clientInfo
          .erp_vendor_auto_numbering === 0 &&
        vendorNumber === ""
      ) {
        isError = true;
        this.setState({
          noNumberError: "Must fill in Vendor Number",
        });
      }
    }
    return isError;
  }

  handleAccept = async (save) => {
    if (this.isVendorNameDuplicated()) {
      this.setState({ vendorNameError: true });
      this.props.fetchInitialData.createSnack(
        "Vendor name is duplicated!",
        "warning",
        2000
      );
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    this.setState({
      isLoading: true,
    });
    if (this.state.formButton === "save") {
      this.handleSave();
      return null;
    } else {
      const update = Object.assign(this.state.updatedFields);

      if (save) {
        this.handleSave(update);
      }

      let fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        userId: this.props.fetchInitialData.credentials.user.uuid,
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        objectType: "VENDOR",
        objectId: this.state.content.master[0].VENDOR_ID,
        url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.${process.env.REACT_APP_SITE_URL}/vendor`,
      };
      if (this.state.formButton === "accept") {
        fetchData["action"] = "approve";
        fetchData[
          "currentLevel"
        ] = this.state.content.master[0].APPROVAL_STATUS_LEVEL;
      } else if (this.state.formButton === "deny") {
        fetchData["action"] = "deny";
        if (this.state.dropdownReason === "other") {
          fetchData["reason"] = this.state.reason;
        } else {
          fetchData["reason"] = this.state.dropdownReason;
        }
      }
      const fetchString = JSON.stringify(fetchData);

      const actionString = `Successfully ${
        this.state.formButton === "accept" ? "Approved" : "Denied"
      } Vendor`;

      const action = await LambdaFetch(
        "approve-deny-apc",
        "post",
        this.props.fetchInitialData.credentials.user.jsonWebTok,
        fetchString,
        actionString
      );
      if (!action) {
        this.setState({ isLoading: true }, () => null);
      }
      this.setState({ isLoading: false });
      this.props.history.push("/allvendors");
    }
  };

  handleForm(event) {
    event.preventDefault();
    this.setState({ isOpen: true });
    const form = event.target;
    const masterPairs = {};
    const addressPairs = [];
    const contactPairs = [];

    const err = this.validateVNum(form["autoVendorNumber"].value);

    if (this.state.formButton === "accept") {
      const validateErrors = this.validate(form);
      if (this.state.formButton === "accept" && validateErrors === true) {
        return null;
      }
    }
    if (!err) {
      masterPairs["VENDOR_ID"] = this.state.content.master[0].VENDOR_ID;

      for (let i = 0; i < apcVenMast.length; i++) {
        const masterId = apcVenMast[i]["fieldId"];
        masterPairs[masterId] = form["master" + masterId + 0].value;
      }

      masterPairs.TAX_CLASS = this.state.taxClassification;
      masterPairs.TERM_CODE = this.state.termCode;
      masterPairs.INV_CURRENCY = this.state.invCurrency;
      masterPairs.LANGUAGE_CODE = this.state.languageCode;
      masterPairs.VENDOR_GROUP = this.state.vendorGroup;
      masterPairs.VEN_CLASS = this.state.venClass;
      masterPairs.TAX_CODE = this.state.taxCode;
      masterPairs.HLD_CODE = this.state.holdCode;
      masterPairs.DIST_CODE = this.state.distributionCode;
      masterPairs.ACCR_CODE = this.state.accrualCode;
      masterPairs.BANK_INST_CODE = this.state.paymentCode;
      masterPairs.CASH_CODE = this.state.cashCode;
      masterPairs.AUTO_VENDOR_NUMBER = this.state.autoVendorNumber;
      masterPairs.FILTER_VALUE = this.state.clientFilter;

      const updatedFields = {
        master: masterPairs,
        address: addressPairs,
        contact: contactPairs,
      };

      this.setState({
        updatedFields: updatedFields,
        isOpen: true,
      });
    }
  }

  handleTaxChange(event) {
    this.setState({ taxClassification: event.target.value });
  }

  handleClickOpen() {
    this.setState({ isOpen: false });
  }

  handleChange(event) {
    this.setState({ reason: event.target.value });
  }

  handleClose() {
    this.setState({ isOpen: false, isReopenOpen: false });
  }

  handleReopen = (event) => {
    event.preventDefault();
    this.setState({ isReopening: true });
    const fetchData = JSON.stringify({
      vendorId: this.state.content.master[0].VENDOR_ID,
    });
    fetch(`${process.env.REACT_APP_API}/reopen-apc`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchData,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, "error", 3000);
          this.setState({ isReopening: false });
          return null;
        } else if (info.success) {
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: "VENDOR",
            OBJ_ID: this.state.content.master[0].VENDOR_ID,
            ACTIVITY_TYPE: "REOPEN",
            ACTIVITY_STRING: `Reopened application`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          writeToActivity({ activity }, () => {
            this.setState({ isReopening: false, isReopenOpen: false });
            this.props.fetchInitialData.createSnack(
              "Successfully reopened application",
              "success",
              2000
            );
            this.props.history.push("/allvendors");
          });
        } else {
          this.props.fetchInitialData.createSnack(
            "There was an error reopening application",
            "error",
            3000
          );
          this.setState({ isReopening: false, isReopenOpen: false });
        }
      });
  };
  isVendorNameDuplicated = () =>
    !!this.state.allVendors.filter(
      (vendor) => vendor.VENDOR_VNAME === this.state.vendorName
    ).length;
  handleSave = async (saveFields) => {
    this.setState({ isLoading: true });
    if (this.state.formButton === "save") {
      saveFields = this.state.updatedFields;
    }
    saveFields[
      "jsonWebTok"
    ] = this.props.fetchInitialData.credentials.user.jsonWebTok;

    const stringUpdate = JSON.stringify(saveFields);

    const action = await LambdaFetch(
      "save-apc",
      "post",
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      stringUpdate,
      this.state.formButton === "save" ? "Successfully saved changes" : null
    );

    if (!action) return null;
    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: "VENDOR",
      OBJ_ID: this.state.content.master[0].VENDOR_ID,
      ACTIVITY_TYPE: "SAVE",
      ACTIVITY_STRING: " Saved updates",
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      field_changes: action.data.changes.field_changes,
    };

    FieldChangeActivity({ activity }, () => {
      if (this.state.formButton === "save") {
        this.getData();
      }
      this.setState({ vendorNameError: false });
    });
  };

  approverTextField(input, index, fieldGroup, readOnly) {
    const { classes } = this.props;

    if (
      input.fieldId === "PHONE_NMBR" ||
      input.fieldId === "PHONE_NUM" ||
      input.fieldId === "MOBILE_NUM" ||
      input.fieldId === "FAX_NMBR" ||
      input.fieldId === "FAX_NUM"
    ) {
      return (
        <InputMask
          key={input.fieldId}
          mask="999-999-9999"
          defaultValue={input.defaultValue}
        >
          {() => (
            <TextField
              inputRef={this[fieldGroup + input.fieldId + index]}
              key={fieldGroup + input.fieldId + index}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              fullWidth
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <span />
                  </InputAdornment>
                ),
              }}
              error={
                this.state.errors[fieldGroup + input.fieldId + index] !== ""
              }
              helperText={this.state.errors[fieldGroup + input.fieldId + index]}
            />
          )}
        </InputMask>
      );
    }
    if (readOnly) {
      return (
        <TextField
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          disabled
          variant="filled"
          margin="dense"
          InputProps={{
            classes: { input: classes.resize, root: classes.textFieldRoot },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      );
    }
    if (index === null) index = "";
    if (input.half) {
      return (
        <span key={input.fieldId} style={{ marginRight: "5px" }}>
          <TextField
            key={input.fieldId}
            id={fieldGroup + input.fieldId + index}
            label={input.fieldName}
            defaultValue={input.defaultValue}
            variant="filled"
            margin="dense"
            InputProps={{
              classes: { input: classes.resize, root: classes.textFieldRoot },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              ),
            }}
          />
        </span>
      );
    } else {
      return (
        <TextField
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          variant="filled"
          margin="dense"
          InputProps={{
            classes: { input: classes.resize, root: classes.textFieldRoot },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
          }}
        />
      );
    }
  }

  handleMainTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        window.location = "#profile";
        break;
      case 1:
        window.location = "#attachments";
        break;
      case 2:
        window.location = "#locations";
        break;
      case 3:
        window.location = "#people";
        break;
      case 4:
        window.location = "#attributes";
        break;
      case 5:
        window.location = "#notifications";
        break;
      case 6:
        window.location = "#contracts";
        break;
      default:
        window.location = "#profile";
        break;
    }
    this.setState({ mainTabValue: newValue });
  };

  handleCountryStateZipChange = (type, value) => {
    this.setState({ [type]: value });
  };
  viewLegal = () => {
    this.setState({ isLoadingLegal: true });
    if (this.state.access.vendor_legal_edit || this.state.vendor_legal_view) {
      const activity = {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: "VENDOR",
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: "VIEWED",
        ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} viewed legal information`,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      };
      writeToActivity({ activity }, () => {
        this.setState({ viewLegal: true, isLoadingLegal: false });
      });
    } else {
      this.setState({ isLoadingLegal: true }, () => {
        this.props.fetchInitialData.createSnack(
          "You do not have credentials to view legal information",
          "warning",
          3000
        );
      });
    }
  };
  handleActivation = async (id, callback) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: "vendor",
      newStatus: this.state.content.master[0].INACTIVE_FLAG === 1 ? 0 : 1,
      id: id,
    };

    const data = await LambdaFetch(
      "update-status",
      "post",
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify(fetchData),
      `Successfully ${
        this.state.content.master[0].INACTIVE_FLAG === 1
          ? "activated"
          : "inactivated"
      } vendor`
    );

    if (data.success) {
      let status =
        this.state.content.master[0].INACTIVE_FLAG === 1
          ? "activated"
          : "inactivated";
      const activity = {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: "VENDOR",
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: status.toUpperCase(),
        ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} ${status} vendor`,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      };
      writeToActivity({ activity }, () => {
        this.getData();
        callback(200);
      });
    } else {
      callback(400);
    }
  };
  render() {
    const { classes } = this.props;
    if (!this.state.content.master) {
      return (
        <LoadingCircle/>
      );
    } else {
      const master = meta.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy["defaultValue"] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });

      const preparer = preparerInfo.map((input) => {
        const inputCopy = Object.assign({}, input);
        inputCopy["defaultValue"] = this.state.content.master[0][input.fieldId];
        return inputCopy;
      });

      let submissionDate = "";
      if (this.state.content.master[0]["SUBMISSION_DATE"]) {
        submissionDate = moment(
          new Date(this.state.content.master[0]["SUBMISSION_DATE"])
        ).format("MMMM Do YYYY, h:mm:ss a");
      }
      const dropdownDefaults = {
        vendorGroupDropdown: "",
        venClassDropdown: "",
        taxCodeDropdown: "",
        holdCodeDropdown: "",
        distributionCodeDropdown: "",
        accrualCodeDropdown: "",
        paymentCodeDropdown: "",
        cashCodeDropdown: "",
      };

      for (let property in dropdownDefaults) {
        if (dropdownDefaults.hasOwnProperty(property)) {
          if (
            !this.state.dropdowns[property] ||
            this.state.dropdowns[property].length === 0
          ) {
            dropdownDefaults[property] = "";
          }
        }
      }

      dropdownsNames.slice(3).forEach((el) => {
        if (this.state[el] !== null) {
          dropdownDefaults[`${el}Dropdown`] = this.state[el];
        }
      });

      const showAutoNumbering =
        this.props.fetchInitialData.credentials.clientInfo
          .erp_vendor_auto_numbering === 0
          ? {}
          : { display: "none" };

      let message,
        saveAnd = "Okay";

      if (this.state.formButton === "save") {
        message = "You are about to save the changes you have made";
      } else if (this.state.formButton === "deny") {
        saveAnd = "Save and Deny";
        message =
          "You are about to deny this vendor's application. Please give the reason why:";
      } else {
        saveAnd = "Save and Approve";
        message =
          this.props.fetchInitialData.credentials.clientInfo
            .no_approval_levels ===
          this.state.content.master[0].APPROVAL_STATUS_LEVEL
            ? "You are about to accept this vendor into your ERP system"
            : `You are about the accept this vendor to level ${this.state.content.master[0].APPROVAL_STATUS_LEVEL}`;
      }

      const dropdownReason = !(this.state.formButton === "deny") ? (
        ""
      ) : (
        <DropDown
          id="dropdownReason"
          showWithDescription
          required
          passDescription
          default={this.state.dropdownReason}
          options={this.state.dropdowns.rejectDropdown}
          label="Rejection Reason"
          passUp={this.dropDownChange}
        />
      );
      const reasonMessage =
        this.state.dropdownReason === "other" &&
        this.state.formButton !== "save" ? (
          <div>
            <TextField
              id="reason"
              multiline
              required
              rows="3"
              label="Please provide a reason"
              fullWidth
              onBlur={this.handleChange}
              margin="normal"
              variant="filled"
            />
          </div>
        ) : (
          ""
        );
      return (
        <div>
          <div style={{ backgroundColor: "white", width: "100%", zIndex: 200 }}>
          <AntTabs
            color={this.props.fetchInitialData.credentials.primaryAppColor}
            value={this.state.mainTabValue}
            onChange={this.handleMainTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
          {vendorDetailsTabs.map((el, index) => {
                let show = true;
                if (el.accessCode) {
                  el.accessCode.forEach((code) => {
                    if (!this.state.access[code]) {
                      show = false;
                    }
                  });
                }
                if (el.license) {
                  if(this.props.fetchInitialData.credentials.clientInfo[el.license] !== 1) {
                    show = false
                  }
                }
                if (show) {
                  return (
                     <AntTab
                key={el.value}
                color={this.props.fetchInitialData.credentials.primaryAppColor}
                label={el.label}
                value={el.value}
              />
                  );
                }
              })}
      
          </AntTabs>
          </div>
          {this.state.viewLegal && (
            <ViewVendorLegal
              access={this.state.access}
              fetchInitialData={this.props.fetchInitialData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              vendorId={this.state.content.master[0].VENDOR_ID}
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              user={this.props.fetchInitialData.credentials.user}
              handleClose={() => {
                this.setState({ viewLegal: false }, () => this.getData());
              }}
            />
          )}

          <div className={classes.formVendor}>
            <Dialog
              open={this.state.isOpen}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
                {dropdownReason}
                {reasonMessage}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Back</Button>
                <Button
                  onClick={() => this.handleAccept(true)}
                  autoFocus
                  disabled={!!this.state.isLoading}
                  variant="contained"
                  style={{
                    backgroundColor: this.state.isLoading ? "" : this.props.fetchInitialData.credentials.primaryAppColor,
                    color: "#FFFFFF",
                  }}
                >
                  {this.state.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}

                  {saveAnd}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.isReopenOpen}
              onClose={this.handleClose}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <form onSubmit={this.handleReopen}>
                <DialogTitle id="alert-dialog-title">Resubmit</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <div>
                      Please confirm that you have addressed the problem below
                      before resubmiting:
                    </div>
                    <div style={{ fontWeight: 400, color: "black" }}>
                      {this.state.content.master[0].REASON}
                    </div>
                    <div style={{ marginTop: "0.5rem" }}>
                      This action will restart the approval process for this
                      vendor. Are you sure you wan to proceed?
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose}>Cancel</Button>
                  <LoadingButton
                    label="Confirm"
                    isLoading={this.state.isReopening}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                </DialogActions>
              </form>
            </Dialog>

            {this.state.content.master[0].VL_STATUS === 5 &&
              !!this.state.access.vendor_reopen && (
                <Alert
                  severity="error"
                  style={{ margin: "1rem 0" }}
                  action={
                    <Button
                      onClick={() => this.setState({ isReopenOpen: true })}
                      variant="contained"
                      disableElevation
                      style={{ marginLeft: "0.5rem" }}
                      color="secondary"
                      size="small"
                    >
                      Resubmit
                    </Button>
                  }
                >
                  {`Status: Rejected (${this.state.content.master[0].REASON})`}
                </Alert>
              )}

            {this.state.mainTabValue === 0 && (
              <Paper style={{ padding: " .5rem 0" }}>
                <div style={{ margin: "1rem" }}>
                  <form onSubmit={this.handleForm}>
                    {this.state.mainTabValue === 0 && (
                      <>
                        <div
                          style={{ position: "relative", marginTop: ".25rem" }}
                        >
                          {this.state.content.master[0].INACTIVE_FLAG === 1 && (
                            <div
                              style={{ margin: "1rem auto" }}
                              className={classes.nonActive}
                            >
                              <div>**This vendor is marked as inactive</div>
                            </div>
                          )}
                          <h2>{this.state.content.master[0]["VENDOR"]}</h2>
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: "0px",
                              fontSize: "12px",
                            }}
                          >
                            <div>
                              Status:{" "}
                              {VendorStatus(
                                this.state.content.master[0].VL_STATUS
                              )}
                            </div>
                            <div>
                              Approval Level:{" "}
                              {this.state.content.master[0]
                                .APPROVAL_STATUS_LEVEL - 1}
                            </div>
                          </div>
                          <div style={{ fontSize: "10px" }}>
                            Submission Date: {submissionDate}
                          </div>
                        </div>
                        <div style={{ marginTop: "0.5rem" }}>
                          {this.state.showAcceptDeny && (
                            <Fragment>
                              {!!this.state.access.vendor_approve && (
                                <Button
                                  variant="contained"
                                  id="accept"
                                  type="submit"
                                  onClick={() => {
                                    this.setState({
                                      formButton: "accept",
                                      noNumberError: "",
                                    });
                                  }}
                                  style={{
                                    marginRight: "0.5rem",
                                  }}
                                  className="primaryVLButton"
                                >
                                  Accept
                                </Button>
                              )}

                              {!!this.state.access.vendor_deny && (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  id="deny"
                                  type="submit"
                                  onClick={() => {
                                    this.setState({
                                      formButton: "deny",
                                      noNumberError: "",
                                    });
                                  }}
                                  style={{ marginRight: "0.5rem" }}
                                >
                                  Deny
                                </Button>
                              )}
                            </Fragment>
                          )}
                        </div>
                        <hr />
                      </>
                    )}

                    <Grid container justify="flex-start" spacing={4}>
                      <Grid item xs={12} sm={6} md={6}>
                        <h5>Vendor Information</h5>
                        {master.map((input) =>
                          this.approverTextField(
                            input,
                            0,
                            "master",
                            input.readOnly
                          )
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h6">
                          {
                            this.props.fetchInitialData.credentials.clientInfo
                              .filter_value_label
                          }
                        </Typography>
                        <TextField
                          id="clientFilter"
                          variant="filled"
                          margin="dense"
                          defaultValue={
                            this.state.content.master[0].FILTER_VALUE
                          }
                          InputProps={{
                            classes: {
                              input: classes.resize,
                              root: classes.textFieldRoot,
                            },
                          }}
                        />
                        <Typography variant="h6" style={{ paddingTop: "2%" }}>
                          Tax Classification
                        </Typography>
                        <FormControl margin="dense" component="fieldset">
                          <FormLabel component="legend" />
                          <RadioGroup
                            aria-label="taxClassification"
                            name="taxClassification"
                            value={this.state.taxClassification}
                            onChange={this.handleTaxChange}
                          >
                            {taxClassification.map((el, index) => (
                              <FormControlLabel
                                key={index}
                                value={el}
                                control={
                                  <Radio
                                    className="vl-primary-text-color"
                                  />
                                }
                                label={el}
                                labelPlacement="end"
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="masterCOMMENT0"
                          label="Describe your service/products"
                          fullWidth
                          defaultValue={this.state.content.master[0]["COMMENT"]}
                          multiline
                          rows="2"
                          variant="filled"
                          margin="dense"
                          InputProps={{ classes: { input: classes.resize } }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h5>Preparer's Information</h5>
                        {preparer.map((input) =>
                          this.approverTextField(
                            input,
                            0,
                            "master",
                            input.readOnly
                          )
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <h5>Invoice Information</h5>
                        {invoiceInformation.map((el, index) => (
                          <DropDown
                            key={index}
                            id={el.id}
                            options={this.state.dropdowns[el.options]}
                            default={this.state[el.defaultState]}
                            label={el.label}
                            passUp={this.dropDownChange}
                          />
                        ))}
                      </Grid>

                      {!!this.state.access.vendor_approve && (
                        <>
                          <div style={{ width: "100%" }}>
                            <h5 style={{ paddingLeft: "16px", margin: 0 }}>
                              For Approver Only
                            </h5>
                          </div>
                          <Grid item xs={12} sm={6} md={6}>
                            {forApproverOnlyDropdowns
                              .slice(0, 4)
                              .map((el, index) => (
                                <DropDown
                                  key={index}
                                  id={el.id}
                                  showWithDescription={el.showWithDescription}
                                  disabled={!this.state.access.vendor_approve}
                                  options={this.state.dropdowns[el.options]}
                                  default={dropdownDefaults[el.defaultState]}
                                  label={el.label}
                                  passUp={this.dropDownChange}
                                />
                              ))}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            {forApproverOnlyDropdowns
                              .slice(4)
                              .map((el, index) => (
                                <DropDown
                                  key={index}
                                  id={el.id}
                                  showWithDescription={el.showWithDescription}
                                  disabled={!this.state.access.vendor_approve}
                                  options={this.state.dropdowns[el.options]}
                                  default={dropdownDefaults[el.defaultState]}
                                  label={el.label}
                                  passUp={this.dropDownChange}
                                />
                              ))}
                          </Grid>
                        </>
                      )}

                      <Grid style={showAutoNumbering} item xs={6} sm={6} md={6}>
                        <TextField
                          id="autoVendorNumber"
                          label="ERP Vendor Number"
                          fullWidth
                          defaultValue={
                            this.state.content.master[0].LAWSON_VENDOR_NUMBER
                          }
                          margin="dense"
                          variant="filled"
                          error={this.state.noNumberError !== ""}
                          helperText={this.state.noNumberError}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {!!this.state.access.vendor_edit && (
                          <Button
                            variant="contained"
                            id="save"
                            type="submit"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                formButton: "save",
                                noNumberError: "",
                              });
                            }}
                            style={{ marginRight: "0.5rem" }}
                          >
                            Save
                          </Button>
                        )}

                        {!!(
                          this.state.access.vendor_legal_edit ||
                          this.state.vendor_legal_view
                        ) && (
                          <Button
                            variant="outlined"
                            disabled={this.state.isLoadingLegal}
                            onClick={() => this.viewLegal()}
                          >
                            {this.state.isLoadingLegal ? (
                              <div>
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                                View/Edit Legal Information
                              </div>
                            ) : (
                              "View/Edit Legal Information"
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Paper>
            )}
            {!!(
              this.state.access.vendor_location_edit ||
              this.state.access.vendor_location_view
            ) &&
              this.state.mainTabValue === 2 && (
                <div style={{ marginTop: "1rem" }}>
                  <ClientLocationMaintenance
                    getData={this.getData}
                    access={this.state.access}
                    address={this.state.content.address}
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    jsonWebTok={
                      this.props.fetchInitialData.credentials.user.jsonWebTok
                    }
                    fetchInitialData={this.props.fetchInitialData}
                  />
                </div>
              )}

            {!!(
              this.state.access.vendor_contact_edit ||
              this.state.access.vendor_contact_view
            ) &&
              this.state.mainTabValue === 3 && (
                <div style={{ marginTop: "1rem" }}>
                  <ClientContactMaintenance
                    getData={this.getData}
                    access={this.state.access}
                    contacts={this.state.content.contact}
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    jsonWebTok={
                      this.props.fetchInitialData.credentials.user.jsonWebTok
                    }
                    fetchInitialData={this.props.fetchInitialData}
                  />
                </div>
              )}
            <AttachAttribTask
              tab={this.state.mainTabValue}
              props={this.props}
              getData={this.getData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              currentUser={this.props.fetchInitialData.credentials.user.uuid}
              parentType="vendor"
              vendorId={this.state.content.master[0].VENDOR_ID}
              parentId={this.state.content.master[0].VENDOR_ID}
              whiteList={this.state.whiteList}
            />
            {this.state.mainTabValue === 6 && (
              <div style={{ padding: "0 1rem", marginTop: "1.5rem" }}>
                <VendorContracts
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  props={this.props}
                />
              </div>
            )}

            {this.state.mainTabValue === 0 && (
              <>
                {!!this.state.access.comment_add && (
                  <Comments
                    fetchInitialData={this.props.fetchInitialData}
                    objectId={
                      window.location.pathname.split("vendordetails/")[1]
                    }
                    objectType="VENDOR"
                    vendorId={this.state.content.master[0].VENDOR_ID}
                    isVendor={this.props.fetchInitialData.credentials.isVendor}
                  />
                )}
                {!!this.state.access.history_view && (
                  <Activity
                    title="Vendor Account History"
                    activity={this.state.content.activity}
                    timezone={
                      this.props.fetchInitialData.credentials.user.timezone
                    }
                    appWidth={this.props.fetchInitialData.credentials.appWidth}
                  />
                )}

                <ObjectNotifications
                  createSnack={this.props.fetchInitialData.createSnack}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  parentId={this.state.content.master[0].VENDOR_ID}
                  parentType="vendor"
                  isVendor={!!this.props.fetchInitialData.credentials.isVendor}
                  vendorId={this.state.content.master[0].VENDOR_ID}
                />
              </>
            )}
            {!!this.state.access.vendor_delete &&
              this.state.mainTabValue === 0 && (
                <div style={{ marginTop: "1rem" }}>
                  {this.state.content.master[0].INACTIVE_FLAG === 1 ? (
                    <ConfirmDialog
                      handleConfirm={this.handleActivation}
                      title="Reactivate Vendor"
                      color="green"
                      reactivate
                      type="vendor"
                      dialogTitle="Reactivate"
                      dialogText="Are you sure you want to reactivate this vendor?"
                      id={this.state.content.master[0].VENDOR_ID}
                    />
                  ) : (
                    <ConfirmDialog
                      handleConfirm={this.handleActivation}
                      title="Inactivate Vendor"
                      color="red"
                      inactivate
                      type="vendor"
                      dialogTitle="Inactivate"
                      dialogText="Are you sure you want to mark this vendor as inactive?"
                      id={this.state.content.master[0].VENDOR_ID}
                    />
                  )}
                </div>
              )}
          </div>
        </div>
      );
    }
  }
}

ViewVendorDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewVendorDetails);
