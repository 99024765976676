import React from 'react';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  withStyles, Grid, Container, TextField, InputAdornment, Button, Checkbox, FormControlLabel, CircularProgress,
  InputLabel, FormControl, Select, MenuItem, DialogContent, DialogTitle, Dialog, DialogActions, DialogContentText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import uuidv1 from 'uuid/v1';

import { withSnackbar } from 'notistack';
import writeToActivity from '../functions/WriteToActivity';
import SuggestionSql from './SuggestionSql';
import LoadingButton from './LoadingButton';

const styles = () => ({
  root: {
    '& label.Mui-focused': {
      color: 'rgb(232, 102, 19);',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgb(232, 102, 19);',
    },
  },
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CreateContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      startDate: '',
      endDate: '',
      vendors: [],
      contractAmount: '',
      contractType: '',
      vendorSelect: null,
      hasExpiration: false,
      addTask: false,
      newVendorModalOpen: false,
      newVendorName: '',
      contractAmountUsed: '',
      locations: [],
      location: null,
      hasInactiveLocation: false,
      newContract: null,
    };
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  getLocations(id) {
    fetch(`${process.env.REACT_APP_API}/get-locations`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ vendorId: id }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (!info.success) return null;
          const data = info.data;
          let locations = data.locations.map((l) => {
            return Object.assign(
                {},
                l,
                l.INACTIVE_FLAG === 1
                    ? { NAME: `${l.NAME} (Inactive)`, ONLY_NAME: l.NAME }
                    : { ONLY_NAME: l.NAME },
                l.PRIMARY_ADDR === 1
                    ?{ NAME: `${l.NAME} (Primary)`, ONLY_NAME: l.NAME }
                    : { ONLY_NAME: l.NAME },
            );
          });
          this.setState({ locations, location: null });
        });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submitNewContract = (event) => {
    event.preventDefault();
    const {
      startDate,
      endDate,
      vendorSelect,
      contractAmount,
      contractAmountUsed,
      location,
      contractType,
      hasExpiration,
    } = this.state;

    if (!vendorSelect) {
      this.createSnack('Please choose a vaild vendor', 'warning', 3000);
      return null;
    }
    const newContract = {
      client_id: this.props.props.fetchInitialData.credentials.user.clientId,
      vendor_id: vendorSelect,
      title: event.target.title.value,
      description: event.target.description.value,
      contract_number: event.target.contractNumber.value,
      contract_type_id: contractType,
      classification1: this.state.classification1Value ? this.state.classification1Value : '',
      start_date: startDate,
      revisions: '',
      status: 1,
      end_date: hasExpiration ? null : endDate,
      contract_amount: contractAmount,
      amount_used: contractAmountUsed,
      location_id: location ? location.ADDR_ID : null,
    };

    let sDate = new Date(startDate);
    let eDate = new Date(endDate);

    if (sDate > new Date('3000-01-01')) {
      this.createSnack('Enter a valid start date', 'warning', 3000);
      return null;
    }
    if (!hasExpiration) {
      if (eDate > new Date('3000-01-01')) {
        this.createSnack('Enter a valid end date', 'warning', 3000);
        return null;
      }
      if (sDate > eDate) {
        this.createSnack('End date must be after start date', 'warning', 3000);
        return null;
      }
    }

    if (this.state.location) {
      if (this.state.location.INACTIVE_FLAG === 1) {
        this.setState({ hasInactiveLocation: true, newContract });
        return null;
      } else {
        this.handleSubmit(newContract);
      }
    } else {
      this.createSnack('Need to add a location', 'warning', 3000);
      return null;
    }
  };
  handleSubmit = (newContract) => {
    this.setState({ isLoading: true });

    if (this.state.hasExpiration) {
      newContract['end_date'] = 'N/A';
    }
    fetch(`${process.env.REACT_APP_API}/create-contract`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.props.fetchInitialData.credentials.user
            .jsonWebTok,
        newContract: newContract,
      }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            let data = info.data
            const id = data.insertId;
            const activity = {
              CLIENT_ID: this.props.props.fetchInitialData.credentials.clientInfo
                  .client_id,
              OBJ_TYPE: 'CONTRACT',
              OBJ_ID: data.insertId,
              ACTIVITY_TYPE: 'CREATED',
              ACTIVITY_STRING: `Created contract`,
              USER_ID: this.props.props.fetchInitialData.credentials.user.uuid,
            };

            writeToActivity({ activity }, () => {
              if (this.state.addTask) {
                this.addNotification(
                    {
                      contractId: id,
                      date: newContract.end_date,
                      title: `${newContract.title} Expires`,
                      description: `${newContract.title} expires. Take the necessary actions required.`,
                      notification_type_id: 1,
                      to_notify: 1,
                      days_90: 0,
                      days_60: 0,
                      days_30: 1,
                      days_7: 0,
                      days_0: 1,
                      users_to_notify: [
                        this.props.props.fetchInitialData.credentials.user.uuid,
                      ],
                    },
                    (status) => {
                      if (status === 200) {
                        this.createSnack(
                            'Successfully created contract',
                            'success',
                            2000
                        );
                        this.setState(
                            { isLoading: false, hasInactiveLocation: false },
                            () => {
                              this.props.props.history.push(`contract-details/${id}`);
                            }
                        );
                      } else {
                        this.createSnack(
                            'There was an error creating expiration task',
                            'error',
                            2000
                        );
                        this.setState(
                            { isLoading: false, hasInactiveLocation: false },
                            () => {
                              this.props.props.history.push(`contract-details/${id}`);
                            }
                        );
                      }
                    }
                );
              } else {
                this.createSnack(
                    'Successfully created Contract',
                    'success',
                    2000
                );
                this.setState(
                    { isLoading: false, hasInactiveLocation: false },
                    () => {
                      this.props.props.history.push(`contract-details/${id}`);
                    }
                );
              }
            });
          } else {
            this.setState({ isLoading: false }, () => {
              this.createSnack(
                  'There was an error created your contract',
                  'error',
                  2000
              );
            });
          }
        });
  };
  addNotification = (newNotification, callback) => {
    newNotification['parent_type'] = 'contract';
    newNotification['parent_id'] = newNotification.contractId;
    const fetchData = {
      jsonWebTok: this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      notification: newNotification,
    };

    fetch(`${process.env.REACT_APP_API}/insert-notification`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.props.fetchInitialData.credentials.user
            .jsonWebTok,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            const activity = {
              CLIENT_ID: this.props.props.fetchInitialData.credentials.clientInfo
                  .client_id,
              OBJ_TYPE: 'CONTRACT',
              OBJ_ID: newNotification.contractId,
              ACTIVITY_TYPE: 'CREATE',
              ACTIVITY_STRING: 'Created contract task',
              USER_ID: this.props.props.fetchInitialData.credentials.user.uuid,
            };
            writeToActivity({ activity }, () => {
              callback(200);
            });
          } else {
            this.createSnack(
                'There was an error inserting your notification',
                'error',
                2000
            );
            callback(400);
          }
        });
  };
  createVendor = (event) => {
    event.preventDefault();
    this.setState({ isLoadingNewVendor: true });
    let form = event.target;
    const fetchData = {
      vendorId: uuidv1(),
      addressId: uuidv1(),
      clientId: this.props.props.fetchInitialData.credentials.user.clientId,
      vendorVname: form.vendorVname.value,
      addr1: form.addr1.value,
      addr2: form.addr2.value,
      cityAddr5: form.cityAddr5.value,
      stateProv: form.stateProv.value,
      postalCode: form.postalCode.value,
      filterValue: form.filterValue.value,
      email: form.email.value,
      submissionDate: moment().format('LLL'),
      userId: this.props.props.fetchInitialData.credentials.user.uuid,
    };
    fetch(`${process.env.REACT_APP_API}/createnewvendor`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.props.fetchInitialData.credentials.user
            .jsonWebTok,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            this.setState(
                {
                  newVendorModalOpen: false,
                  isLoadingNewVendor: true,
                  vendorSelect: fetchData.vendorId,
                },
                () => {
                  this.createSnack(
                      'Successfully created new vendor',
                      'success',
                      2000
                  );
                }
            );
          } else {
            this.setState({
              isLoadingNewVendor: true,
            });
            this.createSnack('Something went wrong', 'error', 2000);
          }
        });
  };

  render() {

    const userClassifications = this.props.props.fetchInitialData.credentials.user.contractClassifications.split(',')
    const classifiicationsToShow = this.props.classification1Values.filter(c => {
      return userClassifications.indexOf(c.classification_id.toString()) !== -1
    })

    const { classes } = this.props;
    const classification1Head =
        this.props.classification1Header.length > 0 ? this.props.classification1Header[0].classification_type_name : '';
    const { startDate, endDate } = this.state;
    return (
        <div style={{ padding: '2rem', margin: '0 auto' }}>
          {this.state.hasInactiveLocation && (
              <Dialog
                  open={this.state.hasInactiveLocation}
                  fullWidth={true}
                  TransitionComponent={this.Transition}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Location Inactive</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Location ${this.state.location.ONLY_NAME} is currently inactive, are you sure you want to select it?`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                      onClick={() => this.setState({ hasInactiveLocation: false })}
                      style={{ margin: '0.3rem' }}
                  >
                    No
                  </Button>
                  <LoadingButton
                      label="Yes"
                      color="primaryVLButton"
                      isLoading={this.state.isLoading}
                      fn={() => this.handleSubmit(this.state.newContract)}
                  />
                </DialogActions>
              </Dialog>
          )}

          <Dialog
              open={this.state.newVendorModalOpen}
              fullWidth={true}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Create New Vendor</DialogTitle>
            <form onSubmit={this.createVendor}>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="vendorVname"
                        label="Vendor Name"
                        required
                        fullWidth
                        variant="filled"
                        defaultValue={this.state.newVendorName}
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="filterValue"
                        label={this.props.props.fetchInitialData.credentials.clientInfo.filter_value_label}
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="addr1"
                        label="Address 1"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="addr2"
                        label="Address 2"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="cityAddr5"
                        label="City"
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <span style={{ marginLeft: '0.5rem' }}/>
                    <TextField
                        id="stateProv"
                        label="State"
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="postalCode"
                        label="Zip"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="email"
                        label="Vendor Email"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={() => this.setState({ newVendorModalOpen: false })}
                    style={{ margin: '0.3rem' }}
                >
                  Cancel
                </Button>

                <LoadingButton
                    label="Submit"
                    isLoading={this.state.isLoadingNewVendor}
                    color="primaryVLButton"
                    buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>

          <form onSubmit={(e) => this.submitNewContract(e)}>
            <Container>
              <Grid
                  style={{ marinTop: '1rem' }}
                  container
                  justify="flex-start"
                  spacing={2}
              >
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <TextField
                      label="Contract Number"
                      id="contractNumber"
                      fullWidth
                      required
                      margin="dense"
                      autoComplete="off"
                  />
                  <div style={{ margin: '8px 0 4px 0' }}>
                    <SuggestionSql
                        id="vendor"
                        jsonWebTok={this.props.props.fetchInitialData.credentials.user.jsonWebTok}
                        handleChange={(id, newVendorName, isSelected) => {
                          if (isSelected) {
                            this.getLocations(id);
                            this.setState({ vendorSelect: id, newVendorName });
                          } else {
                            this.setState({ vendorSelect: null });
                          }
                        }}
                        placeholder=""
                        label="Vendor"
                        required
                        fullWidth
                        clientId={this.props.props.fetchInitialData.credentials.user.clientId}
                        openNewVendor={(newVendorName) => {
                          this.setState({
                            newVendorName,
                            newVendorModalOpen: true,
                          });
                        }}
                    />
                  </div>
                  <div>
                    <Autocomplete
                        value={this.state.location}
                        disabled={this.state.locations.length < 1}
                        onChange={(event, newValue) => {
                          this.setState({ location: newValue });
                        }}
                        id="location"
                        options={this.state.locations}
                        getOptionLabel={(option) =>
                            option.NAME ? option.NAME : 'Name not available'
                        }
                        renderInput={(params) => (
                            <TextField
                                id="textLocations"
                                {...params}
                                margin="dense"
                                label="Location"
                            />
                        )}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div
                      style={{ margin: '0.8rem 0 0 0 ' }}
                  >
                    <FormControlLabel
                        label="Does not expire"
                        labelPlacement="end"
                        control={
                          <Checkbox
                              id="hasExpiration"
                              style={{ color: this.props.props.fetchInitialData.credentials.primaryAppColor, display: 'inline' }}
                              checked={this.state.hasExpiration}
                              onChange={(event) =>
                                  this.setState({ hasExpiration: event.target.checked })}
                          />
                        }
                    />
                  </div>
                  <div>
                    <TextField
                        style={{ marginRight: '0.5rem' }}
                        id="startDate"
                        label="Start Date"
                        required
                        type="date"
                        value={startDate}
                        onChange={(event) =>
                            this.setState({ startDate: event.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                    {!this.state.hasExpiration && (
                        <TextField
                            id="endDate"
                            label="End Date"
                            required={!this.state.hasExpiration}
                            type="date"
                            value={endDate}
                            onChange={(event) =>
                                this.setState({ endDate: event.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <TextField
                      autoComplete="off"
                      label="Title"
                      id="title"
                      required
                      margin="dense"
                      fullWidth
                  />

                  <FormControl
                      style={{ marginTop: '1rem' }}
                      margin="dense"
                      fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Contract Type
                    </InputLabel>
                    <Select
                        labelWidth={100}
                        id="demo-simple-select"
                        value={this.state.contractType}
                        onChange={(e) =>
                            this.setState({ contractType: e.target.value })
                        }
                    >
                      {this.props.contractTypes.map((type) => {
                        return (
                            <MenuItem
                                key={type.contract_type_id}
                                value={type.contract_type_id}
                            >
                              {type.description}
                            </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  {classification1Head && (
                      <div>
                        <Autocomplete
                            size="small"
                            required
                            options={classifiicationsToShow}
                            onChange={(event, values) =>
                                this.setState({ classification1Value: values ? values.classification_id : '' })
                            }
                            getOptionLabel={(option) => option.classification_value}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    style={{ marginTop: '16px', width: '100%' }}
                                    id="classificationType"
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') e.preventDefault();
                                    }}
                                    label={classification1Head}
                                    fullWidth
                                    autoComplete="off"
                                />
                            )}
                        />
                      </div>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                      label="Description"
                      id="description"
                      margin="dense"
                      fullWidth
                      autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                      style={{ marginRight: '0.5rem' }}
                      label="Contract Amount"
                      margin="dense"
                      value={
                        this.state.contractAmount
                            ? Math.round(this.state.contractAmount * 100) / 100
                            : ''
                      }
                      onChange={this.handleChange('contractAmount')}
                      id="contractAmount"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                            <InputAdornment position="start">
                              <span/>
                            </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                  />

                  <TextField
                      label="Amount Used"
                      margin="dense"
                      value={
                        this.state.contractAmountUsed
                            ? Math.round(this.state.contractAmountUsed * 100) / 100
                            : ''
                      }
                      onChange={this.handleChange('contractAmountUsed')}
                      id="contractAmountUsed"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                            <InputAdornment position="start">
                              <span/>
                            </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                      type="submit"
                      size="large"
                      className={this.state.isLoading ? '' : 'primaryVLButton'}
                      disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                        <div>
                          <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                          />
                          Create{' '}
                        </div>
                    ) : (
                        'Create'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        </div>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                value: values.value,
              },
            });
          }}
          thousandSeparator
          prefix="$"
      />
  );
}

CreateContract.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(CreateContract));

