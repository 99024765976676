export const contactsColumns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'mobile',
      label: 'Mobile',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'fax',
      label: 'Fax',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'job',
      label: 'Job Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

export const contractDetailsTabs = [
  { label: 'Contract', value: 0 },
  { label: 'Documents', value: 1 },
  { label: 'Attributes', value: 4 },
  { label: 'Tasks', value: 5 },
];
