import React from "react";
import Button from "@material-ui/core/Button";


class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
      fetch(
        `${process.env.REACT_APP_API}/verify-email`,
        {
          method: "post",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({id: window.location.hash.slice(1)})
        }
      )
        .then(resp => resp.json())
        .then(data => {
        })
        .catch(error => console.log(error));
  }
  render() {
   return (
<div className="NotFound">
      <h3>Thank you for verifying your email</h3>
      <Button onClick={() => this.props.history.push('/')} className="primaryVLButton">Click here to sign in</Button>
    </div>
   )
  }
}


export default VerifyEmail;
