import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  buttonProgress: {
    color: "#545454",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
    };
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleConfirm = () => {
    this.setState({ isLoading: true });
    this.props.handleConfirm(this.props.id, (status) => {
      if (status === 200) {
        this.setState({ isLoading: false, open: false });
      } else {
        this.setState({ isLoading: false }, () => {
          if (this.props.reactivate) {
            window.alert(
              `There was an error reactivating your ${this.props.type}`
            );
          } else {
            window.alert(
              `There was an error archiving your ${this.props.type}`
            );
          }
        });
      }
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Button
          style={{ color: this.props.color }}
          onClick={this.handleClickOpen}
        >
          {this.props.title}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.glyphhandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.props.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>

            <Button
              variant="contained"
              autoFocus
              className={this.state.isLoading ? "" : "primaryVLButton"}
              onClick={this.handleConfirm}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? (
                <div>
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                  Confirm
                </div>
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ConfirmDialog);
