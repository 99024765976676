import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import './PurchasingRequest.css';
import Comments from '../components/Comments';
import Attachments from '../components/Attachments';
import Activity from '../components/Activity';
import VendorStatus from '../functions/VendorStatus'
import { formatter } from "../utils/tableCustoms";
import ObjectNotifications from '../components/ObjectNotifications';
import LoadingCircle from "../components/common/LoadingCircle"

class PurchaseRequestApprovalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isOpen: false,
      action: null,
      company: '',
      location: '',
      vendor: '',
      filterValue: '',
      poNumber: '',
      deliveryDate: '',
      currentQty: 0,
      currentCost: 0,
      header: null,
      rows: [],
      isLoading: false,
    };
    this.handleForm = this.handleForm.bind(this);
    this.createRows = this.createRows.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: window.location.href.split(
          'purchasing-request-approval-details/'
      )[1],
    };
    const jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-pos`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            this.createRows(data.data);
          }
        })
        .catch((error) => console.log(error));
  }

  createRows(rows) {
    const detailsNoDeletes = rows.poDetail.map((line) => {
      if (line.DELETED !== 1) {
        return {
          num: line.LINE_NUM,
          itemNum: line.LINE_ITEM,
          description: line.LINE_DESC,
          qty: line.LINE_QTY,
          uom: line.LINE_UOM,
          cost: line.LINE_COST,
          extCost: line.LINE_EXT_COST,
          deliveryDate: line.LINE_DELIVERY_DATE.split('T00')[0],
          shipTo: line.LINE_SHIP_TO,
        };
      } else return null;
    });
    const filtered = detailsNoDeletes.filter((row) => row !== null);

    const header = rows.poHeader[0];
    this.setState({
      content: rows,
      header: header,
      rows: filtered,
      location: header.LOCATION_ID,
      vendor: header.VENDOR_VNAME,
      filterValue: header.FILTER_VALUE,
      poNumber: header.PO_NUMBER,
      deliveryDate: header.DELIVERY_DATE,
      isLoading: false,
    });
  }

  handleForm(event) {
    event.preventDefault();
    this.setState({ isLoading: true, isOpen: false });
    let purchaseRequest = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      action: this.state.action,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: this.state.content.poHeader[0].PO_ID,
      clientUrl: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/purchasing-request-details/${this.state.content.poHeader[0].PO_ID}`,
      vendorUrl: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/po-vendor-details/${this.state.content.poHeader[0].PO_ID}`,
    };

    if (this.state.action === 'approve') {
      purchaseRequest[
          'currentLevel'
          ] = this.state.content.poHeader[0].APPROVAL_STATUS_LEVEL;
      purchaseRequest['poAMT'] = this.state.content.poHeader[0].EXT_AMOUNT;
    } else {
      purchaseRequest['reason'] = event.target.reason.value;
    }

    fetch(`${process.env.REACT_APP_API}/approve-deny-pr`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(purchaseRequest),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            const approveDeny =
                this.state.action === 'approve' ? 'approved' : 'denied';
            this.props.fetchInitialData.createSnack(`You have successfully ${approveDeny} this purchase request`, 'success', 4000)

            this.props.fetchInitialData.getNotificationCount(
                this.props.fetchInitialData.credentials.user.clientId,
                this.props.fetchInitialData.credentials.user.jsonWebTok
            );
            this.props.history.push('/purchaserequests#pending');
          } else {
            this.props.fetchInitialData.createSnack('Something went wrong', 'error', 4000)
          }
          this.setState({ isOpen: false });
        });
  }

  handleClose() {
    this.setState({
      isOpen: false,
      action: null,
    });
  }

  render() {
    if (!this.state.content) {
      return (
          <LoadingCircle/>
      );
    }
    const { classes } = this.props;
    let sum = this.state.rows.reduce((acc, val) => {
      return parseFloat(acc) + parseFloat(val.extCost);
    }, 0);
    const load = this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <CircularProgress
              style={{ marginLeft: '5px', color: '#E86613' }}
              size="20px"
          />
        </Fade>
    ) : (
        ''
    );
    return (
        <div>
          <Dialog
              open={this.state.isOpen}
              TransitionComponent={this.Transition}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
            <form onSubmit={this.handleForm}>
              <DialogContent>
                {'Are you sure you want to ' +
                this.state.action +
                ' this purchase request?'}
                <div style={{ marginTop: '0.5rem' }}>
                  {this.state.action === 'deny' && (
                      <TextField
                          id="reason"
                          required
                          label="please provide a reason"
                          fullWidth
                      />
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                    variant="text"
                    onClick={this.handleClose}
                >
                  Back
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    className="primaryVLButton"
                >
                  Confirm
                  {load}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <div className="purchasingForm">
          <div
            style={{
              position: 'absolute',
              right: 0,

              fontSize: '12px',
            }}
          >
            <div>Status: {VendorStatus(this.state.content.poHeader[0].PO_STATUS)}</div>
            <div>Approval Level: {this.state.content.poHeader[0].APPROVAL_STATUS_LEVEL}</div>
          </div>
        
            <h3>Purchasing Request Approval</h3>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>
                  Vendor:
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.vendor}
                  </span>
                </span>
                </div>
                <div>
                <span>
                  Location:
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.location}
                  </span>
                </span>
                </div>
                
                <div>
                <span>
                  Contract:
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.content.poHeader[0].contract_number ? this.state.content.poHeader[0].contract_number : 'N/A'}
                  </span>
                </span>
                </div>
                <div>
                <span>
                  {
                    this.props.fetchInitialData.credentials.clientInfo
                        .filter_value_label
                  }
                  :
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.filterValue}
                  </span>
                </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                <span>
                  PO Number:
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.poNumber}
                  </span>
                </span>
                </div>
                <div>
                <span>
                  Delivery Date:
                  <span
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                  >
                    {this.state.deliveryDate.split('T0')[0]}
                  </span>
                </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <span>Total: </span>
                  <span style={{ fontSize: '1.6rem' }}>
                  <strong>{formatter.format(sum)}</strong>
                </span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="center">
                          Items
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Item #
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Description
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Qty
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          UOM
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Cost
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Ext Cost
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Delivery Date
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Ship To
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row) => (
                          <TableRow key={row.num}>
                            <TableCell
                                className={classes.tableCell}
                                align="center"
                                component="th"
                                scope="row"
                            >
                              {row.num}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.itemNum}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.description}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.qty}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.uom}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              ${row.cost}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              ${row.extCost}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.deliveryDate}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              {row.shipTo}
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ margin: '0.5rem' }}>
                <span>
                  <Button
                      style={{ backgroundColor: '#545454', color: 'white' }}
                      onClick={() =>
                          this.setState({ action: 'deny', isOpen: true })
                      }
                      variant="contained"
                  >
                    Deny
                  </Button>
                </span>
                  <span style={{ margin: '0.3rem' }}>
                  <Button
                      className="primaryVLButton"
                      onClick={() =>
                          this.setState({ action: 'approve', isOpen: true })
                      }
                      variant="contained"
            
                  >
                    Approve
                  </Button>
                </span>
                  {load}
                </div>
              </Grid>
            </Grid>

            <Attachments
                fetchInitialData={this.props.fetchInitialData}
                objectId={
                  window.location.pathname.split(
                      'purchasing-request-approval-details/'
                  )[1]
                }
                objectType="PURCHASE"
            />

            <Comments
                fetchInitialData={this.props.fetchInitialData}
                objectId={
                  window.location.href.split(
                      'purchasing-request-approval-details/'
                  )[1]
                }
                objectType="PURCHASE"
                vendorId={this.state.content.poHeader[0].VENDOR_ID}
                isVendor={this.props.fetchInitialData.credentials.isVendor}

            />

            <Activity
                title="Purchase Request History"
                activity={this.state.content.activity}
                timezone={this.props.fetchInitialData.credentials.user.timezone}
                appWidth={this.props.fetchInitialData.credentials.appWidth}
            />

<ObjectNotifications
              createSnack={this.props.fetchInitialData.createSnack}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              parentId={this.state.content.poHeader[0].PO_ID}
              parentType="purchase"
              isVendor={!!this.props.fetchInitialData.credentials.isVendor}
              vendorId={this.state.content.poHeader[0].VENDOR_ID}
            />
            {/* <Grid container justify="flex-start" spacing={2} />
          {this.state.content.activity.length > 0 && (
            <div style={{ margin: "1.5rem auto" }}>
              <h5>Purchase Request History</h5>
              <div>
                <Paper className={classes.paper}>
                  {this.props.fetchInitialData.credentials.appWidth > 599 ? (
                    <Table style={{ overflowX: "auto" }}>
                      <TableBody>
                        {this.state.content.activity.map((row, index) => (
                          <TableRow key={row.ACTIVITY_ID}>
                            <TableCell align="left">
                              {row.ACTIVITY_TYPE}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.ACTIVITY_STRING}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {moment(
                                moment(new Date(row.TIMESTAMP)).utcOffset(
                                  this.props.fetchInitialData.credentials.user
                                    .timezone
                                )._d
                              ).format("lll")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div>
                      {this.state.content.activity.map((row, index) => {
                        return (
                          <Card
                            style={{ border: "0.5px solid #545454" }}
                            key={row.ACTIVITY_ID}
                          >
                            <CardContent>
                              <div>
                                <span style={{ fontSize: "1.2rem" }}>
                                  {row.ACTIVITY_TYPE}
                                </span>
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  {moment(
                                    moment(new Date(row.TIMESTAMP)).utcOffset(
                                      this.props.fetchInitialData.credentials
                                        .user.timezone
                                    )._d
                                  ).format("lll")}
                                </span>
                                <hr style={{ margin: "0.1rem" }} />
                                <p style={{ margin: "0.8rem" }}>
                                  {row.ACTIVITY_STRING}
                                </p>
                                <div
                                  style={{
                                    marginTop: "0.8rem",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  by: {row.name}
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          )} */}
          </div>
        </div>
    );
  }
}

const styles = (theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  table: {
    minWidth: '800px',
  },
  tableCell: {
    padding: '10px 20px',
  },
});

PurchaseRequestApprovalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PurchaseRequestApprovalDetails);
