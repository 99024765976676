export const purchaseRequests = [
  {
    name: "poNumber",
    label: "PR Number",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "vendor",
    label: "Vendor",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "deliveryDate",
    label: "Delivery Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "createDate",
    label: "Create Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "location",
    label: "Location",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "extAmount",
    label: "Ext Amount",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "statusLevel",
    label: "Status Level",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true
    }
  }
];

export const purchasingRequestDetailsColumns = [
  { label: 'Items', width: '5%' },
  { label: 'Item #', width: '5%' },
  { label: 'Description', width: '20%' },
  { label: 'Qty', width: '5%' },
  { label: 'Qty Filled', width: '5%' },
  { label: 'UOM', width: '5%' },
  { label: 'Cost', width: '5%' },
  { label: 'Ext Cost', width: '5%' },
  { label: 'Delivery Date', width: '15%' },
  { label: 'Ship To', width: '20%' },
  { label: 'Edit', width: '5%' }];


export const vendorPurchaseRequests = [
  {
    name: "poNumber",
    label: "PR Number",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "deliveryDate",
    label: "Delivery Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "createDate",
    label: "Create Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "location",
    label: "Location",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "extAmount",
    label: "Ext Amount",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "statusLevel",
    label: "Status Level",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true
    }
  }
];

export const checkStatus = (num) => {
  switch (num) {
    case 0:
      return "Pending";
    case 1:
      return "Ready";
    case 2:
      return "Approved";
    case 3:
      return "Processing";
    case 4:
      return "Processed";
    case 5:
      return "Denied";
    case 6:
      return "ReOpened";
    case 7:
      return "Viewed by Vendor";
    case 8:
      return "Fulfilled";
    default:
      return "Error";
  }
}

export const tableData2 = {
  columns: [
    {
      label: 'PO Number',
      field: 'PO_NUMBER',
      width: 100,
    },
    {
      label: 'Delivery Date',
      field: 'DELIVERY_DATE',
      width: 100,
    },
    {
      label: 'Create Date',
      field: 'CREATE_DATE',
      width: 100,
    },
    {
      label: 'Location',
      field: 'LOCATION_ID',
      width: 100,
    },
    {
      label: 'Ext Amount',
      field: 'EXT_AMOUNT',
      width: 100,
    },
    {
      label: 'Status Level',
      field: 'APPROVAL_STATUS_LEVEL',
      width: 100,
    },
    {
      label: 'Status',
      field: 'PO_STATUS',
      width: 100,
    },
    {
      label: 'Edit',
      field: 'Edit',
      width: 100,
    },
  ],
  rows: [],
};
