import React from 'react';
import {
  withStyles,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIDataTable from 'mui-datatables';
import LoadingCircle from "../components/common/LoadingCircle"

import LambdaFetch from '../functions/FetchFromLambda';
import { invoiceAccountingColumns } from '../utils/constants/invoiceColumns';
import LoadingButton from './LoadingButton';
import ConfirmationDialog from './common/ConfirmationDialog';
import CircularProgress from "@material-ui/core/CircularProgress";

class InvoiceAccounting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      headerFields: null,
      headerValues: null,
      isOpen: false,
      isLoading: false,
      addHeader: false,
      editHeader: false,
      deleteHeader: false,
      currentEntry: null,
      editInvoiceValue: false,
      addInvoiceValue: false,
      currentInvoiceValue: null,
      deleteInvoiceValue: false,
      openInvoiceValueDialog: false,
      confirmDelete: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    const data = await LambdaFetch(
      'get-or-update-invoice-fields',
      'post',
      this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({ action: 'get' })
    );

    if (data.success) {
      this.setState({
        headerFields: data.data.invoiceHeaderFields,
        headerValues: data.data.invoiceHeaderValues,
        isLoading: false,
      });
    }
  };

  handlePanelChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  makePanel = (key, header, description, required, data, index) => {
    const { classes } = this.props;
    const tableData = data.map((row) => [
      row.value,
      row.delete_flag === 0 ? 'Active' : 'Inactive',
      <div
        className={classes.editLink}
        onClick={() =>
          this.setState({
            editInvoiceValue: true,
            currentInvoiceValue: { id: row.value_id, value: row.value },
            openInvoiceValueDialog: true,
          })
        }
      >
        edit
      </div>,
    ]);

    return (
      <Accordion
        expanded={this.state.expanded === index}
        onChange={this.handlePanelChange(index)}
      >
        <AccordionSummary
          classes={{ content: classes.accordionContent }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body2">
            {`${header} - ${description}`}
            {!!required && <i className={classes.i}>(required)</i>}
          </Typography>
          <div
            className={classes.editLink}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                isOpen: true,
                editHeader: true,
                currentEntry: {
                  fieldId: key,
                  header,
                  description,
                  required: !!required,
                },
              });
            }}
          >
            edit
          </div>
        </AccordionSummary>

        <div className={classes.innerPanel}>
          <MUIDataTable
            data={tableData}
            columns={invoiceAccountingColumns.cols}
            options={invoiceAccountingColumns.options}
          />
          <Button
            style={{marginTop: '1rem'}}
            className="primaryVLButton"
            onClick={() =>
              this.setState({
                openInvoiceValueDialog: true,
                addInvoiceValue: true,
                currentEntry: { fieldId: key },
              })
            }
          >
            + Add to {header}
          </Button>
        </div>
      </Accordion>
    );
  };

  checkAccess = () => {
    const { access } = this.props.props;
    return {
      view: access.settings_vendor_view,
      edit: access.settings_vendor_edit,
      add: access.settings_vendor_add,
      delete: access.settings_vendor_delete,
    };
  };

  handleHeaderSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const data = await LambdaFetch(
      'update-invoice-header-fields',
      'post',
      this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({
        action: this.state.addHeader ? 'add' : 'update',
        name: e.target.headerName.value,
        description: e.target.headerDescription.value,
        required: e.target.isHeaderRequired.checked,
        fieldId: this.state.editHeader ? this.state.currentEntry.fieldId : '',
      })
    );
    if (data.success) {
      this.props.props.fetchInitialData.createSnack(
        `Successfully ${this.state.addHeader ? 'added' : 'updated'} header!`,
        'success',
        3000
      );
      this.getData();
    } else {
      this.props.props.fetchInitialData.createSnack(
        'Something went wrong!',
        'error',
        3000
      );
    }

    this.setState({
      isLoading: false,
      isOpen: false,
      currentEntry: null,
      editHeader: false,
      addHeader: false,
    });
  };

  handleHeaderDelete = async () => {
    this.setState({ isLoading: true });
    const data = await LambdaFetch(
      'update-invoice-header-fields',
      'post',
      this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({
        action: 'delete',
        fieldId: this.state.currentEntry.fieldId,
      })
    );
    if (data.success) {
      this.props.props.fetchInitialData.createSnack(
        'Successfully deleted header!',
        'success',
        3000
      );
      await this.getData();
    } else {
      this.props.props.fetchInitialData.createSnack(
        'Something went wrong!',
        'error',
        3000
      );
    }

    this.setState({
      isLoading: false,
      deleteHeader: false,
      currentEntry: null,
      confirmDelete: false,
    });
  };

  handleInvoiceValueSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoice-fields`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        action: this.state.addInvoiceValue ? 'add' : 'update',
        fieldId: this.state.addInvoiceValue
          ? this.state.currentEntry.fieldId
          : undefined,
        value: e.target.invoiceValue.value,
        valueId:
          this.state.editInvoiceValue && this.state.currentInvoiceValue
            ? this.state.currentInvoiceValue.id
            : undefined,
      }),
    })
      .then(() => {
        this.props.props.fetchInitialData.createSnack(
          `Successfully ${
            this.state.addInvoiceValue ? 'added' : 'updated'
          }  invoice value!`,
          'success',
          3000
        );
        this.getData();
      })
      .catch(() => {
        this.props.props.fetchInitialData.createSnack(
          'Something went wrong',
          'error',
          3000
        );
      });
    this.getData();

    this.setState({
      isLoading: false,
      openInvoiceValueDialog: false,
      currentInvoiceValue: null,
      editInvoiceValue: false,
      addInvoiceValue: false,
    });
  };

  handleInvoiceValueDelete = async () => {
    this.setState({ isLoading: true });

    const data = await LambdaFetch(
      'get-or-update-invoice-fields',
      'post',
      this.props.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({
        action: 'delete',
        valueId: this.state.currentInvoiceValue.id,
      })
    );

    if (data.success) {
      this.props.props.fetchInitialData.createSnack(
        'Successfully deleted invoice value!',
        'success',
        3000
      );
      this.getData();

      this.setState({
        isLoading: false,
        openInvoiceValueDialog: false,
        currentInvoiceValue: null,
        deleteInvoiceValue: false,
        confirmDelete: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const access = this.checkAccess();
    return (
      <>

        <Dialog
          fullWidth
          open={this.state.isOpen}
          onClose={() =>
            this.setState({
              isOpen: false,
              currentEntry: null,
              addHeader: false,
              editHeader: false,
            })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.handleHeaderSubmit}>
            <DialogTitle id="alert-dialog-title">
              {`${this.state.addHeader ? 'Add new header' : 'Edit header'}`}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                justify="flex-start"
                alignItems={'center'}
                spacing={2}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="headerName"
                    label="Name"
                    fullWidth
                    autoComplete="off"
                    defaultValue={
                      this.state.currentEntry
                        ? this.state.currentEntry.header
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="headerDescription"
                    label="Description"
                    fullWidth
                    autoComplete="off"
                    defaultValue={
                      this.state.currentEntry
                        ? this.state.currentEntry.description
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: 30 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'checkBox'}
                        style={{ color: this.props.props.fetchInitialData.credentials.primaryAppColor }}
                        inputProps={{ id: 'isHeaderRequired' }}
                        defaultChecked={
                          this.state.currentEntry
                            ? this.state.currentEntry.required
                            : undefined
                        }
                      />
                    }
                    label="Required"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            <Button
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    currentEntry: null,
                    addHeader: false,
                    editHeader: false,
                  });
                }}
              >
                Cancel
              </Button>
              {!!access.delete && this.state.editHeader && (
                <Button
                  style={{ color: 'red' }}
                  onClick={() =>
                    this.setState({
                      confirmDelete: true,
                      isOpen: false,
                      deleteHeader: true,
                    })
                  }
                >
                  Delete
                </Button>
              )}
              {!!access.edit && (
                <LoadingButton
                  label="Submit"
                  isLoading={this.state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              )}
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          fullWidth
          open={this.state.openInvoiceValueDialog}
          onClose={() =>
            this.setState({
              openInvoiceValueDialog: false,
              currentInvoiceValue: null,
              addInvoiceValue: false,
              editInvoiceValue: false,
            })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.handleInvoiceValueSubmit}>
            <DialogTitle id="alert-dialog-title">
              {`${
                this.state.addInvoiceValue
                  ? 'Add new invoice value'
                  : 'Edit invoice value'
              }`}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                justify="flex-start"
                alignItems={'center'}
                spacing={2}
              >
                <TextField
                  id="invoiceValue"
                  label="Value"
                  fullWidth
                  autoComplete="off"
                  defaultValue={
                    this.state.currentInvoiceValue
                      ? this.state.currentInvoiceValue.value
                      : undefined
                  }
                />
              </Grid>
            </DialogContent>
            <DialogActions>
            <Button
                onClick={() => {
                  this.setState({
                    currentInvoiceValue: null,
                    openInvoiceValueDialog: false,
                    editInvoiceValue: false,
                    addInvoiceValue: false,
                  });
                }}
              >
                Cancel
              </Button>
              {!!access.delete && this.state.editInvoiceValue && (
                <Button
                  style={{ color: 'red' }}
                  onClick={() => {
                    this.setState({
                      confirmDelete: true,
                      openInvoiceValueDialog: false,
                      deleteInvoiceValue: true,
                    });
                  }}
                >
                  Delete
                </Button>
              )}
              {!!access.edit && (
                <LoadingButton
                  label="Submit"
                  isLoading={this.state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              )}
            </DialogActions>
          </form>
        </Dialog>

        <ConfirmationDialog
          isDeleting={this.state.isLoading}
          handleClose={() =>
            this.setState({
              confirmDelete: false,
              deleteHeader: false,
              editHeader: false,
              deleteInvoiceValue: false,
              editInvoiceValue: false,
            })
          }
          handleOpen={this.state.confirmDelete}
          dialogTitle={'Are you sure you want to delete this item?'}
          handleDelete={
            this.state.deleteHeader
              ? this.handleHeaderDelete
              : this.handleInvoiceValueDelete
          }
        />

        {this.state.headerFields && this.state.headerValues ? (
            <Grid component={'div'} style={{ margin: '2rem 0' }}>
              <Grid component={'div'} style={{ marginBottom: '1rem' }}>
                <Button
                    onClick={() => this.setState({ isOpen: true, addHeader: true })}
                    style={{float: 'right'}}
                    className="primaryVLButton"
                >
                  + Add Header Field
                </Button>
                <Typography style={{ fontSize: '1.75rem' }} variant={'h5'}>
                  Invoice Header Fields
                </Typography>
              </Grid>
              {this.state.headerFields.map((field, index) => (
                  <Grid
                      component={'div'}
                      item
                      key={index}
                      style={{ marginBottom: 10 }}
                  >
                    {this.makePanel(
                        field.field_id,
                        field.name,
                        field.description,
                        field.required_flag,
                        this.state.headerValues.filter(
                            (vals) => vals.field_id === field.field_id
                        ),
                        index
                    )}
                  </Grid>
              ))}
            </Grid>

        ) : (
            <LoadingCircle/>
        )}
        
      </>
    );
  }
}

const styles = () => ({
  innerPanel: {
    margin: '0 auto',
    padding: '3%',
  },
  i: {
    color: '#545454',
    fontSize: '0.75rem',
    marginLeft: '0.5rem',
  },
  editLink: {
    color: 'rgb(0, 104, 178)',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  addToHeader: {
    backgroundColor: 'rgb(232,84,10)',
    color: 'white',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    },
  },
  addHeaderButton: {
    float: 'right',
    backgroundColor: 'rgb(232,84,10)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    },
  },
  accordionContent: {
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(InvoiceAccounting);
