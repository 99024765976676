import React from "react";
import {
  Dialog, Table, TableBody, TableRow, TableHead, TableCell, DialogTitle, withStyles
} from "@material-ui/core";

import PdfContainer from "./PdfContainer";
import Doc from "./DocService";

const styles = ({
  tableCell: {
    border: '0.5px solid #e0e0e0',
    '& div': {
      position: 'static !important'
    },
    wordBreak: 'break-all',
  }
})

class PdfTableDialog extends React.Component {
  createPdf = (html) => Doc.createPdf(html, this.props.fileName, this.props.isLarge || this.props.landscape);

  render() {
    const { open, onClose, columns, data, title, isLarge, classes } = this.props
    return (
        <Dialog
            fullWidth
            maxWidth={`${isLarge ? 'lg' : 'md'}`}
            open={open} onClose={onClose}>
          <PdfContainer createPdf={this.createPdf} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <Table
                size={`${isLarge ? 'small' : 'medium'}`}
                style={{ fontSize: `${isLarge ? '12px' : '14px'}` }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                      <TableCell
                          padding={isLarge && column.disablePadding}
                          style={{
                            padding: isLarge ? 0 : '6px 24px 6px 16px',
                            fontSize: isLarge ? '12px' : '0.875rem',
                            wordBreak: column.wordBreak || 'break-all'
                          }}
                          className={classes.tableCell} width={column.width}
                          key={index}
                          align={'center'}>
                        {column.label}
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((el, id) => (
                          <TableCell
                              style={{
                                padding: isLarge ? 0 : '6px 24px 6px 16px',
                                fontSize: isLarge ? '12px' : '0.875rem'
                              }}
                              className={classes.tableCell}
                              key={id} align={'center'}>
                            {el}
                          </TableCell>
                      ))}
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </PdfContainer>
        </Dialog>
    )
  }
}

export default withStyles(styles)(PdfTableDialog);
