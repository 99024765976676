import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: "#545454",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class LoadingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  render() {
    const { classes } = this.props;

    if (this.props.fn) {
      return (
        <Fragment>
          <Button
            variant={this.props.variant ? this.props.variant : "contained"}
            onClick={this.props.fn}
            className={this.props.isLoading ? "" : this.props.color}
            style={{backgroundColor: this.props.isLoading ? "" : this.props.backColor ? this.props.backColor : '', color: this.props.fontColor ? this.props.fontColor : ''}}
            disabled={this.props.isLoading}
          >
            {this.props.isLoading ? (
              <div>
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
                {this.props.label}
              </div>
            ) : (
              this.props.label
            )}
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            variant={this.props.variant ? this.props.variant : "contained"}
            type={this.props.buttonType}
            className={clsx(this.props.isLoading ? "" : this.props.color, this.props.className)}
            style={{backgroundColor: this.props.isLoading ? "" : this.props.backColor ? this.props.backColor : '', color: this.props.fontColor ? this.props.fontColor : ''}}
            disabled={this.props.isLoading}
          >
            {this.props.isLoading ? (
              <div>
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
                {this.props.label}
              </div>
            ) : (
              this.props.label
            )}
          </Button>
        </Fragment>
      );
    }
  }
}

export default withStyles(styles)(LoadingButton);
