import React from "react";
import {withStyles} from "@material-ui/core";

import VendorContracts from "../components/VendorContracts";

const styles = {
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
}

class VendorAllContracts extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <div className={classes.paymentForm}>
          <VendorContracts vendorId={this.props.fetchInitialData.credentials.user.vendorId} props={this.props}/>
        </div>
    );
  }
}

export default withStyles(styles)(VendorAllContracts);
