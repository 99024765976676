import React from 'react';
import './Details.css';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import './VendorForm.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import VendorFormDetails from '../components/VendorFormDetails';
import { withRouter } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {responsive} from '../utils/constants/muidatatablesConsts';
import { formatter } from "../utils/tableCustoms";

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
};

class ClientVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: null,
      vendorSelect: '',
      content: null,
    };
  }
  componentDidMount() {
    if (window.location.href.split('/yourvendors/')[1]) {
      this.changeVendor(window.location.href.split('/yourvendors/')[1]);
      this.getVendors();
    } else {
      this.getVendors();
    }
  }

  getVendors() {
    fetch(`${process.env.REACT_APP_API}/clientvendors`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify({
        clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({ vendors: data.vendors });
        }
      });
  }
  changeVendor(vendor) {
    this.setState({ vendorSelect: vendor });
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      vendorId: vendor,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    var jsonVendorId = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-vendor-forms`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: jsonVendorId,
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.props.history.push(`/yourvendors/${vendor}`);
        this.setState({ content: data });
        this.getVendorInfo(vendor);
      })
      .catch((error) => console.log(error));
  }
  getVendorInfo(vendor) {
    let fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      vendorId: vendor,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    fetch(`${process.env.REACT_APP_API}/clientvendors`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 200) {

          const invoices = data.content.invoices.map((invoice) => {
            let vendorObj = [];

            let dueDate = '';
            if (invoice.DUE_DATE) {
              const date = invoice.DUE_DATE.split('-');
              dueDate = date[1].concat('/', date[2], '/', date[0]);
            }
            let status;
            if (invoice.INVOICE_STATUS === 0) {
              status = 'Pending';
            } else if (invoice.INVOICE_STATUS === 1) {
              status = 'Ready';
            } else if (invoice.INVOICE_STATUS === 2) {
              status = 'Approved';
            } else if (invoice.INVOICE_STATUS === 3) {
              status = 'Processing';
            } else if (invoice.INVOICE_STATUS === 4) {
              status = 'Processed';
            } else if (invoice.INVOICE_STATUS === 5) {
              status = 'Denied';
            } else if (invoice.INVOICE_STATUS === 6) {
              status = 'ReOpened';
            } else if (invoice.INVOICE_STATUS === 7) {
              status = 'Viewed by Vendor';
            } else if (invoice.INVOICE_STATUS === 8) {
              status = 'Fulfilled';
            } else {
              status = 'Error';
            }
            vendorObj.push(status);
            vendorObj.push(invoice.INVOICE_NUMBER);
            vendorObj.push(invoice.VENDOR_VNAME);
            vendorObj.push(invoice.INVOICE_DATE);
            vendorObj.push(dueDate);
            vendorObj.push(formatter.format(invoice.TRAN_INV_AMOUNT));
            return vendorObj;
          });

          const prs = data.content.prs.map((po) => {
            let vendorObj = [];
            let status;
            if (po.PO_STATUS === 0) {
              status = 'Pending';
            } else if (po.PO_STATUS === 1) {
              status = 'Ready';
            } else if (po.PO_STATUS === 2) {
              status = 'Approved';
            } else if (po.PO_STATUS === 3) {
              status = 'Processing';
            } else if (po.PO_STATUS === 4) {
              status = 'Processed';
            } else if (po.PO_STATUS === 5) {
              status = 'Denied';
            } else if (po.PO_STATUS === 6) {
              status = 'ReOpened';
            } else if (po.PO_STATUS === 7) {
              status = 'Viewed by Vendor';
            } else if (po.PO_STATUS === 8) {
              status = 'Fulfilled';
            } else {
              status = 'Error';
            }
            let deliveryDate,
              createDate = '';

            if (po.DELIVERY_DATE) {
              const date = po.DELIVERY_DATE.split('T')[0].split('-');
              deliveryDate = date[1].concat('/', date[2], '/', date[0]);
            }
            if (po.CREATE_DATE) {
              const date = po.CREATE_DATE.split('T')[0].split('-');
              createDate = date[1].concat('/', date[2], '/', date[0]);
            }
            vendorObj.push(status);
            vendorObj.push(po.PO_NUMBER);
            vendorObj.push(po.VENDOR_VNAME);
            vendorObj.push(deliveryDate);
            vendorObj.push(createDate);
            vendorObj.push(po.LOCATION_ID);
            vendorObj.push(formatter.format(po.EXT_AMOUNT));
            vendorObj.push(po.APPROVAL_STATUS_LEVEL);
            return vendorObj;
          });
          this.setState({
            content: data.content,
            invoices: invoices,
            prs: prs,
          });
        }
      })
      .catch((error) => console.log(error));
  }
  render() {
    if (!this.state.vendors) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="purchasingForm">
          <h3>Your Vendors</h3>
          <Grid container justify="flex-start" spacing={24}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                select
                id="vendorSelect"
                label="Choose Vendor"
                style={{ width: '300px' }}
                value={this.state.vendorSelect}
                onChange={(event) => {
                  this.changeVendor(event.target.value);
                  this.setState({ vendorSelect: event.target.value });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span />
                    </InputAdornment>
                  ),
                }}
                margin="dense"
              >
                {this.state.vendors.map((vendor) => {
                  return (
                    <MenuItem key={vendor.VENDOR_ID} value={vendor.VENDOR_ID}>
                      {vendor.VENDOR_VNAME}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          {this.state.vendorSelect && (
            <div style={{ marginTop: '1rem' }}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Vendor Information</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <VendorFormDetails
                    vendorId={this.state.vendorSelect}
                    fetchInitialData={this.props.fetchInitialData}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          )}
          {this.state.invoices && (
            <div style={{ marginTop: '2rem' }}>
              <MUIDataTable
                title={'Invoices'}
                data={this.state.invoices}
                columns={[
                  {
                    name: 'status',
                    label: 'Status',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: 'invoiceNumber',
                    label: 'Invoice Number',
                    options: {
                      filter: false,
                      sort: true,
                    },
                  },
                  {
                    name: 'vendor',
                    label: 'Vendor',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: 'invoiceDate',
                    label: 'invoice Date',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'dueDate',
                    label: 'Due Date',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'invoice Amount',
                    label: 'Invoice Amount',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                ]}
                options={{
                  selectableRows: false,
                  filterType: 'dropdown',
                  responsive,
                  print: false,
                  onRowClick: (rowData, rowState) => {
                    this.props.history.push(
                      `/invoice-details/${
                        this.state.content.invoices[rowState.dataIndex]
                          .INVOICE_ID
                      }`,
                      this.state
                    );
                  },
                }}
              />
            </div>
          )}

          {this.state.prs && (
            <div style={{ marginTop: '2rem' }}>
              <MUIDataTable
                title={'Purchase Requests'}
                data={this.state.prs}
                columns={[
                  {
                    name: 'status',
                    label: 'Status',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: 'poNumber',
                    label: 'PR Number',
                    options: {
                      filter: false,
                      sort: true,
                    },
                  },
                  {
                    name: 'vendor',
                    label: 'Vendor',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: 'deliveryDate',
                    label: 'Delivery Date',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'createDate',
                    label: 'Create Date',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'location',
                    label: 'Location',
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: 'extAmount',
                    label: 'Ext Amount',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                  {
                    name: 'statusLevel',
                    label: 'Status Level',
                    options: {
                      filter: true,
                      sort: true,
                    },
                  },
                ]}
                options={{
                  selectableRows: false,
                  filterType: 'dropdown',
                  responsive,
                  print: false,
                  onRowClick: (rowData, rowState) => {
                    this.props.history.push(
                      `/purchasing-request-details/${
                        this.state.content.prs[rowState.dataIndex].PO_ID
                      }`
                    );
                  },
                }}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

ClientVendors.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ClientVendors));
