import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { Select } from "../common/Select";
import { NumberFormatCustom } from "../../utils/numberFormatUtils";
import { formatter } from "../../utils/tableCustoms";

const inputProps = {
  startAdornment: (
      <InputAdornment position="start">
        <div/>
      </InputAdornment>
  ),
};

class EditLineDialog extends React.Component {
  render() {

    return (
        <Dialog open={this.props.open} fullWidth maxWidth={'md'}
                onClose={this.props.onClose}>
          <form onSubmit={(event) => this.props.editInvoiceRow(event)}>
            <DialogTitle id="alert-dialog-title">Edit Item</DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                      fullWidth
                      label="Description"
                      margin="dense"
                      id="description"
                      defaultValue={this.props.editRow.LINE_DESC}
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                              <div/>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  {Select(
                      'uom',
                      'Unit of Measure',
                      this.props.uom,
                      this.props.uomList,
                      '15ch',
                      this.props.vendorId, this.props.state, this.props.setState
                  )}
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                      id="LINE_QTY"
                      label="Rate"
                      margin="dense"
                      value={this.props.editRow.LINE_UNIT_COST}
                      onChange={(e) =>
                          this.props.setEditRow({ ...this.props.editRow, LINE_UNIT_COST: e.target.value })
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                            <InputAdornment position="start">
                              <div/>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                      id="LINE_QTY"
                      label="Qty"
                      margin="dense"
                      type="number"
                      value={this.props.editRow.LINE_QTY}
                      onChange={(e) =>
                          this.props.setEditRow({ ...this.props.editRow, LINE_QTY: e.target.value })
                      }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                      id="LINE_TRAN_AMOUNT"
                      label="Total"
                      margin="dense"
                      value={
                        this.props.editRow.LINE_QTY && this.props.editRow.LINE_UNIT_COST
                            ? formatter.format(this.props.editRow.LINE_QTY * this.props.editRow.LINE_UNIT_COST)
                            : 0
                      }
                      InputProps={inputProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                  onClick={this.props.onClose}
                  variant="outlined"
              >
                Cancel
              </Button>
              <Button
                  onClick={this.props.handleDeleteRow}
                  color="secondary"
                  variant="outlined"
              >
                Delete
              </Button>
              <Button
                  type="submit"
                  disabled={this.props.isLoading}
                  style={{
                    backgroundColor: 'rgb(232,102,19)',
                    color: 'white',
                  }}
                  variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>

    )
  }
}

export default EditLineDialog;
