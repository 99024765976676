import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainTabs.css';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import MenuDrawerVendor from './MenuDrawerVendor';
import { vendorMainPages, vendorSecondaryPages,vendorRoutes } from "../utils/constants/mainTabsConstants";
import VerifyAccess from '../functions/VerifyAccess';

const styles = {
  nav: {
    fontSize: '1.3rem',
    backgroundColor: '#E86613',
    color: 'white',
    borderRadius: '5px',
    height: '40px',
    margin: 'auto',
  },
  active: {
    marginTop: '-5px',
    color: '#E86613',
    height: '45px',
    backgroundColor: 'white',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: 'solid 1px grey',
    borderBottomColor: 'white',
  },
  topNavActive: {
    background: '#CCC',
    color: '#000',
  },
};

class VendorTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'vendor',
      access: {},
    };
  }
  checkAccess = () => {
    VerifyAccess(({ access }) => this.setState({ access }),
        ['vendor_tab', 'contract_tab', 'invoice_tab', 'purchase_tab','settings_vendoruser_view'],
        this.props.appState.user.jsonWebTok
    )
  }
  componentDidMount() {
    this.checkAccess()
    this.checkTab();
    this.unlisten = this.props.history.listen((location, action) => {
      this.checkView(location);
    });
    this.checkView(this.props.location);
  }
  componentWillUnmount() {
    this.unlisten();
  }
  checkView = (location) => {
    const view = vendorRoutes[location.pathname.slice(1).split("/")[0]];
    this.setState({ view });
  };
  checkTab() {
    const currentLocation = window.location.pathname.slice(1);
    if (currentLocation === 'vendor-user-maintenance') {
      this.setState({ view: 'settings' });
      return;
    }
    this.setState({ view: currentLocation });
  }
  render() {
    const { access } = this.state;
        const { clientInfo } = this.props.appState

    const accessedPages = vendorMainPages.filter(page => (access[page.tabAccess] || !page.tabAccess) && (!page.license || clientInfo[page.license] === 1));
    const accessedSecondaryPages = [...vendorSecondaryPages.filter(page => (access[page.tabAccess] || !page.tabAccess))];
    return (
      <>
        <div style={{ float: 'right' }}>
          <nav
            className="navbar navbar-expand-sm"
            style={{
              fontSize: '11px',
              backgroundColor: '#fff',
              padding: 0,
              borderRadius: '30px',
            }}
          >
            <ul className="navbar-nav">
              {accessedSecondaryPages.map((el, index) => (
                  <Link
                      key={index}
                      onClick={() => this.setState({ view: el.view })}
                      to={el.route}
                  >
                    <li
                        style={el.view !== 'home' && this.state.view === el.view ? styles.topNavActive : {}}
                        className={`top-list-item ${index === 0 && 'first-list-item'}`}
                    >
                      {el.title}
                    </li>
                  </Link>
              ))}
              <div
                className="topNavLink"
                onClick={() =>
                  this.props.logout(() => {
                    window.location.pathname = '/';
                    this.setState({ view: '' });
                  })
                }
              >
                <li className="top-list-item last-list-item">Log out</li>
              </div>
            </ul>
          </nav>
        </div>

        <div>
          <div style={{ marginBottom: '0.5rem' }}>
            {process.env.REACT_APP_STAGE === 'DEV' && (
              <img alt={'dev'}
                height={50}
                width={50}
                src={
                  '/Dev.png'
                }
              />
            )}

            <img
              src={this.props.logo}
              style={{ margin: '0.5rem', height: '80px' }}
              alt="client logo"
            />
          </div>
          <nav
            style={{
              color: 'white',
              borderRadius: '5px',
              height: '40px',
            }}
            className="navbar navbar-expand-lg vl-primary-bg-color"
          >
            <MenuDrawerVendor pages={accessedPages} title={viewTitle(this.state.view)} history={this.props.history} />
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {accessedPages.map((el, index) => (
                    <Link
                        key={index}
                        className="mainNavTab"
                        onClick={() => this.setState({ view: el.view })}
                        to={el.route}
                    >
                      <li className="nav-item">
                        <div
                           style={this.state.view === el.view ? styles.active : {}}
                                className={`nav-link ${this.state.view === el.view ? 'vl-primary-text-color' : ''}`}
                            >
                          {el.title}
                        </div>
                      </li>
                    </Link>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </>
    );
  }
}

export default withRouter((props) => <VendorTabs {...props} />);


const viewTitle = (view) => {
  switch(view) {
    case 'home': 
      return 'Home'
    case 'vendors':
    return 'Vendor'
    case 'contracts':
    return 'Contracts'
    case 'invoices':
    return 'Invoices'
    case 'purchaserequests':
    return 'Purchase Requests'
    case 'settings':
    return 'Settings'
    case 'profile':
    return 'Profile'
    default:
    return 'Home'
  }

}