export const vendorDetailsActions = [
  'vendor_location_edit',
  'vendor_location_view',
  'vendor_location_add',
  'vendor_location_delete',
  'vendor_approve',
  'vendor_reopen',
  'vendor_deny',
  'vendor_edit',
  'vendor_legal_edit',
  'vendor_leagal_view',
  'vendor_contact_view',
  'vendor_contact_edit',
  'vendor_contact_add',
  'vendor_contact_delete',
  'comment_add',
  'contract_view',
  'history_view',
  'vendor_delete',
  'document_view',
  'attribute_view',
  'task_view',
  'vendor_document',
  'vendor_attribute',
  'vendor_task'
];

export let status = {
  0: 'Pending',
  1: 'Ready',
  2: 'Approved',
  3: 'Processing',
  4: 'Processed',
  5: 'Rejected',
  6: 'ReOpened',
  7: 'Viewed',
  8: 'Fulfilled',
  9: 'Error',
  10: 'Temporary',
};

export const meta = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID (read only)',
    readOnly: true,
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  //   {
  //     fieldId: "TAX_ID",
  //     fieldName: "EIN / SSN (read only)",
  //     readOnly: true
  //   },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
];

export const addressInputs = [
  {
    fieldId: 'ADDR1',
    fieldName: 'Address 1',
  },
  {
    fieldId: 'ADDR2',
    fieldName: 'Address 2',
  },
  {
    fieldId: 'CITY_ADDR5',
    fieldName: 'City',
  },
  // {
  //   fieldId: "STATE_PROV",
  //   fieldName: "State",
  //   half: true,
  // },
  // {
  //   fieldId: "POSTAL_CODE",
  //   fieldName: "Zip",
  //   half: true,
  // },
];

export const contactInputs = [
  {
    fieldId: 'NAME',
    fieldName: 'Name',
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Email',
  },
  {
    fieldId: 'PHONE_NMBR',
    fieldName: 'Phone',
  },
  {
    fieldId: 'MOBILE_NUM',
    fieldName: 'Mobile',
  },
  {
    fieldId: 'FAX_NMBR',
    fieldName: 'Fax',
  },
  {
    fieldId: 'JOB_TITLE',
    fieldName: 'Job Title',
  },
];

// const bankingInputs = [
//   {
//     fieldId: "BANK_ENTITY",
//     fieldName: "Routing Number (read only)",
//     readOnly: true,
//   },
//   {
//     fieldId: "VBANK_ACCT_NO",
//     fieldName: "Bank Account Number (read only)",
//     readOnly: true,
//   },
//   {
//     fieldId: "VBANK_ACCT_TP",
//     fieldName: "Account Type (read only)",
//     readOnly: true,
//   },
// ];

export const preparerInfo = [
  {
    fieldId: 'PREPARER_NAME',
    fieldName: "Preparer's Name (read only)",
    readOnly: true,
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
    readOnly: true,
  },
];
export const apcVenMast = [
  {
    fieldId: 'VENDOR_ID',
    fieldName: 'Vendor ID',
  },
  {
    fieldId: 'VENDOR',
    fieldName: 'Vendor Name',
  },
  {
    fieldId: 'LEGAL_NAME',
    fieldName: 'Legal Name',
  },
  {
    fieldId: 'VENDOR_SNAME',
    fieldName: 'Vendor Search Name',
  },
  //   {
  //     fieldId: "TAX_ID",
  //     fieldName: "EIN / SSN",
  //   },
  {
    fieldId: 'URL_ADDR',
    fieldName: 'Website',
  },
  {
    fieldId: 'PHONE_NUM',
    fieldName: 'Main Phone',
  },
  {
    fieldId: 'FAX_NUM',
    fieldName: 'Main Fax',
  },
  //   {
  //     fieldId: "BANK_ENTITY",
  //     fieldName: "Routing Number",
  //   },
  //   {
  //     fieldId: "VBANK_ACCT_NO",
  //     fieldName: "Bank Account Number",
  //   },
  //   {
  //     fieldId: "VBANK_ACCT_TP",
  //     fieldName: "Account Type",
  //   },

  {
    fieldId: 'COMMENT',
    fieldName: 'Comment',
  },

  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Preparer Email (read only)',
  },
];

export const apcContact = [
  {
    fieldId: 'NAME',
    fieldName: 'Name',
  },
  {
    fieldId: 'E_MAIL_ADDRESS',
    fieldName: 'Email',
  },
  {
    fieldId: 'PHONE_NMBR',
    fieldName: 'Phone',
  },
  {
    fieldId: 'MOBILE_NUM',
    fieldName: 'Mobile',
  },
  {
    fieldId: 'FAX_NMBR',
    fieldName: 'Fax',
  },
  {
    fieldId: 'JOB_TITLE',
    fieldName: 'Job Title',
  },
];

export const apcVenAddr = [
  {
    fieldId: 'ADDR1',
    fieldName: 'Address 1',
  },
  {
    fieldId: 'ADDR2',
    fieldName: 'Address 2',
  },
  {
    fieldId: 'CITY_ADDR5',
    fieldName: 'City',
  },
  // {
  //   fieldId: "STATE_PROV",
  //   fieldName: "State",
  // },
  // {
  //   fieldId: "POSTAL_CODE",
  //   fieldName: "Zip",
  // },
];

export const dropdownsNames = [
  'termCode',
  'invCurrency',
  'languageCode',
  'vendorGroup',
  'venClass',
  'taxCode',
  'holdCode',
  'distributionCode',
  'accrualCode',
  'paymentCode',
  'cashCode',
];

export const taxClassification = [
  'Sole Proprietor',
  'Corporation',
  'S-Corp',
  'Partnership',
];

export const invoiceInformation = [
  {
    id: 'termCode',
    options: 'termCodeDropdown',
    defaultState: 'termCode',
    label: 'Payment Terms',
  },
  {
    id: 'invCurrency',
    options: 'invCurrencyDropdown',
    defaultState: 'invCurrency',
    label: 'Invoice Currency',
  },
  {
    id: 'languageCode',
    options: 'languageCodeDropdown',
    defaultState: 'languageCode',
    label: 'Language',
  },
];

export const forApproverOnlyDropdowns = [
  {
    id: 'vendorGroup',
    showWithDescription: true,
    options: 'vendorGroupDropdown',
    defaultState: 'vendorGroupDropdown',
    label: 'Vendor Group',
  },
  {
    id: 'venClass',
    showWithDescription: true,
    options: 'venClassDropdown',
    defaultState: 'venClassDropdown',
    label: 'Vendor Class',
  },
  {
    id: 'taxCode',
    showWithDescription: false,
    options: 'taxCodeDropdown',
    defaultState: 'taxCodeDropdown',
    label: 'Tax Code',
  },
  {
    id: 'holdCode',
    showWithDescription: true,
    options: 'holdCodeDropdown',
    defaultState: 'holdCodeDropdown',
    label: 'Hold Code',
  },
  {
    id: 'distributionCode',
    showWithDescription: true,
    options: 'distributionCodeDropdown',
    defaultState: 'distributionCodeDropdown',
    label: 'Distribution Code',
  },
  {
    id: 'accrualCode',
    showWithDescription: false,
    options: 'accrualCodeDropdown',
    defaultState: 'accrualCodeDropdown',
    label: 'Accrual Code',
  },
  {
    id: 'paymentCode',
    showWithDescription: true,
    options: 'paymentCodeDropdown',
    defaultState: 'paymentCodeDropdown',
    label: 'Payment Code',
  },
  {
    id: 'cashCode',
    showWithDescription: false,
    options: 'cashCodeDropdown',
    defaultState: 'cashCodeDropdown',
    label: 'Cash Code',
  },
];

export const vendorDetailsTabs = [
  { label: 'Profile', value: 0 },
  { label: 'Documents', value: 1, accessCode: ['document_view','vendor_document'] },
  { label: 'Locations', value: 2, accessCode: ['vendor_location_view'] },
  { label: 'People', value: 3, accessCode: ['vendor_contact_view'] },
  { label: 'Attributes', value: 4, accessCode: ['attribute_view','vendor_attribute'] },
  { label: 'Tasks', value: 5, accessCode: ['task_view','vendor_task'] },
  { label: 'Contracts', value: 6, accessCode: ['contract_view'], license: "contract_license" },
];
