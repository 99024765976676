import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./PurchasingRequest.css";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import { formatter } from "../utils/tableCustoms";

class EmployeeReimbursementRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
      currentEdit: null,
      reqDateFrom: "",
      reqDateTo: "",
      currentExtAmount: 0,
      rows: [],
      isLoading: false,
      vendorObj: {}
    };
    this.submitEmplReq = this.submitEmplReq.bind(this);
    this.addRow = this.addRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  Transition(props) {
    return <Slide direction="up" {...props} />;
  }
  addRow(event) {
    event.preventDefault();
    const e = event.target;
    const num = this.state.rows.length + 1;
    const newRow = {
      num: num,
      itemNum: e.itemNum.value,
      description: e.description.value,
      extAmt: e.extAmt.value.replace(/[^0-9.-]+/g, ""),
      edit: (
          <span
              className="editLink"
              onClick={() => {
                this.setState({
                  isEdit: true,
                  currentEdit: {
                    num: num,
                    itemNum: e.itemNum.value,
                    description: e.description.value,
                    extAmt: e.extAmt.value.replace(/[^0-9.-]+/g, "")
                  }
                });
              }}
          >
          edit
        </span>
      )
    };
    const updatedRows = this.state.rows.map(line => {
      return Object.assign({}, line);
    });
    updatedRows.push(newRow);
    this.setState({
      isNewEntryOpen: false,
      rows: updatedRows
    });
  }
  editRow(event) {
    event.preventDefault();
    const e = event.target;
    const num = this.state.currentEdit.num;
    const editedRow = {
      num: num,
      itemNum: e.itemNum.value,
      description: e.description.value,
      extAmt: e.extAmt.value.replace(/[^0-9.-]+/g, ""),
      edit: (
          <span
              className="editLink"
              onClick={() => {
                this.setState({
                  isEdit: true,
                  currentEdit: {
                    num: num,
                    itemNum: e.itemNum.value,
                    description: e.description.value,
                    extCost: e.extAmt.value.replace(/[^0-9.-]+/g, "")
                  }
                });
              }}
          >
          edit
        </span>
      )
    };
    const updatedRows = this.state.rows.map((row, index) => {
      if (index === this.state.currentEdit.num - 1) {
        return editedRow;
      } else {
        return Object.assign({}, row);
      }
    });

    this.setState({
      isEdit: false,
      rows: updatedRows
    });
  }
  handleClose() {
    this.setState({
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false
    });
  }
  handleDelete() {
    const updatedRows = this.state.rows.filter(
        row => row.num !== this.state.currentEdit.num
    );
    this.setState({ rows: updatedRows, isEdit: false });
  }
  submitEmplReq() {
    this.setState({ isLoading: true });
    // let sum = this.state.rows.reduce((acc, val) => {
    //   return parseFloat(acc) + parseFloat(val.extAmt);
    // }, 0);
    // var fetchData = {
    //   jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
    //   userId: this.props.fetchInitialData.credentials.user.uuid,
    //   clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    //   requestId: uuidv1(),
    //   tranReqAmount: sum,
    //   requestDateFrom: this.state.reqDateFrom,
    //   requestDateTo: this.state.reqDateTo,
    //   jsonArray: this.state.rows.map(row => {
    //       return {
    //       lineNum: row.num,
    //       lineItem: row.itemNum,
    //       desc: row.description,
    //       lineExtCost: parseFloat(row.extAmt)
    //       }
    //   })
    // };
    // const stringData = JSON.stringify(fetchData);

    // fetch(
    //   "https://dntndq8p6e.execute-api.us-west-2.amazonaws.com/dev/employee-request",
    //   {
    //     method: "post",
    //     headers: { "content-type": "application/json" },
    //     body: stringData
    //   }
    // )
    //   .then(resp => resp.json())
    //   .then(data => {
    //     if (data.statusCode === 200) {
    //       this.setState({ isLoading: false });
    //       window.alert("successfully created!");
    //       this.props.fetchInitialData.getNotificationCount(
    //         this.props.fetchInitialData.credentials.user.clientId,
    //         this.props.fetchInitialData.credentials.user.jsonWebTok
    //       );
    //       this.setState({
    //         reqDateFrom: "",
    //         reqDateTo: "",
    //         rows: [],
    //         confirmOpen: false
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     window.alert("There was an error");
    //   });
  }
  render() {
    const { classes } = this.props;
    let editItemNum,
      editDescription,
      editExtAmt = "";
    if (this.state.currentEdit !== null) {
      const ce = this.state.currentEdit;
      editItemNum = ce.itemNum;
      editDescription = ce.description;
      editExtAmt = ce.extAmt;
    }
    let sum = this.state.rows.reduce((acc, val) => {
      return parseFloat(acc) + parseFloat(val.extAmt);
    }, 0);
    const load = this.state.isLoading ? (
      <Fade in={this.state.isLoading} unmountOnExit>
        <CircularProgress
          style={{ marginLeft: "10px", color: "#545454" }}
          size="20px"
        />
      </Fade>
    ) : (
      ""
    );
    return (
      <div>
        <Dialog
          open={this.state.confirmOpen}
          TransitionComponent={this.Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Employee Reimbursement Request
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: "0 auto" }}>
              <div style={{ overflowX: "scroll" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="center">
                        Items
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Item #
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Description
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Ext Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map(row => (
                      <TableRow key={row.num}>
                        <TableCell
                          className={classes.tableCell}
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {row.num}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.itemNum}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.description}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {formatter.format(row.extAmt)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        className={classes.tableCell}
                        colSpan={2}
                        align="center"
                      />
                      <TableCell
                        className={classes.tableCell}
                        colSpan={1}
                        align="center"
                      >
                        Total:
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {formatter.format(sum)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              style={{
                margin: "0.3rem",
                backgroundColor: "#545454",
                color: "white"
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.submitEmplReq}
              style={{
                margin: "0.3rem",
                backgroundColor: "rgb(232,102,19)",
                color: "white"
              }}
              variant="contained"
            >
              Submit
              {load}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isEdit}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={this.Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.editRow}>
            <DialogTitle id="alert-dialog-title">Add Request</DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={8}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="itemNum"
                    label="Item Number"
                    defaultValue={editItemNum}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="description"
                    fullWidth
                    label="Description"
                    defaultValue={editDescription}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    label="Extended Amount"
                    margin="dense"
                    defaultValue={editExtAmt}
                    id="extAmt"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                style={{
                  margin: "0.3rem"
                }}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleDelete}
                style={{
                  margin: "0.3rem",
                  backgroundColor: "#545454",
                  color: "white"
                }}
                variant="contained"
              >
                Delete
              </Button>
              <Button
                type="submit"
                style={{
                  margin: "0.3rem",
                  backgroundColor: "rgb(232,102,19)",
                  color: "white"
                }}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.isNewEntryOpen}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={this.Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={this.addRow}>
            <DialogTitle id="alert-dialog-title">Add Request</DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={8}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="itemNum"
                    label="Item Number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="description"
                    fullWidth
                    label="Description"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    label="Extended Amount"
                    margin="dense"
                    id="extAmt"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                style={{
                  margin: "0.3rem"
                }}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  margin: "0.3rem",
                  backgroundColor: "rgb(232,102,19)",
                  color: "white"
                }}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <div className="purchasingForm">
          <h3>Employee Reimbursement Request Form</h3>
          <Grid container justify="flex-start" spacing={24}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="dateFrom"
                type="date"
                label="Date From"
                onChange={event =>
                  this.setState({ reqDateFrom: event.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span />
                    </InputAdornment>
                  )
                }}
              />
              <span style={{ margin: "0.5rem" }} />
              <TextField
                id="dateTo"
                type="date"
                label="Date To"
                onChange={event =>
                  this.setState({ reqDateTo: event.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span />
                    </InputAdornment>
                  )
                }}
              />

              <Paper className={classes.paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="center">
                        Items
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Item #
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Description
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Ext Amount
                      </TableCell>
                      {this.state.rows.length > 0 && (
                        <TableCell className={classes.tableCell} align="center">
                          Edit
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map(row => (
                      <TableRow key={row.num}>
                        <TableCell
                          className={classes.tableCell}
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {row.num}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.itemNum}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.description}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {formatter.format(row.extAmt)}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row.edit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ marginLeft: "0.7rem" }}>
                  <Fab
                    onClick={() => {
                      this.setState({ isNewEntryOpen: true });
                    }}
                    size="small"
                    aria-label="Add"
                    className={classes.fab}
                  >
                    +
                  </Fab>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>Total: </span>
                <span style={{ fontSize: "1.6rem" }}>
                  <strong>{formatter.format(sum)}</strong>
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                onClick={() => this.setState({ confirmOpen: true })}
                style={{
                  margin: "0.5rem",
                  backgroundColor: "rgb(232,102,19)",
                  color: "white"
                }}
                variant="contained"
              >
                Save Reimbursement Form
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableCell: {
    padding: "10px 20px"
  },
  paddingNone: {
    padding: "none"
  },
  paper: {
    margin: "0 auto",
    width: "99%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: "rgb(232,102,19)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(220,92,10)",
      color: "white"
    }
  }
});

EmployeeReimbursementRequest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmployeeReimbursementRequest);
