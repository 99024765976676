import React from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { invoiceDetailsFields } from '../../utils/constants/invoiceColumns';
import InvoiceDetailsTable from './InvoiceDetailsTable';
import LoadingButton from '../LoadingButton';
import VendorStatus from '../../functions/VendorStatus';
class InvoiceEditForm extends React.Component {
  render() {
    const {
      state,
      INVOICE_HEADER_CODES,
      onApprove,
      onDeny,
      isSaving,
      fetchInitialData,
      showAcceptDeny,
      access,
      apcinvoice,
      refNum,
      onSubmit,
      invoiceHeaderFields,
      invoiceHeaderValues,
      apcinvdtl,
      handleChange,
      uomList,
    } = this.props;
    return (
      <>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              right: 0,

              fontSize: '12px',
            }}
          >
            <div>Status: {VendorStatus(apcinvoice.INVOICE_STATUS)}</div>
            <div>Approval Level: {apcinvoice.APPROVAL_STATUS_LEVEL}</div>
          </div>
        </div>
        <h3>Invoice {refNum}</h3>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {[
                {
                  label: 'Invoice Date',
                  value: apcinvoice.INVOICE_DATE
                    ? moment(new Date(apcinvoice.INVOICE_DATE)).format(
                        'MM/DD/YYYY'
                      )
                    : 'N/A',
                },
                { label: 'Payable To', value: apcinvoice.VENDOR_VNAME },
              ].map((field, index) => (
                <div key={index} style={{ marginTop: 5 }}>
                  <span>{field.label}: </span>
                  <span>{field.value}</span>
                </div>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              style={{
                maxHeight: 130,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                minWidth: '100%',
              }}
            >
              {invoiceDetailsFields.map((field, index) => (
                <div key={index} style={{ marginTop: 5 }}>
                  <span
                    style={{
                      position: 'relative',
                      top: 10,
                      paddingRight: '0.6rem',
                    }}
                  >
                    {field.label}:{' '}
                  </span>
                  <TextField
                    select={field.select}
                    id={field.id}
                    type={field.type}
                    defaultValue={state[field.state]}
                    onBlur={handleChange}
                    inputProps={{ id: field.id }}
                  >
                    {field.select &&
                      state.content[field.array] &&
                      state.content[field.array].map((el, index) => (
                        <MenuItem key={index} value={el.value}>
                          {el.value}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {fetchInitialData.credentials.isClient &&
                invoiceHeaderFields &&
                invoiceHeaderFields.map((field, index) => (
                  <div key={index}>
                    <span
                      style={{
                        position: 'relative',
                        top: '10px',
                        paddingRight: '0.6rem',
                      }}
                    >
                      {`${field.name}: `}
                    </span>
                    <TextField
                      required={field.required_flag === 1}
                      select
                      style={{ width: '200px' }}
                      id={field.name + 'Header'}
                      defaultValue={
                        JSON.parse(INVOICE_HEADER_CODES)
                          ? JSON.parse(INVOICE_HEADER_CODES).find(
                              (header) => header.field_id === field.field_id
                            ).value
                          : ''
                      }
                      onChange={handleChange}
                      margin="dense"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{}}
                      InputProps={{
                        id: field.name + 'Header',
                        startAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {invoiceHeaderValues
                        .filter((val) => val.field_id === field.field_id)
                        .map((option, index) => (
                          <MenuItem key={index} value={option.value_id}>
                            {option.value}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                ))}
            </Grid>
          </Grid>
          <InvoiceDetailsTable apcinvdtl={apcinvdtl} uomList={uomList} />
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {access.invoice_edit && (
                <LoadingButton
                  buttonType="submit"
                  label="Save"
                  isLoading={isSaving}
                  backColor={'#3f51b5'}
                  fontColor={'white'}
                  variant={showAcceptDeny ? 'outlined' : 'contained'}
                />
              )}
              {showAcceptDeny && access.invoice_deny && (
                <Button
                  style={{
                    backgroundColor: '#545454',
                    color: 'white',
                    margin: '0.3rem',
                  }}
                  onClick={onDeny}
                  variant="contained"
                >
                  Deny
                </Button>
              )}
              {showAcceptDeny && access.invoice_approve && (
                <Button
                  onClick={onApprove}
                  variant="contained"
                  className="primaryVLButton"
                >
                  Approve
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default InvoiceEditForm;
