import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';
import PrintIcon from "@material-ui/icons/Print";

import VendorStatus from '../functions/VendorStatus'
import 'react-table/react-table.css';
import { approverVendorColumns } from "../utils/constants/vendorsColumns";
import PdfTableDialog from "../components/pdf/PdfTableDialog";
import { responsive } from '../utils/constants/muidatatablesConsts';
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginTop: 10,
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)',
    }
  },
  tableCard: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 1400
  }
});

class Approver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '',
      data: null,
      muiTableData: null,
      option: 'No Filter',
      openPrintDialog: false,
      attachments: 0,
      pendingVendorsData: null
    };
    this.filter = this.filter.bind(this);
  }

  componentDidMount() {
    const cId = JSON.stringify({
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      userId: this.props.fetchInitialData.credentials.user.uuid,
    });
    fetch(`${process.env.REACT_APP_API}/get-vendor-forms`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: cId,
    })
        .then((resp) => resp.json())
        .then((info) => {
          const data = info.data
          const filterByApproverLvl = data.master
              .filter((form) => !(form.VL_STATUS_LEVEL >=
                  parseInt(this.props.fetchInitialData.credentials.user.approver_level, 10)
                  && form.VL_STATUS === 1));


          this.setState({
            muiTableData: this.makeTableData(filterByApproverLvl, data.attachment),
            attachments: data.attachment,
            data: data.master,
            pendingVendorsData: filterByApproverLvl
          });
        })

        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
        });
  }

  makeTableData = (data, attachment) => {
    return data.map((form) => {
      let vendorObj = [];
      vendorObj.push(form.VL_ID);
      vendorObj.push(
          <div
              className="editLink"
              onClick={() => this.props.history.push(`/viewvendordetails/${form.VENDOR_ID}`)}>
            {form.VENDOR_VNAME}
          </div>
      );
      vendorObj.push(form.CITY_ADDR5);
      vendorObj.push(form.STATE_PROV);
      vendorObj.push(form.SUBMISSION_DATE ? moment(form.SUBMISSION_DATE).format('MM/DD/YYYY') : '');
      vendorObj.push(attachment.filter((el) => el.OBJECT_ID === form.VENDOR_ID).length);
      vendorObj.push(form.APPROVAL_STATUS_LEVEL - 1);
      vendorObj.push(VendorStatus(form.VL_STATUS));
      return vendorObj;
    });
  };

  filter(event) {
    event.preventDefault();
    if (event.target.id === 'None') {
      this.setState({ forms: this.state.copyOfForms });
    } else {
      const filtered = this.state.copyOfForms.map((form) => {
        return Object.assign({}, form);
      });
      const finalFilter = filtered.filter(
          (form) => form.VL_STATUS === event.target.id
      );
      this.setState({ forms: finalFilter });
    }
  }

  render() {
    const { data } = this.state;
    const { classes } = this.props;
    if (!data) {
      return (
          <LoadingCircle/>
      );
    } else {
      return (
          <div className={classes.paymentForm}>
            <div className={classes.tableCard}>
              <MUIDataTable
                  title={'Pending Vendor Forms'}
                  data={this.state.muiTableData}
                  columns={approverVendorColumns}
                  options={{
                    customSort: (data, colIndex, order) => {
                      let dateCols = approverVendorColumns.reduce((dateCols, currCol, index) => {
                        if (currCol.name.toLowerCase().indexOf('date') !== -1) {
                          return [...dateCols, index];
                        } else return dateCols;
                      }, []);
                      return data.sort((a, b) => {
                        if (dateCols.indexOf(colIndex) !== -1) {
                          return (
                              (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                                  ? -1
                                  : 1) * (order === 'desc' ? 1 : -1)
                          );
                        } else {
                          return (
                              ((typeof a.data[colIndex] === 'object' ? a.data[colIndex].props.children : a.data[colIndex])
                              < (typeof b.data[colIndex] === 'object' ? b.data[colIndex].props.children : b.data[colIndex])
                                  ? -1 : 1) * (order === 'desc' ? 1 : -1)
                          );
                        }
                      });
                    },
                    customSearch: () => true,
                    onSearchChange: (query) => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.data.filter((vendor) => {
                          if (query) {
                            return (
                                vendor.VL_ID
                                    .toString()
                                    .includes(query.trim()) ||
                                vendor.VENDOR_VNAME
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                vendor.STATE_PROV
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                vendor.CITY_ADDR5
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                (vendor.APPROVAL_STATUS_LEVEL - 1)
                                    .toString()
                                    .includes(query.trim()) ||
                                VendorStatus(vendor.VL_STATUS)
                                    .toLowerCase()
                                    .includes(query.toLowerCase().trim()) ||
                                moment(vendor.SUBMISSION_DATE).format('MM/DD/YYYY')
                                    .toString()
                                    .includes(query.trim()) ||
                                this.state.attachments.filter((el) => el.OBJECT_ID === vendor.VENDOR_ID).length
                                    .toString()
                                    .includes(query.trim())
                            );
                          }
                          return vendor;
                        }), this.state.attachments)
                      });
                    },
                    onSearchClose: () => {
                      this.setState({
                        muiTableData: this.makeTableData(this.state.data, this.state.attachments)
                      });
                    },
                    selectableRows: false,
                    filterType: 'dropdown',
                    responsive,
                    print: false,
                  }}
              />
            </div>
            <PdfTableDialog
                isLarge
                open={this.state.openPrintDialog}
                fileName={'pendingVendorForms'}
                title={'Pending Vendor Forms'}
                data={this.state.muiTableData}
                columns={approverVendorColumns}
                onClose={() => this.setState({ openPrintDialog: false })}
            />
            <Button
                className="primaryVLButton"
                style={{marginTop: 10}}
                onClick={() => this.setState({ openPrintDialog: true })}>
              PDF
              <PrintIcon style={{ marginLeft: 10 }}/>
            </Button>
          </div>
      );
    }
  }
}

export default withStyles(styles)(Approver);
