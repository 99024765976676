export const userDepartmentCols = [
    {
      name: 'user',
      label: 'User',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'count',
      label: 'Count',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  