import React from "react";
import PropTypes from "prop-types";
import { withStyles, Tabs, Tab } from "@material-ui/core";

import AdminGeneralSetting from "../containers/AdminGeneralSettings";
import AdminVendorSettings from "../containers/AdminVendorSettings";
import AdminCodesSettings from "../containers/AdminCodesSettings";
import AdminContractSettings from "../containers/AdminContractSettings";
import VerifyAccess from "../functions/VerifyAccess";
import AdminAccountingSettings from "../containers/AdminAccountingSettings";
import {
  adminSettingsAccessActions,
  adminSettingsTabHashes,
  settingsTabs,
} from "../utils/constants/adminSettings";
import AdminUsersAndRolesMaintenance from "./AdminUsersAndRolesMaintenance";
import AdminApprovalSettings from "./AdminApprovalSettings";
import AdminInvoiceSettings from "./AdminInvoiceSettings";
import AdminPOSettings from "./AdminPOSettings";
import { AntTabs, AntTab } from "../components/common/Tabs";

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appBarWidth: 1000,
      tabValue: 1,
      access: {},
    };
  }
  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      adminSettingsAccessActions,
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.viewHash();
    this.calcSize();
    window.addEventListener("resize", this.calcSize.bind(this));
  }

  viewHash = () => {
    const hash = window.location.hash;
    const currentHash = adminSettingsTabHashes.find((el) => el.hash === hash);
    const tabVal = currentHash ? currentHash.value : -1;
    this.setState({ tabValue: tabVal === -1 ? 1 : tabVal + 1 });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      window.location.hash = adminSettingsTabHashes
        .find((el) => el.value === newValue - 1)
        .hash.slice(1);
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcSize.bind(this));
  }

  calcSize() {
    this.setState({ appBarWidth: window.innerWidth });
  }

  renderSwitch = (param) => {
    const {clientInfo} = this.props.fetchInitialData.credentials
    switch (param) {
      case 1:
        return (
          <AdminGeneralSetting
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 2:
        return (
          <AdminCodesSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 3:
        return (
          <AdminUsersAndRolesMaintenance
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 4:
        return (
          <AdminApprovalSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 5:
        return clientInfo.invoice_license === 1 && (
          <AdminInvoiceSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 6:
        return clientInfo.po_license === 1 && (
          <AdminPOSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 7:
        return (
          <AdminVendorSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 8:
        return clientInfo.contract_license === 1 && (
          <AdminContractSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      case 9:
        return (
          <AdminAccountingSettings
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
      default:
        return (
          <AdminGeneralSetting
            access={this.state.access}
            fetchInitialData={this.props.fetchInitialData}
          />
        );
    }
  };
  render() {


    const {clientInfo} = this.props.fetchInitialData.credentials
    const variant = this.state.appBarWidth > 1100 ? "fullWidth" : "scrollable";
    const centerTrue = this.state.appBarWidth > 1100;
    return (
      <div style={{ height: "55px" }}>
        <div
          style={{
            borderBottom: "1px solid rgba(0,0,0,.3)",
            backgroundColor: "white",
            width: "100%",
            zIndex: 200,
          }}
        >
          <AntTabs
            color={this.props.fetchInitialData.credentials.primaryAppColor}
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            variant={variant}
            centered={centerTrue}
            scrollButtons="auto"
          >
            {settingsTabs.map((cur, index) => {
              if (
                cur.access.map((currAccess) => this.state.access[currAccess]) && (!cur.license || clientInfo[cur.license] === 1)
              ) {
                return (
                  <AntTab
                    key={index}
                    color={
                      this.props.fetchInitialData.credentials.primaryAppColor
                    }
                    label={cur.label}
                    value={index + 1}
                  />
                );
              }
            })}
          </AntTabs>
        </div>
        <div style={{ paddingBottom: "2rem"}}>
          {this.state.tabValue && this.renderSwitch(this.state.tabValue)}
        </div>
      </div>
    );
  }
}

export default AdminSettings;
