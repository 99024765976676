import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = {
  root: {
    height: '40px',
  },
  resize: {
    fontSize: 13
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#E86613',
    },
  }
}

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      option: this.props.default,
    }
  }

  handleChange = event => {
    this.props.passUp(this.props.id, event.target.value)
    this.setState({ option: event.target.value })
  }

  render() {
    const { classes } = this.props
    if (this.props.showDescription) {
      return (
        <div>
        <TextField
            className="textfield"
            required={this.props.required}
            select
            disabled={this.props.disabled}
            id={this.props.id}
            label={this.props.label}
            value={this.state.option}
            onChange={this.handleChange}
            fullWidth
            margin="dense"
            variant="filled"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.root
              },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              )
            }}
          >
            {
              this.props.options.map(option => {
                if (option.R_VALUE === "other") {
                  return (
                    <MenuItem key={option.R_VALUE} value={option.DESCRIPTION}>
                      {option.DESCRIPTION}
                    </MenuItem>
                  )
                }
                return (
                  <MenuItem key={option.R_VALUE} value={option.DESCRIPTION}>
                    {option.R_VALUE + ' - ' + option.DESCRIPTION}
                  </MenuItem>
                )
              })
            }
          </TextField>
        </div>
      )
    }
    if (this.props.showWithDescription) {
      return (
        <div>
          <TextField
            className="textfield"
            required={this.props.required}
            select
            id={this.props.id}
            label={this.props.label}
            value={this.state.option}
            onChange={this.handleChange}
            fullWidth
            disabled={this.props.disabled}
            margin="dense"
            variant="filled"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.root
              },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              )
            }}
          >
            {
              this.props.options.map(option => {
                return (
                  <MenuItem key={option.R_VALUE} value={this.props.passDescription ? option.DESCRIPTION : option.R_VALUE}>
                    {option.R_VALUE + ' - ' + option.DESCRIPTION}
                  </MenuItem>
                )
              })
            }
          </TextField>
        </div>
      )
    }
    if (!this.props.options) {
      return (
        <TextField
          className="textfield"
          select
          disabled={this.props.disabled}
          required={this.props.required}
          id={this.props.id}
          label={this.props.label}
          value={this.state.option}
          onChange={this.handleChange}
          fullWidth
          margin="dense"
          variant="filled"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.root
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            )
          }}
        >No Choice Available</TextField>
      )
    }
    if (this.props.underlined) {
      return (

        <TextField
          className="textfield"
          select
          disabled={this.props.disabled}
          required={this.props.required}
          id={this.props.id}
          label={this.props.label}
          value={this.state.option}
          onChange={this.handleChange}
          fullWidth
          margin="dense"
          variant="standard"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.root
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            )
          }}
        >
          {
            this.props.options.map(option => {
              return (
                <MenuItem key={option.R_VALUE} value={option.R_VALUE}>
                  {option.R_VALUE}
                </MenuItem>
              )
            })
          }
        </TextField>

      )
    }
    else {
      return (
        <div>
          <TextField
            className="textfield"
            select
            disabled={this.props.disabled}
            required={this.props.required}
            id={this.props.id}
            label={this.props.label}
            value={this.state.option}
            onChange={this.handleChange}
            fullWidth
            margin="dense"
            variant="filled"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.root
              },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              )
            }}
          >
            {
              this.props.options.map(option => {
                return (
                  <MenuItem key={option.R_VALUE} value={option.R_VALUE}>
                    {option.R_VALUE}
                  </MenuItem>
                )
              })
            }
          </TextField>
        </div>
      )
    }
  }
}


Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Dropdown);

