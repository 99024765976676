import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, TextField, Button, Fade, CircularProgress, RadioGroup, FormControlLabel, FormControl, FormLabel,
  Radio, Checkbox, MenuItem, Grid, withStyles, Snackbar, SnackbarContent, IconButton, Tooltip, InputAdornment
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import InputMask from 'react-input-mask';
import { withSnackbar } from 'notistack';

import DropDown from '../components/DropDown';
import InputTextFields from '../components/TextFields';
import AddressForm from "../components/AddressForm";
import LoadingCircle from "../components/common/LoadingCircle"

import './VendorForm.css';

const inputs = {
  meta: [
    {
      id: 'vendorName',
      label: 'Vendor Name',
      required: 'y',
    },
    {
      id: 'legalName',
      label: 'Legal Name',
      required: 'y',
    },
    {
      id: 'vendorSName',
      label: 'SearchName',
      required: 'y',
    },
    {
      id: 'taxId',
      label: 'EIN / SSN',
      required: 'y',
    },
    {
      id: 'urlAddr',
      label: 'Website',
      required: 'n',
    },
  ],
  mainAddress: [
    {
      id: 'addr1',
      label: 'Address 1',
      required: 'n',
    },
    {
      id: 'addr2',
      label: 'Address 2',
      required: 'n',
    },
    {
      id: 'cityAddr5',
      label: 'City',
      required: 'n',
    },
    // {
    //   id: "stateProv",
    //   label: "State",
    //   required: "y",
    //   half: true
    // },
    // {
    //   id: "postalCode",
    //   label: "Zip",
    //   required: "y",
    //   half: true
    // }
    // {
    //   id: 'phoneNum',
    //   label: 'Main Phone',
    //   required: 'y'
    // },
    // {
    //   id: 'faxNum',
    //   label: 'Main Fax',
    //   required: 'n'
    // }
  ],
  vendorRemit: [
    {
      id: 'remitAddr1',
      label: 'Address 1',
      required: 'n',
    },
    {
      id: 'remitAddr2',
      label: 'Address 2',
      required: 'n',
    },
    {
      id: 'remitCityAddr5',
      label: 'City',
      required: 'n',
    },
    // {
    //   id: "remitStateProv",
    //   label: "State",
    //   required: "n",
    //   half: true
    // },
    // {
    //   id: "remitPostalCode",
    //   label: "Zip",
    //   required: "n",
    //   half: true
    // }
  ],
  contact1: [
    {
      id: 'vendorContctc1',
      label: 'Name',
      required: 'y',
    },
    {
      id: 'emailc1',
      label: 'Email',
      required: 'y',
      type: 'email',
    },
  ],
  // {
  //   id: 'phonec1',
  //   label: 'Phone',
  //   required: 'y'
  // },
  // {
  //   id: 'mobilec1',
  //   label: 'Mobile',
  //   required: 'y'
  // },
  contact1Cont: [
    {
      id: 'jobTitlec1',
      label: 'Job Title',
      required: 'n',
    },
  ],
  contact2: [
    {
      id: 'vendorContctc2',
      label: 'Name',
      required: 'n',
    },
    {
      id: 'emailc2',
      label: 'Email',
      required: 'n',
      type: 'email',
    },
  ],
  // {
  //   id: 'phonec2',
  //   label: 'Phone',
  //   required: 'n'
  // },
  // {
  //   id: 'mobilec2',
  //   label: 'Mobile',
  //   required: 'n'
  // },
};

const fieldIds2 = {
  vendorName: '',
  legalName: '',
  vendorSName: '',
  taxId: '',
  urlAddr: '',
  addr1: '',
  addr2: '',
  cityAddr5: '',
  stateProv: '',
  postalCode: '',
  phoneNum: '',
  faxNum: '',
  remitAddr1: '',
  remitAddr2: '',
  remitCityAddr5: '',
  remitStateProv: '',
  remitPostalCode: '',
  vendorContctc1: '',
  emailc1: '',
  phonec1: '',
  mobilec1: '',
  faxc1: '',
  jobTitlec1: '',
  vendorContctc2: '',
  emailc2: '',
  phonec2: '',
  mobilec2: '',
  faxc2: '',
  jobTitlec2: '',
  bankEntity: '',
  vbankAcctNo: '',
  vbankAcctNoRepeat: '',
  comment: '',
  preparerName: '',
  password: '',
  termCode: '',
  invCurrency: '',
  languageCode: '',
  clientId: '',
  taxClass: '',
  confirmPassword: '',
};

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  checkboxLabel: {
    position: 'absolute',
    top: -15,
    left: 8,
    '& label': {
      '& span': {
        fontSize: '12px !important'
      }
    }
  }
};

class VendorForm1 extends Component {
  constructor(props) {
    super(props);
    this.bankEntity = React.createRef();
    this.vBankAcctNoRepeat = React.createRef();
    this.myRef = React.createRef();
    this.phoneNum = React.createRef();
    this.faxNum = React.createRef();
    this.phonec1 = React.createRef();
    this.mobilec1 = React.createRef();
    this.faxc1 = React.createRef();
    this.phonec2 = React.createRef();
    this.mobilec2 = React.createRef();
    this.faxc2 = React.createRef();
    this.state = {
      clientId: '',
      isLoading: false,
      w9mandetory: false,
      w9File: {
        name: '',
        src: '',
        size: null,
      },
      taxId: '',
      taxClassification: '',
      fileMineType: '',
      termCode: '',
      invCurrency: '',
      locationCode: '',
      vBankAcctTp: '',
      sameAsRemit: false,
      clientSrc: '',
      noClient: false,

      mainAddress: null,
      remitAddress: null,
      errors: {
        emailAddress: '',
        passwordLength: '',
        password: '',
        fileSize: '',
        phoneNum: '',
        faxNum: '',
        phonec1: '',
        mobilec1: '',
        faxc1: '',
        faxc2: '',
        phonec2: '',
        mobilec2: '',
        bankEntity: '',
        vBankAcctNoRepeat: '',
      },
      preparerEmail: '',
      preparerEmailCopy: '',
      emailTake: false,
      emailAlreadyTaken: null,
      termsAndConditions: false,
      dropdowns: null,
      vendorName: '',
      legalName: '',
      vendorSName: '',
      additionalFiles: [],
      filterList: [],
      filterValue: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChangeToRemit = this.handleChangeToRemit.bind(this);
  }

  callSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  componentDidMount() {
    const pathSnippet = window.location.host.split('.')[0];
    const client = {
      subdomain: pathSnippet,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonClient = JSON.stringify(client);
    fetch(`${process.env.REACT_APP_API}/get-client`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
      },
      body: jsonClient,
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (!info.success) {
            this.props.fetchInitialData.callSnack(
                'There was an error',
                'error',
                3000
            );
            return null;
          }
          const data = info.data;
          const termCodeDropdown = data.dropdowns.filter(
              (option) => option.R_KEY === 'termCode'
          );
          const invCurrencyDropdown = data.dropdowns.filter(
              (option) => option.R_KEY === 'invCurrency'
          );
          const languageCodeDropdown = data.dropdowns.filter(
              (option) => option.R_KEY === 'languageCode'
          );
          const dropdownMenus = {
            termCodeDropdown: termCodeDropdown,
            invCurrencyDropdown: invCurrencyDropdown,
            languageCodeDropdown: languageCodeDropdown,
          };
          this.setState({
            clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
            clientSrc: this.props.fetchInitialData.credentials.clientInfo.logo_img,
            dropdowns: dropdownMenus,
            filterList: data.purchaseFilterlist
          });
        });
  }

  updateAddress = (type, address) => {
    this.setState({ [type]: address });
  };
  handleCountryStateZipChange = (type, value) => {
    this.setState({ [type]: value });
  };
  handleChange = (event) => {
    this.setState({ taxClassification: event.target.value });
  };

  handleChangeToRemit() {
    this.setState({ sameAsRemit: !this.state.sameAsRemit });
  }

  handleCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleonChange = (name) => (event) => {
    if (name === 'vendorName') {
      this.setState({
        vendorName: event.target.value,
        legalName: event.target.value,
        vendorSName: event.target.value,
      });
    }
    this.setState({ [name]: event.target.value });
  };

  validatePhoneNumber(number) {
    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return numberPattern.test(number);
  }

  throwPhoneError(validateFields, fieldId, throwErrorMessage, optional) {
    let error;
    if (optional) {
      if (validateFields[fieldId] !== '') {
        if (!this.validatePhoneNumber(validateFields[fieldId].replace('_', ''))) {
          error = throwErrorMessage;
          if (this[fieldId].current) {
            this[fieldId].current.focus();
          }
        } else {
          error = '';
        }
      } else {
        error = '';
      }
    } else {
      if (!this.validatePhoneNumber(validateFields[fieldId].replace('_', ''))) {
        error = throwErrorMessage;
        if (this[fieldId].current) {
          this[fieldId].current.focus();
        }
      } else {
        error = '';
      }
    }
    return error;
  }

  validate(validateFields) {
    const { errors } = this.state;
    let isError = false;

    if (this.state.w9File.size > 5000000) {
      isError = true;
      errors.fileSize = 'File is over 5MB';
      if (this.myRef.current) {
        this.myRef.current.focus();
      }
    } else {
      errors.fileSize = '';
    }

    if (fieldIds2.vbankAcctNoRepeat !== fieldIds2.vbankAcctNo) {
      isError = true;
      errors.vBankAcctNoRepeat = 'Account Numbers do not match';
      if (this.vBankAcctNoRepeat.current) {
        this.vBankAcctNoRepeat.current.focus();
      }
    } else {
      errors.vBankAcctNoRepeat = '';
    }

    if (
        fieldIds2.bankEntity.replace('_', '').length !== 9 &&
        fieldIds2.bankEntity.replace('_', '').length !== 0
    ) {
      isError = true;
      errors.bankEntity = 'Routing Number must be 9 digits';
      if (this.bankEntity.current) {
        this.bankEntity.current.focus();
      }
    } else {
      errors.bankEntity = '';
    }

    errors.faxc2 = this.throwPhoneError(
        validateFields,
        'faxc2',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.mobilec2 = this.throwPhoneError(
        validateFields,
        'mobilec2',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.phonec2 = this.throwPhoneError(
        validateFields,
        'phonec2',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.faxc1 = this.throwPhoneError(
        validateFields,
        'faxc1',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.mobilec1 = this.throwPhoneError(
        validateFields,
        'mobilec1',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.phonec1 = this.throwPhoneError(
        validateFields,
        'phonec1',
        'Must be of format xxx-xxx-xxxx',
        true
    );
    errors.phoneNum = this.throwPhoneError(
        validateFields,
        'phoneNum',
        'Must be of format xxx-xxx-xxxx',
        true
    );

    if (
        errors.faxc2 ||
        errors.mobilec2 ||
        errors.phonec2 ||
        errors.faxc1 ||
        errors.mobilec1 ||
        errors.phonec1 ||
        errors.phoneNum
    ) {
      isError = true;
    }

    if (fieldIds2.password !== fieldIds2.confirmPassword) {
      isError = true;
      errors.password = 'Password does not match!';
    } else {
      errors.password = '';
    }

    if (fieldIds2.preparerEmailAddress !== fieldIds2.preparerEmailAddressCopy) {
      isError = true;
      errors.emailAddress = 'Email does not match!';
    } else {
      errors.emailAddress = '';
    }

    if (fieldIds2.password.length < 6) {
      isError = true;
      errors.passwordLength = 'Password must be atleast 6 characters';
    } else {
      errors.passwordLength = '';
    }

    if (isError) {
      this.setState({
        errors: errors,
      });
    }
    return isError;
  }

  handleSubmit(event) {
    event.preventDefault();
    for (let property in fieldIds2) {
      if (fieldIds2.hasOwnProperty(property)) {
        const prop = property.toString();
        if (event.target[prop]) {
          fieldIds2[prop] = event.target[prop].value;
        }
      }
    }
    if (this.state.mainAddress) {
      fieldIds2.addr1 = event.target.mainAddress1 ? event.target.mainAddress1.value : '';
      fieldIds2.addr2 = event.target.mainAddress2.value;
      fieldIds2.cityAddr5 = event.target.mainAddressCity.value;
      fieldIds2.country = event.target.mainAddressCountry.value;
      fieldIds2.stateProv = event.target.mainAddressState.value;
      fieldIds2.postalCode = event.target.mainAddressZip.value;
    }
    if (this.state.remitAddress) {
      fieldIds2.remitAddr1 = event.target.remitAddress1 ? event.target.remitAddress1.value : '';
      fieldIds2.remitAddr2 = event.target.remitAddress2.value;
      fieldIds2.remitCityAddr5 = event.target.remitAddressCity.value;
      fieldIds2.remitCountry = event.target.remitAddressCountry.value;
      fieldIds2.remitStateProv = event.target.remitAddressState.value;
      fieldIds2.remitPostalCode = event.target.remitAddressZip.value;
    }
    fieldIds2.filter = this.state.filterValue;
    fieldIds2.clientId = this.state.clientId;
    fieldIds2.invCurrency = this.state.invCurrency;
    fieldIds2.termCode = this.state.termCode;
    fieldIds2.languageCode = this.state.languageCode;
    fieldIds2.subdomain = window.location.host.split('.')[0];
    fieldIds2.vbankAcctTp = this.state.vBankAcctTp;
    fieldIds2.preparerEmailAddress = this.state.preparerEmail;
    fieldIds2.preparerEmailAddressCopy = this.state.preparerEmailCopy;
    fieldIds2.sameAsRemit = this.state.sameAsRemit;

    if (this.state.sameAsRemit) {
      fieldIds2.remitAddr1 = null;
      fieldIds2.remitAddr2 = null;
      fieldIds2.remitCityAddr5 = null;
      fieldIds2.remitStateProv = null;
      fieldIds2.remitPostalCode = null;
    }

    const validateFields = {
      phoneNum: fieldIds2['phoneNum'],
      faxNum: fieldIds2['faxNum'],
      phonec1: fieldIds2['phonec1'],
      mobilec1: fieldIds2['mobilec1'],
      faxc1: fieldIds2['faxc1'],
      phonec2: fieldIds2['phonec2'],
      mobilec2: fieldIds2['mobilec2'],
      faxc2: fieldIds2['faxc2'],
    };
    const err = this.validate(validateFields);
    if (err) {
      this.setState({ isLoading: false });
    } else {
      fieldIds2.taxClass = this.state.taxClassification;
      fieldIds2.clientId = this.state.clientId;

      this.setState({ isLoading: true });
      const jsonProspect = JSON.stringify(fieldIds2);
      fetch(`${process.env.REACT_APP_API}/vendorform-submission`, {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: jsonProspect,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.error) {
              this.callSnack(data.error, 'error', 3500);
              return null
            }else if(data.error === 'Email already taken') {
              this.setState({
                emailTaken: true,
                emailAlreadyTaken: fieldIds2.preparerEmailAddress,
              });
              return null;
            } else if (data.success) {
              this.callSnack('Application Submitted', 'success', 3500);
              this.props.history.push('/submitted');
            }
          });
      this.setState({ isLoading: false });
    }
  }

  dropDownChange(name, value) {
    this.setState({ [name]: value });
  }

  closeModal() {
    this.setState({ failedInputs: false });
  }

  GroupEntires(inputs) {
    return inputs.map((input) => {
      return <InputTextFields key={input.id} input={input}/>;
    });
  }

  normalTextField(id, label, type, required) {
    const { classes } = this.props;
    return (
        <TextField
            id={id}
            className="textfield"
            label={label}
            required={required}
            fullWidth
            type={type}
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
            }}
            autoComplete="off"
        />
    );
  }

  maskedTextField(id, label, mask, ref, error, required) {
    const { classes } = this.props;
    if (ref === null) {
      return (
          <InputMask mask={mask}>
            {() => (
                <TextField
                    id={id}
                    required={required}
                    fullWidth
                    variant="filled"
                    label={label}
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    autoComplete="off"
                />
            )}
          </InputMask>
      );
    } else {
      return (
          <InputMask mask={mask}>
            {() => (
                <TextField
                    id={id}
                    inputRef={ref}
                    required={required}
                    fullWidth
                    variant="filled"
                    label={label}
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    error={error !== ''}
                    helperText={error}
                    autoComplete="off"
                />
            )}
          </InputMask>
      );
    }
  }

  errorTextField(id, label, error, ref, type, required) {
    const { classes } = this.props;
    return (
        <TextField
            inputRef={ref}
            id={id}
            className="textfield"
            label={label}
            required={required}
            fullWidth
            type={type}
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
            }}
            error={error !== ''}
            helperText={error}
            autoComplete="off"
        />
    );
  }

  render() {
    if (this.state.clientId === '') {
      return (
          <LoadingCircle/>
      );
    }
    const hideRemit = this.state.sameAsRemit
        ? { display: 'none' }
        : { display: 'block' };
    const load = this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <CircularProgress color="inherit" size="20px"/>
        </Fade>
    ) : (
        <SendIcon style={{ marginLeft: '5px' }}/>
    );
    const submitting = this.state.isLoading ? (
        <span style={{ paddingRight: '0.3rem' }}>Submitting Form</span>
    ) : (
        'Submit Form'
    );

    const emailTaken = this.state.emailAlreadyTaken
        ? 'Sorry, ' + this.state.emailAlreadyTaken + ' is already taken'
        : '';

    const readyToSubmit = !this.state.termsAndConditions;
    const readyToSubmitStyle = this.state.termsAndConditions
        ? { color: 'white', marginTop: '2%', backgroundColor: '#E86613' }
        : { color: 'white', marginTop: '2%', backgroundColor: '#656565' };

    const dropdownDefaults = {
      termCodeDropdown: '',
      invCurrencyDropdown: '',
      languageCodeDropdown: '',
    };
    for (let property in dropdownDefaults) {
      if (dropdownDefaults.hasOwnProperty(property)) {
        if (
            !this.state.dropdowns[property] ||
            this.state.dropdowns[property].length === 0
        ) {
          dropdownDefaults[property] = '';
        } else {
          dropdownDefaults[property] = this.state.dropdowns[
              property
              ][0].R_VALUE;
        }
      }
    }
    const { classes } = this.props;
    return (
        <div className="formVendor">
          <form style={{ position: 'relative' }} onSubmit={this.handleSubmit}>
            <input type="hidden" value="something"/>

            <div className="relative">
              <h2 className="title" style={{margin: '1rem 0'}}>New Vendor Registration</h2>
              <div style={{float: 'right'}}>
              <img
                src={this.props.fetchInitialData.credentials.clientInfo.logo_img}
                style={{ margin: "0.5rem", height: "70px" }}
                alt="client logo"
            />
            </div>
            </div>
            <hr/>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  {this.props.fetchInitialData.credentials.clientInfo.filter_value_label}
                </Typography>
                <TextField
                    id="clientFilter"
                    className="textfield"
                    variant="filled"
                    style={{ width: '200px' }}
                    margin="dense"
                    select
                    value={this.state.filterValue}
                    onChange={(e) => this.setState({ filterValue: e.target.value })}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    autoComplete="off"
                >
                  {this.state.filterList.map(option => {
                    return (
                        <MenuItem key={option.FILTER_VALUE} value={option.FILTER_VALUE}>
                          {`${option.DESCRIPTION}`}</MenuItem>
                    )
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Vendor Information
                </Typography>

                <TextField
                    id="vendorName"
                    className="textfield"
                    label="Vendor Name"
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    onChange={this.handleonChange('vendorName')}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    autoComplete="off"
                />
                <TextField
                    id="legalName"
                    className="textfield"
                    label="Legal Name"
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    value={this.state.legalName}
                    onChange={this.handleonChange('legalName')}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    autoComplete="off"
                />

                <TextField
                    id="vendorSName"
                    className="textfield"
                    label="Vendor Search Name"
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    value={this.state.vendorSName}
                    onChange={this.handleonChange('vendorSName')}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                                title="Name that Vendor Launch will use to search"
                                placement="right"
                            >
                              <span className="tooltipQuestion">?</span>
                            </Tooltip>
                          </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                />
                <TextField
                    id="taxId"
                    className="textfield"
                    label="EIN / SSN"
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    inputProps={{ maxLength : 11, minLength: 9 }}
                    autoComplete="off"
                    value={this.state.taxId}
                    onChange={(e) =>
                        e.target.value.length > 11
                            ? null
                            : this.setState({ taxId: e.target.value })
                    }
                />
                {this.normalTextField('urlAddr', 'Website', null, false, false)}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Tax Classification
                </Typography>

                <FormControl margin="dense" component="fieldset">
                  <FormLabel component="legend"/>
                  <RadioGroup
                      aria-label="taxClassification"
                      name="taxClassification"
                      required
                      value={this.state.taxClassification}
                      onChange={this.handleChange}
                  >
                    <FormControlLabel
                        value="Sole Proprietor"
                        control={
                          <Radio
                              required
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                          />
                        }
                        label="Sole Proprietor"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="Corporation"
                        control={
                          <Radio
                              required
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                          />
                        }
                        label="Corporation"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="S-Corp"
                        control={
                          <Radio
                              required
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                          />
                        }
                        label="S-Corp"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="Partnership"
                        control={
                          <Radio
                              required
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                          />
                        }
                        label="Partnership"
                        labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Main Address
                </Typography>
                <AddressForm isCreateVendor type="mainAddress" updateAddress={this.updateAddress}/>
                {this.maskedTextField(
                    'phoneNum',
                    'Main Phone',
                    '999-999-9999',
                    this.phoneNum,
                    this.state.errors.phoneNum,
                    true
                )}
                {this.maskedTextField(
                    'faxNum',
                    'Main Fax',
                    '999-999-9999',
                    this.faxNum,
                    this.state.errors.faxNum,
                    false
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} style={{ position: 'relative' }}>
                <div className={classes.checkboxLabel}>
                  <FormControlLabel
                      control={<Checkbox
                          checked={this.state.sameAsRemit}
                          onChange={this.handleChangeToRemit}
                          value="checkedA"
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                      />}
                      label="Remit same as main"
                  />
                </div>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Remit Information
                </Typography>

                <div style={hideRemit}>
                  <AddressForm isCreateVendor type="remitAddress" updateAddress={this.updateAddress}/>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Contact #1
                </Typography>
                {this.GroupEntires(inputs.contact1)}

                {this.maskedTextField(
                    'phonec1',
                    'Phone',
                    '999-999-9999',
                    this.phonec1,
                    this.state.errors.phonec1,
                    false
                )}
                {this.maskedTextField(
                    'mobilec1',
                    'Mobile',
                    '999-999-9999',
                    this.mobilec1,
                    this.state.errors.mobilec1,
                    false
                )}
                {this.maskedTextField(
                    'faxc1',
                    'Fax',
                    '999-999-9999',
                    this.faxc1,
                    this.state.errors.faxc1,
                    false
                )}
                {this.GroupEntires(inputs.contact1Cont)}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Contact #2 (Optional)
                </Typography>
                {this.GroupEntires(inputs.contact2)}
                {this.maskedTextField(
                    'phonec2',
                    'Phone',
                    '999-999-9999',
                    this.phonec2,
                    this.state.errors.phonec2,
                    false
                )}
                {this.maskedTextField(
                    'mobilec2',
                    'Mobile',
                    '999-999-9999',
                    this.mobilec2,
                    this.state.errors.mobilec2,
                    false
                )}
                {this.maskedTextField(
                    'faxc2',
                    'Fax',
                    '999-999-9999',
                    this.faxc2,
                    this.state.errors.faxc2,
                    false
                )}
                {this.normalTextField(
                    'jobTitlec2',
                    'Job Title',
                    null,
                    false,
                    false
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Banking Information
                </Typography>
                {this.maskedTextField(
                    'bankEntity',
                    'Routing Number',
                    '999999999',
                    this.bankEntity,
                    this.state.errors.bankEntity,
                    false
                )}
                {this.normalTextField(
                    'vbankAcctNo',
                    'Bank Account Number',
                    null,
                    false,
                    false
                )}
                {this.errorTextField(
                    'vbankAcctNoRepeat',
                    'Repeat Bank Account Number',
                    this.state.errors.vBankAcctNoRepeat,
                    this.vBankAcctNoRepeat,
                    null,
                    false,
                    false
                )}
                <TextField
                    select
                    className="textfield"
                    id="vBankAcctTp"
                    label="Account Type"
                    value={this.state.vBankAcctTp}
                    onChange={(event) =>
                        this.setState({ vBankAcctTp: event.target.value })
                    }
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    margin="dense"
                    variant="filled"
                >
                  <MenuItem key="N/A" value="N/A">
                    N/A
                  </MenuItem>
                  <MenuItem key="Checking" value="Checking">
                    Checking
                  </MenuItem>
                  <MenuItem key="Savings" value="Savings">
                    Savings
                  </MenuItem>
                  <MenuItem key="MoneyMarket" value="MoneyMarket">
                    Money Market
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Invoice Information
                </Typography>
                <DropDown
                    id="termCode"
                    className="textfield"
                    options={this.state.dropdowns.termCodeDropdown}
                    label="Payment Terms"
                    showWithDescription
                    passUp={this.dropDownChange}
                    default={''}
                />
                <DropDown
                    id="invCurrency"
                    className="textfield"
                    options={this.state.dropdowns.invCurrencyDropdown}
                    label="Invoice Currency"
                    passUp={this.dropDownChange}
                    default={''}
                />
                <DropDown
                    id="languageCode"
                    className="textfield"
                    options={this.state.dropdowns.languageCodeDropdown}
                    label="Language"
                    passUp={this.dropDownChange}
                    default={''}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                    id="comment"
                    label="Describe your service/products"
                    fullWidth
                    multiline
                    // required
                    rows="2"
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  Account Creation
                </Typography>
                {this.normalTextField(
                    'preparerName',
                    "Preparer's Name",
                    null,
                    true,
                    false
                )}
                <TextField
                    inputRef={null}
                    id="preparerEmailAddress"
                    className="textfield"
                    label="Preparer's Email"
                    required
                    fullWidth
                    type="email"
                    variant="filled"
                    margin="dense"
                    value={this.state.preparerEmail}
                    onChange={(e) => {
                      this.setState({
                        preparerEmail: e.target.value.toLowerCase(),
                      });
                    }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                                title="This will be the email you use to login"
                                placement="right"
                            >
                              <span className="tooltipQuestion">?</span>
                            </Tooltip>
                          </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                />
                <TextField
                    id="preparerEmailAddressCopy"
                    className="textfield"
                    label="Repeat Email"
                    required
                    fullWidth
                    type="email"
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                    }}
                    value={this.state.preparerEmailCopy}
                    onChange={(e) =>
                        this.setState({
                          preparerEmailCopy: e.target.value.toLowerCase(),
                        })
                    }
                    error={this.state.errors.emailAddress !== ''}
                    helperText={this.state.errors.emailAddress}
                    autoComplete="off"
                />
                <TextField
                    inputRef={null}
                    id="password"
                    autoComplete="off"
                    className="textfield"
                    label="Create Password"
                    required
                    fullWidth
                    type="password"
                    variant="filled"
                    margin="dense"
                    InputProps={{
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                                title="Must be at least 6 characters long"
                                placement="right"
                            >
                              <span className="tooltipQuestion">?</span>
                            </Tooltip>
                          </InputAdornment>
                      ),
                    }}
                    error={this.state.errors.passwordLength !== ''}
                    helperText={this.state.errors.passwordLength}
                />
                {this.errorTextField(
                    'confirmPassword',
                    'Verify Password',
                    this.state.errors.password,
                    null,
                    'password',
                    true,
                    false
                )}
              </Grid>
            </Grid>
            <div style={{ position: 'absolute', left: '-10px' }}>
              <Checkbox
                  checked={this.state.termsAndConditions}
                  onChange={this.handleCheck('termsAndConditions')}
                  value="termsAndConditions"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
              />
              <a
                  href={this.props.fetchInitialData.credentials.clientInfo.terms_and_conditions}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: '10px' }}
              >
                Agree to{' '}
                {this.props.fetchInitialData.credentials.clientInfo.company_name}{' '}
                Terms and Conditions
              </a>
            </div>
            <div className="buttonGroup">
              <div>
                <Button
                    type="submit"
                    disabled={readyToSubmit}
                    style={readyToSubmitStyle}
                    variant="contained"
                >
                  {submitting}
                  {load}
                </Button>
              </div>
            </div>
          </form>
          <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.emailTaken}
              autoHideDuration={10000}
          >
            <SnackbarContent
                variant="body2"
                style={{
                  backgroundColor: 'rgb(205,41,41)',
                  color: 'rgb(250,233,233)',
                }}
                message={emailTaken}
                action={[
                  <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() =>
                          this.setState({
                            emailAlreadyTaken: null,
                            emailTaken: false,
                          })
                      }
                  >
                    <CloseIcon/>
                  </IconButton>,
                ]}
            />
          </Snackbar>
        </div>
    );
  }
}

VendorForm1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(VendorForm1));
