export const userMaintenanceColumns = [
  // {
  //   name: '#',
  //   label: '#',
  //   options: {
  //     filter: false,
  //     sort: true,
  //   },
  // },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Username',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'roles',
    label: 'Roles',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'lastLogin',
    label: 'Last Login',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];


export const vendorUserMaintenanceColumnsErp = [
  // {
  //   name: '#',
  //   label: '#',
  //   options: {
  //     filter: false,
  //     sort: true,
  //   },
  // },
  {
    name: 'vendor',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'erp',
    label: 'ERP Number',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Username',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'roles',
    label: 'Roles',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'lastLogin',
    label: 'Last Login',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];


export const vendorUserMaintenanceColumnsVl = [
  // {
  //   name: '#',
  //   label: '#',
  //   options: {
  //     filter: false,
  //     sort: true,
  //   },
  // },
  {
    name: 'vendor',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'vlId',
    label: 'VL ID',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Username',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'roles',
    label: 'Roles',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'lastLogin',
    label: 'Last Login',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];