export const inputs = {
  meta: [
    {
      id: 'vendorName',
      label: 'Vendor Name',
      required: 'y',
    },
    {
      id: 'legalName',
      label: 'Legal Name',
      required: 'y',
    },
    {
      id: 'vendorSName',
      label: 'SearchName',
      required: 'y',
    },
    {
      id: 'taxId',
      label: 'EIN / SSN',
      required: 'y',
    },
    {
      id: 'urlAddr',
      label: 'Website',
      required: 'y',
    },
  ],
  mainAddress: [
    {
      id: 'addr1',
      label: 'Address 1',
      required: 'y',
    },
    {
      id: 'addr2',
      label: 'Address 2',
      required: 'y',
    },
    {
      id: 'cityAddr5',
      label: 'City',
      required: 'y',
    },
    // {
    //   id: "stateProv",
    //   label: "State",
    //   required: "y",
    //   half: true
    // },
    // {
    //   id: "postalCode",
    //   label: "Zip",
    //   required: "y",
    //   half: true
    // }
    // {
    //   id: 'phoneNum',
    //   label: 'Main Phone',
    //   required: 'y'
    // },
    // {
    //   id: 'faxNum',
    //   label: 'Main Fax',
    //   required: 'n'
    // }
  ],
  vendorRemit: [
    {
      id: 'remitAddr1',
      label: 'Address 1',
      required: 'n',
    },
    {
      id: 'remitAddr2',
      label: 'Address 2',
      required: 'n',
    },
    {
      id: 'remitCityAddr5',
      label: 'City',
      required: 'n',
    },
    // {
    //   id: "remitStateProv",
    //   label: "State",
    //   required: "n",
    //   half: true
    // },
    // {
    //   id: "remitPostalCode",
    //   label: "Zip",
    //   required: "n",
    //   half: true
    // }
  ],
  contact1: [
    {
      id: 'vendorContctc1',
      label: 'Name',
      required: 'y',
    },
    {
      id: 'emailc1',
      label: 'Email',
      required: 'y',
      type: 'email',
    },
  ],
  // {
  //   id: 'phonec1',
  //   label: 'Phone',
  //   required: 'y'
  // },
  // {
  //   id: 'mobilec1',
  //   label: 'Mobile',
  //   required: 'y'
  // },
  contact1Cont: [
    {
      id: 'jobTitlec1',
      label: 'Job Title',
      required: 'n',
    },
  ],
  contact2: [
    {
      id: 'vendorContctc2',
      label: 'Name',
      required: 'n',
    },
    {
      id: 'emailc2',
      label: 'Email',
      required: 'n',
      type: 'email',
    },
  ],
  // {
  //   id: 'phonec2',
  //   label: 'Phone',
  //   required: 'n'
  // },
  // {
  //   id: 'mobilec2',
  //   label: 'Mobile',
  //   required: 'n'
  // },
};

export const fieldIds2 = {
  vendorName: '',
  legalName: '',
  vendorSName: '',
  taxId: '',
  urlAddr: '',

  addr1: '',
  addr2: '',
  cityAddr5: '',
  phoneNum: '',
  faxNum: '',

  remitAddr1: '',
  remitAddr2: '',
  remitCityAddr5: '',

  vendorContctc1: '',
  emailc1: '',
  phonec1: '',
  mobilec1: '',
  faxc1: '',
  jobTitlec1: '',

  vendorContctc2: '',
  emailc2: '',
  phonec2: '',
  mobilec2: '',
  faxc2: '',
  jobTitlec2: '',

  bankEntity: '',
  vbankAcctNo: '',
  vbankAcctNoRepeat: '',

  comment: '',

  termCode: '',
  invCurrency: '',
  languageCode: '',
  clientId: '',
  taxClass: '',
};
