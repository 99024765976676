import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardActions, CardContent, CircularProgress, Grid, TextField, withStyles, } from '@material-ui/core';
import writeToActivity from '../functions/WriteToActivity';
import LoadingButton from '../components/LoadingButton';
import AttributeSettings from '../components/AttributeSettings';
import SettingsList from '../components/SettingsList';
import FieldChangeActivity from '../functions/FieldChangeActivity';
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  paymentForm: {
    margin: '0 auto',
    padding: '2rem',
    maxWidth: '1300px',
    '@media (max-width: 700px)': {
      padding: '0.5rem',
    },
  },
});

class AdminContractSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingContent: true,
      whitelist: [],
      notification_types: [],
      document_types: [],
      contract_types: [],
      classification1: [],
      newDoc: '',
      newContract: '',
      newNotification: '',
      newClassification1: '',
      newWhiteList: '',
      isLoading: '',
      isSaving: false,
      isSavingGeneral: false,
      isDeleting: false,
      classification1Header: '',
      editType: null,
      editId: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/contract-settings`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: 'contract'
        }),
      },
    ];

    Promise.all(
      requests.map((request) => {
        return fetch(request.url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
          },
          body: request.body,
        })
          .then((resp) => resp.json())
          .catch(() => {
            this.props.fetchInitialData.createSnack(
              'There was an error fetch the data',
              'error',
              3000
            );
          });
      })
    ).then((data) => {
      const settingData = data[0];
      this.setState({
        isLoadingContent: false,
        whitelist: settingData.data.whiteList,
        notification_types: settingData.data.notificationType,
        contract_types: settingData.data.contractType,
        document_types: settingData.data.documentType,
        classification1: settingData.data.classification1,
        classification1Header:
          settingData.data.classificationType1.length > 0
            ? settingData.data.classificationType1[0]
            : '',
        editType: null,
        editId: null,
      });
    });
  };

  updateGeneral = (event) => {
    event.preventDefault();
    this.setState({ isSavingGeneral: true });
    let fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: 'classification1Header',
      value: event.target.classification1Type.value,
    };

    fetch(`${process.env.REACT_APP_API}/update-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
        } else if (info.success) {
          this.props.fetchInitialData.createSnack(
            'Successfully updated',
            'success',
            3000
          );

          this.setState({
            isSavingGeneral: false,
            classification1Header: fetchData.value,
          });
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error',
            'error',
            3000
          );
          this.setState({ isSavingGeneral: false });
        }
      });
  };

  addNew = (callback, type, value,required) => {
    let fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      value: value,
      required: required,
      parentType: 'contract'
    };

    fetch(`${process.env.REACT_APP_API}/update-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.e) {
          if (info.e.sqlState === '45000') {
            callback(402);
          } else {
            callback(400);
          }
        } else if (info.success) {

          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: 'SETTINGS',
            OBJ_ID: `${type} settings`,
            ACTIVITY_TYPE: 'CREATE',
            ACTIVITY_STRING: `Created ${type} type: ${value}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };

          writeToActivity({ activity }, () => {
            this.getData();
            callback(200);
          });
        } else {
          callback(400);
        }
      });
  };

  edit = (callback, type, id, value,required) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      id: id,
      value: value,
      action: 'edit',
      required: required,
      parentType: 'contract'
    }
          fetch(`${process.env.REACT_APP_API}/edit-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.e) {
          if (info.e.sqlState === '45000') {
            callback(402);
          } else {
            callback(400);
          }
        } else if (info.success) {
            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                .client_id,
              OBJ_TYPE: 'SETTINGS',
              OBJ_ID: `${type} settings`,
              ACTIVITY_TYPE: 'UPDATE',
              ACTIVITY_STRING: `Updated ${type} settings `,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
              field_changes: info.data.changes.field_changes
            };

            FieldChangeActivity({ activity }, () => {
              this.getData();
              callback(200);
            });

        } else {
          callback(400);
        }
      });
  };

  handleDelete = (callback, type, id) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      id: id,
      action: 'delete',
    };

    fetch(`${process.env.REACT_APP_API}/edit-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
        } else if (info.success) {
          if (info.data.changes.error_flag === 1) {
            this.props.fetchInitialData.createSnack(
              info.data.changes.error_message,
              'error',
              3000
            );
            callback(402);
          } else if (info.data.changes.error_flag === 0) {
            this.getData();
            callback(200);
          }
        } else {
          callback(400);
        }
      });
  };
  renderSettings = (type, id, lookup, title, list,access,hasRequired) => {
    return (
      <SettingsList
        type={type}
        idPropertyName={id}
        lookupProperty={lookup}
        addNew={this.addNew}
        edit={this.edit}
        delete={this.handleDelete}
        title={title}
        list={list}
        access={access}
        hasRequired={hasRequired}
                              fetchInitialData={this.props.fetchInitialData}

      />
    );
  };
  checkAccess = () => {
    const { access } = this.props;
    return {
      view: access.settings_contract_view,
      edit: access.settings_contract_edit,
      add: access.settings_contract_add,
      delete: access.settings_contract_delete,
    };
  };
  render() {
    const { classes } = this.props;
    if (this.state.isLoadingContent) {
      return (
        <LoadingCircle/>
      );
    }
    const access = this.checkAccess();
    const sortedNotificationTypes = this.state.notification_types
      .slice()
      .sort((a, b) => {
        if (b.description > a.description) return -1;
        else return 1;
      })
      .map((a) => Object.assign(a, { edit: false }));

    const sortedDocumentTypes = this.state.document_types
      .slice()
      .sort((a, b) => {
        if (b.description > a.description) return -1;
        else return 1;
      });

    const sortedContractTypes = this.state.contract_types
      .slice()
      .sort((a, b) => {
        if (b.description > a.description) return -1;
        else return 1;
      });

    const sortedClassification1 = this.state.classification1
      .slice()
      .sort((a, b) => {
        if (b.classification_value > a.classification_value) return -1;
        else return 1;
      });

    const classification1Name = this.state.classification1Header
      ? this.state.classification1Header.classification_type_name
      : '';

    return (
      <Box>
        <Box className={classes.paymentForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <form onSubmit={this.updateGeneral}>
                  <CardContent>
                    <h5 style={{ margin: '1rem 0', textAlign: 'center' }}>
                      Contract Settings
                    </h5>
                    <TextField
                      style={{ minWidth: 300 }}
                      disabled={!access.edit}
                      id="classification1Type"
                      label="Contract Classification"
                      defaultValue={
                        this.state.classification1Header
                          .classification_type_name
                      }
                      autoComplete="off"
                    />
                  </CardContent>
                  <CardActions style={{ margin: '0 0 .5rem .5rem' }}>
                    {!!access.edit && (
                      <LoadingButton
                        label="Save"
                        isLoading={this.state.isSavingGeneral}
                        color="primaryVLButton"
                        buttonType="submit"
                      />
                    )}
                  </CardActions>
                </form>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              {this.renderSettings(
                'contract',
                'contract_type_id',
                'description',
                'Contract Type',
                sortedContractTypes,
                access
              )}
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              {this.renderSettings(
                'document',
                'document_type_id',
                'description',
                'Document Types',
                sortedDocumentTypes,
                access,
                true
              )}
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              {this.renderSettings(
                'notification',
                'id',
                'description',
                'Task Types',
                sortedNotificationTypes,
                access
              )}
            </Grid>

            {classification1Name && (
              <Grid item xs={12} sm={6} lg={4}>
                {this.renderSettings(
                  'classification1',
                  'classification_id',
                  'classification_value',
                  `${classification1Name} Types`,
                  sortedClassification1,
                  access
                )}
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={4}>
              {this.renderSettings(
                'whitelist',
                'url_id',
                'url',
                'White List',
                this.state.whitelist,
                access
              )}
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <AttributeSettings
                fetchInitialData={this.props.fetchInitialData}
                jsonWebTok={
                  this.props.fetchInitialData.credentials.user.jsonWebTok
                }
                access={access}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

AdminContractSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminContractSettings);
