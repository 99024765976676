export const attributeColumns = [
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'header',
    label: 'Attribute Header',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'dataType',
    label: 'Data Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'Actions',
    label: 'Actions',
    options: {
      filter: false,
      sort: true,
    },
  },
];
