export const attributeColumns = [
  {
    name: "attribute",
    label: "Attribute",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "value",
    label: "Value",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
    },
  }
];
