import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, Grid, InputAdornment,
  InputLabel, MenuItem, Select, TextField, withStyles
} from '@material-ui/core';

import LoadingButton from './LoadingButton';
import { attributeColumns } from "../utils/constants/attributeColumns";
import ConfirmationDialog from "./common/ConfirmationDialog";
import {responsive} from '../utils/constants/muidatatablesConsts';


const styles = () => ({
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 20,
    marginTop: -12,
    marginLeft: -12,
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, 0.9)',
    },
  },
  editLink: {
    color: 'rgba(0,0,255, 0.8)',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(0,0,255, 1)',
      textDecoration: 'underline',
    }
  },
});

class AttributeSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      tableData: [],
      isAddingNew: false,
      editAttribute: null,
      isSaving: false,
      isDeleting: false,
      currentAttributeType: '',
      currentDataType: '',
      showConfirmation: false,
      showEditWindow: false,
    };
  }

  handleClose = () => {
    this.setState({
      isAddingNew: false, editAttribute: null, showConfirmation: false, showEditWindow: false
    });
  };

  componentDidMount() {
    this.getData();
  }

  makeTableData = (data) => {
    const { classes } = this.props;
    return data.map((a) => {
      return [
        a.attribute_type.toUpperCase(),
        a.attribute_header,
        a.data_type,
        <span
            className={classes.editLink}
            onClick={() => {
              this.setState({
                editAttribute: a,
                currentAttributeType: a.attribute_type,
                currentDataType: a.data_type,
                showEditWindow: true,
              });
            }}
        >
         {this.props.access.edit ? 'edit' : 'view'}
        </span>,
      ];
    });
  };

  getData = () => {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/attribute-settings`,
        body: JSON.stringify({
          jsonWebTok: this.props.jsonWebTok,
        }),
      },
    ];

    Promise.all(
        requests.map((request) => {
          return fetch(request.url, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization: `bearer ${this.props.jsonWebTok}`,
            },
            body: request.body,
          })
              .then((resp) => resp.json())
              .catch(() => {
                this.props.fetchInitialData.createSnack(
                    'There was an error fetching the attribute data',
                    'error',
                    3000
                );
              });
        })
    )
        .then((data) => {
          let attributeData = data[0];
          let tableData = [];
          let attributes = [];
          if (attributeData.success) {
            attributes = attributeData.data.attributes;
            tableData = this.makeTableData(attributeData.data.attributes);
          }
          this.setState({ tableData, attributes });
        })
        .catch((error) => console.log(error));
  };

  addAttribute = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    const { currentAttributeType, currentDataType } = this.state;
    if (!currentAttributeType || !currentDataType) return null;
    let newAttrib = {
      client_id: '',
      attribute_type: this.state.currentAttributeType,
      attribute_header: event.target['currentAttributeHeader'].value,
      data_type: this.state.currentDataType,
      required_flag: 0,
    };

    let fetchData = {
      action: 'new',
      jsonWebTok: this.props.jsonWebTok,
      newAttrib,
    };

    fetch(`${process.env.REACT_APP_API}/attribute-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          let tableData = [];
          let attributes = [];
          if (data.success) {
            attributes = data.data.attributes;
            tableData = this.makeTableData(data.data.attributes);
          }
          this.props.fetchInitialData.createSnack(
              'Successfully added attribute',
              'success',
              3000
          );

          this.setState({
            tableData,
            attributes,
            isSaving: false,
            isAddingNew: false,
          });
        });
  };

  editAttribute = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    let updatedAttrib = Object.assign({}, this.state.editAttribute, {
      attribute_type: this.state.currentAttributeType,
      attribute_header: event.target['currentAttributeHeader'].value,
      data_type: this.state.currentDataType,
    });
    let fetchData = {
      action: 'update',
      jsonWebTok: this.props.jsonWebTok,
      updatedAttrib,
    };

    fetch(`${process.env.REACT_APP_API}/attribute-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          let tableData = [];
          let attributes = [];
          if (data.success) {
            attributes = data.data.attributes;
            tableData = this.makeTableData(data.data.attributes);
            this.props.fetchInitialData.createSnack(
                'Successfully updated attribute',
                'success',
                3000
            );
          }

          this.setState({
            tableData,
            attributes,
            isSaving: false,
            editAttribute: null,
          });
        });
  };

  handleDelete = (id) => {
    this.setState({ isDeleting: true });
    let fetchData = {
      action: 'delete',
      jsonWebTok: this.props.jsonWebTok,
      id,
    };

    fetch(`${process.env.REACT_APP_API}/attribute-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.props.fetchInitialData.createSnack(data.error, 'error', 3500);
            this.setState({
              isDeleting: false,
            });
          } else if (data.success) {
            let tableData = [];
            let attributes = [];
            if (data.success) {
              attributes = data.data.attributes;
              tableData = this.makeTableData(data.data.attributes);
              this.props.fetchInitialData.createSnack(
                  'Successfully deleted attribute',
                  'success',
                  3000
              );
            }

            this.setState({
              tableData,
              attributes,
              isSaving: false,
              isDeleting: false,
              editAttribute: null,
              isAddingNew: false,
            });
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3000
            );
            this.setState({
              isDeleting: false,
            });
          }
        });
  };

  render() {
    const { classes } = this.props;
    return (
        <div>
          <Dialog
              open={this.state.isAddingNew}
              onClose={this.handleClose}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={(event) => this.addAttribute(event)}>
              <DialogTitle id="alert-dialog-title">New Attribute</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl variant="filled" margin="dense" fullWidth>
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          labelWidth={100}
                          id="currentAttributeType"
                          value={this.state.currentAttributeType}
                          onChange={(e) => {
                            this.setState({ currentAttributeType: e.target.value });
                          }}
                      >
                        <MenuItem value="vendor">VENDOR</MenuItem>
                        <MenuItem value="document">DOCUMENT</MenuItem>
                        <MenuItem value="contract">CONTRACT</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        label="Attribute Header"
                        fullWidth
                        required
                        margin="dense"
                        variant="filled"
                        id="currentAttributeHeader"
                        InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                <span/>
                              </InputAdornment>
                          ),
                        }}
                        autoComplete="off"
                    />
                    <FormControl variant="filled" margin="dense" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Data Type
                      </InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          labelWidth={100}
                          id="currentDataType"
                          value={this.state.currentDataType}
                          onChange={(e) => {
                            this.setState({ currentDataType: e.target.value });
                          }}
                      >
                        <MenuItem value="user">USER</MenuItem>
                        <MenuItem value="number">NUMBER</MenuItem>
                        <MenuItem value="text">TEXT</MenuItem>
                        <MenuItem value="date">DATE</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Cancel</Button>
                <LoadingButton
                    label="Save"
                    isLoading={this.state.isSaving}
                    color="primaryVLButton"
                    buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>

          {this.state.editAttribute && (
              <Dialog
                  open={this.state.showEditWindow}
                  onClose={this.handleClose}
                  fullWidth
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={(event) => this.editAttribute(event)}>
                  <DialogTitle id="alert-dialog-title">Edit Attribute</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl variant="filled" margin="dense" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Type
                          </InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              labelWidth={100}
                              id="currentAttributeType"
                              value={this.state.currentAttributeType}
                              onChange={(e) => {
                                this.setState({
                                  currentAttributeType: e.target.value,
                                });
                              }}
                          >
                            <MenuItem value="vendor">VENDOR</MenuItem>
                            <MenuItem value="document">DOCUMENT</MenuItem>
                            <MenuItem value="contract">CONTRACT</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="Attribute Header"
                            fullWidth
                            required
                            margin="dense"
                            variant="filled"
                            defaultValue={this.state.editAttribute.attribute_header}
                            id="currentAttributeHeader"
                            InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <span/>
                                  </InputAdornment>
                              ),
                            }}
                            autoComplete="off"
                        />
                        <FormControl variant="filled" margin="dense" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Data Type
                          </InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              labelWidth={100}
                              id="currentDataType"
                              value={this.state.currentDataType}
                              onChange={(e) => {
                                this.setState({ currentDataType: e.target.value });
                              }}
                          >
                            <MenuItem value="user">USER</MenuItem>
                            <MenuItem value="number">NUMBER</MenuItem>
                            <MenuItem value="text">TEXT</MenuItem>
                            <MenuItem value="date">DATE</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
{!!this.props.access.delete && (
                    <LoadingButton
                        label="Delete"
                        isLoading={this.state.isDeleting}
                        fontColor="red"
                        variant="text"
                        fn={() => {
                          this.setState({ showConfirmation: true, isAddingNew: false, showEditWindow: false })
                        }}
                    />
)}
{!!this.props.access.edit && (

                    <LoadingButton
                        label="Save"
                        isLoading={this.state.isSaving}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
)}
                  </DialogActions>
                </form>
              </Dialog>
          )}

          <ConfirmationDialog
                              handleClose={this.handleClose}
                              handleOpen={this.state.showConfirmation}
                              dialogTitle={"Are you sure you want to delete this attribute?"}
                              isDeleting={this.state.isDeleting}
                              handleDelete={() => {
                                this.setState({ showConfirmation: false })
                                this.handleDelete(this.state.editAttribute.attribute_id)
                              }}/>
          <MUIDataTable
              title="Attribute Settings"
              data={this.state.tableData}
              columns={attributeColumns}
              options={{
                selectableRows: false,
                filterType: 'dropdown',
                responsive,
                print: false,
              }}
          />
          {!!this.props.access.add && (
          <Button
              onClick={() =>
                  this.setState({
                    isAddingNew: true,
                    currentAttributeType: '',
                    currentDataType: '',
                  })
              }
              style={{ marginTop: '1rem' }}
              className="primaryVLButton"
          >
            Add Attribute
          </Button>
          )}
        </div>
    );
  }
}

export default withStyles(styles)(AttributeSettings);
