import React from "react"
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: '40px',
  },
  resize: {
    fontSize: 13
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#E86613',
    },
  },
}

class InputTextFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { classes, input } = this.props
    if (input.required === 'y') {
      if (input.half) {
        return (
          <span key={input.id} style={{ marginRight: '5px' }}>
            <TextField
              required
              style={{ width: '120px' }}
              className="textfield"
              id={input.id}
              label={input.label}
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.root
                }
              }}
              type={input.type ? input.type : ''}
            />
          </span>
        )
      }
      else {
        return (
          <TextField
            required
            className="textfield"
            key={input.id}
            id={input.id}
            label={input.label}
            fullWidth 
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.root
              }
            }}
            type={input.type ? input.type : ''}

          />
        )
      }
    }
    else {
      if (input.half) {
        return (
          <span key={input.id} style={{ marginRight: '5px' }}>
            <TextField
              //required
              style={{ width: '120px' }}
              className="textfield"
              id={input.id}
              label={input.label}
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.root
                }
              }}
              type={input.type ? input.type : ''}

            />
          </span>
        )
      }
      else {
        return (
          <TextField
            className="textfield"
            key={input.id}
            id={input.id}
            label={input.label}
            fullWidth
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.root
              }
            }}
            type={input.type ? input.type : ''}

          />
        )
      }
    }
  }
}

InputTextFields.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InputTextFields);
