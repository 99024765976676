import React, { useState, useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Button,
  Slide
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./Approver.css";
import Approver from "../containers/Approver";
import AllVendors from "../containers/AllVendors";
import ClientCreateVendor from "../components/ClientCreateVendor";
import VerifyAccess from "../functions/VerifyAccess";
import { AntTabs, AntTab, MyBadge } from "../components/common/Tabs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllandPendingVendors = ({ ...props }) => {
  const [access, setAccess] = useState({});
  const [tabVal, setTabVal] = useState(0);
  const [newVendor, setNewVendor] = useState(false);

  useEffect(() => {
    VerifyAccess(
      (data) => {
        setAccess(data.access);
      },
      [
        "vendor_view",
        "vendor_approve",
        "vendor_add",
        "vendor_view_rejected",
        "vendor_view_unapproved",
      ],
      props.fetchInitialData.credentials.user.jsonWebTok
    );
    viewHash();
    props.fetchInitialData.getNotificationCount(
      props.fetchInitialData.credentials.user.clientId,
      props.fetchInitialData.credentials.user.jsonWebTok
    );
  }, []);

  const viewHash = () => {
    setTabVal(window.location.hash === "#pending" ? 1 : 0);
  };

  const handleTabChange = (event, newValue) => {
    setTabVal(newValue);
    window.location.hash = newValue === 0 ? "all" : "pending";
  };

  const vendorFormLink =
    props.fetchInitialData.credentials.vendorFormNotifications > 0 ? (
      <MyBadge
        bgcolor={props.fetchInitialData.credentials.primaryAppColor}
        badgeContent={
          props.fetchInitialData.credentials.vendorFormNotifications
        }
      >
        Pending Vendors
      </MyBadge>
    ) : (
      "Pending Vendors"
    );

  return (
    <div>
      {newVendor && (
        <Dialog
          fullScreen
          open={newVendor}
          onClose={() => setNewVendor(true)}
          TransitionComponent={Transition}
        >
          <AppBar style={{ backgroundColor: "#545454", position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setNewVendor(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Create New Vendor</Typography>
            </Toolbar>
          </AppBar>
          <ClientCreateVendor
            props={props}
            fetchInitialData={props.fetchInitialData}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <AntTabs
          color={props.fetchInitialData.credentials.primaryAppColor}
          value={tabVal}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="ant example"
        >
          {!!access.vendor_view && (
            <AntTab
              color={props.fetchInitialData.credentials.primaryAppColor}
              label="All Vendors"
              value={0}
            />
          )}
          {!!access.vendor_approve && (
            <AntTab
              color={props.fetchInitialData.credentials.primaryAppColor}
              label={vendorFormLink}
              value={1}
            />
          )}
        </AntTabs>
        <div
          style={{ position: "relative", height: "36px", marginTop: "1rem" }}
        >
          {!!access.vendor_add && (
            <div
              style={{ position: "relative", float: "right", height: "30px" }}
            >
              <Button
                className="primaryVLButton"
                onClick={() => setNewVendor(true)}
              >
                Create New Vendor
              </Button>
            </div>
          )}
        </div>
        {tabVal === 0 && (
          <AllVendors
            history={props.history}
            fetchInitialData={props.fetchInitialData}
          />
        )}
        {tabVal === 1 && (
          <Approver
            history={props.history}
            fetchInitialData={props.fetchInitialData}
          />
        )}
      </Grid>
    </div>
  );
};

export default AllandPendingVendors;
