import {tableCellOverflow, tableCellNoWrap} from "../tableCellOverflow";

export const vendorsColumns = [
	{
		name: 'id',
		label: 'VL ID',
		options: {
			filter: false,
			sort: true,
		},
		width: 40,
	},
	{
		name: 'vendor',
		label: 'Vendor Name',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'city',
		label: 'City',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'state',
		label: 'State',
		options: {
			filter: true,
			sort: true,
		},
		width: 40,
	},
	{
		name: 'submissionDate',
		label: 'Submission Date',
		options: {
			filter: false,
			sort: true,
		},
		width: 90,
		wordBreak: 'normal',
	},
	{
		name: 'attachmentCount',
		label: 'Attachment Count',
		options: {
			filter: true,
			sort: true,
		},
		width: 85,
		wordBreak: 'normal',
	},
	{
		name: 'status',
		label: 'Status',
		options: {
			filter: true,
			sort: true,
			filterList: ['Approved'],
		},
		width: 75,
	},
];

export const approverVendorColumns = [
	{
		name: 'id',
		label: 'VL ID',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'vendor',
		label: 'Vendor',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'city',
		label: 'City',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'state',
		label: 'State',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'submissionDate',
		label: 'Submission Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'attachmentCount',
		label: 'Attachment Count',
		options: {
			filter: true,
			sort: true,
		},
		wordBreak: 'normal',
	},
	{
		name: 'approvalLevel',
		label: 'Approval Status Level',
		options: {
			filter: true,
			sort: true,
		},
		wordBreak: 'normal',
	},
	{
		name: 'status',
		label: 'Status',
		options: {
			filter: true,
			sort: true,
		},
	},
];

export const vendorContractsUpdatedColumns = [
	{
		name: 'contractNumber',
		label: 'Contract Number',
		options: {
      customBodyRender: tableCellNoWrap,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'title',
		label: 'Title',
		options: {
      customBodyRender: tableCellOverflow,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'startDate',
		label: 'Start Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'endDate',
		label: 'End Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'daysLeft',
		label: 'Days Left',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'contractAmount',
		label: 'Contract Amount',
		options: {
			filter: true,
			sort: true,
		},
	},
];

export const vendorContractsUpdatedColumnsWithClassification = [
	{
		name: 'contractNumber',
		label: 'Contract Number',
		options: {
      customBodyRender: tableCellNoWrap,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'title',
		label: 'Title',
		options: {
      customBodyRender: tableCellOverflow,
			filter: true,
			sort: true,
		},
	},
	{
		name: 'source',
		label: 'classification',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'startDate',
		label: 'Start Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'endDate',
		label: 'End Date',
		options: {
			filter: false,
			sort: true,
		},
	},
	{
		name: 'daysLeft',
		label: 'Days Left',
		options: {
			filter: true,
			sort: true,
		},
	},
	{
		name: 'contractAmount',
		label: 'Contract Amount',
		options: {
			filter: true,
			sort: true,
		},
	},
];
