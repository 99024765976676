import React from "react";
import "./Approver.css";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import InvoiceDistHome from "../containers/InvoiceDistHome";
import InvoiceApprovalHome from "../containers/InvoiceApprovalHome";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClientCreateInvoice from "../components/ClientCreateInvoice";
import Button from "@material-ui/core/Button";
import LoadingCircle from "../components/common/LoadingCircle"
import { AntTabs, AntTab, MyBadge } from "../components/common/Tabs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = () => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#E86613",
  },

  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    "&:hover": {
      color: "#E86613",
      opacity: 1,
    },
    "&:focus": {
      color: "#E86613",
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  badge: {
    backgroundColor: "#E86613",
  },
});

const hashes = [
  { hash: "#all", value: 0 },
  { hash: "#pending", value: 1 },
  { hash: "#dist", value: 2 },
];

class ClientInvoiceMod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorTabValue: 0,
      newInvoice: false,
      loading: false,
      content: null,
      unapprovedInvoices: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.viewHash();
    this.props.fetchInitialData.getNotificationCount(
      this.props.fetchInitialData.credentials.user.clientId,
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
  }

  getData = () => {
    this.setState({ loading: true });
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      all: true,
    });
    fetch(`${process.env.REACT_APP_API}/get-ready-approval-invoices`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonVendor,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.success) {
          const data = info.data;
          this.setState({
            content: data.invoices,
            unapprovedInvoices: data.invoices.filter(
              (invoice) => invoice.INVOICE_STATUS !== 2
            ),
          });
        }
        this.setState({ loading: false });
      });
  };

  viewHash = () => {
    this.setState({
      vendorTabValue: window.location.hash
        ? hashes.find((el) => el.hash === window.location.hash).value
        : 0,
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ vendorTabValue: newValue }, () => {
      window.location.hash = hashes.find((el) => el.value === newValue).hash;
    });
  };

  checkDuplicateIds = (currentClientId, currentVendorId, currentRefNum) =>
    this.state.content.some(
      (invoice) =>
        invoice.CLIENT_ID === currentClientId &&
        invoice.VENDOR_ID === currentVendorId &&
        invoice.REFERENCE_NUM === currentRefNum
    );

  render() {
    const { classes } = this.props;

    const InvoiceCount =
      this.props.fetchInitialData.credentials.vendorInvoiceNotifications > 0 ? (
        <MyBadge
          bgcolor={this.props.fetchInitialData.credentials.primaryAppColor}
          badgeContent={
            this.props.fetchInitialData.credentials.vendorInvoiceNotifications
          }
        >
          Pending Invoices
          <span style={{ width: "5px" }} />
        </MyBadge>
      ) : (
        "Pending Invoices"
      );

    const InvoiceDistCount =
      this.props.fetchInitialData.credentials.vendorCodeNotifications > 0 ? (
        <MyBadge
          bgcolor={this.props.fetchInitialData.credentials.primaryAppColor}
          badgeContent={
            this.props.fetchInitialData.credentials.vendorCodeNotifications
          }
        >
          Invoice Distribution
          <span style={{ width: "5px" }} />
        </MyBadge>
      ) : (
        "Invoice Distribution"
      );
    if (this.state.loading) {
      return (
        <LoadingCircle/>
      );
    }
    return (
      <div>
        {this.state.newInvoice && (
          <Dialog
            fullScreen
            open={this.state.newInvoice}
            TransitionComponent={Transition}
          >
            <AppBar
              style={{ backgroundColor: "#545454", position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.setState({ newInvoice: false })}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">Create New Invoice</Typography>
              </Toolbar>
            </AppBar>

            <ClientCreateInvoice
              checkDuplicateIds={this.checkDuplicateIds}
              history={this.props.history}
              closeDialog={() =>
                this.setState({ newInvoice: false, tabValue: 0 })
              }
              fetchInitialData={this.props.fetchInitialData}
            />
          </Dialog>
        )}

        <Grid item xs={12} sm={12} md={12}>
          <AntTabs
            color={this.props.fetchInitialData.credentials.primaryAppColor}
            value={this.state.vendorTabValue}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {[
              { label: "All Invoices", value: 0 },
              { label: InvoiceCount, value: 1 },
              { label: InvoiceDistCount, value: 2 },
            ].map((el) => (
              <AntTab
                key={el.value}
                color={this.props.fetchInitialData.credentials.primaryAppColor}
                label={el.label}
                value={el.value}
              />
            ))}
          </AntTabs>
          <div
            style={{ position: "relative", height: "36px", marginTop: "1rem" }}
          >
            <div
              style={{ position: "relative", float: "right", height: "30px" }}
            >
              <Button
                className="primaryVLButton"
                onClick={() => this.setState({ newInvoice: true })}
              >
                Create Invoice
              </Button>
            </div>
          </div>

          {this.state.content && this.state.vendorTabValue === 0 && (
            <InvoiceApprovalHome
              history={this.props.history}
              onlySave
              fetchInitialData={this.props.fetchInitialData}
              content={this.state.content}
            />
          )}
          {this.state.unapprovedInvoices && this.state.vendorTabValue === 1 && (
            <InvoiceApprovalHome
              history={this.props.history}
              fetchInitialData={this.props.fetchInitialData}
              content={this.state.unapprovedInvoices}
            />
          )}
          {this.state.vendorTabValue === 2 && (
            <InvoiceDistHome
              history={this.props.history}
              fetchInitialData={this.props.fetchInitialData}
            />
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ClientInvoiceMod);
