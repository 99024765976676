import React, { Component } from 'react';
import './Home.css';
import clsx from 'clsx';
import {
  Typography,
  Button,
  Fade,
  CircularProgress,
  TextField,
  InputAdornment,
  withStyles,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      failedLogin: false,
    };
  }

  componentDidMount() {
    if (this.props.fetchInitialData.credentials.user.uuid) {
      if (this.props.fetchInitialData.credentials.user.vendorId) {
        this.props.history.push('/home');
      } else {
        this.props.history.push('/vendor');
      }
    }
  }

  callSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      failedLogin: false,
    });
    const loginInfo = {
      username: event.target.loginemail.value,
      password: event.target.loginpassword.value,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };

    const jsonLogin = JSON.stringify(loginInfo);
    fetch(`${process.env.REACT_APP_API}/sign-in-with-jwt`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonLogin,
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.callSnack(data.error, 'error', 5000);
            return null;
          }
          if (data.success) {
            this.props.fetchInitialData.auth(data.data);
            return data.data;
          } else {
            this.callSnack('Something went wrong', 'error', 4000);
          }
        })
        .then((success) => {
          this.setState({ isLoading: false });
          if (success) {

            if(this.props.history.location.state) {
              this.props.history.push(this.props.history.location.state.path)
              return null
            }
            else if (success.info.vendor_id) {
              this.props.history.push('/vendor');
              return null;
            } else {
              this.props.history.push('/home');
              return null;
            }
          }
        })
        .catch((e) => {
          this.callSnack(`There was an error: ${e}`, 'error', 4000);
        });
  };

  render() {
    if (!this.props.fetchInitialData.credentials.user.uuid) {
      const { classes } = this.props;
      const failedLogin = this.state.failedLogin
          ? {
            fontSize: '0.7rem',
            marginTop: '10px',
            lineHeight: '15px',
            color: 'red',
            textAlign: 'center',
          }
          : { display: 'none' };
      return (
          <>
            <div className="homeCard">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ width: 'auto', disply: 'inline-block' }}>
                    <img
                        alt={'client logo'}
                        className="center"
                        src={this.props.fetchInitialData.credentials.clientInfo.logo_img}
                        style={{ height: 'auto', width: '25%' }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="columnBox">
                    <div>
                      <Typography variant="h5">Sign Up</Typography>
                    </div>
                    <hr/>
                    <div style={{ marginTop: '3rem' }}>
                      <Typography variant="h5">New Vendor?</Typography>
                    </div>
                    <div className={classes.buttonLabel}>
                      <Link to="/vendorform">
                        <Button
                            type="submit"

                            className={clsx(classes.loginButton, 'primaryVLButton')}
                            variant="contained"
                        >
                          Start
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="columnBox">
                    <div>
                      <Typography variant="h5">Sign In</Typography>
                    </div>
                    <hr/>
                    <form onSubmit={this.handleSubmit}>
                      <div>
                        <TextField
                            className="loginInput"
                            id="loginemail"
                            label="Email"
                            required
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <AccountCircle/>
                                  </InputAdornment>
                              ),
                              classes: { notchedOutline: classes.outline },
                            }}
                            autoComplete="off"
                        />
                      </div>
                      <div>
                        <TextField
                            className="loginInput"
                            id="loginpassword"
                            required
                            fullWidth
                            type="password"
                            label="Password"
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <Lock/>
                                  </InputAdornment>
                              ),
                              classes: { notchedOutline: classes.outline },
                            }}
                            autoComplete="off"
                        />
                      </div>
                      <div className={classes.noOutline}>
                        <Button
                            type="submit"
                            className={clsx(classes.loginButton, 'primaryVLButton')}
                            variant="contained"
                        >
                          {!this.state.isLoading && 'Log In'}
                          <Fade
                              style={{ position: 'relative', left: '10px' }}
                              in={this.state.isLoading}
                              unmountOnExit
                          >
                            <CircularProgress
                                style={{ color: 'white' }}
                                variant="indeterminate"
                                disableShrink
                                size={30}
                                thickness={4}
                            />
                          </Fade>
                        </Button>
                        <Typography style={failedLogin} variant="body2">
                          Failed to login, Username or password is incorrect
                        </Typography>
                      </div>
                      <div>
                        <Link
                            style={{ fontSize: '0.75rem', color: 'black' }}
                            to="/forgotpassword"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
      );
    } else {
      return (
          <div className="homeCard">
            <Grid container spacing={2}>
              <h3>Welcome Back!</h3>
            </Grid>
          </div>
      );
    }
  }
}

const styles = {
  outline: {
    borderRadius: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
  loginButton: {
    height: 50,
    width: '100%',
    borderRadius: '100px !important',
    marginTop: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  buttonLabel: {
    '& > a': {
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
  },

  noOutline: {
    '&:focus': {
      outline: 'none',
    },
  },
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withRouter(withStyles(styles)(Home)));
