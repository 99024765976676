import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './Admin.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIDataTable from 'mui-datatables';
import LoadingButton from '../components/LoadingButton';
import {responsive} from '../utils/constants/muidatatablesConsts';

class AdminAccountingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isNewEntry: false,
      currentEntry: null,
      tableData: null,
      expanded: null,
      confirmDelete: false,
      isLoading: false,
      segments: [],
      accountingLevels: [],
      accountingCategories: [],
      action: null,
      isOpenCateogries: false,
      currentCategoryEntry: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_API}/get-or-update-account-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'get' }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            const segments = JSON.parse(info.data.segments.segments);
            this.setState({
              segments,
              accountingCategories: info.data.accountingCategories,
              accountingLevels: info.data.accountingLevels,
            });
          }
        })
        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
        });
  };

  makeTableData(unfilteredRows, type) {
    if (type === 'categories') {
      return unfilteredRows.map((option) => {
        let row = [
          option.value,
          option.description,
          option.account,
          option.subaccount,
          option.active_flag === 1 ? 'Active' : 'Inactive',
        ];
        row.push(
            <p
                onClick={() => {
                  this.setState({
                    isOpenCateogries: true,
                    action: 'edit',
                    currentCategoryEntry: option,
                  });
                }}
                className="editLink"
            >
              edit
            </p>
        );
        return row
      })
    } else {
      return unfilteredRows.map((option) => {
        let row = [
          option.value,
          option.description,
          option.active_flag === 1 ? 'Active' : 'Inactive',
        ];

        row.push(
            <p
                onClick={() => {
                  this.setState({
                    isOpen: true,
                    action: 'edit',
                    currentEntry: option,
                  });
                }}
                className="editLink"
            >
              edit
            </p>
        );
        return row;
      });
    }
  }

  handlePanelChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  newEntry = (index) => {
    this.setState({
      isOpen: true,
      action: 'add',
      currentEntry: { level: index },
    });
  };
  handleNew = (event) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    const newAccountingLevel = Object.assign({}, this.state.currentEntry, {
      value: event.target.value.value,
      description: event.target.description.value,
      active_flag: event.target.isActive.checked ? 1 : 0,
    });

    fetch(`${process.env.REACT_APP_API}/get-or-update-account-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'insert', newAccountingLevel }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully added new value',
                'success',
                3500
            );
            this.setState({ isLoading: false, isOpen: false, currentEntry: null });

            this.getData();
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
            this.setState({ isLoading: false });
          }
        });
  };
  handleEdit = (event) => {
    this.setState({ isLoading: true });
    const editAccountingLevel = Object.assign({}, this.state.currentEntry, {
      value: event.target.value.value,
      description: event.target.description.value,
      active_flag: event.target.isActive.checked ? 1 : 0,
    });
    fetch(`${process.env.REACT_APP_API}/get-or-update-account-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'update', editAccountingLevel }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully edited new value',
                'success',
                3500
            );
            this.setState({ isLoading: false, isOpen: false, currentEntry: null, action: null });

            this.getData();
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
            this.setState({ isLoading: false });
          }
        });
  }
  handleNewCategory = (event) => {
    event.preventDefault()
    const newAccountingCategory = {
      value: event.target.value.value,
      description: event.target.description.value,
      account: event.target.account.value,
      subaccount: event.target.subaccount.value,
      active_flag: event.target.isActive.checked ? 1 : 0,
    }

    fetch(`${process.env.REACT_APP_API}/get-or-update-account-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'insertCategory', newAccountingCategory }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully edited new value',
                'success',
                3500
            );
            this.setState({ isLoading: false, isOpenCateogries: false, currentCategoryEntry: null, action: null });

            this.getData();
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
            this.setState({ isLoading: false });
          }
        });
  }
  handleEditCategory = (event) => {
    event.preventDefault()
    this.setState({ isLoading: true });
    const editCategory = Object.assign({}, this.state.currentCategoryEntry, {
      value: event.target.value.value,
      description: event.target.description.value,
      account: event.target.account.value,
      subaccount: event.target.subaccount.value,
      active_flag: event.target.isActive.checked ? 1 : 0,
    });
    fetch(`${process.env.REACT_APP_API}/get-or-update-account-settings`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ action: 'updateCategory', editCategory }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (info.success) {
            this.props.fetchInitialData.createSnack(
                'Successfully edited new value',
                'success',
                3500
            );
            this.setState({ isLoading: false, isOpenCateogries: false, currentCategoryEntry: null, action: null });

            this.getData();
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
            this.setState({ isLoading: false });
          }
        });
  }

  render() {

    return (
        <div>
          {this.state.isOpen && (
              <Dialog
                  open={this.state.isOpen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.state.action === 'add' ? this.handleNew : this.handleEdit}>
                  <DialogTitle id="alert-dialog-title">{`${this.state.action === 'add' ? 'Add New' : 'Edit'} ${
                      this.state.currentEntry.level === 10 ? 'Custom Filter' : this.state.segments[this.state.currentEntry.level]
                  }`}</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id="value"
                            label="Value"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentEntry.value}
                            autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentEntry.description}
                            autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Checkbox
                            style={{ color: this.props.fetchInitialData.credentials.primaryAppColor}}
                            id="isActive"
                            label="Active"
                            defaultChecked={this.state.action === 'add' ? true : this.state.currentEntry.active_flag === 1}
                        />
                        <span style={{ fontSize: '0.8rem' }}>Active</span>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                        onClick={() => {
                          this.setState({
                            isOpen: false,
                            currentEntry: null,
                          });
                        }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                        label="Submit"
                        isLoading={this.state.isLoading}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Dialog>
          )}


          {this.state.isOpenCateogries && (
              <Dialog
                  open={this.state.isOpenCateogries}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                <form onSubmit={this.state.action === 'add' ? this.handleNewCategory : this.handleEditCategory}>
                  <DialogTitle
                      id="alert-dialog-title">{this.state.action === 'add' ? 'Add Category' : 'Edit Category'}</DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-start" spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="value"
                            label="Name"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentCategoryEntry.value}
                            autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>

                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentCategoryEntry.description}
                            autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id="account"
                            label="Account"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentCategoryEntry.account}
                            autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>

                        <TextField
                            id="subaccount"
                            label="Sub-Account"
                            fullWidth
                            defaultValue={this.state.action === 'add' ? '' : this.state.currentCategoryEntry.subaccount}
                            autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Checkbox
                            style={{ color: this.props.fetchInitialData.credentials.primaryAppColor}}
                            id="isActive"
                            label="Active"
                            defaultChecked={this.state.action === 'add' ? true : this.state.currentCategoryEntry.active_flag === 1}
                        />
                        <span style={{ fontSize: '0.8rem' }}>Active</span>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                        onClick={() => {
                          this.setState({
                            isOpenCateogries: false,
                            currentCategoryEntry: null,
                          });
                        }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                        label="Submit"
                        isLoading={this.state.isLoading}
                        color="primaryVLButton"
                        buttonType="submit"
                    />
                  </DialogActions>
                </form>
              </Dialog>
          )}


          <div className="rejectContainer">
            <h3 style={{ textAlign: 'center' }}>Accounting Levels</h3>

            {this.state.segments.map((val, index) => {
              const data = this.state.accountingLevels.filter(
                  (account) => account.level === index
              );
              const tableData = this.makeTableData(data);
              return (
                  <div key={index} className="makePanel">
                    <Accordion
                        expanded={this.state.expanded === index}
                        onChange={this.handlePanelChange(index)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant="body2">{`${index + 1} - ${val} (${tableData.length})`}</Typography>
                      </AccordionSummary>
                      <div style={{ padding: '1rem 2rem' }}>
                        <MUIDataTable
                            columns={levelCols}
                            data={tableData}
                            options={{
                              selectableRows: false,
                              filterType: 'dropdown',
                              responsive,
                              print: false,
                            }}
                        />
                        <Button
                            style={{
                              marginTop: '1rem',
                            }}
                            className="primaryVLButton"
                            onClick={() => this.newEntry(index)}
                        >
                          {`+ Add to ${val}`}
                        </Button>
                      </div>
                    </Accordion>
                  </div>
              );
            })}
            <div className="makePanel">
              <Accordion
                  expanded={this.state.expanded === 10}
                  onChange={this.handlePanelChange(10)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                  <Typography variant="body2">Custom Filters</Typography>
                </AccordionSummary>
                <div style={{ padding: '1rem 2rem' }}>
                  <MUIDataTable
                      columns={levelCols}
                      data={this.makeTableData(this.state.accountingLevels.filter(
                          (account) => account.level === 10
                      ))}
                      options={{
                        selectableRows: false,
                        filterType: 'dropdown',
                        responsive,
                        print: false,
                      }}
                  />
                  <Button
                      style={{
                        marginTop: '1rem',
                      }}
                      className="primaryVLButton"
                      onClick={() => this.newEntry(10)}
                  >
                    {`+ Add Custom Filter`}
                  </Button>
                </div>
              </Accordion>
            </div>
          </div>

          <h3 style={{ textAlign: 'center' }}>Accounting Categories</h3>
          <div className="makePanel" style={{ marginTop: '1rem', marginBottom: '3rem' }}>

            <MUIDataTable
                columns={categoryCols}
                data={this.makeTableData(this.state.accountingCategories, 'categories')}
                options={{
                  selectableRows: false,
                  filterType: 'dropdown',
                  responsive,
                  print: false,
                }}
            />

            <Button
                style={{
                  marginTop: '1rem',
                }}
                className="primaryVLButton"
                onClick={() => this.setState({ action: 'add', isOpenCateogries: true, currentCategoryEntry: {} })}
            >
              {`Add Category`}

            </Button>
          </div>

        </div>
    );
  }
}

export default AdminAccountingSettings;

const levelCols = [
  {
    name: 'val',
    label: 'Value',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      filterList: ['Active'],
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];

const categoryCols = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'account',
    label: 'Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'subAccount',
    label: 'Sub-Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      filterList: ['Active'],
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
];
