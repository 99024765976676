import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, Slide, Fade, CircularProgress, InputAdornment, TextField, Table, TableBody, TableRow,
  TableHead, TableCell, Button, Fab, Paper, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import uuidv1 from 'uuid/v1';

import './PurchasingRequest.css';
import SuggestionSql from '../components/SuggestionSql';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import { formatter } from "../utils/tableCustoms";
import LoadingButton from '../components/LoadingButton';

class PurchasingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
      currentEdit: null,
      accountCategory: '',
      locations: [],
      location: '',
      hasInactiveLocation: false,
      vendor: '',
      vendorVname: '',
      filterValue: '',
      deliveryDate: '',
      total: '',
      currentQty: 0,
      currentCost: 0,
      currentEntryExtCost: 0,
      currentUom: '',
      rows: [],
      uploadFiles: [],
      isLoading: false,
      newVendorModalOpen: false,
      isLoadingNewVendor: false,
      dropdowns: null,
      loadingLocations: false,
      vendorInput: '',
      accountingLevels: null,
      isLoadingLevels: false,
      contracts: null,
    };
    this.submitPO = this.submitPO.bind(this);
    this.addRow = this.addRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.createVendor = this.createVendor.bind(this);
    this.inputFileChange = this.inputFileChange.bind(this);
  }

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  getDropdownData = () => {
    fetch(`${process.env.REACT_APP_API}/get-po-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((data) => {
          this.setState({ dropdowns: data.data });
        });
  };

  getAccountingLevels = () => {
    this.setState({ isLoadingLevels: true });
    fetch(`${process.env.REACT_APP_API}/get-accounting-dropdowns`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
        .then((resp) => resp.json())
        .then((info) => {
          let dropdowns = info.data.accountDropdowns.map((drop) => {
            return JSON.parse(drop.client_accounting_levels)[0];
          });
          this.setState({ accountingLevels: dropdowns });
          this.setState({ isLoadingLevels: false });
        });
  };

  getContracts = (vendorId) => {
    fetch(`${process.env.REACT_APP_API}/get-contracts`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        vendorId,
      }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          const data = info.data
          if (info.success) {
            const activeContracts = data.contracts.filter(
                (contract) => parseInt(contract.status) === 1
            );
            this.setState({contracts: activeContracts})
          }
        });

  }

  componentDidMount() {
    this.getDropdownData();
    this.getAccountingLevels();
  }

  addRow(event) {
    event.preventDefault();
    const e = event.target;
    const extAmt = this.state.currentEntryExtCost;
    const num = this.state.rows.length + 1;
    if (
        moment(e.deliveryDate.value).isBefore(moment(new Date()).add(-1, 'days'))
    ) {
      this.props.fetchInitialData.createSnack(
          `Date cannot be in the past`,
          'error',
          4000
      );
      return;
    } else if (
        moment(e.deliveryDate.value).isAfter(
            moment(new Date()).year(moment(new Date()).year() + 100)
        )
    ) {
      this.props.fetchInitialData.createSnack(`Invalid date`, 'error', 4000);
      return;
    }
    const newAccountingLevels = this.state.accountingLevels.reduce(
        (acc, curr, index) => {
          let res = this.state[`currentSEG${index + 1}`]
              ? this.state[`currentSEG${index + 1}`]
              : this.state[`SEG${index + 1}`];
          acc[`SEG${index + 1}`] = res || null;
          return acc;
        },
        {}
    );
    const newRow = {
      num: num,
      itemNum: e.itemNum.value,
      description: e.description.value,
      qty: e.qty.value,
      uom: this.state.currentUom,
      cost: e.cost.value,
      extCost: extAmt,
      deliveryDate: e.deliveryDate.value,
      shipTo: e.shipTo.value,
      ...Object.assign({}, newAccountingLevels),
      accountCategory: this.state.accountCategory,
      edit: (
          <span
              className="editLink"
              onClick={() => {
                const temp = this.state.accountingLevels.reduce(
                    (acc, curr, index) => {
                      acc[`SEG${index + 1}`] = newRow[`SEG${index + 1}`];
                      return acc;
                    },
                    {}
                );
                this.setState({
                  isEdit: true,
                  currentEdit: {
                    num: num,
                    itemNum: e.itemNum.value,
                    description: e.description.value,
                    qty: e.qty.value,
                    uom: newRow.uom,
                    cost: e.cost.value,
                    extCost: extAmt,
                    deliveryDate: e.deliveryDate.value,
                    shipTo: e.shipTo.value,
                    ...Object.assign({}, temp),
                    accountCategory: newRow.accountCategory,
                  },
                  currentCost: e.cost.value,
                  currentQty: e.qty.value,
                  currentEntryExtCost: extAmt,
                });
              }}
          >
          edit
        </span>
      ),
    };

    const updatedRows = this.state.rows.map((line) => {
      return Object.assign({}, line);
    });
    updatedRows.push(newRow);
    this.setState({
      isEdit: false,
      rows: updatedRows,
      currentCost: 0,
      currentQty: 0,
      isNewEntryOpen: false,
      currentEntryExtCost: 0,
      currentUom: '',
      accountCategory: '',
    });
  }

  editRow(event) {
    event.preventDefault();
    const e = event.target;
    const num = this.state.currentEdit.num;
    let extAmt = this.state.currentEntryExtCost;
    if (
        moment(e.deliveryDate.value).isBefore(moment(new Date()).add(-1, 'days'))
    ) {
      this.props.fetchInitialData.createSnack(
          `Date cannot be in the past`,
          'error',
          4000
      );
      return;
    } else if (
        moment(this.state.deliveryDate).isAfter(
            moment(new Date()).year(moment(new Date()).year() + 100)
        )
    ) {
      this.props.fetchInitialData.createSnack('Invalid date', 'error', 4000);
      return;
    }

    const editedRow = {
      ...this.state.currentEdit,
      itemNum: e.itemNum.value,
      description: e.description.value,
      qty: e.qty.value,
      cost: e.cost.value,
      extCost: extAmt,
      deliveryDate: e.deliveryDate.value,
      shipTo: e.shipTo.value,
      accountCategory: this.state.accountCategory,
      edit: (
          <span
              className="editLink"
              onClick={() => {
                const temp = this.state.accountingLevels.reduce(
                    (acc, curr, index) => {
                      acc[`SEG${index + 1}`] = editedRow[`SEG${index + 1}`];
                      return acc;
                    },
                    {}
                );
                this.setState({
                  isEdit: true,
                  currentEdit: {
                    num: num,
                    itemNum: e.itemNum.value,
                    description: e.description.value,
                    qty: e.qty.value,
                    uom: editedRow.uom,
                    cost: e.cost.value,
                    extCost: extAmt,
                    deliveryDate: e.deliveryDate.value,
                    shipTo: e.shipTo.value,
                    ...Object.assign({}, temp),
                    accountCategory: editedRow.accountCategory,
                  },
                  currentCost: e.cost.value,
                  currentQty: e.qty.value,
                  currentEntryExtCost: extAmt,
                });
              }}
          >
          edit
        </span>
      ),
    };

    const updatedRows = this.state.rows.map((row, index) => {
      if (index === this.state.currentEdit.num - 1) {
        return editedRow;
      } else {
        return Object.assign({}, row);
      }
    });
    this.setState({
      isEdit: false,
      rows: updatedRows,
      currentEdit: null,
      currentCost: '',
      currentQty: '',
      currentEntryExtCost: 0,
      contractId: '',
    });
  }

  handleClose() {
    this.setState({
      isNewEntryOpen: false,
      isEdit: false,
      confirmOpen: false,
      newVendorModalOpen: false,
      currentEntryExtCost: 0,
    });
  }

  handleDelete() {
    const updatedRows = this.state.rows.filter(
        (row) => row.num !== this.state.currentEdit.num
    );
    this.setState({ rows: updatedRows, isEdit: false });
  }

  submitPO() {
    this.setState({ isLoading: true });
    const poId = uuidv1();

    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
      poId: poId,
      locationId: this.state.location ? this.state.location.NAME : null,
      contractId: this.state.contractId,
      deliverBy: this.state.deliveryDate,
      filterValue: this.state.filterValue,
      jsonArray: this.state.rows,
      vendorId: this.state.vendor,
      vendorVname: this.state.vendor.split(' (')[0],
      url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/po-vendor-details/${poId}`,
    };

    this.state.accountingLevels.forEach((level, index) => {
      fetchData[`SEG${index + 1}`] = this.state[`SEG${index + 1}`]
          ? this.state[`SEG${index + 1}`]
          : '';
    });

    const stringData = JSON.stringify(fetchData);

    fetch(`${process.env.REACT_APP_API}/create-po`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: stringData,
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.props.fetchInitialData.createSnack(data.error, 'error', 3500);
            this.setState({
              isLoading: false,
            });
            return null;
          }
          if (data.success) {
            this.props.fetchInitialData.createSnack(
                `You were assigned PO id: ${data.data.poId}`,
                'success',
                4000
            );
            let poId = data.poId;
            for (let i = 0; i < this.state.uploadFiles.length; i++) {
              this.uploadFile(poId, this.state.uploadFiles[i]);
            }

            this.setState({ isLoading: false });
            this.props.fetchInitialData.createSnack(
                'Successfully created purchase request',
                'success',
                3500
            );

            this.props.fetchInitialData.getNotificationCount(
                this.props.fetchInitialData.credentials.user.clientId,
                this.props.fetchInitialData.credentials.user.jsonWebTok
            );
            this.setState({
              currentQty: 0,
              currentCost: 0,
              rows: [],
              confirmOpen: false,
            });
            this.props.history.push('/purchaserequests#all');
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );

            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          window.alert('There was an error');
        });
  }

  createVendor(event) {
    event.preventDefault();
    this.setState({ isLoadingNewVendor: true });
    let form = event.target;
    const fetchData = {
      vendorId: uuidv1(),
      addressId: uuidv1(),
      clientId: this.props.fetchInitialData.credentials.user.clientId,
      vendorVname: form.vendorVname.value,
      addr1: form.addr1.value,
      addr2: form.addr2.value,
      cityAddr5: form.cityAddr5.value,
      stateProv: form.stateProv.value,
      postalCode: form.postalCode.value,
      filterValue: form.filterValue.value,
      email: form.email.value,
      submissionDate: moment().format('LLL'),
      userId: this.props.fetchInitialData.credentials.user.uuid,
    };
    fetch(`${process.env.REACT_APP_API}/createnewvendor`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(fetchData),
    })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 200) {
            this.setState(
                {
                  newVendorModalOpen: false,
                  isLoadingNewVendor: true,
                },
                () => {
                  this.submitPO();
                }
            );
          } else {
            this.setState({
              isLoadingNewVendor: true,
            });
            window.alert('Something went wrong');
          }
        });
  }

  inputFileChange(e) {
    if (e.target.files[0] === undefined) {
      this.setState({
        uploadFiles: [],
      });
    } else {
      this.setState({ uploadFiles: [] });
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
            reader = new FileReader(),
            self = this;
        reader.onload = function (r) {
          const updatedUpload = self.state.uploadFiles.map((file) =>
              Object.assign({}, file)
          );
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size,
          });
          self.setState({
            uploadFiles: updatedUpload,
          });
        };
        reader.readAsDataURL(file);
        this.setState({ value: reader });
      }
    }
  }

  uploadFile(invoiceId, fileData) {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      window.alert(fileData.name + ' is too big to upload');
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: 'PURCHASE',
        obj_id: invoiceId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: this.props.fetchInitialData.credentials.user.uuid,
        activity: {
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
          OBJ_TYPE: 'PURCHASE',
          OBJ_ID: invoiceId,
          ACTIVITY_TYPE: 'UPLOAD',
          ACTIVITY_STRING:
              this.props.fetchInitialData.credentials.user.name +
              ' uploaded an attachment to purchase request',
          USER_ID: this.props.fetchInitialData.credentials.user.uuid,
        },
      };
      const stringData = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: stringData,
      })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.error) {
              this.props.fetchInitialData.createSnack(data.error, 'error', 4000);
            }
            return data;
          });
    }
  }

  checkPo = () => {
    if (!this.state.vendor) {
      this.props.fetchInitialData.createSnack(
          'You must select a vendor',
          'warning',
          3000
      );
    } else if (!this.state.location) {
      this.props.fetchInitialData.createSnack(
          'Need to add a location',
          'warning',
          3000
      );
    } else if (
        moment(this.state.deliveryDate).isBefore(
            moment(new Date()).add(-1, 'days')
        )
    ) {
      this.props.fetchInitialData.createSnack(
          'Date cannot be in the past',
          'warning',
          3000
      );
    } else if (
        moment(this.state.deliveryDate).isAfter(
            moment(new Date()).year(moment(new Date()).year() + 100)
        )
    ) {
      this.props.fetchInitialData.createSnack('Invalid  date', 'warning', 3000);
    } else {
      this.setState({ confirmOpen: true });
    }
  };

  getLocations(id) {
    this.setState({ loadingLocations: true });
    fetch(`${process.env.REACT_APP_API}/get-locations`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify({ vendorId: id }),
    })
        .then((resp) => resp.json())
        .then((info) => {
          if (!info.success) return null;
          const data = info.data;
          let locations = data.locations.map((l) => {
            return Object.assign(
                {},
                l,
                l.INACTIVE_FLAG === 1
                    ? { NAME: `${l.NAME} (Inactive)`, ONLY_NAME: l.NAME }
                    : { ONLY_NAME: l.NAME },
                l.PRIMARY_ADDR === 1
                    ?{ NAME: `${l.NAME} (Primary)`, ONLY_NAME: l.NAME }
                    : { ONLY_NAME: l.NAME },
            );
          });
          this.setState({ locations, location: null
            , loadingLocations: false
          });
        });
  }

  render() {
    const { classes } = this.props;
    let editItemNum,
        editDescription,
        editQty,
        editUom,
        editCost,
        editDeliveryDate,
        editAccountCategory,
        editShipTo = '';
    if (this.state.currentEdit !== null) {
      const ce = this.state.currentEdit;
      editItemNum = ce.itemNum;
      editDescription = ce.description;
      editQty = ce.qty;
      editUom = ce.uom;
      editCost = ce.cost;
      editDeliveryDate = ce.deliveryDate;
      editAccountCategory = ce.accountCategory;
      editShipTo = ce.shipTo;
    }
    let sum = this.state.rows.reduce((acc, val) => {
      return acc + val.extCost;
    }, 0);
    const load = this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <CircularProgress
              style={{ marginLeft: '10px', color: '#545454' }}
              size="20px"
          />
        </Fade>
    ) : (
        ''
    );
    const newVendorLoad = this.state.isLoadingNewVendor ? (
        <Fade in={this.state.isLoadingNewVendor} unmountOnExit>
          <CircularProgress
              style={{ marginLeft: '10px', color: '#545454' }}
              size="20px"
          />
        </Fade>
    ) : (
        ''
    );

    return (
        <div>
          <Dialog
              open={this.state.newVendorModalOpen}
              onClose={this.handleClose}
              fullWidth
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Create New Vendor</DialogTitle>
            <form onSubmit={this.createVendor}>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="vendorVname"
                        label="Vendor Name"
                        required
                        fullWidth
                        variant="filled"
                        defaultValue={this.state.vendor}
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="filterValue"
                        label={
                          this.props.fetchInitialData.credentials.clientInfo
                              .filter_value_label
                        }
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="addr1"
                        label="Address 1"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="addr2"
                        label="Address 2"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="cityAddr5"
                        label="City"
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <span style={{ marginLeft: '0.5rem' }}/>
                    <TextField
                        id="stateProv"
                        label="State"
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                    <TextField
                        id="postalCode"
                        label="Zip"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="email"
                        label="Vendor Email"
                        fullWidth
                        variant="filled"
                        margin="dense"
                        autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={this.handleClose}
                    style={{ margin: '0.3rem' }}
                >
                  Cancel
                </Button>
                <LoadingButton
                    buttonType="submit"
                    label="Submit"
                    style={{
                      margin: '0.3rem',
                    }}
                    isLoading={this.state.isLoadingNewVendor}
                    color="primaryVLButton"
                />
              </DialogActions>
            </form>
          </Dialog>

          <Dialog
              open={this.state.confirmOpen}
              onClose={this.handleClose}
              fullWidth
              maxWidth={'lg'}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Confirm Purchasing Request
            </DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                  <span style={{ display: 'inline-block', marginRight: 5 }}>
                    Vendor:{' '}
                  </span>
                    <span style={{ display: 'inline-block' }}>
                    {' '}{this.state.vendorVname}
                  </span>
                  </div>
                  <div>
                    <span>Location: </span>
                    <span>
                    {this.state.location && this.state.location.ONLY_NAME}
                  </span>
                  </div>
                  {this.state.accountingLevels &&
                  this.state.accountingLevels.map((el, index) => (
                      <div key={index}>
                        <span>{el.accounting_level_name}: </span>
                        <span>{this.state[`SEG${index + 1}`]}</span>
                      </div>
                  ))}
                  <div>
                  <span>
                    {this.props.fetchInitialData.credentials.clientInfo.filter_value_label}:{' '}
                  </span>
                    <span>{this.state.filterValue}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <span>Contract: </span>
                    <span>{this.state.contractId}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <span>Delivery Date: </span>
                    <span>{this.state.deliveryDate}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ overflowX: 'scroll', margin: '0 auto' }}>
                    <Table style={{ width: '100%' }}>
                      <TableHead>
                        <TableRow>
                          {['Items', 'Item #', 'Description', 'Qty', 'UOM', 'Cost', 'Ext Cost', 'Delivery Date', 'Ship To',].map((el, index) => (
                              <TableCell
                                  key={index}
                                  className={classes.tableCell}
                                  align="center"
                              >
                                {el}
                              </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rows.map((row) => (
                            <TableRow key={row.num}>
                              <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                  component="th"
                                  scope="row"
                              >
                                {row.num}
                              </TableCell>
                              {[
                                row.itemNum,
                                row.description,
                                row.qty,
                                row.uom,
                                formatter.format(row.cost),
                                formatter.format(row.extCost),
                                row.deliveryDate,
                                row.shipTo,
                              ].map((el, index) => (
                                  <TableCell
                                      key={index}
                                      className={classes.tableCell}
                                      align="center"
                                  >
                                    {el}
                                  </TableCell>
                              ))}
                            </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                              className={classes.tableCell}
                              colSpan={5}
                              align="center"
                          />
                          <TableCell
                              className={classes.tableCell}
                              colSpan={1}
                              align="center"
                          >
                            Total:
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {formatter.format(sum)}
                          </TableCell>
                          <TableCell
                              className={classes.tableCell}
                              colSpan={2}
                              align="center"
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                  onClick={this.handleClose}
                  style={{ margin: '0.3rem' }}
              >
                Cancel
              </Button>
         
              <LoadingButton
                    fn={this.submitPO}
                    label="Submit"
                    style={{
                      margin: '0.3rem',
                    }}
                    isLoading={this.state.isLoadingNewVendor}
                    color="primaryVLButton"
                />
            </DialogActions>
          </Dialog>

          <Dialog
              open={this.state.isEdit}
              fullWidth
              maxWidth={'lg'}
              onClose={this.handleClose}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.editRow}>
              <DialogTitle id="alert-dialog-title">Edit Request</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                        id="deliveryDate"
                        type="date"
                        label="Delivery Date"
                        defaultValue={editDeliveryDate}
                        error={
                          moment(this.state.deliveryDate).isBefore(
                              moment(new Date()).add(-1, 'days')
                          ) ||
                          moment(this.state.deliveryDate).isAfter(
                              moment(new Date()).year(moment(new Date()).year() + 100)
                          )
                        }
                        helperText={
                          (moment(this.state.deliveryDate).isBefore(
                              moment(new Date()).add(-1, 'days')
                          )
                              ? 'cannot be in the past'
                              : '') ||
                          (moment(this.state.deliveryDate).isAfter(
                              moment(new Date()).year(moment(new Date()).year() + 100)
                          )
                              ? 'invalid date'
                              : '')
                        }
                        InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                <div/>
                              </InputAdornment>
                          ),
                        }}
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="shipTo"
                        fullWidth
                        label="Ship To"
                        defaultValue={editShipTo}
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="itemNum"
                        label="Item Number"
                        defaultValue={editItemNum}
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="description"
                        fullWidth
                        label="Description"
                        defaultValue={editDescription}
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="qty"
                        label="Quantity"
                        defaultValue={editQty}
                        onChange={(event) =>
                            this.setState({
                              currentQty: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentCost,
                            })
                        }
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="uom"
                        label="Unit Of Measure"
                        defaultValue={editUom}
                        autoComplete="off"
                        select
                        className={classes.uomSelect}
                        onChange={(event) => {
                          const updatedEdit = {
                            ...this.state.currentEdit,
                            uom: event.target.value,
                          };
                          this.setState({
                            currentEdit: updatedEdit,
                          });
                        }}
                    >
                      {this.state.dropdowns &&
                      this.state.dropdowns.purchaseUomlist.map((uom, index) => (
                          <MenuItem key={index} value={uom.value}>
                            {uom.value}
                          </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="cost"
                        label="Cost"
                        InputProps={{ inputProps: { min: 0 } }}
                        defaultValue={editCost}
                        onChange={(event) =>
                            this.setState({
                              currentCost: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentQty,
                            })
                        }
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="extCost"
                        label="Extended Cost"
                        value={formatter.format(this.state.currentEntryExtCost)}
                        autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid
                    spacing={3}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                  {this.state.accountingLevels &&
                  this.state.accountingLevels.map((el, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <TextField
                            className={classes.accountLevels}
                            fullWidth
                            id={el.accounting_level_name.toString().toLowerCase()}
                            label={el.accounting_level_name.toString()}
                            autoComplete="off"
                            defaultValue={
                              this.state.currentEdit
                                  ? this.state.currentEdit[`SEG${index + 1}`]
                                  : ''
                            }
                            onChange={(event) => {
                              const updatedEdit = {
                                ...this.state.currentEdit,
                                [`SEG${index + 1}`]: event.target.value,
                              };
                              this.setState({
                                currentEdit: updatedEdit,
                              });
                            }}
                            select
                        >
                          {el.accounting_level_values &&
                          el.accounting_level_values.map((level, index) => (
                              <MenuItem key={index} value={level.value}>
                                {level.description} ({level.value})
                              </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                  ))}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        className={classes.accountLevels}
                        fullWidth
                        id={'account_category'}
                        label={'Account Category'}
                        defaultValue={editAccountCategory}
                        autoComplete="off"
                        select
                        onChange={(event) =>
                            this.setState({ accountCategory: event.target.value })
                        }
                    >
                      {this.state.dropdowns &&
                      this.state.dropdowns.purchaseAccountcategory.map(
                          (category, index) => (
                              <MenuItem key={index} value={category.value}>
                                {category.description} ({category.value})
                              </MenuItem>
                          )
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={this.handleClose}
                    style={{ margin: '0.3rem' }}
                    variant="text"
                    color="primary"
                >
                  Cancel
                </Button>
                <Button
                    onClick={() =>
                        this.setState({ showConfirmation: true, isEdit: false })
                    }
                    style={{
                      margin: '0.3rem',
                    }}
                    color="secondary"
                    variant="text"
                >
                  Delete
                </Button>
                <Button
                    type="submit"
                    style={{
                      margin: '0.3rem',
                    }}
                    className="primaryVLButton"
                    variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
              open={this.state.isNewEntryOpen}
              onClose={this.handleClose}
              fullWidth={true}
              maxWidth={'lg'}
              TransitionComponent={this.Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.addRow}>
              <DialogTitle id="alert-dialog-title">Add Request</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                        id="deliveryDate"
                        type="date"
                        label="Delivery Date"
                        defaultValue={this.state.deliveryDate}
                        InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                <div/>
                              </InputAdornment>
                          ),
                        }}
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="shipTo"
                        label="Ship To"
                        fullWidth
                        defaultValue={
                          this.state.location ? this.state.location.ONLY_NAME : ''
                        }
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="itemNum"
                        label="Item Number"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="description"
                        fullWidth
                        label="Description"
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="qty"
                        label="Quantity"
                        onChange={(event) =>
                            this.setState({
                              currentQty: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentCost,
                            })
                        }
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="uom"
                        label="Unit Of Measure"
                        autoComplete="off"
                        select
                        className={classes.uomSelect}
                        onChange={(event) =>
                            this.setState({
                              currentUom: event.target.value,
                            })
                        }
                    >
                      {this.state.dropdowns &&
                      this.state.dropdowns.purchaseUomlist.map((uom, index) => (
                          <MenuItem key={index} value={uom.value}>
                            {uom.value}
                          </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="cost"
                        label="Cost"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(event) =>
                            this.setState({
                              currentCost: event.target.value,
                              currentEntryExtCost:
                                  event.target.value * this.state.currentQty,
                            })
                        }
                        autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        id="extCost"
                        label="Extended Cost"
                        defaultValue={''}
                        value={formatter.format(this.state.currentEntryExtCost)}
                        autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', padding: '15px 0' }}>
                  <Grid spacing={3} container direction="row" justify="flex-start" alignItems="center">
                    {this.state.accountingLevels &&
                    this.state.accountingLevels.map((el, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <TextField
                              className={classes.accountLevels}
                              fullWidth
                              id={el.accounting_level_name.toString().toLowerCase()}
                              label={el.accounting_level_name.toString()}
                              defaultValue={this.state[`SEG${index + 1}`]}
                              onChange={(event) =>
                                  this.setState({
                                    [`currentSEG${index + 1}`]: event.target.value,
                                  })
                              }
                              autoComplete="off"
                              select
                          >
                            {el.accounting_level_values &&
                            el.accounting_level_values.map((level, index) => (
                                <MenuItem key={index} value={level.value}>
                                  {level.description} ({level.value})
                                </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                    ))}

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                          className={classes.accountLevels}
                          fullWidth
                          id={'account_category'}
                          label={'Account Category'}
                          autoComplete="off"
                          onChange={(event) =>
                              this.setState({ accountCategory: event.target.value })
                          }
                          select
                      >
                        {this.state.dropdowns &&
                        this.state.dropdowns.purchaseAccountcategory.map(
                            (category, index) => (
                                <MenuItem key={index} value={category.value}>
                                  {category.description} ({category.value})
                                </MenuItem>
                            )
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={this.handleClose}
                    style={{
                      margin: '0.3rem',
                    }}
                >
                  Cancel
                </Button>
                <Button
                    type="submit"
                    style={{
                      margin: '0.3rem',
                    }}
                    className="primaryVLButton"
                    variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <div className="purchasingForm">
            <h3>Purchasing Request Form</h3>
            <Grid
                container
                justify="flex-start"
                spacing={2}
                style={{ flexDirection: 'column' }}
            >
              <Grid item xs={12} sm={12} md={12} style={{ maxWidth: 390 }}>
                <div>
                <span
                    style={{
                      position: 'relative',
                      top: '10px',
                      paddingRight: '0.6rem',
                    }}
                >
                  Vendor:{' '}
                </span>
                  <span>
                  <SuggestionSql
                      id="vendor"
                      onEqualName={(vendor, vendorVname, vendorInput) => {
                        this.setState({ vendor, vendorVname, vendorInput });
                        this.getLocations(vendor);
                        this.getContracts(vendor);
                      }}
                      handleChange={(id, newVendorName, isSelected, hit) => {
                        this.setState({ vendorInput: newVendorName });
                        if (isSelected) {
                          this.getLocations(id);
                          this.getContracts(id);
                          this.setState({ vendor: id, vendorVname: hit });
                        } else {
                          this.setState({ vendor: null });
                        }
                      }}
                      jsonWebTok={
                        this.props.fetchInitialData.credentials.user.jsonWebTok
                      }
                      required
                      placeholder=""
                      label=""
                      clientId={
                        this.props.fetchInitialData.credentials.user.clientId
                      }
                      openNewVendor={() =>
                          this.setState({ newVendorModalOpen: true })
                      }
                  />
                </span>
                </div>
                <div>
                <span
                    style={{
                      position: 'relative',
                      top: '10px',
                      paddingRight: '0.6rem',
                    }}
                >
                  Location:{' '}
                </span>
                  <Autocomplete
                      style={{ display: 'inline-block', minWidth: 260 }}
                      value={this.state.location}
                      disabled={
                        this.state.locations.length < 1 || !this.state.vendorInput
                      }
                      onChange={(event, newValue) => {
                        this.setState({ location: newValue });
                      }}
                      id="location"
                      options={this.state.locations}
                      getOptionLabel={(option) =>
                          option.NAME ? option.NAME : 'Name not available'
                      }
                      renderInput={(params) => (
                          <TextField id="textLocations" {...params} margin="dense"/>
                      )}
                  />
                  {this.state.loadingLocations && (
                      <CircularProgress className={classes.spinner} size="20px"/>
                  )}
                </div>
                {this.state.accountingLevels ? (
                    this.state.accountingLevels.map((el, index) => (
                        <div key={index} className={classes.levelsDropdowns}>
                    <span
                        style={{
                          position: 'relative',
                          top: '10px',
                          paddingRight: '0.6rem',
                        }}
                    >
                      {el.accounting_level_name}:{' '}
                    </span>
                          <TextField
                              style={{ minWidth: 238 }}
                              id={el.accounting_level_name.toLowerCase()}
                              autoComplete="off"
                              select
                              onChange={(event) => {
                                this.setState({
                                  [`SEG${index + 1}`]: event.target.value,
                                });
                              }}
                          >
                            {el.accounting_level_values &&
                            el.accounting_level_values.map((level, index) => (
                                <MenuItem key={index} value={level.value}>
                                  {level.description} ({level.value})
                                </MenuItem>
                            ))}
                          </TextField>
                        </div>
                    ))
                ) : (
                    <CircularProgress
                        size="20px"
                        style={{
                          position: 'relative',
                          top: 10,
                          left: '30%',
                          color: '#545454',
                        }}
                    />
                )}
                <div style={{ marginTop: 7 }}>
                <span
                    style={{
                      position: 'relative',
                      top: '10px',
                      paddingRight: '0.6rem',
                    }}
                >
                  {
                    this.props.fetchInitialData.credentials.clientInfo
                        .filter_value_label
                  }
                  :{' '}
                </span>
                  <TextField
                      style={{ minWidth: 210 }}
                      id="filterValue"
                      autoComplete="off"
                      select
                      onChange={(event) =>
                          this.setState({ filterValue: event.target.value })
                      }
                  >
                    {this.state.dropdowns &&
                    this.state.dropdowns.purchaseFilterlist.map(
                        (filter, index) => (
                            <MenuItem key={index} value={filter.FILTER_VALUE}>
                              {filter.FILTER_VALUE}
                            </MenuItem>
                        )
                    )}
                  </TextField>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} style={{ padding: '2px 8px', maxWidth: 390 }}>
                <div>
                <span
                    style={{
                      position: 'relative',
                      top: '10px',
                      paddingRight: '0.6rem',
                    }}
                >
                  Contract:{' '}
                </span>
                  <TextField
                      style={{ minWidth: 240 }}
                      id="contractId"
                      disabled={
                        !this.state.contracts || !this.state.contracts.length || !this.state.vendorInput
                      }
                      getOptionLabel={(option) =>
                          option.NAME ? option.NAME : 'No available contracts'
                      }
                      onChange={(event) =>
                          this.setState({ contractId: event.target.value })
                      }
                      autoComplete="off"
                      select
                  >
                    {this.state.contracts && this.state.contracts.map((contract, index) => (
                        <MenuItem key={index} value={contract.contract_id}>{contract.contract_number}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} style={{ padding: '2px 8px', maxWidth: 390 }}>
                <div>
                <span
                    style={{
                      position: 'relative',
                      top: '10px',
                      paddingRight: '0.6rem',
                    }}
                >
                  Delivery Date:{' '}
                </span>
                  <TextField
                      style={{ minWidth: 230 }}
                      id="deliveryDate"
                      error={
                        moment(this.state.deliveryDate).isBefore(
                            moment(new Date()).add(-1, 'days')
                        ) ||
                        moment(this.state.deliveryDate).isAfter(
                            moment(new Date()).year(moment(new Date()).year() + 100)
                        )
                      }
                      helperText={
                        (moment(this.state.deliveryDate).isBefore(
                            moment(new Date()).add(-1, 'days')
                        )
                            ? 'cannot be in the past'
                            : '') ||
                        (moment(this.state.deliveryDate).isAfter(
                            moment(new Date()).year(moment(new Date()).year() + 100)
                        )
                            ? 'invalid date'
                            : '')
                      }
                      type="date"
                      onChange={(event) =>
                          this.setState({ deliveryDate: event.target.value })
                      }
                      autoComplete="off"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ maxWidth: 390 }}>
                <div>
                  <span>Total: </span>
                  <span style={{ fontSize: '1.6rem' }}>
                  <strong>{formatter.format(sum)}</strong>
                </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {[
                          'Items',
                          'Item #',
                          'Description',
                          'Qty',
                          'UOM',
                          'Cost',
                          'Ext Cost',
                          'Delivery Date',
                          'Ship To',
                        ].map((el, index) => (
                            <TableCell
                                key={index}
                                className={classes.tableCell}
                                align="center"
                            >
                              {el}
                            </TableCell>
                        ))}
                        {this.state.rows.length > 0 && (
                            <TableCell className={classes.tableCell} align="center">
                              Edit
                            </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row) => (
                          <TableRow key={row.num}>
                            <TableCell
                                className={classes.tableCell}
                                align="center"
                                component="th"
                                scope="row"
                            >
                              {row.num}
                            </TableCell>
                            {[
                              row.itemNum,
                              row.description,
                              row.qty,
                              row.uom,
                              formatter.format(row.cost),
                              formatter.format(row.extCost),
                              row.deliveryDate,
                              row.shipTo,
                              row.edit,
                            ].map((el, index) => (
                                <TableCell
                                    key={index}
                                    className={classes.tableCell}
                                    align="center"
                                >
                                  {el}
                                </TableCell>
                            ))}
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div style={{ marginLeft: '0.7rem' }}>
                    <Fab
                        onClick={() => this.setState({ isNewEntryOpen: true })}
                        size="small"
                        aria-label="Add"
                        className="primaryVLButton"
                        style={{margin: '0.5rem'}}
                    >
                      +
                    </Fab>
                  </div>
                </Paper>
              </Grid>
              <ConfirmationDialog
                  handleClose={() => this.setState({ showConfirmation: false })}
                  handleOpen={this.state.showConfirmation}
                  dialogTitle={'Are you sure you want to delete this line?'}
                  handleDelete={() => {
                    this.setState({ showConfirmation: false });
                    this.handleDelete();
                  }}
              />
              <Grid item xs={12} sm={12} md={12}>
                <input
                    type="file"
                    multiple="multiple"
                    onChange={this.inputFileChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                    onClick={() => this.checkPo()}
                    style={{
                      margin: '0.5rem 0',
                    }}
                    className="primaryVLButton"
                    variant="contained"
                >
                  Submit Purchase Request
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  tableCell: {
    padding: '10px 20px',
  },
  paddingNone: {
    padding: 'none',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  fab: {
    margin: theme.spacing(1),
    backgroundColor: 'rgb(232,102,19)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgb(220,92,10)',
      color: 'white',
    },
  },
  uomSelect: {
    '& label': {
      width: 'max-content',
    },
    '& > div': {
      '& > div': {
        paddingRight: '150px !important',
      },
    },
  },
  spinner: {
    marginLeft: '10px',
    color: '#545454',
    position: 'relative',
    right: 60,
    bottom: -15,
  },
  levelsDropdowns: {
    display: 'flex',
    maxWidth: 340,
    justifyContent: 'space-between',
  },
  accountLevels: {
    margin: '0 0.25rem'
  }
});

PurchasingRequest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PurchasingRequest);
