import React from 'react';
import './Admin.css';
import { TextField, withStyles, CircularProgress, Card, CardContent, CardActions, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import PropTypes from 'prop-types';
import LoadingButton from '../components/LoadingButton';
import UserMaintenance from '../components/UserMaintenance';
import LoadingCircle from "../components/common/LoadingCircle"

class AdminUserMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientInfo: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/get-or-update-client-info`,
        body: JSON.stringify({
          client_id: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
        }),
      },
    ];

    Promise.all(
        requests.map((request) => {
          return fetch(request.url, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
            },
            body: request.body,
          })
              .then((resp) => resp.json())
              .catch(() => {
                window.alert('There was an error fetch the data');
              });
        })
    )
        .then((info) => {
          const data = info[0];
          if (data.success) {
            const clientInfo = data.data.clientData[0];
            this.setState({ clientInfo, isSavingGenral: false });
          } else {
            this.props.fetchInitialData.createSnack(
                'There was an error',
                'error',
                3500
            );
          }
        })
        .catch((error) => {
          this.props.fetchInitialData.createSnack(
              `Error: ${error}`,
              'error',
              3000
          );
        });
  }

  updateGeneral = (event) => {
    event.preventDefault();
    this.setState({ isSavingGenral: true })
    const { client_id } = this.state.clientInfo;
    const fetchData = {
      client_id,
      max_login_attempts: event.target.maxLoginAttempts.value,
      login_time_interval: event.target.userLoginLockoutTime.value,
      updateLogin: true
    };

    fetch(`${process.env.REACT_APP_API}/get-or-update-client-info`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
        .then(resp => resp.json())
        .then(data => {
          if (data.error) {
            this.props.fetchInitialData.createSnack(data.error, 'error', 3500)
            this.setState({ isSavingGenral: false })
          } else if (data.success) {
            this.props.fetchInitialData.createSnack('Successfully updated login setting', 'success', 3500)
            this.getData()
          } else {
            this.props.fetchInitialData.createSnack('There was an error', 'error', 3500)
            this.setState({ isSavingGenral: false })
          }
        })
        .catch(err => console.log(err))
  };

  render() {
    if (!this.state.clientInfo) {
      return (
          <div style={{ height: '100vh', width: '100%' }}>
            <LoadingCircle/>
          </div>
      );
    }
    return (
        <div style={{width: '100%'}}>
          <div className="rejectContainer" style={{ marginBottom: 30}}>
            <Grid container spacing={3}>
              {this.props.tab === 3 && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card>
                      <form onSubmit={this.updateGeneral}>
                        <CardContent>
                          <h5 style={{ margin: '1rem 0', textAlign: 'center' }}>
                            Login Settings
                          </h5>
                          <div style={{ margin: '0.5rem' }}>
                            <TextField
                                style={{ minWidth: 200 }}
                                id="maxLoginAttempts"
                                type="number"
                                required
                                defaultValue={this.state.clientInfo.max_login_attempts}
                                label="Max User login Attempts"
                                autoComplete="off"
                            />
                          </div>
                          <div style={{ margin: '0.5rem' }}>
                            <TextField
                                style={{ minWidth: 200 }}
                                id="userLoginLockoutTime"
                                type="number"
                                required
                                defaultValue={this.state.clientInfo.login_time_interval}
                                label="Login Lockout Time"
                                helperText="value in minutes"
                                autoComplete="off"
                            />
                          </div>
                        </CardContent>
                        <CardActions style={{ margin: '0 0 .5rem .5rem' }}>
                          <LoadingButton
                              label="Save"
                              isLoading={this.state.isSavingGenral}
                              color="primaryVLButton"
                              buttonType="submit"
                          />
                        </CardActions>
                      </form>
                    </Card>
                  </Grid>
              )}
              {this.props.tab === 0 && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <UserMaintenance
                        access={this.props.access}
                        jsonWebTok={this.props.fetchInitialData.credentials.user.jsonWebTok}
                        userType="Client"
                        client_id={this.props.fetchInitialData.credentials.user.clientId}
                        props={this.props}
                    />
                  </Grid>
              )}
              {this.props.tab === 1 && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <UserMaintenance
                        access={this.props.access}
                        jsonWebTok={this.props.fetchInitialData.credentials.user.jsonWebTok}
                        userType="Vendor"
                        client_id={this.props.fetchInitialData.credentials.user.clientId}
                        props={this.props}
                    />
                  </Grid>
              )}


            </Grid>
          </div>
        </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
});

AdminUserMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(AdminUserMaintenance));
