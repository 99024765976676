import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  InputAdornment,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  withStyles,
  Fade
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputMask from 'react-input-mask';
import FieldChangeActivity from '../functions/FieldChangeActivity';
import DropDown from '../components/DropDown';
import Comments from '../components/Comments';
import writeToActivity from '../functions/WriteToActivity';
import LoadingButton from '../components/LoadingButton';
import Activity from '../components/Activity';
import ViewVendorLegal from '../components/ViewVendorLegal';
import AttachAttribTask from '../components/AttachAttribTask';
import ClientLocationMaintenance from '../components/ClientLocationMaintenance';
import ClientContactMaintenance from '../components/ClientContactMaintenance';
import VendorStatus from '../functions/VendorStatus';
import VerifyAccess from '../functions/VerifyAccess';
import LambdaFetch from '../functions/FetchFromLambda';
import VendorContracts from '../components/VendorContracts';
import ObjectNotifications from '../components/ObjectNotifications';
import { AntTabs, AntTab } from "../components/common/Tabs";
import LoadingCircle from "../components/common/LoadingCircle"
import clsx from 'clsx';

import './VendorForm.css';
import './Details.css';
import {
  apcVenMast,
  invoiceInformation,
  meta,
  preparerInfo,
  taxClassification,
  vendorDetailsTabs,
} from '../utils/constants/vendorDetailsConstants';

const uuidv1 = require('uuid/v1');

const styles = {
  root: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
  buttonProgress: {
    color: '#545454',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$selected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
};

class VendorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.masterPHONE_NUM0 = React.createRef();
    this.masterFAX_NUM0 = React.createRef();
    this.contactPHONE_NMBR0 = React.createRef();
    this.contactMOBILE_NUM0 = React.createRef();
    this.contactFAX_NMBR0 = React.createRef();
    this.contactPHONE_NMBR1 = React.createRef();
    this.contactMOBILE_NUM1 = React.createRef();
    this.contactFAX_NMBR1 = React.createRef();

    this.state = {
      formButton: null,
      isOpen: false,
      isAccept: null,
      content: {},
      updatedFields: null,
      dropdowns: null,
      termCode: null,
      invCurrency: null,
      languageCode: null,
      noNumberError: '',
      errors: {
        masterPHONE_NUM0: '',
        masterFAX_NUM0: '',
        contactPHONE_NMBR0: '',
        contactMOBILE_NUM0: '',
        contactFAX_NMBR0: '',
        contactPHONE_NMBR1: '',
        contactMOBILE_NUM1: '',
        contactFAX_NMBR1: '',
      },
      fileLoad: '',
      isLoading: false,
      deleteAttachmentOpen: false,
      deleteAttachmentName: null,
      deleteAttachment: null,
      uploadFiles: [],
      isReOpen: false,
      isSavingFiles: false,
      tabValue: 0,
      viewLegal: false,
      isLoadingLegal: false,
      isReopenOpen: false,
      isReopening: false,
      access: {},
      mainTabValue: 0,
      whiteList: null,
    };
    this.dropDownChange = this.dropDownChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.inputFileChange = this.inputFileChange.bind(this);
  }

  componentDidMount() {
    VerifyAccess(
      (data) => {
        this.setState({ access: data.access });
      },
      [
        'vendor_location_edit',
        'vendor_location_view',
        'vendor_location_add',
        'vendor_location_delete',
        'vendor_approve',
        'vendor_edit',
        'vendor_legal_edit',
        'vendor_legal_view',
        'vendor_contact_view',
        'vendor_contact_edit',
        'vendor_contact_add',
        'vendor_contact_delete',
        'comment_add',
        'history_view',
        'vendor_delete',
      ],
      this.props.fetchInitialData.credentials.user.jsonWebTok
    );
    this.getData();
    this.viewHash();
  }
  handleTabChange = (event, newValue) => {
    this.setState({ mainTabValue: newValue }, () => {
      switch (newValue) {
        case 1:
          window.location.hash = 'attachments';
          break;
        case 2:
          window.location.hash = 'locations';
          break;
        case 3:
          window.location.hash = 'people';
          break;
        case 4:
          window.location.hash = 'attributes';
          break;
        case 5:
          window.location.hash = 'notifications';
          break;
        case 6:
          window.location.hash = 'contracts';
          break;
        case 0:
        default:
          window.location.hash = 'profile';
          break;
      }
    });
  };

  viewHash = () => {
    switch (window.location.hash) {
      case '#attachments':
        this.setState({ mainTabValue: 1 });
        break;
      case '#locations':
        this.setState({ mainTabValue: 2 });
        break;
      case '#people':
        this.setState({ mainTabValue: 3 });
        break;
      case '#attributes':
        this.setState({ mainTabValue: 4 });
        break;
      case '#notifications':
        this.setState({ mainTabValue: 5 });
        break;
      case '#contracts':
        this.setState({ mainTabValue: 6 });
        break;
      default:
        this.setState({ mainTabValue: 0 });
        break;
    }
  };

  getData = async () => {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      parentType: 'vendor',
      vendorId: this.props.fetchInitialData.credentials.user.vendorId,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    };
    const jsonVendorId = JSON.stringify(form);

    const info = await LambdaFetch(
      'get-vendor-forms',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      jsonVendorId
    );
    const whiteListInfo = await LambdaFetch(
      'contract-settings',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        parentType: 'vendor',
      })
    );
    let data = info.data;
    const termCodeDropdown = data.dropdowns.filter(
      (option) => option.R_KEY === 'termCode'
    );
    const invCurrencyDropdown = data.dropdowns.filter(
      (option) => option.R_KEY === 'invCurrency'
    );
    const languageCodeDropdown = data.dropdowns.filter(
      (option) => option.R_KEY === 'languageCode'
    );
    const dropdownMenus = {
      termCodeDropdown: termCodeDropdown,
      invCurrencyDropdown: invCurrencyDropdown,
      languageCodeDropdown: languageCodeDropdown,
    };

    this.setState({
      content: data,
      recievedInfo: true,
      dropdowns: dropdownMenus,
      taxClassification: data.master[0].TAX_CLASS,
      termCode: data.master[0].TERM_CODE,
      invCurrency: data.master[0].INV_CURRENCY,
      languageCode: data.master[0].LANGUAGE_CODE,
      vendorGroup: data.master[0].VENDOR_GROUP,
      venClass: data.master[0].VEN_CLASS,
      taxCode: data.master[0].TAX_CODE,
      holdCode: data.master[0].HLD_CODE,
      distributionCode: data.master[0].DIST_CODE,
      accrualCode: data.master[0].ACCR_CODE,
      paymentCode: data.master[0].BANK_INST_CODE,
      cashCode: data.master[0].CASH_CODE,
      isLoading: false,
      isOpen: false,
      isSavingFiles: false,
      uploadFiles: [],
      whiteList: whiteListInfo.data.whiteList,
    });
  };

  dropDownChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  validate(validateFields) {
    const { errors } = this.state;
    let isError = false;

    if (this.state.content.contact.length > 1) {
      if (validateFields.contactFAX_NMBR1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactFAX_NMBR1'].value)
        ) {
          isError = true;
          errors.contactFAX_NMBR1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactFAX_NMBR1.current) {
            this.contactFAX_NMBR1.current.focus();
          }
        } else {
          errors.contactFAX_NMBR1 = '';
        }
      } else {
        errors.contactFAX_NMBR1 = '';
      }

      if (validateFields.contactMOBILE_NUM1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactMOBILE_NUM1'].value)
        ) {
          isError = true;
          errors.contactMOBILE_NUM1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactMOBILE_NUM1.current) {
            this.contactMOBILE_NUM1.current.focus();
          }
        } else {
          errors.contactMOBILE_NUM1 = '';
        }
      } else {
        errors.contactMOBILE_NUM1 = '';
      }

      if (validateFields.contactPHONE_NMBR1.value !== '') {
        if (
          !this.validatePhoneNumber(validateFields['contactPHONE_NMBR1'].value)
        ) {
          isError = true;
          errors.contactPHONE_NMBR1 = 'Must be of format xxx-xxx-xxxx';
          if (this.contactPHONE_NMBR1.current) {
            this.contactPHONE_NMBR1.current.focus();
          }
        } else {
          errors.contactPHONE_NMBR1 = '';
        }
      } else {
        errors.contactPHONE_NMBR1 = '';
      }
    }

    const masterPHONE_NUM0 = this.validatePhoneNumber(
      validateFields['masterPHONE_NUM0'].value
    );
    const contactPHONE_NMBR0 = this.validatePhoneNumber(
      validateFields['contactPHONE_NMBR0'].value
    );
    const contactMOBILE_NUM0 = this.validatePhoneNumber(
      validateFields['contactMOBILE_NUM0'].value
    );

    if (validateFields.contactFAX_NMBR0.value !== '') {
      if (!this.validatePhoneNumber(validateFields['contactFAX_NMBR0'].value)) {
        isError = true;
        errors.contactFAX_NMBR0 = 'Must be of format xxx-xxx-xxxx';
        if (this.contactFAX_NMBR0.current) {
          this.contactFAX_NMBR0.current.focus();
        }
      } else {
        errors.contactFAX_NMBR0 = '';
      }
    } else {
      errors.contactFAX_NMBR0 = '';
    }

    if (!contactMOBILE_NUM0) {
      isError = true;
      errors.contactMOBILE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.contactMOBILE_NUM0.current) {
        this.contactMOBILE_NUM0.current.focus();
      }
    } else {
      errors.contactMOBILE_NUM0 = '';
    }

    if (!contactPHONE_NMBR0) {
      isError = true;
      errors.contactPHONE_NMBR0 = 'Must be of format xxx-xxx-xxxx';
      if (this.contactPHONE_NMBR0.current) {
        this.contactPHONE_NMBR0.current.focus();
      }
    } else {
      errors.contactPHONE_NMBR0 = '';
    }

    if (validateFields.masterFAX_NUM0.value !== '') {
      if (!this.validatePhoneNumber(validateFields['masterFAX_NUM0'].value)) {
        isError = true;
        errors.masterFAX_NUM0 = 'Must be of format xxx-xxx-xxxx';
        if (this.masterFAX_NUM0.current) {
          this.masterFAX_NUM0.current.focus();
        }
      } else {
        errors.masterFAX_NUM0 = '';
      }
    } else {
      errors.masterFAX_NUM0 = '';
    }

    if (!masterPHONE_NUM0) {
      isError = true;
      errors.masterPHONE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.masterPHONE_NUM0.current) {
        this.masterPHONE_NUM0.current.focus();
      }
    } else {
      errors.masterPHONE_NUM0 = '';
    }

    if (!masterPHONE_NUM0) {
      isError = true;
      errors.masterPHONE_NUM0 = 'Must be of format xxx-xxx-xxxx';
      if (this.masterPHONE_NUM0.current) {
        this.masterPHONE_NUM0.current.focus();
      }
    } else {
      errors.masterPHONE_NUM0 = '';
    }

    if (isError) {
      this.setState({
        errors: errors,
      });
    }
    return isError;
  }

  validatePhoneNumber(number) {
    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;
    return numberPattern.test(number);
  }

  handleForm(event) {
    event.preventDefault();
    const form = event.target;
    const masterPairs = {};

    if (this.state.formButton === 'accept') {
      const validateErrors = this.validate(form);
      if (this.state.formButton === 'accept' && validateErrors === true) {
        return null;
      }
    }

    masterPairs['VENDOR_ID'] = this.state.content.master[0].VENDOR_ID;

    for (let i = 0; i < apcVenMast.length; i++) {
      const masterId = apcVenMast[i]['fieldId'];
      masterPairs[masterId] = form['master' + masterId + 0].value;
    }
    masterPairs.TAX_CLASS = this.state.taxClassification;
    masterPairs.TERM_CODE = this.state.termCode;
    masterPairs.INV_CURRENCY = this.state.invCurrency;
    masterPairs.LANGUAGE_CODE = this.state.languageCode;
    masterPairs.FILTER_VALUE = form['clientFilter'].value;

    const updatedFields = {
      master: masterPairs,
    };
    if (this.state.formButton === 'reopen') {
      this.setState({
        updatedFields: updatedFields,
        isReOpen: true,
      });
    } else {
      this.setState({
        updatedFields: updatedFields,
        isOpen: true,
      });
    }
  }

  handleTaxChange(event) {
    this.setState({ taxClassification: event.target.value });
  }

  handleClickOpen() {
    this.setState({ isOpen: false });
  }

  handleChange(event) {
    this.setState({ reason: event.target.value });
  }

  handleClose() {
    this.setState({
      isOpen: false,
      deleteAttachmentOpen: false,
      isReOpen: false,
    });
  }

  handleSave = async (saveFields) => {
    this.setState({ isLoading: true });
    saveFields[
      'jsonWebTok'
    ] = this.props.fetchInitialData.credentials.user.jsonWebTok;

    if (this.state.formButton === 'reopen') {
      saveFields['reopen'] = true;
    }
    const stringUpdate = JSON.stringify(saveFields);
    const action = await LambdaFetch(
      'save-apc',
      'post',
      this.props.fetchInitialData.credentials.user.jsonWebTok,
      stringUpdate,
      'Successfully saved changes'
    );

    if (!action) return null;
    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.state.content.master[0].VENDOR_ID,
      ACTIVITY_TYPE: 'SAVE',
      ACTIVITY_STRING: ' Saved updates',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      field_changes: action.data.changes.field_changes,
    };

    FieldChangeActivity({ activity }, () => {
      if (this.state.formButton === 'save') {
        this.getData();
      }
      this.setState({ vendorNameError: false, isLoading: false });
    });
    // await this.getData();
  };

  getFile(key, originalName) {
    this.setState({ fileLoad: key });
    const getObject = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      key: `${this.props.fetchInitialData.credentials.clientInfo.client_id}/${key}`,
      activity: {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: 'DOWNLOAD',
        ACTIVITY_STRING: `downloaded ${originalName}`,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      },
    };

    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 402) {
          this.props.fetchInitialData.createSnack(
            'You do not have credentials to see this file',
            'warning',
            3000
          );
        }
        if (data.errorMessage) {
          this.props.fetchInitialData.createSnack(
            `Error: ${data.errorMessage}`,
            'error',
            3000
          );
        } else {
          const parsed = JSON.parse(data.body);
          const read = Buffer.from(parsed.Body.data).toString('base64');
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = `data:application/pdf;base64,${read}`;
          a.download = originalName;
          a.click();
          this.getData();
          this.setState({ fileLoad: false });
        }
      })
      .catch((err) => console.log(err));
  }

  handleDeleteAttachment = (callback, attachment) => {
    fetch(`${process.env.REACT_APP_API}/delete-attachment`, {
      method: 'post',
      header: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify({
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        attachmentId: attachment.ATTACHMENT_ID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 200) {
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: 'VENDOR',
            OBJ_ID: this.state.content.master[0].VENDOR_ID,
            ACTIVITY_TYPE: 'DELETE',
            ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} deleted ${attachment.ORIGINAL_NAME}`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          writeToActivity({ activity }, () => {
            this.getData();
            callback(200);
          });
        } else {
          this.getData();
          callback(400);
        }
      });
  };

  approverTextField(input, index, fieldGroup, readOnly) {
    const { classes } = this.props;

    if (
      input.fieldId === 'PHONE_NMBR' ||
      input.fieldId === 'PHONE_NUM' ||
      input.fieldId === 'MOBILE_NUM' ||
      input.fieldId === 'FAX_NMBR' ||
      input.fieldId === 'FAX_NUM'
    ) {
      return (
        <InputMask
          key={input.fieldId}
          mask="999-999-9999"
          defaultValue={input.defaultValue}
        >
          {() => (
            <TextField
              inputRef={this[fieldGroup + input.fieldId + index]}
              key={fieldGroup + input.fieldId + index}
              id={fieldGroup + input.fieldId + index}
              label={input.fieldName}
              fullWidth
              variant="filled"
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.resize,
                  root: classes.textFieldRoot,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <span />
                  </InputAdornment>
                ),
              }}
              error={
                this.state.errors[fieldGroup + input.fieldId + index] !== ''
              }
              helperText={this.state.errors[fieldGroup + input.fieldId + index]}
            />
          )}
        </InputMask>
      );
    }
    if (readOnly) {
      return (
        <TextField
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          disabled
          variant="filled"
          margin="dense"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.textFieldRoot,
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      );
    }
    if (index === null) index = '';
    if (input.half) {
      return (
        <span key={input.fieldId} style={{ marginRight: '5px' }}>
          <TextField
            key={input.fieldId}
            id={fieldGroup + input.fieldId + index}
            label={input.fieldName}
            defaultValue={input.defaultValue}
            variant="filled"
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <span />
                </InputAdornment>
              ),
            }}
          />
        </span>
      );
    } else {
      return (
        <TextField
          key={input.fieldId}
          id={fieldGroup + input.fieldId + index}
          label={input.fieldName}
          defaultValue={input.defaultValue}
          fullWidth
          variant="filled"
          margin="dense"
          InputProps={{
            classes: {
              input: classes.resize,
              root: classes.textFieldRoot,
            },
            startAdornment: (
              <InputAdornment position="start">
                <span />
              </InputAdornment>
            ),
          }}
        />
      );
    }
  }

  inputFileChange(e) {
    if (e.target.files[0] === undefined) {
      this.setState({
        uploadFiles: [],
      });
    } else {
      this.setState({ uploadFiles: [] });
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
          reader = new FileReader(),
          self = this;
        reader.onload = function(r) {
          const updatedUpload = self.state.uploadFiles.map((file) =>
            Object.assign({}, file)
          );
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size,
          });
          self.setState({
            uploadFiles: updatedUpload,
          });
        };
        reader.readAsDataURL(file);
        this.setState({ value: reader });
      }
    }
  }

  uploadFiles = () => {
    this.setState({ isLoading: true, isSavingFiles: true });
    for (let i = 0; i < this.state.uploadFiles.length; i++) {
      this.uploadFile(
        this.state.content.master[0].VENDOR_ID,
        this.state.uploadFiles[i]
      );
    }
    this.fileInput.value = '';
  };
  uploadFile = (vendorId, fileData) => {
    if (fileData.src === '') {
      return null;
    }
    if (fileData.size > 5000000) {
      this.props.fetchInitialData.createSnack(
        `${fileData.name} is too big to upload`,
        'warning',
        3000
      );
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(';')[0].replace('data:', '');
      let data = fileData.src.split(';')[1].replace('base64,', '');
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: 'VENDOR',
        obj_id: vendorId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: this.props.props.fetchInitialData.credentials.user.uuid,
        activity: {
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          OBJ_TYPE: 'VENDOR',
          OBJ_ID: vendorId,
          ACTIVITY_TYPE: 'UPLOAD',
          ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} uploaded an attachment to application`,
          USER_ID: this.props.fetchInitialData.credentials.user.uuid,
        },
      };
      const stringData = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: this.props.fetchInitialData.credentials.user
            .jsonWebTok,
        },
        body: stringData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          this.getData();
          if (data.errorMessage) {
            this.props.fetchInitialData.createSnack(
              `Error: ${data.errorMessage}`,
              'error',
              3000
            );
          }
          return data;
        });
    }
  };
  viewLegal = () => {
    this.setState({ isLoadingLegal: true });
    if (
      this.props.fetchInitialData.credentials.isVendor ||
      this.props.fetchInitialData.credentials.isVendorAdmin
    ) {
      const activity = {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'VENDOR',
        OBJ_ID: this.state.content.master[0].VENDOR_ID,
        ACTIVITY_TYPE: 'VIEWED',
        ACTIVITY_STRING: `${this.props.fetchInitialData.credentials.user.name} viewed legal information`,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      };
      writeToActivity({ activity }, () => {
        this.setState({ viewLegal: true, isLoadingLegal: false });
      });
    } else {
      this.setState({ isLoadingLegal: false }, () => {
        this.props.fetchInitialData.createSnack(
          'You do not have credentials to view legal information',
          'warning',
          3000
        );
      });
    }
  };
  handleReopen = (event) => {
    event.preventDefault();
    this.setState({ isReopening: true });
    const fetchData = JSON.stringify({
      vendorId: this.state.content.master[0].VENDOR_ID,
    });
    fetch(`${process.env.REACT_APP_API}/reopen-apc`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: fetchData,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          this.setState({ isReopening: false });
          return null;
        } else if (info.success) {
          const activity = {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: 'VENDOR',
            OBJ_ID: this.state.content.master[0].VENDOR_ID,
            ACTIVITY_TYPE: 'RESUBMITTED',
            ACTIVITY_STRING: `Resubmitted application`,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          };
          writeToActivity({ activity }, () => {
            this.setState({ isReopening: false, isReopenOpen: false });
            this.getData();
            this.props.fetchInitialData.createSnack(
              'Successfully reopened application',
              'success',
              2000
            );
          });
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error reopening application',
            'error',
            3000
          );
          this.setState({ isReopening: false, isReopenOpen: false });
        }
      });
  };

  render() {
    const { classes } = this.props;
    if (!this.state.content.master) {
      return (
        <LoadingCircle/>
      );
    } else {
      const vendorInfo = [{ fields: meta }, { fields: preparerInfo }];
      vendorInfo.forEach((el) => {
        el.values = el.fields.map((input) => {
          const inputCopy = Object.assign({}, input);
          inputCopy['defaultValue'] = this.state.content.master[0][
            input.fieldId
          ];
          return inputCopy;
        });
      });
      let submissionDate = '';
      if (this.state.content.master[0]['SUBMISSION_DATE']) {
        submissionDate = this.state.content.master[0]['SUBMISSION_DATE'].split(
          ' GMT'
        )[0];
      }

      const load = this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <CircularProgress
            style={{ marginLeft: '10px', color: 'white' }}
            size="20px"
          />
        </Fade>
      ) : (
        ''
      );
      return (
        <div>
          <div style={{ backgroundColor: 'white', width: '100%', zIndex: 200 }}>


           <AntTabs
            color={this.props.fetchInitialData.credentials.primaryAppColor}
            value={this.state.mainTabValue}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
          {vendorDetailsTabs.map((el, index) => {
            let show = true
              if (el.license) {
                if(this.props.fetchInitialData.credentials.clientInfo[el.license] !== 1) {
                  show = false
                }
              }
              if(show) {
                  return (
                     <AntTab
                key={el.value}
                color={this.props.fetchInitialData.credentials.primaryAppColor}
                label={el.label}
                value={el.value}
              />
                  );

              }
                
              })}
      
          </AntTabs>
          </div>
          {this.state.viewLegal && this.state.mainTabValue === 0 && (
            <ViewVendorLegal
              access={this.state.access}
              fetchInitialData={this.props.fetchInitialData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              vendorId={this.state.content.master[0].VENDOR_ID}
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              user={this.props.fetchInitialData.credentials.user}
              handleClose={() =>
                this.setState({ viewLegal: false }, () => this.getData())
              }
            />
          )}
          <Dialog
            open={this.state.deleteAttachmentOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete{' '}
                {this.state.deleteAttachmentName}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Back</Button>
              <Button
                onClick={() =>
                  this.handleDeleteAttachment(this.state.deleteAttachment)
                }
                autoFocus
                variant="contained"
                style={{ backgroundColor: '#E86613', color: '#FFFFFF' }}
              >
                Delete
                {load}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Save</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to save changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Back</Button>
              <Button
                onClick={() => this.handleSave(this.state.updatedFields)}
                autoFocus
                variant="contained"
                className="primaryVLButton"
              >
                Save
                {load}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isReopenOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.handleReopen}>
              <DialogTitle id="alert-dialog-title">Resubmit</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div>
                    Please confirm that you have addressed the problem below
                    before resubmiting:
                  </div>
                  <div style={{ fontWeight: 400, color: 'black' }}>
                    {this.state.content.master[0].REASON}
                  </div>
                  <div style={{ marginTop: '0.5rem' }}>
                    This action will restart the approval process for this
                    vendor. Are you sure you wan to proceed?
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ isReopenOpen: false })}>
                  Cancel
                </Button>
                <LoadingButton
                  label="Confirm"
                  isLoading={this.state.isReopening}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>
          <div className="formVendor">
            {this.state.mainTabValue === 0 && (
              <>
                <div style={{ position: 'relative' }}>
                  <h2>{this.state.content.master[0]['VENDOR']}</h2>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: '0px',
                      fontSize: '12px',
                    }}
                  >
                    <div>
                      Status:{' '}
                      {VendorStatus(this.state.content.master[0].VL_STATUS)}
                    </div>
                    <div>
                      Approval Level:{' '}
                      {this.state.content.master[0].APPROVAL_STATUS_LEVEL - 1}
                    </div>
                  </div>
                  <div style={{ fontSize: '10px' }}>
                    Submission Date: {submissionDate}
                  </div>
                </div>
                <hr />
              </>
            )}

            {this.state.mainTabValue === 0 && (
              <Fragment>
                {this.state.content.master[0].VL_STATUS === 5 && (
                  <Alert
                    severity="error"
                    style={{ margin: '1rem 0' }}
                    action={
                      <Button
                        onClick={() => this.setState({ isReopenOpen: true })}
                        variant="contained"
                        disableElevation
                        style={{ marginLeft: '0.5rem' }}
                        color="secondary"
                        size="small"
                      >
                        Resubmit
                      </Button>
                    }
                  >
                    {`Status: Rejected (${this.state.content.master[0].REASON})`}
                  </Alert>
                )}

                <form onSubmit={this.handleForm}>
                  <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6">
                        {
                          this.props.fetchInitialData.credentials.clientInfo
                            .filter_value_label
                        }
                      </Typography>
                      <TextField
                        id="clientFilter"
                        variant="filled"
                        margin="dense"
                        defaultValue={this.state.content.master[0].FILTER_VALUE}
                        InputProps={{
                          classes: {
                            input: classes.resize,
                            root: classes.textFieldRoot,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <h5>Vendor Information</h5>
                      {vendorInfo[0].values.map((input) => {
                        return this.approverTextField(
                          input,
                          0,
                          'master',
                          input.readOnly
                        );
                      })}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Typography variant="h6" style={{ paddingTop: '2%' }}>
                        Tax Classification
                      </Typography>
                      <FormControl margin="dense" component="fieldset">
                        <FormLabel component="legend" />
                        <RadioGroup
                          aria-label="taxClassification"
                          name="taxClassification"
                          value={this.state.taxClassification}
                          onChange={this.handleTaxChange}
                        >
                          {taxClassification.map((el, index) => (
                            <FormControlLabel
                              key={index}
                              value={el}
                              control={
                                <Radio
                                  className={clsx({
                                    root: classes.root,
                                    checked: classes.checked,
                                  }, "primaryVLcolor")}
                                />
                              }
                              label={el}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <div style={{ width: '100%' }} />
                    <div style={{ width: '100%' }} />
                    <Grid item xs={12} sm={6} md={6}>
                      <h5>Invoice Information</h5>
                      {invoiceInformation.map((el, index) => (
                        <DropDown
                          key={index}
                          id={el.id}
                          options={this.state.dropdowns[el.options]}
                          default={this.state[el.defaultState]}
                          label={el.label}
                          passUp={this.dropDownChange}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="masterCOMMENT0"
                        label="Describe your service/products"
                        fullWidth
                        defaultValue={this.state.content.master[0]['COMMENT']}
                        multiline
                        rows="2"
                        variant="filled"
                        margin="dense"
                        InputProps={{
                          classes: {
                            input: classes.resize,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <h5>Preparer's Information</h5>
                      {vendorInfo[1].values.map((input) => {
                        return this.approverTextField(
                          input,
                          0,
                          'master',
                          input.readOnly
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <span>
                        <Button
                          variant="contained"
                          className="primaryVLButton"
                          disabled={this.state.isLoading}
                          id="save"
                          type="submit"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              formButton: 'save',
                              noNumberError: '',
                            });
                          }}
                          style={{ marginRight: '0.5rem' }}
                        >
                          Save
                          {load}
                        </Button>
                      </span>
                      <span>
                        <Button
                          variant="outlined"
                          disabled={
                            this.state.isLoadingLegal ||
                            !this.state.access.vendor_legal_view
                          }
                          onClick={() => this.viewLegal()}
                          style={{ marginRight: '0.5rem' }}
                        >
                          {this.state.isLoadingLegal ? (
                            <div>
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                              View/Edit Legal Information
                            </div>
                          ) : (
                            'View/Edit Legal Information'
                          )}
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </form>
              </Fragment>
            )}
            {this.state.mainTabValue === 2 && (
              <div style={{ marginTop: '1rem' }}>
                <ClientLocationMaintenance
                  access={this.state.access}
                  getData={this.getData}
                  address={this.state.content.address}
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  fetchInitialData={this.props.fetchInitialData}
                />
              </div>
            )}
            {this.state.mainTabValue === 3 && (
              <div style={{ marginTop: '1rem' }}>
                <ClientContactMaintenance
                  access={this.state.access}
                  getData={this.getData}
                  contacts={this.state.content.contact}
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  fetchInitialData={this.props.fetchInitialData}
                />
              </div>
            )}
            {this.state.mainTabValue === 6 && (
              <div style={{ padding: '0 1rem', marginTop: '1.5rem' }}>
                <VendorContracts
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  props={this.props}
                />
              </div>
            )}
            <AttachAttribTask
              tab={this.state.mainTabValue}
              access={this.state.access}
              props={this.props}
              getData={this.getData}
              jsonWebTok={
                this.props.fetchInitialData.credentials.user.jsonWebTok
              }
              clientId={
                this.props.fetchInitialData.credentials.clientInfo.client_id
              }
              currentUser={this.props.fetchInitialData.credentials.user.uuid}
              parentType="vendor"
              parentId={this.state.content.master[0].VENDOR_ID}
              whiteList={this.state.whiteList}
            />
            {this.state.mainTabValue === 0 && (
              <>
                <Comments
                  access={this.state.access}
                  fetchInitialData={this.props.fetchInitialData}
                  objectId={
                    this.props.fetchInitialData.credentials.user.vendorId
                  }
                  objectType="VENDOR"
                  vendorId={this.state.content.master[0].VENDOR_ID}
                  isVendor={true}
                />
                <Activity
                  access={this.state.access}
                  title="Vendor Account History"
                  activity={this.state.content.activity}
                  timezone={
                    this.props.fetchInitialData.credentials.user.timezone
                  }
                  appWidth={this.props.fetchInitialData.credentials.appWidth}
                />

                <ObjectNotifications
                  createSnack={this.props.fetchInitialData.createSnack}
                  jsonWebTok={
                    this.props.fetchInitialData.credentials.user.jsonWebTok
                  }
                  parentId={this.state.content.master[0].VENDOR_ID}
                  parentType="vendor"
                  isVendor={!!this.props.fetchInitialData.credentials.isVendor}
                  vendorId={this.state.content.master[0].VENDOR_ID}
                />
              </>
            )}
          </div>
        </div>
      );
    }
  }
}

VendorDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorDetails);
