import toast from "../components/common/Toast";

const axios = require("axios");

export default async function lambdaFetch(
  path,
  method,
  webToken,
  body,
  successMessage
) {
  try {
    // const res = await axios({
    //   method,
    //   url: `${process.env.REACT_APP_API}/${path}`,
    //   headers: {
    //     "content-type": "application/json",
    //     ...(webToken && { Authorization: `bearer ${webToken}` }),
    //   },
    //   data: body,
    // }).catch((e) => {
    //   console.log(e);
    //   throw new Error(e);
    // });

    const res = await fetch(`${process.env.REACT_APP_API}/${path}`, {
      method: method,
      headers: {
        "content-type": "application/json",
        ...(webToken && { Authorization: `bearer ${webToken}` }),
      },
      body: body,
    }).then((resp) => {
      return resp.json()
    });

    if (res.status !== 200 && res.error) {
      toast.persist(res.error, "error");
      return false;
    } else {
      successMessage && toast.success(successMessage);
      return res;
    }
  } catch (err) {
    toast.persist(err.toString(), "error");
  }
}
