import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './VendorPayment.css';
import Comments from '../components/Comments';
import Attachments from '../components/Attachments';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Activity from '../components/Activity';
import { formatter } from "../utils/tableCustoms";

class InvoiceVendorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      poNumber: null,
      contract: null,
      department: null,
      description: null,
      dueDate: null,
      company: '',
      processLevel: '',
      clientSegments: JSON.parse(
        this.props.fetchInitialData.credentials.clientInfo.segments
      ),
      isOpen: false,
      currentGroupOfCodes: [],
      currentCodeTotal: 0,
      invoiceTranAmount: 0,
      currentCodeEntry: {},
    };
    this.getFile = this.getFile.bind(this);
  }
  componentDidMount() {
    this.getInvoiceDetails();
  }
  getInvoiceDetails() {
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      invoice_id: window.location.href.split('invoice-vendor-details/')[1],
    });
    fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonVendor,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3500)
          return null
        }
        if (info.success) {
          const data = info.data
          let currentCodeTotal = data.apcinvoice.TRAN_INV_AMOUNT;
          let currentCodes = [];

          if (data.distrib.length > 0) {
            currentCodes = data.distrib.map((distrib) => {
              currentCodeTotal -= distrib.DIST_TRAN_AMOUNT;
              var codeRow = {
                line: distrib.DIST_LINE_NUM,
              };
              for (var i = 0; i < this.state.clientSegments.length; i++) {
                codeRow['segment' + (i + 1)] = distrib['SEG' + (i + 1)];
              }
              codeRow['total'] = distrib.DIST_TRAN_AMOUNT;
              return codeRow;
            });
          }

          let company = '';
          let processLvl = '';
          if (data.apcinvoice.COMPANY) {
            company = data.apcinvoice.COMPANY;
          }
          if (data.apcinvoice.PROCESS_LEVEL) {
            processLvl = data.apcinvoice.PROCESS_LEVEL;
          }
          data.activity = data.activity.reverse();
          this.setState({
            currentGroupOfCodes: currentCodes,
            content: data,
            poNumber: data.apcinvoice.PO_NUMBER,
            contract: data.apcinvoice.CONTRACT_NUM,
            department: data.apcinvoice.DEPARTMENT,
            description: data.apcinvoice.DESCRIPTION,
            dueDate: data.apcinvoice.DUE_DATE,
            invoiceCurrency: data.apcinvoice.CURRENCY,
            currentCodeTotal: currentCodeTotal,
            invoiceTranAmount: data.apcinvoice.TRAN_INV_AMOUNT,
            company: company,
            processLevel: processLvl,
          });
        } else {
          window.alert('There was an error');
        }
      })
      .catch((error) => console.log(error));
  }
  getFile(key, originalName) {
    this.setState({ fileLoad: key });
    var getObject = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      key:
        this.props.fetchInitialData.credentials.clientInfo.client_id +
        '/' +
        key,
      activity: {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        OBJ_TYPE: 'INVOICE',
        OBJ_ID: this.state.content.apcinvoice.INVOICE_ID,
        ACTIVITY_TYPE: 'DOWNLOAD',
        ACTIVITY_STRING:
          this.props.fetchInitialData.credentials.user.name +
          ' downloaded ' +
          originalName,
        USER_ID: this.props.fetchInitialData.credentials.user.uuid,
      },
    };
    fetch(`${process.env.REACT_APP_API}/get-s3-object`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: this.props.fetchInitialData.credentials.user.jsonWebTok,
      },
      body: JSON.stringify(getObject),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.statusCode === 402) {
          window.alert('You do not have credentials to see this file');
        }
        if (data.errorMessage) {
          window.alert(data.errorMessage);
        } else {
          var parsed = JSON.parse(data.body);
          var read = Buffer.from(parsed.Body.data).toString('base64');
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          var url = `data:application/pdf;base64,${read}`;
          a.href = url;
          a.download = originalName;
          a.click();
          this.setState({ fileLoad: false });
        }
      })
      .catch((err) => console.log(err));
  }
  render() {
    if (!this.state.content) {
      return (
        <div style={{ height: '100vh' }}>
          <div style={{ position: 'relative', left: '50%', top: '40%' }}>
            <CircularProgress
              style={{ color: 'rgb(232,102,19)' }}
              disableShrink
            />
          </div>
        </div>
      );
    } else {
      const { classes } = this.props;
      let status;
      switch (this.state.content.apcinvoice.INVOICE_STATUS.toString()) {
        case '2':
          status = 'Approved';
          break;
        case '3':
          status = 'Processing';
          break;
        case '4':
          status = 'Paid';
          break;
        case '5':
          status = 'Rejected';
          break;
        default:
          status = 'Pending';
      }
      const { apcinvoice, apcinvdtl } = this.state.content;
      let subTotal = 0;
      for (var i = 0; i < apcinvdtl.length; i++) {
        subTotal += apcinvdtl[i].LINE_TRAN_AMOUNT;
      }
      return (
        <div className="paymentForm">
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div>
                <span>Invoice Date: </span>
                <span>{apcinvoice.INVOICE_DATE}</span>
              </div>
              <div>
                <span>Payable To: </span>
                <span>company</span>
              </div>
              <div>
                <span>Ref#: </span>
                <span>{apcinvoice.REFERENCE_NUM}</span>
              </div>
              <div>
                <span>
                  {
                    this.props.fetchInitialData.credentials.clientInfo
                      .filter_value_label
                  }
                  :{' '}
                </span>
                <span>{apcinvoice.FILTER_VALUE}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div>
                <span>PO Number: </span>
                <span>{this.state.poNumber}</span>
              </div>
              <div>
                <span>Contract: </span>
                <span>{this.state.contract}</span>
              </div>
              <div>
                <span>Department: </span>
                <span>{this.state.department}</span>
              </div>
              <div>
                <span>Description: </span>
                <span>{this.state.description}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div>
                <span>Invoice Currency: </span>
                <span>{this.state.invoiceCurrency}</span>
              </div>
              <div>
                <span>Due Date: </span>
                <span>{this.state.dueDate}</span>
              </div>
            </Grid>
          </Grid>

          <div style={{ margin: '1rem 0rem' }}>
            <span style={{ fontSize: '1.5rem' }}>Invoice Status:</span>
            <span style={{ marginLeft: '0.5rem', fontSize: '1.7rem' }}>
              {status}
            </span>
            <Paper className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Line</TableCell>
                    <TableCell align="center">Notes</TableCell>
                    <TableCell align="center">Unit</TableCell>
                    <TableCell align="center">Rate</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apcinvdtl.map((row) => (
                    <TableRow key={row.LINE_NUM}>
                      <TableCell align="center" component="th" scope="row">
                        {row.LINE_NUM}
                      </TableCell>
                      <TableCell align="center">{row.LINE_DESC}</TableCell>
                      <TableCell align="center">{row.LINE_UOM}</TableCell>
                      <TableCell align="center">
                        {formatter.format(row.LINE_UNIT_COST)}
                      </TableCell>
                      <TableCell align="center">{row.LINE_QTY}</TableCell>
                      <TableCell align="center">
                        {formatter.format(row.LINE_TRAN_AMOUNT)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell colSpan={1} alight="center">
                      <strong>Subtotal:</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>{formatter.format(subTotal)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>

          <Attachments
            fetchInitialData={this.props.fetchInitialData}
            objectId={window.location.href.split('invoice-vendor-details/')[1]}
            objectType="INVOICE"
          />
          {this.state.content.apcinvoice.INVOICE_STATUS.toString() === '5' && (
            <Button
              variant="contained"
              style={{ backgroundColor: '#E86613', color: 'white' }}
              onClick={() => {
                this.props.history.push('/vendorpaymentform', {
                  headers: this.state.content.apcinvoice,
                  details: this.state.content.apcinvdtl,
                });
              }}
            >
              ReOpen
            </Button>
          )}

          <Comments
            fetchInitialData={this.props.fetchInitialData}
            objectId={window.location.href.split('invoice-vendor-details/')[1]}
            objectType="INVOICE"
          />

          <Activity
            title="Invoice History"
            activity={this.state.content.activity}
            timezone={this.props.fetchInitialData.credentials.user.timezone}
            appWidth={this.props.fetchInitialData.credentials.appWidth}
          />

          {/*
          {this.state.content.activity.length > 0 && (
            <div style={{ margin: "1rem auto" }}>
              <h5>Invoice History</h5>
              <div>
                <Paper className={classes.paper}>
                  {this.props.fetchInitialData.credentials.appWidth > 599 ? (
                    <Table style={{ overflowX: "auto" }} padding="dense">
                      <TableBody>
                        {this.state.content.activity.map((row, index) => (
                          <TableRow key={row.ACTIVITY_ID}>
                            <TableCell align="left">
                              {row.ACTIVITY_TYPE}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.ACTIVITY_STRING}
                            </TableCell>
                            <TableCell align="center" scope="row">
                              {moment(
                                moment(new Date(row.TIMESTAMP)).utcOffset(
                                  this.props.fetchInitialData.credentials.user
                                    .timezone
                                )._d
                              ).format("lll")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div>
                      {this.state.content.activity.map((row, index) => {
                        return (
                          <Card
                            style={{ border: "0.5px solid #545454" }}
                            key={row.ACTIVITY_ID}
                          >
                            <CardContent>
                              <div>
                                <span style={{ fontSize: "1.2rem" }}>
                                  {row.ACTIVITY_TYPE}
                                </span>
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  {moment(
                                    moment(new Date(row.TIMESTAMP)).utcOffset(
                                      this.props.fetchInitialData.credentials
                                        .user.timezone
                                    )._d
                                  ).format("lll")}
                                </span>
                                <hr style={{ margin: "0.1rem" }} />
                                <p style={{ margin: "0.8rem" }}>
                                  {row.ACTIVITY_STRING}
                                </p>
                                <div
                                  style={{
                                    marginTop: "0.8rem",
                                    fontSize: "0.8rem"
                                  }}
                                >
                                  by: {row.name}
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          )} */}
        </div>
      );
    }
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  textroot: {
    color: 'rgb(117,117,117)',
    '&$checked': {
      color: '#E86613',
    },
  },
  checked: {},
  resize: {
    fontSize: 12,
    maxWidth: '70px',
  },
  textFieldRoot: {
    height: '25px',
  },
  tableCell: {
    flex: 1,
    padding: '5px',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

InvoiceVendorDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(InvoiceVendorDetails));
