import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Grid, LinearProgress, Link, Typography, withStyles } from "@material-ui/core";

const styles = () => ({
  icons: {
    width: 60,
    height: 60,
    backgroundColor: 'white',
    position: 'relative',
    top: -30,
  },
  container: {
    marginTop: 40,
    marginBottom: 30,
  },
  progress: {
    backgroundColor: 'gray',
    '& div': {
      backgroundColor: 'green'
    }
  },
  iconLabel: {
    position: 'relative',
    top: -20,
    maxWidth: 120,
    textAlign: 'center',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '20%',
    alignItems: 'center'
  }
});

const statusLabels = [
  { value: 1, label: 'Completed Sign up' },
  { value: 2, label: 'Verify Email' },
  { value: 3, label: 'Upload Required Documents' },
  { value: 4, label: 'Client Review' },
  { value: 5, label: 'Vendor Number Assignment' }
];

class SignUpProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      status: 2,
    };
  }

  determineStatus = (mask) => {
    mask.forEach((el, i) => {
      switch (i) {
        case 0:
        case 1:
          this.setState({ progress: (10 + (i + 1) * 20), status: i + 1 });
          break;
        case 2:
          (el === 0) && this.setState({ progress: (10 + (i + 1) * 20), status: i + 1 });
          break;
        case 3:
          (el === 2) && this.setState({ progress: (10 + (i + 1) * 20), status: i + 1 });
          break;
        case 4:
          (el !== null) && this.setState({ progress: (10 + (i + 1) * 20), status: i + 1 });
          break;
        default:
          break;
      }
    })
  }

  componentDidMount() {
    this.determineStatus(this.props.mask);
  }

  render() {
    const { classes } = this.props;
    const numberOfSteps = 5;

    return (
        <Grid className={classes.container}>
          <LinearProgress variant="determinate" value={this.state.progress} className={classes.progress}/>
          <Grid justify={"space-around"} container>
            {[...Array(numberOfSteps)].map((e, i) => (
                <Grid key={i} className={classes.iconsContainer}>
                  {((i === 3 && this.props.mask[3] === 5) ||
                      (i === 2 && this.props.mask[2] !== 0)) ? (
                      <CancelIcon className={classes.icons} style={{ fill: 'red' }}/>
                  ) : (
                      <CheckCircleIcon
                          className={classes.icons}
                          style={{
                            fill: `${(
                                (i === 4 && this.props.mask[4] !== null) ||
                                (i === 3 && this.props.mask[3] === 2) ||
                                (i === 0) || (i === 1) || (i === 2 && this.props.mask[2] === 0)
                            ) ? 'green' : 'gray'}`
                          }}/>
                  )}
                  {
                    i === 2 && this.props.mask[2] !== 0 ? (
                        <Link color="inherit" href={'/vendordetails#attachments'}>
                          <Typography component={'h6'} className={classes.iconLabel}>
                            {statusLabels[i].label}
                          </Typography>
                        </Link>
                    ) : (
                        <Typography component={'h6'} className={classes.iconLabel}>
                          {statusLabels[i].label}
                        </Typography>
                    )
                  }

                </Grid>
            ))}
          </Grid>
        </Grid>
    )
  }
}

export default withStyles(styles)(SignUpProgressBar);
