import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { withSnackbar } from "notistack";
import moment from "moment-timezone";
import UploadS3Object from "../components/UploadS3Object";
import LoadingButton from "./LoadingButton";
import CustomTable from "../components/CustomTable";
import {
  documentColumns,
  uploadTypes,
} from "../utils/constants/documentsColumns";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: this.props.documents,
      addingNewDocument: false,
      newDocumentType: "",
      newUploadType: "c",
      uploadFileData: null,
      isSaving: false,
      inputFile: null,
      docDoesNotExpire: false,
      showArchived: false,
      showAlert: true,
    };
  }
  componentDidMount = () => {
    let docDefault;
    let isVendor = !!this.props.props.fetchInitialData.credentials.isVendor;
    const linkBehavior = this.props.props.fetchInitialData.credentials
      .clientInfo.linked_document_behavior;
    if ((linkBehavior === "D" && !isVendor) || linkBehavior === "E") {
      docDefault = "l";
    } else {
      docDefault = "c";
    }
    this.setState({ newUploadType: docDefault });
  };
  createSnack = (message, variant, duration) => {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      variant: variant,
      autoHideDuration: duration,
    });
  };

  isLinkValid = (link) =>
    this.props.whiteList.some((el) => {
      const pattern = `^https:\\/\\/.*(${el.url}).*`;
      const regex = new RegExp(pattern);
      return regex.test(link);
    });

  addNewDocument = (event) => {
    event.preventDefault();
    const { docDoesNotExpire } = this.state;
    this.setState({ isSaving: true });

    if (!docDoesNotExpire) {
      if (new Date(event.target.newDocDate.value) > new Date("3000-01-01")) {
        this.createSnack("Enter a valid end date", "warning", 3000);
        this.setState({ isSaving: false });
        return null;
      }
    }
    if (this.state.newUploadType === "l") {
      if (!this.isLinkValid(event.target.uploadLink.value)) {
        this.setState({ isSaving: false });
        this.props.enqueueSnackbar(
          `The domain hosting this document is not in our safe list of valid domains`,
          {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 2500,
          }
        );
        return null;
      }
      const newDoc = {
        title: event.target.newDocTitle.value,
        expiration_date: docDoesNotExpire
          ? null
          : event.target.newDocDate.value,
        description: event.target.newDocDescription.value,
        parent_type: this.props.parentType,
        document_type_id: this.state.newDocumentType,
        document_type_other:
          this.state.newDocumentType === "o"
            ? event.target.otherDocType.value
            : null,
        reference_type: this.state.newUploadType,
        status: 1,
        document_reference: event.target.uploadLink.value,
      };

      this.props.addNewDocument(newDoc, (data) => {
        if (data.statusCode === 200) {
          this.props.enqueueSnackbar(`Successfully created document`, {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "success",
            autoHideDuration: 3500,
          });
          this.setState({
            addingNewDocument: false,
            isSaving: false,
            docDoesNotExpire: false,
          });
        } else if (data.statusCode === 400) {
          this.props.enqueueSnackbar(data.error, {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 3500,
          });
          this.setState({ isSaving: false });
        } else {
          this.props.enqueueSnackbar(`There was an error`, {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 2500,
          });
          this.setState({ isSaving: false });
        }
      });
    } else if (this.state.newUploadType === "c") {
      const newDoc = {
        title: event.target.newDocTitle.value,
        expiration_date: docDoesNotExpire
          ? null
          : event.target.newDocDate.value,
        description: event.target.newDocDescription.value,
        parent_type: "contract",
        document_type_id: this.state.newDocumentType,
        document_type_other:
          this.state.newDocumentType === "o"
            ? event.target.otherDocType.value
            : null,
        reference_type: this.state.newUploadType,
        status: 1,
        document_reference: null,
      };

      if (!this.state.inputFile) {
        this.props.enqueueSnackbar(`You must choose a file`, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "warning",
          autoHideDuration: 3500,
        });
        this.setState({ isSaving: false });
        return null;
      }

      if (this.state.inputFile[0].size > 20000000) {
        this.props.enqueueSnackbar(`The file must be smaller than 20MB`, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "warning",
          autoHideDuration: 3500,
        });
        this.setState({ isSaving: false });
        return null;
      }

      this.props.uploadFile(this.state.inputFile[0], (statusCode, data) => {
        if (statusCode === 200) {
          newDoc.document_reference = data.key;
          this.props.addNewDocument(newDoc, (data) => {
            if (data.statusCode === 200) {
              this.setState({
                addingNewDocument: false,
                isSaving: false,
                docDoesNotExpire: false,
              });
              this.props.enqueueSnackbar(`Successfully created document`, {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                variant: "success",
                autoHideDuration: 3500,
              });
            } else {
              this.props.enqueueSnackbar(`There was an error`, {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                variant: "error",
                autoHideDuration: 3500,
              });
              this.setState({ isSaving: false });
            }
          });
        } else {
          this.props.enqueueSnackbar(`There was an error`, {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "error",
            autoHideDuration: 3500,
          });
          this.setState({ isSaving: false });
        }
      });
    }
  };
  updateFileData = (data) => this.setState({ inputFile: data });
  docLinkBehavior = () => {
    const linkBehavior = this.props.props.fetchInitialData.credentials
      .clientInfo.linked_document_behavior;
    let docOptions;
    let isVendor = !!this.props.props.fetchInitialData.credentials.isVendor;
    if (isVendor && (linkBehavior === "C" || linkBehavior === "D")) {
      docOptions = uploadTypes.slice().slice(0, 1);
    } else {
      docOptions = uploadTypes;
    }
    return docOptions;
  };
  render() {

    const filteredDocuments = this.props.documents
      .filter((d) => d.status === (this.state.showArchived ? 2 : 1))
      .map((d) => {
        return [
          <div>
            {d.document_type}
            {!!d.verified && (
              <CheckIcon
                size="small"
                style={{
                  fontSize: "15px",
                  color: "green",
                  marginLeft: "0.25rem",
                }}
              />
            )}
          </div>,
          d.title,
          new Date(d.expiration_date).getTime()
            ? moment.utc(new Date(d.expiration_date)).format("MM/DD/YYYY")
            : "N/A",
          d.name,
          new Date(d.create_date).getTime()
            ? moment.utc(new Date(d.create_date)).format("MM/DD/YYYY")
            : "N/A",
          <span
            className="editLink"
            onClick={() =>
              this.props.props.history.push(
                `/document-details/${d.document_id}`
              )
            }
          >
            view/edit
          </span>,
        ];
      });

    let viewNum = this.props.documents.length - filteredDocuments.length;

    const docOptions = this.docLinkBehavior();

    return (
      <Grid item xs={12} sm={12} md={12}>
        {this.props.missingDocuments.length &&
        (this.props.parentType === "vendor" ||
          this.props.parentType === "contract") ? (
          <Collapse in={this.state.showAlert}>
            <Alert
              severity="error"
              style={{ position: "relative", top: -10 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => this.setState({ showAlert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>
                You are still missing the following documents:
              </AlertTitle>
              {this.props.missingDocuments.map((doc, index) => (
                <div key={index}>- {doc.description}</div>
              ))}
            </Alert>
          </Collapse>
        ) : (
          <Collapse in={this.state.showAlert}>
            <Alert
              severity="success"
              style={{ position: "relative", top: -10 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => this.setState({ showAlert: false })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>You have all required documents</AlertTitle>
            </Alert>
          </Collapse>
        )}
        <Card>
          <CardContent>
            {this.state.addingNewDocument && (
              <Dialog
                open={this.state.addingNewDocument}
                onClose={() => this.setState({ addingNewDocument: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Fragment>
                  <form onSubmit={(event) => this.addNewDocument(event)}>
                    <DialogTitle id="alert-dialog-title">
                      Add Document
                    </DialogTitle>
                    <DialogContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="newDocTitle"
                            required
                            label="Title"
                            size="small"
                            fullWidth
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <FormControlLabel
                            style={{ margin: "0", verticalAlign: "middle" }}
                            control={
                              <Checkbox
                                checked={this.state.docDoesNotExpire}
                                onChange={() =>
                                  this.setState({
                                    docDoesNotExpire: !this.state
                                      .docDoesNotExpire,
                                  })
                                }
                                name="docDoesNotExpire"
                                style={{
                                  color: this.props.props.fetchInitialData.credentials.primaryAppColor,
                                  display: "inline",
                                }}
                              />
                            }
                            label="Document does not expire"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {!this.state.docDoesNotExpire && (
                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                style={{ marginTop: ".5rem" }}
                                id="newDocDate"
                                required
                                fullWidth
                                label="Expiration Date"
                                type="date"
                                size="small"
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                autoComplete="off"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="newDocDescription"
                            multiline
                            fullWidth
                            margin="dense"
                            label="Description"
                            variant="filled"
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="newDocType"
                            select
                            fullWidth
                            margin="dense"
                            label="Document Type"
                            value={this.state.newDocumentType}
                            onChange={(event) =>
                              this.setState({
                                newDocumentType: event.target.value,
                              })
                            }
                            variant="filled"
                            autoComplete="off"
                          >
                            {this.props.documentTypes.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option.document_type_id}
                              >
                                {option.description}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            style={{
                              display:
                                this.state.newDocumentType !== "o"
                                  ? "none"
                                  : "",
                            }}
                            required={this.state.newDocumentType === "o"}
                            id="otherDocType"
                            fullWidth
                            margin="dense"
                            label="Enter a Document Type"
                            variant="filled"
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="newUploadType"
                            select
                            fullWidth
                            margin="dense"
                            label="Upload Type"
                            value={this.state.newUploadType}
                            onChange={(event) =>
                              this.setState({
                                newUploadType: event.target.value,
                              })
                            }
                            variant="filled"
                            autoComplete="off"
                          >
                            {docOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          {this.state.newUploadType === "c" && (
                            <UploadS3Object
                              updateFileData={this.updateFileData}
                              myStyle={JSON.stringify({
                                position: "relative",
                                top: "15px",
                                left: "5px",
                              })}
                            />
                          )}
                          <TextField
                            style={{
                              display:
                                this.state.newUploadType !== "l" ? "none" : "",
                            }}
                            id="uploadLink"
                            required={this.state.newUploadType === "l"}
                            fullWidth
                            helperText="Must be in the form of: https://..."
                            margin="dense"
                            label="Document Link"
                            variant="filled"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="secondaryVLButton"
                        onClick={() =>
                          this.setState({ addingNewDocument: false })
                        }
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        label="Save"
                        isLoading={this.state.isSaving}
                        color="primaryVLButton"
                        buttonType="submit"
                      />
                    </DialogActions>
                  </form>
                </Fragment>
              </Dialog>
            )}
            <CustomTable
              rows={filteredDocuments}
              title="Attachments"
              cols={documentColumns}
            />
            <div style={{ float: "left", margin: "1rem 0" }}>
              <Button
                className="primaryVLButton"
                aria-label="add"
                onClick={() =>
                  this.setState({
                    addingNewDocument: true,
                    docDoesNotExpire: false,
                  })
                }
              >
                Add Document
              </Button>
            </div>
            <div style={{ float: "right", margin: "1rem 0" }}>
              <Button
                onClick={() =>
                  this.setState({ showArchived: !this.state.showArchived })
                }
                variant="outlined"
                className="primaryTextVLButton"
              >
                {this.state.showArchived
                  ? `View Active Documents (${viewNum})`
                  : `View Archived Documents (${viewNum})`}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withSnackbar(Documents);
