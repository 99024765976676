import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MainTabs.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import VerifyAccess from "../functions/VerifyAccess";
import { pages, clientRoutes } from "../utils/constants/mainTabsConstants";

const styles = {
  nav: {
    fontSize: "1.3rem",
    backgroundColor: "#E86613",
    color: "white",
    borderRadius: "5px",
    height: "40px",
    margin: "auto",
  },
  active: {
    marginTop: "-5px",
    color: "#E86613",
    height: "45px",
    backgroundColor: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    border: "solid 1px grey",
    borderBottomColor: "white",
  },
  topNavActive: {
    background: "#CCC",
    color: "#000",
  },
};


const viewTitle = (view) => {
  switch(view) {
    case 'home': 
      return 'Home'
    case 'vendors':
    return 'Vendor'
    case 'contracts':
    return 'Contracts'
    case 'invoices':
    return 'Invoices'
    case 'purchaserequests':
    return 'Purchase Requests'
    case 'settings':
    return 'Settings'
    case 'profile':
    return 'Profile'
    default:
    return 'Home'
  }

}

class MainTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "home",
      access: {},
    };
  }

  componentDidMount() {
    this.checkTab();
    this.checkAccess();
    this.unlisten = this.props.history.listen((location, action) => {
      this.checkView(location);
    });
    this.checkView(this.props.location);
  }
  componentWillUnmount() {
    this.unlisten();
  }
  checkView = (location) => {
    const view = clientRoutes[location.pathname.slice(1).split("/")[0]];
    this.setState({ view });
  };
  checkAccess = () => {
    VerifyAccess(
      ({ access }) => this.setState({ access }),
      [
        "vendor_tab",
        "contract_tab",
        "invoice_tab",
        "purchase_tab",
        "report_tab",
        "settings_tab",
      ],
      this.props.appState.user.jsonWebTok
    );
  };

  checkTab() {
    const currentLocation = window.location.pathname.slice(1);
    if (
      [
        "admingeneralsettings",
        "admincodessettings",
        "adminusermaintenance",
      ].indexOf(currentLocation) !== -1
    ) {
      this.setState({ view: "settings" });
    } else if (currentLocation === "allvendors") {
      this.setState({ view: "vendors" });
    } else if (!currentLocation) {
      this.setState({ view: "home" });
    } else {
      this.setState({ view: currentLocation });
    }
  }

  render() {
    const { access } = this.state;
    const { clientInfo } = this.props.appState
    const accessedPages = pages.slice().filter((page) => (access[page.tabAccess] || !page.tabAccess) && (!page.license || clientInfo[page.license] === 1))
  
    return (
      <>
        <div style={{ float: "right" }}>
          <div
            style={{
              fontSize: "11px",
              backgroundColor: "#fff",
              padding: 0,
              borderRadius: "30px",
            }}
          >
            <Link
              onClick={() => this.setState({ view: "profile" })}
              to="/profile"
            >
              <li
                style={this.state.view === "profile" ? styles.topNavActive : {}}
                className="top-list-item first-list-item"
              >
                My Account
              </li>
            </Link>
            {access && !!access.settings_tab && (
              <Link
                onClick={() => this.setState({ view: "settings" })}
                to="/adminsettings"
              >
                <li
                  style={
                    this.state.view === "settings" ? styles.topNavActive : {}
                  }
                  className="top-list-item"
                >
                  Settings
                </li>
              </Link>
            )}
            <Link onClick={() => this.setState({ view: "home" })} to="/home">
              <li className="top-list-item">Help</li>
            </Link>

            <span
              className="topNavLink"
              onClick={() =>
                this.props.logout(() => {
                  window.location.pathname = "/";
                  this.setState({ view: "" });
                })
              }
            >
              <li className="top-list-item last-list-item">Log out</li>
            </span>
          </div>
        </div>
        <div style={{ marginBottom: "0.5rem" }}>
          {process.env.REACT_APP_STAGE === "DEV" && (
            <img height={50} width={50} alt={"dev"} src="/Dev.png" />
          )}
          <img
            src={this.props.logo}
            style={{ margin: "0.5rem", height: "80px" }}
            alt="client logo"
          />
        </div>
        <nav
          style={{
            color: "white",
            borderRadius: "5px",
            height: "40px",
          }}
          className="navbar navbar-expand-lg vl-primary-bg-color"
        >
          <MenuDrawer pages={accessedPages} title={viewTitle(this.state.view)} history={this.props.history} />
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {accessedPages.map(
                (el, index) =>
                    <Link
                      key={index}
                      onClick={() => this.setState({ view: el.view })}
                      className="mainNavTab"
                      to={el.route}
                    >
                      <li className="nav-item">
                        <div
                          style={
                            this.state.view === el.view ? styles.active : {}
                          }
                          className={`nav-link ${
                            this.state.view === el.view
                              ? "vl-primary-text-color"
                              : ""
                          }`}
                        >
                          {el.title}
                        </div>
                      </li>
                    </Link>
                  )
              }
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default withRouter((props) => <MainTabs {...props} />);
