import React from 'react';
import MUIDataTable from 'mui-datatables';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import './Admin.css';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoadingButton from '../components/LoadingButton';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import SettingsList from '../components/SettingsList';
import writeToActivity from '../functions/WriteToActivity';
import {responsive} from '../utils/constants/muidatatablesConsts';
import LoadingCircle from "../components/common/LoadingCircle"

class AdminVendorSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdowns: null,
      rejects: [],
      isOpen: false,
      currentEntry: null,
      confirmDelete: false,
      isActive: true,
      isPageLoading: false,
      isLoading: false,
      clientInfo: null,
      autoNumbering: false,
      vlGenerate: false,
      isSaving: false,
      document_types: [],
    };
    this.makeTableData = this.makeTableData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    const requests = [
      {
        url: `${process.env.REACT_APP_API}/get-or-update-client-info`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          client_id: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/get-or-update-val-pair`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          client_id: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          get: true,
          type: 'Vendor',
          category: 'All',
        }),
      },
      {
        url: `${process.env.REACT_APP_API}/contract-settings`,
        body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: 'vendor',
        }),
      },
    ];

    Promise.all(
      requests.map((request) => {
        return fetch(request.url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
          },
          body: request.body,
        })
          .then((resp) => resp.json())
          .catch((err) => {
            this.props.fetchInitialData.createSnack(
              `Error: ${err}`,
              'error',
              3000
            );
          });
      })
    )
      .then((data) => {
        let clientInfo = data[0].data.clientData[0];
        const settingData = data[2];

        let rejectReasons = data[1].data;
        let rejects = [];
        if (Array.isArray(rejectReasons.dropdowns)) {
          rejects = rejectReasons.dropdowns.filter(
            (option) => option.R_KEY === 'rejectReason'
          );
        }

        let bool = false;
        let vlGenBool = false;
        if (clientInfo.erp_vendor_auto_numbering !== 0) {
          bool = true;
        }
        if (clientInfo.vl_vendor_number_generation !== 0) {
          vlGenBool = true;
        }

        this.setState({
          rejects: rejects,
          dropdowns: rejectReasons.dropdowns,
          isOpen: false,
          currentEntry: null,
          isNewEntry: false,
          confirmDelete: false,
          clientInfo,
          autoNumbering: bool,
          vlGenerate: vlGenBool,
          document_types: settingData.data.documentType,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  makeTableData(unfilteredRows, access) {
    return unfilteredRows.map((option, index) => {
      let reducedObj = [index + 1];
      let activeness;
      if (option.ACTIVE === 1) {
        activeness = 'Active';
      } else {
        activeness = 'Inactive';
      }
      reducedObj.push(option.R_VALUE);
      reducedObj.push(option.DESCRIPTION);
      reducedObj.push(activeness);
      reducedObj.push(
        <p
          onClick={() => {
            this.setState({
              isOpen: true,
              currentEntry: option,
            });
          }}
          className="editLink"
        >
          {access.edit ? 'edit' : 'view'}
        </p>
      );
      return reducedObj;
    });
  }
  handleFormSubmission = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    let vlGen = this.state.vlGenerate;

    if (this.state.autoNumbering) {
      vlGen = false;
    }

    let updatedInfo = Object.assign({}, this.state.clientInfo);
    updatedInfo['update'] = true;
    updatedInfo[
      'jsonWebTok'
    ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
    updatedInfo.erp_vendor_auto_numbering = this.state.autoNumbering;
    updatedInfo.vl_vendor_number_generation = vlGen;
    updatedInfo.no_approval_levels = event.target.noApprovalLevels.value;
    fetch(`${process.env.REACT_APP_API}/get-or-update-client-info`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(updatedInfo),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
        } else if (info.success) {
          let bool = false;
          let vlGenBool = false;
          if (info.data.clientData[0].erp_vendor_auto_numbering !== 0) {
            bool = true;
          }
          if (info.data.clientData[0].vl_vendor_number_generation !== 0) {
            vlGenBool = true;
          }

          this.setState({
            autoNumbering: bool,
            vlGenerate: vlGenBool,
            clientInfo: info.data.clientData[0],
            isSaving: false,
          });
          this.props.fetchInitialData.changeClientInfo(info.data.clientData[0]);
          this.props.fetchInitialData.createSnack(
            'Changes have been saved',
            'success',
            3000
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange() {
    var copyOfCurrentEntry = Object.assign({}, this.state.currentEntry);
    if (copyOfCurrentEntry.ACTIVE === 1) {
      copyOfCurrentEntry.ACTIVE = 0;
    } else {
      copyOfCurrentEntry.ACTIVE = 1;
    }
    this.setState({ currentEntry: copyOfCurrentEntry });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let copyOfCurrentEntryString;
    let activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'Setting',
      ACTIVITY_TYPE: 'Update',
      ACTIVITY_STRING:
        this.props.fetchInitialData.credentials.user.name +
        ' made and update to Approval Settings',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    if (this.state.confirmDelete) {
      let copyOfCurrentEntry = Object.assign({}, this.state.currentEntry);
      copyOfCurrentEntry['delete'] = true;
      copyOfCurrentEntry[
        'jsonWebTok'
      ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
      copyOfCurrentEntry[
        'client_id'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntry[
        'CLINET_ID'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntry['activity'] = activity;
      copyOfCurrentEntryString = JSON.stringify(copyOfCurrentEntry);
    } else if (this.state.isNewEntry) {
      const newObj = {
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        R_KEY: 'rejectReason',
        NEW_R_VALUE: event.target.codeValue.value,
        DESCRIPTION: event.target.codeDescription.value,
        new: true,
        ACTIVE: 1,
        activity: activity,
      };
      copyOfCurrentEntryString = JSON.stringify(newObj);
    } else {
      let copyOfCurrentEntry = Object.assign({}, this.state.currentEntry);
      copyOfCurrentEntry['NEW_R_VALUE'] = event.target.codeValue.value;
      copyOfCurrentEntry.DESCRIPTION = event.target.codeDescription.value;
      copyOfCurrentEntry['edit'] = true;
      copyOfCurrentEntry[
        'jsonWebTok'
      ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
      copyOfCurrentEntry[
        'client_id'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntry[
        'CLIENT_ID'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntry['activity'] = activity;
      copyOfCurrentEntryString = JSON.stringify(copyOfCurrentEntry);
    }

    fetch(`${process.env.REACT_APP_API}/get-or-update-val-pair`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: copyOfCurrentEntryString,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          this.setState({ isLoading: false, confirmDelete: false });
        } else if (info.success) {
          if (this.state.confirmDelete) {
            this.props.fetchInitialData.createSnack(
              'Successfully deleted value',
              'success',
              3000
            );
          } else if (this.state.isNewEntry) {
            this.props.fetchInitialData.createSnack(
              'Successfully added value',
              'success',
              3000
            );
          } else {
            this.props.fetchInitialData.createSnack(
              'Successfully updated value',
              'success',
              3000
            );
          }
          this.setState({
            isLoading: false,
            isOpen: false,
            currentEntry: null,
            isNewEntry: false,
            confirmDelete: false,
          });
          this.getData();
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error',
            'error',
            3000
          );
          this.setState({ isLoading: false, confirmDelete: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleCheckChange = (e, name) => {
    this.setState({ [name]: e.target.checked });
  };
  addNew = (callback, type, value, required) => {
    let fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      value: value,
      required: required,
      parentType: 'vendor',
    };

    fetch(`${process.env.REACT_APP_API}/update-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.e) {
          if (info.e.sqlState === '45000') {
            callback(402);
          } else {
            callback(400);
          }
        } else if (info.success) {
          callback(200);
          this.getData();
        } else {
          callback(400);
        }
      });
  };

  edit = (callback, type, id, value, required) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      id: id,
      value: value,
      action: 'edit',
      required: required,
    };
    fetch(`${process.env.REACT_APP_API}/edit-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.e) {
          if (info.e.sqlState === '45000') {
            callback(402);
          } else {
            callback(400);
          }
        } else if (info.success) {
          if (info.data.changes.field_changes) {
            const jsonChanges = JSON.parse(info.data.changes.field_changes);
            const activityString = jsonChanges.reduce(
              (string, change, index) => {
                return (
                  string +
                  `${change.field} from: ${change.before} to:${change.after}${
                    index + 1 === jsonChanges.length ? '' : ', '
                  }`
                );
              },
              `${this.props.fetchInitialData.credentials.user.name} updated ${type} settings information `
            );

            const activity = {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                .client_id,
              OBJ_TYPE: 'SETTINGS',
              OBJ_ID: `${type} settings`,
              ACTIVITY_TYPE: 'UPDATE',
              ACTIVITY_STRING: activityString,
              USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            };
            writeToActivity({ activity }, () => {
              callback(200);
              this.getData();
            });
          }
        } else {
          callback(400);
        }
      });
  };

  handleDelete = (callback, type, id) => {
    const fetchData = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      type: type,
      id: id,
      action: 'delete',
    };

    fetch(`${process.env.REACT_APP_API}/edit-contract-settings`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: JSON.stringify(fetchData),
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
        } else if (info.success) {
          if (info.data.results.error_flag === 1) {
            this.props.fetchInitialData.createSnack(
              info.data.results.error_message,
              'error',
              3000
            );
            callback(402);
          } else if (info.data.results.error_flag === 0) {
            this.getData();
            callback(200);
          }
        } else {
          callback(400);
        }
      });
  };
  checkAccess = () => {
    const { access } = this.props;
    return {
      view: access.settings_vendor_view,
      edit: access.settings_vendor_edit,
      add: access.settings_vendor_add,
      delete: access.settings_vendor_delete,
    };
  };
  render() {
    if (!this.state.dropdowns) {
      return (
        <LoadingCircle/>
      );
    }
    const access = this.checkAccess();
    const tableData = this.makeTableData(this.state.rejects, access);
    const showDelete = this.state.isNewEntry
      ? { display: 'none' }
      : { color: 'red' };

    let title = '';
    let codeValue = '';
    let codeDescription = '';
    let activeName = 'Active';
    let active = true;

    if (this.state.currentEntry) {
      const { R_VALUE, DESCRIPTION, ACTIVE } = this.state.currentEntry;
      codeValue = R_VALUE;
      codeDescription = DESCRIPTION;
      if (ACTIVE === 0) {
        active = false;
        activeName = 'Inactive';
      }
    }

    const columns = [
      {
        name: '#',
        label: '#',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'R_VALUE',
        label: 'Value',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'description',
        label: 'Description',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'active',
        label: 'Active',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'edit',
        label: 'Edit',
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    const sortedDocumentTypes = this.state.document_types
      .slice()
      .sort((a, b) => {
        if (b.description > a.description) return -1;
        else return 1;
      });

    return (
      <div>
        <div className="approvalSettings">
          <Dialog
            open={this.state.confirmDelete}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this option?
            </DialogContent>
            <DialogActions>
              <form onSubmit={this.handleSubmit}>
                <Button
                  onClick={() => {
                    this.setState({
                      confirmDelete: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  label="Confirm"
                  isLoading={this.state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </form>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.isOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.handleSubmit}>
              <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="codeValue"
                      label="Value"
                      fullWidth
                      defaultValue={codeValue}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="codeDescription"
                      label="Description"
                      fullWidth
                      defaultValue={codeDescription}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Checkbox
                      checked={active}
                      style={{ color: this.props.fetchInitialData.credentials.primaryAppColor}}
                      onChange={() => this.handleChange()}
                      value="checkedA"
                    />
                    <span style={{ fontSize: '0.8rem' }}>{activeName}</span>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
              <Button
                  onClick={() => {
                    this.setState({
                      isOpen: false,
                      currentEntry: null,
                      isNewEntry: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                {!!access.delete && (
                  <Button
                    style={showDelete}
                    onClick={() => {
                      this.setState({ confirmDelete: true });
                    }}
                  >
                    Delete
                  </Button>
                )}
                {!!access.edit && (
                  <LoadingButton
                    label="Submit"
                    isLoading={this.state.isLoading}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                )}
              </DialogActions>
            </form>
          </Dialog>
          <div className="rejectContainer">
            <div style={{ marginBottom: '1.5rem' }}>
              <div style={{ padding: '16px' }}>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <h5 style={{ marginBottom: '1rem', textAlign: 'center' }}>
                      Vendor Approval Settings
                    </h5>
                    <Paper style={{ padding: '16px' }} elevation={2}>
                      <form onSubmit={this.handleFormSubmission}>
                        <TextField
                          id="noApprovalLevels"
                          disabled={!access.edit}
                          label="# of Approval Levels"
                          fullWidth
                          defaultValue={
                            this.state.clientInfo.no_approval_levels
                          }
                          margin="dense"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span />
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="off"
                        />

                        <TextField
                          label="Vendor Search By:"
                          select
                          disabled={!access.edit}
                          fullWidth
                          onChange={(e) => {
                            const copyOfClientInfo = Object.assign(
                              {},
                              this.state.clientInfo,
                              { vendor_numbering_scheme: e.target.value }
                            );
                            this.setState({ clientInfo: copyOfClientInfo });
                          }}
                          value={this.state.clientInfo.vendor_numbering_scheme}
                          margin="dense"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span />
                              </InputAdornment>
                            ),
                          }}
                        >
                          {schemes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        {!!access.edit && (
                          <div style={{ marginTop: '8px' }}>
                            <LoadingButton
                              label="Update"
                              isLoading={this.state.isSaving}
                              color="primaryVLButton"
                              buttonType="submit"
                            />
                          </div>
                        )}
                      </form>
                    </Paper>

                    {/* <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body1">
                          Does your ERP system have auto vendor number creation?
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.autoNumbering}
                              disabled={!access.edit}
                              onChange={(e) =>
                                this.handleCheckChange(e, 'autoNumbering')
                              }
                              value="autoNumbering"
                              classes={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                track: classes.colorBar,
                              }}
                            />
                          }
                          label={yesNo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={showVlGeneration}>
                          <Typography variant="body1">
                            Does you want VendorLaunch to generate vendor id's?
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!access.edit}
                                checked={this.state.vlGenerate}
                                onChange={(e) =>
                                  this.handleCheckChange(e, 'vlGenerate')
                                }
                                value="vlGenerate"
                                classes={{
                                  switchBase: classes.colorSwitchBase,
                                  checked: classes.colorChecked,
                                  track: classes.colorBar,
                                }}
                              />
                            }
                            label={yesNoGenerate}
                          />
                        </div>
                      </Grid> */}
                  </Grid>

                  <Grid item xs={12} sm={6} lg={6}>
                    <SettingsList
                      type="document"
                      idPropertyName="document_type_id"
                      lookupProperty="description"
                      addNew={this.addNew}
                      edit={this.edit}
                      delete={this.handleDelete}
                      title="Vendor Document Types"
                      list={sortedDocumentTypes}
                      access={access}
                      hasRequired={true}
                      fetchInitialData={this.props.fetchInitialData}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>



            <MUIDataTable
              title={'Rejection Reasons'}
              data={tableData}
              columns={columns}
              options={{
                selectableRows: false,
                filterType: 'dropdown',
                responsive,
                print: false,
              }}
            />
            {!!access.add && (
              <Button
                style={{
                  marginTop: '1rem',
                }}
                className="primaryVLButton"
                onClick={() =>
                  this.setState({
                    isOpen: true,
                    isNewEntry: true,
                  })
                }
              >
                + Add Reason
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
  colorSwitchBase: {
    color: 'rgb(232,102,19)',
    '&$colorChecked': {
      color: 'rgb(232,102,19)',
      '& + $colorBar': {
        backgroundColor: 'rgb(232,102,19)',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

AdminVendorSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminVendorSettings);

const schemes = [
  {
    value: 'ERP',
    label: 'ERP',
  },
  {
    value: 'VL',
    label: 'Vendor Launch ID',
  },
];
