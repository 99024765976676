import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

let pages = [
  {
    title: 'Home',
    route: '/vendor'
  },
  
  {
    title: 'Contracts',
    route: '/vcontracts'
  },
  {
    title: 'Invoices',
    route: '/vinvoices'
  },
  {
    title: 'Pos',
    route: '/vpos'
  },
  {
    title: 'Profile',
    route: '/vendordetails'
  }
]

export default function MenuDrawerVendor(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
      <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {props.pages.map((page) => (
              <ListItem button onClick={() => props.history.push(page.route)}
                        key={page.title}>
                <ListItemText primary={page.title}/>
              </ListItem>
          ))}
        </List>
      </div>
  );

  return (
      <div>
        <React.Fragment key='left'>
          <IconButton
              data-toggle="collapse"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              size="small"
              className="noFocus navbar-toggler"
              style={{ position: 'absolute', top: '5px' }}
              onClick={toggleDrawer('left', true)}
          >
            <MenuIcon size="small"/>
          </IconButton>
          <Drawer anchor='left' open={state.left} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
        </React.Fragment>
      </div>
  );
}
