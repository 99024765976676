import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { debounce } from 'throttle-debounce';
import { CircularProgress, Chip, MenuItem, Paper, TextField, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

class SuggestionSql extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      inputValue: this.props.defaultValue || '',
      suggestions: [],
      selectedItem: [],
      loading: false,
      notFound: false,
    };
    this.autocompleteSearchDebounced = debounce(200, this.autocompleteSearch);
    this.handleBlur = this.handleBlur.bind(this);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  focusTextInput() {
    this.textInput.current.focus();
  }

  close() {
    this.setState({ isOpen: false });
  }

  getVendorNameWithoutId = (str) => (
      str.indexOf('(') !== -1 ? str.slice(0, str.indexOf('(')).trim() : str.trim()
  );

  handleInputChange = (event) => {
    event.preventDefault();
    let input = event.target.value;
    this.setState({ inputValue: event.target.value }, () => {
      this.autocompleteSearchDebounced(this.state.inputValue);
    });
    this.props.handleChange(input, event.target.value);
  };

  autocompleteSearch = (input) => {
    if (input.length < 3 && !parseInt(input)) {
      this.setState({ suggestions: [], notFound: false });
      return null;
    }

    let fetchString = JSON.stringify({
      clientId: this.props.clientId,
      lookup: this.getVendorNameWithoutId(input),
      limitNum: 5,
    });
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_API}/autocomplete-sql`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `bearer ${this.props.jsonWebTok}`
      },
      body: fetchString,
    })
        .then((resp) => resp.json())
        .then((info) => {
          this.setState({ loading: false })
          this.focusTextInput();
          if (!info.success) return null
          const data = info.data
          if (data.suggestions.length === 0) {
            this.setState({ suggestions: [], isOpen: true, notFound: true });
          } else if (data.suggestions.length > 0) {
            const suggestions = data.suggestions.map((hit) => {
              if(hit.suggestion  === input && data.suggestions.length === 1) {
                this.props.onEqualName(hit.id, hit.suggestion, input);
              }
              if (hit.suggestion === input.trim() && data.suggestions.length === 1) {
                this.props.onEqualName(hit.id, hit.suggestion, input);
              }
              return {
                id: hit.id,
                hit: hit.suggestion,
              };
            });
            this.setState({
              isOpen: true,
              suggestions,
              notFound: false,
            });
          } else {
            this.setState({
              isOpen: true,
              suggestions: [],
              notFound: true,
            });
          }
        })
        .catch((err) => console.log(err));
  };

  handleBlur() {
    this.setState({ suggestions: [], isOpen: false });
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <>
          <TextField
              style={{ width: this.props.fullWidth ? '' : '270px' }}
              required={this.props.required}
              variant={this.props.variant}
              margin="dense"
              error={this.state.notFound}
              helperText={`${((this.state.inputValue && this.state.inputValue.length < 3 && !parseInt(this.state.inputValue))
                  ? 'Type at least 3 characters' : '') || (this.state.notFound ? 'No vendor found' : '')}`}
              fullWidth={this.props.fullWidth}
              disabled={this.state.loading}
              onKeyDown={
                (e) => {
                  if (this.state.suggestions.length && e.keyCode === 40) {
                  }
                }
              }
              InputProps={{
                inputRef: this.textInput,
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                ...InputProps,
              }}
              {...other}
          />
          {this.state.inputValue && !this.state.loading && (
              <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => this.setState({ inputValue: '', suggestions: [], isOpen: false })}
              />
          )}

        </>
    );
  }

  render() {
    const { classes } = this.props;
    const { inputValue, selectedItem } = this.state;
    return (
        <Downshift
            id="downshift-multiple"
            inputValue={inputValue}
            onChange={this.handleChange}
            selectedItem={selectedItem}
        >
          {({ getInputProps }) => (
              <span className={classes.container}>
            {this.renderInput({
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItem.map((item) => (
                    <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        className={classes.chip}
                        onDelete={this.handleDelete(item)}
                    />
                )),
                onBlur: this.handleBlur,
                onChange: this.handleInputChange,
                placeholder: this.props.placeholder,
              }),
              label: this.props.label,
            })}
                {this.state.loading && (
                    <CircularProgress
                        className={classes.spinner}
                        size="20px"
                    />
                )}
                {this.state.isOpen && (
                    <Paper className={classes.paper} square>
                      {this.state.suggestions.map((sug) => {
                        return (
                            <MenuItem
                                key={sug.id}
                                onMouseDown={() => {
                                  this.props.handleChange(
                                      sug.id,
                                      this.state.inputValue,
                                      true,
                                      sug.hit
                                  );
                                  this.setState({ inputValue: sug.hit, isOpen: false });
                                }}
                            >
                              <span>{sug.hit}</span>
                            </MenuItem>
                        );
                      })}
                    </Paper>
                )}
          </span>
          )}
        </Downshift>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    width: theme.spacing(45),
    marginTop: 0,
    left: 0,
    right: 0,
    borderRadius: '3px',
    overflowY: 'auto',
    maxHeight: '205px',
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    bottom: -10,
    right: 10,
    cursor: 'pointer',
    border: '1px solid gray',
    borderRadius: '10px',
  },
  spinner: {
    marginLeft: '10px',
    color: '#545454',
    position: 'absolute',
    right: 10,
    bottom: -13,
  }
});

SuggestionSql.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SuggestionSql);
