import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import './Admin.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from 'mui-datatables';
import LoadingButton from '../components/LoadingButton';
import {responsive} from '../utils/constants/muidatatablesConsts';
import LoadingCircle from "../components/common/LoadingCircle"

class AdminCodesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isNewEntry: false,
      currentEntry: null,
      tableData: null,
      expanded: null,
      confirmDelete: false,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.makePanel = this.makePanel.bind(this);
    this.newEntry = this.newEntry.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    let form = {
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
      get: true,
      type: 'Client',
      category: 'All'
    };
    const jsonClientInfo = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_API}/get-or-update-val-pair`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonClientInfo,
    })
      .then((resp) => resp.json())
      .then((info) => {
        const data = info.data;
        const codes = [
          'termCode',
          'invCurrency',
          'languageCode',
          'vendorGroup',
          'venClass',
          'taxCode',
          'holdCode',
          'distributionCode',
          'accrualCode',
          'paymentCode',
          'cashCode',
          'uom',
        ];
        const dataTablesColumns = {};
        codes.map((name) => {
          const listOfCodes = data.dropdowns.filter(
              (code) => code.R_KEY === name
          );
          const dataColumns = listOfCodes.map((option) => {
            let reducedObj = [];
            let activeness;
            if (option.ACTIVE === 1) {
              activeness = 'Active';
            } else {
              activeness = 'Inactive';
            }
            reducedObj.push(option.R_VALUE);
            reducedObj.push(option.DESCRIPTION);
            reducedObj.push(activeness);
            reducedObj.push(
                <p
                    onClick={() => {
                      this.setState({
                        isOpen: true,
                        currentEntry: option,
                      });
                    }}
                    className="editLink"
                >
                  {this.props.access.settings_codes_edit ? 'edit' : 'view'}
                </p>
            );
            return reducedObj;
          });
          dataTablesColumns[name] = dataColumns;
          return dataColumns;
        });

        this.setState({
          tableData: dataTablesColumns,
          isOpen: false,
          currentEntry: null,
          isNewEntry: false,
          confirmDelete: false,
        });
      })
      .catch((error) => {
        this.props.fetchInitialData.createSnack(
          `Error: ${error}`,
          'error',
          3000
        );
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let copyOfCurrentEntryString;
    let copyOfCurrentEntry = Object.assign({}, this.state.currentEntry);
    copyOfCurrentEntry['activity'] = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'Setting',
      ACTIVITY_TYPE: 'Update',
      ACTIVITY_STRING:
        this.props.fetchInitialData.credentials.user.name +
        ' made and update to Code Settings',
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };

    if (this.state.confirmDelete) {
      copyOfCurrentEntry['delete'] = true;
      copyOfCurrentEntry[
        'jsonWebTok'
      ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
      copyOfCurrentEntry[
        'client_id'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntryString = JSON.stringify(copyOfCurrentEntry);
    } else if (this.state.isNewEntry) {
      copyOfCurrentEntry['NEW_R_VALUE'] = event.target.codeValue.value;
      copyOfCurrentEntry.DESCRIPTION = event.target.codeDescription.value;
      copyOfCurrentEntry['new'] = true;
      copyOfCurrentEntry['R_KEY'] = this.state.isNewEntry;
      copyOfCurrentEntry[
        'jsonWebTok'
      ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
      copyOfCurrentEntry[
        'client_id'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntryString = JSON.stringify(copyOfCurrentEntry);
    } else {
      copyOfCurrentEntry['NEW_R_VALUE'] = event.target.codeValue.value;
      copyOfCurrentEntry.DESCRIPTION = event.target.codeDescription.value;
      copyOfCurrentEntry['edit'] = true;
      copyOfCurrentEntry[
        'jsonWebTok'
      ] = this.props.fetchInitialData.credentials.user.jsonWebTok;
      copyOfCurrentEntry[
        'client_id'
      ] = this.props.fetchInitialData.credentials.clientInfo.client_id;
      copyOfCurrentEntryString = JSON.stringify(copyOfCurrentEntry);
    }
    fetch(`${process.env.REACT_APP_API}/get-or-update-val-pair`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: copyOfCurrentEntryString,
    })
      .then((resp) => resp.json())
      .then((info) => {
        if (info.error) {
          this.props.fetchInitialData.createSnack(info.error, 'error', 3000);
          this.setState({ isLoading: false });
        } else if (info.success) {
          if (this.state.confirmDelete) {
            this.props.fetchInitialData.createSnack(
              'Successfully deleted code',
              'success',
              3000
            );
          } else if (this.state.isNewEntry) {
            this.props.fetchInitialData.createSnack(
              'Successfully added code',
              'success',
              3000
            );
          } else {
            this.props.fetchInitialData.createSnack(
              'Successfully updated code',
              'success',
              3000
            );
          }
          this.setState({ isLoading: false, isOpen: false, confirmDelete: false });
          this.getData();
        } else {
          this.props.fetchInitialData.createSnack(
            'There was an error',
            'error',
            3000
          );
          this.setState({ isLoading: false, confirmDelete: false});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange() {
    const copyOfCurrentEntry = Object.assign({}, this.state.currentEntry);
    if (copyOfCurrentEntry.ACTIVE === 1) {
      copyOfCurrentEntry.ACTIVE = 0;
    } else {
      copyOfCurrentEntry.ACTIVE = 1;
    }
    this.setState({ currentEntry: copyOfCurrentEntry });
  }
  handlePanelChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  newEntry(data) {
    this.setState({
      isOpen: true,
      isNewEntry: data,
      currentEntry: {
        ACTIVE: 1,
        CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      },
    });
  }
  makePanel(key, header, data, index) {
    return (
      <div className="makePanel">
        <Accordion
          expanded={this.state.expanded === index}
          onChange={this.handlePanelChange(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">{header}</Typography>
          </AccordionSummary>

          <div className="innerPanel">
            <MUIDataTable
              data={data}
              columns={cols}
              options={{
                selectableRows: false,
                filterType: 'dropdown',
                responsive,
                print: false,
              }}
            />
            {!!this.props.access.settings_codes_add && (
              <Button
              style={{
                marginTop: '1rem',
              }}
              className="primaryVLButton"
              onClick={() => this.newEntry(key)}
            >
              + Add to {header}
            </Button>
            )}

          </div>
        </Accordion>
      </div>
    );
  }
  render() {
    if (!this.state.tableData) {
      return (
        <div style={{ height: '100vh' }}>
          <LoadingCircle/>
        </div>
      );
    }
    let title = '';
    let codeValue = '';
    let codeDescription = '';
    let active = true;
    if (this.state.currentEntry) {
      const { R_KEY, R_VALUE, DESCRIPTION, ACTIVE } = this.state.currentEntry;
      codeValue = R_VALUE;
      codeDescription = DESCRIPTION;
      if (ACTIVE === 0) {
        active = false;
      }
      switch (R_KEY) {
        case 'accrualCode':
          title = 'Edit Accrual Code';
          break;
        case 'cashCode':
          title = 'Edit Cash Code';
          break;
        case 'distributionCode':
          title = 'Edit Distribution Code';
          break;
        case 'holdCode':
          title = 'Edit Hold Code';
          break;
        case 'invCurrency':
          title = 'Edit Invoice Currency';
          break;
        case 'languageCode':
          title = 'Edit Language Code';
          break;
        case 'paymentCode':
          title = 'Edit Payment Code';
          break;
        case 'taxCode':
          title = 'Edit Tax Code';
          break;
        case 'termCode':
          title = 'Edit Term Code';
          break;
        case 'venClass':
          title = 'Edit Vendor Class';
          break;
        case 'vendorGroup':
          title = 'Edit Vendor Group';
          break;
        case 'uom':
          title = 'Edit Unit of Measure';
          break;
        default:
          title = 'Edit Code';
      }
    }

    if (this.state.isNewEntry) {
      switch (this.state.isNewEntry) {
        case 'accrualCode':
          title = 'Add New Accrual Code';
          break;
        case 'cashCode':
          title = 'Add New Cash Code';
          break;
        case 'distributionCode':
          title = 'Add New Distribution Code';
          break;
        case 'holdCode':
          title = 'Add New Hold Code';
          break;
        case 'invCurrency':
          title = 'Add New Invoice Currency';
          break;
        case 'languageCode':
          title = 'Add New Language Code';
          break;
        case 'paymentCode':
          title = 'Add New Payment Code';
          break;
        case 'taxCode':
          title = 'Add New Tax Code';
          break;
        case 'termCode':
          title = 'Add New Term Code';
          break;
        case 'venClass':
          title = 'Add New Vendor Class';
          break;
        case 'vendorGroup':
          title = 'Add New Vendor Group';
          break;
        case 'uom':
          title = 'Add Unit of Measure';
          break;
        default:
          title = 'Add New Code';
      }
    }
    const showDelete = this.state.isNewEntry
        ? { display: 'none' }
        : { padding: '6px 16px', color: 'red' };

    return (
      <div>
        <div>
          <Dialog
            open={this.state.confirmDelete}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this option?
            </DialogContent>
            <DialogActions>
              <form onSubmit={this.handleSubmit}>
                <Button
                  onClick={() => {
                    this.setState({
                      confirmDelete: false,
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  label="Confirm"
                  isLoading={this.state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </form>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.handleSubmit}>
              <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

              <DialogContent>
                <Grid container justify="flex-start" spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="codeValue"
                      label="Value"
                      fullWidth
                      defaultValue={codeValue}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="codeDescription"
                      label="Description"
                      fullWidth
                      defaultValue={codeDescription}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Checkbox
                      checked={active}
                      style={{ color: this.props.fetchInitialData.credentials.primaryAppColor }}
                      onChange={() => this.handleChange()}
                      value="checkedA"
                    />
                    <span style={{ fontSize: '0.8rem' }}>Active</span>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
              {!!this.props.access.settings_codes_delete && (

                <Button
                  style={showDelete}
                  onClick={() => {
                    this.setState({ confirmDelete: true });
                  }}
                >
                  Delete
                </Button>
              )}
                <Button
                  onClick={() => {
                    this.setState({
                      isOpen: false,
                      currentEntry: null,
                      isNewEntry: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                {!!this.props.access.settings_codes_edit && (

                <LoadingButton
                  label="Submit"
                  isLoading={this.state.isLoading}
                  color="primaryVLButton"
                  buttonType="submit"
                />
                )}
              </DialogActions>
            </form>
          </Dialog>
        </div>
        <div className="rejectContainer">
          <h3>Client Codes</h3>
          {this.makePanel(
            'termCode',
            'Terms',
            this.state.tableData.termCode,
            1
          )}
          {this.makePanel(
            'invCurrency',
            'Invoice Currency',
            this.state.tableData.invCurrency,
            2
          )}
          {this.makePanel(
            'languageCode',
            'Languages',
            this.state.tableData.languageCode,
            3
          )}
          {this.makePanel(
            'vendorGroup',
            'Vendor Groups',
            this.state.tableData.vendorGroup,
            4
          )}
          {this.makePanel(
            'venClass',
            'Vendor Classes',
            this.state.tableData.venClass,
            5
          )}
          {this.makePanel(
            'taxCode',
            'Tax Codes',
            this.state.tableData.taxCode,
            6
          )}
          {this.makePanel(
            'holdCode',
            'Hold Codes',
            this.state.tableData.holdCode,
            7
          )}
          {this.makePanel(
            'distributionCode',
            'Distribution Codes',
            this.state.tableData.distributionCode,
            8
          )}
          {this.makePanel(
            'accrualCode',
            'Accrual Codes',
            this.state.tableData.accrualCode,
            9
          )}
          {this.makePanel(
            'paymentCode',
            'Payment Codes',
            this.state.tableData.paymentCode,
            10
          )}
          {this.makePanel(
            'cashCode',
            'Cash Codes',
            this.state.tableData.cashCode,
            11
          )}
          {this.makePanel(
            'uom',
            'Invoice Unit of Measure',
            this.state.tableData.uom,
            12
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    borderBottom: '0 solid #e8e8e8',
  },
  tabsIndicator: {
    color: 'rgb(232,102,19)',
    backgroundColor: 'rgb(232,102,19)',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: 'rgb(232,102,19)',
      opacity: 1,
    },
  },
});

AdminCodesSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminCodesSettings);

const cols = [
  {
    name: 'R_VALUE',
    label: 'Value',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      filterList: ['Active']
    },
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
    },
  },
]