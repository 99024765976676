export const pages = [
  {
    title: 'Home',
    route: '/home',
    view: "home"
  },
  {
    title: 'Vendors',
    route: '/allvendors',
    tabAccess: 'vendor_tab',
    view: "vendors",
  },
  {
    title: 'Contracts',
    route: '/contracts',
    tabAccess: 'contract_tab',
    view: "contracts",
    license: "contract_license"
  },
  {
    title: 'Invoices',
    route: '/invoices',
    tabAccess: 'invoice_tab',
    view: "invoices",
    license: "invoice_license"
  },
  {
    title: 'Purchase Requests',
    route: '/purchaserequests',
    tabAccess: 'purchase_tab',
    view: "purchaserequests",
    license: "po_license"
  },
  {
    title: 'Reports',
    route: '/reports',
    tabAccess: 'report_tab',
    view: "reports",
  }
]

export const clientRoutes = {
  'home': 'home',
  'allvendors': 'vendors',
  'viewvendordetails': 'vendors',
  'contracts': 'contracts',
  'contract-details': 'contracts',
  'invoices': 'invoices',
  'invoice-details': 'invoices',
  'purchaserequests': 'purchaserequests',
  'purchasing-request-details': 'purchaserequests',
  'purchasing-request-approval-details': 'purchaserequests',
  'reports': 'reports',
  'profile': 'profile',
  'adminsettings': 'settings',
  'docuemnt-details': null
}

export const vendorMainPages = [
  {
    title: 'Home',
    route: '/vendor',
    view: "vendor"
  },
  {
    title: 'Contracts',
    route: '/vcontracts',
    tabAccess: 'contract_tab',
    view: "vcontracts",
    license: "contract_license"
  },
  {
    title: 'Invoices',
    route: '/vinvoices',
    tabAccess: 'invoice_tab',
    view: "vinvoices",
    license: "invoice_license"
  },
  {
    title: 'POs',
    route: '/vpos',
    tabAccess: 'purchase_tab',
    view: 'vpos',
    license: "po_license"
  },

  {
    title: 'My Vendor Profile',
    route: '/vendordetails',
    tabAccess: 'vendor_tab',
    view: "vendordetails",
  },
  // {
  //   title: 'Reports',
  //   route: '/vreports',
  //   view: 'reports',
  // }
];

export const vendorSecondaryPages = [
  {
    title: 'My Account',
    route: '/profile',
    view: "profile",
  },
  {
    title: 'Settings',
    route: '/vendor-user-maintenance',
    tabAccess: 'settings_vendoruser_view',
    view: "settings",
  },
  {
    title: 'Help',
    route: '/vendor',
    view: 'home',
  },
]

export const vendorRoutes = {
  'vendor': 'vendor',
  'vcontracts': 'vcontracts',
  'contract-details': 'vcontracts',
  'vinvoices': 'vinvoices',
  'invoice-details': 'vinvoices',
  'vpos': 'vpos',
  'po-vendor-details': 'vpos',
  'vendordetails': 'vendordetails',
  'vendor-user-maintenance': 'settings',
  'profile': 'profile',
  'docuemnt-details': null
}