import React from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import moment from "moment-timezone";
import { vendorPurchaseRequests } from "../utils/constants/purchaseRequestColumns";
import VendorStatus from "../functions/VendorStatus";
import { responsive } from "../utils/constants/muidatatablesConsts";
import { customTableSort, formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = () => ({
  invoicesContainer: {
    margin: "0 auto",
    maxWidth: 900,
    padding: "2rem",
    "@media (max-width: 700px)": {
      padding: "0.1rem",
    },
  },
  nonActive: {
    border: "solid #E86613 2px",
    padding: "1rem",
    borderRadius: 10,
    backgroundColor: "#FF9F61",
    margin: "1rem 1rem 0 1rem",
  },
});

class VendorPos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: null,
      tableData: null,
    };
  }

  getPos() {
    fetch(`${process.env.REACT_APP_API}/vendor-landing-page`, {
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
    })
      .then((resp) => resp.json())
      .then((info) => {
        this.setState({
          pos: info.data.content.purchasing,
          tableData: this.makeTableData(info.data.content.purchasing),
        });
      })
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getPos();
  }

  makeTableData = (data) =>
    data.map((po) => [
      <div
        className="editLink"
        onClick={() =>
          this.props.history.push(`/po-vendor-details/${po.PO_ID}`)
        }
      >
        {po.PO_NUMBER}
      </div>,
      moment.utc(po.DELIVERY_DATE).format("MM/DD/YYYY"),
      moment.utc(po.CREATE_DATE).format("MM/DD/YYYY"),
      po.LOCATION_ID,
      formatter.format(po.EXT_AMOUNT),
      po.APPROVAL_STATUS_LEVEL,
      VendorStatus(po.PO_STATUS),
    ]);

  render() {
    const { classes } = this.props;

    return this.state.pos ? (
      <Grid item xs={12} sm={12} md={12} className={classes.invoicesContainer}>
        <MUIDataTable
          title={"Your Pos"}
          data={this.state.tableData}
          columns={vendorPurchaseRequests}
          options={{
            customSort: (data, colIndex, order) =>
              customTableSort(data, colIndex, order, 4),
            customSearch: () => true,
            onSearchChange: (query) => {
              this.setState({
                tableData: this.makeTableData(
                  this.state.pos.filter((po) => {
                    if (query) {
                      return (
                        po.PO_NUMBER.toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        moment
                          .utc(po.DELIVERY_DATE)
                          .format("MM/DD/YYYY")
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        moment
                          .utc(po.CREATE_DATE)
                          .format("MM/DD/YYYY")
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        formatter
                          .format(po.EXT_AMOUNT)
                          .toString()
                          .includes(query.toLowerCase().trim()) ||
                        po.EXT_AMOUNT.toString().includes(
                          query.toLowerCase().trim()
                        ) ||
                        po.APPROVAL_STATUS_LEVEL.toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        VendorStatus(po.PO_STATUS)
                          .toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim()) ||
                        po.LOCATION_ID.toString()
                          .toLowerCase()
                          .includes(query.toLowerCase().trim())
                      );
                    }
                    return po;
                  })
                ),
              });
            },
            onSearchClose: () => {
              this.setState({
                tableData: this.makeTableData(this.state.pos),
              });
            },
            selectableRows: false,
            filterType: "dropdown",
            responsive,
            print: false,
          }}
        />
      </Grid>
    ) : (
      <LoadingCircle/>
    );
  }
}

export default withStyles(styles)(VendorPos);
