import React from "react";
import { NumberFormatCustom } from "../../utils/numberFormatUtils";
import { TextField } from "@material-ui/core";

class BasicField extends React.Component {
  render() {
    const { id, label, type, val, onChange, inputProps } = this.props;
    if (type === 'decimal') inputProps['inputComponent'] = NumberFormatCustom;
    return (
        <TextField
            id={id}
            label={label}
            margin="dense"
            type={type}
            value={val}
            onChange={(e) => onChange(id, e.target.value)}
            InputProps={inputProps}
        />
    )
  }
}

export default BasicField;
