import React, { Component } from 'react';
import '../containers/VendorForm.css';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingButton from '../components/LoadingButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import writeToActivity from '../functions/WriteToActivity';
import { withSnackbar } from 'notistack';
import InputMask from 'react-input-mask';
import LambdaFetch from '../functions/FetchFromLambda';
import { contactsColumns } from '../utils/constants/contactColums';
import {responsive} from '../utils/constants/muidatatablesConsts';

const styles = {
  checked: {},
  resize: {
    fontSize: 13,
  },
  textFieldRoot: {
    height: '40px',
  },
};

class ClientContactMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addingNewContact: false,
      isEdit: false,
      editContact: null,
      isSaving: false,
      showInactive: false,
    };
  }

  createSnack(message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: type,
      autoHideDuration: duration,
    });
  }

  handleClose = () => {
    this.setState({
      addingNewContact: false,
      isEdit: false,
      editContact: null,
    });
  };
  handleChange = (event) => {
    this.setState({ locationType: event.target.value });
  };
  handleCountryStateZipChange = (type, value) => {
    this.setState({ [type]: value });
  };
  handleNew = async (event) => {
    event.preventDefault();

    this.setState({ isSaving: true });
    let newContact = {
      name: event.target.newName.value,
      email: event.target.newEmail.value,
      phone: event.target.newPhone.value,
      mobile: event.target.newMobile.value,
      fax: event.target.newFax.value,
      job: event.target.newJob.value,
    };
    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'add',
      vendorId: this.props.vendorId,
      newContact,
    };
    let contactName = event.target.newName.value;

    await LambdaFetch(
      'add-update-contact',
      'post',
      this.props.jsonWebTok,
      JSON.stringify(fetchData),
      'Successfully created new contact'
    );

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.props.vendorId,
      ACTIVITY_TYPE: 'CREATE',
      ACTIVITY_STRING: `Created contact${
        contactName ? `: ${contactName}` : ``
      }`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    writeToActivity({ activity }, () => {
      this.setState({ isSaving: false, addingNewContact: false }, () => {
        this.props.getData();
      });
    });
  };
  handleEdit = async (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    let editContact = {
      name: event.target.editName.value,
      email: event.target.editEmail.value,
      phone: event.target.editPhone.value,
      mobile: event.target.editMobile.value,
      fax: event.target.editFax.value,
      job: event.target.editJob.value,
    };
    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'edit',
      vendorId: this.props.vendorId,
      contactId: this.state.editContact.CONTACT_ID,
      editContact,
    };
    let contactName = this.state.editContact.NAME;

    await LambdaFetch(
      'add-update-contact',
      'post',
      this.props.jsonWebTok,
      JSON.stringify(fetchData),
      'Successfully edited contact'
    );

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.props.vendorId,
      ACTIVITY_TYPE: 'UPDATE',
      ACTIVITY_STRING: `Updated contact${
        contactName ? `: ${contactName}` : ``
      }`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    writeToActivity({ activity }, () => {
      this.setState(
        { isSaving: false, isEdit: false, editContact: null },
        () => {
          this.props.getData();
        }
      );
    });
  };
  handleActivate = async () => {
    this.setState({ isActivating: true });

    const fetchData = {
      jsonWebTok: this.props.jsonWebTok,
      action: 'activate',
      vendorId: this.props.vendorId,
      contactId: this.state.editContact.CONTACT_ID,
      inactiveFlag: this.state.editContact.INACTIVE_FLAG === 0 ? 1 : 0,
    };

    let contactName = this.state.editContact.NAME;
    let contactAction =
      this.state.editContact.INACTIVE_FLAG === 0 ? 'Inactived' : 'Reactivated';

    await LambdaFetch(
      'add-update-contact',
      'post',
      this.props.jsonWebTok,
      JSON.stringify(fetchData),
      `Successfully ${contactAction} contact: ${contactName}`
    );

    const activity = {
      CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo.client_id,
      OBJ_TYPE: 'VENDOR',
      OBJ_ID: this.props.vendorId,
      ACTIVITY_TYPE: contactAction.toUpperCase(),
      ACTIVITY_STRING: `${contactAction} contact${
        contactName ? `: ${contactName}` : ``
      }`,
      USER_ID: this.props.fetchInitialData.credentials.user.uuid,
    };
    writeToActivity({ activity }, () => {
      this.setState(
        { isActivating: false, isEdit: false, editContact: null },
        () => {
          this.props.getData();
        }
      );
    });
  };

  createTextField(id, label, required, type, defaultValue) {
    const { classes } = this.props;
    return (
      <TextField
        id={id}
        required={required}
        fullWidth
        defaultValue={defaultValue}
        type={type ? type : ''}
        className="textfield"
        label={label}
        variant="filled"
        margin="dense"
        InputProps={{
          classes: {
            input: classes.resize,
            root: classes.textFieldRoot,
          },
        }}
      />
    );
  }

  createMaskedTextField(id, label, required, mask, defaultValue) {
    const { classes } = this.props;

    return (
      <InputMask mask={mask} defaultValue={defaultValue}>
        {() => (
          <TextField
            id={id}
            required={required}
            fullWidth
            variant="filled"
            label={label}
            margin="dense"
            InputProps={{
              classes: {
                input: classes.resize,
                root: classes.textFieldRoot,
              },
            }}
            autoComplete="off"
          />
        )}
      </InputMask>
    );
  }

  render() {
    const { classes } = this.props;

    let filteredContacts;
    if (this.state.showInactive) {
      filteredContacts = this.props.contacts
        .filter((l) => l.INACTIVE_FLAG === 1)
        .map((contact) => {
          return [
            contact.NAME,
            contact.E_MAIL_ADDRESS,
            contact.PHONE_NMBR ? contact.PHONE_NMBR : 'N/A',
            contact.MOBILE_NUM ? contact.MOBILE_NUM : 'N/A',
            contact.FAX_NMBR ? contact.FAX_NMBR : 'N/A',
            contact.JOB_TITLE ? contact.JOB_TITLE : 'N/A',
            <div
              className="editLink"
              onClick={() =>
                this.setState({ isEdit: true, editContact: contact })
              }
            >
              {this.props.access.vendor_contact_edit ? 'edit' : 'view'}
            </div>,
          ];
        });
    } else {
      filteredContacts = this.props.contacts
        .filter((l) => l.INACTIVE_FLAG === 0)
        .map((contact) => [
              contact.NAME,
              contact.E_MAIL_ADDRESS,
              contact.PHONE_NMBR ? contact.PHONE_NMBR : 'N/A',
              contact.MOBILE_NUM ? contact.MOBILE_NUM : 'N/A',
              contact.FAX_NMBR ? contact.FAX_NMBR : 'N/A',
              contact.JOB_TITLE ? contact.JOB_TITLE : 'N/A',
              <div
                  className="editLink"
                  onClick={() => this.setState({ isEdit: true, editContact: contact })}
              >
                {this.props.access.vendor_contact_edit ? 'edit' : 'view'}
              </div>,
            ]);
    }

    let viewNum = this.props.contacts.length - filteredContacts.length;

    return (
      <div>
        {this.state.addingNewContact && (
          <Dialog
            open={this.state.addingNewContact}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.handleNew}>
              <DialogTitle id="alert-dialog-title">Add Contact</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField('newName', 'Name', true)}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField('newEmail', 'Email', true, 'email')}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'newPhone',
                      'Phone Number',
                      false,
                      '999-999-9999'
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'newMobile',
                      'Mobile Number',
                      false,
                      '999-999-9999'
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'newFax',
                      'Fax Number',
                      false,
                      '999-999-9999'
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField('newJob', 'Job Title', false)}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Cancel</Button>
                <LoadingButton
                  label="Add"
                  isLoading={this.state.isSaving}
                  color="primaryVLButton"
                  buttonType="submit"
                />
              </DialogActions>
            </form>
          </Dialog>
        )}
        {this.state.isEdit && (
          <Dialog
            open={this.state.isEdit}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={(e) => this.handleEdit(e)}>
              <DialogTitle id="alert-dialog-title">Edit Contact</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField(
                      'editName',
                      'Name',
                      true,
                      null,
                      this.state.editContact.NAME
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField(
                      'editEmail',
                      'Email',
                      true,
                      'email',
                      this.state.editContact.E_MAIL_ADDRESS
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'editPhone',
                      'Phone Number',
                      false,
                      '999-999-9999',
                      this.state.editContact.PHONE_NMBR
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'editMobile',
                      'Mobile Number',
                      false,
                      '999-999-9999',
                      this.state.editContact.MOBILE_NUM
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createMaskedTextField(
                      'editFax',
                      'Fax Number',
                      false,
                      '999-999-9999',
                      this.state.editContact.FAX_NMBR
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.createTextField(
                      'editJob',
                      'Job Title',
                      false,
                      null,
                      this.state.editContact.JOB_TITLE
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Cancel</Button>
                {!!this.props.access.vendor_contact_delete && (
                  <LoadingButton
                    label={
                      this.state.editContact.INACTIVE_FLAG === 0
                        ? 'Inactivate'
                        : 'Reactivate'
                    }
                    isLoading={this.state.isActivating}
                    fontColor={
                      this.state.editContact.INACTIVE_FLAG === 0
                        ? 'red'
                        : 'green'
                    }
                    variant="text"
                    fn={this.handleActivate}
                  />
                )}
                {!!this.props.access.vendor_contact_edit && (
                  <LoadingButton
                    label="Update"
                    isLoading={this.state.isSaving}
                    color="primaryVLButton"
                    buttonType="submit"
                  />
                )}
              </DialogActions>
            </form>
          </Dialog>
        )}
        <Card className={classes.root}>
          <CardContent>
            <MUIDataTable
              title={'Contact Maintenance'}
              data={filteredContacts}
              columns={contactsColumns}
              options={{
                search: true,
                print: false,
                filter: true,
                viewColumns: true,
                download: false,
                displaySelectToolbar: false,
                elevation: 0,
                selectableRows: false,
                filterType: 'dropdown',
                responsive,
              }}
            />
            {!!this.props.access.vendor_contact_add && (
              <Button
                style={{ marginTop: '1rem' }}
                className="primaryVLButton"
                onClick={() =>
                  this.setState({
                    addingNewContact: true,
                  })
                }
              >
                Add New Contact
              </Button>
            )}
            <div style={{ float: 'right', margin: '1rem 0' }}>
              <Button
                onClick={() => {
                  if (!this.state.showInactive) {
                    this.setState({ showInactive: !this.state.showInactive });
                  } else {
                    this.setState({ showInactive: !this.state.showInactive });
                  }
                }}
                variant="outlined"
                      className="primaryTextVLButton"
              >
                {this.state.showInactive
                  ? `View Active Contacts (${viewNum})`
                  : `View Inactive Contacts (${viewNum})`}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

ClientContactMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(ClientContactMaintenance));
