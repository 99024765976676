import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  CircularProgress,
  Grid,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Button,
  TextField,
  MenuItem,
  Fab,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Slide,
  Fade,
  Paper,
  InputAdornment,
} from "@material-ui/core";

import uuidv1 from "uuid/v1";
import uuid from "uuid";

import { NumberFormatCustom } from "../utils/numberFormatUtils";
import FormTextField from "../components/common/FormTextField";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { formatter } from "../utils/tableCustoms";
import LoadingCircle from "../components/common/LoadingCircle"

const styles = (theme) => ({
  paper: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  paymentForm: {
    margin: "0 auto",
    padding: "2rem",
    maxWidth: "1300px",
    "@media (max-width: 700px)": {
      padding: "0.5rem",
    },
  },
  editLink: {
    cursor: "pointer",
    color: "blue",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class VendorPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      isLoading: false,
      date: "",
      newUnit: "",
      poNumber: "",
      contract: "",
      department: "",
      description: "",
      ref: "",
      invoiceCurrency: "",
      dueDate: "",
      notes: "",
      newRate: "",
      newQuantity: "",
      newTotal: "",
      invoiceSubTotal: "",
      confirm: false,
      fetchString: null,
      rows: [],
      uploadFiles: [],
      filterValue: "",
      editOpen: false,
      editNum: null,
      editRate: 0,
      editQty: 0,
      editTotal: 0,
      editUnit: "hourly",
      addLine: false,
      currentNewTotal: 0,
      clientUom: [],
      invoiceId: null,
      reOpen: false,
      showConfirmation: false,
    };
    this.addToInvoice = this.addToInvoice.bind(this);
    this.submitInvoice = this.submitInvoice.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.inputFileChange = this.inputFileChange.bind(this);
    this.updateRows = this.updateRows.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  componentDidMount() {
    const jsonVendor = JSON.stringify({
      jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
      vendorId: this.props.fetchInitialData.credentials.user.vendorId,
      userId: this.props.fetchInitialData.credentials.user.uuid,
      isVendor: true,
      clientId: this.props.fetchInitialData.credentials.clientInfo.client_id,
    });
    fetch(`${process.env.REACT_APP_API}/get-vendor-forms`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
      },
      body: jsonVendor,
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          content: data.data,
        });
      })
      .catch((error) => console.log(error));
  }

  addToInvoice(event) {
    event.preventDefault();
    if (event.target.rate.value === "" || event.target.quantity.value === "") {
      this.props.fetchInitialData.createSnack(
        "Must add a rate and quantity",
        "warning",
        3000
      );
      return null;
    }
    const newRow = {
      LINE_NUM: this.state.rows.length + 1,
      LINE_DESC: event.target.notes.value,
      LINE_UOM: this.state.newUnit,
      LINE_UNIT_COST: this.state.newRate,
      LINE_QTY: event.target.quantity.value,
      LINE_TRAN_AMOUNT: this.state.currentNewTotal,
    };
    let invoiceSub = 0;
    for (let i = 0; i < this.state.rows.length; i++) {
      invoiceSub += parseFloat(this.state.rows[i].LINE_TRAN_AMOUNT);
    }
    invoiceSub += parseFloat(this.state.currentNewTotal);
    const newTableRows = this.state.rows.map((row) => {
      return Object.assign({}, row);
    });
    newTableRows.push(newRow);
    this.setState({
      rows: newTableRows,
      newRate: "",
      newQuantity: "",
      newTotal: "",
      notes: "",
      addLine: false,
      currentNewTotal: 0,
      invoiceSubTotal: invoiceSub,
    });
  }

  updateFetchData() {
    if (this.state.rows.length === 0) {
      this.props.fetchInitialData.createSnack(
        "Form must contain at least 1 line",
        "warning",
        3000
      );
      return null;
    } else {
      if (this.state.invoiceId) {
        const { invoiceId } = this.state;

        const reOpenedRows = this.state.rows.map((row) => {
          if (row.INVOICE_ID) {
            return Object.assign({}, row);
          } else {
            return Object.assign({}, row, {
              CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
                .client_id,
              INVOICE_ID: this.state.invoiceId,
              VENDOR_ID: this.state.content.master[0].VENDOR_ID,
              UUID: uuidv1(),
            });
          }
        });

        const reOpenData = {
          reOpen: true,
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/invoice-details/${invoiceId}`,
          activity: {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: "INVOICE",
            OBJ_ID: invoiceId,
            ACTIVITY_TYPE: "REOPENED",
            ACTIVITY_STRING: "Invoice Reopened",
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          },
          apcinvoice: {
            INVOICE_ID: invoiceId,
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            VENDOR_ID: this.state.content.master[0].VENDOR_ID,
            REFERENCE_NUM: this.state.ref,
            CURRENCY: this.state.invoiceCurrency,
            INVOICE_DATE: new Date(),
            PO_NUMBER: this.state.poNumber,
            DEPARTMENT: this.state.department,
            CONTRACT_NUM: this.state.contract,
            DESCRIPTION: this.state.description,
            TRAN_INV_AMOUNT: this.state.invoiceSubTotal,
            DUE_DATE: this.state.dueDate,
            INVOICE_STATUS: 1,
            APPROVAL_STATUS_LEVEL: 1,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            FILTER_VALUE: this.state.filterValue,
            CODING_COMPLETE: 0,
          },
          apcinvdtl: reOpenedRows,
        };
        this.setState({
          fetchString: JSON.stringify(reOpenData),
          confirm: true,
        });
      } else {
        const copyOfRows = this.state.rows.map((row) => {
          return Object.assign({}, row, { UUID: uuid() });
        });
        let invoiceId = uuid();
        const fetchData = {
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          url: `https://${this.props.fetchInitialData.credentials.clientInfo.subdomain}.vendorlaunch.com/invoice-details/${invoiceId}`,
          activity: {
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            OBJ_TYPE: "INVOICE",
            OBJ_ID: invoiceId,
            ACTIVITY_TYPE: "CREATED",
            ACTIVITY_STRING: "Invoice created",
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
          },
          apcinvoice: {
            INVOICE_ID: invoiceId,
            CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
              .client_id,
            VENDOR_ID: this.state.content.master[0].VENDOR_ID,
            REFERENCE_NUM: this.state.ref,
            CURRENCY: this.state.invoiceCurrency,
            INVOICE_DATE: this.state.date,
            PO_NUMBER: this.state.poNumber,
            DEPARTMENT: this.state.department,
            CONTRACT_NUM: this.state.contract,
            DESCRIPTION: this.state.description,
            TRAN_INV_AMOUNT: this.state.invoiceSubTotal,
            DUE_DATE: this.state.dueDate,
            INVOICE_STATUS: 1,
            APPROVAL_STATUS_LEVEL: 1,
            USER_ID: this.props.fetchInitialData.credentials.user.uuid,
            FILTER_VALUE: this.state.filterValue,
            CODING_COMPLETE: 0,
          },
          apcinvdtl: copyOfRows,
        };
        this.setState({
          fetchString: JSON.stringify(fetchData),
          confirm: true,
        });
      }
    }
  }

  submitInvoice() {
    this.setState({ isLoading: true });
    if (this.state.reOpen) {
      fetch(`${process.env.REACT_APP_API}/reopen-invoice`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: this.props.fetchInitialData.credentials.user
            .jsonWebTok,
        },
        body: this.state.fetchString,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.setState({ isLoading: false });
            this.props.fetchInitialData.createSnack(data.error, "error", 3000);
          } else if (data.statusCode === 200) {
            let invoiceId = data.invoice_id;
            for (let i = 0; i < this.state.uploadFiles.length; i++) {
              this.uploadFile(invoiceId, this.state.uploadFiles[i]);
            }
            this.setState({ isLoading: false });
            this.props.history.push("/vendor");
            this.props.fetchInitialData.createSnack(
              "Invoice Reopened",
              "success",
              3000
            );
          } else {
            this.setState({ isLoading: false });
            this.props.fetchInitialData.createSnack(
              "There was an error",
              "error",
              3000
            );
          }
        });
    } else {
      fetch(`${process.env.REACT_APP_API}/get-or-update-invoices`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: this.state.fetchString,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            let invoiceId = data.data.invoice_id;
            for (let i = 0; i < this.state.uploadFiles.length; i++) {
              this.uploadFile(invoiceId, this.state.uploadFiles[i]);
            }
            this.props.fetchInitialData.createSnack(
              "Invoice created",
              "success",
              3000
            );
            this.setState({
              newRate: "",
              newQuantity: "",
              newTotal: "",
              notes: "",
              invoiceSubTotal: "",
              fetchData: null,
              confirm: false,
              rows: [],
              uploadFile: [],
            });
            this.setState({ isLoading: false });
            this.props.history.push("/vinvoices");
          } else if (data.error) {
            this.setState({ isLoading: false });
            this.props.fetchInitialData.createSnack(data.error, "error", 3000);
          } else {
            this.setState({ isLoading: false });
            this.props.fetchInitialData.createSnack(
              "There was an error",
              "error",
              3000
            );
          }
        });
    }
  }

  uploadFile(invoiceId, fileData) {
    if (fileData.src === "") {
      return null;
    }
    if (fileData.size > 5000000) {
      this.props.fetchInitialData.createSnack(
        `${fileData.name} is too big to upload`,
        "error",
        3000
      );
    } else {
      const attachmentId = uuidv1();
      let mime = fileData.src.split(";")[0].replace("data:", "");
      let data = fileData.src.split(";")[1].replace("base64,", "");
      const fetchData = {
        attachment_id: attachmentId,
        key: attachmentId,
        client_id: this.props.fetchInitialData.credentials.clientInfo.client_id,
        obj_type: "INVOICE",
        obj_id: invoiceId,
        attachment_mine: mime,
        original_name: fileData.name,
        data: data,
        created_by: this.props.fetchInitialData.credentials.user.uuid,
        activity: {
          CLIENT_ID: this.props.fetchInitialData.credentials.clientInfo
            .client_id,
          OBJ_TYPE: "INVOICE",
          OBJ_ID: invoiceId,
          ACTIVITY_TYPE: "UPLOAD",
          ACTIVITY_STRING:
            this.props.fetchInitialData.credentials.user.name +
            " uploaded an attachment to invoice",
          USER_ID: this.props.fetchInitialData.credentials.user.uuid,
        },
      };
      const stringData = JSON.stringify(fetchData);
      fetch(`${process.env.REACT_APP_API}/upload-s3-object`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
        },
        body: stringData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.error) {
            this.props.fetchInitialData.createSnack(data.error, "error", 3000);
          }
          return data;
        })
        .catch((err) => console.log(err));
    }
  }

  inputFileChange(e) {
    if (e.target.files[0] === undefined) {
      this.setState({
        uploadFiles: [],
      });
    } else {
      this.setState({ uploadFiles: [] });
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i],
          reader = new FileReader(),
          self = this;
        reader.onload = function(r) {
          const updatedUpload = self.state.uploadFiles.map((file) =>
            Object.assign({}, file)
          );
          updatedUpload.push({
            name: file.name,
            src: r.target.result,
            size: file.size,
          });
          self.setState({
            uploadFiles: updatedUpload,
          });
        };
        reader.readAsDataURL(file);
        this.setState({ value: reader });
      }
    }
  }

  updateRows(event) {
    event.preventDefault();
    const form = event.target;
    const updatedRows = this.state.rows.map((row, index) => {
      if (this.state.editNum === index) {
        return {
          LINE_DESC: form.editDesc.value,
          LINE_NUM: index + 1,
          LINE_QTY: form.editQty.value,
          LINE_UNIT_COST: this.state.editRate,
          LINE_UOM: this.state.editUnit,
          LINE_TRAN_AMOUNT: this.state.editQty * this.state.editRate,
        };
      } else return row;
    });
    let invoiceSub = 0;
    for (let i = 0; i < this.state.rows.length; i++) {
      invoiceSub += updatedRows[i].LINE_TRAN_AMOUNT;
    }

    this.setState({
      rows: updatedRows,
      editOpen: false,
      invoiceSubTotal: invoiceSub,
    });
  }

  handleDelete() {
    const updatedRows = this.state.rows.filter(
      (row, index) => index !== this.state.editNum
    );
    let invoiceSub = 0;
    for (let i = 0; i < updatedRows.length; i++) {
      invoiceSub += updatedRows[i].LINE_TRAN_AMOUNT;
    }

    this.setState({
      rows: updatedRows,
      editOpen: false,
      invoiceSubTotal: invoiceSub,
    });
  }

  handleClose() {
    this.setState({ confirm: false, editOpen: false, addLine: false });
  }

  render() {
    const { forwardedRef } = this.props;
    if (!this.state.content) {
      return (
        <div style={{ height: "100vh" }}>
          <div style={{ position: "relative", left: "50%", top: "40%" }}>
            <CircularProgress
              style={{ color: "rgb(232,102,19)" }}
              disableShrink
            />
          </div>
        </div>
      );
    }
    const { classes } = this.props;
    const load = this.state.isLoading ? (
      <LoadingCircle/>
    ) : (
      ""
    );
    let total = 0;
    if (this.state.editOpen) {
      total = this.state.editQty * this.state.editRate;
    }
    return (
      <div className={classes.paymentForm} ref={forwardedRef}>
        <Dialog
          open={this.state.addLine}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={this.Transition}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={(event) => this.addToInvoice(event)}>
            <DialogTitle id="alert-dialog-title">Add Item</DialogTitle>
            <DialogContent>
              <Grid container justify="flex-start" spacing={0}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    id="notes"
                    value={this.state.notes}
                    onChange={(event) =>
                      this.setState({ notes: event.target.value })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    select
                    id="unit"
                    label="Unit"
                    value={this.state.newUnit}
                    onChange={(event) =>
                      this.setState({ newUnit: event.target.value })
                    }
                    margin="normal"
                    variant="standard"
                  >
                    {this.state.content.invoiceUomList.map(
                      (dropdown, index) => {
                        return (
                          <MenuItem key={index} value={dropdown.value}>
                            {dropdown.value}
                          </MenuItem>
                        );
                      }
                    )}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    label="Rate"
                    id="rate"
                    margin="normal"
                    value={this.state.newRate}
                    onChange={(event) =>
                      this.setState({
                        newRate: event.target.value,
                        currentNewTotal:
                          event.target.value * this.state.newQuantity,
                      })
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    type="number"
                    label="Qty"
                    id="quantity"
                    margin="normal"
                    value={this.state.newQuantity}
                    onChange={(event) =>
                      this.setState({
                        newQuantity: event.target.value,
                        currentNewTotal:
                          event.target.value * this.state.newRate,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    id="total"
                    label="Total"
                    margin="normal"
                    value={Math.round(this.state.currentNewTotal * 100) / 100}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      classes: {
                        input: classes.resize,
                        root: classes.textFieldRoot,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                style={{
                  color: "#545454",
                }}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="primaryVLButton"
                variant="contained"
              >
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={this.state.confirm}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={this.Transition}
          // onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Payment Form
          </DialogTitle>
          <DialogContent>
            <Grid container justify="flex-start" spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <span>Invoice Date: </span>
                  <span>{this.state.date}</span>
                </div>
                <div>
                  <span>Payable To: </span>
                  <span>{this.state.content.master[0].VENDOR_VNAME}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <span>PO Number: </span>
                  <span>{this.state.poNumber}</span>
                </div>
                <div>
                  <span>Contract: </span>
                  <span>{this.state.contract}</span>
                </div>
                <div>
                  <span>Department: </span>
                  <span>{this.state.department}</span>
                </div>
                <div>
                  <span>Description: </span>
                  <span>{this.state.description}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <span>Vendor Invoice Number: </span>
                  <span>{this.state.ref}</span>
                </div>
                <div>
                  <span>Invoice Currency: </span>
                  <span>{this.state.invoiceCurrency}</span>
                </div>
                <div>
                  <span>Due Date: </span>
                  <span>{this.state.dueDate}</span>
                </div>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Line</TableCell>
                  <TableCell align="center">Notes</TableCell>
                  <TableCell align="center">Unit</TableCell>
                  <TableCell align="center">Rate</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map((row) => (
                  <TableRow key={row.LINE_NUM}>
                    <TableCell align="center">{row.LINE_NUM}</TableCell>
                    <TableCell align="center">{row.LINE_DESC}</TableCell>
                    <TableCell align="center">{row.LINE_UOM}</TableCell>
                    <TableCell align="center">
                      {formatter.format(row.LINE_UNIT_COST)}
                    </TableCell>
                    <TableCell align="center">{row.LINE_QTY}</TableCell>
                    <TableCell align="center">
                      {formatter.format(row.LINE_TRAN_AMOUNT)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell align="center" colSpan={1}>
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    <strong>
                      {formatter.format(this.state.invoiceSubTotal)}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              style={{
                backgroundColor: "#545454",
                color: "white",
              }}
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              onClick={this.submitInvoice}
              style={{
                margin: "0.5rem",
                backgroundColor: "rgb(232,102,19)",
                color: "white",
              }}
              variant="contained"
            >
              Confirm
              {load}
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.editOpen && (
          <Dialog
            open={this.state.editOpen}
            fullWidth={true}
            maxWidth={"lg"}
            TransitionComponent={this.Transition}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={this.updateRows}>
              <DialogTitle id="alert-dialog-title">Edit</DialogTitle>
              <DialogContent>
                <Grid container justify="flex-start" spacing={0}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="editDesc"
                      label="Description"
                      multiline
                      fullWidth
                      rowsMax="4"
                      defaultValue={
                        this.state.rows[this.state.editNum].LINE_DESC
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      select
                      label="Unit"
                      id="editUnit"
                      value={this.state.editUnit}
                      onChange={(event) =>
                        this.setState({ editUnit: event.target.value })
                      }
                      margin="normal"
                      variant="standard"
                    >
                      {this.state.content.invoiceUomList.map(
                        (dropdown, index) => {
                          return (
                            <MenuItem key={index} value={dropdown.value}>
                              {dropdown.value}
                            </MenuItem>
                          );
                        }
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editRate"
                      margin="normal"
                      label="Rate"
                      value={this.state.editRate}
                      onChange={(event) =>
                        this.setState({
                          editRate: event.target.value,
                          editTotal: event.target * this.state.editQty,
                        })
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        classes: {
                          input: classes.resize,
                          root: classes.textFieldRoot,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editQty"
                      label="Quantity"
                      margin="normal"
                      value={this.state.editQty}
                      onChange={(event) =>
                        this.setState({
                          editQty: event.target.value,
                          editTotal: event.target * this.state.editRate,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="editTotal"
                      label="Total"
                      margin="normal"
                      value={Math.round(total * 100) / 100}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        classes: {
                          input: classes.resize,
                          root: classes.textFieldRoot,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{
                    backgroundColor: "#545454",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ showConfirmation: true, editOpen: false })
                  }
                  style={{
                    backgroundColor: "#545454",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "rgb(232,102,19)",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Confirm
                  {load}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}

        <ConfirmationDialog
          ref={forwardedRef}
          handleClose={() =>
            this.setState({ showConfirmation: false, editOpen: false })
          }
          handleOpen={this.state.showConfirmation}
          dialogTitle={"Are you sure you want to delete this item?"}
          handleDelete={() => {
            this.handleDelete();
            this.setState({ showConfirmation: false });
          }}
        />

        <h3>Payment Request Form</h3>
        <Grid container justify="flex-start" spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <span>Invoice Date: </span>
              <span>{this.state.date}</span>
            </div>
            <div>
              <span>Payable To: </span>
              <span>{this.state.content.master[0].VENDOR_VNAME}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormTextField
              label={"PO Number"}
              value={this.state.poNumber}
              select
              menuItems={this.state.content.invoicePoList}
              onChange={(event) =>
                this.setState({ poNumber: event.target.value })
              }
            />
            <FormTextField
              label={"Contract"}
              value={this.state.contract}
              select
              menuItems={this.state.content.invoiceContractList}
              onChange={(event) => {
                this.setState({ contract: event.target.value });
              }}
            />
            <FormTextField
              label={"Description"}
              value={this.state.description}
              onChange={(event) =>
                this.setState({ description: event.target.value })
              }
            />
            <FormTextField
              label={"Vendor Invoice Number"}
              value={this.state.ref}
              onChange={(event) => this.setState({ ref: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormTextField
              select
              label={"Invoice Currency"}
              menuItems={this.state.content.invoiceCurrencyCodeList}
              value={this.state.invoiceCurrency}
              onChange={(event) =>
                this.setState({ invoiceCurrency: event.target.value })
              }
            />
            <FormTextField
              label={"Due Date"}
              value={this.state.dueDate}
              type={"date"}
              onChange={(event) =>
                this.setState({ dueDate: event.target.value })
              }
            />
            <div>
              <span
                style={{
                  position: "relative",
                  top: "10px",
                  paddingRight: "0.6rem",
                }}
              >
                {
                  this.props.fetchInitialData.credentials.clientInfo
                    .filter_value_label
                }
                :{" "}
              </span>
              <TextField
                select
                value={this.state.filterValue}
                onChange={(event) =>
                  this.setState({ filterValue: event.target.value })
                }
              >
                {this.state.content.invoiceFilterList.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.FILTER_VALUE}>
                      {option.FILTER_VALUE}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={this.addToInvoice}>
              <div style={{ padding: "0.5rem 0" }}>
                <Paper className={classes.paper}>
                  <Table
                    style={{ tableLayout: "auto" }}
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Line</TableCell>
                        <TableCell style={{ width: "300px" }} align="left">
                          Description
                        </TableCell>
                        <TableCell align="center">Unit</TableCell>
                        <TableCell align="center">Rate </TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Total</TableCell>
                        {this.state.rows.length > 0 && (
                          <TableCell align="center">edit</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row, index) => (
                        <TableRow key={row.LINE_NUM}>
                          <TableCell align="center" component="th" scope="row">
                            {row.LINE_NUM}
                          </TableCell>
                          <TableCell style={{ width: "300px" }} align="left">
                            {row.LINE_DESC}
                          </TableCell>
                          <TableCell align="center">{row.LINE_UOM}</TableCell>
                          <TableCell align="center">
                            {formatter.format(row.LINE_UNIT_COST)}
                          </TableCell>
                          <TableCell align="center">{row.LINE_QTY}</TableCell>
                          <TableCell align="center">
                            {formatter.format(row.LINE_TRAN_AMOUNT)}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              this.setState({
                                editOpen: true,
                                editNum: index,
                                editUnit: row.LINE_UOM,
                                editRate: row.LINE_UNIT_COST,
                                editQty: row.LINE_QTY,
                              })
                            }
                            align="center"
                            style={{ color: "blue" }}
                            className={classes.editLink}
                          >
                            edit
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell align="center" colSpan={1}>
                          <Fab
                            onClick={() => this.setState({ addLine: true })}
                            style={{
                              color: "white",
                              backgroundColor: "#545454",
                            }}
                            size="small"
                            aria-label="Add"
                          >
                            +
                          </Fab>
                        </TableCell>

                        <TableCell colSpan={3} />
                        <TableCell align="center" colSpan={1}>
                          <strong>Subtotal:</strong>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <strong>
                            {formatter.format(this.state.invoiceSubTotal)}
                          </strong>
                        </TableCell>
                        {this.state.rows.length > 0 && (
                          <TableCell align="center" colSpan={1} />
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </form>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <input
              type="file"
              style={{ margin: "1rem 0" }}
              multiple="multiple"
              onChange={this.inputFileChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Button
              onClick={() => this.updateFetchData()}
                              className="primaryVLButton"

              variant="contained"
            >
              Submit
              {load}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VendorPaymentForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorPaymentForm);
