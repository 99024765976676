import React from 'react';
import { Grid, Tab, Tabs, withStyles, withWidth } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import PropTypes from 'prop-types';
import AdminRoleMaintenance from "./AdminRoleMaintenance";
import AdminUserMaintenance from "./AdminUserMantenance";
import ChangeResponsibility from "./ChangeResponsibility";
import UserFilters from "../components/UserFilters";
import { AntTab, AntTabsVert } from "../components/common/Tabs";
import LambdaFetch from "../functions/FetchFromLambda";

class AdminUsersAndRolesMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      classifications: [],
      classificationType: ''
    };
  }

  viewHash = () => {
    switch (window.location.hash) {
      case '#users':
      default:
        return 0;
      case '#vendorUsers':
        return 1;
      case '#roles':
        return 2;
      case '#loginSettings':
        return 3;
      case '#userFilters':
        return 4;
    }
  };

  async componentDidMount() {
    
    fetch(`${process.env.REACT_APP_API}/contract-settings`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${this.props.fetchInitialData.credentials.user.jsonWebTok}`,
          },
          body: JSON.stringify({
          jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
          parentType: 'contract'
        }),
        })
          .then((resp) => resp.json())
          .then(data => {

            this.setState({ tabValue: this.viewHash(),
            classifications: data.data.classification1,
            classificationType: data.data.classificationType1[0].classification_type_name
             });
          })


    this.setState({ tabValue: this.viewHash() });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      switch (newValue) {
        case 1:
          window.location.hash = 'vendorUsers';
          break;
        case 2:
          window.location.hash = 'roles';
          break;
        case 3:
          window.location.hash = 'loginSettings';
          break;
        case 4:
          window.location.hash = 'userFilters';
          break;
        case 0:
        default:
          window.location.hash = 'users';
          break;
      }
    });
  }

  render() {
    const { classes } = this.props
    const tabs = [{ label: "Users", value: 0 },
      { label: "Vendor Users", value: 1 },
      { label: "Roles", value: 2 },
      { label: `Users Contract ${this.state.classificationType}`, value: 4, license: 'contract_license' },
      { label: "Login Settings", value: 3 }];

    return (
        <Grid style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: `${(this.props.width === 'sm' || this.props.width === 'xs') ? 'column' : 'row'}`,
          height: 424,
        }}>
        
        <AntTabsVert
          color={this.props.fetchInitialData.credentials.primaryAppColor}
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          orientation={(this.props.width === 'sm' || this.props.width === 'xs') ? 'horizontal' : 'vertical'}
        >
          {tabs.map((el) => (!el.license || this.props.fetchInitialData.credentials.clientInfo[el.license] === 1) && (
            <AntTab
              key={el.value}
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              label={el.label}
              value={el.value}
            />
          ))}
          {/* {!!this.props.access['task_swap'] && (
              <AntTab
              key={5}
              color={this.props.fetchInitialData.credentials.primaryAppColor}
              label="Task Swap"
              value={5}
          />
            )} */}
        </AntTabsVert>
          {[0,1,3].indexOf(this.state.tabValue) !== -1 && (
              <AdminUserMaintenance
                  tab={this.state.tabValue}
                  access={this.props.access}
                  fetchInitialData={this.props.fetchInitialData}
              />
          )}
          {this.state.tabValue === 2 && (
              <AdminRoleMaintenance
                  access={this.props.access}
                  fetchInitialData={this.props.fetchInitialData}
              />
          )}
          {this.state.tabValue === 4 && (
              <UserFilters
                  access={this.props.access}
                  fetchInitialData={this.props.fetchInitialData}
                  classifications={this.state.classifications}
                  classificationType={this.state.classificationType}
              />
          )}
          {/* {(this.state.tabValue === 5 && !!this.props.access['task_swap']) &&  (
              <ChangeResponsibility
                  fetchInitialData={this.props.fetchInitialData}
              />
          )} */}
        </Grid>
    );
  }
}

const styles = (theme) => ({
  tabsRoot: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    },
  },
  tabsIndicator: {
    backgroundColor: '#E86613',
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    '&:hover': {
      color: '#E86613',
      opacity: 1,
    },
    '&$selected': {
      color: '#E86613',
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
});

AdminUsersAndRolesMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withWidth()(withSnackbar(AdminUsersAndRolesMaintenance)));
